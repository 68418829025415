import { ManageCampaignTabsEnum } from 'constants/enums/campaign';
import produce from 'immer';
import { combineReducers } from 'redux';
import {
  ManageMainActionEnum,
  TManageCampaignRootAction,
} from 'redux/ActionTypes';

import campaignDetailReducer from './detail/detailReducer';
import manageCampaign from './manage/manageCampaign';
import { IMainManageCampaignReducer } from './type';

const initialValues: IMainManageCampaignReducer = {
  activeTab: ManageCampaignTabsEnum.DETAIL,
  loadings: {},
  modals: {
    reject: false,
  },
};

const mainReducer = produce(
  (draft: IMainManageCampaignReducer, action: TManageCampaignRootAction) => {
    switch (action.type) {
      case ManageMainActionEnum.CONTAIN_MANAGE_CAMPAIGN_ROOT_DATA: {
        draft.campaignId = action.payload.campaignId;
        draft.status = action.payload.status;

        return draft;
      }

      case ManageMainActionEnum.SET_CAMPAIGN_MODE: {
        draft.mode = action.payload;

        return draft;
      }

      case ManageMainActionEnum.CHANGE_CAMPAIGN_TAB: {
        draft.activeTab = action.payload;

        return draft;
      }

      case ManageMainActionEnum.SET_COPY_CAMPAIGN_ID: {
        draft.copyCampaignId = action.payload;

        return draft;
      }

      case ManageMainActionEnum.SET_LOADINGS: {
        draft.loadings[action.payload.type] = action.payload.value;

        return draft;
      }

      case ManageMainActionEnum.SET_MODALS: {
        draft.modals[action.payload.type] = action.payload.value;

        return draft;
      }

      case ManageMainActionEnum.SET_DRAFT_MANAGE_CAMPAIGN: {
        draft.draft = { ...draft };

        return draft;
      }

      case ManageMainActionEnum.APPLY_DRAFT_MANAGE_CAMPAIGN: {
        if (draft?.draft) {
          draft = { ...draft.draft };

          return draft;
        }

        draft = initialValues;

        return draft;
      }

      case ManageMainActionEnum.CLEANUP_ROOT_MANAGE_STATE: {
        draft = { ...initialValues };

        return draft;
      }

      default:
        return draft;
    }
  },
  initialValues,
);

const manageCampaignReducerRoot = combineReducers({
  main: mainReducer,
  detail: campaignDetailReducer,
  manage: manageCampaign,
});

export default manageCampaignReducerRoot;
