import { Skeleton } from '@mui/material';
import React from 'react';

import { SBox } from './MediaLoading.styled';

const loadersSkeletonCount = 6;

const MediaLoading: React.FC = () => {
  return (
    <SBox>
      {Array.from({ length: loadersSkeletonCount }).map((_, index) => (
        <Skeleton key={index} variant="rectangular" height={200} />
      ))}
    </SBox>
  );
};

export default MediaLoading;
