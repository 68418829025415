import produce from 'immer';

import { StatesActionEnum, TStatesActions } from '../../ActionTypes';
import { IStatesState } from './type';

const initialValues: IStatesState = {
  states: [],
  loading: {
    getAll: false,
  },
};

const statesReducer = produce(
  (draft = initialValues, action: TStatesActions) => {
    switch (action.type) {
      case StatesActionEnum.SET_STATES: {
        draft.states = action.payload;

        return draft;
      }
      case StatesActionEnum.SET_LOADING: {
        draft.loading[action.payload.type] = action.payload.value;

        return draft;
      }
      default: {
        return draft;
      }
    }
  },
  initialValues,
);

export default statesReducer;
