/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { AlertStatusesEnum } from 'constants/constants';
import { CampaignTypesEnum } from 'constants/enums/campaign';
import { NothingSelectedEnum } from 'constants/enums/nothing';
import { convertToCountDaysOfWeek } from 'helpers';
import { removeTimezone } from 'helpers/date/date';
import { useTypedSelector } from 'hooks';
import { useSnackbar } from 'notistack';
import {
  ICampaignQueryDto,
  ICampaignScheduleQueryDto,
  ICampaignSchedulesNew,
  ICampaignSitesNew,
  ICampaignSitesQueryDto,
  ISchedulesMediaQueryDto,
} from 'types';

import { IObjectIdWithName } from '../../../types/general';

interface ICampaignHookReturn {
  getCampaignQueryScheme: () => ICampaignQueryDto | undefined;
  getCampaignSitesQueryScheme: () => ICampaignSitesQueryDto[];
  getCampaignSchedulesQueryScheme: (
    campaignSite: ICampaignSitesNew,
  ) => ICampaignScheduleQueryDto[];
  getScheduleMedias: (
    campaignSchedule: ICampaignSchedulesNew,
    scheduleId: number,
  ) => ISchedulesMediaQueryDto[];
}

const useCampaign = (): ICampaignHookReturn => {
  const { categories } = useTypedSelector((state) => state && state.category);

  const { manage, detail } = useTypedSelector(
    (state) => state && state.manageCampaign,
  );

  const { enqueueSnackbar } = useSnackbar();
  const showSnackbar = (message: string) =>
    enqueueSnackbar(message, {
      variant: AlertStatusesEnum.ERROR,
      preventDuplicate: true,
    });

  const getDefaultCategoryId = (): IObjectIdWithName | undefined => {
    return categories.find(
      (category) => category.name === 'Marketing/Community/Content',
    );
  };

  const getScheduleMedias: ICampaignHookReturn['getScheduleMedias'] = (
    campaignSchedule,
    scheduleId,
  ) => {
    return campaignSchedule.scheduleMedias.map((scheduleMedia) => ({
      scheduleId,
      mediaId: scheduleMedia.mediaId,
      tags: scheduleMedia.tags || '',
    }));
  };

  const getCampaignSchedulesQueryScheme: ICampaignHookReturn['getCampaignSchedulesQueryScheme'] =
    (campaignSite) => {
      return campaignSite.campaignSchedules.reduce<ICampaignScheduleQueryDto[]>(
        (acc, campaignSchedule) => {
          const campaignSchedules = [...acc];

          const campaignScheduleId =
            typeof campaignSchedule.id === 'number' ? campaignSchedule.id : 0;

          const isStartDateExist = !!campaignSchedule.startDate;
          const isEndDateExist = !!campaignSchedule.endDate;
          const isDateExist = isStartDateExist && isEndDateExist;

          const isStartTimeExist = !!campaignSchedule.startTime;
          const isEndTimeExist = !!campaignSchedule.endTime;
          const isTimeExist = isStartTimeExist && isEndTimeExist;

          const isDurationExist = !!campaignSchedule.duration;
          const isDayOfWeeks = !!campaignSchedule.dayOfWeek;

          if (isDateExist && isTimeExist && isDurationExist && isDayOfWeeks) {
            campaignSchedules.push({
              id: campaignScheduleId,
              startDate: removeTimezone(
                campaignSchedule.startDate!,
              ).toISOString(),
              endDate: removeTimezone(campaignSchedule.endDate!).toISOString(),
              dayOfWeek: convertToCountDaysOfWeek(campaignSchedule.dayOfWeek!),
              startTime: campaignSchedule.startTime!,
              endTime: campaignSchedule.endTime!,
              duration: campaignSchedule.duration,
              scheduleMedias: getScheduleMedias(
                campaignSchedule,
                campaignScheduleId,
              ),
            });
          }

          return campaignSchedules;
        },
        [],
      );
    };

  const getCampaignSitesQueryScheme: ICampaignHookReturn['getCampaignSitesQueryScheme'] =
    () => {
      return manage.campaignSites.map((campaignSite) => {
        const campaignSiteId =
          typeof campaignSite.id === 'number' ? campaignSite.id : 0;

        return {
          id: campaignSiteId,
          siteId: campaignSite.siteId,
          campaignSchedules: getCampaignSchedulesQueryScheme(campaignSite),
        };
      });
    };

  const getCampaignQueryScheme: ICampaignHookReturn['getCampaignQueryScheme'] =
    () => {
      if (!detail?.campaignType?.id) {
        showSnackbar(NothingSelectedEnum.CAMPAIGN_TYPE);

        return;
      }

      const isAdvertising =
        detail.campaignType.name === CampaignTypesEnum.ADVERTISING;

      if (!detail?.advertiser?.value) {
        showSnackbar(NothingSelectedEnum.ADVERTISER);

        return;
      }

      if (isAdvertising && !detail?.product?.value) {
        showSnackbar(NothingSelectedEnum.PRODUCT);

        return;
      }

      const productId = isAdvertising ? detail?.product?.value : null;

      const categoryId = isAdvertising
        ? detail?.category?.value
        : getDefaultCategoryId()?.id;

      if (isAdvertising && !detail?.category?.value) {
        showSnackbar(NothingSelectedEnum.CATEGORY);

        return;
      }

      const campaignSites = getCampaignSitesQueryScheme();

      return {
        advertiserId: detail.advertiser.value,
        productId,
        categoryId,
        typeId: detail.campaignType.id,
        name: detail.campaignName,
        isPreemptive: manage.isPreemptive,
        campaignSites,
      };
    };

  return {
    getCampaignSchedulesQueryScheme,
    getCampaignSitesQueryScheme,
    getCampaignQueryScheme,
    getScheduleMedias,
  };
};

export default useCampaign;
