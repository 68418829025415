import './Selects.scss';

import { CampaignTypesEnum } from 'constants/enums/campaign';
import { useTypedSelector } from 'hooks';
import React from 'react';

import Advertising from './Advertising/Advertising';
import Category from './Category/Category';
import Product from './Product/Product';

const Selects: React.FC = () => {
  const { campaignType } = useTypedSelector(
    (state) => state && state.manageCampaign.detail,
  );
  const isProductView = campaignType?.name === CampaignTypesEnum.ADVERTISING;

  return (
    <div className="manage-campaign-selects-wrapper">
      <Advertising />
      {isProductView && <Product />}
      <Category />
    </div>
  );
};

export default Selects;
