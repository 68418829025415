/* eslint-disable @typescript-eslint/no-explicit-any */
import './TableBody.scss';

import { flexRender, Table } from '@tanstack/react-table';
import classnames from 'classnames';
import React, { ReactElement } from 'react';

import { ITableHookReturn, TTable } from '../../../types';

interface ITableBody<TData> {
  readonly table: Table<TData>;
  readonly handleRowClick: ITableHookReturn<TData>['handleRowClick'];
  readonly rowSelecting?: TTable<TData>['rowSelecting'];
}

const TableBody = <TData,>({
  table,
  handleRowClick,
  rowSelecting,
}: ITableBody<TData>): ReactElement => {
  return (
    <tbody className="__table_body">
      {table.getRowModel().rows.map((row) => (
        <tr
          key={row.id}
          onClick={(event) => handleRowClick(row, event)}
          className={classnames(
            '__table_row',
            '__table_row-body',
            `__row-${row.id}`,
            `__row-body-${row.id}`,
            { canSelected: rowSelecting },
          )}
        >
          {row.getVisibleCells().map((cell) => (
            <td
              key={cell.id}
              style={{ width: cell.column.getSize() }}
              className={classnames(
                {
                  [`__${(cell.column.columnDef as any).accessorKey}`]: Boolean(
                    (cell.column.columnDef as any).accessorKey,
                  ),
                },
                '__table_cell',
                '__table_cell-body',
                `__cell-${row.id}-${cell.id}`,
                `__cell-body-${row.id}-${cell.id}`,
              )}
            >
              {flexRender(cell.column.columnDef.cell, cell.getContext())}
            </td>
          ))}
        </tr>
      ))}
    </tbody>
  );
};

export default TableBody;
