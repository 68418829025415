import './HasSites.scss';

import React from 'react';

import { ManageTabContent, ManageTabHeader } from './components';

const HasSites: React.FC = () => {
  return (
    <div className="has-sites-wrapper">
      <ManageTabHeader />
      <ManageTabContent />
    </div>
  );
};

export default HasSites;
