import { IInitialCampaignDetail } from 'redux/Reducers/campaignsReducer/manage/detail/type';

import {
  IContainDetailManageCampaignPayload,
  IManageCampaignDetailModals,
  IManageCampaignLoaders,
} from './type';

export enum ManageCampaignDetailActionEnum {
  CONTAIN_DETAIL_MANAGE_CAMPAIGN_DATA = 'CONTAIN_DETAIL_MANAGE_CAMPAIGN_DATA',

  SET_CAMPAIGN_FOR_MANAGE = 'SET_CAMPAIGN_FOR_MANAGE',

  SET_CAMPAIGN_NAME_MANAGE = 'SET_CAMPAIGN_NAME_MANAGE',
  SET_SEARCH_CAMPAIGN_MANAGE = 'SET_SEARCH_CAMPAIGN_MANAGE',
  SET_CAMPAIGN_TYPE = 'SET_CAMPAIGN_TYPE',

  SET_ADVERTISER = 'SET_ADVERTISER_MANAGE_CAMPAIGN',
  SET_PRODUCT = 'SET_PRODUCT_MANAGE_CAMPAIGN',
  SET_CATEGORY = 'SET_CATEGORY_MANAGE_CAMPAIGN',
  SET_AUTO_SUBSTITUTION_CATEGORY = 'SET_AUTO_SUBSTITUTION_CATEGORY',

  SET_LOADING_MANAGE_CAMPAIGN_DETAIL = 'SET_LOADING_MANAGE_CAMPAIGN_DETAIL',
  SET_MODAL_MANAGE_CAMPAIGN_DETAIL = 'SET_MODAL_MANAGE_CAMPAIGN_DETAIL',

  SET_DRAFT_MANAGE_CAMPAIGN_DETAIL = 'SET_DRAFT_MANAGE_CAMPAIGN_DETAIL',
  APPLY_DRAFT_MANAGE_CAMPAIGN_DETAIL = 'APPLY_DRAFT_MANAGE_CAMPAIGN_DETAIL',

  CLEANUP_MANAGE_CAMPAIGN_DETAIL_DATA = 'CLEANUP_MANAGE_CAMPAIGN_DETAIL_DATA',
}

interface IContainDetailManageCampaignData {
  type: ManageCampaignDetailActionEnum.CONTAIN_DETAIL_MANAGE_CAMPAIGN_DATA;
  payload: IContainDetailManageCampaignPayload;
}

interface ISetCampaign {
  type: ManageCampaignDetailActionEnum.SET_CAMPAIGN_FOR_MANAGE;
  payload: IInitialCampaignDetail['campaigns'];
}

interface ISetCampaignName {
  type: ManageCampaignDetailActionEnum.SET_CAMPAIGN_NAME_MANAGE;
  payload: IInitialCampaignDetail['campaignName'];
}

interface ISetSearchCampaignManage {
  type: ManageCampaignDetailActionEnum.SET_SEARCH_CAMPAIGN_MANAGE;
  payload: IInitialCampaignDetail['searchCampaign'];
}

interface ISetCampaignType {
  type: ManageCampaignDetailActionEnum.SET_CAMPAIGN_TYPE;
  payload: IInitialCampaignDetail['campaignType'];
}

interface ISetAdvertiser {
  type: ManageCampaignDetailActionEnum.SET_ADVERTISER;
  payload: IInitialCampaignDetail['advertiser'];
}

interface ISetProduct {
  type: ManageCampaignDetailActionEnum.SET_PRODUCT;
  payload: IInitialCampaignDetail['product'];
}

interface ISetCategory {
  type: ManageCampaignDetailActionEnum.SET_CATEGORY;
  payload: IInitialCampaignDetail['category'];
}

interface ISetAutoSubstitutionCategory {
  type: ManageCampaignDetailActionEnum.SET_AUTO_SUBSTITUTION_CATEGORY;
  payload: IInitialCampaignDetail['isAutoSubstitutionCategory'];
}

interface ISetLoaders {
  type: ManageCampaignDetailActionEnum.SET_LOADING_MANAGE_CAMPAIGN_DETAIL;
  payload: IManageCampaignLoaders;
}

interface ISetModals {
  type: ManageCampaignDetailActionEnum.SET_MODAL_MANAGE_CAMPAIGN_DETAIL;
  payload: IManageCampaignDetailModals;
}

interface ISetDraftManageCampaignDetail {
  type: ManageCampaignDetailActionEnum.SET_DRAFT_MANAGE_CAMPAIGN_DETAIL;
}

interface IApplyDraftManageCampaignDetail {
  type: ManageCampaignDetailActionEnum.APPLY_DRAFT_MANAGE_CAMPAIGN_DETAIL;
}

interface ICleanupManageCampaignDetailData {
  type: ManageCampaignDetailActionEnum.CLEANUP_MANAGE_CAMPAIGN_DETAIL_DATA;
}

export type TManageCampaignDetailAction =
  | IContainDetailManageCampaignData
  | ISetCampaign
  | ISetCampaignName
  | ISetSearchCampaignManage
  | ISetCampaignType
  | ISetAdvertiser
  | ISetProduct
  | ISetCategory
  | ISetAutoSubstitutionCategory
  | ISetLoaders
  | ISetModals
  | ICleanupManageCampaignDetailData
  | ISetDraftManageCampaignDetail
  | IApplyDraftManageCampaignDetail;
