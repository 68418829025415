import './TableActions.scss';

import { Search as SearchIcon } from '@mui/icons-material';
import { InputAdornment, Tooltip } from '@mui/material';
import { Table } from '@tanstack/react-table';
import classnames from 'classnames';
import React, {
  ChangeEvent,
  forwardRef,
  Fragment,
  Ref,
  useImperativeHandle,
  useRef,
} from 'react';

import { TActions, TActionsButtons } from '../../types';
import { useOrdering, useRenderActions, useTableActions } from './hooks';
import { SIconButton, STextFiled } from './TableActions.styled';

export interface ITableActions<TData> {
  readonly show: boolean;
  readonly loading: boolean;
  readonly table: Table<TData>;
  readonly actions?: TActions[];
  readonly globalSearch?: string;
  readonly isSearchable?: boolean;
  readonly handleGlobalSearch: (event: ChangeEvent<HTMLInputElement>) => void;
}

const TableActionsComponent = <TData,>(
  {
    show,
    actions,
    loading,
    table,
    isSearchable,
    globalSearch,
    handleGlobalSearch,
  }: ITableActions<TData>,
  ref: Ref<Array<TActionsButtons>>,
) => {
  const { isOrderingPopoverOpen, orderingPopoverOpen, orderingPopoverClose } =
    useOrdering();

  const { allActions } = useTableActions<TData>({
    actions,
    orderingPopoverOpen,
  });

  const { renderSubComponent } = useRenderActions({
    table,
    isOrderingPopoverOpen,
    orderingPopoverClose,
  });

  const buttonsRef = useRef<Array<TActionsButtons>>([]);

  useImperativeHandle(ref, () => buttonsRef.current, [actions]);

  return (
    <div className={classnames('__table-actions', { show })}>
      {isSearchable && (
        <div className="__search_wrapper">
          <STextFiled
            label="Search"
            value={globalSearch}
            variant="filled"
            color="primary"
            onChange={handleGlobalSearch}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </div>
      )}
      <div className="__actions_wrapper">
        {allActions.length &&
          allActions.map(
            ({ id, icon, label, type, handleClick, disabled }, index) => {
              return (
                <Fragment key={id}>
                  <Tooltip title={label} placement="top" arrow>
                    <span>
                      <SIconButton
                        onClick={handleClick}
                        ref={(el) => {
                          buttonsRef.current[index] = el;
                        }}
                        disabled={loading || disabled}
                      >
                        {icon}
                      </SIconButton>
                    </span>
                  </Tooltip>
                  {type && renderSubComponent(type, buttonsRef.current[index])}
                </Fragment>
              );
            },
          )}
      </div>
    </div>
  );
};

const TableActions = forwardRef(TableActionsComponent) as <T>(
  props: ITableActions<T> & { ref?: Ref<Array<TActionsButtons>> },
) => ReturnType<typeof TableActionsComponent>;

export default TableActions;
