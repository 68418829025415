import produce from 'immer';

import { TAllUsersColumns } from '../../../types';
import {
  TUsersAction,
  UserActionsEnum,
} from '../../ActionTypes/UsersTypes/usersTypes';
import { IUsersState } from './types';

const initialState: IUsersState<TAllUsersColumns> = {
  users: [],
  queryParams: {},
  allUsersResponse: {},
  allUsersTable: {
    selectedUsers: [],
    globalSearch: '',
    totalUsersCount: 0,
    pagination: {
      currentPage: 0,
      pageSize: 10,
    },
    sort: {
      column: undefined,
      direction: undefined,
    },
    currentClickedActionsRowId: null,
  },
  manageUserData: {
    subOrganisations: [],
    centres: [],
    networks: [],
    roles: [],
  },
  bulkDeleteModalData: {
    selectedUsers: [],
  },
  loaders: {},
  errors: {},
  modals: {},
};

const usersReducer = produce(
  (
    draft: IUsersState<TAllUsersColumns> = initialState,
    action: TUsersAction,
  ) => {
    switch (action.type) {
      case UserActionsEnum.SET_LOADING: {
        draft.loaders[action.payload.type] = action.payload.value;

        return draft;
      }
      case UserActionsEnum.SET_ERROR: {
        draft.errors[action.payload.type] = action.payload.value;

        return draft;
      }
      case UserActionsEnum.GET_ALL_USERS: {
        const { data } = action.payload;

        draft.users = data;
        draft.allUsersResponse = action.payload;
        draft.loaders.getAll = false;
        draft.errors.getAll = '';
        draft.allUsersTable.totalUsersCount = action.payload.totalCount;

        return draft;
      }

      case UserActionsEnum.SET_ALL_USERS_PARAMS: {
        draft.queryParams.getAll = action.payload;

        return draft;
      }

      case UserActionsEnum.GET_ONE_USER: {
        draft.allUsersTable.currentUserData = action.payload;

        return draft;
      }

      case UserActionsEnum.TOGGLE_MODAL: {
        draft.modals[action.payload.type] = action.payload.value;

        return draft;
      }

      case UserActionsEnum.ALL_USER_TABLE_GLOBAL_SEARCH: {
        draft.allUsersTable.globalSearch = action.payload;

        return draft;
      }

      case UserActionsEnum.ALL_USER_TABLE_SET_SELECTED_USERS: {
        draft.allUsersTable.selectedUsers = action.payload;

        return draft;
      }

      case UserActionsEnum.ALL_USER_TABLE_CLEANUP_SELECTED_USERS: {
        draft.allUsersTable.selectedUsers = [];
        draft.bulkDeleteModalData.selectedUsers = [];

        return draft;
      }

      case UserActionsEnum.ALL_USER_TABLE_PAGINATION_PAGE_SIZE: {
        draft.allUsersTable.pagination.pageSize = action.payload;

        return draft;
      }

      case UserActionsEnum.ALL_USER_TABLE_PAGINATION_CURRENT_PAGE: {
        draft.allUsersTable.pagination.currentPage = action.payload;

        return draft;
      }
      case UserActionsEnum.ALL_USER_TABLE_SORT_COLUMN: {
        draft.allUsersTable.sort.column = action.payload;

        return draft;
      }

      case UserActionsEnum.ALL_USER_TABLE_SORT_DIRECTION: {
        draft.allUsersTable.sort.direction = action.payload;

        return draft;
      }

      case UserActionsEnum.ALL_USER_TABLE_CURRENT_CLICKED_ACTIONS_ROW_ID: {
        draft.allUsersTable.currentClickedActionsRowId = action.payload;

        return draft;
      }

      case UserActionsEnum.SET_CURRENT_CLICKED_USER: {
        draft.allUsersTable.currentClickedUser = action.payload;

        return draft;
      }

      case UserActionsEnum.REMOVE_CURRENT_CLICKED_USER: {
        draft.allUsersTable.currentClickedUser = undefined;
        draft.allUsersTable.currentUserData = undefined;

        return draft;
      }

      case UserActionsEnum.SET_SUBORGANISATIONS: {
        draft.manageUserData.subOrganisations = action.payload;

        return draft;
      }
      case UserActionsEnum.SET_CENTRES: {
        draft.manageUserData.centres = action.payload;

        return draft;
      }

      case UserActionsEnum.SET_NETWORKS: {
        draft.manageUserData.networks = action.payload;

        return draft;
      }

      case UserActionsEnum.SET_ROLES: {
        draft.manageUserData.roles = action.payload;

        return draft;
      }

      case UserActionsEnum.SET_BULK_MODAL_SELECTED_USERS: {
        draft.bulkDeleteModalData.selectedUsers = action.payload;

        return draft;
      }

      case UserActionsEnum.REMOVE_BULK_MODAL_USER: {
        draft.bulkDeleteModalData.selectedUsers =
          draft.bulkDeleteModalData.selectedUsers.filter(
            (item) => item.id !== action.payload,
          );

        return draft;
      }

      default:
        return draft;
    }
  },
  initialState,
);

export default usersReducer;
