import './PopupMenu.scss';

import React, { useMemo } from 'react';

import Checkbox from '../Checkbox';
import { StyledMenu, StyledMenuItem } from './PopupMenu.styled';

interface ICampaignListMenuProps {
  isOpen: boolean;
  anchorEl: null | HTMLElement;
  setAnchorEl: React.Dispatch<React.SetStateAction<HTMLElement | null>>;
  id?: number;
  items: string[];
  onClick: React.Dispatch<React.SetStateAction<string[]>>;
  initialTableColumnsValue: string[];
  exceptions?: string[];
}

const PopupMenu: React.FC<ICampaignListMenuProps> = (props) => {
  const {
    isOpen,
    anchorEl,
    setAnchorEl,
    items,
    onClick,
    initialTableColumnsValue,
    exceptions,
  } = props;

  const menuListItems = useMemo(() => {
    if (exceptions) {
      return initialTableColumnsValue.filter(
        (item) => exceptions?.findIndex((element) => item === element) === -1,
      );
    }

    return initialTableColumnsValue;
  }, [initialTableColumnsValue, exceptions]);

  const menuClose = () => {
    setAnchorEl(null);
  };

  const onChange = (item: string) => {
    if (items.includes(item)) {
      onClick(items.filter((el) => el !== item));

      return;
    }
    onClick([...items, item]);
  };

  return (
    <StyledMenu
      id="long-menu"
      anchorEl={anchorEl}
      keepMounted
      open={isOpen}
      onClose={menuClose}
      className="list-container"
      elevation={0}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
    >
      {menuListItems.map((item) => (
        <div key={item}>
          <StyledMenuItem onClick={() => onChange(item)}>
            <div className="menu-list-icon menu-list">
              <span className="icon-label menu-list-item menu-text">
                {item}
              </span>
              <Checkbox withoutPaddings isChecked={items.includes(item)} />
            </div>
          </StyledMenuItem>
        </div>
      ))}
    </StyledMenu>
  );
};

export default PopupMenu;
