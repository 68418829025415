/* eslint-disable max-lines */
import './TableFilter.scss';

import {
  Clear as ClearIcon,
  ExpandMore as ExpandMoreIcon,
  Search as SearchIcon,
} from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  TextField,
  Typography,
} from '@mui/material';
import { AdjustmentsIcon, ColumnFilterIcon } from 'assets/icons';
import { colors } from 'constants/colors/colors';
import React, { ReactElement, useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import {
  IDateRangeFilter,
  IDurationFilter,
  IFiltersList,
  IFiltersText,
  IStartDateFilter,
  TFilterRecord,
} from 'types';

import Button from '../../Button';
import IconButton from '../../Button/components/IconButton';
import DatePicker from '../../DatePicker/DatePicker';
import CampaignListMenu from '../../PopupMenu';
import SvgIcon from '../../SvgIcon/SvgIcon';
import TableFilterList from './TableFilterList';

interface ITableFilterProps<FiltersValue> {
  setIsFilterOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setSearchQuery: React.Dispatch<React.SetStateAction<string>>;
  searchQuery: string;
  filtersList: IFiltersList[];
  filtersValue: FiltersValue;
  initialFilterValue: FiltersValue;
  setFiltersValue: React.Dispatch<React.SetStateAction<FiltersValue>>;
  tableColumnsFilter: string[];
  setTableColumnsFilter: React.Dispatch<React.SetStateAction<string[]>>;
  resetPagination: () => void;
  initialTableColumnsValue: string[];
  filtersText: IFiltersText;
  dateRangeFilter?: IDateRangeFilter;
  startDateFilter?: IStartDateFilter;
  durationFilter?: IDurationFilter;
  setDurationFilterSeconds?: React.Dispatch<React.SetStateAction<number>>;
  setDurationFilterMinute?: React.Dispatch<React.SetStateAction<number>>;
  durationSeconds?: number;
  durationMinute?: number;
  exceptions?: string[];
  setPageNumber: React.Dispatch<React.SetStateAction<number>>;
}

const useStyles = makeStyles()(() => ({
  accordionRoot: {
    '&.Mui-expanded': {
      margin: 0,

      '& + .MuiPaper-root': {
        '&:before': {
          opacity: 0,
        },
      },
    },
  },

  accordionSummary: {
    height: 48,
    minHeight: 'unset',
    padding: 0,

    '&.Mui-expanded': {
      height: 48,
      minHeight: 'unset',
    },
  },
  accordionDetails: {
    padding: 0,
  },
}));

const TableFilter = <Filters extends TFilterRecord>({
  setIsFilterOpen,
  setSearchQuery,
  searchQuery,
  filtersList,
  filtersValue,
  setFiltersValue,
  tableColumnsFilter,
  setTableColumnsFilter,
  resetPagination,
  initialFilterValue,
  initialTableColumnsValue,
  filtersText,
  dateRangeFilter,
  startDateFilter,
  durationFilter,
  setDurationFilterSeconds,
  setDurationFilterMinute,
  durationSeconds,
  durationMinute,
  exceptions,
  setPageNumber,
}: ITableFilterProps<Filters>): ReactElement => {
  const [expandedAccordions, setExpandedAccordions] = useState<string[]>([]);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const isOpen = Boolean(anchorEl);

  const { classes, cx } = useStyles();

  const illuminateFilter = (isFilter: number | Date | undefined): string => {
    return isFilter ? 'accordion-item-active' : 'accordion-item';
  };

  const activeMediaFilter = (
    initialFiltersList: Filters,
    filtersListArr: IFiltersList[],
  ) => {
    const initialFiltersNameList = Object.keys(initialFiltersList);
    const activeFiltersList: IFiltersList[] = filtersListArr.filter((item) =>
      initialFiltersNameList.includes(item.name),
    );

    return activeFiltersList;
  };

  const handleExpand = (id: string) => {
    const isExpanded = expandedAccordions?.includes(id);

    if (!isExpanded) {
      setExpandedAccordions([...expandedAccordions, id]);

      return;
    }
    setExpandedAccordions([
      ...expandedAccordions.filter((item) => item !== id),
    ]);
  };

  const handleClearFilters = () => {
    if (dateRangeFilter?.handleClearDateRange) {
      const { handleClearDateRange } = dateRangeFilter;
      handleClearDateRange();
    }
    if (startDateFilter) {
      startDateFilter.handleChangeDateAdded(undefined);
    }
    if (dateRangeFilter) {
      dateRangeFilter.handleClearDateRange();
    }
    if (setDurationFilterSeconds) {
      setDurationFilterSeconds(0);
    }
    if (setDurationFilterMinute) {
      setDurationFilterMinute(0);
    }
    setPageNumber(0);
    setFiltersValue(initialFilterValue);
    setExpandedAccordions([]);
  };

  const handleChange = ({
    target: { value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(value);
    resetPagination();
  };

  const handleChangeSecondsDuration = ({
    target: { value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    if (setDurationFilterSeconds) {
      setDurationFilterSeconds(+value);
    }
  };

  const handleChangeMinuteDuration = ({
    target: { value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    if (setDurationFilterMinute) {
      setDurationFilterMinute(+value);
    }
  };

  const menuHandler = (e: { currentTarget: HTMLElement }) => {
    setAnchorEl(e.currentTarget);
  };

  const isExpanded = (item: string) => expandedAccordions.includes(item);
  const filtersItems = activeMediaFilter(initialFilterValue, filtersList);

  return (
    <>
      <div className="icon-container">
        <IconButton
          onClick={() => setIsFilterOpen(false)}
          icon={<AdjustmentsIcon className="activeSVG" />}
          style={{ fill: colors.Cerulean }}
          className="icon-l"
        />
        <IconButton
          aria-label="more"
          aria-controls="long-menu"
          aria-haspopup="true"
          name="menu"
          onClick={menuHandler}
          icon={<ColumnFilterIcon />}
          wrapperSize="l"
        />
      </div>
      <div className="table-filter">
        <div className="filter-container">
          <TextField
            className="search-input-styles full-width"
            variant="outlined"
            label={filtersText.search}
            defaultValue={searchQuery || ''}
            onChange={handleChange}
            InputProps={{
              endAdornment: (
                <SvgIcon className="icon-l">
                  <SearchIcon />
                </SvgIcon>
              ),
            }}
          />
        </div>
        <div className="filter-container">
          <div className="title-container">
            <div className="second-header filter-title">
              {filtersText.title}
            </div>
            <Button
              onClick={handleClearFilters}
              className="clear"
              variant="text"
              label={filtersText.clear}
            />
          </div>
        </div>
        <div className="filters-container">
          {startDateFilter && (
            <Accordion
              onChange={() =>
                handleExpand(startDateFilter.StartDatePickerText.label)
              }
              expanded={isExpanded(startDateFilter.StartDatePickerText.label)}
              className={cx('accordion-item', classes.accordionRoot)}
              TransitionProps={{ unmountOnExit: true }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                className={cx(
                  illuminateFilter(startDateFilter.dateAdded),
                  classes.accordionSummary,
                )}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className="accordion-item__heading">
                  {startDateFilter.StartDatePickerText.label}
                </Typography>
              </AccordionSummary>
              <AccordionDetails className={classes.accordionDetails}>
                <DatePicker {...startDateFilter.datePickerProps} />
              </AccordionDetails>
            </Accordion>
          )}
          {dateRangeFilter && (
            <Accordion
              onChange={() =>
                handleExpand(dateRangeFilter.DatePickerText.label)
              }
              expanded={isExpanded(dateRangeFilter.DatePickerText.label)}
              className={cx('accordion-item', classes.accordionRoot)}
              TransitionProps={{ unmountOnExit: true }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                className={cx(
                  illuminateFilter(dateRangeFilter.dateRange?.from),
                  classes.accordionSummary,
                )}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className="accordion-item__heading">
                  {dateRangeFilter.DatePickerText.label}
                  {dateRangeFilter.dateRange?.from && (
                    <IconButton
                      onClick={dateRangeFilter.handleClearDateRange}
                      icon={<ClearIcon />}
                      className="icon-l"
                    />
                  )}
                </Typography>
              </AccordionSummary>
              <AccordionDetails className={classes.accordionDetails}>
                <DatePicker {...dateRangeFilter.datePickerProps} />
              </AccordionDetails>
            </Accordion>
          )}
          {durationFilter && (
            <Accordion
              onChange={() => handleExpand(durationFilter.name)}
              expanded={isExpanded(durationFilter.name)}
              className={cx('accordion-item', classes.accordionRoot)}
              TransitionProps={{ unmountOnExit: true }}
            >
              <AccordionSummary
                className={cx(
                  illuminateFilter(durationMinute || durationSeconds),
                  classes.accordionSummary,
                )}
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className="accordion-item__heading">
                  {durationFilter.name}
                </Typography>
              </AccordionSummary>
              <AccordionDetails className={classes.accordionDetails}>
                <Box width="100%" display="flex" justifyContent="space-between">
                  <TextField
                    className="search-input-styles width-45"
                    variant="outlined"
                    type="number"
                    label="Minute"
                    InputProps={{ inputProps: { min: 0 } }}
                    defaultValue={durationMinute || ''}
                    onChange={handleChangeMinuteDuration}
                  />
                  <TextField
                    className="search-input-styles width-45"
                    variant="outlined"
                    type="number"
                    label="Seconds"
                    InputProps={{ inputProps: { min: 0, max: 59 } }}
                    defaultValue={durationSeconds || ''}
                    onChange={handleChangeSecondsDuration}
                  />
                </Box>
              </AccordionDetails>
            </Accordion>
          )}
          {filtersItems.map((item) => (
            <Accordion
              onChange={() => handleExpand(item.name)}
              expanded={isExpanded(item.name)}
              className={cx(
                illuminateFilter(filtersValue[item.name].length),
                classes.accordionRoot,
              )}
              key={item.name}
              TransitionProps={{ unmountOnExit: true }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                className={classes.accordionSummary}
                id="panel1a-header"
              >
                <Typography className="accordion-item__heading">
                  {item.name}
                </Typography>
              </AccordionSummary>
              <AccordionDetails className={classes.accordionDetails}>
                <TableFilterList
                  filterList={item}
                  filtersValue={filtersValue}
                  setFiltersValue={setFiltersValue}
                  value={item.name}
                  resetPagination={resetPagination}
                />
              </AccordionDetails>
            </Accordion>
          ))}
        </div>
        <CampaignListMenu
          isOpen={isOpen}
          anchorEl={anchorEl}
          setAnchorEl={setAnchorEl}
          items={tableColumnsFilter}
          onClick={setTableColumnsFilter}
          initialTableColumnsValue={initialTableColumnsValue}
          exceptions={exceptions}
        />
      </div>
    </>
  );
};

export default TableFilter;
