import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  LinearProgress,
  Typography,
} from '@mui/material';
import { useTypedSelector } from 'hooks';
import React from 'react';

import useDeleteOne from './DeleteOne.hook';

const texts = {
  title: {
    base: `Are you sure you want to delete the user: `,
    userName: (firstName?: string, lastName?: string): string =>
      `${firstName ?? 'first name'} ${lastName ?? 'last name'}`,
  },
  content: 'This user cannot be returned',
  actions: {
    buttons: {
      agree: 'Agree',
      disagree: 'Disagree',
    },
  },
};

const DeleteOne: React.FC = () => {
  const {
    modals,
    loaders,
    allUsersTable: { currentClickedUser },
  } = useTypedSelector((state) => state && state.users);

  const { handleClose, handleDeleteUser } = useDeleteOne();

  return (
    <Dialog
      open={Boolean(modals.deleteOne)}
      onClose={handleClose}
      maxWidth="md"
    >
      {loaders.deleteOne && <LinearProgress />}
      <DialogTitle>
        <Typography variant="h5">
          {texts.title.base}
          <b>
            {texts.title.userName(
              currentClickedUser?.firstName,
              currentClickedUser?.lastName,
            )}
          </b>
        </Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>{texts.content}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          color="error"
          variant="contained"
          onClick={handleClose}
          disabled={loaders.deleteOne}
        >
          {texts.actions.buttons.disagree}
        </Button>
        <Button
          variant="contained"
          onClick={() => handleDeleteUser(currentClickedUser?.id)}
          disabled={loaders.deleteOne}
        >
          {texts.actions.buttons.agree}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteOne;
