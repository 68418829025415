import { Box, Chip, FormHelperText, MenuItem } from '@mui/material';
import { useFormikContext } from 'formik';
import { useTypedSelector } from 'hooks';
import React from 'react';

import { TInitialValues } from '../../../../utils';
import {
  SFormControl,
  SInputLabel,
  SSelect,
} from '../../ManageUserContent.styled';

const NetworkSelect: React.FC = () => {
  const { manageUserData } = useTypedSelector((state) => state && state.users);
  const { values, touched, errors, isSubmitting, handleChange } =
    useFormikContext<TInitialValues>();

  return (
    <SFormControl fullWidth>
      <SInputLabel
        id="user-network-label"
        error={touched.adminNetworks && Boolean(errors.adminNetworks)}
      >
        Network
      </SInputLabel>
      <SSelect
        labelId="user-network-label"
        id="select-user-network"
        name="adminNetworks"
        label="Networks"
        value={values.adminNetworks ?? []}
        multiple
        disabled={!manageUserData.networks.length || isSubmitting}
        onChange={handleChange}
        error={touched.adminNetworks && Boolean(errors.adminNetworks)}
        renderValue={(selected) => (
          <Box
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              gap: 0.5,
            }}
          >
            {selected.map((selectedItem) => {
              const chipValue = manageUserData.networks.find(
                (network) => network.id === selectedItem,
              );

              return (
                chipValue && (
                  <Chip key={chipValue.name} label={chipValue.name} />
                )
              );
            })}
          </Box>
        )}
      >
        {manageUserData.networks.map((adminNetworks) => (
          <MenuItem key={adminNetworks.id} value={adminNetworks.id}>
            {adminNetworks.name}
          </MenuItem>
        ))}
      </SSelect>
      {touched.adminNetworks && Boolean(errors.adminNetworks) && (
        <FormHelperText
          error={touched.adminNetworks && Boolean(errors.adminNetworks)}
        >
          {errors.adminNetworks}
        </FormHelperText>
      )}
    </SFormControl>
  );
};

export default NetworkSelect;
