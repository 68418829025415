import produce from 'immer';

import { ManageSiteActionEnum, TManageSiteActions } from '../../../ActionTypes';
import { IAddSiteState } from './type';

const initialValues: IAddSiteState = {
  isNewCentre: false,
  modals: {},
};

const manageSiteReducer = produce(
  (draft = initialValues, action: TManageSiteActions) => {
    switch (action.type) {
      case ManageSiteActionEnum.SET_IS_NEW_CENTRE: {
        draft.isNewCentre = action.payload;

        return draft;
      }

      case ManageSiteActionEnum.SET_MODALS: {
        draft.modals[action.payload.type] = action.payload.value;

        return draft;
      }
      default: {
        return draft;
      }
    }
  },
  initialValues,
);

export default manageSiteReducer;
