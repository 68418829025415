import { useCallback, useEffect, useState } from 'react';

import { IAdditionallyStaff } from '../AdditionallyStaff';

interface IModalConfigurationHookProps {
  isOpen: IAdditionallyStaff['isOpen'];
  handleOpen: IAdditionallyStaff['handleClose'];
  handleClose: IAdditionallyStaff['handleOpen'];
}

export interface IModalConfigurationHookReturn {
  isOpenLocal: boolean;
  handleModalClose: (closeBlock?: boolean) => void;
  handleModalOpen: () => void;
}

const useModalConfiguration = ({
  isOpen,
  handleClose,
  handleOpen,
}: IModalConfigurationHookProps): IModalConfigurationHookReturn => {
  const [isOpenLocal, setIsOpenLocal] = useState<boolean>(Boolean(isOpen));

  useEffect(() => {
    setIsOpenLocal(Boolean(isOpen));
  }, [isOpen]);

  const handleModalClose = useCallback(
    (closeBlock = false) => {
      if (!closeBlock) {
        if (handleClose) {
          handleClose();

          return;
        }

        setIsOpenLocal(false);
      }
    },
    [handleClose],
  );

  const handleModalOpen = useCallback(() => {
    if (handleOpen) {
      handleOpen();

      return;
    }

    setIsOpenLocal(true);
  }, [handleOpen]);

  return { isOpenLocal, handleModalClose, handleModalOpen };
};

export default useModalConfiguration;
