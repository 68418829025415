import React, { ReactElement } from 'react';

import { SButton } from './Button.styled';

export interface IButtonProps {
  label: string;
  icon?: React.ReactNode;
  className?: string;
  variant?: 'outlined' | 'text' | 'contained';
  color?:
    | 'error'
    | 'success'
    | 'warning'
    | 'info'
    | 'inherit'
    | 'primary'
    | 'secondary'
    | undefined;
  type?: 'button' | 'submit' | 'reset';
  disabled?: boolean;
  disableRipple?: boolean;
  onClick?: (e: React.FormEvent) => void;
  onMouseDown?: () => void;
  endIcon?: boolean;
}

export default function Button({
  label,
  icon,
  className,
  color = 'primary',
  onClick,
  disabled,
  type,
  variant = 'contained',
  disableRipple,
  onMouseDown,
  endIcon = false,
}: IButtonProps): ReactElement {
  return (
    <SButton
      variant={variant}
      color={color}
      className={className}
      startIcon={endIcon ? null : icon}
      endIcon={endIcon ? icon : null}
      onClick={onClick}
      disabled={disabled}
      type={type}
      disableRipple={disableRipple}
      onMouseDown={onMouseDown}
    >
      <div
        className="text-button"
        dangerouslySetInnerHTML={{ __html: `${label}` }}
      />
    </SButton>
  );
}
