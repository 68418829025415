import { CheckCircleOutlineOutlined as CheckCircleOutlineOutlinedIcon } from '@mui/icons-material';
import { Grid } from '@mui/material';
import LogoImage from 'assets/images/login-logo.png';
import BackdropWithLoader from 'components/common/Backdrop';
import Button from 'components/common/Button';
import Input from 'components/common/Input';
import { AlertStatusesEnum } from 'constants/constants';
import {
  ForgotPasswordPageText,
  ResetPasswordPageText,
} from 'constants/text/text';
import { urls } from 'constants/urls/url';
import { RESET_PASSWORD_SCHEMA } from 'helpers/validationSchemas';
import { useActions } from 'hooks/useActions';
import { useTypedSelector } from 'hooks/useTypedSelector';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { Link, Navigate, useLocation } from 'react-router-dom';

interface ISetChecked {
  email: null | string;
  token: null | string;
  password: null | string;
  confirmPassword: null | string;
}

const ResetPasswordPage: React.FC = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { setNewPassword, cleanUpAuthStore } = useActions();
  const {
    hasLoggedIn,
    isResetPasswordLoading,
    isResetPasswordStatus,
    resetPasswordError,
  } = useTypedSelector((state) => state && state.auth);

  const [user, setUser] = useState<ISetChecked>({
    email: '',
    token: '',
    password: '',
    confirmPassword: '',
  });
  const [errors, setErrors] = useState('');

  const location = useLocation();

  useEffect(() => {
    if (resetPasswordError) {
      enqueueSnackbar(String(resetPasswordError), {
        variant: AlertStatusesEnum.ERROR,
      });
    }
  }, [resetPasswordError]);

  useEffect(() => {
    const currUser = {
      email: new URLSearchParams(location.search).get('email'),
      token: new URLSearchParams(location.search).get('token'),
    };
    setUser({ ...user, ...currUser });

    return () => {
      cleanUpAuthStore();
    };
  }, []);

  const inputHandler = ({
    target: { value, name },
  }: React.ChangeEvent<HTMLInputElement>) => {
    setErrors('');
    setUser({ ...user, [name]: value });
  };

  const handleReset = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      await RESET_PASSWORD_SCHEMA.validate(user);
      setNewPassword(user);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      setErrors(err.errors);
    }
  };

  if (hasLoggedIn) {
    return <Navigate to={urls.campaign} />;
  }

  return (
    <div className="login-wrapper">
      <Grid container spacing={0}>
        <Grid item lg={6} xs={12} sm={6}>
          <div className="form-section">
            <div className="content-group">
              <div className="logo-container">
                <img className="logo" src={LogoImage} alt="logo" />
              </div>
              <div>
                <div className="first-header login_header">
                  {ForgotPasswordPageText.header}
                </div>
              </div>
              {!isResetPasswordStatus ? (
                <div className="form-content">
                  <form onSubmit={handleReset}>
                    <div>
                      <div>
                        <div className="reset-container">
                          <div className="reset-input-wrapper">
                            <div className="input-container-reset">
                              <span className="input-label">
                                {ResetPasswordPageText.newPassword}
                              </span>
                              <Input
                                name="password"
                                type="password"
                                className="input-field"
                                onChange={inputHandler}
                                error={Boolean(errors)}
                                helperText={errors}
                              />
                            </div>
                            <div className="input-container-reset">
                              <span className="input-label">
                                {ResetPasswordPageText.repeat}
                              </span>
                              <Input
                                name="confirmPassword"
                                type="password"
                                className="input-field"
                                onChange={inputHandler}
                                error={Boolean(errors)}
                                helperText={errors}
                              />
                            </div>
                          </div>
                          <div className="password-info-box">
                            <div style={{ paddingTop: '15px' }}>
                              <span className="info-title">
                                {ResetPasswordPageText.infoPasswordBox}
                              </span>
                              <ul>
                                <li>{ResetPasswordPageText.passwordLength}</li>
                                <li>{ResetPasswordPageText.passwordType}</li>
                                <li>{ResetPasswordPageText.passwordCapital}</li>
                                <li>{ResetPasswordPageText.passwordSpecial}</li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div className="button-group button-group_top-margin">
                          <Button
                            onClick={handleReset}
                            label={ForgotPasswordPageText.header}
                            icon={<CheckCircleOutlineOutlinedIcon />}
                            className="without-shadow"
                          />
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              ) : (
                <>
                  <div className="input-container">
                    <div className="reset-text">
                      {ResetPasswordPageText.succsedResetPassword}
                    </div>
                  </div>
                  <div className="remember-group">
                    <div>
                      <Link to={urls.login} className="link">
                        {ForgotPasswordPageText.back}
                      </Link>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </Grid>
        <Grid item lg={6} xs={12} sm={6} className="login-image">
          <div className="login-image" />
        </Grid>
      </Grid>
      <BackdropWithLoader open={isResetPasswordLoading} />
    </div>
  );
};

export default ResetPasswordPage;
