import { Box } from '@mui/material';
import { ManageCampaignAddMediaTabsEnum } from 'constants/enums';
import React from 'react';

import { SCanvas, STabPanel } from '../Tabs.styled';
import { AllTabTable } from './components';

const AllTab: React.FC = () => {
  return (
    <STabPanel value={ManageCampaignAddMediaTabsEnum.ALL}>
      <SCanvas>
        <Box>
          <AllTabTable />
        </Box>
      </SCanvas>
    </STabPanel>
  );
};

export default AllTab;
