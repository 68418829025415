export enum SideBarActionTypesEnum {
  TOGGLE_EXPAND = 'TOGGLE_EXPAND',
}

interface IToggleExpand {
  type: SideBarActionTypesEnum.TOGGLE_EXPAND;
  payload: boolean;
}

export type TSideBarAction = IToggleExpand;
