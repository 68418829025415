import { AxiosPromise } from 'axios';
import { Dispatch } from 'redux';

import { AxiosMethodEnum } from '../../../constants/text/text';
import { urlConstructors, urls } from '../../../constants/urls/url';
import { apiCall } from '../../../helpers/api/apiCall';
import { TActionsFileUpload } from './type';

// eslint-disable-next-line max-params-no-constructor/max-params-no-constructor
function postFileApi<T extends object>(
  url: string,
  data: object,
  uid: string,
  callBack: (
    uid: string,
    progressValue: number,
  ) => (dispatch: Dispatch<any>) => void,
  params: object,
): AxiosPromise<T> {
  return apiCall<T>(
    AxiosMethodEnum.post,
    url,
    data,
    params,
    '',
    {},
    uid,
    callBack,
  );
}

function getMethodMedia<T extends object>(
  url: string,
  params: object,
): AxiosPromise<T> {
  return apiCall<T>(AxiosMethodEnum.get, url, '', params, '', {});
}

function renameMediaApi(url: string, params: object): AxiosPromise {
  return apiCall(AxiosMethodEnum.put, url, '', params, '', {});
}

function getMediaFilters<T extends object>(url: string): AxiosPromise<T> {
  return apiCall<T>(AxiosMethodEnum.get, url, '', null, '', {});
}

function deleteMediasApi(url: string, params: object): AxiosPromise {
  return apiCall(AxiosMethodEnum.delete, url, '', params, '', {});
}

function deleteMediaApi(url: string): AxiosPromise {
  return apiCall(AxiosMethodEnum.delete, url, '', null, '', {});
}

export const postFile = <T extends object>(
  data: FormData,
  uid: string,
  callBack: (
    uid: string,
    progressValue: number,
  ) => (dispatch: Dispatch<TActionsFileUpload>) => void,
  params: {
    siteId?: number;
    duration?: number;
  },
): AxiosPromise<T> => postFileApi<T>(urls.media, data, uid, callBack, params);

export const postFileBulk = <T extends object>(
  data: FormData,
  uid: string,
  callBack: (
    uid: string,
    progressValue: number,
  ) => (dispatch: Dispatch<TActionsFileUpload>) => void,
  params: {
    siteIds?: number[];
    duration?: number;
  },
): AxiosPromise<T> =>
  postFileApi<T>(
    urlConstructors.mediaBulkUpload(),
    data,
    uid,
    callBack,
    params,
  );

export const allMedia = <T extends object>(params: object): AxiosPromise<T> =>
  getMethodMedia<T>(urls.media, params);

export const mediaFilters = <T extends object>(): AxiosPromise<T> =>
  getMediaFilters<T>(urlConstructors.mediaFilters());

export const getRecently = <T extends object>(
  params: object,
): AxiosPromise<T> =>
  getMethodMedia<T>(urlConstructors.mediaRecently(), params);

export const getSelected = <T extends object>(data: object): AxiosPromise<T> =>
  getMethodMedia<T>(urlConstructors.mediaSelected(), data);

export const renameSelected = (params: object, id: number): AxiosPromise =>
  renameMediaApi(`${urls.media}/${id}`, params);

export const deleteMediasRequest = (params: object): AxiosPromise =>
  deleteMediasApi(`${urls.media}`, params);

export const deleteMediaRequest = (id: number): AxiosPromise =>
  deleteMediaApi(`${urls.media}/${id}/delete`);
