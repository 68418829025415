import { TabContext } from '@mui/lab';
import { AppBar, Box, LinearProgress, Modal as MuiModal } from '@mui/material';
import React, { SyntheticEvent } from 'react';

import { colors } from '../../../../../../../constants/colors/colors';
import { useTypedSelector } from '../../../../../../../hooks/useTypedSelector';
import { IAdditionallyStaff } from '../../AdditionallyStaff';
import { TabIdsEnum } from '../../constants/enum';
import { useAdditionalStaffQuery } from '../../hooks';
import { IModalConfigurationHookReturn } from '../../hooks/modal.hook';
import { ITabsSwitcherState } from '../../type';
import { Tabs, TabsList } from './components';

const modalContentStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 24,
};

interface IModal {
  isOpen: boolean;
  currentTab: TabIdsEnum;
  tabsSwitcherState: ITabsSwitcherState;
  handleClose: IModalConfigurationHookReturn['handleModalClose'];
  handleTabChange: (event: SyntheticEvent, newIndex: TabIdsEnum) => void;
  ModalProps: IAdditionallyStaff['ModalProps'];
  AppBarProps: IAdditionallyStaff['AppBarProps'];
}

const Modal: React.FC<IModal> = ({
  isOpen,
  currentTab,
  tabsSwitcherState,
  handleClose,
  handleTabChange,
  ModalProps,
  AppBarProps,
}) => {
  useAdditionalStaffQuery({ tabsSwitcherState });

  const { loading: centreLoading } = useTypedSelector(
    (state) => state && state.centre,
  );
  const { loadings: resolutionLoading } = useTypedSelector(
    (state) => state && state.resolution,
  );
  const { loading: networkLoading } = useTypedSelector(
    (state) => state && state.network,
  );

  const isLoading =
    centreLoading.addNew ||
    resolutionLoading.createOne ||
    networkLoading.createOne ||
    resolutionLoading.createOrientation;

  return (
    <MuiModal
      open={isOpen}
      onClose={() => handleClose(isLoading)}
      {...ModalProps}
    >
      <Box sx={modalContentStyle}>
        {isLoading && <LinearProgress />}
        <TabContext value={currentTab}>
          <Box sx={{ bgColor: '#fff', width: 800 }}>
            <AppBar
              position="static"
              sx={{ bgcolor: colors.White }}
              {...AppBarProps}
            >
              <TabsList
                currentTab={currentTab}
                handleChange={handleTabChange}
                tabsSwitcherState={tabsSwitcherState}
              />
              <Tabs
                tabsSwitcherState={tabsSwitcherState}
                handleModalClose={() => handleClose(isLoading)}
              />
            </AppBar>
          </Box>
        </TabContext>
      </Box>
    </MuiModal>
  );
};

export default Modal;
