import { Box, IconButton, ImageList, ImageListItem } from '@mui/material';
import { withStyles } from 'tss-react/mui';

const SImageList = withStyles(ImageList, {
  root: {
    height: '100%',
    width: '100%',
    padding: '2rem',
    margin: 0,
    transform: 'translateZ(0)',
  },
});

const SImageListItem = withStyles(ImageListItem, {
  root: {
    position: 'relative',
    cursor: 'pointer',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: 80,

    '&:hover .MuiIconButton-root': {
      transition: '0.3s',
      opacity: 1,
    },
  },
});

const SBoxSubTitle = withStyles(Box, {
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: 5,
  },
});

const SIconButton = withStyles(IconButton, (theme) => ({
  root: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    opacity: 0,
    transition: '0.3s',
    zIndex: theme.zIndex.drawer + 1,

    '.MuiSvgIcon-root': {
      width: 40,
      height: 40,
    },
  },
}));

export { SBoxSubTitle, SIconButton, SImageList, SImageListItem };
