import React, { useEffect, useState } from 'react';
import { DayPicker } from 'react-day-picker';

import { TMultipleDate } from '../../types';

interface IMultiplePicker {
  defaultDate?: TMultipleDate;
  onSelect?: (date: TMultipleDate) => void;
}

const MultiplePicker: React.FC<IMultiplePicker> = ({
  defaultDate,
  onSelect,
  ...props
}) => {
  const [selected, setSelected] = useState<TMultipleDate>(defaultDate);

  useEffect(() => {
    setSelected(defaultDate);
  }, [defaultDate]);

  useEffect(() => {
    if (onSelect) {
      onSelect(selected);
    }
  }, [selected]);

  const handleSelect = (date: TMultipleDate) => {
    setSelected(date);
  };

  return (
    <DayPicker
      mode="multiple"
      selected={selected}
      onSelect={handleSelect}
      {...props}
    />
  );
};

export default MultiplePicker;
