import { AddCircleOutline as AddCircleOutlineIcon } from '@mui/icons-material';
import { CheckCircle } from 'assets/icons';
import { Button } from 'components/common';
import { useActions, useTypedSelector } from 'hooks';
import React from 'react';

import useAddMedia from '../../hooks/AddMedia.hook';
import { SBox } from './MediaModalFooter.styled';

const MediaModalFooter: React.FC = () => {
  const { addMediaModal } = useTypedSelector(
    (state) => state && state.manageCampaign.manage,
  );
  const { hasLoadedMedia } = useAddMedia();
  const { doneMediaModal, doneMediaModalBulk } = useActions();

  const hasMedia = Boolean(
    addMediaModal.campaignSchedule?.scheduleMedias.length,
  );

  const handleDone = () => {
    if (addMediaModal.isBulk) {
      doneMediaModalBulk();

      return;
    }

    doneMediaModal();
  };

  const buttonLabel = hasMedia ? 'Update' : 'Add Selected';
  const buttonIcon = hasMedia ? <AddCircleOutlineIcon /> : <CheckCircle />;

  return (
    <SBox>
      <Button
        disabled={hasLoadedMedia}
        label={buttonLabel}
        icon={buttonIcon}
        onClick={handleDone}
      />
    </SBox>
  );
};

export default MediaModalFooter;
