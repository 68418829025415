import { ICampaignSchedulesNew, ICampaignSitesNew } from 'types';

import { getCampaignSitesIdsExcludedSubRowSites, parseSubRowsIds } from '..';
import {
  ICheckAdditionalOptions,
  IFindCampaignScheduleReturn,
  ISelectedCampaignSchedulesResult,
} from '../type';

export const checkExistCampaignSchedule = (
  campaignSites: ICampaignSitesNew[],
  { campaignSiteId, campaignScheduleId }: ICheckAdditionalOptions,
): boolean => {
  if (!campaignScheduleId) {
    return false;
  }

  const lookingCampaignSite = campaignSites.find(
    (campaignSite) => campaignSite.id === campaignSiteId,
  );

  if (!lookingCampaignSite) {
    return false;
  }

  const lookingCampaignSchedule = lookingCampaignSite.campaignSchedules.find(
    (campaignSchedule) => campaignSchedule.id === campaignScheduleId,
  );

  if (!lookingCampaignSchedule) {
    return false;
  }

  return true;
};

export const getCampaignSchedule = (
  campaignSites: ICampaignSitesNew[],
  campaignScheduleId: number | string,
): IFindCampaignScheduleReturn => {
  const lookingCampaignSite = campaignSites.find((campaignSite) =>
    campaignSite.campaignSchedules.some(
      (campaignSchedule) => campaignSchedule.id === campaignScheduleId,
    ),
  );

  if (!lookingCampaignSite) {
    throw new Error('Not found campaign schedule');
  }

  const lookingCampaignSchedule = lookingCampaignSite.campaignSchedules.find(
    (campaignSchedule) => campaignSchedule.id === campaignScheduleId,
  );

  if (!lookingCampaignSchedule) {
    throw new Error('Not found campaign schedule');
  }

  return {
    campaignSite: lookingCampaignSite,
    campaignSchedule: lookingCampaignSchedule,
  };
};

export const getCampaignSchedulesIds = (
  campaignSites: ICampaignSitesNew[],
  selectedIds?: Array<string | number>,
): Array<string | number> => {
  if (!selectedIds || !selectedIds.length) {
    return campaignSites.reduce<Array<string | number>>((acc, campaignSite) => {
      const idsList = [...acc];

      campaignSite.campaignSchedules.forEach((campaignSchedule) => {
        idsList.push(campaignSchedule.id);
      });

      return idsList;
    }, []);
  }

  const onlyCampaignId = selectedIds
    .map((id) => parseSubRowsIds(id, 'scheduleId'))
    .filter((id) => id) as Array<string | number>;

  return onlyCampaignId;
};

export const getCampaignSchedulesExcludedSites = (
  campaignSites: ICampaignSitesNew[],
  excludedSites: Array<string | number>,
  selected?: Array<string | number>,
): ICampaignSchedulesNew[] => {
  if (!excludedSites.length) {
    return campaignSites.reduce<ICampaignSchedulesNew[]>(
      (acc, campaignSite) => {
        const schedules = [...acc];

        if (selected?.length) {
          const schedulesIds = selected.map((id) =>
            parseSubRowsIds(id, 'scheduleId'),
          );

          campaignSite.campaignSchedules.forEach((campaignSchedule) => {
            if (schedulesIds.includes(campaignSchedule.id)) {
              schedules.push(campaignSchedule);
            }
          });

          return schedules;
        }

        campaignSite.campaignSchedules.forEach((campaignSchedule) => {
          schedules.push(campaignSchedule);
        });

        return schedules;
      },
      [],
    );
  }

  return campaignSites.reduce<ICampaignSchedulesNew[]>((acc, campaignSite) => {
    const schedules = [...acc];

    if (excludedSites.includes(campaignSite.id)) {
      return schedules;
    }

    if (selected?.length) {
      const schedulesIds = selected.map((id) =>
        parseSubRowsIds(id, 'scheduleId'),
      );
      campaignSite.campaignSchedules.forEach((campaignSchedule) => {
        if (schedulesIds.includes(campaignSchedule.id)) {
          schedules.push(campaignSchedule);
        }
      });

      return schedules;
    }

    campaignSite.campaignSchedules.forEach((campaignSchedule) => {
      schedules.push(campaignSchedule);
    });

    return schedules;
  }, []);
};

export const getSelectedCampaignSchedules = (
  campaignSites: ICampaignSitesNew[],
  selectedIds: Array<string | number>,
): ISelectedCampaignSchedulesResult => {
  const onlySitesNoSubRowId =
    getCampaignSitesIdsExcludedSubRowSites(selectedIds);

  const schedules = getCampaignSchedulesExcludedSites(
    campaignSites,
    onlySitesNoSubRowId,
    selectedIds,
  );

  const scheduleIds = schedules.map((schedule) => schedule.id);

  const result = campaignSites.reduce<ICampaignSchedulesNew[]>(
    (acc, campaignSite) => {
      const campaignSchedules = [...acc];

      if (onlySitesNoSubRowId.includes(campaignSite.id)) {
        campaignSite.campaignSchedules.forEach((campaignSchedule) => {
          campaignSchedules.push(campaignSchedule);
        });

        return campaignSchedules;
      }

      campaignSite.campaignSchedules.forEach((campaignSchedule) => {
        if (scheduleIds.includes(campaignSchedule.id)) {
          campaignSchedules.push(campaignSchedule);
        }
      });

      return campaignSchedules;
    },
    [],
  );

  return {
    selectedCampaignSchedules: result,
    ids: result.map((item) => item.id),
  };
};
