import axios, { AxiosPromise, AxiosRequestConfig, Method } from 'axios';
import { LocalStorageKeysEnum } from 'constants/constants';
import qs from 'qs';
import { Dispatch } from 'redux';
import { TMediaAction } from 'redux/ActionTypes/mediaTypes';

import { IHeaders } from './type';

export const apiCall = <T = unknown>(
  method: Method,
  url: string,
  data?: object | string,
  params?: object | null,
  host?: string,
  headers?: object,
  uid?: string,
  callBack?: (
    uid: string,
    progressValue: number,
  ) => (dispatch: Dispatch<TMediaAction>) => void,
  otherConfig?: AxiosRequestConfig,
): // eslint-disable-next-line max-params-no-constructor/max-params-no-constructor
AxiosPromise<T> => {
  const loginToken = localStorage.getItem(LocalStorageKeysEnum.jwtToken);
  const baseApi = host || process.env.REACT_APP_BASE_API;
  const allHeaders: IHeaders = {
    'Content-Type': 'application/json',
    ...headers,
  };

  if (loginToken) {
    allHeaders.Authorization = `Bearer ${loginToken}`;
  }

  return axios({
    method,
    url,
    data,
    params,
    baseURL: baseApi,
    headers: allHeaders,
    paramsSerializer: (param) => qs.stringify(param),
    onUploadProgress(progressEvent) {
      if (uid && callBack) {
        const progress = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total - 1,
        );
        callBack(uid, progress);
      }
    },
    ...otherConfig,
  });
};
