import { Select } from 'components/common';
import { useFormikContext } from 'formik';
import { convertToSelectData } from 'helpers';
import { useActions, useTypedSelector } from 'hooks';
import React, { useEffect } from 'react';
import { SingleValue } from 'react-select';

import { texts } from '../../../../../../constants/texts';
import { IInitialValues } from '../../../../types';

const { fromObjectIdWithName } = convertToSelectData;

const Networks: React.FC = () => {
  const { values, errors, isSubmitting, setFieldValue } =
    useFormikContext<IInitialValues>();
  const { networks, loading } = useTypedSelector(
    (state) => state && state.network,
  );

  const { getNetworks } = useActions();

  const handleNetworkChange = (
    newValue: SingleValue<IInitialValues['network']>,
  ) => {
    setFieldValue('network', newValue);
  };

  useEffect(() => {
    getNetworks();
  }, []);

  return (
    <div className="network-wrapper">
      <Select
        label={texts.pages.manage.content.centreNetworks.selects.networks}
        placeholder={texts.pages.manage.content.centreNetworks.selects.networks}
        options={fromObjectIdWithName(networks)}
        value={values.network}
        onChange={handleNetworkChange}
        isLoading={loading.getAll}
        error={Boolean(errors.network)}
        helperText={errors.network}
        isDisabled={isSubmitting}
        isClearable
      />
    </div>
  );
};

export default Networks;
