import { AddCircleOutline as AddCircleOutlineIcon } from '@mui/icons-material';
import React, { ReactNode } from 'react';

import { SAddButton } from './Selects.styled';

interface ISelectsHookReturn {
  formatCreateLabel: (value: string) => ReactNode;
}

const useSelects = (): ISelectsHookReturn => {
  const formatCreateLabel = (inputValue: string) => {
    return (
      <SAddButton
        label={`Create ${inputValue}`}
        icon={<AddCircleOutlineIcon />}
        variant="text"
      />
    );
  };

  return { formatCreateLabel };
};

export default useSelects;
