import { UserRolesEnum } from '../../../types';

export interface IAuthState {
  user: any[];
  isLoading: boolean;
  error: null | boolean;
  hasLoggedIn: boolean;
  jwtToken: null | string;
  role: null | UserRolesEnum;
  errorMessage: boolean | string;
  forgotError: boolean | string;
  isForgotLoading: boolean;
  isForgotPasswordStatus: boolean;
  isResetPasswordStatus: boolean;
  isResetPasswordLoading: boolean;
  resetPasswordError: boolean | string;
  isSendMagicLinkLoading: boolean;
  isSendMagicLinkStatus: boolean;
  sendMagicLinkError: boolean | string;
}

export enum AuthActionTypesEnum {
  LOGIN_LOADING = 'LOGIN_LOADING',
  LOGIN_SUCCESS = 'LOGIN_SUCCESS',
  LOGIN_WITH_NEW_PASSWORD_SUCCESS = 'LOGIN_WITH_NEW_PASSWORD_SUCCESS',
  LOGIN_WITH_MAGIC_LINK_SUCCESS = 'LOGIN_WITH_MAGIC_LINK_SUCCESS',
  LOGIN_ERROR = 'LOGIN_ERROR',
  IS_LOGGED = 'IS_LOGGED',
  IS_UNAUTHORIZE_USER = 'IS_UNAUTHORIZE_USER',
  FORGOT_PASSWORD_LOADING = 'FORGOT_PASSWORD_LOADING',
  FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS',
  FORGOT_PASSWORD_ERROR = 'FORGOT_PASSWORD_ERROR',
  RESET_PASSWORD_LOADING = 'RESET_PASSWORD_LOADING',
  RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS',
  RESET_PASSWORD_ERROR = 'RESET_PASSWORD_ERROR',
  SEND_MAGIC_LINK_LOADING = 'SEND_MAGIC_LINK_LOADING',
  SEND_MAGIC_LINK_SUCCESS = 'SEND_MAGIC_LINK_SUCCESS',
  SEND_MAGIC_LINK_ERROR = 'SEND_MAGIC_LINK_ERROR',
  CLEAN_UP_AUTH_STORE = 'CLEAN_UP_AUTH_STORE',
  SIGN_OUT = 'SIGN_OUT',
}

interface ILoginLoadingAction {
  type: AuthActionTypesEnum.LOGIN_LOADING;
}
interface IResetLoadingAction {
  type: AuthActionTypesEnum.RESET_PASSWORD_LOADING;
}
interface ILoginSuccessAction {
  type: AuthActionTypesEnum.LOGIN_SUCCESS;
  payload: any;
}
interface ILoginNewPasswordAction {
  type: AuthActionTypesEnum.LOGIN_WITH_NEW_PASSWORD_SUCCESS;
}

interface ILoginWithMagicLinkAction {
  type: AuthActionTypesEnum.LOGIN_WITH_MAGIC_LINK_SUCCESS;
  payload: {
    jwtToken: string;
  };
}
interface ILoginErrorAction {
  type: AuthActionTypesEnum.LOGIN_ERROR;
  payload: boolean | string;
}
interface IIsLoggedAction {
  type: AuthActionTypesEnum.IS_LOGGED;
  payload: {
    jwtToken: string;
    role: UserRolesEnum;
  };
}
interface IIsUnauthorizeAction {
  type: AuthActionTypesEnum.IS_UNAUTHORIZE_USER;
}
interface IForgotPasswordLoadingAction {
  type: AuthActionTypesEnum.FORGOT_PASSWORD_LOADING;
}
interface IResetPasswordSuccessAction {
  type: AuthActionTypesEnum.RESET_PASSWORD_SUCCESS;
}
interface IResetPasswordErrorAction {
  type: AuthActionTypesEnum.RESET_PASSWORD_ERROR;
  payload: boolean | string;
}
interface IForgotPasswordSuccessAction {
  type: AuthActionTypesEnum.FORGOT_PASSWORD_SUCCESS;
}
interface IForgotPasswordErrorAction {
  type: AuthActionTypesEnum.FORGOT_PASSWORD_ERROR;
  payload: boolean | string;
}

interface ISendMagicLinkActionLoadingAction {
  type: AuthActionTypesEnum.SEND_MAGIC_LINK_LOADING;
}
interface ISendMagicLinkActionSuccessAction {
  type: AuthActionTypesEnum.SEND_MAGIC_LINK_SUCCESS;
}
interface ISendMagicLinkActionErrorAction {
  type: AuthActionTypesEnum.SEND_MAGIC_LINK_ERROR;
  payload: boolean | string;
}

interface ISignOutAction {
  type: AuthActionTypesEnum.SIGN_OUT;
}

interface ICleanUpAction {
  type: AuthActionTypesEnum.CLEAN_UP_AUTH_STORE;
}

export type TAuthAction =
  | ILoginLoadingAction
  | ILoginSuccessAction
  | ILoginNewPasswordAction
  | ILoginWithMagicLinkAction
  | ILoginErrorAction
  | IIsLoggedAction
  | IIsUnauthorizeAction
  | IForgotPasswordLoadingAction
  | IForgotPasswordLoadingAction
  | IResetPasswordSuccessAction
  | IResetPasswordErrorAction
  | IResetLoadingAction
  | IForgotPasswordSuccessAction
  | IForgotPasswordErrorAction
  | ISendMagicLinkActionLoadingAction
  | ISendMagicLinkActionSuccessAction
  | ISendMagicLinkActionErrorAction
  | ISignOutAction
  | ICleanUpAction;
