import {
  LocalStorageKeysEnum,
  SessionStorageKeysEnum,
} from '../constants/constants';
import { StorageTypeEnum } from '../types';

type TStorageKeysBase =
  | keyof typeof LocalStorageKeysEnum
  | keyof typeof SessionStorageKeysEnum;

function getFromStorage<T>(
  item: keyof typeof LocalStorageKeysEnum,
  storage: StorageTypeEnum.localStorage,
): T | null;
function getFromStorage<T>(
  item: keyof typeof SessionStorageKeysEnum,
  storage: StorageTypeEnum.sessionStorage,
): T | null;
function getFromStorage<T>(
  item: TStorageKeysBase,
  storage: StorageTypeEnum,
): T | null {
  switch (storage) {
    case StorageTypeEnum.localStorage: {
      const data = localStorage.getItem(item);

      if (!data) {
        return null;
      }

      try {
        return JSON.parse(data);
      } catch {
        return data as unknown as T;
      }
    }
    case StorageTypeEnum.sessionStorage: {
      const data = sessionStorage.getItem(item);

      if (!data) {
        return null;
      }

      try {
        return JSON.parse(data);
      } catch {
        return data as unknown as T;
      }
    }

    default:
      return null;
  }
}

function setToStorage<T extends object = object>(
  params: T,
  storage: StorageTypeEnum = StorageTypeEnum.localStorage,
): void {
  if (!Object.keys(params).length) {
    return;
  }

  switch (storage) {
    case StorageTypeEnum.localStorage: {
      Object.entries(params).forEach(([key, value]) => {
        localStorage.setItem(key, value);
      });
      break;
    }
    case StorageTypeEnum.sessionStorage: {
      Object.entries(params).forEach(([key, value]) => {
        sessionStorage.setItem(key, value);
      });
      break;
    }
    default:
      break;
  }
}

export { getFromStorage, setToStorage };
