import { Dispatch } from 'redux';
import {
  IDefaultDtoExtended,
  IMedia,
  IMediaRecentlyDto,
  IMediaSelectedDto,
} from 'types';

import { MediaActionTypesEnum, TMediaAction } from '../../ActionTypes';
import {
  allMedia,
  deleteMediaRequest,
  deleteMediasRequest,
  getRecently,
  getSelected,
  mediaFilters,
} from './mediaApi';

export const getRecentlyMedia =
  (
    tags: string,
    ids?: number[],
    screenSize?: string | null,
    formats?: string | null,
  ) =>
  async (dispatch: Dispatch<TMediaAction>): Promise<void> => {
    try {
      const params = {
        ids,
        tags,
        screenSize,
        formats,
      };
      dispatch({ type: MediaActionTypesEnum.GET_RECENTLY_MEDIA_LOADING });
      const {
        data: { data },
      } = await getRecently<IMediaRecentlyDto>(params);
      dispatch({
        type: MediaActionTypesEnum.GET_RECENTLY_MEDIA_SUCCESS,
        payload: data,
      });
    } catch (error: any) {
      dispatch({
        type: MediaActionTypesEnum.GET_RECENTLY_MEDIA_ERROR,
        payload: !!error,
      });
    }
  };

export const getSelectedMedia =
  (ids: number[]) =>
  async (dispatch: Dispatch<TMediaAction>): Promise<void> => {
    try {
      dispatch({ type: MediaActionTypesEnum.GET_SELECTED_MEDIA_LOADING });
      const {
        data: { data },
      } = await getSelected<IMediaSelectedDto>({ ids });
      dispatch({
        type: MediaActionTypesEnum.GET_SELECTED_MEDIA_SUCCESS,
        payload: data,
      });
    } catch (error: any) {
      dispatch({
        type: MediaActionTypesEnum.GET_SELECTED_MEDIA_ERROR,
        payload: !!error,
      });
    }
  };

export const getMedia =
  (params: object) =>
  async (dispatch: Dispatch<TMediaAction>): Promise<void> => {
    try {
      dispatch({ type: MediaActionTypesEnum.GET_MEDIA_LOADING });
      const { data } = await allMedia<IDefaultDtoExtended<IMedia[]>>(params);

      const payload = {
        medias: data.data,
        pageSize: data.pageSize,
        pageNumber: data.pageNumber,
        totalItem: data.totalCount,
      };
      dispatch({ type: MediaActionTypesEnum.GET_MEDIA_SUCCESS, payload });
    } catch (error: any) {
      dispatch({
        type: MediaActionTypesEnum.GET_MEDIA_ERROR,
        payload: !!error,
      });
    }
  };

export const getMediaFilters =
  () =>
  async (dispatch: Dispatch<TMediaAction>): Promise<void> => {
    try {
      dispatch({ type: MediaActionTypesEnum.GET_MEDIA_FILTERS_LOADING });
      const { data } = await mediaFilters();
      dispatch({
        type: MediaActionTypesEnum.GET_MEDIA_FILTERS_SUCCESS,
        payload: data,
      });
    } catch (error: any) {
      dispatch({
        type: MediaActionTypesEnum.GET_MEDIA_FILTERS_ERROR,
        payload: !!error,
      });
    }
  };

export const deleteMedias =
  (ids: number[]) =>
  async (dispatch: Dispatch<TMediaAction>): Promise<void> => {
    try {
      const params = { ids };
      await deleteMediasRequest(params);
      dispatch({
        type: MediaActionTypesEnum.REMOVE_MEDIAS_SUCCESS,
        payload: ids,
      });
      dispatch({
        type: MediaActionTypesEnum.MEDIA_LENGTH,
        payload: ids.length,
      });
    } catch (error: any) {
      dispatch({
        type: MediaActionTypesEnum.REMOVE_MEDIAS_ERROR,
        payload: !!error,
      });
    }
  };

export const deleteMedia =
  (id: number) =>
  async (dispatch: Dispatch<TMediaAction>): Promise<void> => {
    try {
      await deleteMediaRequest(id);
      dispatch({
        type: MediaActionTypesEnum.REMOVE_MEDIA_SUCCESS,
        payload: id,
      });
      dispatch({ type: MediaActionTypesEnum.MEDIA_LENGTH, payload: 1 });
    } catch (error: any) {
      dispatch({
        type: MediaActionTypesEnum.REMOVE_MEDIA_ERROR,
        payload: !!error,
      });
    }
  };
