import {
  ArrowBack as ArrowBackIcon,
  Save as SaveIcon,
} from '@mui/icons-material';
import { Form, Formik } from 'formik';
import React, { Fragment } from 'react';

import Button from '../../../../components/common/Button';
import { validationSchema } from '../../constants/validateSchema';
import { disabledSaveButtonInspector } from '../../helpers/inspectors';
import { SitesHeader } from '..';
import { Content, Modals } from './components';
import useSiteForm from './SiteForm.hook';
import { IInitialValues } from './types';

interface ISiteFormProps {
  initialValues: IInitialValues;
  title: string;
}

const SiteForm: React.FC<ISiteFormProps> = ({ initialValues, title }) => {
  const { onSubmit, handleClickGoBack } = useSiteForm();

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnChange={false}
      validateOnMount={false}
      onSubmit={onSubmit}
    >
      {({ values, isSubmitting }) => (
        <Fragment>
          <Form>
            <div className="sites-container">
              <SitesHeader title={title}>
                <div className="__children-many-element-default-container">
                  <Button
                    label="Go back"
                    color="error"
                    icon={<ArrowBackIcon />}
                    onClick={handleClickGoBack}
                    disabled={isSubmitting}
                  />
                  <Button
                    type="submit"
                    label="Save"
                    icon={<SaveIcon />}
                    disabled={
                      disabledSaveButtonInspector(values) || isSubmitting
                    }
                  />
                </div>
              </SitesHeader>
            </div>
            <Content />
          </Form>
          <Modals />
        </Fragment>
      )}
    </Formik>
  );
};

export default SiteForm;
