export const texts = {
  centre: {
    input: 'Centre Name',
    formName: 'centreName',
    selectState: 'State',
  },
  network: {
    name: 'networkName',
    label: 'Network',
  },
  orientation: {
    name: 'orientationName',
    label: 'Orientation',
  },
  suborganisation: {
    name: 'suborganisationName',
    label: 'Suborganisation',
  },
  resolution: {
    width: {
      label: 'Width',
      name: 'width',
    },
    height: {
      label: 'Height',
      name: 'height',
    },
    orientation: 'Orientation',
  },
  actions: {
    submit: 'Create',
    close: 'Close',
  },
} as const;

export const messages = {
  required: 'This Field is required',
  onlyNumber: 'This field should contain only numbers',
  forbiddenSymbols: 'It is forbidden to use incorrect characters',
  min: (length: number) => `This Field should be more than ${length}`,
  max: (length: number) => `This Field should be less than ${length}`,
  success: (name: string) => `${name} successfully added`,
  error: 'Something went wrong',
} as const;
