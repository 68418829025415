import { Dispatch } from 'redux';

import { LocalStorageKeysEnum } from '../../../constants/constants';
import { AxiosMethodEnum } from '../../../constants/text/text';
import { apiCall } from '../../../helpers/api/apiCall';
import {
  IDefaultDto,
  IObjectIdWithName,
  IOrganisationChangeDto,
  IOrganisationCurrentDto,
} from '../../../types';
import {
  AuthActionTypesEnum,
  TAuthAction,
} from '../../ActionTypes/authTypes/authTypes';
import {
  OrganisationActionTypesEnum,
  TOrganisationAction,
} from '../../ActionTypes/organisationTypes/organisationTypes';
import {
  createSubOrganisationApi,
  getCurrentOrganisation,
  getSubOrganisationsApi,
} from './organisationApi';

export const getOrganisation =
  (id?: number) =>
  async (
    dispatch: Dispatch<TOrganisationAction | TAuthAction>,
  ): Promise<void> => {
    try {
      if (id) {
        const {
          data: {
            data: { jwtToken },
          },
        } = await apiCall<IOrganisationChangeDto>(
          AxiosMethodEnum.post,
          `change-org?organisationId=${id}`,
        );
        localStorage.setItem(LocalStorageKeysEnum.jwtToken, jwtToken);
        localStorage.setItem(
          LocalStorageKeysEnum.currentOrganisation,
          String(id),
        );
      }
      dispatch({ type: OrganisationActionTypesEnum.GET_ORGANISATION_LOADING });
      const {
        data: { data },
      } = await getCurrentOrganisation<IOrganisationCurrentDto>();
      dispatch({
        type: OrganisationActionTypesEnum.GET_ORGANISATION_SUCCESS,
        payload: data,
      });
    } catch (error: any) {
      localStorage.removeItem(LocalStorageKeysEnum.jwtToken);
      localStorage.removeItem(LocalStorageKeysEnum.currentOrganisation);
      dispatch({ type: AuthActionTypesEnum.CLEAN_UP_AUTH_STORE });
      dispatch({
        type: OrganisationActionTypesEnum.GET_ORGANISATION_ERROR,
        payload: error as any,
      });
    }
  };

export const getSubOrganisation =
  () =>
  async (dispatch: Dispatch<TOrganisationAction>): Promise<void> => {
    try {
      dispatch({
        type: OrganisationActionTypesEnum.SET_LOADING,
        payload: { type: 'getSuborganisation', value: true },
      });
      const {
        data: { data },
      } = await getSubOrganisationsApi<IDefaultDto<IObjectIdWithName[]>>();
      dispatch({
        type: OrganisationActionTypesEnum.SET_SUBORGANISATIONS,
        payload: data,
      });
    } catch (error: any) {
      const message = error?.response?.data?.UserMessage;

      console.error(message);
      throw new Error(message);
    } finally {
      dispatch({
        type: OrganisationActionTypesEnum.SET_LOADING,
        payload: { type: 'getSuborganisation', value: false },
      });
    }
  };

export const createSuborganisation =
  (name: string) =>
  async (dispatch: Dispatch<TOrganisationAction>): Promise<void> => {
    try {
      dispatch({
        type: OrganisationActionTypesEnum.SET_LOADING,
        payload: { type: 'createSuborganisation', value: true },
      });
      await createSubOrganisationApi({ name });
    } catch (error: any) {
      const message = error?.response?.data?.UserMessage || error?.message;

      console.error(message);
      throw new Error(error);
    } finally {
      dispatch({
        type: OrganisationActionTypesEnum.SET_LOADING,
        payload: { type: 'createSuborganisation', value: false },
      });
    }
  };

export const cleanupCurrent =
  () =>
  (dispatch: Dispatch<TOrganisationAction>): void => {
    dispatch({ type: OrganisationActionTypesEnum.CLEANUP_CURRENT });
    localStorage.removeItem(LocalStorageKeysEnum.currentOrganisation);
  };
