import { LocalStorageKeysEnum } from '../constants/constants';
import { TNullable, UserRolesEnum } from '../types';

export const isAdmin = (role: TNullable<UserRolesEnum>): boolean =>
  role === UserRolesEnum.Admin || role === UserRolesEnum.SuperAdmin;

export const isAdminOrEditor = (role: TNullable<UserRolesEnum>): boolean =>
  isAdmin(role) || role === UserRolesEnum.Editor;

export const isAdminLs = (): boolean =>
  localStorage
    .getItem(LocalStorageKeysEnum.userRole)
    ?.includes(UserRolesEnum.Admin) ?? false;

export const isMarketing = (role: TNullable<UserRolesEnum>): boolean =>
  role === UserRolesEnum.Marketing;

export const isRole = (
  expectedRoles: UserRolesEnum[] | UserRolesEnum,
  role: TNullable<UserRolesEnum>,
): boolean => {
  if (!role) {
    return false;
  }

  if (Array.isArray(expectedRoles)) {
    return expectedRoles.includes(role);
  }

  return expectedRoles === role;
};

export const isRoleExcept = (
  expectedRoles: UserRolesEnum[] | UserRolesEnum,
  role: TNullable<UserRolesEnum>,
): boolean => {
  if (!role) {
    return false;
  }

  if (Array.isArray(expectedRoles)) {
    return !expectedRoles.includes(role);
  }

  return expectedRoles !== role;
};
