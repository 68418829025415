import { Close as CloseIcon } from '@mui/icons-material';
import { Chip, IconButton, Tooltip } from '@mui/material';
import { ColumnDef } from '@tanstack/react-table';
import classnames from 'classnames';
import React from 'react';

import { IUserShort } from '../../../../../../../types';

interface IColumnsProps {
  data: IUserShort[];
  handleRemove: (id: number) => void;
  removeDisabled: boolean;
}

export const getColumns = ({
  data,
  handleRemove,
  removeDisabled,
}: IColumnsProps): ColumnDef<IUserShort>[] => {
  return [
    {
      id: 'User Name',
      accessorKey: 'userName',
      minSize: 150,
      header: () => (
        <div className={classnames('__cell_block', '__cell_block_header')}>
          User Name
        </div>
      ),
      cell: ({ getValue }) => {
        return <>{getValue()}</>;
      },
      enableSorting: true,
    },
    {
      id: 'Email',
      accessorKey: 'email',
      minSize: 150,
      header: () => (
        <div className={classnames('__cell_block', '__cell_block_header')}>
          Email
        </div>
      ),
      cell: ({ getValue }) => {
        return <>{getValue()}</>;
      },
      enableSorting: true,
    },
    {
      id: 'Role',
      accessorKey: 'role',
      minSize: 150,
      header: () => (
        <div
          className={classnames(
            '__cell_block',
            '__cell_block_header',
            '__center',
          )}
        >
          Role
        </div>
      ),
      cell: ({ getValue }) => {
        return (
          <div
            className={classnames(
              '__cell_block',
              '__cell_block_body',
              '__center_horizontal',
              '__table_cell_ps20',
            )}
          >
            <Chip
              label={getValue() as string}
              sx={{ width: '100%' }}
              className="__max_width-160"
            />
          </div>
        );
      },
      enableSorting: true,
    },
    {
      id: 'Remove Selecting User',
      maxSize: 50,
      cell: ({ row }) => (
        <div>
          <Tooltip title="Remove user from list">
            <span>
              <IconButton
                color="error"
                disabled={data.length <= 1 || removeDisabled}
                onClick={() => handleRemove(row.original.id)}
              >
                <CloseIcon />
              </IconButton>
            </span>
          </Tooltip>
        </div>
      ),
    },
  ];
};
