import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import { CheckCircle } from 'assets/icons';
import { Button } from 'components/common';
import { useTypedSelector } from 'hooks';
import React from 'react';

import ScheduleModalDate from './components/Date/ScheduleModalDate';
import DayParts from './components/DayParts/DayParts';
import useScheduleModal from './ScheduleModal.hook';
import { SAnotherDayPartButton } from './ScheduleModal.styled';

const ScheduleModal: React.FC = () => {
  const { modals } = useTypedSelector(
    (state) => state && state.manageCampaign.manage,
  );
  const {
    handleClose,
    handleDone,
    handleAnotherDayPart,
    getIsScheduleContain,
  } = useScheduleModal();

  return (
    <Dialog
      open={modals.campaignSchedule}
      fullWidth
      maxWidth="md"
      onClose={handleClose}
    >
      <DialogTitle sx={{ textAlign: 'center' }}>
        Set campaign duration
      </DialogTitle>
      <DialogContent>
        <ScheduleModalDate />
        <DayParts />
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'center' }}>
        <SAnotherDayPartButton
          label="Add another day part"
          variant="text"
          onClick={handleAnotherDayPart}
        />
        <Button
          label="Done"
          onClick={handleDone}
          icon={<CheckCircle className="white-svg icon-s done-icon" />}
          disabled={!getIsScheduleContain()}
        />
      </DialogActions>
    </Dialog>
  );
};

export default ScheduleModal;
