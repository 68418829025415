import './ManageUser.scss';

import { Dialog, DialogContent, LinearProgress, Zoom } from '@mui/material';
import { LocalLoading } from 'components/Loading/index';
import { Form, Formik } from 'formik';
import { useActions, useTypedSelector } from 'hooks';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useEffectOnce } from 'usehooks-ts';

import {
  ManageUserActions,
  ManageUserContent,
  ManageUserTitle,
} from './components';
import { validationSchema } from './constants/validationSchema';
import useManageUserModal from './ManageUser.hook';

const ManageUserModal: React.FC = () => {
  const { modals, loaders } = useTypedSelector((state) => state && state.users);
  const {
    currentWindowParam: { isSmall },
  } = useTypedSelector((state) => state && state.app);

  const { getManageUserData } = useActions();

  const { enqueueSnackbar } = useSnackbar();

  useEffectOnce(() => {
    getManageUserData(enqueueSnackbar);
  });

  const {
    initialValues,
    roleNames,
    isLoadingManageUser,
    handleModalClose,
    handleManageUserSubmit,
  } = useManageUserModal();

  return (
    <Dialog
      open={Boolean(modals.manageUser)}
      onClose={handleModalClose}
      TransitionComponent={Zoom}
      maxWidth="md"
      fullScreen={isSmall}
    >
      {loaders.getOne ? (
        <DialogContent>
          <LocalLoading />
        </DialogContent>
      ) : (
        <Formik
          onSubmit={handleManageUserSubmit}
          initialValues={initialValues}
          validationSchema={validationSchema(roleNames)}
        >
          <Form className="manage-user-form">
            {isLoadingManageUser && <LinearProgress />}
            <ManageUserTitle />
            <ManageUserContent />
            <ManageUserActions />
          </Form>
        </Formik>
      )}
    </Dialog>
  );
};

export default ManageUserModal;
