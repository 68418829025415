import { dateFnsFormats } from 'constants/date/date';
import { format } from 'date-fns';
import { removeTimezone } from 'helpers';
import { Dispatch } from 'redux';

import { IDefaultDto, IDefaultDtoExtended } from '../../../../types';
import { ISingleSite, ISites } from '../../../../types/site';
import {
  SitesActionTypesEnum,
  TSitesAction,
} from '../../../ActionTypes/sitesTypes/main/main';
import {
  allSites,
  editCampaignSites,
  getSiteByIdApi,
  selectedSitesRequest,
  sitesFilters,
  sitesSchedulesApi,
} from '../sitesApi';
import { ISitesSchedulesPayload } from '../type';
import { ISetModalsPayload } from './type';

export const getSites =
  (params: object) =>
  async (dispatch: Dispatch<TSitesAction>): Promise<void> => {
    try {
      dispatch({ type: SitesActionTypesEnum.GET_SITES_LOADING });
      const { data } = await allSites<IDefaultDtoExtended<ISites[]>>(params);

      const payload = {
        sites: data.data,
        pageNumber: data.pageNumber,
        pageSize: data.pageSize,
        totalItems: data.totalCount,
      };

      dispatch({
        type: SitesActionTypesEnum.GET_SITES_SUCCESS,
        payload,
      });
    } catch (error: any) {
      dispatch({
        type: SitesActionTypesEnum.GET_SITES_ERROR,
        payload: error as any,
      });
    }
  };

export const getSiteById =
  (id: number | string) =>
  async (dispatch: Dispatch<TSitesAction>): Promise<void> => {
    try {
      dispatch({
        type: SitesActionTypesEnum.SET_LOADING,
        payload: { type: 'getOne', value: true },
      });
      const {
        data: { data },
      } = await getSiteByIdApi<IDefaultDto<ISingleSite>>(id);

      dispatch({ type: SitesActionTypesEnum.SET_SINGLE_SITE, payload: data });
    } catch (error) {
      console.error(error);
    } finally {
      dispatch({
        type: SitesActionTypesEnum.SET_LOADING,
        payload: { type: 'getOne', value: false },
      });
    }
  };

export const setCurrentSiteId =
  (id: number) =>
  (dispatch: Dispatch<TSitesAction>): void => {
    dispatch({ type: SitesActionTypesEnum.SET_CURRENT_SITE_ID, payload: id });
  };

export const getSelectedSites =
  (ids: number[]) =>
  async (dispatch: Dispatch<TSitesAction>): Promise<void> => {
    try {
      const params = {
        ids,
      };
      dispatch({ type: SitesActionTypesEnum.GET_SELECTED_SITES_LOADING });
      const { data } = await selectedSitesRequest(params);
      dispatch({
        type: SitesActionTypesEnum.GET_SELECTED_SITES_SUCCESS,
        payload: data,
      });
    } catch (error: any) {
      dispatch({
        type: SitesActionTypesEnum.GET_SELECTED_SITES_ERROR,
        payload: error as any,
      });
    }
  };

export const getSitesFilters =
  (selectedIds: number[]) =>
  async (dispatch: Dispatch<TSitesAction>): Promise<void> => {
    try {
      dispatch({ type: SitesActionTypesEnum.GET_SITES_FILTERS_LOADING });
      const { data } = await sitesFilters(selectedIds);
      dispatch({
        type: SitesActionTypesEnum.GET_SITES_FILTERS_SUCCESS,
        payload: data,
      });
    } catch (error: any) {
      dispatch({
        type: SitesActionTypesEnum.GET_SITES_FILTERS_ERROR,
        payload: error as any,
      });
    }
  };

export const getEditCampaignSites =
  (params: object) =>
  async (dispatch: Dispatch<TSitesAction>): Promise<void> => {
    try {
      dispatch({
        type: SitesActionTypesEnum.GET_EDIT_CAMPAIGNS_SITES_LOADING,
      });
      const { data } = await editCampaignSites(params);
      dispatch({
        type: SitesActionTypesEnum.GET_EDIT_CAMPAIGNS_SITES_SUCCESS,
        payload: data,
      });
    } catch (error: any) {
      dispatch({
        type: SitesActionTypesEnum.GET_EDIT_CAMPAIGNS_SITES_ERROR,
        payload: error as any,
      });
    }
  };

export const siteSchedules =
  (data: ISitesSchedulesPayload<Date>) =>
  async (dispatch: Dispatch<TSitesAction>): Promise<void> => {
    try {
      dispatch({
        type: SitesActionTypesEnum.SET_LOADING,
        payload: { type: 'schedules', value: true },
      });

      const payload = {
        id: data.id,
        startDate: removeTimezone(data.startDate).toISOString(),
        endDate: removeTimezone(data.endDate).toISOString(),
      };

      const { data: pdfFile } = await sitesSchedulesApi<BlobPart>(payload);
      const {
        data: { data: site },
      } = await getSiteByIdApi<IDefaultDto<ISingleSite>>(data.id);

      const pdfBlob = new Blob([pdfFile], { type: 'application/pdf' });
      const url = URL.createObjectURL(pdfBlob);

      const dummy = document.createElement('a');
      dummy.href = url;
      dummy.download = `${site.name} [${format(
        data.startDate,
        dateFnsFormats.default('.'),
      )}] to [${format(data.endDate, dateFnsFormats.default('.'))}].pdf`;

      document.body.appendChild(dummy);
      dummy.click();
      document.body.removeChild(dummy);
      dispatch({
        type: SitesActionTypesEnum.SET_MODAL,
        payload: { type: 'schedules', value: false },
      });
    } catch (error: any) {
      const message = error?.response?.data?.UserMessage;
      console.error(message);

      throw new Error(error);
    } finally {
      dispatch({
        type: SitesActionTypesEnum.SET_LOADING,
        payload: { type: 'schedules', value: false },
      });
    }
  };

export const setSitesModals =
  (payload: ISetModalsPayload) =>
  (dispatch: Dispatch<TSitesAction>): void => {
    dispatch({ type: SitesActionTypesEnum.SET_MODAL, payload });
  };

export const cleanUpSitesStore =
  () =>
  (dispatch: Dispatch<TSitesAction>): void => {
    dispatch({ type: SitesActionTypesEnum.CLEAN_UP_SITES_STORE });
  };

export const cleanupCurrentSite =
  () =>
  (dispatch: Dispatch<TSitesAction>): void => {
    dispatch({ type: SitesActionTypesEnum.CLEANUP_CURRENT_SITE });
  };
