import './SchedulesModal.scss';

import { Download as DownloadIcon } from '@mui/icons-material';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LinearProgress,
} from '@mui/material';
import { DatePicker } from 'components/common';
import { colors } from 'constants/colors/colors';
import { useActions, useTypedSelector } from 'hooks';
import React, { useState } from 'react';
import { DateRange } from 'react-day-picker';

const SchedulesModal: React.FC = () => {
  const [rangeDate, setRangeDate] = useState<DateRange>();
  const { loadings, modals, currentSite } = useTypedSelector(
    (state) => state && state.sites,
  );
  const { setSitesModals, cleanupCurrentSite, siteSchedules } = useActions();

  const handleClose = () => {
    if (loadings.schedules) {
      return;
    }
    setSitesModals({ type: 'schedules', value: false });
    cleanupCurrentSite();
  };

  const handleChangeRangeDate = (data: DateRange | undefined) => {
    setRangeDate(data);
  };

  const handleDownload = () => {
    if (currentSite?.id && rangeDate?.from && rangeDate.to) {
      siteSchedules({
        id: currentSite.id,
        startDate: rangeDate.from,
        endDate: rangeDate.to,
      });
    }
  };

  return (
    <Dialog
      open={Boolean(modals.schedules)}
      onClose={handleClose}
      maxWidth="sm"
      fullWidth
    >
      {loadings.schedules && <LinearProgress />}
      <DialogTitle>Select dates</DialogTitle>
      <DialogContent sx={{ display: 'flex', justifyContent: 'center' }}>
        <DatePicker
          relatedComponent="input"
          mode="range"
          defaultDate={rangeDate}
          onSelect={handleChangeRangeDate}
          relatedComponentDisabled={loadings.schedules}
          className="site-schedules-date-range"
        />
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="error"
          onClick={handleClose}
          disabled={loadings.schedules}
        >
          Close
        </Button>
        <Button
          variant="contained"
          sx={{ color: colors.White }}
          onClick={handleDownload}
          disabled={loadings.schedules}
          startIcon={<DownloadIcon />}
        >
          Download
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default SchedulesModal;
