import { Typography } from '@mui/material';
import { withStyles } from 'tss-react/mui';

import { colors } from '../../../../constants/colors/colors';

const STypography = withStyles(Typography, () => ({
  root: {
    fontSize: 42,
    fontFamily: '"Poppins-Bold", sans-serif',
    color: colors.Tundora,
  },
}));

export { STypography };
