import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#00B6F1',
    },
  },
  typography: {
    fontFamily: "'Akkurat-Regular', sans-serif",
  },
});

export default theme;
