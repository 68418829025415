import '../Table.scss';

import React from 'react';

import { STablePagination } from './TableFooter.styled';

interface ICampaignTableFooterProps {
  total: number;
  pageNumber: null | number;
  pageSize: null | number;
  setPageSize: React.Dispatch<React.SetStateAction<number>>;
  setPageNumber: React.Dispatch<React.SetStateAction<number>>;
}

const TableFooter: React.FC<ICampaignTableFooterProps> = ({
  total,
  pageNumber,
  setPageSize,
  setPageNumber,
  pageSize,
}) => {
  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setPageNumber(newPage);
  };

  const handleChangeRowsPerPage = ({
    target: { value },
  }: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setPageSize(Number(value));
    setPageNumber(0);
  };

  const isExist = total && total > 0;

  return (
    <div className="footer-container">
      {isExist ? (
        <STablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={total}
          rowsPerPage={pageSize || 20}
          page={pageNumber || 0}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      ) : (
        <div />
      )}
    </div>
  );
};

export default TableFooter;
