import { TabPanel } from '@mui/lab';
import { Button, TextField } from '@mui/material';
import { AlertStatusesEnum } from 'constants/constants';
import { FormikHelpers, useFormik } from 'formik';
import { useActions, useError } from 'hooks';
import { useSnackbar } from 'notistack';
import React, { memo } from 'react';

import { messages, texts } from '../../../../../constants';
import { StaffEnum, TabIdsEnum } from '../../../../../constants/enum';
import { validationSchemaSuborganisation } from '../../../constants/validationSchemas';
import { ITabPropsDefault } from '../Tabs';

interface IForm {
  [texts.suborganisation.name]: string;
}

const SuborganisationTab: React.FC<ITabPropsDefault> = ({
  handleModalClose,
}) => {
  const { createSuborganisation, getSubOrganisation } = useActions();
  const { enqueueSnackbar } = useSnackbar();
  const { outputError } = useError();

  const onSubmit = async (
    data: IForm,
    { resetForm, setSubmitting }: FormikHelpers<IForm>,
  ) => {
    try {
      await createSuborganisation(data[texts.suborganisation.name]);
      resetForm();
      enqueueSnackbar(messages.success(StaffEnum.SUBORGANISATION), {
        variant: AlertStatusesEnum.SUCCESS,
        anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
      });
      await getSubOrganisation();
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      outputError(error, { isSnackbar: true });
    } finally {
      setSubmitting(false);
    }
  };

  const { values, errors, isSubmitting, handleChange, handleSubmit } =
    useFormik<IForm>({
      initialValues: { [texts.suborganisation.name]: '' },
      validationSchema: validationSchemaSuborganisation,
      onSubmit,
      validateOnChange: false,
      validateOnMount: false,
    });

  return (
    <TabPanel value={TabIdsEnum.SUBORGANISATION}>
      <form onSubmit={handleSubmit}>
        <div className="content suborganisation-content">
          <TextField
            label={texts.suborganisation.label}
            name={texts.suborganisation.name}
            value={values[texts.suborganisation.name]}
            onChange={handleChange}
            error={Boolean(errors[texts.suborganisation.name])}
            helperText={errors[texts.suborganisation.name]}
            disabled={isSubmitting}
          />
        </div>
        <div className="actions suborganisation-actions">
          <Button variant="contained" type="submit" disabled={isSubmitting}>
            {texts.actions.submit}
          </Button>
          <Button
            variant="contained"
            color="error"
            disabled={isSubmitting}
            onClick={handleModalClose}
          >
            {texts.actions.close}
          </Button>
        </div>
      </form>
    </TabPanel>
  );
};

export default memo(SuborganisationTab);
