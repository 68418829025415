import { CalendarMonth as CalendarMonthIcon } from '@mui/icons-material';
import { IconButton, InputAdornment } from '@mui/material';
import classnames from 'classnames';
import React, { ChangeEvent, useEffect, useState } from 'react';

import { regulars } from '../../../../../../constants/regular/regulars';
import { getStringFromDate } from '../../../../../../helpers';
import { TSeparator } from '../../../../../../types';
import SvgIcon from '../../../../SvgIcon/SvgIcon';
import { STextField } from './DateInput.styled';

export interface IDateInputProps {
  label: string;
  separator: TSeparator;
  dateValue?: Date | undefined;
  handleDateSelect: (value: Date | undefined) => void;
  toggleOpen: () => void;
  className?: string;
  disabled?: boolean;
}

const DateInput: React.FC<IDateInputProps> = ({
  separator,
  dateValue,
  handleDateSelect,
  label,
  toggleOpen,
  className,
  disabled,
}) => {
  const [inputValue, setInputValue] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');

  useEffect(() => {
    if (dateValue) {
      setInputValue(getStringFromDate(dateValue));
    } else {
      setInputValue('');
    }
    setErrorMessage('');
  }, [dateValue]);

  const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const {
      target: { value },
    } = event;
    const cutValue = value.split(separator);
    const cutValueString = cutValue.join('');

    if (cutValueString.length < 9) {
      setErrorMessage('');
    }

    if (!cutValueString) {
      setInputValue('');
    }
    if (cutValueString.length === 8) {
      if (value.match(regulars.date)) {
        const date = new Date(+cutValue[2], +cutValue[1] - 1, +cutValue[0]);
        handleDateSelect(date);
      } else {
        setErrorMessage('Wrong date format');
      }
    }

    if (
      cutValueString.match(regulars.onlyNumber) &&
      cutValueString.length < 9
    ) {
      setInputValue(cutValueString);
    }
  };

  const valueConverter = (value: string) => {
    const cutValue = value.split(separator).join('');

    if (cutValue.length > 4) {
      const day = value.slice(0, 2);
      const month = value.slice(2, 4);
      const year = value.slice(4);

      return `${day}${separator}${month}${separator}${year}`;
    }

    if (cutValue.length > 2) {
      const day = value.slice(0, 2);
      const month = value.slice(2, 4);

      return `${day}${separator}${month}`;
    }

    return value;
  };

  return (
    <STextField
      label={label}
      variant="outlined"
      error={Boolean(errorMessage)}
      helperText={errorMessage}
      value={valueConverter(inputValue)}
      onChange={handleChange}
      className={classnames('date-input', className)}
      disabled={disabled}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton onClick={toggleOpen}>
              <SvgIcon>
                <CalendarMonthIcon />
              </SvgIcon>
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};

export default DateInput;
