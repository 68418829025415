import { TabPanel } from '@mui/lab';
import { Button, TextField } from '@mui/material';
import { AlertStatusesEnum } from 'constants/constants';
import { FormikHelpers, useFormik } from 'formik';
import { useActions, useError } from 'hooks';
import { useSnackbar } from 'notistack';
import React, { memo } from 'react';

import { texts } from '../../../../../constants';
import { StaffEnum, TabIdsEnum } from '../../../../../constants/enum';
import { messages } from '../../../../../constants/text';
import { validationSchemaNetwork } from '../../../constants/validationSchemas';
import { ITabPropsDefault } from '../Tabs';

interface IForm {
  [texts.network.name]: string;
}

const NetworkTab: React.FC<ITabPropsDefault> = ({ handleModalClose }) => {
  const { createNewNetwork, getNetworks } = useActions();
  const { enqueueSnackbar } = useSnackbar();
  const { outputError } = useError();

  const onSubmit = async (
    data: IForm,
    { resetForm, setSubmitting }: FormikHelpers<IForm>,
  ) => {
    try {
      await createNewNetwork(data[texts.network.name]);
      resetForm();
      enqueueSnackbar(messages.success(StaffEnum.NETWORK), {
        variant: AlertStatusesEnum.SUCCESS,
        anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
      });
      await getNetworks();
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      outputError(error, { isSnackbar: true });
      console.error(error);
    } finally {
      setSubmitting(false);
    }
  };

  const { values, isSubmitting, errors, handleChange, handleSubmit } =
    useFormik<IForm>({
      initialValues: { [texts.network.name]: '' },
      validationSchema: validationSchemaNetwork,
      onSubmit,
      validateOnMount: false,
      validateOnChange: false,
    });

  return (
    <TabPanel value={TabIdsEnum.NETWORK}>
      <form onSubmit={handleSubmit}>
        <div className="content network-content">
          <TextField
            label={texts.network.label}
            name={texts.network.name}
            value={values[texts.network.name]}
            onChange={handleChange}
            error={Boolean(errors[texts.network.name])}
            helperText={errors[texts.network.name]}
            disabled={isSubmitting}
          />
        </div>
        <div className="actions network-actions">
          <Button variant="contained" type="submit" disabled={isSubmitting}>
            {texts.actions.submit}
          </Button>
          <Button
            variant="contained"
            color="error"
            disabled={isSubmitting}
            onClick={handleModalClose}
          >
            {texts.actions.close}
          </Button>
        </div>
      </form>
    </TabPanel>
  );
};

export default memo(NetworkTab);
