import { AlertStatusesEnum } from 'constants/constants';
import {
  createCampaignSiteScheme,
  getSelectedCampaignSites,
} from 'helpers/Campaign';
import { defaultErrorMessage } from 'hooks/useError';
import _ from 'lodash';
import { ProviderContext } from 'notistack';
import { DateRange } from 'react-day-picker';
import { Dispatch } from 'redux';
import {
  selectedSitesRequest,
  sitesFilters,
} from 'redux/Actions/sitesActions/sitesApi';
import {
  IAddToSelectedPayload,
  IChangeFileLoadStatusPayload,
  IRenameLoadedFilePayload,
  ISetCampaignScheduleDatesPayload,
  ISetDayOfWeekScheduleModalPayload,
  ISetDayPartScheduleModalPayload,
  ISetLoadedFileSrcPayload,
  ISetLoadingManageCampaignManageTab,
  ISetModalManageCampaignManageTab,
  ISetTimeOnScheduleModalPayload,
  ManageTabCampaignActionsEnum,
  TManageCampaignManageTabActions,
} from 'redux/ActionTypes';
import {
  IAddMediaModal,
  IInitialManageTabCampaignState,
  ILoadersStorageMedia,
} from 'redux/Reducers/campaignsReducer/manage/manage/type';
import { IDefaultDto, IFiltersList, IMedia, ISiteFilters, ISites } from 'types';

import { FileLoadStatusesEnum } from '../../../../../constants/enums/fileLoadStatuses';
import { getIsMediaSelected } from '../../../../../helpers/Campaign/addMediaModal/addMediaModal';
import { IObjectWithId } from '../../../../../types/general';
import { TRootState } from '../../../../Reducers/index';
import {
  getRecently,
  getSelected,
  mediaFilters,
  postFile,
  postFileBulk,
  renameSelected,
} from '../../../mediaActions/mediaApi';
import { ICallbackUploadFile, IRecentlyAddedPayload } from '../type';

export const changeIsPreemptive =
  (payload: IInitialManageTabCampaignState['isPreemptive']) =>
  (dispatch: Dispatch<TManageCampaignManageTabActions>): void => {
    dispatch({ type: ManageTabCampaignActionsEnum.SET_IS_PREEMPTIVE, payload });
  };

export const setLoadersManageTab =
  (payload: ISetLoadingManageCampaignManageTab) =>
  (dispatch: Dispatch<TManageCampaignManageTabActions>): void => {
    dispatch({ type: ManageTabCampaignActionsEnum.SET_LOADING, payload });
  };

export const setModalsManageTab =
  (payload: ISetModalManageCampaignManageTab) =>
  (dispatch: Dispatch<TManageCampaignManageTabActions>): void => {
    dispatch({ type: ManageTabCampaignActionsEnum.SET_MODAL, payload });
  };

export const setCampaignSchedulesDates =
  (payload: ISetCampaignScheduleDatesPayload) =>
  (dispatch: Dispatch<TManageCampaignManageTabActions>): void => {
    dispatch({
      type: ManageTabCampaignActionsEnum.SET_CAMPAIGN_SCHEDULE_DATES,
      payload,
    });
  };

export const containScheduleModal =
  (payload: number | string | undefined) =>
  (dispatch: Dispatch<TManageCampaignManageTabActions>): void => {
    dispatch({
      type: ManageTabCampaignActionsEnum.CONTAIN_SCHEDULE_MODAL,
      payload,
    });
  };

export const containScheduleModalTemplate =
  () =>
  (dispatch: Dispatch<TManageCampaignManageTabActions>): void => {
    dispatch({
      type: ManageTabCampaignActionsEnum.CONTAIN_SCHEDULE_MODAL_TEMPLATE,
    });
  };

export const setSelectedSiteSchedules =
  (payload: IInitialManageTabCampaignState['selectedSiteSchedule']) =>
  (dispatch: Dispatch<TManageCampaignManageTabActions>): void => {
    dispatch({
      type: ManageTabCampaignActionsEnum.SET_SELECTED_SCHEDULE,
      payload,
    });
  };

export const removeSelectedItems =
  () =>
  (dispatch: Dispatch<TManageCampaignManageTabActions>): void => {
    dispatch({
      type: ManageTabCampaignActionsEnum.REMOVE_SELECTED_ITEMS,
    });
  };

export const setDatesOnScheduleModal =
  (payload?: DateRange) =>
  (dispatch: Dispatch<TManageCampaignManageTabActions>): void => {
    dispatch({
      type: ManageTabCampaignActionsEnum.SET_DATES_ON_SCHEDULE_MODAL,
      payload,
    });
  };

export const setTimesOnScheduleModal =
  (payload: ISetTimeOnScheduleModalPayload) =>
  (dispatch: Dispatch<TManageCampaignManageTabActions>): void => {
    dispatch({
      type: ManageTabCampaignActionsEnum.SET_TIMES_ON_SCHEDULE_MODAL,
      payload,
    });
  };

export const setDaysOfWeekScheduleModal =
  (payload: ISetDayOfWeekScheduleModalPayload) =>
  (dispatch: Dispatch<TManageCampaignManageTabActions>): void => {
    dispatch({
      type: ManageTabCampaignActionsEnum.SET_DAYS_OF_WEEK_SCHEDULE_MODAL,
      payload,
    });
  };

export const setUseForAllSites =
  (payload: boolean) =>
  (dispatch: Dispatch<TManageCampaignManageTabActions>): void => {
    dispatch({
      type: ManageTabCampaignActionsEnum.SET_IS_FOR_ALL_SITES,
      payload,
    });
  };

export const setIsBulkScheduleModal =
  (payload: boolean) =>
  (dispatch: Dispatch<TManageCampaignManageTabActions>): void => {
    dispatch({
      type: ManageTabCampaignActionsEnum.SET_IS_BULK_SCHEDULE_MODAL,
      payload,
    });
  };

export const addAnotherDayPart =
  () =>
  (dispatch: Dispatch<TManageCampaignManageTabActions>): void => {
    dispatch({ type: ManageTabCampaignActionsEnum.ADD_ANOTHER_DAY_PART });
  };

export const addAnotherDayPartBulk =
  () =>
  (dispatch: Dispatch<TManageCampaignManageTabActions>): void => {
    dispatch({ type: ManageTabCampaignActionsEnum.ADD_ANOTHER_DAY_PART_BULK });
  };

export const removeDayPart =
  (payload: number) =>
  (dispatch: Dispatch<TManageCampaignManageTabActions>): void => {
    dispatch({ type: ManageTabCampaignActionsEnum.REMOVE_DAY_PART, payload });
  };

export const setDayPartScheduleModal =
  (payload: ISetDayPartScheduleModalPayload) =>
  (dispatch: Dispatch<TManageCampaignManageTabActions>): void => {
    dispatch({
      type: ManageTabCampaignActionsEnum.SET_DAY_PART_SCHEDULE_MODAL,
      payload,
    });
  };

export const doneScheduleModal =
  () =>
  (
    dispatch: Dispatch<TManageCampaignManageTabActions>,
    getState: () => TRootState,
  ): void => {
    const {
      manageCampaign: {
        manage: { scheduleCampaignModalData },
      },
    } = getState();

    if (scheduleCampaignModalData.isBulk) {
      dispatch({
        type: ManageTabCampaignActionsEnum.DONE_SCHEDULE_MODAL_BULK,
      });
      dispatch({ type: ManageTabCampaignActionsEnum.CLEANUP_SCHEDULE_MODAL });

      return;
    }

    if (scheduleCampaignModalData.isUseForAll) {
      dispatch({
        type: ManageTabCampaignActionsEnum.DONE_SCHEDULE_MODAL_FOR_ALL,
      });
      dispatch({ type: ManageTabCampaignActionsEnum.CLEANUP_SCHEDULE_MODAL });

      return;
    }

    dispatch({ type: ManageTabCampaignActionsEnum.DONE_SCHEDULE_MODAL });
    dispatch({ type: ManageTabCampaignActionsEnum.CLEANUP_SCHEDULE_MODAL });
  };

export const cleanupScheduleModal =
  () =>
  (dispatch: Dispatch<TManageCampaignManageTabActions>): void => {
    dispatch({ type: ManageTabCampaignActionsEnum.CLEANUP_SCHEDULE_MODAL });
  };

export const cleanupViewAllMediaModal =
  () =>
  (dispatch: Dispatch<TManageCampaignManageTabActions>): void => {
    dispatch({
      type: ManageTabCampaignActionsEnum.CLEANUP_VIEW_ALL_MEDIA_MODAL,
    });
  };

export const containAddMediaModal =
  (payload: number | string) =>
  (dispatch: Dispatch<TManageCampaignManageTabActions>): void => {
    dispatch({
      type: ManageTabCampaignActionsEnum.CONTAIN_ADD_MEDIA_MODAL,
      payload,
    });
  };

export const containAddMediaModalBulk =
  () =>
  (dispatch: Dispatch<TManageCampaignManageTabActions>): void => {
    dispatch({
      type: ManageTabCampaignActionsEnum.CONTAIN_ADD_MEDIA_MODAL_BULK,
    });
  };

export const setAddMediaModalTab =
  (payload: IAddMediaModal['currentTab']) =>
  (dispatch: Dispatch<TManageCampaignManageTabActions>): void => {
    dispatch({
      type: ManageTabCampaignActionsEnum.SET_CURRENT_TAB_MEDIA_MODAL,
      payload,
    });
  };

export const setIsBulkAddMediaModal =
  (payload: IAddMediaModal['isBulk']) =>
  (dispatch: Dispatch<TManageCampaignManageTabActions>): void => {
    dispatch({
      type: ManageTabCampaignActionsEnum.SET_IS_BULK_ADD_MEDIA_MODAL,
      payload,
    });
  };

export const clickedMedia =
  (payload: IAddToSelectedPayload) =>
  (
    dispatch: Dispatch<TManageCampaignManageTabActions>,
    getStore: () => TRootState,
  ): void => {
    const {
      manageCampaign: {
        manage: { addMediaModal },
      },
    } = getStore();

    const isSelected = getIsMediaSelected(
      addMediaModal.selectedMedias,
      payload.media,
    );

    if (isSelected) {
      dispatch({
        type: ManageTabCampaignActionsEnum.REMOVE_FROM_SELECTED,
        payload: payload.media.id,
      });

      return;
    }

    dispatch({ type: ManageTabCampaignActionsEnum.ADD_TO_SELECTED, payload });
  };

export const addToAllTabStorage =
  (payload: IObjectWithId<number>[]) =>
  (dispatch: Dispatch<TManageCampaignManageTabActions>): void => {
    dispatch({
      type: ManageTabCampaignActionsEnum.ADD_TO_SELECTED_FROM_ALL_TAB,
      payload,
    });
  };

export const setCurrentLookingMedia =
  (payload: IAddMediaModal['currentLookingMedia']) =>
  (dispatch: Dispatch<TManageCampaignManageTabActions>): void => {
    dispatch({
      type: ManageTabCampaignActionsEnum.SET_CURRENT_LOOKING_MEDIA,
      payload,
    });
  };

export const doneMediaModal =
  () =>
  (dispatch: Dispatch<TManageCampaignManageTabActions>): void => {
    dispatch({
      type: ManageTabCampaignActionsEnum.DONE_ADD_MEDIA_MODAL,
    });
    dispatch({
      type: ManageTabCampaignActionsEnum.SET_MODAL,
      payload: { type: 'addMedia', value: false },
    });
  };

export const doneMediaModalBulk =
  () =>
  (dispatch: Dispatch<TManageCampaignManageTabActions>): void => {
    dispatch({ type: ManageTabCampaignActionsEnum.DONE_ADD_MEDIA_MODAL_BULK });
    dispatch({
      type: ManageTabCampaignActionsEnum.SET_MODAL,
      payload: { type: 'addMedia', value: false },
    });
  };

export const addFileToLoad =
  (payload: ILoadersStorageMedia) =>
  (dispatch: Dispatch<TManageCampaignManageTabActions>): void => {
    dispatch({ type: ManageTabCampaignActionsEnum.ADD_FILE_TO_LOAD, payload });
  };

export const changeFileLoadStatus =
  (payload: IChangeFileLoadStatusPayload) =>
  (dispatch: Dispatch<TManageCampaignManageTabActions>): void => {
    dispatch({
      type: ManageTabCampaignActionsEnum.CHANGE_STATUS_LOADED_FILE,
      payload,
    });
  };

export const setCurrentRenamedFile =
  (payload: IAddMediaModal['currentRenamedFile']) =>
  (dispatch: Dispatch<TManageCampaignManageTabActions>): void => {
    dispatch({
      type: ManageTabCampaignActionsEnum.SET_CURRENT_RENAMED_FILE,
      payload,
    });
  };

export const renameLoadedFile =
  (payload: IRenameLoadedFilePayload) =>
  (dispatch: Dispatch<TManageCampaignManageTabActions>): void => {
    dispatch({
      type: ManageTabCampaignActionsEnum.RENAME_LOADED_FILE,
      payload,
    });
  };

export const removeLoadedFile =
  (payload: number | string) =>
  (dispatch: Dispatch<TManageCampaignManageTabActions>): void => {
    dispatch({
      type: ManageTabCampaignActionsEnum.REMOVE_LOADED_FILE,
      payload,
    });
  };

export const changeLoadFileProgress =
  (uid: string, loadProgress: number) =>
  (dispatch: Dispatch<TManageCampaignManageTabActions>): void => {
    dispatch({
      type: ManageTabCampaignActionsEnum.CHANGE_LOAD_PROGRESS,
      payload: { id: uid, loadProgress },
    });
  };

export const setLoaderFileSrc =
  (payload: ISetLoadedFileSrcPayload) =>
  (dispatch: Dispatch<TManageCampaignManageTabActions>): void => {
    dispatch({
      type: ManageTabCampaignActionsEnum.SET_LOADED_FILE_SRC,
      payload,
    });
  };

export const cleanupAddMediaModal =
  () =>
  (dispatch: Dispatch<TManageCampaignManageTabActions>): void => {
    dispatch({ type: ManageTabCampaignActionsEnum.CLEANUP_ADD_MEDIA_MODAL });
  };

export const getSiteFiltersManageCampaign =
  (ids?: number[]) =>
  async (
    dispatch: Dispatch<TManageCampaignManageTabActions>,
  ): Promise<void> => {
    try {
      dispatch({
        type: ManageTabCampaignActionsEnum.SET_LOADING,
        payload: { type: 'getSiteFilters', value: true },
      });
      const {
        data: { data },
      } = await sitesFilters<IDefaultDto<ISiteFilters[]>>(ids);

      dispatch({
        type: ManageTabCampaignActionsEnum.SET_SITE_FILTERS,
        payload: data,
      });
    } catch (error) {
      console.error(error);
    } finally {
      dispatch({
        type: ManageTabCampaignActionsEnum.SET_LOADING,
        payload: { type: 'getSiteFilters', value: false },
      });
    }
  };

export const getSelectedSitesManageCampaign =
  (ids: number[]) =>
  async (
    dispatch: Dispatch<TManageCampaignManageTabActions>,
  ): Promise<void> => {
    try {
      dispatch({
        type: ManageTabCampaignActionsEnum.SET_LOADING,
        payload: { type: 'getSelected', value: true },
      });
      const {
        data: { data },
      } = await selectedSitesRequest<IDefaultDto<ISites[]>>({ ids });

      const campaignSites = createCampaignSiteScheme(data);

      dispatch({
        type: ManageTabCampaignActionsEnum.PUT_TO_CAMPAIGN_SITES,
        payload: campaignSites,
      });
    } catch (error) {
      console.error(error);
    } finally {
      dispatch({
        type: ManageTabCampaignActionsEnum.SET_LOADING,
        payload: { type: 'getSelected', value: false },
      });
    }
  };

export const getRecentlyAddedMedia =
  (payload: IRecentlyAddedPayload) =>
  async (
    dispatch: Dispatch<TManageCampaignManageTabActions>,
  ): Promise<void> => {
    try {
      dispatch({
        type: ManageTabCampaignActionsEnum.SET_LOADING,
        payload: { type: 'getRecentlyMedia', value: true },
      });
      const {
        data: { data },
      } = await getRecently<IDefaultDto<IMedia[]>>(payload);

      dispatch({
        type: ManageTabCampaignActionsEnum.SET_RECENTLY_MEDIA_STORAGE,
        payload: data,
      });
    } catch (error: any) {
      const message =
        error?.response?.data?.UserMessage || `Couldn't load recently media`;
      console.error(error);

      throw new Error(message);
    } finally {
      dispatch({
        type: ManageTabCampaignActionsEnum.SET_LOADING,
        payload: { type: 'getRecentlyMedia', value: false },
      });
    }
  };

export const getSelectedMediaV2 =
  (ids: number[]) =>
  async (
    dispatch: Dispatch<TManageCampaignManageTabActions>,
  ): Promise<void> => {
    try {
      dispatch({
        type: ManageTabCampaignActionsEnum.SET_LOADING,
        payload: { type: 'getSelectedMedia', value: true },
      });

      const {
        data: { data },
      } = await getSelected<IDefaultDto<IMedia[]>>({ ids });
      dispatch({
        type: ManageTabCampaignActionsEnum.SET_SELECTED_MEDIA_STORAGE,
        payload: data,
      });
    } catch (error: any) {
      const message = error?.response?.data?.UserMessage;
      console.error(error);

      throw new Error(message);
    } finally {
      dispatch({
        type: ManageTabCampaignActionsEnum.SET_LOADING,
        payload: { type: 'getSelectedMedia', value: false },
      });
    }
  };

export const getMediaFiltersManageCampaign =
  () =>
  async (
    dispatch: Dispatch<TManageCampaignManageTabActions>,
  ): Promise<void> => {
    try {
      dispatch({
        type: ManageTabCampaignActionsEnum.SET_LOADING,
        payload: { type: 'getMediaFilters', value: true },
      });
      const {
        data: { data },
      } = await mediaFilters<IDefaultDto<IFiltersList[]>>();

      dispatch({
        type: ManageTabCampaignActionsEnum.SET_MEDIA_FILTERS,
        payload: data,
      });
    } catch (error) {
      console.error(error);
    } finally {
      dispatch({
        type: ManageTabCampaignActionsEnum.SET_LOADING,
        payload: { type: 'getMediaFilters', value: false },
      });
    }
  };

export const uploadMediaManageCampaign =
  (
    file: ILoadersStorageMedia,
    cb: ICallbackUploadFile<TManageCampaignManageTabActions>['cb'],
    showSnack: ProviderContext['enqueueSnackbar'],
  ) =>
  async (
    dispatch: Dispatch<TManageCampaignManageTabActions>,
    getStore: () => TRootState,
  ): Promise<void> => {
    try {
      const {
        manageCampaign: {
          manage: { addMediaModal },
        },
      } = getStore();
      const { campaignSchedule, campaignSite } = addMediaModal;

      if (campaignSchedule && campaignSite) {
        const formData = new FormData();
        formData.append('file', file.file);
        const {
          data: { data },
        } = await postFile<IDefaultDto<number>>(formData, String(file.id), cb, {
          duration: campaignSchedule.duration,
          siteId: campaignSite.siteId,
        });

        dispatch({
          type: ManageTabCampaignActionsEnum.CHANGE_STATUS_LOADED_FILE,
          payload: { id: file.id, status: FileLoadStatusesEnum.SUCCESS },
        });
        dispatch({
          type: ManageTabCampaignActionsEnum.CHANGE_LOADED_FILE_ID,
          payload: { currentId: file.id, newId: data },
        });
      }
    } catch (error: any) {
      const errorMessage = error?.response?.data?.UserMessage;

      showSnack(errorMessage || defaultErrorMessage, {
        variant: AlertStatusesEnum.ERROR,
      });
      dispatch({
        type: ManageTabCampaignActionsEnum.CHANGE_STATUS_LOADED_FILE,
        payload: { id: file.id, status: FileLoadStatusesEnum.ERROR },
      });
    }
  };

export const uploadMediaManageCampaignBulk =
  (
    file: ILoadersStorageMedia,
    cb: ICallbackUploadFile<TManageCampaignManageTabActions>['cb'],
    showSnack: ProviderContext['enqueueSnackbar'],
  ) =>
  async (
    dispatch: Dispatch<TManageCampaignManageTabActions>,
    getStore: () => TRootState,
  ): Promise<void> => {
    try {
      const {
        manageCampaign: {
          manage: { addMediaModal, campaignSites, selectedSiteSchedule },
        },
      } = getStore();
      const { campaignSchedule } = addMediaModal;
      const selectedIds = Object.keys(selectedSiteSchedule);

      const selectedSites = getSelectedCampaignSites(
        campaignSites,
        selectedIds,
      );
      const selectedSitesUniq = _.uniqBy(selectedSites, 'siteId');
      const duration = campaignSchedule?.duration;

      if (selectedSites.length && duration) {
        const formData = new FormData();
        formData.append('file', file.file);

        const {
          data: { data },
        } = await postFileBulk<IDefaultDto<number>>(
          formData,
          String(file.id),
          cb,
          {
            siteIds: selectedSitesUniq.map(
              (campaignSite) => campaignSite.siteId,
            ),
            duration,
          },
        );

        dispatch({
          type: ManageTabCampaignActionsEnum.CHANGE_STATUS_LOADED_FILE,
          payload: { id: file.id, status: FileLoadStatusesEnum.SUCCESS },
        });
        dispatch({
          type: ManageTabCampaignActionsEnum.CHANGE_LOADED_FILE_ID,
          payload: { currentId: file.id, newId: data },
        });
      }
    } catch (error: any) {
      const errorMessage = error?.response?.data?.UserMessage;

      showSnack(errorMessage || defaultErrorMessage, {
        variant: AlertStatusesEnum.ERROR,
      });
      dispatch({
        type: ManageTabCampaignActionsEnum.CHANGE_STATUS_LOADED_FILE,
        payload: { id: file.id, status: FileLoadStatusesEnum.ERROR },
      });
    }
  };

export const getSelectedMediasForViewAllMediaModal =
  (ids: number[]) =>
  async (
    dispatch: Dispatch<TManageCampaignManageTabActions>,
  ): Promise<void> => {
    try {
      dispatch({
        type: ManageTabCampaignActionsEnum.SET_LOADING,
        payload: { type: 'getSelectedMediaForViewAll', value: true },
      });
      const {
        data: { data },
      } = await getSelected<IDefaultDto<IMedia[]>>({ ids });

      dispatch({
        type: ManageTabCampaignActionsEnum.SET_MEDIAS,
        payload: data,
      });
    } catch (error: any) {
      const message = error?.response?.data?.UserMessage;

      throw new Error(message);
    } finally {
      dispatch({
        type: ManageTabCampaignActionsEnum.SET_LOADING,
        payload: { type: 'getSelectedMediaForViewAll', value: false },
      });
    }
  };

export const renameMediaNew =
  (serverId: number, fileName: string) => async (): Promise<void> => {
    try {
      await renameSelected({ Name: fileName }, serverId);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };
