import produce from 'immer';

import {
  CategoryActionTypesEnum,
  ICategoryState,
  TCategoryAction,
} from '../../ActionTypes/categoriesTypes/categoryTypes';

const initialState: ICategoryState = {
  categories: [],
  hasErrorCategoriesLoading: false,
  isLoadingCategories: false,
};

const categoryReducer = produce(
  (draft = initialState, action: TCategoryAction) => {
    switch (action.type) {
      case CategoryActionTypesEnum.GET_CATEGORIES_LOADING:
        draft.isLoadingCategories = true;
        draft.hasErrorCategoriesLoading = false;

        return draft;
      case CategoryActionTypesEnum.GET_CATEGORIES_SUCCESS:
        draft.categories = action.payload;
        draft.isLoadingCategories = false;

        return draft;
      case CategoryActionTypesEnum.GET_CATEGORIES_ERROR:
        draft.isLoadingCategories = false;
        draft.hasErrorCategoriesLoading = true;

        return draft;
      default:
        return draft;
    }
  },
  initialState,
);

export default categoryReducer;
