import './input.scss';

import { TextField, TextFieldProps } from '@mui/material';
import classnames from 'classnames';
import React from 'react';

type TInputProps = {
  label?: string;
  className?: classnames.ArgumentArray | classnames.Argument;
} & TextFieldProps;

const Input: React.FC<TInputProps> = ({
  label,
  variant = 'outlined',
  className,
  ...props
}) => (
  <div className="input-wrapper">
    {label && <div className="custom-input-label">{label}</div>}
    <TextField
      className={classnames('input-field', className)}
      variant={variant}
      {...props}
    />
  </div>
);

export default Input;
