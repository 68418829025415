import produce from 'immer';

import {
  AdvertiserActionTypesEnum,
  IAdvertiserState,
  TAdvertiserAction,
} from '../../ActionTypes/advertiserTypes/advertisersTypes';

const initialState: IAdvertiserState = {
  advertisers: [],
  hasErrorAdvertisersLoading: false,
  isLoadingAdvertiser: false,
  isLoadingPostAdvertiser: false,
  hasErrorPostAdvertiser: false,
};

const productReducer = produce(
  (draft = initialState, action: TAdvertiserAction) => {
    switch (action.type) {
      case AdvertiserActionTypesEnum.GET_ADVERTISERS_LOADING:
        draft.isLoadingAdvertiser = true;
        draft.hasErrorAdvertisersLoading = false;

        return draft;
      case AdvertiserActionTypesEnum.GET_ADVERTISERS_SUCCESS:
        draft.advertisers = action.payload;
        draft.isLoadingAdvertiser = false;

        return draft;
      case AdvertiserActionTypesEnum.GET_ADVERTISERS_ERROR:
        draft.isLoadingAdvertiser = false;
        draft.hasErrorAdvertisersLoading = true;

        return draft;
      case AdvertiserActionTypesEnum.POST_ADVERTISERS_LOADING:
        draft.isLoadingPostAdvertiser = true;
        draft.hasErrorPostAdvertiser = false;

        return draft;
      case AdvertiserActionTypesEnum.POST_ADVERTISERS_SUCCESS:
        draft.isLoadingPostAdvertiser = false;

        return draft;
      case AdvertiserActionTypesEnum.POST_ADVERTISERS_ERROR:
        draft.isLoadingPostAdvertiser = false;
        draft.hasErrorPostAdvertiser = true;

        return draft;
      default:
        return draft;
    }
  },
  initialState,
);

export default productReducer;
