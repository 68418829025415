import { IObjectIdWithName } from '../../types/general';

export enum FormatIdsEnum {
  JPG = 1,
  MP4 = 2,
  PNG = 4,
  JPEG = 8,
}

export enum FormatsEnum {
  JPG = 'JPG',
  MP4 = 'MP4',
  PNG = 'PNG',
  JPEG = 'JPEG',
}

export type TImageFormats =
  | FormatsEnum.JPEG
  | FormatsEnum.JPG
  | FormatsEnum.PNG;
export type TVideoFormats = FormatsEnum.MP4;

export const formatsScheme: Record<
  FormatsEnum,
  IObjectIdWithName<FormatsEnum, FormatIdsEnum>
> = {
  JPG: {
    id: FormatIdsEnum.JPG,
    name: FormatsEnum.JPG,
  },
  MP4: {
    id: FormatIdsEnum.MP4,
    name: FormatsEnum.MP4,
  },
  PNG: {
    id: FormatIdsEnum.PNG,
    name: FormatsEnum.PNG,
  },
  JPEG: {
    id: FormatIdsEnum.JPEG,
    name: FormatsEnum.JPEG,
  },
};

export const selectOptions = [
  { value: formatsScheme.JPG.id, label: formatsScheme.JPG.name },
  { value: formatsScheme.MP4.id, label: formatsScheme.MP4.name },
  { value: formatsScheme.PNG.id, label: formatsScheme.PNG.name },
  { value: formatsScheme.JPEG.id, label: formatsScheme.JPEG.name },
];

export const formatsImageScheme: Record<TImageFormats, FormatsEnum> = {
  [FormatsEnum.JPEG]: FormatsEnum.JPEG,
  [FormatsEnum.PNG]: FormatsEnum.PNG,
  [FormatsEnum.JPG]: FormatsEnum.JPG,
};

export const formatsVideoScheme: Record<TVideoFormats, FormatsEnum> = {
  [FormatsEnum.MP4]: FormatsEnum.MP4,
};
