import { Checkbox, FormControlLabel } from '@mui/material';
import { withStyles } from 'tss-react/mui';

import { colors } from '../../../constants/colors/colors';

const SFormControlLabel = withStyles(FormControlLabel, {
  root: {
    marginLeft: 0,
  },
});

const SCheckbox = withStyles(Checkbox, {
  root: {
    color: 'grey',

    '&.withoutPaddings': {
      padding: 0,
    },

    '&.withLabel': {
      marginRight: 10,
    },
  },

  checked: {
    color: `${colors.Tundora} !important`,
  },
});

export { SCheckbox, SFormControlLabel };
