import { AxiosPromise } from 'axios';

import { AxiosMethodEnum } from '../../../constants/text/text';
import { urlConstructors } from '../../../constants/urls/url';
import { apiCall } from '../../../helpers/api/apiCall';
import { ICreateSuborganisationPayload } from './type';

function getOrganisation<T extends object>(url: string): AxiosPromise<T> {
  return apiCall<T>(AxiosMethodEnum.get, url, '', null, '', {});
}

function postOrganisationMethod<T extends object>(
  url: string,
  data?: object,
): AxiosPromise<T> {
  return apiCall<T>(AxiosMethodEnum.post, url, data);
}

export const getCurrentOrganisation = <T extends object>(): AxiosPromise<T> =>
  getOrganisation<T>(urlConstructors.organisation());

export const getSubOrganisationsApi = <
  T extends object | Array<object>,
>(): AxiosPromise<T> => getOrganisation<T>(urlConstructors.subOrganisations());

export const createSubOrganisationApi = <T extends object>(
  data: ICreateSuborganisationPayload,
): AxiosPromise<T> =>
  postOrganisationMethod<T>(urlConstructors.subOrganisations(), data);
