import {
  ArrowForwardIosSharp as ArrowForwardIosSharpIcon,
  Close as CloseIcon,
} from '@mui/icons-material';
import { IconButton, TextField, Typography } from '@mui/material';
import { FieldArrayRenderProps, useFormikContext } from 'formik';
import React from 'react';

import { texts } from '../../../../../../../../constants/texts';
import { IInitialValues } from '../../../../../../types';
import {
  SAccordion,
  SAccordionDetails,
  SAccordionSummary,
} from '../../SiteDayParts.styled';
import useFrameManager from './FrameManager.hook';

interface IFrameManagerProps {
  expand: boolean;
  number: number;
  index: number;
  dayPartIndex: number;
  handleChangeAccordion: (accordionNumber: number) => void;
  siteFrameArrayHelper: FieldArrayRenderProps;
}

const FrameManager: React.FC<IFrameManagerProps> = ({
  number,
  index,
  expand,
  dayPartIndex,
  handleChangeAccordion,
  siteFrameArrayHelper,
}) => {
  const { values, handleChange, isSubmitting } =
    useFormikContext<IInitialValues>();

  const { handleRemoveFrame, handleFrameIdChange, isRemoveButtonDisabled } =
    useFrameManager({
      dayPartIndex,
    });

  return (
    <SAccordion
      expanded={expand}
      onChange={() => handleChangeAccordion(index)}
      disabled={isSubmitting}
      elevation={0}
      disableGutters
      square
    >
      <SAccordionSummary
        expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
      >
        <Typography>
          {texts.pages.manage.content.siteDayParts.accordions.siteFrame(number)}
        </Typography>
        <IconButton
          sx={{
            position: 'absolute',
            right: 0,
            top: 4,
          }}
          onClick={(event) =>
            handleRemoveFrame(event, siteFrameArrayHelper, index)
          }
          disabled={isRemoveButtonDisabled}
        >
          <CloseIcon color={!isRemoveButtonDisabled ? 'error' : 'inherit'} />
        </IconButton>
      </SAccordionSummary>
      <SAccordionDetails>
        <div className="site-frame-info">
          <TextField
            label={
              texts.pages.manage.content.siteDayParts.inputs.siteFrame.name
            }
            name={`siteDayParts[${dayPartIndex}].siteFrames[${index}].name`}
            onChange={handleChange}
            value={values.siteDayParts[dayPartIndex].siteFrames[index].name}
          />
          <TextField
            label={texts.pages.manage.content.siteDayParts.inputs.siteFrame.id}
            name={`siteDayParts[${dayPartIndex}].siteFrames[${index}].frameId`}
            onChange={handleFrameIdChange}
            value={values.siteDayParts[dayPartIndex].siteFrames[index].frameId}
          />
        </div>
      </SAccordionDetails>
    </SAccordion>
  );
};

export default FrameManager;
