import { useFormikContext } from 'formik';
import React from 'react';
import { Options } from 'react-select';

import { Select } from '../../../../../../../../components/common';
import { selectOptions } from '../../../../../../../../constants/file/formats';
import { ISelectOption } from '../../../../../../../../types/general';
import { texts } from '../../../../../../constants/texts';
import { IInitialValues } from '../../../../types';

const Formats: React.FC = () => {
  const { values, errors, isSubmitting, setFieldValue } =
    useFormikContext<IInitialValues>();

  const handleChange = (newValue: Options<ISelectOption>) => {
    setFieldValue('formats', newValue);
  };

  return (
    <div className="formats-wrapper">
      <Select
        label={texts.pages.manage.content.mediaConfig.selects.formats}
        placeholder={texts.pages.manage.content.mediaConfig.selects.formats}
        options={selectOptions}
        value={values.formats}
        onChange={handleChange}
        error={Boolean(errors.formats)}
        helperText={errors.formats}
        isDisabled={isSubmitting}
        closeMenuOnSelect={false}
        isMulti
      />
    </div>
  );
};

export default Formats;
