import { Backdrop, CircularProgress } from '@mui/material';
import React, { ReactElement } from 'react';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

interface IBackdropProps {
  open: boolean;
}

export default function BackdropWithLoader({
  open = true,
}: IBackdropProps): ReactElement {
  const { classes } = useStyles();

  return (
    <div>
      <Backdrop className={classes.backdrop} open={open}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
}
