import { useActions } from 'hooks';
import { SyntheticEvent } from 'react';
import { IMedia } from 'types';

interface ILookingMediaModalHookReturn {
  handleLookingModalOpen: (media: IMedia, event?: SyntheticEvent) => void;
  handleLookingModalClose: () => void;
}

const useLookingMediaModal = (): ILookingMediaModalHookReturn => {
  const { setCurrentLookingMedia } = useActions();

  const handleLookingModalOpen: ILookingMediaModalHookReturn['handleLookingModalOpen'] =
    (media, event) => {
      event?.stopPropagation();
      setCurrentLookingMedia({
        name: media.name,
        fileType: media.type,
        url: media.url,
      });
    };

  const handleLookingModalClose: ILookingMediaModalHookReturn['handleLookingModalClose'] =
    () => {
      setCurrentLookingMedia(null);
    };

  return { handleLookingModalOpen, handleLookingModalClose };
};

export default useLookingMediaModal;
