import './SideNavBar.scss';

import { Backdrop, Drawer, List } from '@mui/material';
import { useActions, useTypedSelector } from 'hooks';
import React from 'react';
import { useLocation } from 'react-router-dom';

import { Logo, SideBarListItem } from './components';
import useSideNavBar from './hook';

const SideNavBar: React.FC = () => {
  const { pathname } = useLocation();

  const {
    currentWindowParam: { isSmall },
  } = useTypedSelector((state) => state && state.app);
  const { isExpand } = useTypedSelector((state) => state && state.sideBar);

  const { classes, hasRendered, sideBarLayout } = useSideNavBar();
  const { sideBarCompress } = useActions();

  return (
    <>
      {hasRendered && (
        <Drawer
          className={classes.drawer}
          variant="permanent"
          anchor="left"
          open={false}
          classes={{
            paper: classes.paper,
            paperAnchorDockedLeft: classes.noBorderRight,
          }}
        >
          <div className={classes.root}>
            <div className="nav-list">
              <div className="upper-buttons">
                <Logo />
                <List>
                  {sideBarLayout.top.map(({ label, onClick, icon, id }) => {
                    const isActive: boolean = id === pathname;

                    return (
                      <SideBarListItem
                        isActive={isActive}
                        label={label}
                        icon={icon}
                        onClick={onClick}
                        key={id}
                      />
                    );
                  })}
                </List>
              </div>
              <div className="bottom-buttons">
                <List>
                  {sideBarLayout.bottom.map(({ label, onClick, icon, id }) => (
                    <SideBarListItem
                      label={label}
                      icon={icon}
                      onClick={onClick}
                      key={id}
                    />
                  ))}
                </List>
              </div>
            </div>
          </div>
        </Drawer>
      )}
      <Backdrop
        open={isExpand && isSmall}
        sx={{
          zIndex: (theme) => theme.zIndex.drawer - 1,
        }}
        onClick={sideBarCompress}
      />
    </>
  );
};

export default SideNavBar;
