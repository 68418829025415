import { AxiosPromise, AxiosResponse } from 'axios';

import { AxiosMethodEnum } from '../../../constants/text/text';
import { urlConstructors, urls } from '../../../constants/urls/url';
import { apiCall } from '../../../helpers/api/apiCall';
import { TUserDto } from '../../../types';
import { getCentresApi } from '../centre/centreApi';
import { getNetworksApi } from '../networks/networksApi';
import { getSubOrganisationsApi } from '../organisationActions/organisationApi';
import { IGetAllUsersParams, INewUserData, IUpdateUserProps } from './types';

const getMethod = <T extends object>(
  url: string,
  params?: object,
): AxiosPromise<T> => apiCall<T>(AxiosMethodEnum.get, url, '', params);

const postMethod = <T extends object>(
  url: string,
  data: object,
): AxiosPromise<T> => apiCall<T>(AxiosMethodEnum.post, url, data);

const deleteMethod = <T extends object>(
  url: string,
  params?: object,
): AxiosPromise<T> => apiCall<T>(AxiosMethodEnum.delete, url, '', params);

const putMethod = <T extends object>(
  url: string,
  data: object,
): AxiosPromise<T> => apiCall<T>(AxiosMethodEnum.put, url, data);

export const fetchAllUsers = <T extends object>(
  params?: IGetAllUsersParams,
): AxiosPromise<T> => getMethod<T>(urls.users, params);

export const getRoles = <T extends object>(): AxiosPromise<T> =>
  getMethod<T>(urlConstructors.userRoles());

export function getNewUserDataApi<
  SO extends INewUserData<object[]>,
  N extends INewUserData<object[]>,
  C extends INewUserData<object[]>,
  R extends INewUserData<object[]>,
>(): Promise<Array<PromiseSettledResult<AxiosResponse<SO | N | C | R>>>> {
  return Promise.allSettled([
    getSubOrganisationsApi<SO>(),
    getNetworksApi<N>(),
    getCentresApi<C>(),
    getRoles<R>(),
  ]);
}

export function getOneApi<T extends object>(id: number): AxiosPromise<T> {
  return getMethod(urlConstructors.userWithId(id));
}

export function createUserApi<T extends object>(
  data: TUserDto,
): AxiosPromise<T> {
  return postMethod(urls.users, data);
}

export function deleteUserApi<T extends object>(id: number): AxiosPromise<T> {
  return deleteMethod(urlConstructors.userWithId(id));
}

export function deleteUserBulkApi<T extends object>(
  ids: number[],
): AxiosPromise<T> {
  return deleteMethod(urls.users, { ids });
}

export function updateUserApi<T extends object>({
  id,
  data,
}: IUpdateUserProps): AxiosPromise<T> {
  return putMethod(urlConstructors.userWithId(id), data);
}
