import { TextField } from '@mui/material';
import { useFormikContext } from 'formik';
import React, { ChangeEvent } from 'react';

import { regulars } from '../../../../../../../../constants/regular/regulars';
import { texts } from '../../../../../../constants/texts';
import { IInitialValues } from '../../../../types';

const Broadsign: React.FC = () => {
  const { values, errors, isSubmitting, handleChange } =
    useFormikContext<IInitialValues>();

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (!event.target.value || regulars.onlyNumber.test(event.target.value)) {
      handleChange(event);
    }
  };

  return (
    <div className="broadsignId-wrapper">
      <TextField
        label={texts.pages.manage.content.main.inputs.broadsignId}
        name="broadsignId"
        value={values.broadsignId}
        onChange={handleInputChange}
        error={Boolean(errors.broadsignId)}
        helperText={errors.broadsignId}
        disabled={isSubmitting}
      />
    </div>
  );
};

export default Broadsign;
