import { useActions, useTypedSelector } from 'hooks';

interface IScheduleModalHookReturn {
  handleDone: () => void;
  handleAnotherDayPart: () => void;
  handleOpen: () => void;
  handleClose: () => void;
  getIsScheduleContain: () => boolean;
}

const useScheduleModal = (): IScheduleModalHookReturn => {
  const { scheduleCampaignModalData } = useTypedSelector(
    (state) => state && state.manageCampaign.manage,
  );
  const {
    setModalsManageTab,
    setIsBulkScheduleModal,
    doneScheduleModal,
    addAnotherDayPart,
    addAnotherDayPartBulk,
    cleanupScheduleModal,
  } = useActions();

  const handleOpen = () => {
    setModalsManageTab({ type: 'campaignSchedule', value: true });
  };

  const handleClose = () => {
    setModalsManageTab({ type: 'campaignSchedule', value: false });
    cleanupScheduleModal();
    setIsBulkScheduleModal(false);
  };

  const handleDone = () => {
    doneScheduleModal();
    handleClose();
  };

  const handleAnotherDayPart = () => {
    if (scheduleCampaignModalData.isBulk) {
      addAnotherDayPartBulk();

      return;
    }

    addAnotherDayPart();
  };

  const getIsScheduleContain = (): boolean => {
    const isStartDate = !!scheduleCampaignModalData.dates?.from;
    const isEndTime = !!scheduleCampaignModalData.dates?.to;
    const isDateContain = isStartDate && isEndTime;

    const isDayPartsContained = scheduleCampaignModalData.dayParts.every(
      (dayPart) => {
        const isTimeContain = !!dayPart.times;
        const isDayOfWeekExist = Object.values(dayPart.days).some(
          (value) => !!value,
        );

        if (isTimeContain && isDayOfWeekExist) {
          return true;
        }

        return false;
      },
    );

    if (isDateContain && isDayPartsContained) {
      return true;
    }

    return false;
  };

  return {
    handleDone,
    handleAnotherDayPart,
    handleOpen,
    handleClose,
    getIsScheduleContain,
  };
};

export default useScheduleModal;
