import { DialogContent } from '@mui/material';
import { useFormikContext } from 'formik';
import { isRole } from 'helpers/role';
import React from 'react';
import { UserRolesEnum } from 'types';

import { TInitialValues } from '../../utils';
import {
  CentreNetworkSelect,
  NetworkSelect,
  SubOrganisationSelect,
  UserInfo,
  UserPassword,
  UserRole,
} from './components';

const MangeUserContent: React.FC = () => {
  const { values } = useFormikContext<TInitialValues>();

  const isSubOrganisationView = isRole(UserRolesEnum.Advertising, values.role);
  const isCentreNetworksView = isRole(
    [UserRolesEnum.Editor, UserRolesEnum.Advertising, UserRolesEnum.Marketing],
    values.role,
  );
  const isNetworkView = isRole(UserRolesEnum.Admin, values.role);

  return (
    <DialogContent>
      <UserInfo />
      <UserRole />
      {isNetworkView && <NetworkSelect />}
      {isSubOrganisationView && <SubOrganisationSelect />}
      {isCentreNetworksView && <CentreNetworkSelect />}
      {!values.isUpdate && <UserPassword />}
    </DialogContent>
  );
};

export default MangeUserContent;
