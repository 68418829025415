import { IStatesState } from '../../Reducers/states/type';
import { IStatesSetLoadingPayload } from './type';

export enum StatesActionEnum {
  /* <--------- States ---------> */

  SET_STATES = 'SET_STATES',

  /* <--------- Loadings ---------> */

  SET_LOADING = 'SET_LOADING_STATES',
}

interface ISetStates {
  type: StatesActionEnum.SET_STATES;
  payload: IStatesState['states'];
}

interface ISetLoading {
  type: StatesActionEnum.SET_LOADING;
  payload: IStatesSetLoadingPayload;
}

export type TStatesActions = ISetStates | ISetLoading;
