import { convertToNumberFromFormats } from 'helpers/convert';
import { IRecentlyAddedPayload } from 'redux/Actions/campaignsActions/manage/type';
import { IAddMediaModal } from 'redux/Reducers/campaignsReducer/manage/manage/type';
import {
  ICampaignSchedulesNew,
  ICampaignSitesNew,
  IMedia,
  IMediaForCreateCampaign,
} from 'types';

import {
  getCampaignSchedule,
  getSelectedCampaignSchedules,
  getSelectedCampaignSites,
  parseSubRowsIds,
} from '..';

export const getRecentlyPayload = (
  campaignSites: ICampaignSitesNew[],
  campaignScheduleId: number | string,
): IRecentlyAddedPayload => {
  const idsAndTags = campaignSites.reduce<{ ids: number[]; tags: string[] }>(
    (acc, campaignSite) => {
      const idsList = [...acc.ids];
      const tags = [...acc.tags];

      campaignSite.campaignSchedules.forEach((campaignSchedule) => {
        campaignSchedule.scheduleMedias.forEach((scheduleMedia) => {
          idsList.push(scheduleMedia.mediaId);

          if (scheduleMedia.tags) {
            tags.push(scheduleMedia.tags);
          }
        });
      });

      return { ids: idsList, tags };
    },
    { ids: [], tags: [] },
  );

  const { campaignSite } = getCampaignSchedule(
    campaignSites,
    campaignScheduleId,
  );

  return {
    ids: idsAndTags.ids,
    tags: idsAndTags.tags.join(','),
    screenSize: campaignSite.screenSize,
    formats: convertToNumberFromFormats(campaignSite.formats),
  };
};

/** @param selected is campaignSchedulesIds */
export const getSelectedMedias = (
  campaignSites: ICampaignSitesNew[],
  selected?: Array<string | number> | string | number,
): number[] => {
  if (!selected) {
    return campaignSites.reduce<number[]>((acc, campaignSite) => {
      const idsList = [...acc];

      campaignSite.campaignSchedules.forEach((campaignSchedule) => {
        campaignSchedule.scheduleMedias.forEach((scheduleMedia) => {
          idsList.push(scheduleMedia.mediaId);
        });
      });

      return idsList;
    }, []);
  }

  if (Array.isArray(selected)) {
    const schedulesIds = selected
      .map((id) => parseSubRowsIds(id))
      .filter((id) => !!id);

    return campaignSites.reduce<number[]>((acc, campaignSite) => {
      const idsList = [...acc];

      campaignSite.campaignSchedules.forEach((campaignSchedule) => {
        if (schedulesIds.includes(campaignSchedule.id)) {
          campaignSchedule.scheduleMedias.forEach((scheduleMedia) => {
            idsList.push(scheduleMedia.mediaId);
          });
        }
      });

      return idsList;
    }, []);
  }

  return campaignSites.reduce<number[]>((acc, campaignSite) => {
    const idsList = [...acc];

    campaignSite.campaignSchedules.forEach((campaignSchedule) => {
      if (selected === campaignSchedule.id) {
        campaignSchedule.scheduleMedias.forEach((scheduleMedia) => {
          idsList.push(scheduleMedia.mediaId);
        });
      }
    });

    return idsList;
  }, []);
};

export const getIsMediaAlreadyAdded = (
  campaignSchedule: ICampaignSchedulesNew | undefined,
  currentMedia: IMedia,
): boolean => {
  if (!campaignSchedule) {
    return false;
  }

  const lookingMedia = campaignSchedule.scheduleMedias.find(
    (media) => media.mediaId === currentMedia.id,
  );

  if (lookingMedia) {
    return true;
  }

  return false;
};

export const getIsMediaSelected = (
  selectedStore: IAddMediaModal['selectedMedias'],
  currentMedia: IMedia,
): boolean => {
  const findInSelectedStore = selectedStore.selectedTab.find(
    (media) => media.mediaId === currentMedia.id,
  );

  if (findInSelectedStore) {
    return true;
  }

  const findInRecently = selectedStore.recentlyTab.find(
    (media) => media.mediaId === currentMedia.id,
  );

  if (findInRecently) {
    return true;
  }

  const findInAllTab = selectedStore.allTab.find(
    (media) => media.mediaId === currentMedia.id,
  );

  if (findInAllTab) {
    return true;
  }

  return false;
};

interface IAddMediaInspectorReturn {
  dimension: boolean;
  duration: boolean;
  formats: boolean;
}

export const addMediaModalBulkInspector = (
  campaignSites: ICampaignSitesNew[],
  selectedIds: Array<number | string>,
): IAddMediaInspectorReturn => {
  const result = {
    dimension: false,
    duration: false,
    formats: false,
  };

  const selectedSites = getSelectedCampaignSites(campaignSites, selectedIds);

  const dimension = selectedSites[0].screenSize;
  const { formats } = selectedSites[0];

  const isDimension = selectedSites.every(
    (campaignSite) => campaignSite.screenSize === dimension,
  );

  if (!isDimension) {
    return result;
  }

  result.dimension = isDimension;

  const isFormats = selectedSites.every(
    (campaignSite) => campaignSite.formats === formats,
  );

  if (!isFormats) {
    return result;
  }

  result.formats = isFormats;

  const { selectedCampaignSchedules } = getSelectedCampaignSchedules(
    campaignSites,
    selectedIds,
  );

  const { duration } = selectedCampaignSchedules[0];

  const isDuration = selectedCampaignSchedules.every(
    (campaignSchedule) => campaignSchedule.duration === duration,
  );

  if (!isDuration) {
    return result;
  }

  result.duration = isDuration;

  return result;
};

export const getMediasUniqBulk = (
  ...args: IMediaForCreateCampaign[][]
): IMediaForCreateCampaign[] => {
  const allMedias = args.reduce<IMediaForCreateCampaign[]>(
    (acc, scheduleMedias) => {
      const medias = [...acc];

      scheduleMedias.forEach((media) => {
        medias.push(media);
      });

      return medias;
    },
    [],
  );

  return allMedias.reduce<IMediaForCreateCampaign[]>((acc, scheduleMedia) => {
    const medias = [...acc];

    const isAlreadyExist = medias.some(
      (media) => media.mediaId === scheduleMedia.mediaId,
    );

    if (!isAlreadyExist) {
      medias.push(scheduleMedia);
    }

    return medias;
  }, []);
};
