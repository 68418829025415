import React from 'react';

const OrDivider: React.FC = () => {
  return (
    <div className="or-divider-wrapper">
      <div className="divider"></div>
      <span className="or-span">OR</span>
    </div>
  );
};

export default OrDivider;
