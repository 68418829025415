import { AxiosPromise } from 'axios';

import { AxiosMethodEnum } from '../../../constants/text/text';
import { urls } from '../../../constants/urls/url';
import { apiCall } from '../../../helpers/api/apiCall';

function postMethodAuth<T extends object>(
  url: string,
  data: object,
): AxiosPromise<T> {
  return apiCall<T>(AxiosMethodEnum.post, url, data, null, '', {});
}

export const loginUser = <T extends object>(data: object): AxiosPromise<T> =>
  postMethodAuth<T>(urls.signIn, data);

export const userWithNewPassword = <T extends object>(
  data: object,
): AxiosPromise<T> => postMethodAuth<T>(urls.resetPassword, data);

export const forgotPasswordMessage = <T extends object>(
  data: object,
): AxiosPromise<T> => postMethodAuth<T>(urls.forgotPassword, data);

export const loginMagicLink = <T extends object>(
  data: object,
): AxiosPromise<T> => postMethodAuth<T>(urls.magicLink, data);
