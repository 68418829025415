import { Button } from '@mui/material';
import { withStyles } from 'tss-react/mui';

const SButton = withStyles(Button, {
  root: {
    minWidth: 40,
    width: 40,
    height: 40,
  },
});

export { SButton };
