import { TextField } from '@mui/material';
import { useFormikContext } from 'formik';
import React, { memo } from 'react';

import { TInitialValues } from '../../../../utils';
import { SBox } from './UserPassword.styled';

const UserPassword: React.FC = () => {
  const { values, touched, errors, handleChange, isSubmitting } =
    useFormikContext<TInitialValues>();

  return (
    <SBox>
      <TextField
        id="password"
        name="password"
        label="Password"
        type="password"
        value={values.password}
        onChange={handleChange}
        error={touched.password && Boolean(errors.password)}
        helperText={touched.password && errors.password}
        disabled={isSubmitting}
      />
      <TextField
        id="confirmPassword"
        name="confirmPassword"
        label="Confirm password"
        type="password"
        value={values.confirmPassword}
        onChange={handleChange}
        error={touched.confirmPassword && Boolean(errors.confirmPassword)}
        helperText={touched.confirmPassword && errors.confirmPassword}
        disabled={isSubmitting}
      />
    </SBox>
  );
};

export default memo(UserPassword);
