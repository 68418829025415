import { millisecondsToSeconds, secondsToMilliseconds } from 'date-fns';
import moment from 'moment';

import { momentFormats } from '../../../constants/date/date';
import { FormatsEnum } from '../../../constants/file/formats';
import { radix } from '../../../constants/variables';
import { convertToSelectData } from '../../../helpers';
import { ISingleSite, ISiteDayPartSingleSite, ISiteDto } from '../../../types';
import { IDayPartsForm, IInitialValues } from '../shared/SiteForm/types';
import { dayPartsInspector } from './inspectors';

const { fromObjectIdWithName, fromFormats } = convertToSelectData;

export const createInitialValueFromSite = (
  site: ISingleSite,
): IInitialValues => {
  const siteDayPartsScheme = site.siteDayParts.map((item) => {
    return {
      id: item.id,
      rangeTime: [
        moment(item.startTime, momentFormats.time().fullTime.default),
        moment(item.endTime, momentFormats.time().fullTime.default),
      ],
      duration: String(millisecondsToSeconds(item.duration)),
      siteFrames: item.siteFrames,
    } as IDayPartsForm;
  });

  const formats = site.formats.split(', ') as FormatsEnum[];

  return {
    name: site.name,
    address: site.address,
    broadsignId: String(site.broadsignId),
    centre: fromObjectIdWithName(site.centre),
    network: fromObjectIdWithName(site.network),
    resolution: fromObjectIdWithName(site.resolution),
    formats: fromFormats(formats),
    siteDayParts: siteDayPartsScheme,
    isUpdate: true,
  };
};

export const createSchemeToQuery = (data: IInitialValues): ISiteDto => {
  const dayPartFiltered = data.siteDayParts.filter((dayPart) =>
    dayPartsInspector(dayPart),
  );

  const dayPartScheme: ISiteDayPartSingleSite[] = dayPartFiltered.map(
    (dayPart) => {
      const duration = secondsToMilliseconds(
        parseInt(dayPart.duration || '0', radix[10]),
      );

      const siteFrames = dayPart.siteFrames.map((frame) => {
        return {
          ...frame,
          id: Number(frame.id) || 0,
        };
      });

      if (dayPart.rangeTime) {
        return {
          id: Number(dayPart.id) || 0,
          startTime: moment(dayPart.rangeTime[0]).format('HH:mm:ss'),
          endTime: moment(dayPart.rangeTime[1]).format('HH:mm:ss'),
          duration,
          siteFrames,
        };
      }

      return {
        id: Number(dayPart.id) || 0,
        startTime: '00:00:00',
        endTime: '00:00:00',
        duration,
        siteFrames,
      };
    },
  );

  const formatsCount = data.formats
    ? data.formats.reduce<number>((acc, format) => {
        return acc + format.value;
      }, 0)
    : 0;

  return {
    name: data.name,
    address: data.address,
    centreId: Number(data.centre?.value),
    networkId: Number(data.network?.value),
    formats: formatsCount,
    resolutionId: Number(data.resolution?.value),
    broadsignId: Number(data.broadsignId),
    siteDayParts: dayPartScheme,
  };
};
