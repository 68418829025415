/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { TDaysOfWeek, TDaysOfWeekFull, TSeparator } from '../../types';

export const dateFnsFormats = {
  /**
   * Return value like dd.MM.yyyy
   */
  default: (separator: TSeparator = '.'): string =>
    `dd${separator}MM${separator}yyyy`,
};

export const momentFormats = {
  date: (separator: TSeparator = '.') => ({
    default: {
      full: `DD${separator}MM${separator}YYYY`,
    },
    textMonth: {
      full: `D${separator}MMM${separator}YYYY`,
      short: `D${separator}MMM`,
    },
  }),
  time: (separator: TSeparator = ':') => ({
    fullTime: {
      default: `HH${separator}mm${separator}ss`,
      use12Hours: `hh${separator}mm${separator}ss a`,
      use12HoursUpper: `hh${separator}mm${separator}ss A`,
    },
    hoursWithMinutes: {
      default: `HH${separator}mm`,
      use12Hours: `hh${separator}mm a`,
      use12HoursUpper: `hh${separator}mm A`,
    },
  }),
};

export const getInitialDaysOfWeek = (
  value = true,
): Readonly<Record<TDaysOfWeek, boolean>> => {
  return {
    Mon: value,
    Tue: value,
    Wed: value,
    Thu: value,
    Fri: value,
    Sat: value,
    Sun: value,
  };
};

export const dayOsWeekFull: Readonly<Record<TDaysOfWeek, TDaysOfWeekFull>> = {
  Mon: 'Monday',
  Tue: 'Tuesday',
  Wed: 'Wednesday',
  Thu: 'Thursday',
  Fri: 'Friday',
  Sat: 'Saturday',
  Sun: 'Sunday',
};

export const daysOfWeekIndexes: Record<TDaysOfWeekFull, number> = {
  Monday: 1,
  Tuesday: 2,
  Wednesday: 4,
  Thursday: 8,
  Friday: 16,
  Saturday: 32,
  Sunday: 64,
};
