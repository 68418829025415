import produce from 'immer';
import {
  ManageCampaignDetailActionEnum,
  TManageCampaignDetailAction,
} from 'redux/ActionTypes';

import { IInitialCampaignDetail } from './type';

const initialValues: IInitialCampaignDetail = {
  campaigns: [],
  searchCampaign: '',
  campaignName: '',
  loaders: {},
  modals: { copyWarning: false },
  isAutoSubstitutionCategory: true,
};

const campaignDetailReducer = produce(
  (draft: IInitialCampaignDetail, action: TManageCampaignDetailAction) => {
    switch (action.type) {
      case ManageCampaignDetailActionEnum.CONTAIN_DETAIL_MANAGE_CAMPAIGN_DATA: {
        draft.campaignName = action.payload.campaignName;
        draft.campaignType = action.payload.campaignType;
        draft.advertiser = action.payload.advertiser;
        draft.product = action.payload.product;
        draft.category = action.payload.category;

        return draft;
      }

      case ManageCampaignDetailActionEnum.SET_CAMPAIGN_FOR_MANAGE: {
        draft.campaigns = action.payload;

        return draft;
      }

      case ManageCampaignDetailActionEnum.SET_CAMPAIGN_NAME_MANAGE: {
        draft.campaignName = action.payload;

        return draft;
      }

      case ManageCampaignDetailActionEnum.SET_SEARCH_CAMPAIGN_MANAGE: {
        draft.searchCampaign = action.payload;

        return draft;
      }

      case ManageCampaignDetailActionEnum.SET_CAMPAIGN_TYPE: {
        draft.campaignType = action.payload;

        return draft;
      }

      case ManageCampaignDetailActionEnum.SET_ADVERTISER: {
        draft.advertiser = action.payload;

        return draft;
      }

      case ManageCampaignDetailActionEnum.SET_PRODUCT: {
        draft.product = action.payload;

        return draft;
      }

      case ManageCampaignDetailActionEnum.SET_CATEGORY: {
        draft.category = action.payload;

        return draft;
      }

      case ManageCampaignDetailActionEnum.SET_AUTO_SUBSTITUTION_CATEGORY: {
        draft.isAutoSubstitutionCategory = action.payload;

        return draft;
      }

      case ManageCampaignDetailActionEnum.SET_LOADING_MANAGE_CAMPAIGN_DETAIL: {
        draft.loaders[action.payload.type] = action.payload.value;

        return draft;
      }

      case ManageCampaignDetailActionEnum.SET_MODAL_MANAGE_CAMPAIGN_DETAIL: {
        draft.modals[action.payload.type] = action.payload.value;

        return draft;
      }

      case ManageCampaignDetailActionEnum.SET_DRAFT_MANAGE_CAMPAIGN_DETAIL: {
        draft.draft = { ...draft };

        return draft;
      }

      case ManageCampaignDetailActionEnum.APPLY_DRAFT_MANAGE_CAMPAIGN_DETAIL: {
        if (draft?.draft) {
          draft = { ...draft.draft };
          draft.modals = initialValues.modals;
          draft.loaders = initialValues.loaders;

          return draft;
        }

        draft = initialValues;

        return draft;
      }

      case ManageCampaignDetailActionEnum.CLEANUP_MANAGE_CAMPAIGN_DETAIL_DATA: {
        draft = { ...initialValues };

        return draft;
      }

      default:
        return draft;
    }
  },
  initialValues,
);

export default campaignDetailReducer;
