import { Row, RowSelectionState } from '@tanstack/react-table';
import { useActions, useTypedSelector } from 'hooks';
import { IUserShort } from 'types';

interface IUsersContentRowSelectionHookReturn {
  readonly onRowClick: (row: Row<IUserShort>, value: boolean) => void;
  readonly rowSelection: RowSelectionState;
}

const useUsersContentRowSelection = (): IUsersContentRowSelectionHookReturn => {
  const { allUsersTable } = useTypedSelector((state) => state && state.users);
  const { allUserTableSetSelectedUser } = useActions();

  const onRowClick = ({ original }: Row<IUserShort>, value: boolean) => {
    const currentSelectedUser = [...allUsersTable.selectedUsers];

    if (value) {
      currentSelectedUser.push(original);
      allUserTableSetSelectedUser(currentSelectedUser);
    } else {
      allUserTableSetSelectedUser(
        currentSelectedUser.filter((item) => original.id !== item.id),
      );
    }
  };

  const rowSelection = allUsersTable.selectedUsers.reduce<RowSelectionState>(
    (acc, item) => {
      acc[String(item.id)] = true;

      return acc;
    },
    {},
  );

  return { onRowClick, rowSelection };
};

export default useUsersContentRowSelection;
