import { FormHelperText, MenuItem } from '@mui/material';
import { useFormikContext } from 'formik';
import { useTypedSelector } from 'hooks/useTypedSelector';
import React, { memo } from 'react';

import { TInitialValues } from '../../../../utils';
import {
  SFormControl,
  SInputLabel,
  SSelect,
} from '../../ManageUserContent.styled';

const SubOrganisationSelect: React.FC = () => {
  const { manageUserData } = useTypedSelector((state) => state && state.users);
  const { values, touched, errors, handleChange, isSubmitting } =
    useFormikContext<TInitialValues>();

  return (
    <SFormControl fullWidth>
      <SInputLabel
        id="user-subOrganisation-label"
        error={touched.subOrganisation && Boolean(errors.subOrganisation)}
      >
        SubOrganisation
      </SInputLabel>
      <SSelect
        labelId="user-subOrganisation-label"
        id="select-user-subOrganisation"
        name="subOrganisation"
        label="SubOrganisation"
        value={values.subOrganisation}
        disabled={!manageUserData.subOrganisations.length || isSubmitting}
        onChange={handleChange}
        error={touched.subOrganisation && Boolean(errors.subOrganisation)}
      >
        {manageUserData.subOrganisations.map((subOrganisation) => (
          <MenuItem key={subOrganisation.id} value={subOrganisation.id}>
            {subOrganisation.name}
          </MenuItem>
        ))}
      </SSelect>
      {touched.subOrganisation && Boolean(errors.subOrganisation) && (
        <FormHelperText
          error={touched.subOrganisation && Boolean(errors.subOrganisation)}
        >
          {errors.subOrganisation}
        </FormHelperText>
      )}
    </SFormControl>
  );
};

export default memo(SubOrganisationSelect);
