export const urlParams = {
  filters: 'filters',
  types: 'types',
  draft: 'draft',
  selected: 'selected',
  current: 'current',
  published: 'published',
  recentlyAdded: 'recently-added',
  new: 'new',
  edit: 'edit',
  review: 'review',
  id: ':id',
  suborganisations: 'suborganisations',
  roles: 'roles',
  bulkupload: 'bulkupload',
  schedules: 'schedules',
  reject: 'reject',
  approve: 'approve',
  stop: 'stop',
} as const;

export const urls = {
  root: '/',
  signIn: '/sign-in',
  login: '/login',
  forgot: '/forgot',
  reset: '/reset',
  changePortal: '/change-portal',
  resetPassword: '/reset-password',
  magicLink: '/magic-link',
  campaign: '/campaign',
  campaigns: '/campaigns',
  editCampaign: '/edit-campaign',
  newCampaign: '/new-campaign',
  advertisers: '/advertisers',
  categories: '/categories',
  categoryProductCache: '/category-map',
  products: '/products',
  forgotPassword: '/forgot-password',
  sites: '/sites',
  media: '/media',
  users: '/users',
  organisations: '/organisations',
  networks: '/networks',
  centres: '/centres',
  states: '/states',
  resolutions: '/resolutions',
  orientations: '/orientations',
  redirect: 'redirect',
} as const;

export const urlConstructors = {
  /* <----------- Campaign -----------> */

  newCampaign: (): string => `${urls.campaign}/${urlParams.new}`,
  editCampaign: (id: number | string): string =>
    `${urls.campaign}/${id}/${urlParams.edit}`,
  reviewCampaign: (id: number | string): string =>
    `${urls.campaign}/${id}/${urlParams.review}`,
  campaignFilters: (): string => `${urls.campaigns}/${urlParams.filters}`,
  campaignTypes: (): string => `${urls.campaigns}/${urlParams.types}`,
  campaignReject: (id: number | string): string =>
    `${urls.campaigns}/${id}/${urlParams.reject}`,
  campaignApprove: (id: number | string): string =>
    `${urls.campaigns}/${id}/${urlParams.approve}`,
  campaignStop: (id: number | string): string =>
    `${urls.campaigns}/${id}/${urlParams.stop}`,
  campaignDraft: (id?: number | string): string => {
    if (id) {
      return `${urls.campaigns}/${id}/${urlParams.draft}`;
    }

    return `${urls.campaigns}/${urlParams.draft}`;
  },
  campaignPublish: (id?: number | string): string => {
    if (id) {
      return `${urls.campaigns}/${id}/${urlParams.published}`;
    }

    return `${urls.campaigns}/${urlParams.published}`;
  },

  /* <----------- Sites -----------> */

  editSite: (id: number): string => `${urls.sites}/${id}/${urlParams.edit}`,
  newSite: (): string => `${urls.sites}/${urlParams.new}`,
  sitesSelected: (): string => `${urls.sites}/${urlParams.selected}`,
  sitesFilters: (): string => `${urls.sites}/${urlParams.filters}`,
  siteWithId: (id: number | string): string => `${urls.sites}/${id}`,
  siteSchedules: (id: number | string): string =>
    `${urls.sites}/${id}/${urlParams.schedules}`,

  /* <----------- Media -----------> */

  mediaFilters: (): string => `${urls.media}/${urlParams.filters}`,
  mediaRecently: (): string => `${urls.media}/${urlParams.recentlyAdded}`,
  mediaSelected: (): string => `${urls.media}/${urlParams.selected}`,
  mediaBulkUpload: (): string => `${urls.media}/${urlParams.bulkupload}`,

  /* <----------- Organisation -----------> */

  organisation: (): string => `${urls.organisations}/${urlParams.current}`,
  subOrganisations: (): string =>
    `${urls.organisations}/${urlParams.suborganisations}`,

  /* <----------- Users -----------> */

  userWithId: (id: number): string => `${urls.users}/${id}`,
  userRoles: (): string => `${urls.users}/${urlParams.roles}`,
} as const;
