import { AxiosPromise } from 'axios';

import { AxiosMethodEnum } from '../../../constants/text/text';
import { urls } from '../../../constants/urls/url';
import { apiCall } from '../../../helpers/api/apiCall';
import { INewNetworkPayload } from './type';

const getMethodNetworks = <T extends object>(url: string): AxiosPromise<T> => {
  return apiCall<T>(AxiosMethodEnum.get, url);
};

const postMethodNetwork = <T extends object>(
  url: string,
  data?: object,
): AxiosPromise<T> => {
  return apiCall<T>(AxiosMethodEnum.post, url, data);
};

export const getNetworksApi = <
  T extends object | Array<object>,
>(): AxiosPromise<T> => getMethodNetworks<T>(urls.networks);

export const createNewNetworkApi = <T extends object | Array<object>>(
  data: INewNetworkPayload,
): AxiosPromise<T> => postMethodNetwork<T>(urls.networks, data);
