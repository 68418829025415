import './React-Table.scss';

import { rankItem } from '@tanstack/match-sorter-utils';
import {
  ColumnOrderState,
  FilterFn,
  getCoreRowModel,
  getExpandedRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  RowSelectionState,
  SortingState,
  useReactTable,
} from '@tanstack/react-table';
import classnames from 'classnames';
import React, {
  forwardRef,
  ReactElement,
  Ref,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { IObjectWithId } from 'types';

import {
  TableActions,
  TablePagination,
  TBody,
  TBodyLoading,
  TFooter,
  THeader,
} from './components';
import useTableHook from './hook';
import { IDataSubRows, ITableRefObject, TTable } from './types';

const TableComponent = <TData extends IObjectWithId, TColumns = unknown>(
  {
    data,
    columns,
    initialState,
    state,
    rowSelecting = false,
    loading = false,
    itemsCount = 10,
    className,
    isFullWidth,
    isSearchable,
    isSSR,
    hasPagination,
    pagination,
    sort,
    actions,
    totalDataCount,
    globalSearch = '',
    indexing = 'id',
    showDefaultActions = true,
    onRowClick,
    onSelectedChange,
    handleGlobalSearch,
    handleChangePageSize,
    handleChangePageNumber,
    handleChangeSort,
    ...otherTableOptions
  }: TTable<TData, TColumns>,
  ref: Ref<ITableRefObject<TData>>,
): ReactElement => {
  const [selected, setSelected] = useState<RowSelectionState>(
    state?.rowSelection || initialState?.rowSelection || {},
  );
  const [sorting, setSorting] = React.useState<SortingState>([]);
  const [columnVisibility, setColumnVisibility] = useState({});
  const [columnOrder, setColumnOrder] = useState<ColumnOrderState>([]);
  const [globalTableSearch, setGlobalTableSearch] = useState<string>('');

  useEffect(() => {
    setSelected(state?.rowSelection || {});
  }, [state?.rowSelection]);

  const globalFilterFn: FilterFn<TData> = (row, columnId, value, addMeta) => {
    const itemRank = rankItem(row.getValue(columnId), value);

    addMeta({
      itemRank,
    });

    return itemRank.passed;
  };

  /** It is need for overwriting default id */
  const getRowIdAsDataId = (originalRow: TData): string => {
    return String(originalRow.id);
  };

  const getRowIdAsIndex = (__: TData, index: number): string => {
    return String(index);
  };

  const table = useReactTable<TData>({
    data,
    columns,
    state: {
      sorting,
      rowSelection: selected,
      columnVisibility,
      columnOrder,
      globalFilter: globalTableSearch,
      ...state,
    },
    filterFns: {
      fuzzy: globalFilterFn,
    },
    initialState,
    onRowSelectionChange: setSelected,
    onColumnVisibilityChange: setColumnVisibility,
    onColumnOrderChange: setColumnOrder,
    onGlobalFilterChange: setGlobalTableSearch,
    onSortingChange: setSorting,
    globalFilterFn,
    getSubRows: (row) => (row as IDataSubRows<TData>).subRows,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getRowId: indexing === 'id' ? getRowIdAsDataId : getRowIdAsIndex,
    ...otherTableOptions,
  });

  const { handleRowClick, handleChangeGlobalSearch } = useTableHook({
    isSSR,
    table,
    selected,
    rowSelecting,
    onRowClick,
    onSelectedChange,
    handleGlobalSearch,
    setGlobalTableSearch,
  });

  const actionsRef = useRef<Array<HTMLButtonElement>>(null);

  useImperativeHandle(
    ref,
    () => ({
      table,
      actionsEls: actionsRef,
    }),
    [columns, data, actions],
  );

  const isActionsPanelView = Boolean(actions || isSearchable);

  return (
    <div
      className={classnames('__table-wrapper', {
        'w-full': isFullWidth,
      })}
    >
      {isActionsPanelView && (
        <TableActions
          show={showDefaultActions}
          loading={loading}
          actions={actions}
          ref={actionsRef}
          table={table}
          isSearchable={isSearchable}
          globalSearch={isSSR ? globalSearch : globalTableSearch}
          handleGlobalSearch={handleChangeGlobalSearch}
        />
      )}
      <div>
        <table className={classnames(className, '__table')}>
          <THeader
            table={table}
            isSSR={isSSR}
            sort={sort}
            loading={loading}
            handleChangeSort={handleChangeSort}
          />
          {loading ? (
            <TBodyLoading
              rowsNumber={itemsCount}
              columnsNumber={table.getAllColumns().length}
            />
          ) : (
            <TBody
              table={table}
              handleRowClick={handleRowClick}
              rowSelecting={rowSelecting}
            />
          )}
          <TFooter table={table} />
        </table>
      </div>

      {hasPagination && (
        <TablePagination
          table={table}
          data={data}
          totalDataCount={totalDataCount}
          isSSR={isSSR}
          loading={loading}
          pagination={pagination}
          handleChangePageSize={handleChangePageSize}
          handleChangePageNumber={handleChangePageNumber}
        />
      )}
    </div>
  );
};

const Table = forwardRef(TableComponent) as <T>(
  props: TTable<T> & { ref?: Ref<ITableRefObject<T>> },
) => ReturnType<typeof TableComponent>;

export default Table;
