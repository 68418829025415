import { Button } from 'components/common';
import { withStyles } from 'tss-react/mui';

const SAnotherDayPartButton = withStyles(Button, {
  root: {
    textTransform: 'unset',
    textDecoration: 'underline',

    '&:hover': {
      textDecoration: 'underline',
    },
  },
});

export { SAnotherDayPartButton };
