import { CircularProgress } from '@mui/material';
import React from 'react';

import useLoadingHook from './hook';

export enum LoaderSizesEnum {
  xs = 'xs',
  xm = 'xm',
  xl = 'xl',
}

export const MainLoading: React.FC = () => {
  const { classes } = useLoadingHook();

  return (
    <div className={classes.root}>
      <CircularProgress className={classes.circle} />
    </div>
  );
};

export const LocalLoading: React.FC = () => {
  const { classes } = useLoadingHook();

  return (
    <div className={classes.localRoot}>
      <CircularProgress className={classes.localCircle} />
    </div>
  );
};

interface IIconLoadingProps {
  size?: LoaderSizesEnum;
}

const { xs, xm, xl } = LoaderSizesEnum;

export const IconLoading: React.FC<IIconLoadingProps> = ({ size = xs }) => {
  const { classes } = useLoadingHook();
  const getSize = (sizeValue: string) => {
    switch (sizeValue) {
      case xm:
        return classes.iconCircleXm;
      case xl:
        return classes.iconCircleXl;
      default:
        return classes.iconCircleXs;
    }
  };

  return <CircularProgress className={getSize(size)} />;
};
