import { ManageCampaignTabsEnum } from 'constants/enums/campaign';
import React from 'react';

import { STabPanel } from '../Tabs.styled';
import { ManageTabContent, ManageTabFooter, Modals } from './components';

const ManageTab: React.FC = () => {
  return (
    <STabPanel value={ManageCampaignTabsEnum.MANAGE}>
      <ManageTabContent />
      <ManageTabFooter />
      <Modals />
    </STabPanel>
  );
};

export default ManageTab;
