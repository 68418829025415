export interface IUrlState {
  location: string;
}

export enum UrlActionTypesEnum {
  POST_URL_LOCATION = 'POST_URL_LOCATION',
}

interface IPostUrlLocationAction {
  type: UrlActionTypesEnum.POST_URL_LOCATION;
  payload: { location: string };
}

export type TUrlStateAction = IPostUrlLocationAction;
