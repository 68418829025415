import { format } from 'date-fns';
import moment from 'moment';
import {
  ICampaignSchedulesNew,
  ICampaignSitesNew,
  IDateRange,
  IMediaForCreateCampaign,
  ISiteDayPartsNew,
  ITimeRange,
} from 'types';

type TId = string | number;

export type TCampaignDates =
  | string
  | IDateRange<Date | undefined>[]
  | undefined;
export type TCampaignTimes =
  | string
  | ITimeRange<string | undefined>[]
  | undefined;
export type TCampaignScheduleIds = TId | TId[];
export type TCampaignDayOsWeeks = string | string[] | undefined;
export type TCampaignDuration = number | number[] | number[][] | undefined;

export interface ICampaignSitesDataTable {
  id: TId;
  campaignScheduleIds?: TCampaignScheduleIds;
  siteId: number;
  name: string;
  centre: string;
  state: string;
  dimensions: string;
  duration: TCampaignDuration;
  campaignDates: TCampaignDates;
  times: TCampaignTimes;
  dayParting: TCampaignDayOsWeeks;
  formats: string;
  schedulesMedias: IMediaForCreateCampaign[];
  subRows?: TCampaignSitesDataTableSubRows;
}

export type TCampaignSitesDataTableSubRows =
  | Omit<
      ICampaignSitesDataTable,
      'siteId' | 'name' | 'centre' | 'state' | 'dimensions' | 'formats'
    >[]
  | undefined;

const getCampaignSchedulesIds = (
  campaignSchedules: ICampaignSchedulesNew[],
) => {
  if (!campaignSchedules.length) {
    return undefined;
  }

  if (campaignSchedules.length === 1) {
    return campaignSchedules[0].id;
  }

  return campaignSchedules.map((campaignSchedule) => campaignSchedule.id);
};

const constructorCampaignDate = (
  campaignSchedules: ICampaignSchedulesNew,
): string | undefined => {
  if (campaignSchedules.startDate && campaignSchedules.endDate) {
    const parsedStartDate = format(campaignSchedules.startDate, 'dd MMM');
    const parsedEndDate = format(campaignSchedules.endDate, 'dd MMM y');

    return `${parsedStartDate} - ${parsedEndDate}`;
  }
};

const constructorCampaignTimes = (
  campaignSchedules: ICampaignSchedulesNew,
): string | undefined => {
  if (!campaignSchedules.startTime && !campaignSchedules.endTime) {
    return;
  }

  const parsedStartTime = moment(
    campaignSchedules.startTime,
    'kk:mm:ss',
  ).format('hh:mm a');
  const parsedEndTime = moment(campaignSchedules.endTime, 'kk:mm:ss').format(
    'hh:mm a',
  );

  return `${parsedStartTime} - ${parsedEndTime}`;
};

const getCampaignDatesFromSchedules = (
  campaignSchedules: ICampaignSchedulesNew[],
): TCampaignDates => {
  if (!campaignSchedules.length) {
    return;
  }

  if (campaignSchedules.length === 1) {
    return constructorCampaignDate(campaignSchedules[0]);
  }

  return campaignSchedules.map((campaignSchedule) => ({
    startDate: campaignSchedule.startDate,
    endDate: campaignSchedule.endDate,
  }));
};

const getCampaignTimesFromSchedules = (
  campaignSchedules: ICampaignSchedulesNew[],
): TCampaignTimes => {
  if (!campaignSchedules.length) {
    return;
  }

  if (campaignSchedules.length === 1) {
    return constructorCampaignTimes(campaignSchedules[0]);
  }

  return campaignSchedules.map((campaignSchedule) => ({
    startTime: campaignSchedule.startTime,
    endTime: campaignSchedule.endTime,
  }));
};

const getCampaignDayOfWeeksFromSchedules = (
  campaignSchedules: ICampaignSchedulesNew[],
): TCampaignDayOsWeeks => {
  if (!campaignSchedules.length) {
    return;
  }

  if (campaignSchedules.length === 1) {
    return campaignSchedules[0].dayOfWeek;
  }

  return campaignSchedules.map(
    (campaignSchedule) => campaignSchedule.dayOfWeek || '',
  );
};

const getCampaignDurations = (
  campaignSchedules: ICampaignSchedulesNew[],
  siteDayParts: ISiteDayPartsNew[],
): TCampaignDuration => {
  if (!campaignSchedules.length) {
    if (siteDayParts.length === 1) {
      return siteDayParts[0].adLength;
    }

    return siteDayParts.map((siteDayPart) => siteDayPart.adLength);
  }

  if (campaignSchedules.length === 1) {
    return campaignSchedules[0].duration;
  }

  if (siteDayParts.length === 1) {
    return campaignSchedules.map(() => [siteDayParts[0].adLength]);
  }

  return campaignSchedules.map(() => {
    return siteDayParts.map((siteDayPart) => siteDayPart.adLength);
  });
};

const getSchedulesMedia = (
  campaignSchedules: ICampaignSchedulesNew[],
): IMediaForCreateCampaign[] => {
  if (!campaignSchedules.length) {
    return [];
  }

  if (campaignSchedules.length === 1) {
    return campaignSchedules[0].scheduleMedias;
  }

  return campaignSchedules.reduce<IMediaForCreateCampaign[]>(
    (acc, campaignSchedule) => {
      const accumulator = [...acc];

      campaignSchedule.scheduleMedias.forEach((media) =>
        accumulator.push(media),
      );

      return accumulator;
    },
    [],
  );
};

const getSubRows = (
  campaignSite: ICampaignSitesNew,
): TCampaignSitesDataTableSubRows => {
  const { campaignSchedules } = campaignSite;

  if (!campaignSchedules.length || campaignSchedules.length === 1) {
    return;
  }

  return campaignSchedules.map((campaignSchedule) => ({
    id: `${campaignSite.id}.${campaignSchedule.id}`,
    campaignScheduleIds: campaignSchedule.id,
    duration: campaignSchedule.duration,
    campaignDates: constructorCampaignDate(campaignSchedule),
    times: constructorCampaignTimes(campaignSchedule),
    schedulesMedias: campaignSchedule.scheduleMedias,
    dayParting: campaignSchedule.dayOfWeek,
  }));
};

export const createTableData = (
  campaignSites: ICampaignSitesNew[],
): ICampaignSitesDataTable[] => {
  return campaignSites.map((campaignSite) => ({
    id: campaignSite.id,
    campaignScheduleIds: getCampaignSchedulesIds(
      campaignSite.campaignSchedules,
    ),
    siteId: campaignSite.siteId,
    centre: campaignSite.centre,
    state: campaignSite.state,
    dimensions: campaignSite.screenSize,
    duration: getCampaignDurations(
      campaignSite.campaignSchedules,
      campaignSite.siteDayParts,
    ),
    campaignDates: getCampaignDatesFromSchedules(
      campaignSite.campaignSchedules,
    ),
    times: getCampaignTimesFromSchedules(campaignSite.campaignSchedules),
    dayParting: getCampaignDayOfWeeksFromSchedules(
      campaignSite.campaignSchedules,
    ),
    formats: campaignSite.formats,
    name: campaignSite.siteName || 'Untitled Site',
    schedulesMedias: getSchedulesMedia(campaignSite.campaignSchedules),
    subRows: getSubRows(campaignSite),
  }));
};
