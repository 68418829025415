import { Box } from '@mui/material';
import { withStyles } from 'tss-react/mui';

const SBoxWrapper = withStyles(Box, {
  root: {
    padding: '0 0 16px 0',
  },
});

const SBoxContent = withStyles(Box, {
  root: {
    display: 'grid',
    gap: 20,
    paddingLeft: 40,
    gridTemplateColumns: '1fr 1fr 40px',
    alignItems: 'center',
  },
});

export { SBoxContent, SBoxWrapper };
