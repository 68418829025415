export enum CampaignTypesEnum {
  ADVERTISING = 'Advertising',
  MARKETING = 'Marketing',
  COMMUNITY = 'Community',
}

export enum ManageCampaignTabsEnum {
  DETAIL = 'detail',
  MANAGE = 'manage',
}

export enum ManageCampaignAddMediaTabsEnum {
  SELECTED = 'Selected',
  RECENTLY = 'Recently',
  ALL = 'All',
}

export enum ManageCampaignModeEnum {
  ADD = 'add',
  EDIT = 'edit',
  REVIEW = 'review',
}

export enum CampaignStatusEnum {
  DRAFT = 'Draft',
  COMPLETED = 'Completed',
  LIVE = 'Live',
  UPCOMING = 'Upcoming',
  PENDING_APPROVAL = 'PendingApproval',
  ARCHIVED = 'Archived',
  REJECTED = 'Rejected',
  STOPPED = 'Stopped',
}
