import React, { ReactNode, ReactPortal } from 'react';
import { createPortal } from 'react-dom';

interface IPortal {
  children: ReactNode;
}

const Portal = ({ children }: IPortal): ReactPortal => {
  const container = document.getElementById('portals');

  if (!container) {
    throw new Error('There is no container for portals');
  }

  return createPortal(children, container);
};

export default Portal;
