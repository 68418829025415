import moment from 'moment';

import {
  ICampaignFilter,
  ICampaignScheduleById,
  IMediaFilter,
  ISitesFilter,
  ISortingValue,
  TMediaFilterAllTabs,
} from '../types';
import { ScheduleModalText } from './text/text';

export const USER_GUIDE_PDF_HREF = '/Portal User Guide (Marketing).pdf';

export const redirectToKey = 'redirectTo';

export enum LocalStorageKeysEnum {
  jwtToken = 'jwtToken',
  userRole = 'userRole',
  isOrganisation = 'isOrganisation',
  currentOrganisation = 'currentOrganisation',
}

export enum SessionStorageKeysEnum {
  redirectTo = 'redirectTo',
}

export const FORMAT_FOR_DURATION = 'm[m]:ss[s]';

export const DEFAULT_SLASH_DATE_FORMAT = 'DD/MM/YYYY';
export const DEFAULT_SLASH_DATE_FORMAT_DATE_FNS = 'd/M/yyyy';
export const DEFAULT_DATE_FORMAT_REVERSE = 'D MMM YYYY';
export const DEFAULT_DATE_FORMAT_REVERSE_SHORT = 'D MMM';
export const DEFAULT_TIME_FORMAT = 'HH:mm';
export const DEFAULT_TIME_FORMAT_AM_PM = 'h:mm a';
export const DEFAULT_FULLTIME_FORMAT = 'HH:mm:ss';
export const DEFAULT_TIME_FORMAT_HH = 'HH';
export const DEFAULT_TIME_FORMAT_MM = 'mm';
export const TIME_FORMAT_LT = 'LT';
export const DATE_WITH_DAY_OF_WEEK_AND_TIME = 'ddd DD MMMM YYYY h:mma';
export const TIME_MILLISECOND = 'millisecond';

export const TITLE_FOR_RADIO_GROUP = 'Time for site';

export enum SortingTypeEnum {
  asc = 'asc',
  desc = 'desc',
}

export const CAMPAIGN_MARKETING_TYPE = ['Marketing', 'Community'];

export const DEFAULT_CATEGORY = {
  id: 24,
  name: 'Marketing/Community/Content',
};

export const DEFAULT_CATEGORY_ID = 42;

export const NEW_CAMPAIGN_INITIAL_VALUE = {
  advertiserId: null,
  productId: null,
  categoryId: null,
  name: '',
  typeId: null,
  startDate: moment().toISOString(),
  endDate: moment().toISOString(),
  isPreemptive: false,
  campaignSites: [],
};

export const CAMPAIGN_INITIAL_VALUE: ICampaignScheduleById = {
  advertiser: null,
  category: null,
  product: null,
  isPreemptive: false,
  name: '',
  type: null,
  campaignSites: [],
  status: null,
};

export const INITIAL_CAMPAIGN_FILTER_VALUE: ICampaignFilter = {
  Status: [],
  Site: [],
  Advertiser: [],
  Product: [],
  'Created By': [],
  'Campaign type': [],
  Category: [],
};

export const INITIAL_SITES_FILTER_VALUE: ISitesFilter = {
  Dimensions: [],
  State: [],
  Centre: [],
  Formats: [],
  'Site type': [],
  Network: [],
};

export const INITIAL_MEDIA_FILTER_VALUE: IMediaFilter = {
  Type: [],
  Advertiser: [],
  Product: [],
  'Uploaded by': [],
  Dimensions: [],
  Category: [],
};

export const INITIAL_MEDIA_FILTER_VALUE_ALL_TAB: TMediaFilterAllTabs = {
  Type: [],
  Advertiser: [],
  Product: [],
  'Uploaded by': [],
  Category: [],
};

export const STATUS_FILTER = {
  name: 'name',
  items: [{ id: 0, name: 'Live', count: 0 }],
};

export const INITIAL_CAMPAIGN_TYPE_DATA = {
  campType: null,
  advertiser: {
    id: 0,
    name: '',
    createdDate: '',
    updatedDate: '',
  },
  product: {
    id: 0,
    name: '',
    createdDate: '',
    updatedDate: '',
  },
  category: {
    id: 0,
    name: '',
  },
};

export const INITIAL_SORTING_VALUE_CAMPAIGN_PAGE: ISortingValue = {
  SortDirection: 'desc',
  SortColumn: 'ID',
};

export const INITIAL_SORTING_VALUE = {
  SortDirection: undefined,
  SortColumn: undefined,
};

export const INITIAL_CAMPAIGN_TABLE_COLUMNS_VALUE = [
  'ID',
  'Campaign name',
  'Created by',
  'Type',
  'Advertiser',
  'Product',
  'Category',
  'Status',
  'Starts',
  'Ends',
  '# of sites',
];

export const INITIAL_CAMPAIGN_TABLE_COLUMNS_EXCEPTIONS = ['Campaign name'];

export const INITIAL_SITES_TABLE_COLUMNS_VALUE = [
  'Site ID',
  'Site name',
  'Centre',
  'State',
  'Dimensions',
  'Type',
  'Formats',
  'Network',
  'Duration',
];

export const INITIAL_SITES_TABLE_COLUMNS_EXCEPTIONS = ['Site name'];

export const INITIAL_MEDIA_TABLE_COLUMNS_VALUE = [
  'ID',
  'Content',
  'Name',
  'Advertiser',
  'Product',
  'Type',
  'Category',
  'Dimensions',
  'Date added',
  'Uploaded by',
  'Number of sites',
];

export const INITIAL_MEDIA_TABLE_COLUMNS_EXCEPTIONS = ['Name'];

export const INITIAL_SELECTED_SITES_COLUMNS_VALUE = [
  'Site ID',
  'Site name',
  'Dimensions',
  'Centre',
  'State',
  'Duration',
  'Campaign dates',
  'Times',
  'Day parting',
  'Media',
  'Formats',
];

export const INITIAL_SELECTED_SITES_COLUMNS_EXCEPTIONS = ['Site name'];

export const DAYS_OF_WEEK = [
  { label: 'Mon', value: 'Monday' },
  { label: 'Tue', value: 'Tuesday' },
  { label: 'Wed', value: 'Wednesday' },
  { label: 'Thu', value: 'Thursday' },
  { label: 'Fri', value: 'Friday' },
  { label: 'Sat', value: 'Saturday' },
  { label: 'Sun', value: 'Sunday' },
];

export const INITIAL_SCHEDULE_VALUE = {
  startDate: undefined,
  endDate: undefined,
  startTime: new Date(moment().startOf('day').toISOString()),
  endTime: new Date(moment().endOf('day').toISOString()),
  dayOfWeek: DAYS_OF_WEEK.map((item) => item.value),
  duration: 0,
};

export const EVERY_DAY_VALUE = 'Every day';
export const ALL_DAY_VALUE = 'All';

export const REPEATING_VALUE = [
  { label: ScheduleModalText.allWeek, value: '1' },
];

export const VALID_FILE_TYPES = [
  'image/jpeg',
  'image/jpg',
  'image/png',
  'image/gif',
  'video/mp4',
  'video/m4v',
  'application/zip',
];

export const TABLE_MEDIA_VALUE = {
  label: 'Media',
  existMedia: 'View',
  nonExistMedia: 'Add media',
};

export const DEFAULT_SCHEDULE_VALUE = {
  duration: '',
  datesValue: 'Set dates',
  times: 'Set times',
  days: 'Set days',
  media: 'Add media',
};

export const TABLE_DATES_VALUE = {
  nonExistMedia: 'Set dates',
};

export const DEFAULT_GET_SITES_VALUE = {
  pageNumber: 0,
  pageSize: 25,
};

export const MEDIA_TYPES = {
  video: 'Video',
  image: 'Image',
};

export const VIDEO_PLAYER_STYLES = {
  width: '380px',
  height: 'fit-content',
};

export const TABS_VALUE = {
  FIRST_TAB: 0,
  SECOND_TAB: 1,
  THIRD_TAB: 2,
};

export enum TablePaginationEnum {
  FIVE = 5,
  TEN = 10,
  QUARTER = 25,
}

export enum PagesEnum {
  ZERO = 0,
  FIRST = 1,
  SECOND = 2,
  THIRD = 3,
  FOURTH = 4,
  FIFTH = 5,
  SIXTH = 6,
  SEVENTH = 7,
}

export enum AlertStatusesEnum {
  DEFAULT = 'default',
  ERROR = 'error',
  SUCCESS = 'success',
  WARNING = 'warning',
  INFO = 'info',
}
