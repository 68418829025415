import { MainLoading } from 'components/Loading';
import { LocalStorageKeysEnum, redirectToKey } from 'constants/constants';
import { urls } from 'constants/urls/url';
import { getValidRedirectTo } from 'helpers/urls/urls';
import React, { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

const Redirect: React.FC = () => {
  const [searchParams] = useSearchParams();

  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem(LocalStorageKeysEnum.jwtToken);
    const hasRedirect = searchParams.has(redirectToKey);
    const originalRedirectTo = searchParams.get(redirectToKey);
    const redirectTo = getValidRedirectTo(originalRedirectTo);

    if (!token) {
      if (hasRedirect) {
        sessionStorage.setItem(redirectToKey, redirectTo);
      }

      navigate(urls.login);

      return;
    }

    navigate(redirectTo || urls.campaign);
  }, []);

  return <MainLoading />;
};

export default Redirect;
