import './Header.scss';

import {
  AddCircleOutline as AddCircleOutlineIcon,
  ArrowBackIosNew as ArrowIcon,
  DoDisturbOn as DoDisturbOnIcon,
  ImageOutlined as ImageOutlinedIcon,
} from '@mui/icons-material';
import { ListItemIcon } from '@mui/material';
import { Button } from 'components/common';
import {
  CampaignStatusEnum,
  ManageCampaignModeEnum,
} from 'constants/enums/campaign';
import { getAllSchedulesMedia } from 'helpers/Campaign';
import { useActions, useTypedSelector } from 'hooks';
import React, { MouseEvent, useState } from 'react';

import useViewAllSelectedMedia from '../../../../../Modals/ViewAllSelectedMedia/ViewAllSelectedMedia.hook';
import useHeader from './Header.hook';
import {
  SBulkActionsButton,
  SMenu,
  SMenuItem,
  ViewAllMediaButton,
} from './Header.styled';

const ManageTabHeader: React.FC = () => {
  const { status, mode } = useTypedSelector(
    (state) => state && state.manageCampaign.main,
  );
  const { campaignSites } = useTypedSelector(
    (state) => state && state.manageCampaign.manage,
  );
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { setModalsManageTab } = useActions();
  const { handleOpenViewMediaModal } = useViewAllSelectedMedia();

  const handleStopCampaignModalOpen = () => {
    setModalsManageTab({ type: 'stopCampaign', value: true });
  };

  const handleOpenAddSiteModal = () => {
    setModalsManageTab({ type: 'addSites', value: true });
  };

  const handleBulkOpen = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleBulkClose = () => {
    setAnchorEl(null);
  };

  const isOpen = !!anchorEl;

  const { menuItems } = useHeader();

  const isReview = mode === ManageCampaignModeEnum.REVIEW;

  const isStopButtonView =
    status === CampaignStatusEnum.LIVE ||
    status === CampaignStatusEnum.UPCOMING;

  const isAllMediaDisabled = !getAllSchedulesMedia(campaignSites, true).length;

  return (
    <div className="manage-tab-header-wrapper">
      <div className="manage-tab-header-left header-manage-tab-block">
        <SBulkActionsButton
          endIcon={<ArrowIcon sx={{ transform: 'rotate(-90deg)' }} />}
          onClick={handleBulkOpen}
        >
          Bulk actions
        </SBulkActionsButton>
        <SMenu anchorEl={anchorEl} open={isOpen} onClose={handleBulkClose}>
          {menuItems.map(({ label, icon, disabled, onClick, classNames }) => (
            <SMenuItem
              key={label}
              onClick={onClick}
              className={classNames}
              disabled={disabled}
            >
              {icon && <ListItemIcon>{icon}</ListItemIcon>}
              {label}
            </SMenuItem>
          ))}
        </SMenu>
      </div>
      <div className="manage-tab-header-right header-manage-tab-block">
        <ViewAllMediaButton
          label="View all media for campaign"
          variant="text"
          icon={<ImageOutlinedIcon />}
          onClick={handleOpenViewMediaModal}
          disabled={isAllMediaDisabled}
        />
        {isStopButtonView && (
          <Button
            label="Stop"
            className="dangerous-button"
            icon={<DoDisturbOnIcon />}
            onClick={handleStopCampaignModalOpen}
          />
        )}
        {!isReview && (
          <Button
            label="Add sites"
            icon={<AddCircleOutlineIcon />}
            onClick={handleOpenAddSiteModal}
          />
        )}
      </div>
    </div>
  );
};

export default ManageTabHeader;
