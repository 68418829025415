import { RefObject } from 'react';
import { useEventListener } from 'usehooks-ts';

type THandler = (event: MouseEvent) => void;

function useOnClickOutsideExpand<T extends HTMLElement = HTMLElement>(
  ref: RefObject<T> | RefObject<T>[],
  handler: THandler,
  mouseEvent: 'mousedown' | 'mouseup' = 'mousedown',
): void {
  useEventListener(mouseEvent, (event) => {
    if (Array.isArray(ref)) {
      if (!ref.length) {
        handler(event);

        return;
      }

      const isContains = Boolean(
        ref.find((item) => {
          const el = item?.current;

          if (!el || el.contains(event.target as Node)) {
            return true;
          }

          return false;
        }),
      );

      if (isContains) {
        return;
      }

      handler(event);

      return;
    }

    const el = ref?.current;

    // Do nothing if clicking ref's element or descendent elements
    if (!el || el.contains(event.target as Node)) {
      return;
    }

    handler(event);
  });
}

export default useOnClickOutsideExpand;
