export enum NothingEnum {
  NO_CENTRE = 'No centre',
  NO_STATE = 'No state',
  NO_SCREEN_SIZE = 'No screen size',
  NO_SITE_NAME = 'No site name',
  NO_FORMATS = 'No formats',
}

export enum NothingSelectedEnum {
  ADVERTISER = 'No advertiser selected',
  CAMPAIGN_TYPE = 'No campaign type selected',
  PRODUCT = 'No product selected',
  CATEGORY = 'No category selected',
}
