import { useActions } from 'hooks';
import { TSizesPage } from 'types';

interface IUsersContentTablePaginationHookReturn {
  readonly handleChangePageSize: (value: TSizesPage) => void;
  readonly handleChangePageNumber: (value: number) => void;
}

const useUsersContentTablePagination =
  (): IUsersContentTablePaginationHookReturn => {
    const {
      allUserTablePaginationPageSize,
      allUserTablePaginationCurrentPage,
    } = useActions();

    const handleChangePageSize = (value: TSizesPage): void => {
      allUserTablePaginationPageSize(value);
      allUserTablePaginationCurrentPage(0);
    };

    const handleChangePageNumber = (value: number): void => {
      allUserTablePaginationCurrentPage(value);
    };

    return { handleChangePageNumber, handleChangePageSize };
  };

export default useUsersContentTablePagination;
