import { flexRender, Table } from '@tanstack/react-table';
import React, { ReactElement } from 'react';

interface ITableFooter<TData> {
  readonly table: Table<TData>;
}

const TableFooter = <TData,>({ table }: ITableFooter<TData>): ReactElement => {
  return (
    <tfoot className="__table-footer">
      {table.getFooterGroups().map((footerGroup) => (
        <tr
          key={footerGroup.id}
          className={`__table_row __table_row-footer __row-${footerGroup.id} __row-footer-${footerGroup.id}`}
        >
          {footerGroup.headers.map((header) => (
            <th
              key={header.id}
              className={`__table_cell __table_cell-footer __cell-${footerGroup.id}-${header.id} __cell-footer-${footerGroup.id}-${header.id}`}
            >
              {header.isPlaceholder
                ? null
                : flexRender(
                    header.column.columnDef.footer,
                    header.getContext(),
                  )}
            </th>
          ))}
        </tr>
      ))}
    </tfoot>
  );
};

export default TableFooter;
