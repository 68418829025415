import { Add as AddIcon } from '@mui/icons-material';
import {
  AppBarTypeMap,
  ExtendButtonBase,
  Fab,
  FabTypeMap,
  ModalTypeMap,
} from '@mui/material';
import { DefaultComponentProps } from '@mui/material/OverridableComponent';
import classnames from 'classnames';
import React, { ReactNode } from 'react';

import { colors } from '../../../../../constants/colors/colors';
import { Modal } from './components';
import {
  useFabConfiguration,
  useModalConfiguration,
  useTabsConfiguration,
} from './hooks';
import { ITabsSwitcherState, THorizontal, TVertical } from './type';

export interface IAdditionallyStaff {
  isOpen?: boolean;
  handleClose?: () => void;
  handleOpen?: () => void;
  fabClassName?: classnames.ArgumentArray;
  verticalPosition?: TVertical;
  horizontalPosition?: THorizontal;
  customFabPadding?: number;
  fabDisabled?: boolean;
  fabIcon?: ReactNode;
  isCentre?: ITabsSwitcherState['isCentre'];
  isNetwork?: ITabsSwitcherState['isNetwork'];
  isResolution?: ITabsSwitcherState['isResolution'];
  isSuborganisation?: ITabsSwitcherState['isSuborganisation'];
  FabProps?: Omit<ExtendButtonBase<FabTypeMap>, 'onClick'>;
  ModalProps?: DefaultComponentProps<ModalTypeMap>;
  AppBarProps?: DefaultComponentProps<AppBarTypeMap>;
}

const AdditionallyStaff: React.FC<IAdditionallyStaff> = ({
  isOpen,
  handleClose,
  handleOpen,
  fabClassName,
  verticalPosition,
  horizontalPosition,
  customFabPadding,
  fabDisabled,
  fabIcon,
  isCentre = true,
  isNetwork = true,
  isResolution = true,
  isSuborganisation = true,
  FabProps,
  ModalProps,
  AppBarProps,
}) => {
  const {
    tabsSwitcherState,
    verticalSx,
    horizontalSx,
    isAllTurnOff,
    initialedCurrentTab,
  } = useFabConfiguration({
    isCentre,
    isNetwork,
    isResolution,
    isSuborganisation,
    verticalPosition,
    horizontalPosition,
    customFabPadding,
  });

  const { isOpenLocal, handleModalClose, handleModalOpen } =
    useModalConfiguration({ isOpen, handleClose, handleOpen });

  const { currentTab, handleTabChange } = useTabsConfiguration({
    initialedCurrentTab,
  });

  return (
    <>
      <Fab
        color="primary"
        aria-label="add"
        className={classnames('additionally-staff-fab', fabClassName)}
        onClick={handleModalOpen}
        sx={{ position: 'fixed', ...verticalSx, ...horizontalSx }}
        disabled={fabDisabled || isAllTurnOff}
        {...FabProps}
      >
        {fabIcon ? <>{fabIcon}</> : <AddIcon sx={{ color: colors.White }} />}
      </Fab>
      {isOpenLocal && !isAllTurnOff && (
        <Modal
          isOpen={isOpenLocal}
          currentTab={currentTab}
          tabsSwitcherState={tabsSwitcherState}
          handleClose={handleModalClose}
          handleTabChange={handleTabChange}
          ModalProps={ModalProps}
          AppBarProps={AppBarProps}
        />
      )}
    </>
  );
};

export default AdditionallyStaff;
