import './TableFilter.scss';

import {
  List,
  ListItemButton,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
} from '@mui/material';
import React, { ReactElement } from 'react';
import { makeStyles } from 'tss-react/mui';

import { convertStatusName } from '../../../../helpers/common';
import {
  IFiltersList,
  IFiltersListItem,
  TFilterRecord,
} from '../../../../types';
import Checkbox from '../../../common/Checkbox';

interface ICampaignsFilterListProps<FiltersValue> {
  filterList: IFiltersList;
  filtersValue: FiltersValue;
  setFiltersValue: React.Dispatch<React.SetStateAction<FiltersValue>>;
  value: string;
  resetPagination: () => void;
}

const useStyles = makeStyles()(() => ({
  listItemIcon: {
    minWidth: 'unset',
  },
  listItemSecondaryAction: {
    padding: 0,
  },
}));

const TableFilterList = <FiltersValue extends TFilterRecord>(
  props: ICampaignsFilterListProps<FiltersValue>,
): ReactElement => {
  const { filterList, value, filtersValue, setFiltersValue, resetPagination } =
    props;

  const { classes, cx } = useStyles();

  const handleToggle = (id: number | null, name: string) => () => {
    if (!value || !filtersValue) {
      return null;
    }
    resetPagination();
    if (filtersValue?.[value]) {
      if (id) {
        if (filtersValue?.[value]?.includes(id)) {
          const newChecked = filtersValue[value].filter((i) => i !== id);

          return setFiltersValue({ ...filtersValue, [value]: newChecked });
        }
        const newValue = [...filtersValue[value], id];

        return setFiltersValue({ ...filtersValue, [value]: newValue });
      }
      if (!id) {
        if (filtersValue?.[value]?.includes(name)) {
          const newChecked = filtersValue?.[value].filter((i) => i !== name);

          return setFiltersValue({ ...filtersValue, [value]: newChecked });
        }
        const newValue = [...filtersValue[value], name];

        return setFiltersValue({ ...filtersValue, [value]: newValue });
      }
    }

    return null;
  };

  return (
    <List className="filter-items-container">
      {filterList.items.map(({ id, name, count }: IFiltersListItem) => {
        const filterName = convertStatusName(name);
        const labelId = `checkbox-list-label-${id}`;
        const isCheckedFilter: boolean =
          Boolean(value && id && filtersValue?.[value]?.includes(id)) ||
          Boolean(value && name && filtersValue?.[value]?.includes(name));

        return (
          <ListItemButton
            className="filter-items-container__list-item"
            key={`${id}-${name}-${count}`}
            dense
            onClick={handleToggle(id, name)}
          >
            <ListItemIcon
              className={cx(
                'accordion-item__filter-item-checkbox',
                classes.listItemIcon,
              )}
            >
              <Checkbox
                isChecked={isCheckedFilter}
                disableRipple
                withoutPaddings
              />
            </ListItemIcon>
            <ListItemText
              className="filter-items-container__list-item-text"
              id={labelId}
              primary={filterName}
            />
            <ListItemSecondaryAction
              className={cx(
                'filter-items-container__secondary-item-text',
                classes.listItemSecondaryAction,
              )}
            >
              <div className="accordion-item__number-container">{count}</div>
            </ListItemSecondaryAction>
          </ListItemButton>
        );
      })}
    </List>
  );
};

export default TableFilterList;
