import { ICentreNetworkFull } from '../../../../../../../../redux/Reducers/users/types';
import { ICentreNetworksDto, TNullable } from '../../../../../../../../types';

export interface ICentreNetworks<TCentre = number, TNetworks = number> {
  centreId: TCentre;
  networkIds: TNetworks[];
  id: number;
}

export const createCentreNetworksScheme = (
  centreNetworks: ICentreNetworkFull[],
): ICentreNetworks[] => {
  if (!centreNetworks.length) {
    return [];
  }

  /** Declaring a repository for the IDs of all centers */
  const idsList: number[] = [];

  /** Looking for ids of all centers (unique) */
  centreNetworks.forEach((centreNetwork) => {
    if (!idsList.includes(centreNetwork.centreId)) {
      idsList.push(centreNetwork.centreId);
    }
  });

  /** Creating objects that the form can read */
  const centreNetworksScheme = idsList.map((id) => {
    const centreNetworkScheme: ICentreNetworks = {
      centreId: id,
      networkIds: [],
      id: 0,
    };

    centreNetworks.forEach((centreNetwork) => {
      if (centreNetwork.centreId === id) {
        centreNetworkScheme.networkIds.push(centreNetwork.networkId);
        centreNetworkScheme.id = centreNetwork.id;
      }
    });

    return centreNetworkScheme;
  });

  return centreNetworksScheme;
};

export const getNetworkScheme = (
  centreNetworks: ICentreNetworksDto[],
): TNullable<number[]> => {
  if (!centreNetworks.length) {
    return null;
  }

  return centreNetworks.map((item) => item.networkId ?? 0);
};
