import { IconButton as MuiIconButton, IconButtonProps } from '@mui/material';
import React, { ReactElement } from 'react';

import { SizesEnum } from '../../SvgIcon/sizes';
import SvgIcon from '../../SvgIcon/SvgIcon';

export interface IIconButtonProps extends IconButtonProps {
  icon: ReactElement;
  wrapperSize?: keyof typeof SizesEnum;
}

export default function IconButton({
  icon,
  wrapperSize = 'm',
  ...props
}: IIconButtonProps): ReactElement {
  return (
    <MuiIconButton {...props}>
      <SvgIcon className={`icon-${wrapperSize}`}>{icon}</SvgIcon>
    </MuiIconButton>
  );
}
