import { Button, DialogActions } from '@mui/material';
import { useFormikContext } from 'formik';
import React, { memo } from 'react';

import { isRole } from '../../../../../../../../../helpers/role';
import { UserRolesEnum } from '../../../../../../../../../types';
import useManageUserModal from '../../ManageUser.hook';
import { TInitialValues } from '../../utils';

const ManageUserActions: React.FC = () => {
  const { values, isSubmitting } = useFormikContext<TInitialValues>();
  const { handleModalClose } = useManageUserModal();

  const formValuesInspector = () => {
    const isNeededCentreNetworks = isRole(
      [
        UserRolesEnum.Advertising,
        UserRolesEnum.Marketing,
        UserRolesEnum.Editor,
      ],
      values.role,
    );
    const isNeededSubOrganisation = isRole(
      UserRolesEnum.Advertising,
      values.role,
    );

    if (isRole(UserRolesEnum.Admin, values.role)) {
      if (!values.adminNetworks?.length) {
        return true;
      }
    }

    if (isNeededCentreNetworks && values.centreNetworks) {
      const one = values.centreNetworks.find(
        (centreNetwork) =>
          Boolean(centreNetwork.centreId) &&
          Boolean(centreNetwork.networkIds.length),
      );

      if (!one) {
        return true;
      }

      if (isNeededSubOrganisation) {
        const hasSubOrganisation = values.subOrganisation === -1;

        if (hasSubOrganisation) {
          return true;
        }
      }
    }

    return false;
  };

  const isDoneButtonDisabled = isSubmitting || formValuesInspector();

  return (
    <DialogActions>
      <Button
        onClick={handleModalClose}
        disabled={isSubmitting}
        variant="contained"
        color="error"
      >
        Cancel
      </Button>
      <Button type="submit" disabled={isDoneButtonDisabled} variant="contained">
        Done
      </Button>
    </DialogActions>
  );
};

export default memo(ManageUserActions);
