/* eslint-disable @typescript-eslint/naming-convention */
export const addCampaignDetailsText = {
  advertiser: 'Advertiser',
  product: 'product',
  category: 'Category',
  separator: 'OR',
  name: 'Campaign name',
  type: 'Campaign type',
  infoBox:
    'Give your new campaign a name or search to start from a previous campaign',
  campaignNameError: 'Incorrect campaign name',
};

export const mediaModalText = {
  selectedTabLabel: 'Selected media',
  recentlyTabLabel: 'Recently added',
  allTabLabel: 'All media',
  addMediaTabLabel: 'Add media',
  viewAllMediaTab: 'View all media',
};

export enum AxiosMethodEnum {
  get = 'get',
  post = 'post',
  delete = 'delete',
  put = 'put',
}

export const errorsLabel = {
  passwordReq: 'Password is required',
  invalidePassword: 'Invalid password',
  loginReq: 'Login is required',
  passwordMatch: 'Passwords must match',
  emailValid: 'Please enter a valid email address',
  reasonReq: 'Reason is required',
};

export const CampaignListMenuText = {
  view: 'View details',
  edit: 'Edit campaign',
  review: 'Review campaign',
  clone: 'Clone campaign',
  report: 'Generate report',
  archive: 'Archive',
  delete: 'Delete',
};

export const SitesListMenuText = {
  view: 'View details',
  viewOnMap: 'View on map',
};

export const CampaignsFilterText = {
  title: 'Filter campaigns',
  clear: 'Clear all',
  search: 'Search campaigns',
};

export const SitesFilterText = {
  title: 'Filter sites',
  clear: 'Clear all',
  search: 'Search sites',
};

export const MediaFilterText = {
  title: 'Filter media',
  clear: 'Clear all',
  search: 'Search media',
};

export const campaignArchiveDialog = {
  header: 'Do you really want to archive this campaign?',
  cancelButton: 'cancel',
  confirmButton: 'archive',
};

export const campaignDeleteDialog = {
  header: 'Do you really want to delete this campaign?',
  cancelButton: 'cancel',
  confirmButton: 'delete',
};

export const mediaArchiveDialog = {
  header: 'Do you really want to archive this media?',
  cancelButton: 'cancel',
  confirmButton: 'archive',
};

export const SiteFilterText = {
  title: 'Filter sites',
  clear: 'Clear all',
  centre: 'Centre',
  state: 'State',
  dimensions: 'Dimensions',
  search: 'Search sites',
};

export const SideNavBarText = {
  campaign: 'campaigns',
  sites: 'sites',
  mediaLibrary: 'media library',
  users: 'users',
  userGuide: 'user guide',
  changePortalButton: 'Change portal',
  signOutButton: 'Sign out',
};

export const CampaignType = {
  advertising: 'Advertising',
  marketing: 'Marketing',
  community: 'Community',
};

export const AddNewCampaignText = {
  header: 'New campaign',
  draft: 'Save as draft',
  publish: 'Publish',
  submit: 'Submit for approval',
  next: 'Next: manage campaign',
  preemptive: 'Set campaign as preemptive',
};

export const EditCampaignText = {
  header: 'Edit campaign',
  draft: 'Save as draft',
  publish: 'Publish',
  submit: 'Submit for approval',
  update: 'Update',
  next: 'Next: manage campaign',
  preemptive: 'Set campaign as preemptive',
};

export const ReviewCampaignText = {
  header: 'Review campaign',
  decline: 'Decline',
  approve: 'Approve',
  next: 'Next: manage campaign',
  preemptive: 'Set campaign as preemptive',
};

export const CampaignPageText = {
  header: 'Campaigns',
  newCampaign: 'New campaign',
};

export const campaignTabs = {
  details: 'Campaign details',
  manage: 'Manage campaign',
};

export const MediaPageText = {
  header: 'Media library',
  archive: 'Archive',
};

export const ForgotPasswordPageText = {
  header: 'Reset password',
  emailLabel: 'username or email address',
  recovert: 'email me a recovery link',
  sentLabel: 'An email has been sent. Please click the link when you get it.',
  back: 'Get back',
};

export const MagicLinkPageText = {
  header: 'LogIn with Magic link',
  emailLabel: 'email address',
  recovert: 'email me a recovery link',
  sentLabel: 'An email has been sent. Please click the link when you get it.',
  back: 'Get back',
};

export const ResetPasswordPageText = {
  newPassword: 'Enter a new password',
  repeat: 'repeat password',
  infoPasswordBox: 'Password should contain:',
  passwordLength: 'at least 8 characters',
  passwordType: ' number',
  passwordCapital: 'a capital letter',
  passwordSpecial: 'a special character',
  succsedResetPassword: 'Password has been successfully changed',
};

export const LoginPageText = {
  header: 'Login to the portal',
  emailLabel: 'username or email address',
  passwordLabel: 'password',
  loginButton: 'Login',
  magicLink: 'Send a magic link',
  forgot: 'Reset password',
  remember: 'Remember me',
};

export const ManageCampaignText = {
  button: 'Add sites',
  title: 'Add sites and media to your campaign',
  removeSite: 'Remove site',
  schedule: 'Schedule',
  addMedia: 'Add media',
  viewAllMedia: 'View all media for campaign',
};

export const SearchText = {
  placeholder: 'Search to clone previous campaign',
};

export const autocompletePlaceholder = {
  typingPlaceholder: 'Start typing',
  selectPlaceholder: 'Please select',
  adverticer: 'Add Col as an advertiser',
};

export const AddSitesModalText = {
  header: 'Select sites for your campaign',
  addSitesButton: 'Add selected',
};

export const ScheduleModalText = {
  header: 'Set campaign duration',
  sites: 'Sites',
  completeAddSchedule: 'Done',
  AddAnotherSchedule: 'Add another day part',
  fromTime: 'from',
  toTime: 'to',
  allWeek: 'Repeating days of the week',
  specificDays: 'Specific dates',
  everyDay: 'Every day',
  startsTime: 'Starts',
  endsTime: 'Ends',
  infoBoxDates: 'Enter the start and end date of the campaign',
  allSitesLabel: 'Use these details for all sites in campaign',
};

export const CampaignTableText = {
  noResults: 'No campaigns found',
};

export const SitesTableText = {
  noResults: 'No sites found',
};

export const MediaTableText = {
  noResults: 'No media found',
};

export const DatePickerText = {
  label: 'Date range',
  startDate: 'From',
  endDate: 'To',
};

export const StartDatePickerText = {
  label: 'Date added',
};

export const AutoCompleteDefaultText = {
  noOptions: 'No options',
};

export const SelectedMediaTabText = {
  uploadingFileHeader: 'Uploading media...',
  selectedMediaHeader: 'Selected Media',
  updateButtonText: 'Update',
};

export const RecentlyAddedMediaTabText = {
  uploadingFileHeader: 'Uploading media...',
  recentlyAddedHeader: 'Recently added media',
  updateButtonText: 'Update',
};

export const AllMediaTabText = {
  updateButtonText: 'Add selected',
};

export const DropZoneText = {
  invalidType: 'File type not supported',
  dropZoneMainText: 'Drop to add media or',
  uploadButtonLabel: 'Browse',
  acceptedTypes: 'Accepted file types:',
};

export const SitesMediaErrors = {
  sites: 'Please, select sites',
  schedule: 'Please, select sites with an existing schedule',
  format: 'Please, select sites with the same accepted file formats',
  resolution: 'Please, select sites with the same screen resolution settings',
};

export const TimeValueErrors = {
  startTime:
    'the end time should be longer than the start time. Please, enter the correct time',
};

export const BulkActionsMassages = {
  mediaLibraryError: 'Please select media',
  campaignsError: 'Please select campaigns',
  scheduleError: 'Please select sites',
  remiveSitesError: 'Please select sites',
};

export const DeclineDialogText = {
  header: 'Campaign Declined',
  inputLabel: 'Please provide a reason for declining the campaign',
  placeholder: 'Please provide a reason',
  description:
    'The campaign editor will receive an email detailing your reasons for declining the campaign',
  dismiss: 'Dismiss',
  submit: 'Send and exit',
};

export enum ActionDescriptionTextEnum {
  approved = 'Approved',
  declined = 'Declined',
  created = 'Created',
  added = 'Added',
}

export const StatusBoxText = {
  declined: ActionDescriptionTextEnum.declined,
  created: ActionDescriptionTextEnum.created,
  viewReason: 'View reason',
  by: 'by',
  verticalBar: '|',
};

export const CarouselItemText = {
  added: ActionDescriptionTextEnum.added,
  sitesUsing: 'Sites using',
  bullet: '•',
};
