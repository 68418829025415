import { Dispatch } from 'redux';
import {
  ISetMainModalsPayload,
  ManageCampaignDetailActionEnum,
  ManageMainActionEnum,
  ManageTabCampaignActionsEnum,
  TManageCampaignAction,
  TManageCampaignRootAction,
} from 'redux/ActionTypes';
import { IMainManageCampaignReducer } from 'redux/Reducers/campaignsReducer/manage/type';
import { ICampaignByIdNew, ICampaignQueryDto, IDefaultDto } from 'types';

import {
  approveCampaignApi,
  declineCampaignApi,
  editDraftCampaignAsDraftApi,
  oneCampaign,
  publishCampaignApi,
  saveCampaignAsDraftApi,
  stopCampaignApi,
  updateCampaignApi,
} from '../../campaignsApi';
import { IDeclineCampaignPayload } from '../type';

export const getCurrentCampaign =
  (campaignId: number, isCopy?: boolean) =>
  async (dispatch: Dispatch<TManageCampaignAction>): Promise<void> => {
    try {
      dispatch({
        type: ManageMainActionEnum.SET_LOADINGS,
        payload: { type: 'getOne', value: true },
      });

      const {
        data: { data },
      } = await oneCampaign<IDefaultDto<ICampaignByIdNew>>(campaignId);

      if (!isCopy) {
        dispatch({
          type: ManageMainActionEnum.CONTAIN_MANAGE_CAMPAIGN_ROOT_DATA,
          payload: {
            status: data.status,
            campaignId: data.id,
          },
        });
      }
      dispatch({
        type: ManageCampaignDetailActionEnum.CONTAIN_DETAIL_MANAGE_CAMPAIGN_DATA,
        payload: {
          campaignName: data.name,
          campaignType: data.type,
          advertiser: {
            value: data.advertiser.id,
            label: data.advertiser.name,
          },
          product: data.product && {
            value: data.product.id,
            label: data.product.name,
          },
          category: data.category && {
            value: data.category.id,
            label: data.category.name,
          },
        },
      });

      dispatch({
        type: ManageTabCampaignActionsEnum.CONTAIN_MANAGE_TAB,
        payload: data,
      });
    } catch (error: any) {
      const message = error?.response?.data?.UserMessage || error?.message;

      throw new Error(message);
    } finally {
      dispatch({
        type: ManageMainActionEnum.SET_LOADINGS,
        payload: { type: 'getOne', value: false },
      });
      dispatch({
        type: ManageMainActionEnum.SET_COPY_CAMPAIGN_ID,
        payload: null,
      });
    }
  };

export const changeCampaignTab =
  (payload: IMainManageCampaignReducer['activeTab']) =>
  (dispatch: Dispatch<TManageCampaignRootAction>): void => {
    dispatch({ type: ManageMainActionEnum.CHANGE_CAMPAIGN_TAB, payload });
  };

export const setCampaignMode =
  (payload: IMainManageCampaignReducer['mode']) =>
  (dispatch: Dispatch<TManageCampaignRootAction>): void => {
    dispatch({ type: ManageMainActionEnum.SET_CAMPAIGN_MODE, payload });
  };

export const setCopyCampaignId =
  (payload: IMainManageCampaignReducer['copyCampaignId']) =>
  (dispatch: Dispatch<TManageCampaignRootAction>): void => {
    dispatch({ type: ManageMainActionEnum.SET_COPY_CAMPAIGN_ID, payload });
  };

export const setModalManageCampaignRoot =
  (payload: ISetMainModalsPayload) =>
  (dispatch: Dispatch<TManageCampaignRootAction>): void => {
    dispatch({ type: ManageMainActionEnum.SET_MODALS, payload });
  };
export const cleanupManageCampaignRoot =
  () =>
  (dispatch: Dispatch<TManageCampaignRootAction>): void => {
    dispatch({ type: ManageMainActionEnum.CLEANUP_ROOT_MANAGE_STATE });
  };

export const cleanupAllManageCampaignData =
  () =>
  (dispatch: Dispatch<TManageCampaignAction>): void => {
    dispatch({ type: ManageMainActionEnum.CLEANUP_ROOT_MANAGE_STATE });
    dispatch({
      type: ManageCampaignDetailActionEnum.CLEANUP_MANAGE_CAMPAIGN_DETAIL_DATA,
    });
    dispatch({
      type: ManageTabCampaignActionsEnum.CLEANUP_MANAGE_TAB,
    });
  };

export const setDraftManageCampaign =
  () =>
  (dispatch: Dispatch<TManageCampaignAction>): void => {
    dispatch({ type: ManageMainActionEnum.SET_DRAFT_MANAGE_CAMPAIGN });
    dispatch({
      type: ManageCampaignDetailActionEnum.SET_DRAFT_MANAGE_CAMPAIGN_DETAIL,
    });
    dispatch({
      type: ManageTabCampaignActionsEnum.SET_DRAFT_MANAGE_TAB,
    });
  };

export const applyDraftManageCampaign =
  () =>
  (dispatch: Dispatch<TManageCampaignAction>): void => {
    dispatch({ type: ManageMainActionEnum.APPLY_DRAFT_MANAGE_CAMPAIGN });
    dispatch({
      type: ManageCampaignDetailActionEnum.APPLY_DRAFT_MANAGE_CAMPAIGN_DETAIL,
    });
    dispatch({
      type: ManageTabCampaignActionsEnum.APPLY_DRAFT_MANAGE_TAB,
    });
  };

export const saveCampaignAsDraft =
  (payload: ICampaignQueryDto) =>
  async (dispatch: Dispatch<TManageCampaignRootAction>): Promise<void> => {
    try {
      dispatch({
        type: ManageMainActionEnum.SET_LOADINGS,
        payload: { type: 'saveAsDraft', value: true },
      });
      await saveCampaignAsDraftApi(payload);
    } catch (error: any) {
      const message = error?.response?.data?.UserMessage;
      throw new Error(message);
    } finally {
      dispatch({
        type: ManageMainActionEnum.SET_LOADINGS,
        payload: { type: 'saveAsDraft', value: false },
      });
    }
  };

export const editDraftCampaignAsDraft =
  (payload: ICampaignQueryDto, id: number) =>
  async (dispatch: Dispatch<TManageCampaignRootAction>): Promise<void> => {
    try {
      dispatch({
        type: ManageMainActionEnum.SET_LOADINGS,
        payload: { type: 'saveAsDraft', value: true },
      });
      await editDraftCampaignAsDraftApi(payload, id);
    } catch (error: any) {
      const message = error?.response?.data?.UserMessage;
      throw new Error(message);
    } finally {
      dispatch({
        type: ManageMainActionEnum.SET_LOADINGS,
        payload: { type: 'saveAsDraft', value: false },
      });
    }
  };

export const publishCampaign =
  (payload: ICampaignQueryDto) =>
  async (dispatch: Dispatch<TManageCampaignRootAction>): Promise<void> => {
    try {
      dispatch({
        type: ManageMainActionEnum.SET_LOADINGS,
        payload: { type: 'publish', value: true },
      });
      await publishCampaignApi(payload);
    } catch (error: any) {
      const message = error?.response?.date?.UserMessage;

      throw new Error(message);
    } finally {
      dispatch({
        type: ManageMainActionEnum.SET_LOADINGS,
        payload: { type: 'publish', value: false },
      });
    }
  };

export const updateCampaign =
  (payload: ICampaignQueryDto, id: number) =>
  async (dispatch: Dispatch<TManageCampaignRootAction>): Promise<void> => {
    try {
      dispatch({
        type: ManageMainActionEnum.SET_LOADINGS,
        payload: { type: 'update', value: true },
      });

      await updateCampaignApi(payload, id);
    } catch (error: any) {
      const message = error?.response?.date?.UserMessage;

      throw new Error(message);
    } finally {
      dispatch({
        type: ManageMainActionEnum.SET_LOADINGS,
        payload: { type: 'update', value: false },
      });
    }
  };

export const declineCampaign =
  (payload: IDeclineCampaignPayload) =>
  async (dispatch: Dispatch<TManageCampaignRootAction>): Promise<void> => {
    try {
      dispatch({
        type: ManageMainActionEnum.SET_LOADINGS,
        payload: { type: 'decline', value: true },
      });
      await declineCampaignApi(payload);
    } catch (error: any) {
      const message = error?.response?.date?.UserMessage;

      throw new Error(message);
    } finally {
      dispatch({
        type: ManageMainActionEnum.SET_LOADINGS,
        payload: { type: 'decline', value: false },
      });
    }
  };

export const approveCampaign =
  (payload: number) /** campaign Id */ =>
  async (dispatch: Dispatch<TManageCampaignRootAction>): Promise<void> => {
    try {
      dispatch({
        type: ManageMainActionEnum.SET_LOADINGS,
        payload: { type: 'approve', value: true },
      });

      await approveCampaignApi(payload);
    } catch (error: any) {
      const message = error?.response?.date?.UserMessage;

      throw new Error(message);
    } finally {
      dispatch({
        type: ManageMainActionEnum.SET_LOADINGS,
        payload: { type: 'approve', value: false },
      });
    }
  };

export const stopCampaignManageCampaign =
  (payload: number) /** campaign Id */ =>
  async (dispatch: Dispatch<TManageCampaignRootAction>): Promise<void> => {
    try {
      dispatch({
        type: ManageMainActionEnum.SET_LOADINGS,
        payload: { type: 'stopped', value: true },
      });
      await stopCampaignApi(payload);
    } catch (error: any) {
      const message = error?.response?.date?.UserMessage;

      throw new Error(message);
    } finally {
      dispatch({
        type: ManageMainActionEnum.SET_LOADINGS,
        payload: { type: 'stopped', value: false },
      });
    }
  };
