import { AdditionallyStaff } from 'components/common';
import { MainLoading } from 'components/Loading';
import { radix } from 'constants/variables';
import { useActions } from 'hooks';
import { useTypedSelector } from 'hooks/useTypedSelector';
import MainLayout from 'layouts/Main/index';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { texts } from '../../constants/texts';
import { createInitialValueFromSite } from '../../helpers/site';
import SiteForm from '../../shared/SiteForm/SiteForm';

const EditSite: React.FC = () => {
  const { currentSite, loadings } = useTypedSelector(
    (state) => state && state.sites,
  );
  const { id } = useParams<{ id: string }>();

  const { getSiteById, setCurrentSiteId } = useActions();

  useEffect(() => {
    if (id) {
      setCurrentSiteId(parseInt(id, radix[10]));
      getSiteById(parseInt(id, radix[10]));
    }
  }, [id]);

  const isLoading: boolean = loadings.getOne || !currentSite;
  const initialValues =
    currentSite?.site && createInitialValueFromSite(currentSite.site);

  if (!id && !initialValues) {
    return <div>Something went wrong</div>;
  }

  return (
    <MainLayout>
      {isLoading ? (
        <MainLoading />
      ) : (
        <>
          <SiteForm
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            initialValues={initialValues!}
            title={texts.pages.edit.title}
          />
          <AdditionallyStaff />
        </>
      )}
    </MainLayout>
  );
};

export default EditSite;
