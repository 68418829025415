import {
  CheckCircle as CheckCircleIcon,
  RadioButtonUnchecked as RadioButtonUncheckedIcon,
} from '@mui/icons-material';
import { IconButton, Tooltip } from '@mui/material';
import { colors } from 'constants/colors/colors';
import { getIsMediaAlreadyAdded, getIsMediaSelected } from 'helpers/Campaign';
import { useTypedSelector } from 'hooks';
import React from 'react';
import { IMedia } from 'types';

interface IActionIcon {
  currentMedia: IMedia;
}

const ActionIcon: React.FC<IActionIcon> = ({ currentMedia }) => {
  const { selectedMedias, campaignSchedule } = useTypedSelector(
    (state) => state && state.manageCampaign.manage.addMediaModal,
  );

  const isAlreadySelected = getIsMediaAlreadyAdded(
    campaignSchedule,
    currentMedia,
  );
  const isSelected = getIsMediaSelected(selectedMedias, currentMedia);

  if (isAlreadySelected && isSelected) {
    return (
      <Tooltip title="Already added">
        <IconButton>
          <CheckCircleIcon sx={{ color: colors.DarkEmerald }} />
        </IconButton>
      </Tooltip>
    );
  }

  if (isSelected) {
    return (
      <Tooltip title="Will be added" arrow>
        <IconButton>
          <CheckCircleIcon sx={{ color: colors.Cerulean }} />
        </IconButton>
      </Tooltip>
    );
  }

  return (
    <Tooltip title="Add this media" arrow>
      <IconButton>
        <RadioButtonUncheckedIcon sx={{ color: colors.White }} />
      </IconButton>
    </Tooltip>
  );
};

export default ActionIcon;
