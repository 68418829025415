import CampaignDeclineDialog from 'components/common/Modals/CampaignDeclineDialog/CampaignDeclineDialog';
import {
  CampaignStatusEnum,
  ManageCampaignModeEnum,
} from 'constants/enums/campaign';
import { urls } from 'constants/urls/url';
import { radix } from 'constants/variables';
import { useActions, useError, useTypedSelector } from 'hooks';
import MainLayout from 'layouts/Main';
import { useSnackbar } from 'notistack';
import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { AlertStatusesEnum } from '../../../../constants/constants';
import { texts } from '../../constants';
import { CampaignForm } from '../../shared';

const ReviewCampaign: React.FC = () => {
  const { status, modals } = useTypedSelector(
    (state) => state && state.manageCampaign.main,
  );
  const {
    setCampaignMode,
    getCurrentCampaign,
    declineCampaign,
    setModalManageCampaignRoot,
  } = useActions();

  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const { outputError } = useError();
  const { enqueueSnackbar } = useSnackbar();

  const campaignIdNumber = id ? parseInt(id, radix[10]) : null;

  const getCampaignData = async (campaignId: number) => {
    try {
      await getCurrentCampaign(campaignId);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      outputError(error?.message, { isSnackbar: true });
      navigate(urls.campaign);
    }
  };

  const handleCloseDeclineModal = () => {
    setModalManageCampaignRoot({ type: 'reject', value: false });
  };

  const handleDeclineCampaign = async ({ reason }: { reason: string }) => {
    try {
      if (campaignIdNumber) {
        handleCloseDeclineModal();
        await declineCampaign({ id: campaignIdNumber, reason });
        enqueueSnackbar('Campaign declined', {
          variant: AlertStatusesEnum.WARNING,
        });
        navigate(urls.campaign);
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      outputError(error?.message, { isSnackbar: true });
    }
  };

  useEffect(() => {
    setCampaignMode(ManageCampaignModeEnum.REVIEW);

    if (campaignIdNumber) {
      getCampaignData(campaignIdNumber);
    }
  }, [campaignIdNumber]);

  useEffect(() => {
    if (!status) {
      return;
    }
    if (status !== CampaignStatusEnum.PENDING_APPROVAL) {
      navigate(urls.campaign);
    }
  }, [status]);

  return (
    <MainLayout>
      <CampaignForm title={texts.review} />
      {modals.reject && (
        <CampaignDeclineDialog
          isDeclineDialogOpened={modals.reject}
          handleToggleDeclineDialog={handleCloseDeclineModal}
          handleDeclineCampaign={handleDeclineCampaign}
        />
      )}
    </MainLayout>
  );
};

export default ReviewCampaign;
