import { Close as CloseIcon } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { colors } from 'constants/colors/colors';
import { AlertStatusesEnum } from 'constants/constants';
import { useActions, useError, useTypedSelector } from 'hooks';
import { SnackbarKey, useSnackbar } from 'notistack';
import React, { useEffect } from 'react';
import { SingleValue } from 'react-select';
import { ISelectOption } from 'types';
import { useDebounce } from 'usehooks-ts';

import useInspector from '../../../../../../../../../hooks/useInspector';
import { SButton } from './SelectCampaign.styled';

const searchCampaignDelay = 500;

interface ISelectCampaignHookReturn {
  handleApplyDraft: (snackbarKey: SnackbarKey) => void;
  handleSearch: (value: string) => void;
  handleClose: () => void;
  handleOpen: () => void;
  handleAgree: () => Promise<void>;
  handleChange: (value: SingleValue<ISelectOption<number>>) => Promise<void>;
}

const useSelectCampaign = (): ISelectCampaignHookReturn => {
  const { copyCampaignId } = useTypedSelector(
    (state) => state && state.manageCampaign.main,
  );
  const { searchCampaign, loaders } = useTypedSelector(
    (state) => state && state.manageCampaign.detail,
  );

  const {
    setSearchCampaign,
    getCurrentCampaign,
    getCampaignsForManage,
    setManageCampaignDetailModals,
    setCopyCampaignId,
    setDraftManageCampaign,
    applyDraftManageCampaign,
  } = useActions();

  const delaySearchCampaign = useDebounce(searchCampaign, searchCampaignDelay);
  const { isContentExist } = useInspector();
  const { outputError } = useError();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  useEffect(() => {
    if (!loaders.getByName) {
      getCampaignsForManage(delaySearchCampaign);
    }
  }, [delaySearchCampaign]);

  const handleApplyDraft = (snackbarKey: SnackbarKey): void => {
    applyDraftManageCampaign();
    closeSnackbar(snackbarKey);
  };

  const handleSearch = (value: string): void => {
    setSearchCampaign(value);
  };

  const handleClose = (): void => {
    setManageCampaignDetailModals({ type: 'copyWarning', value: false });
  };

  const handleOpen = (): void => {
    setManageCampaignDetailModals({ type: 'copyWarning', value: true });
  };

  const handleAgree = async (): Promise<void> => {
    try {
      if (copyCampaignId) {
        setDraftManageCampaign();
        await getCurrentCampaign(copyCampaignId, true);
        enqueueSnackbar('Copied campaign success', {
          variant: AlertStatusesEnum.SUCCESS,
          anchorOrigin: { horizontal: 'center', vertical: 'bottom' },
          autoHideDuration: 10000,
          action: (snackbarKey) => (
            <>
              <SButton
                label="Undo"
                variant="outlined"
                onClick={() => handleApplyDraft(snackbarKey)}
              />
              <IconButton
                onClick={() => closeSnackbar(snackbarKey)}
                sx={{ color: colors.White }}
              >
                <CloseIcon />
              </IconButton>
            </>
          ),
        });
        handleClose();
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      outputError(error.message, { isSnackbar: true });
    }
  };

  const handleChange = async (
    value: SingleValue<ISelectOption<number>>,
  ): Promise<void> => {
    if (value) {
      setCopyCampaignId(value.value);

      if (isContentExist) {
        handleOpen();

        return;
      }

      try {
        await getCurrentCampaign(value.value, true);
        enqueueSnackbar('Copied campaign success', {
          variant: AlertStatusesEnum.SUCCESS,
          anchorOrigin: { horizontal: 'center', vertical: 'bottom' },
          autoHideDuration: 3000,
        });
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        outputError(error.message, { isSnackbar: true });
      }
    }
  };

  return {
    handleApplyDraft,
    handleSearch,
    handleClose,
    handleOpen,
    handleAgree,
    handleChange,
  };
};

export default useSelectCampaign;
