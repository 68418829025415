import './TablePagination.scss';

import {
  KeyboardArrowLeftSharp as KeyboardArrowLeftSharpIcon,
  KeyboardArrowRightSharp as KeyboardArrowRightSharpIcon,
  KeyboardDoubleArrowLeftSharp as KeyboardDoubleArrowLeftSharpIcon,
  KeyboardDoubleArrowRightSharp as KeyboardDoubleArrowRightSharpIcon,
} from '@mui/icons-material';
import {
  Box,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Tooltip,
} from '@mui/material';
import { Table } from '@tanstack/react-table';
import React, { ReactElement } from 'react';

import { TSizesPage } from '../../../../../../types';
import { TTable } from '../../types';
import {
  useTablePagination,
  useTablePaginationDisabled,
  useTablePaginationPages,
} from './hooks';

export interface ITablePagination<TData> {
  table: Table<TData>;
  data: TTable<TData>['data'];
  totalDataCount?: TTable<TData>['totalDataCount'];
  isSSR?: TTable<TData>['isSSR'];
  pagination?: TTable<TData>['pagination'];
  loading?: TTable<TData>['loading'];
  handleChangePageSize?: TTable<TData>['handleChangePageSize'];
  handleChangePageNumber?: TTable<TData>['handleChangePageNumber'];
}

const sizesPage: TSizesPage[] = [10, 20, 30, 40, 50];

const TablePagination = <TData,>({
  table,
  data,
  totalDataCount,
  isSSR,
  pagination,
  handleChangePageSize,
  handleChangePageNumber,
  loading,
}: ITablePagination<TData>): ReactElement => {
  const { pageSize, setPageSize, totalPages } = useTablePagination<TData>({
    data,
    isSSR,
    pagination,
    totalDataCount,
  });

  const {
    isPreviousPageButtonDisabled,
    isNextPageButtonDisabled,
    isMenuItemDisabled,
  } = useTablePaginationDisabled<TData>({
    table,
    data,
    isSSR,
    loading,
    pagination,
    totalPages,
    totalDataCount,
  });

  const {
    handlePageSize,
    handleClickFirstPage,
    handleClickPreviousPage,
    handleClickNextPage,
    handleClickLastPage,
    outputCurrentPage,
  } = useTablePaginationPages<TData>({
    table,
    totalPages,
    isSSR,
    pagination,
    handleChangePageSize,
    handleChangePageNumber,
    setPageSize,
  });

  return (
    <div className="__table_pagination">
      <div className="__table_navigation">
        <Tooltip title="First page" placement="top">
          <span>
            <IconButton
              onClick={handleClickFirstPage}
              disabled={isPreviousPageButtonDisabled()}
            >
              <KeyboardDoubleArrowLeftSharpIcon />
            </IconButton>
          </span>
        </Tooltip>

        <Tooltip title="Previous page" placement="top">
          <span>
            <IconButton
              onClick={handleClickPreviousPage}
              disabled={isPreviousPageButtonDisabled()}
            >
              <KeyboardArrowLeftSharpIcon />
            </IconButton>
          </span>
        </Tooltip>

        <Tooltip title="Next page" placement="top">
          <span>
            <IconButton
              onClick={handleClickNextPage}
              disabled={isNextPageButtonDisabled()}
            >
              <KeyboardArrowRightSharpIcon />
            </IconButton>
          </span>
        </Tooltip>

        <Tooltip title="Last Page" placement="top">
          <span>
            <IconButton
              onClick={handleClickLastPage}
              disabled={isNextPageButtonDisabled()}
            >
              <KeyboardDoubleArrowRightSharpIcon />
            </IconButton>
          </span>
        </Tooltip>
      </div>

      <div className="__table_pages">
        <div>Page</div>
        <div>{outputCurrentPage()}</div>
      </div>

      <Box sx={{ minWidth: 120 }}>
        <FormControl fullWidth>
          <InputLabel id="__table-pagination-page-input">Page size</InputLabel>
          <Select
            labelId="__table-pagination-page-input"
            id="__table-pagination-page-select"
            value={pageSize}
            sx={{ height: 50 }}
            label="Page size"
            onChange={handlePageSize}
            disabled={loading}
          >
            {sizesPage.map((item) => (
              <MenuItem
                value={item}
                key={item}
                disabled={isMenuItemDisabled(item)}
              >
                {item}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
    </div>
  );
};

export default TablePagination;
