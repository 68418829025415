import { ManageCampaignModeEnum } from 'constants/enums';
import { radix } from 'constants/variables';
import { useActions, useTypedSelector } from 'hooks';
import React, { ChangeEvent } from 'react';

import {
  SFormControl,
  SFormControlLabel,
  SFormLabel,
  SRadio,
  SRadioGroup,
} from './CampaignType.styled';

const CampaignType: React.FC = () => {
  const { mode } = useTypedSelector(
    (state) => state && state.manageCampaign.main,
  );
  const { campaignType } = useTypedSelector(
    (state) => state && state.manageCampaign.detail,
  );
  const { campaignTypes } = useTypedSelector(
    (state) => state && state.campaigns,
  );

  const { changeCampaignType } = useActions();

  const handleChangeCampaignType = (event: ChangeEvent<HTMLInputElement>) => {
    const {
      target: { value },
    } = event;

    const valueNumber = parseInt(value, radix[10]);
    const currentType = campaignTypes.find((type) => type.id === valueNumber);

    if (currentType) {
      changeCampaignType(currentType);
    }
  };

  const isDisabled = mode === ManageCampaignModeEnum.REVIEW;

  return (
    <SFormControl>
      <SFormLabel>CAMPAIGN TYPE</SFormLabel>
      <SRadioGroup>
        {campaignTypes.map((type) => {
          return (
            <SFormControlLabel
              disabled={isDisabled}
              key={type.id}
              value={type.id}
              label={type.name}
              checked={campaignType?.id === type.id}
              control={<SRadio onChange={handleChangeCampaignType} />}
            />
          );
        })}
      </SRadioGroup>
    </SFormControl>
  );
};

export default CampaignType;
