import { UploadFile as UploadFileIcon } from '@mui/icons-material';
import { SiteIcon } from 'assets/icons';
import {
  INITIAL_SITES_FILTER_VALUE,
  INITIAL_SORTING_VALUE,
} from 'constants/constants';
import { urlConstructors } from 'constants/urls/url';
import { isRole } from 'helpers';
import { createSitesData, TSitesTableDataItem } from 'helpers/constructors';
import { useActions, useTypedSelector } from 'hooks';
import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import {
  IMenuListItems,
  ISitesFilter,
  ISortingValue,
  ITableMenuProps,
  UserRolesEnum,
} from 'types';
import { useDebounce } from 'usehooks-ts';

const durationMultiplier = 1000;

interface ISitePageHookReturn {
  searchQuery: string;
  filtersValue: ISitesFilter;
  sortingValue: ISortingValue;
  durationSeconds: number;
  durationMinute: number;
  pageNumber: number;
  pageSize: number;
  tableItems: TSitesTableDataItem[];
  tableMenuItem: ITableMenuProps;
  resetPagination: () => void;
  handleClickAddSite: () => void;
  setSearchQuery: Dispatch<SetStateAction<string>>;
  setDurationFilterSeconds: Dispatch<SetStateAction<number>>;
  setDurationFilterMinute: Dispatch<SetStateAction<number>>;
  setPageSize: Dispatch<SetStateAction<number>>;
  setPageNumber: Dispatch<SetStateAction<number>>;
  setFiltersValue: Dispatch<SetStateAction<ISitesFilter>>;
  setSortingValue: Dispatch<SetStateAction<ISortingValue>>;
}

const useSitePage = (): ISitePageHookReturn => {
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [durationSeconds, setDurationFilterSeconds] = useState<number>(0);
  const [durationMinute, setDurationFilterMinute] = useState<number>(0);
  const [pageNumber, setPageNumber] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(10);
  const [currentClicked, setCurrentClicked] = useState<number | null>();

  const [tableMenuAnchorEl, setTableMenuAnchorEl] = useState<
    null | HTMLElement | Element
  >(null);

  const [filtersValue, setFiltersValue] = useState<ISitesFilter>(
    INITIAL_SITES_FILTER_VALUE,
  );

  const [sortingValue, setSortingValue] = useState<ISortingValue>(
    INITIAL_SORTING_VALUE,
  );

  const { sites } = useTypedSelector((state) => state && state.sites);
  const { role } = useTypedSelector((state) => state && state.auth);

  const tableItems = createSitesData(sites);
  const { getSites, getSitesFilters, setCurrentSiteId, setSitesModals } =
    useActions();

  const navigate = useNavigate();

  const duration = useMemo(() => {
    if (durationMinute) {
      if (durationSeconds) {
        return durationMinute * 60 + durationSeconds;
      }

      return durationMinute * 60;
    }

    return durationSeconds;
  }, [durationSeconds, durationMinute]);

  const debouncedDuration = useDebounce(duration, 300);
  const debouncedSearchValue = useDebounce(searchQuery, 300);

  const datas = useMemo(() => {
    const filters = {
      'Filters.StatesIds': filtersValue.State,
      'Filters.CentresIds': filtersValue.Centre,
      'Filters.ResolutionsIds': filtersValue.Dimensions,
      'Filters.OrientationsIds': filtersValue['Site type'],
      'Filters.Formats': filtersValue.Formats,
      'Filters.NetworksIds': filtersValue.Network,
      'Filters.Duration': debouncedDuration
        ? debouncedDuration * durationMultiplier
        : undefined,
    };

    const sorting = {
      SortDirection: sortingValue?.SortDirection?.toUpperCase(),
      SortColumn: sortingValue?.SortColumn,
    };

    return {
      pageNumber,
      pageSize,
      SearchQuery: debouncedSearchValue,
      ...sorting,
      ...filters,
    };
  }, [
    filtersValue,
    sortingValue,
    debouncedSearchValue,
    pageNumber,
    pageSize,
    debouncedDuration,
  ]);

  const handleEditSiteClick = () => {
    if (currentClicked) {
      navigate(urlConstructors.editSite(currentClicked));
    }
    setTableMenuAnchorEl(null);
  };

  const handleSchedulesSite = () => {
    if (currentClicked) {
      setCurrentSiteId(currentClicked);
      setSitesModals({ type: 'schedules', value: true });
    }
    setTableMenuAnchorEl(null);
  };

  const handleOpenTableMenu = (
    anchorEl: HTMLElement | Element,
    id?: number,
  ) => {
    setTableMenuAnchorEl(anchorEl);
    setCurrentClicked(id);
  };

  const handleCloseTableMenu = () => {
    setTableMenuAnchorEl(null);
    setCurrentClicked(null);
  };

  const getEditItem = (): IMenuListItems | null => {
    const isEditSitesAccess = isRole(
      [UserRolesEnum.Admin, UserRolesEnum.SuperAdmin],
      role,
    );

    if (isEditSitesAccess) {
      return {
        id: 1,
        label: 'Edit Site',
        icon: <SiteIcon className="edit-site-icon" />,
        onClick: handleEditSiteClick,
      };
    }

    return null;
  };

  const menuListItems: IMenuListItems[] = [
    getEditItem(),
    {
      id: 2,
      label: 'Download Schedules',
      icon: <UploadFileIcon />,
      onClick: handleSchedulesSite,
    },
  ].filter(Boolean) as IMenuListItems[];

  const tableMenuItem: ITableMenuProps = {
    menuListItems,
    tableMenuAnchorEl,
    handleOpenTableMenu,
    handleCloseTableMenu,
  };

  useEffect(() => {
    getSites(datas);

    return () => setTableMenuAnchorEl(null);
  }, [datas]);

  useEffect(() => {
    getSitesFilters([]);
  }, []);

  const resetPagination = () => {
    setPageNumber(0);
  };

  const handleClickAddSite = () => {
    navigate(urlConstructors.newSite());
  };

  return {
    searchQuery,
    filtersValue,
    sortingValue,
    durationSeconds,
    durationMinute,
    pageNumber,
    pageSize,
    tableItems,
    tableMenuItem,
    resetPagination,
    handleClickAddSite,
    setSearchQuery,
    setDurationFilterSeconds,
    setDurationFilterMinute,
    setPageNumber,
    setPageSize,
    setFiltersValue,
    setSortingValue,
  };
};

export default useSitePage;
