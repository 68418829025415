import { Dispatch } from 'redux';

import {
  SideBarActionTypesEnum,
  TSideBarAction,
} from '../../ActionTypes/sideBarTypes/sideBarTypes';

export const sideBarExpand =
  () =>
  (dispatch: Dispatch<TSideBarAction>): void => {
    dispatch({ type: SideBarActionTypesEnum.TOGGLE_EXPAND, payload: true });
  };

export const sideBarCompress =
  () =>
  (dispatch: Dispatch<TSideBarAction>): void => {
    dispatch({ type: SideBarActionTypesEnum.TOGGLE_EXPAND, payload: false });
  };
