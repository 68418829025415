import './ScheduleModalDate.scss';

import { ArrowCircleDown as ArrowCircleDownIcon } from '@mui/icons-material';
import { Tooltip } from '@mui/material';
import { Checkbox, DatePicker } from 'components/common';
import { dateFnsFormats } from 'constants/date/date';
import { format } from 'date-fns';
import { getCountOfCampaignSiteWithOneDayPart } from 'helpers/Campaign';
import { useActions, useTypedSelector } from 'hooks';
import React, { ChangeEvent } from 'react';
import { DateRange } from 'react-day-picker';

const ScheduleModalDate: React.FC = () => {
  const { scheduleCampaignModalData, campaignSites } = useTypedSelector(
    (state) => state && state.manageCampaign.manage,
  );

  const { setDatesOnScheduleModal, setUseForAllSites } = useActions();

  const handleDateChange = (date: DateRange | undefined) => {
    setDatesOnScheduleModal(date);
  };

  const handleUseForAllChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
    setUseForAllSites(target.checked);
  };

  const { count } = getCountOfCampaignSiteWithOneDayPart(campaignSites);

  const currentCampaignSite = scheduleCampaignModalData.campaignSite;
  const defaultMonth = scheduleCampaignModalData?.dates?.from || new Date();
  const startDate = scheduleCampaignModalData?.dates?.from;
  const endDate = scheduleCampaignModalData?.dates?.to;
  const isForAllValue = scheduleCampaignModalData.isUseForAll;
  const isNeededAllSitesCheckForNoBulk =
    currentCampaignSite?.siteDayParts &&
    currentCampaignSite?.siteDayParts.length === 1 &&
    count > 1;

  const isNeededAllSitesCheck = scheduleCampaignModalData.isBulk
    ? false
    : isNeededAllSitesCheckForNoBulk;

  return (
    <div className="upper-block-wrapper">
      <div className="schedule-modal-date-wrapper">
        <DatePicker
          mode="range"
          defaultDate={scheduleCampaignModalData?.dates}
          defaultMonth={defaultMonth}
          onSelect={handleDateChange}
          disabled={{ before: new Date() }}
        />
        <div className="schedule-modal-date-displayed-wrapper">
          <div className="schedule-modal-start-date schedule-modal-date-block">
            <div className="schedule-modal-date-block-label">Starts</div>
            <div className="schedule-modal-date-block-value">
              {startDate && format(startDate, dateFnsFormats.default())}
            </div>
          </div>
          <div className="schedule-modal-date-block-arrow-icon">
            <ArrowCircleDownIcon />
          </div>
          <div className="schedule-modal-end-date schedule-modal-date-block">
            <div className="schedule-modal-date-block-label">Ends</div>
            <div className="schedule-modal-date-block-value">
              {endDate && format(endDate, dateFnsFormats.default())}
            </div>
          </div>
        </div>
      </div>
      {isNeededAllSitesCheck && (
        <div className="use-for-all-wrapper">
          <Tooltip title={`This will set the dates for ${count} sites`}>
            <span>
              <Checkbox
                label="Use these dates for all sites in the campaign"
                checked={isForAllValue}
                onChange={handleUseForAllChange}
              />
            </span>
          </Tooltip>
        </div>
      )}
    </div>
  );
};

export default ScheduleModalDate;
