import { FormControlLabel } from '@mui/material';
import { CampaignTypesEnum } from 'constants/enums/campaign';
import { useActions, useTypedSelector } from 'hooks';
import React, { ChangeEvent, useEffect } from 'react';

import { SSwitch } from './ManageTabFooter.styled';

const ManageTabFooter: React.FC = () => {
  const { campaignType } = useTypedSelector(
    (state) => state && state.manageCampaign.detail,
  );
  const { isPreemptive } = useTypedSelector(
    (state) => state && state.manageCampaign.manage,
  );

  const { changeIsPreemptive } = useActions();

  const isAdvertising = campaignType?.name === CampaignTypesEnum.ADVERTISING;

  useEffect(() => {
    if (!isAdvertising) {
      changeIsPreemptive(true);
    }
  }, [campaignType]);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    changeIsPreemptive(event.target.checked);
  };

  return (
    <div className="manage-campaign-footer">
      <FormControlLabel
        control={
          <SSwitch
            sx={{ m: 1 }}
            checked={isPreemptive}
            onChange={handleChange}
            disabled={!isAdvertising}
          />
        }
        label="Set campaign as preemptive"
      />
    </div>
  );
};

export default ManageTabFooter;
