import produce from 'immer';

import {
  NetworksActionsEnum,
  TNetworksAction,
} from '../../ActionTypes/NetworksTypes/networksTypes';
import { INetworkState } from './type';

const initialValues: INetworkState = {
  networks: [],
  loading: {
    getAll: false,
    createOne: false,
  },
};

const networkReducer = produce(
  (draft = initialValues, action: TNetworksAction) => {
    switch (action.type) {
      case NetworksActionsEnum.SET_NETWORKS: {
        draft.networks = action.payload;

        return draft;
      }

      case NetworksActionsEnum.SET_LOADING: {
        draft.loading[action.payload.type] = action.payload.value;

        return draft;
      }

      default:
        return draft;
    }
  },
  initialValues,
);

export default networkReducer;
