import { combineReducers } from 'redux';

import advertiserReducer from './advertiserReducer/advertiserReducer';
import appReducer from './appReducer/appReducer';
import authReducer from './authReducer/authReducer';
import campaignsReducer from './campaignsReducer/main/campaignsReducer';
import manageCampaignReducerRoot from './campaignsReducer/manage/manageCampaignReducerRoot';
import categoryReducer from './categoryReducer/categoryReducer';
import chooseCategoryReducer from './categoryReducer/chooseCategoryReducer';
import centreReducer from './centre/centreReducer';
import mediaReducers from './mediaReducer';
import networkReducer from './network/networkReducer';
import organisationReducer from './organisationReducer/organisationReducer';
import productReducer from './productReducer/productReducer';
import sideBarReducer from './sideBarReducer/sideBarReducer';
import sitesReducer from './sites/main/siteReducer';
import manageSiteReducer from './sites/manageSite/manageSite';
import resolutionReducer from './sites/resolutions/resolutionReducer';
import statesReducer from './states/statesReducer';
import urlReducer from './urlReducer/urlReducer';
import usersReducer from './users/usersReducer';

export const rootReducers = combineReducers({
  app: appReducer,
  sideBar: sideBarReducer,
  auth: authReducer,
  campaigns: campaignsReducer,
  manageCampaign: manageCampaignReducerRoot,
  products: productReducer,
  advertiser: advertiserReducer,
  category: categoryReducer,
  chooseCategory: chooseCategoryReducer,
  sites: sitesReducer,
  manageSite: manageSiteReducer,
  resolution: resolutionReducer,
  organisation: organisationReducer,
  medias: mediaReducers,
  url: urlReducer,
  users: usersReducer,
  network: networkReducer,
  centre: centreReducer,
  states: statesReducer,
});

export type TRootState = ReturnType<typeof rootReducers>;
