import { getInitialDaysOfWeek, momentFormats } from 'constants/date/date';
import { ManageCampaignAddMediaTabsEnum } from 'constants/enums';
import {
  checkExistCampaignSchedule,
  convertIsoToDate,
  findCampaignSite,
  getCampaignSchedule,
  getCampaignSchedulesExcludedSites,
  getCampaignSchedulesIds,
  getCountOfCampaignSiteWithOneDayPart,
  getDefaultScheduleTime,
  getFullSelectedSitesFromSelectedSchedules,
  getMediasUniqBulk,
  getSelectedCampaignSchedules,
  getSelectedCampaignSites,
  getSiteToFullOverwriting,
} from 'helpers/Campaign';
import {
  changeValueToDayPartsOfWeek,
  getDayOfWeekString,
  getDayPartFromDuration,
  getDaysOfWeek,
} from 'helpers/date/date';
import produce from 'immer';
import _ from 'lodash';
import moment from 'moment';
import {
  ManageTabCampaignActionsEnum,
  TManageCampaignManageTabActions,
} from 'redux/ActionTypes';
import {
  ICampaignSchedulesNew,
  IMediaForCreateCampaign,
  TDaysOfWeek,
} from 'types';
import { v4 as uuidv4 } from 'uuid';

import { IInitialManageTabCampaignState } from './type';

const initialValues: IInitialManageTabCampaignState = {
  campaignSites: [],
  isPreemptive: false,
  selectedSiteSchedule: {},
  siteFilters: [],
  loaders: {},
  modals: {
    addSites: false,
    campaignSchedule: false,
    addMedia: false,
    stopCampaign: false,
    removeModal: false,
    viewAllMedia: false,
  },
  scheduleCampaignModalData: {
    isUseForAll: false,
    isBulk: false,
    dayParts: [],
  },
  addMediaModal: {
    currentTab: ManageCampaignAddMediaTabsEnum.RECENTLY,
    isBulk: false,
    selectedMedias: {
      selectedTab: [],
      recentlyTab: [],
      allTab: [],
    },
    mediaFilters: [],
    mediaStorage: {
      selectedTab: [],
      recentlyTab: [],
      loaderStore: [],
    },
  },
  viewAllMediaModal: {
    medias: [],
  },
};

const manageCampaign = produce(
  (
    draft: IInitialManageTabCampaignState,
    action: TManageCampaignManageTabActions,
  ) => {
    switch (action.type) {
      case ManageTabCampaignActionsEnum.CONTAIN_MANAGE_TAB: {
        draft.isPreemptive = action.payload.isPreemptive;
        draft.campaignSites = convertIsoToDate(action.payload.campaignSites);

        return draft;
      }

      case ManageTabCampaignActionsEnum.PUT_TO_CAMPAIGN_SITES: {
        draft.campaignSites = [...draft.campaignSites, ...action.payload];

        return draft;
      }

      case ManageTabCampaignActionsEnum.SET_CAMPAIGN_SCHEDULE_DATES: {
        const isExist = checkExistCampaignSchedule(draft.campaignSites, {
          campaignSiteId: action.payload.campaignSiteId,
          campaignScheduleId: action.payload.campaignScheduleId,
        });

        if (isExist) {
          draft.campaignSites = draft.campaignSites.map((campaignSite) => {
            if (campaignSite.id === action.payload.campaignSiteId) {
              return {
                ...campaignSite,
                campaignSchedules: campaignSite.campaignSchedules.map(
                  (campaignSchedule) => {
                    if (
                      campaignSchedule.id === action.payload.campaignScheduleId
                    ) {
                      return {
                        ...campaignSchedule,
                        startDate: action.payload.dates.startDate,
                        endDate: action.payload.dates.endDate,
                      };
                    }

                    return campaignSchedule;
                  },
                ),
              };
            }

            return campaignSite;
          });

          return draft;
        }

        draft.campaignSites = draft.campaignSites.map((campaignSite) => {
          if (campaignSite.id === action.payload.campaignSiteId) {
            const campaignSchedulesDraft = [...campaignSite.campaignSchedules];
            campaignSchedulesDraft.push({
              ...action.payload.dates,
              id: uuidv4(),
              scheduleMedias: [],
              duration: 0,
            });

            return {
              ...campaignSite,
              campaignSchedules: campaignSchedulesDraft,
            };
          }

          return campaignSite;
        });

        return draft;
      }

      case ManageTabCampaignActionsEnum.CONTAIN_SCHEDULE_MODAL: {
        const lookingCampaignSite = findCampaignSite(
          draft.campaignSites,
          action.payload,
        );

        if (!lookingCampaignSite) {
          return draft;
        }

        draft.scheduleCampaignModalData.campaignSite = lookingCampaignSite;
        draft.scheduleCampaignModalData.dates = {
          from: lookingCampaignSite.campaignSchedules?.[0]?.startDate,
          to: lookingCampaignSite.campaignSchedules?.[0]?.endDate,
        };

        if (!lookingCampaignSite.campaignSchedules.length) {
          draft.scheduleCampaignModalData.dayParts = [
            {
              duration: lookingCampaignSite.siteDayParts[0].adLength,
              times: getDefaultScheduleTime(),
              days: getDaysOfWeek(),
              startTimeEdge: moment(
                lookingCampaignSite.siteDayParts[0].startTime,
                momentFormats.time().fullTime.default,
              ),
              endTimeEdge: moment(
                lookingCampaignSite.siteDayParts[0].endTime,
                momentFormats.time().fullTime.default,
              ),
            },
          ];

          return draft;
        }

        draft.scheduleCampaignModalData.dayParts =
          lookingCampaignSite.campaignSchedules.map((campaignSchedule) => ({
            duration: campaignSchedule.duration,
            times: getDefaultScheduleTime(),
            days: getDaysOfWeek(campaignSchedule.dayOfWeek),
            startTimeEdge: moment(
              getDayPartFromDuration(
                lookingCampaignSite.siteDayParts,
                campaignSchedule.duration,
              ).startTime,
              momentFormats.time().fullTime.default,
            ),
            endTimeEdge: moment(
              getDayPartFromDuration(
                lookingCampaignSite.siteDayParts,
                campaignSchedule.duration,
              ).endTime,
              momentFormats.time().fullTime.default,
            ),
          }));

        return draft;
      }

      case ManageTabCampaignActionsEnum.CONTAIN_SCHEDULE_MODAL_TEMPLATE: {
        draft.scheduleCampaignModalData.dayParts = [
          {
            duration: 0,
            times: getDefaultScheduleTime(),
            days: getInitialDaysOfWeek(),
            startTimeEdge: moment(
              '00:00:00',
              momentFormats.time().fullTime.default,
            ),
            endTimeEdge: moment(
              '23:59:00',
              momentFormats.time().fullTime.default,
            ),
          },
        ];

        return draft;
      }

      case ManageTabCampaignActionsEnum.SET_DATES_ON_SCHEDULE_MODAL: {
        draft.scheduleCampaignModalData.dates = action.payload;

        return draft;
      }

      case ManageTabCampaignActionsEnum.SET_TIMES_ON_SCHEDULE_MODAL: {
        draft.scheduleCampaignModalData.dayParts[action.payload.index].times =
          action.payload.times;

        return draft;
      }

      case ManageTabCampaignActionsEnum.SET_DAYS_OF_WEEK_SCHEDULE_MODAL: {
        if (action.payload.day === 'All') {
          if (action.payload.checked) {
            draft.scheduleCampaignModalData.dayParts[
              action.payload.index
            ].days = getInitialDaysOfWeek();
          } else {
            draft.scheduleCampaignModalData.dayParts[
              action.payload.index
            ].days = getInitialDaysOfWeek(false);
          }

          return draft;
        }

        const { days } =
          draft.scheduleCampaignModalData.dayParts[action.payload.index];

        draft.scheduleCampaignModalData.dayParts[action.payload.index].days =
          changeValueToDayPartsOfWeek(
            days,
            action.payload.day as TDaysOfWeek,
            action.payload.checked,
          );

        return draft;
      }

      case ManageTabCampaignActionsEnum.ADD_ANOTHER_DAY_PART: {
        const currentCampaignSite =
          draft.scheduleCampaignModalData.campaignSite;

        if (!currentCampaignSite) {
          return draft;
        }

        const newDayPartDuration = currentCampaignSite.siteDayParts[0].adLength;
        const startTimeEdge = moment(
          currentCampaignSite.siteDayParts[0].startTime,
          momentFormats.time().fullTime.default,
        );
        const endTimeEdge = moment(
          currentCampaignSite.siteDayParts[0].endTime,
          momentFormats.time().fullTime.default,
        );

        if (!newDayPartDuration) {
          return draft;
        }

        const dayPartsDraft = [...draft.scheduleCampaignModalData.dayParts];
        dayPartsDraft.push({
          duration: newDayPartDuration,
          times: getDefaultScheduleTime(),
          startTimeEdge,
          endTimeEdge,
          days: getInitialDaysOfWeek(),
        });
        draft.scheduleCampaignModalData.dayParts = dayPartsDraft;

        return draft;
      }

      case ManageTabCampaignActionsEnum.ADD_ANOTHER_DAY_PART_BULK: {
        const dayPartsDraft = [...draft.scheduleCampaignModalData.dayParts];

        const startTimeEdge = moment(
          '00:00:00',
          momentFormats.time().fullTime.default,
        );
        const endTimeEdge = moment(
          '23:59:00',
          momentFormats.time().fullTime.default,
        );

        dayPartsDraft.push({
          duration: 0,
          startTimeEdge,
          endTimeEdge,
          days: getInitialDaysOfWeek(),
        });

        draft.scheduleCampaignModalData.dayParts = dayPartsDraft;

        return draft;
      }

      case ManageTabCampaignActionsEnum.REMOVE_DAY_PART: {
        draft.scheduleCampaignModalData.dayParts =
          draft.scheduleCampaignModalData.dayParts.filter(
            (__, index) => index !== action.payload,
          );

        return draft;
      }

      case ManageTabCampaignActionsEnum.SET_DAY_PART_SCHEDULE_MODAL: {
        const currentCampaignSite =
          draft.scheduleCampaignModalData.campaignSite;

        if (!currentCampaignSite) {
          return draft;
        }

        const lookingDayPart = getDayPartFromDuration(
          currentCampaignSite.siteDayParts,
          action.payload.duration,
        );

        draft.scheduleCampaignModalData.dayParts[
          action.payload.index
        ].duration = action.payload.duration;
        draft.scheduleCampaignModalData.dayParts[
          action.payload.index
        ].startTimeEdge = moment(
          lookingDayPart.startTime,
          momentFormats.time().fullTime.default,
        );
        draft.scheduleCampaignModalData.dayParts[
          action.payload.index
        ].endTimeEdge = moment(
          lookingDayPart.endTime,
          momentFormats.time().fullTime.default,
        );

        return draft;
      }

      case ManageTabCampaignActionsEnum.SET_SELECTED_SCHEDULE: {
        draft.selectedSiteSchedule = action.payload;

        return draft;
      }

      case ManageTabCampaignActionsEnum.REMOVE_SELECTED_ITEMS: {
        const selectedIds = Object.keys(draft.selectedSiteSchedule);
        const sitesIdsForRemove = getFullSelectedSitesFromSelectedSchedules(
          draft.campaignSites,
          selectedIds,
        );
        const schedulesForRemove = getCampaignSchedulesExcludedSites(
          draft.campaignSites,
          sitesIdsForRemove,
          selectedIds,
        );

        const schedulesIdsForRemove = schedulesForRemove.map(
          (schedule) => schedule.id,
        );

        const campaignSitesFiltered = draft.campaignSites.filter(
          (campaignSite) => !sitesIdsForRemove.includes(campaignSite.id),
        );

        draft.campaignSites = campaignSitesFiltered.map((campaignSite) => {
          return {
            ...campaignSite,
            campaignSchedules: campaignSite.campaignSchedules.filter(
              (campaignSchedule) =>
                !schedulesIdsForRemove.includes(campaignSchedule.id),
            ),
          };
        });

        return draft;
      }

      case ManageTabCampaignActionsEnum.SET_IS_FOR_ALL_SITES: {
        draft.scheduleCampaignModalData.isUseForAll = action.payload;

        return draft;
      }

      case ManageTabCampaignActionsEnum.SET_IS_BULK_SCHEDULE_MODAL: {
        draft.scheduleCampaignModalData.isBulk = action.payload;

        return draft;
      }

      case ManageTabCampaignActionsEnum.DONE_SCHEDULE_MODAL: {
        const modalData = draft.scheduleCampaignModalData;
        const currentCampaignSite =
          draft.scheduleCampaignModalData.campaignSite;

        // If for some reason the current campaign is not selected, then we do nothing
        if (!currentCampaignSite) {
          return draft;
        }

        // We count how many schedules we need
        const neededCampaignScheduled = modalData.dayParts.length;

        // If they suddenly turned out to be 0, then we do nothing
        if (!neededCampaignScheduled) {
          return draft;
        }

        // Creating a schedule template
        const newCampaignSchedules: ICampaignSchedulesNew[] = Array.from({
          length: neededCampaignScheduled,
        }).map((__, index) => ({
          id: currentCampaignSite.campaignSchedules[index]?.id || uuidv4(),
          startDate: modalData.dates?.from,
          endDate: modalData.dates?.to,
          startTime: moment(modalData.dayParts[index].times?.[0]).format(
            momentFormats.time().fullTime.default,
          ),
          endTime: moment(modalData.dayParts[index].times?.[1]).format(
            momentFormats.time().fullTime.default,
          ),
          duration: modalData.dayParts[index].duration,
          dayOfWeek: getDayOfWeekString(modalData.dayParts[index].days),
          scheduleMedias: [
            ...(currentCampaignSite.campaignSchedules[index]?.scheduleMedias ||
              []),
          ],
        }));

        // Overwriting schedules in the current site
        draft.campaignSites = draft.campaignSites.map((campaignSite) => {
          if (campaignSite.id === currentCampaignSite.id) {
            return { ...campaignSite, campaignSchedules: newCampaignSchedules };
          }

          return campaignSite;
        });

        return draft;
      }

      case ManageTabCampaignActionsEnum.DONE_SCHEDULE_MODAL_FOR_ALL: {
        const modalData = draft.scheduleCampaignModalData;
        /**
         * In order not to stretch the logic even more, we will only change those sites that have 1 dayPart
         * To do this, we need to know from the quantity and identifiers
         */
        const { count, campaignSites } = getCountOfCampaignSiteWithOneDayPart(
          draft.campaignSites,
        );

        // if suddenly there are no such, then we do nothing
        if (!count) {
          return draft;
        }

        // We count how many schedules we need
        const neededCampaignScheduled = modalData.dayParts.length;

        // If they suddenly turned out to be 0, then we do nothing
        if (!neededCampaignScheduled) {
          return draft;
        }

        /** Since we now have several sites, and we need to go through the array with another array.
         * But we understand that we can't do it all at once.
         * We'll have to split it into several iterations */

        // 1. Creating a basic template for schedules, without id and medias
        const campaignSchedulesDraft: ICampaignSchedulesNew[] = Array.from({
          length: neededCampaignScheduled,
        }).map((__, index) => ({
          id: 0,
          startDate: modalData.dates?.from,
          endDate: modalData.dates?.to,
          startTime: moment(modalData.dayParts[index].times?.[0]).format(
            momentFormats.time().fullTime.default,
          ),
          endTime: moment(modalData.dayParts[index].times?.[1]).format(
            momentFormats.time().fullTime.default,
          ),
          duration: modalData.dayParts[index].duration,
          dayOfWeek: getDayOfWeekString(modalData.dayParts[index].days),
          scheduleMedias: [],
        }));

        // 2. This step is only needed to pull ids and medias if the schedule already existed before
        const draftCampaignSitesCampaignSchedules = campaignSites.reduce<
          Record<string, ICampaignSchedulesNew[]>
        >((acc, campaignSite) => {
          const accumulatorCampaignSchedules = { ...acc };

          accumulatorCampaignSchedules[String(campaignSite.id)] =
            campaignSchedulesDraft.map((campaignSchedule, index) => ({
              ...campaignSchedule,
              id: campaignSite.campaignSchedules[index]?.id || uuidv4(),
              scheduleMedias: [
                ...(campaignSite.campaignSchedules[index]?.scheduleMedias ||
                  []),
              ],
            }));

          return accumulatorCampaignSchedules;
        }, {});

        const idsList = Object.keys(draftCampaignSitesCampaignSchedules);

        // 3. Rewriting sites
        draft.campaignSites = draft.campaignSites.map((campaignSite) => {
          if (idsList.includes(String(campaignSite.id))) {
            return {
              ...campaignSite,
              campaignSchedules:
                draftCampaignSitesCampaignSchedules[String(campaignSite.id)],
            };
          }

          return campaignSite;
        });

        // 🤯🤯
        return draft;
      }

      case ManageTabCampaignActionsEnum.DONE_SCHEDULE_MODAL_BULK: {
        const modalData = draft.scheduleCampaignModalData;
        const ids = Object.keys(draft.selectedSiteSchedule);

        /**
         * We get sites that are highlighted
         * */

        const selectedSites = getSelectedCampaignSites(
          draft.campaignSites,
          ids,
          1,
        );
        const sitesIdsForFullOverwriting = getSiteToFullOverwriting(ids);

        const neededCampaignScheduled = modalData.dayParts.length;

        // If they suddenly turned out to be 0, then we do nothing
        if (!neededCampaignScheduled) {
          return draft;
        }

        const campaignSchedulesIds = getCampaignSchedulesIds(
          draft.campaignSites,
          ids,
        );

        /**
         * 1. Creating a basic template for schedules, without id and medias
         * (Attention, the difference from the previous case is that there is no duration)
         */
        const campaignSchedulesDraft: ICampaignSchedulesNew[] = Array.from({
          length: neededCampaignScheduled,
        }).map((__, index) => ({
          id: 0,
          startDate: modalData.dates?.from,
          endDate: modalData.dates?.to,
          startTime: moment(modalData.dayParts[index].times?.[0]).format(
            momentFormats.time().fullTime.default,
          ),
          endTime: moment(modalData.dayParts[index].times?.[1]).format(
            momentFormats.time().fullTime.default,
          ),
          duration: 0,
          dayOfWeek: getDayOfWeekString(modalData.dayParts[index].days),
          scheduleMedias: [],
        }));

        /**  2. This step is necessary in order to fill up the schedules on the site.
         * Here, as in the previous case, it will not be possible to select them by index,
         * since only one schedule can be allocated, and we can record, for example, two additional ones.
         * That's why so many extra checks are required
         */
        const draftCampaignSitesCampaignSchedules = selectedSites.reduce<
          Record<string, ICampaignSchedulesNew[]>
        >((acc, campaignSite) => {
          const accumulatorCampaignSchedules = { ...acc };
          let campaignSchedulesIdsCopy = [...campaignSchedulesIds];

          accumulatorCampaignSchedules[String(campaignSite.id)] =
            campaignSchedulesDraft.map((campaignSchedule) => {
              const lookingCampaignSchedule =
                campaignSite.campaignSchedules.find((campaignScheduleItem) =>
                  campaignSchedulesIdsCopy.includes(campaignScheduleItem.id),
                );

              if (lookingCampaignSchedule) {
                campaignSchedulesIdsCopy = campaignSchedulesIdsCopy.filter(
                  (item) => item !== lookingCampaignSchedule.id,
                );

                return {
                  ...campaignSchedule,
                  id: lookingCampaignSchedule.id,
                  duration: lookingCampaignSchedule.duration,
                  scheduleMedias: lookingCampaignSchedule.scheduleMedias,
                };
              }

              return {
                ...campaignSchedule,
                id: uuidv4(),
                duration: campaignSite.siteDayParts[0].adLength,
                scheduleMedias: [],
              };
            });

          return accumulatorCampaignSchedules;
        }, {});

        // Does not include sites with a large number of day parts
        const finalSiteIdsList = Object.keys(
          draftCampaignSitesCampaignSchedules,
        );

        /**
         * 3. Rewriting sites
         * (Considering those sites that need to be overwritten)
         */
        draft.campaignSites = draft.campaignSites.map((campaignSite) => {
          if (finalSiteIdsList.includes(String(campaignSite.id))) {
            if (sitesIdsForFullOverwriting.includes(campaignSite.id)) {
              return {
                ...campaignSite,
                campaignSchedules:
                  draftCampaignSitesCampaignSchedules[String(campaignSite.id)],
              };
            }

            return {
              ...campaignSite,
              campaignSchedules: campaignSite.campaignSchedules.reduce<
                ICampaignSchedulesNew[]
              >((acc, campaignSchedule) => {
                const accumulator = [...acc];

                if (campaignSchedulesIds.includes(campaignSchedule.id)) {
                  draftCampaignSitesCampaignSchedules[
                    String(campaignSite.id)
                  ].forEach((item) => accumulator.push(item));

                  return accumulator;
                }

                accumulator.push(campaignSchedule);

                return accumulator;
              }, []),
            };
          }

          return campaignSite;
        });

        draft.scheduleCampaignModalData.isBulk = false;

        // 🤯🤯
        return draft;
      }

      case ManageTabCampaignActionsEnum.SET_IS_PREEMPTIVE: {
        draft.isPreemptive = action.payload;

        return draft;
      }

      case ManageTabCampaignActionsEnum.SET_LOADING: {
        draft.loaders[action.payload.type] = action.payload.value;

        return draft;
      }

      case ManageTabCampaignActionsEnum.SET_MODAL: {
        draft.modals[action.payload.type] = action.payload.value;

        return draft;
      }

      case ManageTabCampaignActionsEnum.SET_SITE_FILTERS: {
        draft.siteFilters = action.payload;

        return draft;
      }

      case ManageTabCampaignActionsEnum.SET_DRAFT_MANAGE_TAB: {
        draft.draft = { ...draft };

        return draft;
      }

      case ManageTabCampaignActionsEnum.APPLY_DRAFT_MANAGE_TAB: {
        if (draft.draft) {
          draft = { ...draft.draft };

          return draft;
        }

        draft = initialValues;

        return draft;
      }

      case ManageTabCampaignActionsEnum.CONTAIN_ADD_MEDIA_MODAL: {
        const { campaignSchedule, campaignSite } = getCampaignSchedule(
          draft.campaignSites,
          action.payload,
        );

        draft.addMediaModal.campaignSchedule = campaignSchedule;
        draft.addMediaModal.campaignSite = campaignSite;
        draft.addMediaModal.selectedMedias.selectedTab =
          campaignSchedule.scheduleMedias;

        return draft;
      }

      case ManageTabCampaignActionsEnum.CONTAIN_ADD_MEDIA_MODAL_BULK: {
        const selectedIds = Object.keys(draft.selectedSiteSchedule);

        const selectedCampaignSites = getSelectedCampaignSites(
          draft.campaignSites,
          selectedIds,
        );

        if (!selectedCampaignSites.length) {
          return draft;
        }

        const { selectedCampaignSchedules } = getSelectedCampaignSchedules(
          draft.campaignSites,
          selectedIds,
        );

        if (!selectedCampaignSchedules.length) {
          return draft;
        }

        const [firstCampaignSite] = selectedCampaignSites;
        const [firstCampaignSchedule] = selectedCampaignSchedules;

        draft.addMediaModal.campaignSite = firstCampaignSite;
        draft.addMediaModal.campaignSchedule = firstCampaignSchedule;

        return draft;
      }

      case ManageTabCampaignActionsEnum.SET_CURRENT_TAB_MEDIA_MODAL: {
        draft.addMediaModal.currentTab = action.payload;

        return draft;
      }

      case ManageTabCampaignActionsEnum.SET_SELECTED_MEDIA_STORAGE: {
        draft.addMediaModal.mediaStorage.selectedTab = action.payload;

        return draft;
      }

      case ManageTabCampaignActionsEnum.SET_RECENTLY_MEDIA_STORAGE: {
        draft.addMediaModal.mediaStorage.recentlyTab = action.payload;

        return draft;
      }

      case ManageTabCampaignActionsEnum.ADD_TO_SELECTED: {
        const media = { mediaId: action.payload.media.id, tags: '' };

        if (action.payload.tab === ManageCampaignAddMediaTabsEnum.SELECTED) {
          draft.addMediaModal.selectedMedias.selectedTab.push(media);

          return draft;
        }

        if (action.payload.tab === ManageCampaignAddMediaTabsEnum.RECENTLY) {
          draft.addMediaModal.selectedMedias.recentlyTab.push(media);

          return draft;
        }

        if (action.payload.tab === ManageCampaignAddMediaTabsEnum.ALL) {
          draft.addMediaModal.selectedMedias.allTab.push(media);

          return draft;
        }

        return draft;
      }

      case ManageTabCampaignActionsEnum.REMOVE_FROM_SELECTED: {
        draft.addMediaModal.selectedMedias.selectedTab =
          draft.addMediaModal.selectedMedias.selectedTab.filter(
            (media) => media.mediaId !== action.payload,
          );
        draft.addMediaModal.selectedMedias.recentlyTab =
          draft.addMediaModal.selectedMedias.recentlyTab.filter(
            (media) => media.mediaId !== action.payload,
          );
        draft.addMediaModal.selectedMedias.allTab =
          draft.addMediaModal.selectedMedias.allTab.filter(
            (media) => media.mediaId !== action.payload,
          );

        return draft;
      }

      case ManageTabCampaignActionsEnum.ADD_TO_SELECTED_FROM_ALL_TAB: {
        const medias = action.payload.map((media) => ({
          mediaId: media.id,
          tags: '',
        }));
        draft.addMediaModal.selectedMedias.allTab = medias;

        return draft;
      }

      case ManageTabCampaignActionsEnum.SET_CURRENT_LOOKING_MEDIA: {
        draft.addMediaModal.currentLookingMedia = action.payload;

        return draft;
      }

      case ManageTabCampaignActionsEnum.SET_MEDIA_FILTERS: {
        draft.addMediaModal.mediaFilters = action.payload;

        return draft;
      }

      case ManageTabCampaignActionsEnum.SET_IS_BULK_ADD_MEDIA_MODAL: {
        draft.addMediaModal.isBulk = action.payload;

        return draft;
      }

      case ManageTabCampaignActionsEnum.DONE_ADD_MEDIA_MODAL: {
        const currentCampaignSchedule = draft.addMediaModal.campaignSchedule;
        if (!currentCampaignSchedule) {
          return draft;
        }

        const loadersStore =
          draft.addMediaModal.mediaStorage.loaderStore.reduce<
            IMediaForCreateCampaign[]
          >((acc, media) => {
            const medias = [...acc];
            if (typeof media.id === 'number') {
              medias.push({ mediaId: media.id });
            }

            return medias;
          }, []);

        const medias = _.concat(
          draft.addMediaModal.selectedMedias.allTab,
          draft.addMediaModal.selectedMedias.recentlyTab,
          draft.addMediaModal.selectedMedias.selectedTab,
          loadersStore,
        );

        draft.campaignSites = draft.campaignSites.map((campaignSite) => {
          const lookingCampaignSchedule = campaignSite.campaignSchedules.find(
            (campaignSchedule) =>
              campaignSchedule.id === currentCampaignSchedule.id,
          );

          if (lookingCampaignSchedule) {
            return {
              ...campaignSite,
              campaignSchedules: campaignSite.campaignSchedules.map(
                (campaignSchedule) => {
                  if (campaignSchedule.id === currentCampaignSchedule.id) {
                    return { ...campaignSchedule, scheduleMedias: medias };
                  }

                  return campaignSchedule;
                },
              ),
            };
          }

          return campaignSite;
        });

        return draft;
      }

      case ManageTabCampaignActionsEnum.DONE_ADD_MEDIA_MODAL_BULK: {
        const selectedIds = Object.keys(draft.selectedSiteSchedule);

        const { ids } = getSelectedCampaignSchedules(
          draft.campaignSites,
          selectedIds,
        );

        const loadersStore =
          draft.addMediaModal.mediaStorage.loaderStore.reduce<
            IMediaForCreateCampaign[]
          >((acc, media) => {
            const medias = [...acc];
            if (typeof media.id === 'number') {
              medias.push({ mediaId: media.id });
            }

            return medias;
          }, []);

        const medias = _.concat(
          draft.addMediaModal.selectedMedias.allTab,
          draft.addMediaModal.selectedMedias.recentlyTab,
          loadersStore,
        );

        draft.campaignSites = draft.campaignSites.map((campaignSite) => {
          return {
            ...campaignSite,
            campaignSchedules: campaignSite.campaignSchedules.map(
              (campaignSchedule) => {
                if (ids.includes(campaignSchedule.id)) {
                  return {
                    ...campaignSchedule,
                    scheduleMedias: getMediasUniqBulk(
                      medias,
                      campaignSchedule.scheduleMedias,
                    ),
                  };
                }

                return campaignSchedule;
              },
            ),
          };
        });

        return draft;
      }

      case ManageTabCampaignActionsEnum.ADD_FILE_TO_LOAD: {
        draft.addMediaModal.mediaStorage.loaderStore.push(action.payload);

        return draft;
      }

      case ManageTabCampaignActionsEnum.CHANGE_STATUS_LOADED_FILE: {
        draft.addMediaModal.mediaStorage.loaderStore =
          draft.addMediaModal.mediaStorage.loaderStore.map((file) => {
            if (file.id === action.payload.id) {
              return { ...file, status: action.payload.status };
            }

            return file;
          });

        return draft;
      }

      case ManageTabCampaignActionsEnum.SET_CURRENT_RENAMED_FILE: {
        draft.addMediaModal.currentRenamedFile = action.payload;

        return draft;
      }

      case ManageTabCampaignActionsEnum.RENAME_LOADED_FILE: {
        draft.addMediaModal.mediaStorage.loaderStore =
          draft.addMediaModal.mediaStorage.loaderStore.map((file) => {
            if (file.id === action.payload.id) {
              return { ...file, name: action.payload.name };
            }

            return file;
          });

        return draft;
      }

      case ManageTabCampaignActionsEnum.REMOVE_LOADED_FILE: {
        draft.addMediaModal.mediaStorage.loaderStore =
          draft.addMediaModal.mediaStorage.loaderStore.filter(
            (file) => file.id !== action.payload,
          );

        return draft;
      }

      case ManageTabCampaignActionsEnum.CHANGE_LOAD_PROGRESS: {
        draft.addMediaModal.mediaStorage.loaderStore =
          draft.addMediaModal.mediaStorage.loaderStore.map((file) => {
            if (file.id === action.payload.id) {
              return { ...file, loadProgress: action.payload.loadProgress };
            }

            return file;
          });

        return draft;
      }

      case ManageTabCampaignActionsEnum.SET_LOADED_FILE_SRC: {
        draft.addMediaModal.mediaStorage.loaderStore =
          draft.addMediaModal.mediaStorage.loaderStore.map((file) => {
            if (file.id === action.payload.id) {
              return { ...file, src: action.payload.src };
            }

            return file;
          });

        return draft;
      }

      case ManageTabCampaignActionsEnum.SET_MEDIAS: {
        draft.viewAllMediaModal.medias = action.payload;

        return draft;
      }

      case ManageTabCampaignActionsEnum.CHANGE_LOADED_FILE_ID: {
        draft.addMediaModal.mediaStorage.loaderStore =
          draft.addMediaModal.mediaStorage.loaderStore.map((file) => {
            if (file.id === action.payload.currentId) {
              return { ...file, id: action.payload.newId };
            }

            return file;
          });

        return draft;
      }

      case ManageTabCampaignActionsEnum.CLEANUP_MANAGE_TAB: {
        draft = { ...initialValues };

        return draft;
      }

      case ManageTabCampaignActionsEnum.CLEANUP_SCHEDULE_MODAL: {
        draft.scheduleCampaignModalData = {
          ...initialValues.scheduleCampaignModalData,
        };

        return draft;
      }

      case ManageTabCampaignActionsEnum.CLEANUP_ADD_MEDIA_MODAL: {
        draft.addMediaModal = { ...initialValues.addMediaModal };

        return draft;
      }

      case ManageTabCampaignActionsEnum.CLEANUP_VIEW_ALL_MEDIA_MODAL: {
        draft.viewAllMediaModal = { ...initialValues.viewAllMediaModal };

        return draft;
      }

      default:
        return draft;
    }
  },
  initialValues,
);

export default manageCampaign;
