import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LinearProgress,
  Typography,
} from '@mui/material';
import { Button, Select } from 'components/common';
import { convertToSelectData } from 'helpers';
import { useTypedSelector } from 'hooks';
import { helperMessages, texts } from 'pages/Campaign/constants';
import React from 'react';

import useSelectCampaign from './SelectCampaign.hook';

const { fromCampaign } = convertToSelectData;

const SelectCampaign: React.FC = () => {
  const { loadings } = useTypedSelector(
    (state) => state && state.manageCampaign.main,
  );

  const { campaigns, searchCampaign, loaders, modals } = useTypedSelector(
    (state) => state && state.manageCampaign.detail,
  );

  const { handleSearch, handleChange, handleClose, handleAgree } =
    useSelectCampaign();

  return (
    <div className="select-exist-campaign">
      <Select
        options={fromCampaign(campaigns)}
        onInputChange={handleSearch}
        inputValue={searchCampaign}
        isLoading={loaders.getByName}
        onChange={handleChange}
        value={null}
      />

      <Dialog open={modals.copyWarning} onClose={handleClose}>
        {loadings.getOne && <LinearProgress />}
        <DialogTitle>Warning</DialogTitle>
        <DialogContent>
          <Typography>{helperMessages.copyWarning}</Typography>
        </DialogContent>
        <DialogActions>
          <Button
            label={texts.buttons.cancel}
            onClick={handleClose}
            className="dangerous-button"
          />
          <Button
            label={texts.buttons.agree}
            onClick={handleAgree}
            className="successfully"
          />
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default SelectCampaign;
