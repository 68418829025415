import { Button as MuiButton, Menu, MenuItem } from '@mui/material';
import { Button } from 'components/common';
import { colors } from 'constants/colors/colors';
import { withStyles } from 'tss-react/mui';

const ViewAllMediaButton = withStyles(Button, {
  root: {
    textTransform: 'unset',
    fontSize: '12px',
  },
});

const SBulkActionsButton = withStyles(MuiButton, {
  root: {
    font: 'normal normal normal 16px/30px Akkurat-Bold',
    letterSpacing: '1.6px',
  },
});

const SMenu = withStyles(Menu, (theme) => ({
  root: {
    zIndex: theme.zIndex.drawer - 1,
  },
}));

const SMenuItem = withStyles(MenuItem, {
  root: {
    color: colors.Cerulean,

    '&.red': {
      color: colors.Red,
    },
  },
});

export { SBulkActionsButton, SMenu, SMenuItem, ViewAllMediaButton };
