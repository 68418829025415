import './BulkActionsButton.scss';

import { NavigateNext as NavigateNextIcon } from '@mui/icons-material';
import React from 'react';

import SvgIcon from '../../components/common/SvgIcon/SvgIcon';
import { ISelectedTableItem, ITableMenuProps, TItem } from '../../types';
import Button from '../common/Button';
import ListMenu from '../common/ListMenu';

// Todo Refactoring selectedItems

interface IBulkActionsProps<Item> {
  bulkActionsMenuItems: ITableMenuProps;
  selectedItems?: Item[];
  selectedItemsIdsAndIndexes?: ISelectedTableItem[];
}

const BulkActionsButton: React.FC<IBulkActionsProps<TItem>> = (props) => {
  const { bulkActionsMenuItems, selectedItems, selectedItemsIdsAndIndexes } =
    props;

  const { handleOpenTableMenu, tableMenuAnchorEl } = bulkActionsMenuItems;

  const isOpened = Boolean(tableMenuAnchorEl);

  const selectedItemsIds = selectedItems?.map((item) => item.id.value);

  const handleOpenMenu = (e: React.FormEvent) => {
    const anchor: Element = e.currentTarget;
    handleOpenTableMenu(anchor);
  };

  return (
    <div className="bulk-actions-container">
      <Button
        className="bulk-actions-button"
        variant="text"
        label="Bulk actions"
        onClick={handleOpenMenu}
        endIcon
        icon={
          <SvgIcon
            className={
              isOpened
                ? 'bulk-icon-container-opened'
                : 'bulk-icon-container-closed'
            }
          >
            <NavigateNextIcon className="activeSVG bulk-actions-icon" />
          </SvgIcon>
        }
      />
      <ListMenu
        popupAlign="right"
        selectedItemsIds={selectedItemsIds}
        menuItem={bulkActionsMenuItems}
        selectedItems={selectedItems}
        selectedItemsIdsAndIndexes={selectedItemsIdsAndIndexes}
      />
    </div>
  );
};

export default BulkActionsButton;
