import React from 'react';
import { IMedia } from 'types';

interface IImageSlide {
  media: IMedia;
}

const ImageSlide: React.FC<IImageSlide> = ({ media }) => {
  return (
    <div className="image-wrapper">
      <img src={media.url} alt={media.name} className="slider-image" />
    </div>
  );
};

export default ImageSlide;
