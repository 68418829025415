import { Dispatch } from 'redux';

import {
  TUrlStateAction,
  UrlActionTypesEnum,
} from '../../ActionTypes/UrlTypes/UrlTypes';

export const postUrl =
  (url: string) =>
  (dispatch: Dispatch<TUrlStateAction>): void => {
    dispatch({
      type: UrlActionTypesEnum.POST_URL_LOCATION,
      payload: { location: url },
    });
  };
