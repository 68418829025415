import { IFiltersList, IMedia } from '../../../types';
import { IGetAllMediaPayload } from './type';

export interface IMediaState {
  media: IMedia[];
  isLoadingMedia: boolean;
  hasErrorMediaLoading: boolean;
  totalItems: number;
  pageNumber: number;
  pageSize: number;
  mediaFilters: IFiltersList[];
  isErrorMediaFiltersLoading: boolean;
  isLoadingMediaFilters: boolean;
  isLoadingRemoveMedias: boolean;
  hasErrorRemoveMedias: boolean;
  isLoadingRemoveMedia: boolean;
  hasErrorRemoveMedia: boolean;
  recentlyMedia: IMedia[];
  selectedMedia: IMedia[];
  isLoadingRecentlyMedia: boolean;
  isLoadingSelectedMedia: boolean;
  hasErrorRecentlyMedia: boolean;
  hasErrorSelectedMedia: boolean;
}

export enum MediaActionTypesEnum {
  GET_MEDIA_LOADING = 'GET_MEDIA_LOADING',
  GET_MEDIA_SUCCESS = 'GET_MEDIA_SUCCESS',
  GET_MEDIA_ERROR = 'GET_MEDIA_ERROR',
  GET_MEDIA_FILTERS_LOADING = 'GET_MEDIA_FILTERS_LOADING',
  GET_MEDIA_FILTERS_SUCCESS = 'GET_MEDIA_FILTERS_SUCCESS',
  GET_MEDIA_FILTERS_ERROR = 'GET_MEDIA_FILTERS_ERROR',
  REMOVE_MEDIAS_LOADING = 'REMOVE_MEDIAS_LOADING',
  REMOVE_MEDIAS_SUCCESS = 'REMOVE_MEDIAS_SUCCESS',
  REMOVE_MEDIAS_ERROR = 'REMOVE_MEDIAS_ERROR',
  REMOVE_MEDIA_LOADING = 'REMOVE_MEDIA_LOADING',
  REMOVE_MEDIA_SUCCESS = 'REMOVE_MEDIA_SUCCESS',
  REMOVE_MEDIA_ERROR = 'REMOVE_MEDIA_ERROR',
  MEDIA_LENGTH = 'MEDIA_LENGTH',
  GET_RECENTLY_MEDIA_LOADING = 'GET_RECENTLY_MEDIA_LOADING',
  GET_RECENTLY_MEDIA_SUCCESS = 'GET_RECENTLY_MEDIA_SUCCESS',
  GET_RECENTLY_MEDIA_ERROR = 'GET_RECENTLY_MEDIA_ERROR',
  GET_SELECTED_MEDIA_LOADING = 'GET_SELECTED_MEDIA_LOADING',
  GET_SELECTED_MEDIA_SUCCESS = 'GET_SELECTED_MEDIA_SUCCESS',
  GET_SELECTED_MEDIA_ERROR = 'GET_SELECTED_MEDIA_ERROR',
}

interface IGetMediaLoadingAction {
  type: MediaActionTypesEnum.GET_MEDIA_LOADING;
}
interface IGetMediaSuccessAction {
  type: MediaActionTypesEnum.GET_MEDIA_SUCCESS;
  payload: IGetAllMediaPayload;
}
interface IGetMediaErrorAction {
  type: MediaActionTypesEnum.GET_MEDIA_ERROR;
  payload: boolean;
}

interface IGetMediaFiltersLoadingAction {
  type: MediaActionTypesEnum.GET_MEDIA_FILTERS_LOADING;
}
interface IGetMediaFiltersSuccessAction {
  type: MediaActionTypesEnum.GET_MEDIA_FILTERS_SUCCESS;
  payload: any;
}
interface IGetMediaFiltersErrorAction {
  type: MediaActionTypesEnum.GET_MEDIA_FILTERS_ERROR;
  payload: boolean;
}

interface IRemoveMediasLoadingAction {
  type: MediaActionTypesEnum.REMOVE_MEDIAS_LOADING;
}
interface IRemoveMediasSuccessAction {
  type: MediaActionTypesEnum.REMOVE_MEDIAS_SUCCESS;
  payload: any;
}
interface IRemoveMediasErrorAction {
  type: MediaActionTypesEnum.REMOVE_MEDIAS_ERROR;
  payload: boolean;
}

interface IRemoveMediaLoadingAction {
  type: MediaActionTypesEnum.REMOVE_MEDIA_LOADING;
}
interface IRemoveMediaSuccessAction {
  type: MediaActionTypesEnum.REMOVE_MEDIA_SUCCESS;
  payload: any;
}
interface IRemoveMediaErrorAction {
  type: MediaActionTypesEnum.REMOVE_MEDIA_ERROR;
  payload: boolean;
}

interface ILengthMediaAction {
  type: MediaActionTypesEnum.MEDIA_LENGTH;
  payload: number;
}

interface IGetRecentlyMediaLoadingAction {
  type: MediaActionTypesEnum.GET_RECENTLY_MEDIA_LOADING;
}
interface IGetRecentlyMediaSuccessAction {
  type: MediaActionTypesEnum.GET_RECENTLY_MEDIA_SUCCESS;
  payload: IMedia[];
}
interface IGetRecentlyMediaErrorAction {
  type: MediaActionTypesEnum.GET_RECENTLY_MEDIA_ERROR;
  payload: boolean;
}

interface IGetSelectedMediaLoadingAction {
  type: MediaActionTypesEnum.GET_SELECTED_MEDIA_LOADING;
}
interface IGetSelectedMediaSuccessAction {
  type: MediaActionTypesEnum.GET_SELECTED_MEDIA_SUCCESS;
  payload: IMedia[];
}
interface IGetSelectedMediaErrorAction {
  type: MediaActionTypesEnum.GET_SELECTED_MEDIA_ERROR;
  payload: boolean;
}

export type TMediaAction =
  | IGetMediaLoadingAction
  | IGetMediaSuccessAction
  | IGetMediaErrorAction
  | IGetMediaFiltersLoadingAction
  | IGetMediaFiltersSuccessAction
  | IGetMediaFiltersErrorAction
  | IRemoveMediasLoadingAction
  | IRemoveMediasSuccessAction
  | IRemoveMediasErrorAction
  | IRemoveMediaLoadingAction
  | IRemoveMediaSuccessAction
  | IRemoveMediaErrorAction
  | IGetRecentlyMediaLoadingAction
  | IGetRecentlyMediaSuccessAction
  | IGetRecentlyMediaErrorAction
  | IGetSelectedMediaLoadingAction
  | IGetSelectedMediaSuccessAction
  | IGetSelectedMediaErrorAction
  | ILengthMediaAction;
