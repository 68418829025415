import { getMediasUniqBulk } from 'helpers/Campaign';
import _ from 'lodash';
import { ICampaignSitesNew, IScheduleMediaNew } from 'types';

export const getAllSchedulesMedia = (
  campaignSites: ICampaignSitesNew[],
  isUniq = false,
): IScheduleMediaNew[] => {
  const medias = campaignSites.reduce<IScheduleMediaNew[]>(
    (acc, campaignSite) => {
      const mediasList = [...acc];

      campaignSite.campaignSchedules.forEach((campaignSchedule) => {
        campaignSchedule.scheduleMedias.forEach((scheduleMedia) => {
          mediasList.push(scheduleMedia);
        });
      });

      return mediasList;
    },
    [],
  );

  if (!medias.length) {
    return medias;
  }

  if (isUniq) {
    return getMediasUniqBulk(medias);
  }

  return medias;
};

export const getSitesByMediaId = (
  campaignSites: ICampaignSitesNew[],
  mediaId: number,
): ICampaignSitesNew[] => {
  const scheduleSites = campaignSites.reduce<ICampaignSitesNew[]>(
    (acc, campaignSite) => {
      const sitesList = [...acc];

      const hasMediaIntoSite = campaignSite.campaignSchedules.some(
        (campaignSchedule) => {
          const hasMedia = campaignSchedule.scheduleMedias.some(
            (scheduleMedia) => scheduleMedia.mediaId === mediaId,
          );

          if (hasMedia) {
            return true;
          }

          return false;
        },
      );

      if (hasMediaIntoSite) {
        sitesList.push(campaignSite);
      }

      return sitesList;
    },
    [],
  );

  return _.uniqBy(scheduleSites, 'siteId');
};
