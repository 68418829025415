import { ISetModalsPayload } from './types';

export enum ManageSiteActionEnum {
  SET_IS_NEW_CENTRE = 'SET_IS_NEW_CENTRE',

  SET_MODALS = 'SET_MODALS',
}

interface ISetNewCentre {
  type: ManageSiteActionEnum.SET_IS_NEW_CENTRE;
  payload: boolean;
}

interface ISetModals {
  type: ManageSiteActionEnum.SET_MODALS;
  payload: ISetModalsPayload;
}

export type TManageSiteActions = ISetNewCentre | ISetModals;
