import * as Yup from 'yup';

import { regulars } from '../constants/regular/regulars';
import { errorsLabel } from '../constants/text/text';

export const RESET_PASSWORD_SCHEMA = Yup.object({
  password: Yup.string()
    .required(errorsLabel.passwordReq)
    .matches(regulars.passwordReg, errorsLabel.invalidePassword),
  confirmPassword: Yup.string().oneOf(
    [Yup.ref('password'), null],
    errorsLabel.passwordMatch,
  ),
});

export const FORGOT_PASSWORD_SCHEMA = Yup.object({
  login: Yup.string().required(errorsLabel.loginReq),
});

export const MAGIC_LINK_SCHEMA = Yup.object({
  email: Yup.string().email().required(errorsLabel.loginReq),
});

export const LOGIN_VALIDATION_SCHEMA = Yup.object({
  login: Yup.string().required(errorsLabel.loginReq),
  password: Yup.string().required(errorsLabel.passwordReq),
});

export const DECLINING_SCHEMA = Yup.object({
  reason: Yup.string().required(errorsLabel.reasonReq),
});
