import { SelectChangeEvent } from '@mui/material';
import { AlertStatusesEnum } from 'constants/constants';
import { FormikHelpers } from 'formik';
import { isRole } from 'helpers';
import { isRoleExcept } from 'helpers/role';
import { useActions, useTypedSelector } from 'hooks';
import { useSnackbar } from 'notistack';
import { useMemo } from 'react';
import { TFormikSetValues, UserRolesEnum } from 'types';
import { IObjectIdWithName } from 'types/general';

import { createUserScheme, getInitialValues, TInitialValues } from './utils';

interface IManageUserModalHookReturn {
  initialValues: TInitialValues;
  roleItems: IObjectIdWithName[];
  roleNames: UserRolesEnum[];
  isLoadingManageUser: boolean;
  handleModalClose: () => void;
  handleManageUserSubmit: (
    values: TInitialValues,
    formikHelpers: FormikHelpers<TInitialValues>,
  ) => void | Promise<void>;
  handleRoleChange: (
    event: SelectChangeEvent<UserRolesEnum>,
    setValues: TFormikSetValues<TInitialValues>,
  ) => void;
  viewTitle: () => string;
}

const title = {
  create: 'Create new user',
  update: 'Update user',
} as const;

const useManageUserModal = (): IManageUserModalHookReturn => {
  const { role: currentRole } = useTypedSelector(
    (state) => state && state.auth,
  );
  const {
    loaders,
    queryParams,
    manageUserData,
    allUsersTable: { currentClickedUser, currentUserData },
  } = useTypedSelector((state) => state && state.users);

  const {
    getAllUsers,
    createUser,
    updateUser,
    usersModalToggler,
    allUserTableRemoveCurrentClickedUser,
  } = useActions();

  const { enqueueSnackbar } = useSnackbar();

  /** It is created depending on what we want, to update the user or create a new one */
  const initialValues: TInitialValues = getInitialValues(currentUserData);

  const roleItems = useMemo(() => {
    if (isRole(UserRolesEnum.SuperAdmin, currentRole)) {
      return manageUserData.roles;
    }

    return manageUserData.roles.filter((role) =>
      isRoleExcept(
        [UserRolesEnum.SuperAdmin, UserRolesEnum.Admin],
        role.name as UserRolesEnum,
      ),
    );
  }, [currentRole, manageUserData.roles]);

  const roleNames = roleItems.map((role) => role.name) as UserRolesEnum[];

  const handleModalClose = (): void => {
    if (!loaders.createUser && !loaders.updateUser) {
      usersModalToggler({ type: 'manageUser', value: false });
      allUserTableRemoveCurrentClickedUser();
    }
  };

  const viewTitle = (): string => {
    return currentClickedUser ? title.update : title.create;
  };

  const handleManageUserSubmit = async (
    values: TInitialValues,
    { setSubmitting }: FormikHelpers<TInitialValues>,
  ) => {
    try {
      if (values.isUpdate) {
        if (currentClickedUser) {
          await updateUser(
            currentClickedUser.id,
            createUserScheme(values, {
              originalCentreNetworks: currentUserData?.centreNetworks,
            }),
          );
          enqueueSnackbar('User successfully updated', {
            variant: AlertStatusesEnum.SUCCESS,
          });
        } else {
          throw new Error('User ID is missing');
        }
      } else {
        await createUser(createUserScheme(values));
        enqueueSnackbar('User successfully added', {
          variant: AlertStatusesEnum.SUCCESS,
        });
      }
      setSubmitting(false);
      await getAllUsers(queryParams.getAll);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      enqueueSnackbar(error?.response?.data?.UserMessage, {
        variant: AlertStatusesEnum.ERROR,
        anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
      });
    }
  };

  const createCentreNetworkState = (
    ongoingRole: UserRolesEnum,
    { centreNetworks }: TInitialValues,
  ): TInitialValues['centreNetworks'] => {
    const isNeedCentreNetwork = isRole(
      [
        UserRolesEnum.Advertising,
        UserRolesEnum.Editor,
        UserRolesEnum.Marketing,
      ],
      ongoingRole,
    );

    if (isNeedCentreNetwork) {
      if ((centreNetworks && !centreNetworks.length) || !centreNetworks) {
        return [{ centreId: null, networkIds: [], id: 0 }];
      }
    }

    if (centreNetworks) {
      return [...centreNetworks];
    }

    return null;
  };

  const handleRoleChange = (
    event: SelectChangeEvent<UserRolesEnum>,
    setValues: TFormikSetValues<TInitialValues>,
  ): void => {
    setValues((prev) => ({
      ...prev,
      role: event.target.value as UserRolesEnum,
      centreNetworks: createCentreNetworkState(
        event.target.value as UserRolesEnum,
        prev,
      ),
    }));
  };

  const isLoadingManageUser =
    Boolean(loaders.manageUser) ||
    Boolean(loaders.createUser) ||
    Boolean(loaders.updateUser);

  return {
    initialValues,
    roleItems,
    roleNames,
    isLoadingManageUser,
    viewTitle,
    handleModalClose,
    handleManageUserSubmit,
    handleRoleChange,
  } as const;
};

export default useManageUserModal;
