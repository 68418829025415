import { AxiosPromise } from 'axios';

import { AxiosMethodEnum } from '../../../constants/text/text';
import { urls } from '../../../constants/urls/url';
import { apiCall } from '../../../helpers/api/apiCall';
import { INewAdvertiser } from './type';

function getAdvertisers<T extends object>(url: string): AxiosPromise<T> {
  return apiCall<T>(AxiosMethodEnum.get, url, '', null, '', {});
}

function postAdvertisers<T extends object>(
  url: string,
  data: object,
): AxiosPromise<T> {
  return apiCall<T>(AxiosMethodEnum.post, url, data, null, '', {});
}

export const allAdvertisers = <T extends object>(): AxiosPromise<T> =>
  getAdvertisers<T>(urls.advertisers);

export const newAdvertisers = <T extends object>(
  data: INewAdvertiser,
): AxiosPromise<T> => postAdvertisers<T>(urls.advertisers, data);
