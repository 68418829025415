import React from 'react';
import ReactPlayer from 'react-player';
import { IMedia } from 'types';

interface IVideoSlide {
  media: IMedia;
}

const VideoSlide: React.FC<IVideoSlide> = ({ media }) => {
  return (
    <div className="video-wrapper">
      <ReactPlayer url={media.url} controls />
    </div>
  );
};

export default VideoSlide;
