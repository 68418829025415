import produce from 'immer';

import {
  AppActionTypesEnum,
  TAppAction,
} from '../../ActionTypes/appTypes/appTypes';
import { IAppState, TCurrentWindow, TCurrentWindowParams } from './types';

const initialState: IAppState = {
  currentWindowParam: { isSmall: false, isMedium: false },
};

const appReducer = produce(
  (draft: IAppState = initialState, action: TAppAction): IAppState => {
    switch (action.type) {
      case AppActionTypesEnum.SWITCH_WINDOW_PARAM: {
        draft.currentWindowParam = Object.entries(
          draft.currentWindowParam,
        ).reduce<TCurrentWindow>((acc, [key]) => {
          if (action.payload === key) {
            acc[key as TCurrentWindowParams] = true;
          } else {
            acc[key as TCurrentWindowParams] = false;
          }

          return acc;
        }, draft.currentWindowParam);

        return draft;
      }
      default:
        return draft;
    }
  },
  initialState,
);

export default appReducer;
