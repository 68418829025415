import * as yup from 'yup';

import { messages } from './texts';

export const validationSchema = yup.object().shape({
  name: yup.string().required(messages.required),
  address: yup.string().required(messages.required),
  isNewCentre: yup.boolean(),
  newCentreName: yup.string().when('isNewCentre', {
    is: true,
    then: yup.string().required(messages.required),
  }),
  state: yup.object().when('isNewCentre', {
    is: true,
    then: yup.object().required(messages.required),
  }),
  broadsignId: yup.number().required(messages.required),
  centre: yup.object().when('isNewCentre', {
    is: false,
    then: yup.object().required(messages.required),
  }),
  network: yup.object().required(messages.required),
  formats: yup
    .array()
    .of(yup.object())
    .min(1, messages.required)
    .required(messages.required),
  isNewResolution: yup.boolean(),
  resolution: yup.object().when('isNewResolution', {
    is: false,
    then: yup.object().required(messages.required),
  }),
  newResolution: yup.object().when('isNewResolution', {
    is: true,
    then: yup.object({
      width: yup.string().required(messages.required),
      height: yup.string().required(messages.required),
    }),
  }),
  orientation: yup.object().when('isNewResolution', {
    is: true,
    then: yup.object().required(messages.required),
  }),
});
