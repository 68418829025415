import {
  Event as EventIcon,
  HighlightOff as HighlightOffIcon,
  Image as ImageIcon,
} from '@mui/icons-material';
import { colors } from 'constants/colors/colors';
import { AlertStatusesEnum } from 'constants/constants';
import { ManageCampaignModeEnum } from 'constants/enums';
import {
  addMediaModalBulkInspector,
  getSelectedCampaignSites,
} from 'helpers/Campaign';
import { useActions, useTypedSelector } from 'hooks';
import { useSnackbar } from 'notistack';
import React, { ReactNode } from 'react';

import { useAddMedia } from '../../../../../Modals/AddMedia/hooks';
import useScheduleModal from '../../../../../Modals/ScheduleModal/ScheduleModal.hook';

export interface IMenuItems {
  label: string;
  icon?: ReactNode;
  disabled: boolean;
  onClick: () => void;
  classNames?: string;
}

interface IUseHeaderHookReturn {
  menuItems: IMenuItems[];
}

const useHeader = (): IUseHeaderHookReturn => {
  const { mode } = useTypedSelector(
    (state) => state && state.manageCampaign.main,
  );
  const { campaignSites, selectedSiteSchedule } = useTypedSelector(
    (state) => state && state.manageCampaign.manage,
  );
  const {
    setIsBulkScheduleModal,
    setIsBulkAddMediaModal,
    containScheduleModalTemplate,
    containAddMediaModalBulk,
    setModalsManageTab,
  } = useActions();
  const { handleOpen } = useScheduleModal();
  const { handleAddMediaOpen } = useAddMedia();
  const { enqueueSnackbar } = useSnackbar();
  const showErrorSnackbar = (message: string) =>
    enqueueSnackbar(message, {
      variant: AlertStatusesEnum.ERROR,
    });

  const handleClickBulkSchedule = (): void => {
    setIsBulkScheduleModal(true);
    containScheduleModalTemplate();
    handleOpen();
  };

  const handleClickBulkMedia = (): void => {
    const ids = Object.keys(selectedSiteSchedule);
    const isAllSchedulesValid = addMediaModalBulkInspector(campaignSites, ids);

    if (!isAllSchedulesValid.dimension) {
      showErrorSnackbar('Sites must have the same screen resolutions');

      return;
    }

    if (!isAllSchedulesValid.formats) {
      showErrorSnackbar('Sites must have the same file formats');

      return;
    }

    if (!isAllSchedulesValid.duration) {
      showErrorSnackbar('Sites should have the same duration');

      return;
    }

    setIsBulkAddMediaModal(true);
    containAddMediaModalBulk();
    handleAddMediaOpen();
  };

  const handleRemoveModalOpen = () => {
    setModalsManageTab({ type: 'removeModal', value: true });
  };

  const selectedIds = Object.keys(selectedSiteSchedule);

  const isReview = mode === ManageCampaignModeEnum.REVIEW;

  const selectedCampaignOneDayParts = getSelectedCampaignSites(
    campaignSites,
    selectedIds,
    1,
  );

  const selectedCampaignMoreDayParts = getSelectedCampaignSites(
    campaignSites,
    selectedIds,
  );

  const isScheduleDisabled = !selectedCampaignOneDayParts.length || isReview;
  const isMediaDisabled = !selectedCampaignMoreDayParts.length || isReview;
  const isRemoveDisabled = !selectedCampaignMoreDayParts.length || isReview;

  const menuItems = [
    {
      label: 'Schedule',
      icon: <EventIcon sx={{ color: colors.Cerulean }} />,
      disabled: isScheduleDisabled,
      onClick: handleClickBulkSchedule,
    },
    {
      label: 'Media',
      icon: <ImageIcon sx={{ color: colors.Cerulean }} />,
      disabled: isMediaDisabled,
      onClick: handleClickBulkMedia,
    },
    {
      label: 'Remove',
      icon: <HighlightOffIcon sx={{ color: colors.Red }} />,
      disabled: isRemoveDisabled,
      classNames: 'red',
      onClick: handleRemoveModalOpen,
    },
  ];

  return { menuItems };
};

export default useHeader;
