import {
  Downloading as DownloadingIcon,
  Edit as EditIcon,
  HighlightOff as HighlightOffIcon,
  ZoomIn as ZoomInIcon,
} from '@mui/icons-material';
import { Box, ListItemIcon, Menu, Tooltip } from '@mui/material';
import classnames from 'classnames';
import { CircularProgressWithLabel } from 'components/common';
import { colors } from 'constants/colors/colors';
import { FileLoadStatusesEnum } from 'constants/enums';
import { FileTypesEnum } from 'constants/enums/fileLoadStatuses';
import { useActions } from 'hooks';
import React, { MouseEvent, ReactNode, SyntheticEvent, useState } from 'react';
import { ILoadersStorageMedia } from 'redux/Reducers/campaignsReducer/manage/manage/type';

import {
  SFileIconNotUrl,
  SFileListWrapper,
  SMenuItem,
} from './FileItem.styled';

interface IFileItem {
  file: ILoadersStorageMedia;
}

interface IItems {
  label: string;
  icon: ReactNode;
  className: string;
  onClick: (file: ILoadersStorageMedia) => void;
}

const FileItem: React.FC<IFileItem> = ({ file }) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const isLoading = file.status === FileLoadStatusesEnum.LOADED;
  const isSuccess = file.status === FileLoadStatusesEnum.SUCCESS;
  const isError = file.status === FileLoadStatusesEnum.ERROR;

  const { setCurrentLookingMedia, setCurrentRenamedFile, removeLoadedFile } =
    useActions();

  const hasElement = !!anchorEl;

  const getItems = (): IItems[] => {
    const items = [
      {
        label: 'Remove',
        icon: <HighlightOffIcon sx={{ color: colors.Red }} />,
        className: 'red-item',
        onClick: (currentFile: ILoadersStorageMedia) => {
          removeLoadedFile(currentFile.id);
        },
      },
    ];

    if (isSuccess) {
      items.push({
        label: 'View',
        icon: <ZoomInIcon sx={{ color: colors.Cerulean }} />,
        className: '',
        onClick: (currentFile: ILoadersStorageMedia) => {
          setCurrentLookingMedia({
            name: currentFile.name,
            fileType: currentFile.fileType,
            file: currentFile.file,
            url:
              currentFile.fileType === FileTypesEnum.IMAGE
                ? currentFile.src
                : undefined,
          });
        },
      });
      items.push({
        label: 'Rename',
        className: '',
        icon: <EditIcon sx={{ color: colors.Cerulean }} />,
        onClick: (currentFile: ILoadersStorageMedia) => {
          setCurrentRenamedFile(currentFile);
        },
      });
    }

    return items;
  };

  const handleOpen = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event: SyntheticEvent) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  return (
    <SFileListWrapper
      className={classnames({ success: isSuccess, error: isError })}
      onClick={handleOpen}
      disableRipple
    >
      {!file.src ? (
        <SFileIconNotUrl>
          <DownloadingIcon />
        </SFileIconNotUrl>
      ) : (
        <img
          src={file.src}
          alt={file.name}
          style={{ width: '100%', height: '100%', objectFit: 'cover' }}
        />
      )}
      {anchorEl && (
        <Menu anchorEl={anchorEl} open={hasElement} onClose={handleClose}>
          {getItems().map(({ label, icon, onClick, className }) => (
            <SMenuItem
              key={label}
              onClick={() => onClick(file)}
              className={className}
            >
              <ListItemIcon>{icon}</ListItemIcon>
              {label}
            </SMenuItem>
          ))}
        </Menu>
      )}

      {isLoading && (
        <Tooltip title={`Progress: ${file.loadProgress}%`}>
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
            }}
          >
            <CircularProgressWithLabel value={file.loadProgress} />
          </Box>
        </Tooltip>
      )}
    </SFileListWrapper>
  );
};

export default FileItem;
