import produce from 'immer';

import {
  SideBarActionTypesEnum,
  TSideBarAction,
} from '../../ActionTypes/sideBarTypes/sideBarTypes';
import { ISideBarState } from './types';

const initialState: ISideBarState = {
  isExpand: false,
};

const sideBarReducer = produce(
  (draft: ISideBarState = initialState, action: TSideBarAction) => {
    switch (action.type) {
      case SideBarActionTypesEnum.TOGGLE_EXPAND: {
        draft.isExpand = action.payload;

        return draft;
      }

      default:
        return draft;
    }
  },
  initialState,
);

export default sideBarReducer;
