import { FormControl, FormControlLabel, FormLabel, Radio } from '@mui/material';
import RadioGroup from '@mui/material/RadioGroup';
import { colors } from 'constants/colors/colors';
import { withStyles } from 'tss-react/mui';

const SFormControl = withStyles(FormControl, () => ({
  root: {
    marginTop: 16,
  },
}));

const SFormLabel = withStyles(FormLabel, () => ({
  root: {
    fontFamily: "'Akkurat-Regular', sans-serif",
    fontWeight: 600,
    fontSize: 16,
    color: '#00b6f1',
    letterSpacing: '1.6px',
  },
}));

const SRadioGroup = withStyles(RadioGroup, () => ({
  root: {
    flexDirection: 'row',
    gap: 20,
  },
}));

const SFormControlLabel = withStyles(FormControlLabel, () => ({
  root: {
    '.MuiButtonBase-root:not(.Mui-checked) ~ .MuiTypography-root': {
      color: colors.Gray,
    },
  },
}));

const SRadio = withStyles(Radio, () => ({
  root: {
    '&:not(.Mui-checked)': {
      '.MuiSvgIcon-root': {
        fill: colors.Gray,
      },
    },
  },
}));

export { SFormControl, SFormControlLabel, SFormLabel, SRadio, SRadioGroup };
