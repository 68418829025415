import { ConfirmDialog } from 'components/common';
import { useTypedSelector } from 'hooks';
import React from 'react';

import { useActions } from '../../../../../../../../../../../hooks/useActions';
import AddMedia from './AddMedia/AddMedia';
import AddSiteModal from './AddSiteModal/AddSiteModal';
import ScheduleModal from './ScheduleModal/ScheduleModal';
import StopCampaignAlert from './StopCampaignAlert/StopCampaignAlert';
import ViewAllSelectedMedia from './ViewAllSelectedMedia/ViewAllSelectedMedia';

const Modals: React.FC = () => {
  const { modals } = useTypedSelector(
    (state) => state && state.manageCampaign.manage,
  );

  const { setModalsManageTab, removeSelectedItems } = useActions();

  const handleRemoveModalClose = () => {
    setModalsManageTab({ type: 'removeModal', value: false });
  };

  const handleRemoveConfirm = () => {
    removeSelectedItems();
    handleRemoveModalClose();
  };

  return (
    <>
      {modals.addSites && <AddSiteModal />}
      {modals.campaignSchedule && <ScheduleModal />}
      {modals.addMedia && <AddMedia />}
      {modals.viewAllMedia && <ViewAllSelectedMedia />}
      {modals.stopCampaign && <StopCampaignAlert />}
      {modals.removeModal && (
        <ConfirmDialog
          isOpen={modals.removeModal}
          title="Are you sure you want to delete the selected items?"
          onCancel={handleRemoveModalClose}
          onConfirm={handleRemoveConfirm}
        />
      )}
    </>
  );
};

export default Modals;
