import { Button } from '@mui/material';
import { withStyles } from 'tss-react/mui';

const SButton = withStyles(Button, {
  root: {
    textTransform: 'unset',
    fontWeight: 900,
    fontSize: 12,
    padding: 0,
    justifyContent: 'flex-start',
    width: '100%',
  },
});

export { SButton };
