import { IconButton, TextField } from '@mui/material';
import { withStyles } from 'tss-react/mui';

const SIconButton = withStyles(IconButton, (theme) => ({
  root: {
    '&:hover svg': {
      transition: '0.5s ease',
      color: theme.palette.primary.main,
    },
  },
}));

const STextFiled = withStyles(TextField, {
  root: {
    width: '100%',
  },
});

export { SIconButton, STextFiled };
