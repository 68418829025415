import { AlertStatusesEnum } from 'constants/constants';
import { useActions, useTypedSelector } from 'hooks';
import { useSnackbar } from 'notistack';
import { IUserShort } from 'types/user';

interface IBulkDeleteHookReturn {
  handleDeleteBulk: () => Promise<void>;
  handleClose: () => void;
}

const useBulkDelete = (): IBulkDeleteHookReturn => {
  const { loaders, queryParams, bulkDeleteModalData } = useTypedSelector(
    (state) => state && state.users,
  );
  const {
    usersModalToggler,
    deleteUsersBulk,
    getAllUsers,
    allUserTableCleanupSelectedUser,
  } = useActions();

  const { enqueueSnackbar } = useSnackbar();

  const getIds = (data: IUserShort[]): number[] => {
    return data.map((user) => user.id);
  };

  const handleClose = () => {
    if (!loaders.deleteBulk) {
      usersModalToggler({ type: 'deleteBulk', value: false });
    }
  };

  const handleDeleteBulk = async () => {
    try {
      await deleteUsersBulk(getIds(bulkDeleteModalData.selectedUsers));
      await getAllUsers(queryParams.getAll);
      allUserTableCleanupSelectedUser();
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      enqueueSnackbar(error?.response?.data?.UserMessage, {
        variant: AlertStatusesEnum.ERROR,
      });
    }
  };

  return { handleClose, handleDeleteBulk };
};

export default useBulkDelete;
