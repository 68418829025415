import {
  Delete as DeleteIcon,
  Edit as EditIcon,
  MoreVert as MoreVertIcon,
} from '@mui/icons-material';
import {
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Popover,
  useTheme,
} from '@mui/material';
import { Row } from '@tanstack/react-table';
import { isRole } from 'helpers';
import { useActions } from 'hooks';
import { useTypedSelector } from 'hooks/useTypedSelector';
import React, {
  Fragment,
  memo,
  MouseEvent,
  SyntheticEvent,
  useCallback,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { IUserShort } from 'types';
import { UserRolesEnum } from 'types/general';

interface IUserActionsButton {
  row: Row<IUserShort>;
}

const UserActionsButton: React.FC<IUserActionsButton> = ({ row }) => {
  const { role } = useTypedSelector((state) => state && state.auth);
  const {
    allUsersTable: { currentClickedActionsRowId },
  } = useTypedSelector((state) => state && state.users);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const theme = useTheme();

  const { usersModalToggler, getOneUser, setCurrentClickedActionsRowId } =
    useActions();

  useLayoutEffect(() => {
    if (currentClickedActionsRowId === row.original.id) {
      setIsOpen(true);
    } else {
      setIsOpen(false);
    }
  }, [currentClickedActionsRowId]);

  const {
    allUserTableSetCurrentClickedUser,
    allUserTableRemoveCurrentClickedUser,
  } = useActions();

  const handleOpenPopover = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();
      allUserTableSetCurrentClickedUser(row.original);
      setCurrentClickedActionsRowId(row.original.id);
    },
    [],
  );

  const handleClosePopover = useCallback((event: SyntheticEvent) => {
    event.stopPropagation();
    allUserTableRemoveCurrentClickedUser();
    setCurrentClickedActionsRowId(null);
  }, []);

  const anchorEl = useRef<HTMLButtonElement>(null);

  const handleClickEditUser = useCallback(
    (event: MouseEvent<HTMLDivElement>) => {
      event.stopPropagation();
      usersModalToggler({ type: 'manageUser', value: true });
      getOneUser(row.original.id);
      setCurrentClickedActionsRowId(null);
    },
    [],
  );

  const handleClickDeleteUser = useCallback(
    (event: MouseEvent<HTMLDivElement>) => {
      event.stopPropagation();
      usersModalToggler({ type: 'deleteOne', value: true });
      setCurrentClickedActionsRowId(null);
    },
    [],
  );

  const isAdminCheckAdmin =
    isRole(UserRolesEnum.Admin, role) &&
    isRole(UserRolesEnum.Admin, row.original.role);
  const isPopoverView = isOpen && !isAdminCheckAdmin;

  return (
    <Fragment key={row.original.id}>
      <IconButton
        onClick={handleOpenPopover}
        ref={anchorEl}
        disabled={isAdminCheckAdmin}
      >
        <MoreVertIcon />
      </IconButton>
      {isPopoverView && (
        <Popover
          open={isOpen}
          onClose={handleClosePopover}
          anchorEl={anchorEl.current}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          sx={{ zIndex: theme.zIndex.modal - 1 }}
        >
          <List>
            <ListItem disablePadding>
              <ListItemButton onClick={handleClickEditUser}>
                <ListItemIcon>
                  <EditIcon color="primary" />
                </ListItemIcon>
                <ListItemText primary="Edit user" />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton onClick={handleClickDeleteUser}>
                <ListItemIcon>
                  <DeleteIcon color="error" />
                </ListItemIcon>
                <ListItemText primary="Delete user" />
              </ListItemButton>
            </ListItem>
          </List>
        </Popover>
      )}
    </Fragment>
  );
};

export default memo(UserActionsButton);
