import { Box } from '@mui/material';
import { useTypedSelector } from 'hooks';
import React from 'react';

import { STypography, STypographyWrapper } from '../Tabs/Tabs.styled';
import { FileItem } from './components';

const LoadersFileList: React.FC = () => {
  const { mediaStorage } = useTypedSelector(
    (state) => state && state.manageCampaign.manage.addMediaModal,
  );

  return (
    <Box>
      <STypographyWrapper>
        <STypography variant="h4">Uploading Media</STypography>
      </STypographyWrapper>
      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2, p: 4 }}>
        {mediaStorage.loaderStore.map((file) => {
          return <FileItem key={file.id} file={file} />;
        })}
      </Box>
    </Box>
  );
};

export default LoadersFileList;
