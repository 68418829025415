import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import { useFormikContext } from 'formik';
import { useActions, useTypedSelector } from 'hooks';
import React from 'react';

import { messages } from '../../../../../constants/texts';
import useSiteForm from '../../../SiteForm.hook';
import { IInitialValues } from '../../../types';

const { title, content } = messages.incorrectDayPartsSector;

const IncorrectDayParts: React.FC = () => {
  const { values, setSubmitting } = useFormikContext<IInitialValues>();
  const { modals } = useTypedSelector((state) => state && state.manageSite);

  const { handleSubmit } = useSiteForm();

  const { setManageSiteModal } = useActions();

  const handleClose = () => {
    setManageSiteModal({ type: 'hasIncorrectDayParts', value: false });
  };

  const handleSubmitForm = () => {
    setSubmitting(true);
    handleClose();
    handleSubmit(values);
  };

  return (
    <Dialog open={Boolean(modals.hasIncorrectDayParts)} onClose={handleClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>{content}</DialogContent>
      <DialogActions>
        <Button onClick={handleClose} variant="contained" color="error">
          Cancel
        </Button>
        <Button onClick={handleSubmitForm} variant="contained">
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default IncorrectDayParts;
