import { useActions, useTypedSelector } from 'hooks';
import React, { useEffect } from 'react';

import { HasSites, NoSites } from './components';

const ManageTabContent: React.FC = () => {
  const { campaignSites } = useTypedSelector(
    (state) => state && state.manageCampaign.manage,
  );
  const { getSiteFiltersManageCampaign } = useActions();

  useEffect(() => {
    getSiteFiltersManageCampaign();
  }, []);

  const hasSites = Boolean(campaignSites.length);

  return (
    <div className="manage-campaign-content">
      {hasSites ? <HasSites /> : <NoSites />}
    </div>
  );
};

export default ManageTabContent;
