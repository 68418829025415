import { Box } from '@mui/material';
import { withStyles } from 'tss-react/mui';

const SBox = withStyles(Box, {
  root: {
    display: 'grid',
    gap: 20,
    gridTemplateColumns: '1fr 1fr',
  },

  '@media (max-width: 600px)': {
    root: {
      gridTemplateColumns: '1fr',
    },
  },
});

export { SBox };
