import { daysOfWeekIndexes } from 'constants/date/date';
import _ from 'lodash';
import { TDaysOfWeekFull } from 'types';

export const convertToCountDaysOfWeek = (daysOfWeek: string): number => {
  if (daysOfWeek === 'All') {
    return _.sum(Object.values(daysOfWeekIndexes));
  }

  const dayOfWeekArray = daysOfWeek.split(',') as TDaysOfWeekFull[];

  return dayOfWeekArray.reduce<number>((acc, dayOfWeek) => {
    let count = acc;

    if (daysOfWeekIndexes[dayOfWeek]) {
      count += daysOfWeekIndexes[dayOfWeek];
    }

    return count;
  }, 0);
};
