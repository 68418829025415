import { RadioGroup } from '@mui/material';
import { withStyles } from 'tss-react/mui';

const SRadioGroup = withStyles(RadioGroup, {
  root: {
    flexDirection: 'row',
  },
});

export { SRadioGroup };
