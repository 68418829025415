import { TextField } from '@mui/material';
import { withStyles } from 'tss-react/mui';

const STextField = withStyles(TextField, {
  root: {
    height: 'unset',

    '.MuiInputBase-root.Mui-error': {
      border: 'none',
    },

    '.MuiFormLabel-root.Mui-error': {
      border: 'none',
    },
  },
});

export { STextField };
