import produce from 'immer';

import {
  SitesActionTypesEnum,
  TSitesAction,
} from '../../../ActionTypes/sitesTypes/main/main';
import { ISitesState } from './type';

const initialState: ISitesState = {
  sites: [],
  selectedSites: [],
  editCampaignSites: [],
  currentSite: null,
  loadings: {
    selectedSites: false,
    getAll: false,
    getOne: false,
    campaignFilters: false,
    editCampaignSites: false,
    schedules: false,
  },
  modals: {},
  hasErrorSelectedSitesLoading: false,
  hasErrorSitesLoading: false,
  totalItems: 0,
  pageNumber: 0,
  pageSize: 10,
  sitesFilters: [],
  hasErrorCampaignsFiltersLoading: false,
  hasErrorEditCampaignSites: false,
};

const sitesReducer = produce((draft = initialState, action: TSitesAction) => {
  switch (action.type) {
    case SitesActionTypesEnum.GET_SITES_LOADING:
      draft.loadings.getAll = true;

      return draft;
    case SitesActionTypesEnum.GET_SITES_SUCCESS:
      draft.sites = action.payload.sites;
      draft.totalItems = action.payload.totalItems;
      draft.pageNumber = action.payload.pageNumber;
      draft.pageSize = action.payload.pageSize;
      draft.loadings.getAll = false;

      return draft;
    case SitesActionTypesEnum.GET_SITES_ERROR:
      draft.loadings.getAll = false;
      draft.hasErrorSitesLoading = action.payload;

      return draft;
    case SitesActionTypesEnum.GET_SITES_FILTERS_LOADING:
      draft.loadings.campaignFilters = true;

      return draft;
    case SitesActionTypesEnum.GET_SITES_FILTERS_SUCCESS:
      draft.loadings.campaignFilters = false;
      draft.sitesFilters = action.payload.data;

      return draft;
    case SitesActionTypesEnum.GET_SITES_FILTERS_ERROR:
      draft.loadings.campaignFilters = false;
      draft.hasErrorCampaignsFiltersLoading = action.payload;

      return draft;
    case SitesActionTypesEnum.GET_EDIT_CAMPAIGNS_SITES_LOADING:
      draft.loadings.editCampaignSites = true;

      return draft;
    case SitesActionTypesEnum.GET_EDIT_CAMPAIGNS_SITES_SUCCESS:
      draft.loadings.editCampaignSites = false;
      draft.editCampaignSites = action.payload.data;

      return draft;
    case SitesActionTypesEnum.GET_EDIT_CAMPAIGNS_SITES_ERROR:
      draft.loadings.editCampaignSites = false;
      draft.hasErrorEditCampaignSites = action.payload;

      return draft;
    case SitesActionTypesEnum.GET_SELECTED_SITES_LOADING:
      draft.loadings.selectedSites = true;

      return draft;
    case SitesActionTypesEnum.GET_SELECTED_SITES_SUCCESS:
      draft.selectedSites = action.payload.data;
      draft.loadings.selectedSites = false;

      return draft;
    case SitesActionTypesEnum.GET_SELECTED_SITES_ERROR:
      draft.loadings.selectedSites = false;
      draft.hasErrorSelectedSitesLoading = action.payload;

      return draft;
    case SitesActionTypesEnum.CLEAN_UP_SITES_STORE:
      draft.selectedSites = [];
      draft.currentSite = initialState.currentSite;

      return draft;
    case SitesActionTypesEnum.SITES_LENGTH:
      return {
        ...draft,
        totalItems: draft.totalItems - action.payload,
      };

    case SitesActionTypesEnum.SET_CURRENT_SITE_ID: {
      draft.currentSite = { id: action.payload };

      return draft;
    }

    case SitesActionTypesEnum.SET_LOADING: {
      draft.loadings[action.payload.type] = action.payload.value;

      return draft;
    }

    case SitesActionTypesEnum.SET_MODAL: {
      draft.modals[action.payload.type] = action.payload.value;

      return draft;
    }

    case SitesActionTypesEnum.SET_SINGLE_SITE: {
      draft.currentSite = { id: action.payload.id, site: action.payload };

      return draft;
    }

    case SitesActionTypesEnum.CLEANUP_CURRENT_SITE: {
      draft.currentSite = null;

      return draft;
    }

    default:
      return draft;
  }
}, initialState);

export default sitesReducer;
