import React from 'react';

import MainLayout from '../../layouts/Main';
import { Content, Header } from './components';

const UsersPage: React.FC = () => {
  return (
    <MainLayout>
      <Header />
      <Content />
    </MainLayout>
  );
};

export default UsersPage;
