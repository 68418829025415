import { Button } from 'components/common';
import { withStyles } from 'tss-react/mui';

const SButton = withStyles(Button, {
  root: {
    fontSize: 12,
  },
});

export { SButton };
