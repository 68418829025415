import { KeyboardDoubleArrowRight as KeyboardDoubleArrowRightIcon } from '@mui/icons-material';
import { Tooltip } from '@mui/material';
import cn from 'classnames';
import { useActions, useTypedSelector } from 'hooks';
import React from 'react';

import { SButton } from './Logo.styled';

const Logo: React.FC = () => {
  const { isExpand } = useTypedSelector((state) => state && state.sideBar);
  const { currentWindowParam } = useTypedSelector(
    (state) => state && state.app,
  );
  const {
    organisation: { logoUrl, name },
  } = useTypedSelector((state) => state && state.organisation);

  const { sideBarExpand } = useActions();

  return (
    <div className={cn('sidebar-logo-container')}>
      {!currentWindowParam.isSmall || isExpand ? (
        <img src={logoUrl} alt="logo" className={cn('logo', name)} />
      ) : (
        <Tooltip title="EXPAND" placement="right" arrow>
          <SButton variant="outlined" onClick={sideBarExpand}>
            <KeyboardDoubleArrowRightIcon />
          </SButton>
        </Tooltip>
      )}
    </div>
  );
};

export default Logo;
