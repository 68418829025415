import './Coordinate.scss';

import classnames from 'classnames';
import React, {
  forwardRef,
  ReactNode,
  Ref,
  RefObject,
  useImperativeHandle,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';

import { TCoordinate } from '../../../types';
import useCoordinate from './Coordinate.hook';

interface ICoordinate<Element extends HTMLElement> {
  children: ReactNode;
  className?: string;
  anchorEl: RefObject<Element | null>;
}

const Coordinate = <Element extends HTMLElement>(
  { children, className, anchorEl }: ICoordinate<Element>,
  ref: Ref<HTMLDivElement | null>,
) => {
  const [coordinates, setCoordinates] = useState<TCoordinate>({
    top: 0,
    left: 0,
  });

  const { calculatePosition } = useCoordinate();

  const containerRef = useRef<HTMLDivElement>(null);

  useImperativeHandle(ref, () => containerRef.current);

  const handleResize = () => {
    if (anchorEl.current && containerRef.current) {
      setCoordinates(
        calculatePosition<HTMLDivElement | Element>(
          anchorEl.current,
          containerRef.current,
        ),
      );
    }
  };

  useLayoutEffect(() => {
    if (anchorEl.current && containerRef.current) {
      setCoordinates(
        calculatePosition<HTMLDivElement | Element>(
          anchorEl.current,
          containerRef.current,
        ),
      );

      window.addEventListener('resize', handleResize);

      return () => window.removeEventListener('resize', handleResize);
    }
  }, [anchorEl]);

  return (
    <div
      ref={containerRef}
      className={classnames('coordinate', className)}
      style={coordinates}
    >
      {children}
    </div>
  );
};

export default forwardRef(Coordinate);
