import './Table.scss';

import { Table as MaterialTable } from '@mui/material';
import React from 'react';

import { urls } from '../../../constants/urls/url';
import {
  ISelectedTableItem,
  ISortingValue,
  ITableMenuProps,
  ITableText,
} from '../../../types';
import { LocalLoading } from '../../Loading';
import TableListMenu from '../ListMenu';
import TableBody from './TableBody';
import TableFooter from './TableFooter';
import TableHead from './TableHead';

interface ITableProps<Items> {
  items: Items[];
  total: number;
  setPageSize: React.Dispatch<React.SetStateAction<number>>;
  setPageNumber: React.Dispatch<React.SetStateAction<number>>;
  pageNumber: null | number;
  pageSize: null | number;
  tableColumnsFilter: string[];
  isLoadingItems?: boolean;
  sortingValue: ISortingValue;
  setSortingValue: React.Dispatch<React.SetStateAction<ISortingValue>>;
  tableText: ITableText;
  selected: ISelectedTableItem[];
  setSelected: React.Dispatch<React.SetStateAction<ISelectedTableItem[]>>;
  tableMenuItem?: ITableMenuProps;
  isWithoutFooter?: boolean;
  isDisabledSorting?: boolean;
}

interface IItems {
  id: {
    name: string;
    value: number;
    onClick?: () => void;
  };
  sitesId?: {
    name: string;
    value: number;
  };
}

const Table: React.FC<ITableProps<IItems>> = ({
  total,
  pageNumber = 0,
  setPageSize,
  setPageNumber,
  pageSize = 5,
  items,
  tableColumnsFilter,
  sortingValue,
  setSortingValue,
  isLoadingItems,
  tableText,
  selected,
  setSelected,
  tableMenuItem,
  isWithoutFooter,
  isDisabledSorting,
}) => {
  const isModalWindow =
    window.location.pathname === urls.newCampaign ||
    window.location.pathname.includes(urls.editCampaign);

  const handleSelectAllClick = () => {
    const sitesWithoutTwoParts = items.filter(
      (el) =>
        !Array.isArray(
          (el as { duration?: { value?: string | string[] } })?.duration?.value,
        ),
    );

    if (selected.length >= sitesWithoutTwoParts.length) {
      setSelected([]);

      return;
    }

    const allIdsSelectedItems = sitesWithoutTwoParts.map((item) => ({
      id: item.id.value,
    }));

    setSelected(allIdsSelectedItems);
  };

  const menuHandler = (e: React.SyntheticEvent, id?: number) => {
    e.stopPropagation();
    if (tableMenuItem) {
      const { handleOpenTableMenu } = tableMenuItem;
      handleOpenTableMenu(e.currentTarget, id);
    }
  };

  const isNoResults = !isLoadingItems && !items.length;

  return (
    <div className={isModalWindow ? 'modal-root' : 'table-root'}>
      <div className="table-container">
        <MaterialTable
          stickyHeader
          className="table"
          aria-labelledby="tableTitle"
          size="small"
          aria-label="enhanced table"
        >
          {items.length ? (
            <TableHead
              items={items}
              selected={selected}
              onSelectAllClick={handleSelectAllClick}
              rowCount={items.length}
              tableColumnsFilter={tableColumnsFilter}
              sortingValue={sortingValue}
              setSortingValue={setSortingValue}
              tableMenuItem={tableMenuItem}
              isDisabledSorting={isDisabledSorting}
            />
          ) : null}
          {!isLoadingItems && items.length ? (
            <TableBody
              items={items}
              selected={selected}
              setSelected={setSelected}
              tableColumnsFilter={tableColumnsFilter}
              menuHandler={menuHandler}
              tableMenuItem={tableMenuItem}
            />
          ) : null}
        </MaterialTable>
        {!isWithoutFooter && (
          <TableFooter
            total={total}
            pageNumber={pageNumber}
            setPageSize={setPageSize}
            setPageNumber={setPageNumber}
            pageSize={pageSize}
          />
        )}
        {isLoadingItems ? (
          <>
            <div className="center-wrapper">
              <LocalLoading />
            </div>
          </>
        ) : null}
        {isNoResults ? (
          <>
            <div className="center-wrapper">
              <div className="second-header">{tableText.noResults}</div>
            </div>
          </>
        ) : null}
        {tableMenuItem && <TableListMenu menuItem={tableMenuItem} />}
      </div>
    </div>
  );
};

export default Table;
