import { IOrganisation } from '../../../types';
import { IObjectIdWithName } from '../../../types/general';
import { TLoadings } from '../../Reducers/organisationReducer/type';
import { ISetLoadingPayload } from './type';

export interface IOrganisationState {
  organisation: IOrganisation;
  loading: Partial<Record<TLoadings, boolean>>;
  subOrganisations: IObjectIdWithName[];
  isLoadingOrganisation: boolean;
  errorOrganisationLoading: boolean;
}

export enum OrganisationActionTypesEnum {
  GET_ORGANISATION_LOADING = 'GET_ORGANISATION_LOADING',
  GET_ORGANISATION_SUCCESS = 'GET_ORGANISATION_SUCCESS',
  GET_ORGANISATION_ERROR = 'GET_ORGANISATION_ERROR',

  SET_LOADING = 'SET_LOADING_ORGANISATION',
  SET_SUBORGANISATIONS = 'SET_SUBORGANISATIONS',
  CLEANUP_CURRENT = 'CLEANUP_CURRENT',
}

interface IGetOrganisationLoadingAction {
  type: OrganisationActionTypesEnum.GET_ORGANISATION_LOADING;
}
interface IGetOrganisationSuccessAction {
  type: OrganisationActionTypesEnum.GET_ORGANISATION_SUCCESS;
  payload: IOrganisation;
}
interface IGetOrganisationErrorAction {
  type: OrganisationActionTypesEnum.GET_ORGANISATION_ERROR;
  payload: boolean;
}

interface ISetSubOrganisations {
  type: OrganisationActionTypesEnum.SET_SUBORGANISATIONS;
  payload: IObjectIdWithName[];
}

interface ISetLoading {
  type: OrganisationActionTypesEnum.SET_LOADING;
  payload: ISetLoadingPayload;
}

interface ICleanupCurrent {
  type: OrganisationActionTypesEnum.CLEANUP_CURRENT;
}

export type TOrganisationAction =
  | IGetOrganisationLoadingAction
  | IGetOrganisationSuccessAction
  | IGetOrganisationErrorAction
  | ISetSubOrganisations
  | ISetLoading
  | ICleanupCurrent;
