import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';

import { TSizesPage } from '../../../../../../../types';
import { TTable } from '../../../types';
import { ITablePagination } from '../TablePagination';

interface ITablePaginationHookProps<TData> {
  readonly data: TTable<TData>['data'];
  readonly isSSR?: ITablePagination<TData>['isSSR'];
  readonly pagination?: ITablePagination<TData>['pagination'];
  readonly totalDataCount?: ITablePagination<TData>['totalDataCount'];
}

interface ITablePaginationHookReturn {
  readonly pageSize: TSizesPage;
  readonly setPageSize: Dispatch<SetStateAction<TSizesPage>>;
  readonly totalPages: number;
}

const defaultPageSize = 10;

const useTablePagination = <TData>({
  data,
  isSSR,
  pagination,
  totalDataCount,
}: ITablePaginationHookProps<TData>): ITablePaginationHookReturn => {
  const [pageSize, setPageSize] = useState<TSizesPage>(() => {
    if (isSSR && pagination?.pageSize) {
      return pagination.pageSize;
    }

    return defaultPageSize;
  });

  useEffect(() => {
    if (isSSR && pagination?.pageSize) {
      setPageSize(pagination.pageSize);
    }
  }, [pagination?.pageSize]);

  const totalPages = useMemo<number>(() => {
    if (totalDataCount) {
      return Math.ceil(totalDataCount / pageSize);
    }

    if (data.length) {
      return Math.ceil(data.length / pageSize);
    }

    return 1;
  }, [pageSize, totalDataCount]);

  return { pageSize, setPageSize, totalPages };
};

export default useTablePagination;
