import { DateRange } from 'react-day-picker';
import {
  IAddMediaModal,
  IInitialManageTabCampaignState,
  ILoadersStorageMedia,
  IViewAllMediaModal,
} from 'redux/Reducers/campaignsReducer/manage/manage/type';
import { ICampaignByIdNew, ICampaignSitesNew, IMedia } from 'types';

import { IObjectWithId } from '../../../../../types/general';
import {
  IAddToSelectedPayload,
  IChangeFileLoadStatusPayload,
  IChangeLoadedFileIdPayload,
  IChangeLoadProgressPayload,
  IRenameLoadedFilePayload,
  ISetCampaignScheduleDatesPayload,
  ISetDayOfWeekScheduleModalPayload,
  ISetDayPartScheduleModalPayload,
  ISetLoadedFileSrcPayload,
  ISetLoadingManageCampaignManageTab,
  ISetModalManageCampaignManageTab,
  ISetTimeOnScheduleModalPayload,
} from './type';

export enum ManageTabCampaignActionsEnum {
  /** < --------------- CONTAIN --------------- > */

  CONTAIN_MANAGE_TAB = 'CONTAIN_MANAGE_TAB',

  /** < --------------- ADD_SOMETHING --------------- > */

  PUT_TO_CAMPAIGN_SITES = 'PUT_TO_CAMPAIGN_SITES',

  /** < --------------- SCHEDULE_CONFIGURATION --------------- > */

  SET_CAMPAIGN_SCHEDULE_DATES = 'SET_CAMPAIGN_SCHEDULE_DATES',

  /** < --------------- SELECTED --------------- > */

  SET_SELECTED_SCHEDULE = 'SET_SELECTED_SCHEDULE',
  REMOVE_SELECTED_ITEMS = 'REMOVE_SELECTED_ITEMS',

  /** < --------------- SITE --------------- > */

  SET_SITE_FILTERS = 'SET_SITE_FILTERS_MANAGE_CAMPAIGN',

  /** < --------------- CAMPAIGN_CONFIGURATION --------------- > */

  SET_IS_PREEMPTIVE = 'SET_IS_PREEMPTIVE',

  /** < --------------- MODALS/LOADINGS --------------- > */

  SET_LOADING = 'SET_LOADING_MANAGE_CAMPAIGN_MANAGE_TAB',
  SET_MODAL = 'SET_MODAL_MANAGE_CAMPAIGN_MANAGE_TAB',

  /** < --------------- DRAFT --------------- > */

  SET_DRAFT_MANAGE_TAB = 'SET_DRAFT_MANAGE_TAB',
  APPLY_DRAFT_MANAGE_TAB = 'APPLY_DRAFT_MANAGE_TAB',

  /** < --------------- SCHEDULE_MODAL --------------- > */

  CONTAIN_SCHEDULE_MODAL = 'CONTAIN_SCHEDULE_MODAL',
  CONTAIN_SCHEDULE_MODAL_TEMPLATE = 'CONTAIN_SCHEDULE_MODAL_TEMPLATE',
  SET_DATES_ON_SCHEDULE_MODAL = 'SET_DATES_ON_SCHEDULE_MODAL',
  SET_TIMES_ON_SCHEDULE_MODAL = 'SET_TIMES_ON_SCHEDULE_MODAL',
  SET_DAYS_OF_WEEK_SCHEDULE_MODAL = 'SET_DAYS_OF_WEEK_SCHEDULE_MODAL',
  SET_DAY_PART_SCHEDULE_MODAL = 'SET_DAY_PART_SCHEDULE_MODAL',
  SET_IS_FOR_ALL_SITES = 'SET_IS_FOR_ALL_SITES',
  SET_IS_BULK_SCHEDULE_MODAL = 'SET_IS_BULK_SCHEDULE_MODAL',
  ADD_ANOTHER_DAY_PART = 'ADD_ANOTHER_DAY_PART',
  ADD_ANOTHER_DAY_PART_BULK = 'ADD_ANOTHER_DAY_PART_BULK',
  REMOVE_DAY_PART = 'REMOVE_DAY_PART',
  DONE_SCHEDULE_MODAL = 'DONE_SCHEDULE_MODAL',
  DONE_SCHEDULE_MODAL_FOR_ALL = 'DONE_SCHEDULE_MODAL_FOR_ALL',
  DONE_SCHEDULE_MODAL_BULK = 'DONE_SCHEDULE_MODAL_BULK',

  /** < --------------- ADD_MEDIA_MODAL --------------- > */

  CONTAIN_ADD_MEDIA_MODAL = 'CONTAIN_ADD_MEDIA_MODAL',
  CONTAIN_ADD_MEDIA_MODAL_BULK = 'CONTAIN_ADD_MEDIA_MODAL_BULK',
  SET_CURRENT_TAB_MEDIA_MODAL = 'SET_CURRENT_TAB_MEDIA_MODAL',
  SET_SELECTED_MEDIA_STORAGE = 'SET_SELECTED_MEDIA_STORAGE',
  SET_RECENTLY_MEDIA_STORAGE = 'SET_RECENTLY_MEDIA_STORAGE',
  ADD_TO_SELECTED = 'ADD_TO_SELECTED',
  ADD_TO_SELECTED_FROM_ALL_TAB = 'ADD_TO_SELECTED_FROM_ALL_TAB',
  REMOVE_FROM_SELECTED = 'REMOVE_FROM_SELECTED',
  SET_CURRENT_LOOKING_MEDIA = 'SET_CURRENT_LOOKING_MEDIA',
  SET_MEDIA_FILTERS = 'SET_MEDIA_FILTERS',
  DONE_ADD_MEDIA_MODAL = 'DONE_ADD_MEDIA_MODAL',
  DONE_ADD_MEDIA_MODAL_BULK = 'DONE_ADD_MEDIA_MODAL_BULK',
  ADD_FILE_TO_LOAD = 'ADD_FILE_TO_LOAD',
  CHANGE_STATUS_LOADED_FILE = 'CHANGE_STATUS_LOADED_FILE',
  CHANGE_LOAD_PROGRESS = 'CHANGE_LOAD_PROGRESS',
  RENAME_LOADED_FILE = 'RENAME_LOADED_FILE',
  REMOVE_LOADED_FILE = 'REMOVE_LOADED_FILE',
  SET_LOADED_FILE_SRC = 'SET_LOADED_FILE_SRC',
  CHANGE_LOADED_FILE_ID = 'CHANGE_LOADED_FILE_ID',
  SET_CURRENT_RENAMED_FILE = 'SET_CURRENT_RENAMED_FILE',
  SET_IS_BULK_ADD_MEDIA_MODAL = 'SET_IS_BULK_ADD_MEDIA_MODAL',

  /** < --------------- VIEW_ALL_MEDIA_MODAL --------------- > */

  SET_MEDIAS = 'SET_MEDIAS_VIEW_ALL_MEDIA_MODAL',

  /** < --------------- CLEANUP --------------- > */

  CLEANUP_MANAGE_TAB = 'CLEANUP_MANAGE_TAB',
  CLEANUP_SCHEDULE_MODAL = 'CLEANUP_SCHEDULE_MODAL',
  CLEANUP_ADD_MEDIA_MODAL = 'CLEANUP_ADD_MEDIA_MODAL',
  CLEANUP_VIEW_ALL_MEDIA_MODAL = 'CLEANUP_VIEW_ALL_MEDIA_MODAL',
}

/** < --------------- CONTAIN --------------- > */

interface IContainManageTab {
  type: ManageTabCampaignActionsEnum.CONTAIN_MANAGE_TAB;
  payload: ICampaignByIdNew;
}

/** < --------------- ADD_SOMETHING --------------- > */

interface IPutToCampaignSchedules {
  type: ManageTabCampaignActionsEnum.PUT_TO_CAMPAIGN_SITES;
  payload: ICampaignSitesNew[];
}

/** < --------------- SCHEDULE_CONFIGURATION --------------- > */

interface ISetCampaignScheduleDate {
  type: ManageTabCampaignActionsEnum.SET_CAMPAIGN_SCHEDULE_DATES;
  payload: ISetCampaignScheduleDatesPayload;
}

/** < --------------- SELECTED --------------- > */

interface ISetSelectedSchedule {
  type: ManageTabCampaignActionsEnum.SET_SELECTED_SCHEDULE;
  payload: IInitialManageTabCampaignState['selectedSiteSchedule'];
}

interface IRemoveSelectedItems {
  type: ManageTabCampaignActionsEnum.REMOVE_SELECTED_ITEMS;
}

/** < --------------- SITE --------------- > */

interface ISetSiteFiltersManageCampaign {
  type: ManageTabCampaignActionsEnum.SET_SITE_FILTERS;
  payload: IInitialManageTabCampaignState['siteFilters'];
}

/** < --------------- CAMPAIGN_CONFIGURATION --------------- > */

interface ISetIsPreemptive {
  type: ManageTabCampaignActionsEnum.SET_IS_PREEMPTIVE;
  payload: IInitialManageTabCampaignState['isPreemptive'];
}

/** < --------------- MODALS/LOADINGS --------------- > */

interface ISetLoading {
  type: ManageTabCampaignActionsEnum.SET_LOADING;
  payload: ISetLoadingManageCampaignManageTab;
}

interface ISetModal {
  type: ManageTabCampaignActionsEnum.SET_MODAL;
  payload: ISetModalManageCampaignManageTab;
}

/** < --------------- DRAFT --------------- > */

interface ISetDraftManageTab {
  type: ManageTabCampaignActionsEnum.SET_DRAFT_MANAGE_TAB;
}

interface IApplyDraftManageTab {
  type: ManageTabCampaignActionsEnum.APPLY_DRAFT_MANAGE_TAB;
}

/** < --------------- SCHEDULE_MODAL --------------- > */

interface IContainScheduleModal {
  type: ManageTabCampaignActionsEnum.CONTAIN_SCHEDULE_MODAL;
  payload: number | string | undefined; // site id
}

interface IContainScheduleModalTemplate {
  type: ManageTabCampaignActionsEnum.CONTAIN_SCHEDULE_MODAL_TEMPLATE;
}

interface ISetDatesOnScheduleModal {
  type: ManageTabCampaignActionsEnum.SET_DATES_ON_SCHEDULE_MODAL;
  payload?: DateRange;
}

interface ISetTimesOnScheduleModal {
  type: ManageTabCampaignActionsEnum.SET_TIMES_ON_SCHEDULE_MODAL;
  payload: ISetTimeOnScheduleModalPayload;
}

interface ISetDayOfWeekScheduleModal {
  type: ManageTabCampaignActionsEnum.SET_DAYS_OF_WEEK_SCHEDULE_MODAL;
  payload: ISetDayOfWeekScheduleModalPayload;
}

interface IAddAnotherDayPart {
  type: ManageTabCampaignActionsEnum.ADD_ANOTHER_DAY_PART;
}

interface IAddAnotherDayPartBulk {
  type: ManageTabCampaignActionsEnum.ADD_ANOTHER_DAY_PART_BULK;
}

interface IRemoveDayPart {
  type: ManageTabCampaignActionsEnum.REMOVE_DAY_PART;
  payload: number; // index
}

interface ISetDayPartScheduleModal {
  type: ManageTabCampaignActionsEnum.SET_DAY_PART_SCHEDULE_MODAL;
  payload: ISetDayPartScheduleModalPayload;
}

interface ISetUseForAllSites {
  type: ManageTabCampaignActionsEnum.SET_IS_FOR_ALL_SITES;
  payload: boolean; // is all checkbox state
}

interface ISetIsBulkScheduleModal {
  type: ManageTabCampaignActionsEnum.SET_IS_BULK_SCHEDULE_MODAL;
  payload: boolean; // bulk state
}

interface IDoneScheduleModal {
  type: ManageTabCampaignActionsEnum.DONE_SCHEDULE_MODAL;
}

interface IDoneScheduleModalForAll {
  type: ManageTabCampaignActionsEnum.DONE_SCHEDULE_MODAL_FOR_ALL;
}

interface IDoneScheduleModalBulk {
  type: ManageTabCampaignActionsEnum.DONE_SCHEDULE_MODAL_BULK;
}

/** < --------------- ADD_MEDIA_MODAL --------------- > */

interface ISetCurrentTabMediaModal {
  type: ManageTabCampaignActionsEnum.SET_CURRENT_TAB_MEDIA_MODAL;
  payload: IAddMediaModal['currentTab'];
}

interface IContainAddMediaModal {
  type: ManageTabCampaignActionsEnum.CONTAIN_ADD_MEDIA_MODAL;
  payload: string | number; // campaignScheduleId
}

interface IContainAddMediaModalBulk {
  type: ManageTabCampaignActionsEnum.CONTAIN_ADD_MEDIA_MODAL_BULK;
}

interface ISetSelectedMediaStorage {
  type: ManageTabCampaignActionsEnum.SET_SELECTED_MEDIA_STORAGE;
  payload: IMedia[];
}

interface ISetRecentlyMediaStorage {
  type: ManageTabCampaignActionsEnum.SET_RECENTLY_MEDIA_STORAGE;
  payload: IMedia[];
}

interface IAddToSelected {
  type: ManageTabCampaignActionsEnum.ADD_TO_SELECTED;
  payload: IAddToSelectedPayload;
}

// it is desirable to get rid of this in the future, since it is necessary because of the old code
interface IAddToSelectedFromAllTab {
  type: ManageTabCampaignActionsEnum.ADD_TO_SELECTED_FROM_ALL_TAB;
  payload: IObjectWithId<number>[];
}

interface IRemoveFromSelected {
  type: ManageTabCampaignActionsEnum.REMOVE_FROM_SELECTED;
  payload: number; // media ID
}

interface ISetCurrentLookingMedia {
  type: ManageTabCampaignActionsEnum.SET_CURRENT_LOOKING_MEDIA;
  payload: IAddMediaModal['currentLookingMedia'];
}

interface ISetMediaFilters {
  type: ManageTabCampaignActionsEnum.SET_MEDIA_FILTERS;
  payload: IAddMediaModal['mediaFilters'];
}

interface IDoneAddMediaModal {
  type: ManageTabCampaignActionsEnum.DONE_ADD_MEDIA_MODAL;
}

interface IDoneAddMediaModalBulk {
  type: ManageTabCampaignActionsEnum.DONE_ADD_MEDIA_MODAL_BULK;
}

interface IAddFileToLoad {
  type: ManageTabCampaignActionsEnum.ADD_FILE_TO_LOAD;
  payload: ILoadersStorageMedia;
}

interface IChangeFileLoadStatus {
  type: ManageTabCampaignActionsEnum.CHANGE_STATUS_LOADED_FILE;
  payload: IChangeFileLoadStatusPayload;
}

interface IRenameLoadedFile {
  type: ManageTabCampaignActionsEnum.RENAME_LOADED_FILE;
  payload: IRenameLoadedFilePayload;
}

interface IRemoveLoadedFile {
  type: ManageTabCampaignActionsEnum.REMOVE_LOADED_FILE;
  payload: number | string; // file id
}

interface IChangeLoadProgress {
  type: ManageTabCampaignActionsEnum.CHANGE_LOAD_PROGRESS;
  payload: IChangeLoadProgressPayload;
}

interface ISetLoadedFileSrc {
  type: ManageTabCampaignActionsEnum.SET_LOADED_FILE_SRC;
  payload: ISetLoadedFileSrcPayload;
}

interface IChangeLoadedFileId {
  type: ManageTabCampaignActionsEnum.CHANGE_LOADED_FILE_ID;
  payload: IChangeLoadedFileIdPayload;
}

interface ISetCurrentRenamedFile {
  type: ManageTabCampaignActionsEnum.SET_CURRENT_RENAMED_FILE;
  payload: IAddMediaModal['currentRenamedFile'];
}

interface ISetIsBulkAddMediaModal {
  type: ManageTabCampaignActionsEnum.SET_IS_BULK_ADD_MEDIA_MODAL;
  payload: IAddMediaModal['isBulk'];
}

/** < --------------- VIEW_ALL_MEDIA_MODAL --------------- > */

interface ISetMediaViewAllMediaModal {
  type: ManageTabCampaignActionsEnum.SET_MEDIAS;
  payload: IViewAllMediaModal['medias'];
}

/** < --------------- CLEANUP --------------- > */

interface ICleanupManageTab {
  type: ManageTabCampaignActionsEnum.CLEANUP_MANAGE_TAB;
}

interface ICleanupScheduleModal {
  type: ManageTabCampaignActionsEnum.CLEANUP_SCHEDULE_MODAL;
}

interface ICleanupAddMediaModal {
  type: ManageTabCampaignActionsEnum.CLEANUP_ADD_MEDIA_MODAL;
}

interface ICleanupViewAllMediaModal {
  type: ManageTabCampaignActionsEnum.CLEANUP_VIEW_ALL_MEDIA_MODAL;
}

export type TManageCampaignManageTabActions =
  | IContainManageTab
  | IPutToCampaignSchedules
  | ISetSelectedSchedule
  | IRemoveSelectedItems
  | ISetCampaignScheduleDate
  | IContainScheduleModal
  | IContainScheduleModalTemplate
  | ISetSiteFiltersManageCampaign
  | ISetIsPreemptive
  | ISetLoading
  | ISetModal
  | ISetDraftManageTab
  | IApplyDraftManageTab
  | ISetDatesOnScheduleModal
  | ISetTimesOnScheduleModal
  | ISetDayOfWeekScheduleModal
  | IAddAnotherDayPart
  | ISetUseForAllSites
  | IAddAnotherDayPartBulk
  | IRemoveDayPart
  | ISetDayPartScheduleModal
  | ISetIsBulkScheduleModal
  | IDoneScheduleModal
  | IDoneScheduleModalForAll
  | IDoneScheduleModalBulk
  | ISetCurrentTabMediaModal
  | IContainAddMediaModal
  | IContainAddMediaModalBulk
  | ISetSelectedMediaStorage
  | ISetRecentlyMediaStorage
  | IAddToSelected
  | IRemoveFromSelected
  | ISetCurrentLookingMedia
  | ISetMediaFilters
  | IAddToSelectedFromAllTab
  | IAddFileToLoad
  | IChangeFileLoadStatus
  | IRenameLoadedFile
  | IRemoveLoadedFile
  | IChangeLoadProgress
  | ISetLoadedFileSrc
  | IChangeLoadedFileId
  | ISetCurrentRenamedFile
  | ISetIsBulkAddMediaModal
  | IDoneAddMediaModal
  | IDoneAddMediaModalBulk
  | ISetMediaViewAllMediaModal
  | ICleanupManageTab
  | ICleanupScheduleModal
  | ICleanupAddMediaModal
  | ICleanupViewAllMediaModal;
