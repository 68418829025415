import { ColumnDef } from '@tanstack/react-table';
import { arrayHaveSameId, excludeArraysForId } from 'helpers';
import { useActions, useTypedSelector } from 'hooks';
import { useCallback, useMemo } from 'react';
import { IUserShort } from 'types';

import { getColumns } from '../columns';

interface IUsersContentColumnsHookReturn {
  readonly columns: ColumnDef<IUserShort>[];
}

const useUsersContentColumns = (): IUsersContentColumnsHookReturn => {
  const { loaders, users, allUsersTable } = useTypedSelector(
    (state) => state && state.users,
  );

  const { allUserTableSetSelectedUser } = useActions();

  const getAllSelected = useCallback((): boolean => {
    if (
      allUsersTable.totalUsersCount > 0 &&
      allUsersTable.totalUsersCount === allUsersTable.selectedUsers?.length
    ) {
      return true;
    }

    return false;
  }, [allUsersTable.totalUsersCount, allUsersTable.selectedUsers]);

  const getSomeSelected = useCallback((): boolean => {
    if (getAllSelected()) {
      return false;
    }

    if (allUsersTable.selectedUsers?.length) {
      return true;
    }

    return false;
  }, [allUsersTable.selectedUsers, getAllSelected]);

  const selectAll = useCallback((): void => {
    if (!allUsersTable.selectedUsers.length) {
      allUserTableSetSelectedUser([...users]);

      return;
    }

    if (arrayHaveSameId(users, allUsersTable.selectedUsers)) {
      allUserTableSetSelectedUser(
        excludeArraysForId(allUsersTable.selectedUsers, users),
      );

      return;
    }

    allUserTableSetSelectedUser([
      ...allUsersTable.selectedUsers,
      ...excludeArraysForId(users, allUsersTable.selectedUsers),
    ]);
  }, [allUsersTable.selectedUsers]);

  const allSelectedOptions = useMemo(
    () => ({
      getAllSelected,
      getSomeSelected,
      selectAll,
      loading: loaders.getAll,
    }),
    [getAllSelected, getSomeSelected, selectAll, loaders.getAll],
  );

  const columns = useMemo<ColumnDef<IUserShort>[]>(
    () => getColumns(allSelectedOptions),
    [allSelectedOptions],
  );

  return { columns };
};

export default useUsersContentColumns;
