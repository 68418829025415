export enum StaffEnum {
  CENTRE = 'Centre',
  NETWORK = 'Network',
  RESOLUTION = 'Resolution',
  SUBORGANISATION = 'Suborganisation',
}

export enum TabIdsEnum {
  CENTRE = 'Centre-tab',
  NETWORK = 'Network-tab',
  RESOLUTION = 'Resolution-tab',
  SUBORGANISATION = 'Suborganisation-tab',
}
