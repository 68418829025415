import { FormHelperText, MenuItem } from '@mui/material';
import { useFormikContext } from 'formik';
import { useTypedSelector } from 'hooks/useTypedSelector';
import React, { memo } from 'react';

import useManageUserModal from '../../../../ManageUser.hook';
import { TInitialValues } from '../../../../utils';
import {
  SFormControl,
  SInputLabel,
  SSelect,
} from '../../ManageUserContent.styled';

const UserRole = () => {
  const { loaders } = useTypedSelector((state) => state && state.users);
  const { touched, values, errors, isSubmitting, setValues } =
    useFormikContext<TInitialValues>();
  const { handleRoleChange, roleItems } = useManageUserModal();

  return (
    <SFormControl fullWidth>
      <SInputLabel
        id="user-role-label"
        error={touched.role && Boolean(errors.role)}
      >
        Role
      </SInputLabel>
      <SSelect
        labelId="user-role-label"
        id="select-user-role"
        name="role"
        label="Role"
        value={values.role}
        onChange={(event) => handleRoleChange(event, setValues)}
        error={touched.role && Boolean(errors.role)}
        disabled={isSubmitting || loaders.manageUser}
      >
        {roleItems.map((role) => {
          return (
            <MenuItem key={role.id} value={role.name}>
              {role.name}
            </MenuItem>
          );
        })}
      </SSelect>
      {touched.role && Boolean(errors.role) && (
        <FormHelperText error={touched.role && Boolean(errors.role)}>
          {errors.role}
        </FormHelperText>
      )}
    </SFormControl>
  );
};

export default memo(UserRole);
