import { Tooltip } from '@mui/material';
import { ColumnDef } from '@tanstack/react-table';
import classnames from 'classnames';
import { Checkbox } from 'components/common';
import { convertFromMillisecondsToHumanFormat } from 'helpers';
import React from 'react';

import {
  ICampaignSitesDataTable,
  TCampaignDayOsWeeks,
  TCampaignDuration,
  TCampaignTimes,
} from '../../helpers/table';
import { MediaButton, SetDatesButton } from './components';
import { SButton } from './Content.styled';

export const getColumns = (): ColumnDef<ICampaignSitesDataTable>[] => {
  return [
    {
      id: 'Select',
      maxSize: 50,
      header: ({ table }) => (
        <Checkbox
          indeterminate={table.getIsSomePageRowsSelected()}
          checked={table.getIsAllPageRowsSelected()}
          onChange={table.getToggleAllRowsSelectedHandler()}
        />
      ),
      cell: ({ row }) => (
        <Checkbox
          checked={row.getIsSelected()}
          indeterminate={row.getIsSomeSelected()}
          onChange={row.getToggleSelectedHandler()}
        />
      ),
      enableSorting: false,
      enableHiding: false,
    },
    {
      id: 'Site Id',
      accessorKey: 'siteId',
      header: 'Id',
      cell: ({ getValue }) => {
        return <div>{getValue<string>()}</div>;
      },
    },
    {
      id: 'Site Name',
      accessorKey: 'name',
      header: 'Site name',
      cell: ({ getValue }) => {
        const value = getValue<string>();

        return (
          <Tooltip title={value}>
            <div className={classnames('__siteName_cell_wrapper')}>{value}</div>
          </Tooltip>
        );
      },
    },
    {
      id: 'Centre',
      accessorKey: 'centre',
      header: 'Centre',
      cell: ({ getValue }) => {
        return <div>{getValue<string>()}</div>;
      },
    },
    {
      id: 'State',
      accessorKey: 'state',
      header: 'State',
      cell: ({ getValue }) => {
        return <div>{getValue<string>()}</div>;
      },
    },
    {
      id: 'Dimensions',
      accessorKey: 'dimensions',
      header: 'Dimensions',
      cell: ({ getValue }) => {
        return <div>{getValue<string>()}</div>;
      },
    },
    {
      id: 'Duration',
      accessorKey: 'duration',
      header: 'Duration',
      cell: ({ getValue, row }) => {
        const value = getValue<TCampaignDuration>();

        if (Array.isArray(value)) {
          if (Array.isArray(value[0])) {
            return (
              <SButton
                onClick={(event) => {
                  event.stopPropagation();
                  event.preventDefault();
                  row.getToggleExpandedHandler()();
                }}
              >
                Multiple
              </SButton>
            );
          }

          return (
            <SButton>
              {value
                .map((duration) =>
                  convertFromMillisecondsToHumanFormat(duration as number),
                )
                .join('; ')}
            </SButton>
          );
        }

        return <SButton>{convertFromMillisecondsToHumanFormat(value)}</SButton>;
      },
    },
    {
      id: 'Campaign dates',
      accessorKey: 'campaignDates',
      header: 'Campaign Dates',
      cell: ({ row, getValue }) => {
        return <SetDatesButton row={row} getValue={getValue} />;
      },
    },
    {
      id: 'Times',
      accessorKey: 'times',
      header: 'Times',
      cell: ({ getValue, row }) => {
        const value = getValue<TCampaignTimes>();

        if (!value) {
          return (
            <SButton variant="text" disableRipple>
              Set Times
            </SButton>
          );
        }

        if (Array.isArray(value)) {
          return (
            <SButton
              variant="text"
              disableRipple
              onClick={(event) => {
                event.stopPropagation();
                event.preventDefault();
                row.getToggleExpandedHandler()();
              }}
            >
              Multiple
            </SButton>
          );
        }

        return <div>{value}</div>;
      },
    },
    {
      id: 'Day parting',
      accessorKey: 'dayParting',
      header: 'Day parting',
      cell: ({ getValue, row }) => {
        const value = getValue<TCampaignDayOsWeeks>();

        if (!value) {
          return (
            <SButton variant="text" disableRipple>
              Set Day partings
            </SButton>
          );
        }

        if (Array.isArray(value)) {
          return (
            <SButton
              variant="text"
              disableRipple
              onClick={(event) => {
                event.stopPropagation();
                event.preventDefault();
                row.getToggleExpandedHandler()();
              }}
            >
              Multiple
            </SButton>
          );
        }

        return <div>{value}</div>;
      },
    },
    {
      id: 'Formats',
      accessorKey: 'formats',
      header: 'Formats',
      cell: ({ getValue }) => {
        return <div>{getValue<string>()}</div>;
      },
    },
    {
      id: 'Media',
      accessorKey: 'schedulesMedias',
      header: '',
      cell: ({ getValue, row }) => (
        <MediaButton row={row} getValue={getValue} />
      ),

      enableHiding: false,
      enableSorting: false,
    },
  ];
};
