import 'App.scss';

import { Grid } from '@mui/material';
import LogoImage from 'assets/images/login-logo.png';
import React, { ReactNode } from 'react';

interface ILoginLayoutPropsInterface {
  header: string;
  children: ReactNode | ReactNode[];
  logo?: ReactNode;
}

const LoginLayout: React.FC<ILoginLayoutPropsInterface> = ({
  header,
  children,
  logo,
}) => (
  <div className="login-wrapper">
    <Grid container spacing={0}>
      <Grid item lg={6} xs={12} sm={6}>
        <div className="form-section">
          <div className="content-group">
            <div className={logo ? 'custom-logo-container' : 'logo-container'}>
              <img className="logo" src={logo || LogoImage} alt="logo" />
            </div>
            <div>
              <div className="first-header login_header">{header}</div>
            </div>
            <div className="form-content">{children}</div>
          </div>
        </div>
      </Grid>
      <Grid item lg={6} xs={12} sm={6} className="login-image">
        <div className="login-image" />
      </Grid>
    </Grid>
  </div>
);

export default LoginLayout;
