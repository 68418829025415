import { CampaignTypesEnum } from 'constants/enums/campaign';
import { isRole } from 'helpers';
import { useTypedSelector } from 'hooks';
import { UserRolesEnum } from 'types';

interface IInspectorHookReturn {
  isAdvertiser: boolean;
  isMarketing: boolean;
  isContentExist: boolean;
  getIsCheckManageTabFullFill: () => boolean;
  getIsCheckDetailFullFill: () => boolean;
}

const useInspector = (): IInspectorHookReturn => {
  const { campaignName, campaignType, advertiser, category, product } =
    useTypedSelector((state) => state && state.manageCampaign.detail);

  const { role } = useTypedSelector((state) => state && state.auth);
  const { campaignSites } = useTypedSelector(
    (state) => state && state.manageCampaign.manage,
  );

  const getAdditionallyFillState = (): boolean => {
    const isAdvertising =
      campaignType?.name === CampaignTypesEnum.ADVERTISING &&
      Boolean(advertiser && product && category);

    const isMarketing =
      campaignType?.name === CampaignTypesEnum.MARKETING && Boolean(advertiser);
    const isCommunity =
      campaignType?.name === CampaignTypesEnum.COMMUNITY && Boolean(advertiser);

    const hasTrulyExpression = isAdvertising || isMarketing || isCommunity;

    if (hasTrulyExpression) {
      return true;
    }

    return false;
  };

  const isContentExist = Boolean(
    campaignName || campaignType || advertiser || category || product,
  );

  const getIsCheckDetailFullFill = (): boolean => {
    const isCampaignBaseInfo = Boolean(campaignName && campaignType);
    const isCampaignAdditionallyInfo = getAdditionallyFillState();

    if (isCampaignBaseInfo && isCampaignAdditionallyInfo) {
      return true;
    }

    return false;
  };

  const getIsCheckManageTabFullFill = (): boolean => {
    if (!campaignSites.length) {
      return false;
    }

    return campaignSites.every((campaignSite) => {
      if (!campaignSite.campaignSchedules.length) {
        return false;
      }

      return campaignSite.campaignSchedules.every((campaignSchedule) => {
        const isStartDate = !!campaignSchedule.startDate;
        const isEndDate = !!campaignSchedule.endDate;
        const isStartTime = !!campaignSchedule.startTime;
        const isEndTime = !!campaignSchedule.endTime;
        const isDayOfWeek = !!campaignSchedule.dayOfWeek;
        const isDuration = !!campaignSchedule.duration;

        const isDateContained = isStartDate && isEndDate;
        const isTimeContained = isStartTime && isEndTime;

        const isScheduleMediaContained =
          !!campaignSchedule.scheduleMedias.length;

        const isCondition =
          isDateContained &&
          isTimeContained &&
          isDayOfWeek &&
          isDuration &&
          isScheduleMediaContained;

        if (isCondition) {
          return true;
        }

        return false;
      });
    });
  };

  const isAdvertiser = isRole(UserRolesEnum.Advertising, role);
  const isMarketing = isRole(UserRolesEnum.Marketing, role);

  return {
    isAdvertiser,
    isMarketing,
    isContentExist,
    getIsCheckManageTabFullFill,
    getIsCheckDetailFullFill,
  };
};

export default useInspector;
