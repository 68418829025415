import './AddMediaModal.scss';

import { TabContext } from '@mui/lab';
import { Drawer } from '@mui/material';
import classnames from 'classnames';
import { ManageCampaignAddMediaTabsEnum } from 'constants/enums/campaign';
import { useTypedSelector } from 'hooks';
import React from 'react';
import { useDropzone } from 'react-dropzone';

import {
  Dropzone,
  LookingMediaModal,
  MediaModalFooter,
  RenameFileModal,
  Tabs,
  TabsList,
} from './components';
import { useAddMedia, useAddMediaModalDropzone } from './hooks';

const AddMedia: React.FC = () => {
  const { modals, addMediaModal } = useTypedSelector(
    (state) => state && state.manageCampaign.manage,
  );

  const { handleAddMediaClose, getDrawerGridTemplate, getDrawerWidth } =
    useAddMedia();
  const { onDrop } = useAddMediaModalDropzone();

  const isDropzone =
    addMediaModal.currentTab !== ManageCampaignAddMediaTabsEnum.ALL;

  const { getRootProps, isDragActive } = useDropzone({ onDrop, noClick: true });

  return (
    <>
      <Drawer
        anchor="right"
        open={modals.addMedia}
        onClose={handleAddMediaClose}
        PaperProps={{
          ...getRootProps({
            className: classnames('add-media-modal-dropzone', {
              isActive: isDragActive,
            }),
          }),
          sx: {
            width: getDrawerWidth(),
            display: 'grid',
            gridTemplateRows: getDrawerGridTemplate(),
          },
        }}
      >
        <TabContext value={addMediaModal.currentTab}>
          <TabsList />
          {isDropzone && <Dropzone />}
          <Tabs />
          <MediaModalFooter />
        </TabContext>
      </Drawer>
      <LookingMediaModal />
      <RenameFileModal />
    </>
  );
};

export default AddMedia;
