import { FormControl, InputLabel, Select } from '@mui/material';
import { withStyles } from 'tss-react/mui';

const SFormControl = withStyles(FormControl, {
  root: {
    padding: '16px 0',

    '.MuiInputBase-root': {
      padding: 0,
    },
  },
});

const SInputLabel = withStyles(InputLabel, {
  root: {
    top: 'unset',
    left: 'unset',
    padding: 'unset',

    '&.MuiInputLabel-shrink': {
      transform: 'translate(14px, -5px) scale(0.75)',
    },
  },
});

const SSelect = withStyles(Select, {
  root: {
    '.MuiSelect-select': {
      minHeight: 56,
      height: 'auto',
      padding: '16.5px 32px 16.5px 14px',
    },
  },
});

export { SFormControl, SInputLabel, SSelect };
