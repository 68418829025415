import _ from 'lodash';
import { ICampaignSitesNew } from 'types';

import { parseSubRowsIds } from '..';

export const getCampaignSitesIdsExcludedSubRowSites = (
  ids: Array<string | number>,
): Array<string | number> => {
  const siteId = ids.filter((id) => {
    if (typeof id === 'number') {
      return true;
    }

    const isSiteIdAlreadyHasNestedSchedules = ids
      .map(String)
      .some((checkId) => checkId.indexOf(`${id}.`) !== -1);

    if (isSiteIdAlreadyHasNestedSchedules) {
      return false;
    }

    const isThisIdSubRow = id.indexOf('.') !== -1;

    if (isThisIdSubRow) {
      return false;
    }

    return true;
  });

  return siteId.map((id) => {
    if (Number(id)) {
      return Number(id);
    }

    return id;
  });
};

export const findCampaignSite = (
  campaignSites: ICampaignSitesNew[],
  campaignSiteId: number | string | undefined,
): ICampaignSitesNew | undefined => {
  if (!campaignSiteId) {
    return;
  }
  const lookingCampaignSite = campaignSites.find(
    (campaignSite) => campaignSite.id === campaignSiteId,
  );

  return lookingCampaignSite;
};

export const getCountOfCampaignSiteWithOneDayPart = (
  campaignSites: ICampaignSitesNew[],
): { count: number; campaignSites: ICampaignSitesNew[] } => {
  const campaignSitesWithOneDayPart = campaignSites.filter(
    (campaignSite) => campaignSite.siteDayParts.length === 1,
  );

  return {
    count: campaignSitesWithOneDayPart.length,
    campaignSites: campaignSitesWithOneDayPart,
  };
};

export const getSelectedCampaignSites = (
  campaignSites: ICampaignSitesNew[],
  ids: Array<number | string>,
  countOfDayParts?: number,
): ICampaignSitesNew[] => {
  const siteIds = ids.map((id) => parseSubRowsIds(id));

  if (!countOfDayParts) {
    return campaignSites.filter((campaignSite) =>
      siteIds.includes(campaignSite.id),
    );
  }

  return campaignSites.filter((campaignSite) => {
    const hasOneDayPart = campaignSite.siteDayParts.length === countOfDayParts;
    const isInTheArray = siteIds.includes(campaignSite.id);

    if (hasOneDayPart && isInTheArray) {
      return true;
    }

    return false;
  });
};

export const getFullSelectedSitesFromSelectedSchedules = (
  campaignSites: ICampaignSitesNew[],
  selectedIds: Array<string | number>,
): Array<string | number> => {
  const selectedSitesIds = selectedIds.map((id) => parseSubRowsIds(id));
  const selectedSchedulesId = selectedIds.map((id) =>
    parseSubRowsIds(id, 'scheduleId'),
  );

  const selectedSitesIdsUniq = _.uniq(selectedSitesIds);
  const selectedSchedulesIdsUniq = _.uniq(selectedSchedulesId);

  return campaignSites.reduce<Array<string | number>>((acc, campaignSite) => {
    const idsList = [...acc];

    if (selectedSitesIdsUniq.includes(campaignSite.id)) {
      if (campaignSite.campaignSchedules.length === 1) {
        idsList.push(campaignSite.id);

        return idsList;
      }

      const allScheduleIdsFromCurrentSite = campaignSite.campaignSchedules.map(
        (campaignSchedule) => campaignSchedule.id,
      );

      const isAllSelected = allScheduleIdsFromCurrentSite.every((scheduleId) =>
        selectedSchedulesIdsUniq.includes(scheduleId),
      );

      if (isAllSelected) {
        idsList.push(campaignSite.id);
      }
    }

    return idsList;
  }, []);
};

export const getSiteToFullOverwriting = (
  selectedIds: Array<string | number>,
): Array<string | number> => {
  return selectedIds
    .filter((site) => typeof site === 'number' || site.indexOf('.') === -1)
    .map((site) => {
      if (Number(site)) {
        return Number(site);
      }

      return site;
    });
};
