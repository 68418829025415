import './UsersHeader.scss';

import React from 'react';

import { STypography } from './UsersHeader.styled';

const UsersHeaders: React.FC = () => {
  return (
    <div className="header-wrapper">
      <STypography className="page-title">Users</STypography>
    </div>
  );
};

export default UsersHeaders;
