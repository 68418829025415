import './Content.scss';

import { TabContext } from '@mui/lab';
import { useTypedSelector } from 'hooks';
import React, { memo } from 'react';

import { Tabs, TabsList } from './components';

const CampaignFormContent = () => {
  const { activeTab } = useTypedSelector(
    (state) => state && state.manageCampaign.main,
  );

  return (
    <div className="campaign-content-wrapper">
      <TabContext value={activeTab}>
        <TabsList />
        <Tabs />
      </TabContext>
    </div>
  );
};

export default memo(CampaignFormContent);
