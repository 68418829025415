import { TabList } from '@mui/lab';
import { Tab as MuiTab } from '@mui/material';
import React, { memo, SyntheticEvent } from 'react';

import { isRole } from '../../../../../../../../../helpers';
import { useTypedSelector } from '../../../../../../../../../hooks';
import { UserRolesEnum } from '../../../../../../../../../types';
import { StaffEnum, TabIdsEnum } from '../../../../constants/enum';
import { ITabsSwitcherState } from '../../../../type';

interface ITabsContainer {
  currentTab: TabIdsEnum;
  handleChange: (event: SyntheticEvent, newValue: TabIdsEnum) => void;
  tabsSwitcherState: ITabsSwitcherState;
}

const TabsContainer: React.FC<ITabsContainer> = ({
  currentTab,
  handleChange,
  tabsSwitcherState,
}) => {
  const { role } = useTypedSelector((state) => state && state.auth);

  const isNetworkView =
    tabsSwitcherState.isNetwork && isRole(UserRolesEnum.SuperAdmin, role);

  return (
    <TabList variant="fullWidth" value={currentTab} onChange={handleChange}>
      {tabsSwitcherState.isCentre && (
        <MuiTab
          label={StaffEnum.CENTRE}
          value={TabIdsEnum.CENTRE}
          id={TabIdsEnum.CENTRE}
        />
      )}
      {isNetworkView && (
        <MuiTab
          label={StaffEnum.NETWORK}
          value={TabIdsEnum.NETWORK}
          id={TabIdsEnum.NETWORK}
        />
      )}
      {tabsSwitcherState.isResolution && (
        <MuiTab
          label={StaffEnum.RESOLUTION}
          value={TabIdsEnum.RESOLUTION}
          id={TabIdsEnum.RESOLUTION}
        />
      )}
      {tabsSwitcherState.isSuborganisation && (
        <MuiTab
          label={StaffEnum.SUBORGANISATION}
          value={TabIdsEnum.SUBORGANISATION}
          id={TabIdsEnum.SUBORGANISATION}
        />
      )}
    </TabList>
  );
};

export default memo(TabsContainer);
