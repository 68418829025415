import './MonthToggler.scss';

import { ToggleButton, ToggleButtonGroup, Tooltip } from '@mui/material';
import React from 'react';

import { MonthTogglerEnum } from '../../../enum';

interface IMonthToggler {
  alignment: MonthTogglerEnum;
  handleChange: (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
    value: MonthTogglerEnum,
  ) => void;
}

const MonthToggler: React.FC<IMonthToggler> = ({ alignment, handleChange }) => {
  return (
    <Tooltip title="Number of months">
      <ToggleButtonGroup
        value={alignment}
        exclusive
        onChange={handleChange}
        aria-label="text alignment"
        size="small"
        color="primary"
      >
        <ToggleButton value={MonthTogglerEnum.ONE} aria-label="left aligned">
          <div className="toggle-button-wrapper">1</div>
        </ToggleButton>
        <ToggleButton value={MonthTogglerEnum.TWO} aria-label="centered">
          <div className="toggle-button-wrapper">2</div>
        </ToggleButton>
        <ToggleButton value={MonthTogglerEnum.THREE} aria-label="right aligned">
          <div className="toggle-button-wrapper">3</div>
        </ToggleButton>
      </ToggleButtonGroup>
    </Tooltip>
  );
};

export default MonthToggler;
