import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
} from '@mui/material';
import { withStyles } from 'tss-react/mui';

const SAccordion = withStyles(Accordion, (theme) => ({
  root: {
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
  },
}));

const SAccordionSummary = withStyles(AccordionSummary, (theme) => ({
  root: {
    backgroundColor: 'rgba(0, 0, 0, .03)',
    flexDirection: 'row-reverse',

    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
      transform: 'rotate(90deg)',
    },

    '& .MuiAccordionSummary-content': {
      marginLeft: theme.spacing(1),
    },

    '.secondary-accordion-text': {
      display: 'flex',
      gap: 10,
    },
  },
}));

const SAccordionDetails = withStyles(AccordionDetails, (theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
    gap: 15,

    '.time-duration-wrapper': {
      display: 'flex',
      alignItems: 'center',
      gap: 20,
    },

    '.site-frame-info': {
      display: 'flex',
      alignItems: 'center',
      gap: 20,
    },
  },
}));

const SButton = withStyles(Button, (theme) => ({
  root: {
    height: 48,
    justifyContent: 'flex-start',
    borderRadius: '0',
    border: `1px solid ${theme.palette.divider}`,
    paddingLeft: theme.spacing(2),
  },
}));

export { SAccordion, SAccordionDetails, SAccordionSummary, SButton };
