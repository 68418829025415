import { AddCircleOutline as AddCircleOutlineIcon } from '@mui/icons-material';
import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
} from '@mui/material';
import { Button } from 'components/common';
import { useActions, useTypedSelector } from 'hooks';
import React, { useEffect, useState } from 'react';
import { ISelectedTableItem } from 'types';

import { AddSiteTableManageCampaign } from './components';

const AddSiteModal: React.FC = () => {
  const { loaders, modals } = useTypedSelector(
    (state) => state && state.manageCampaign.manage,
  );

  const [selected, setSelected] = useState<ISelectedTableItem[]>([]);

  const {
    setModalsManageTab,
    getSelectedSitesManageCampaign,
    setIsBulkAddMediaModal,
  } = useActions();

  useEffect(() => {
    return () => {
      setIsBulkAddMediaModal(false);
    };
  }, []);

  const handleClose = () => {
    if (!loaders.getSelected) {
      setModalsManageTab({ type: 'addSites', value: false });
    }
  };

  const handleClickAddButton = async () => {
    const ids = selected.map((item) => item.id);
    await getSelectedSitesManageCampaign(ids);
    setSelected([]);
    handleClose();
  };

  const isDisabledAddButton = !selected.length || loaders.getSelected;

  // eslint-disable-next-line @typescript-eslint/naming-convention
  const IconButton = loaders.getSelected ? (
    <CircularProgress size={24} />
  ) : (
    <AddCircleOutlineIcon />
  );

  return (
    <Dialog
      open={modals.addSites}
      onClose={handleClose}
      fullWidth
      maxWidth="lg"
    >
      <DialogContent>
        <AddSiteTableManageCampaign
          selected={selected}
          setSelected={setSelected}
        />
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'center', p: 2 }}>
        <Button
          label="Add"
          icon={IconButton}
          disabled={isDisabledAddButton}
          onClick={handleClickAddButton}
        />
      </DialogActions>
    </Dialog>
  );
};

export default AddSiteModal;
