import { Paper } from '@mui/material';
import { AdjustmentsIcon, ArchiveIcon } from 'assets/icons';
import BulkActionsButton from 'components/BulkActionsButton';
import IconButton from 'components/common/Button/components/IconButton';
import AlertDialog from 'components/common/Modals/DialogModal';
import PreviewMediaModal from 'components/common/Modals/PreviewMediaModal';
import Table from 'components/common/Table';
import TableFilter from 'components/common/Table/TableFilter';
import {
  INITIAL_MEDIA_FILTER_VALUE,
  INITIAL_MEDIA_TABLE_COLUMNS_EXCEPTIONS,
  INITIAL_MEDIA_TABLE_COLUMNS_VALUE,
  INITIAL_SORTING_VALUE,
} from 'constants/constants';
import {
  BulkActionsMassages,
  mediaArchiveDialog,
  MediaFilterText,
  MediaPageText,
  MediaTableText,
  StartDatePickerText,
} from 'constants/text/text';
import { removeTimezone } from 'helpers';
import { createMediaData } from 'helpers/constructors';
import { useActions } from 'hooks/useActions';
import { useTypedSelector } from 'hooks/useTypedSelector';
import MainLayout from 'layouts/Main';
import React, { useEffect, useMemo, useState } from 'react';
import {
  IMediaFilter,
  IPreviewMediaData,
  ISelectedTableItem,
  ISortingValue,
  IStartDateFilter,
  ITableMenuProps,
} from 'types';
import { useDebounce } from 'usehooks-ts';

const MediaPage: React.FC = () => {
  const { totalItems, isLoadingMedia, media, mediaFilters } = useTypedSelector(
    (state) => state && state.medias,
  );

  const { getMedia, getMediaFilters, deleteMedias, deleteMedia } = useActions();
  const [isFilterOpen, setIsFilterOpen] = useState<boolean>(true);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [pageNumber, setPageNumber] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(10);
  const [selected, setSelected] = useState<ISelectedTableItem[]>([]);
  const [filtersValue, setFiltersValue] = useState<IMediaFilter>(
    INITIAL_MEDIA_FILTER_VALUE,
  );
  const [tableColumnsFilter, setTableColumnsFilter] = useState<string[]>(
    INITIAL_MEDIA_TABLE_COLUMNS_VALUE,
  );
  const [sortingValue, setSortingValue] = useState<ISortingValue>(
    INITIAL_SORTING_VALUE,
  );
  const [dateAdded, setDateAdded] = useState<Date | undefined>(undefined);

  const [bulkActionsAnchorEl, setBulkActionsAnchorEl] = useState<
    null | HTMLElement | Element
  >(null);
  const [isArchiveMediasDialogOpen, setIsArchiveMediasDialogOpen] =
    useState<boolean>(false);
  const [selectedMedia, setSelectedMedia] = useState<number | null>(null);
  const [isArchiveMediaDialogOpen, setIsArchiveMediaDialogOpen] =
    useState<boolean>(false);
  const [tableMenuAnchorEl, setTableMenuAnchorEl] = useState<
    null | HTMLElement | Element
  >(null);

  const debouncedSearchValue = useDebounce(searchQuery, 300);

  const [isPreviewMediaModal, setIsPreviewMediaModal] =
    useState<boolean>(false);

  const [previewMediaUrl, setIsPreviewMediaUrl] = useState<IPreviewMediaData>({
    url: null,
    type: null,
  });

  const handleOpenPreviewMediaModal = (url: string, type: string) => {
    setIsPreviewMediaModal(true);
    setIsPreviewMediaUrl({ url, type });
  };

  const handleClosePreviewMediaModal = () => {
    setIsPreviewMediaModal(false);
    setIsPreviewMediaUrl({
      url: null,
      type: null,
    });
  };

  const tableItems = createMediaData(media, handleOpenPreviewMediaModal);

  const datas = useMemo(() => {
    const filters = {
      'Filters.Types': filtersValue?.Type,
      'Filters.AuthorsIds': filtersValue?.['Uploaded by'],
      'Filters.CreatedDate':
        dateAdded && removeTimezone(dateAdded).toISOString(),
      'Filters.AdvertisersIds': filtersValue?.Advertiser,
      'Filters.ProductsIds': filtersValue?.Product,
      'Filters.CategoriesIds': filtersValue?.Category,
      'Filters.ResolutionsIds': filtersValue?.Dimensions,
    };

    const sorting = {
      SortDirection: sortingValue?.SortDirection?.toUpperCase(),
      SortColumn: sortingValue?.SortColumn,
    };

    return {
      pageNumber,
      pageSize,
      SearchQuery: debouncedSearchValue,
      ...sorting,
      ...filters,
    };
  }, [
    filtersValue,
    dateAdded,
    sortingValue,
    debouncedSearchValue,
    pageNumber,
    pageSize,
  ]);

  useEffect(() => {
    getMedia(datas);
  }, [datas]);

  useEffect(() => {
    getMediaFilters();
  }, []);

  const resetPagination = () => {
    setPageNumber(0);
  };

  const handleArchiveMedias = () => {
    setIsArchiveMediasDialogOpen(true);
    setBulkActionsAnchorEl(null);
  };

  const handleCloseArchiveMediasDialog = () => {
    setIsArchiveMediasDialogOpen(false);
  };

  const handleOpenBulkActionsMenu = (
    anchorEl: HTMLElement | Element,
    id?: number,
  ) => {
    setBulkActionsAnchorEl(anchorEl);
    if (id) {
      setSelectedMedia(id);
    }
  };

  const handleCloseBulkActionsMenu = () => {
    setBulkActionsAnchorEl(null);
  };

  const handleArchiveSeveralMedias = (ids?: number[]) => {
    if (tableItems.length === ids?.length && pageNumber !== 0) {
      setPageNumber(pageNumber - 1);
    }
    if (ids?.length) {
      deleteMedias(ids);
    }
  };

  const handleArchiveMedia = () => {
    setIsArchiveMediaDialogOpen(true);
    setTableMenuAnchorEl(null);
  };

  const handleCloseArchiveMediaDialog = () => {
    setIsArchiveMediaDialogOpen(false);
    setSelectedMedia(null);
  };

  const handleOpenTableMenu = (
    anchorEl: HTMLElement | Element,
    id?: number,
  ) => {
    setTableMenuAnchorEl(anchorEl);
    if (id) {
      setSelectedMedia(id);
    }
  };

  const handleCloseTableMenu = () => {
    setTableMenuAnchorEl(null);
  };

  const confirmArchiveMedia = () => {
    setTableMenuAnchorEl(null);

    if (tableItems.length === 1 && pageNumber !== 0) {
      setPageNumber(pageNumber - 1);
    }
    if (selectedMedia) {
      deleteMedia(selectedMedia);
    }
    setSelectedMedia(null);
  };

  const handleChangeDateAdded = (date: Date | undefined) => {
    setDateAdded(date);
  };

  const handleClearStartDate = () => {
    setDateAdded(undefined);
  };

  const startDateFilter: IStartDateFilter = {
    StartDatePickerText,
    dateAdded,
    datePickerProps: {
      mode: 'single',
      relatedComponent: 'input',
      onSelect: handleChangeDateAdded,
      disabled: { after: new Date() },
      toDate: new Date(),
    },
    handleChangeDateAdded,
    handleClearStartDate,
  };

  const bulkActionsListItems = [
    {
      id: 1,
      label: MediaPageText.archive,
      icon: <ArchiveIcon />,
      onClick: handleArchiveMedias,
      errorToasterLabel: BulkActionsMassages.mediaLibraryError,
    },
  ];

  const menuListItems = [
    {
      id: 1,
      label: MediaPageText.archive,
      icon: <ArchiveIcon />,
      onClick: handleArchiveMedia,
    },
  ];

  const tableMenuItem: ITableMenuProps = {
    menuListItems,
    tableMenuAnchorEl,
    handleOpenTableMenu,
    handleCloseTableMenu,
  };

  const bulkActionsMenuItems: ITableMenuProps = {
    menuListItems: bulkActionsListItems,
    tableMenuAnchorEl: bulkActionsAnchorEl,
    handleOpenTableMenu: handleOpenBulkActionsMenu,
    handleCloseTableMenu: handleCloseBulkActionsMenu,
  };

  const selectedItems = tableItems.filter((item) =>
    selected.map((el) => el.id).includes(item.id.value),
  );

  return (
    <MainLayout>
      <div className="container">
        <div className="campaigns-header">
          <div className="first-header">{MediaPageText.header}</div>
          <div className="controllers">
            <BulkActionsButton
              selectedItems={selectedItems}
              bulkActionsMenuItems={bulkActionsMenuItems}
            />
          </div>
        </div>
        <div className="campaign-body">
          <Paper sx={{ height: 960 }}>
            <div className="table-container">
              <div
                className={`filter-campaign-container ${
                  isFilterOpen ? 'opened-campaign-filter-container' : ''
                }`}
              >
                {isFilterOpen ? (
                  <TableFilter
                    setIsFilterOpen={setIsFilterOpen}
                    setSearchQuery={setSearchQuery}
                    searchQuery={searchQuery}
                    filtersList={mediaFilters}
                    filtersValue={filtersValue}
                    setFiltersValue={setFiltersValue}
                    tableColumnsFilter={tableColumnsFilter}
                    setTableColumnsFilter={setTableColumnsFilter}
                    resetPagination={resetPagination}
                    initialFilterValue={INITIAL_MEDIA_FILTER_VALUE}
                    initialTableColumnsValue={INITIAL_MEDIA_TABLE_COLUMNS_VALUE}
                    exceptions={INITIAL_MEDIA_TABLE_COLUMNS_EXCEPTIONS}
                    filtersText={MediaFilterText}
                    startDateFilter={startDateFilter}
                    setPageNumber={setPageNumber}
                  />
                ) : (
                  <div style={{ paddingTop: '10px' }}>
                    <IconButton
                      onClick={() => setIsFilterOpen(true)}
                      icon={<AdjustmentsIcon />}
                      className="icon-l"
                    />
                  </div>
                )}
              </div>
              <Table
                items={tableItems}
                total={totalItems}
                pageNumber={pageNumber}
                pageSize={pageSize}
                setPageSize={setPageSize}
                setPageNumber={setPageNumber}
                tableColumnsFilter={tableColumnsFilter}
                sortingValue={sortingValue}
                setSortingValue={setSortingValue}
                isLoadingItems={isLoadingMedia}
                tableText={MediaTableText}
                selected={selected}
                setSelected={setSelected}
                tableMenuItem={tableMenuItem}
              />
            </div>
          </Paper>
        </div>
        <AlertDialog
          message={mediaArchiveDialog.header}
          isOpen={isArchiveMediaDialogOpen}
          handleClose={handleCloseArchiveMediaDialog}
          handleConfirmAction={confirmArchiveMedia}
        />
        <AlertDialog
          message={mediaArchiveDialog.header}
          isOpen={isArchiveMediasDialogOpen}
          handleClose={handleCloseArchiveMediasDialog}
          handleConfirmAction={handleArchiveSeveralMedias}
          selectedItems={selectedItems}
        />
        <PreviewMediaModal
          open={isPreviewMediaModal}
          handleClose={handleClosePreviewMediaModal}
          previewMediaUrl={previewMediaUrl}
        />
      </div>
    </MainLayout>
  );
};

export default MediaPage;
