import React, { ReactElement } from 'react';

import { TActionsButtons, TDefaultActionsTypes } from '../../../types';
import { OrderingPopover } from '../components';
import { ITableActions } from '../TableActions';
import { IOrderingHookReturn } from './ordering.hook';

interface IRenderActionsHookProps<TData> {
  readonly table: ITableActions<TData>['table'];
  readonly isOrderingPopoverOpen: IOrderingHookReturn['isOrderingPopoverOpen'];
  readonly orderingPopoverClose: IOrderingHookReturn['orderingPopoverClose'];
}
interface IRenderActionsHookReturn {
  readonly renderSubComponent: (
    type: TDefaultActionsTypes,
    anchorEl: TActionsButtons,
  ) => ReactElement | null;
}

const useRenderActions = <TData,>({
  table,
  isOrderingPopoverOpen,
  orderingPopoverClose,
}: IRenderActionsHookProps<TData>): IRenderActionsHookReturn => {
  const renderSubComponent = (
    type: TDefaultActionsTypes,
    anchorEl: TActionsButtons,
  ) => {
    switch (type) {
      case 'ordering':
        if (!anchorEl) {
          return null;
        }

        return (
          <OrderingPopover
            isOpen={isOrderingPopoverOpen}
            anchorEl={anchorEl}
            table={table}
            handleClose={orderingPopoverClose}
          />
        );
      default:
        return null;
    }
  };

  return { renderSubComponent };
};

export default useRenderActions;
