import {
  IObjectIdWithName,
  IUser,
  IUserShort,
  SortDirectionEnum,
  TAllUsersColumns,
  TPagination,
} from '../../../types';
import { IGetAllUsersParams } from '../../Actions/users/types';
import {
  IGetAllUsersPayload,
  ISetErrorsPayload,
  ISetLoadingPayload,
  IToggleModalsPayload,
} from './types';

export enum UserActionsEnum {
  /* <------- QUERIES -------> */
  GET_ALL_USERS = 'GET_ALL_USERS',
  SET_ALL_USERS_PARAMS = 'SET_ALL_USERS_PARAMS',
  GET_ONE_USER = 'GET_ONE_USER',

  /* <------- LOADERS -------> */
  SET_LOADING = 'SET_LOADING_USERS',

  /* <------- ERRORS -------> */
  SET_ERROR = 'SET_ERROR_USERS',

  /* <------- MODALS -------> */
  TOGGLE_MODAL = 'TOGGLE_MODAL_USERS',

  /* <-------------- ALL USERS TABLE --------------> */
  ALL_USER_TABLE_GLOBAL_SEARCH = 'ALL_USER_TABLE_GLOBAL_SEARCH',
  ALL_USER_TABLE_SET_SELECTED_USERS = 'ALL_USER_TABLE_SET_SELECTED_USERS',
  ALL_USER_TABLE_CLEANUP_SELECTED_USERS = 'ALL_USER_TABLE_CLEANUP_SELECTED_USERS',
  ALL_USER_TABLE_PAGINATION_PAGE_SIZE = 'ALL_USER_TABLE_PAGINATION_PAGE_SIZE',
  ALL_USER_TABLE_PAGINATION_CURRENT_PAGE = 'ALL_USER_TABLE_PAGINATION_CURRENT_PAGE',
  ALL_USER_TABLE_SORT_DIRECTION = 'ALL_USER_TABLE_SORT_DIRECTION',
  ALL_USER_TABLE_SORT_COLUMN = 'ALL_USER_TABLE_SORT_COLUMN',
  ALL_USER_TABLE_CURRENT_CLICKED_ACTIONS_ROW_ID = 'ALL_USER_TABLE_CURRENT_CLICKED_ACTIONS_ROW_ID',

  SET_CURRENT_CLICKED_USER = 'SET_CURRENT_CLICKED_USER',
  REMOVE_CURRENT_CLICKED_USER = 'REMOVE_CURRENT_CLICKED_USER',

  /* <------- MANAGE_MODAL -------> */

  SET_SUBORGANISATIONS = 'SET_SUBORGANISATIONS',
  SET_CENTRES = 'SET_CENTRES',
  SET_NETWORKS = 'SET_NETWORKS',
  SET_ROLES = 'SET_ROLES',

  /* <------- BULK_MODAL -------> */

  SET_BULK_MODAL_SELECTED_USERS = 'SET_BULK_MODAL_SELECTED_USERS',
  REMOVE_BULK_MODAL_USER = 'REMOVE_BULK_MODAL_USER',
}

interface IGetAllUsers {
  type: UserActionsEnum.GET_ALL_USERS;
  payload: IGetAllUsersPayload;
}

interface ISetAllUsersParams {
  type: UserActionsEnum.SET_ALL_USERS_PARAMS;
  payload: IGetAllUsersParams;
}

interface IGetOneUser {
  type: UserActionsEnum.GET_ONE_USER;
  payload: IUser;
}

interface ISetLoading {
  type: UserActionsEnum.SET_LOADING;
  payload: ISetLoadingPayload;
}

interface ISetError {
  type: UserActionsEnum.SET_ERROR;
  payload: ISetErrorsPayload;
}

interface IToggleModals {
  type: UserActionsEnum.TOGGLE_MODAL;
  payload: IToggleModalsPayload;
}

interface IAllUserTableGlobalSearch {
  type: UserActionsEnum.ALL_USER_TABLE_GLOBAL_SEARCH;
  payload: string;
}

interface IAllUserTableSetSelectedUser {
  type: UserActionsEnum.ALL_USER_TABLE_SET_SELECTED_USERS;
  payload: IUserShort[];
}

interface IAllUserTableCleanupSelectedUser {
  type: UserActionsEnum.ALL_USER_TABLE_CLEANUP_SELECTED_USERS;
}

interface IAllUserTablePaginationPageSize {
  type: UserActionsEnum.ALL_USER_TABLE_PAGINATION_PAGE_SIZE;
  payload: TPagination['pageSize'];
}

interface IAllUserTablePaginationCurrentPage {
  type: UserActionsEnum.ALL_USER_TABLE_PAGINATION_CURRENT_PAGE;
  payload: TPagination['currentPage'];
}

interface IAllUserTableSortDirection {
  type: UserActionsEnum.ALL_USER_TABLE_SORT_DIRECTION;
  payload?: SortDirectionEnum | undefined;
}

interface IAllUserTableSortColumn {
  type: UserActionsEnum.ALL_USER_TABLE_SORT_COLUMN;
  payload?: TAllUsersColumns;
}

interface IAllUserTableSetCurrentClickedUser {
  type: UserActionsEnum.SET_CURRENT_CLICKED_USER;
  payload: IUserShort;
}

interface IAllUserTableRemoveCurrentClickedUser {
  type: UserActionsEnum.REMOVE_CURRENT_CLICKED_USER;
}

interface IAllUserTableCurrentClickedActionsRowId {
  type: UserActionsEnum.ALL_USER_TABLE_CURRENT_CLICKED_ACTIONS_ROW_ID;
  payload: number | null;
}

interface ISetSubOrganisations {
  type: UserActionsEnum.SET_SUBORGANISATIONS;
  payload: IObjectIdWithName[];
}

interface ISetCentres {
  type: UserActionsEnum.SET_CENTRES;
  payload: IObjectIdWithName[];
}

interface ISetNetworks {
  type: UserActionsEnum.SET_NETWORKS;
  payload: IObjectIdWithName[];
}

interface ISetRoles {
  type: UserActionsEnum.SET_ROLES;
  payload: IObjectIdWithName[];
}

interface ISetBulkModalSelectedUsers {
  type: UserActionsEnum.SET_BULK_MODAL_SELECTED_USERS;
  payload: IUserShort[];
}

interface IRemoveBulModalUser {
  type: UserActionsEnum.REMOVE_BULK_MODAL_USER;
  payload: IUserShort['id'];
}

export type TUsersAction =
  | IGetAllUsers
  | ISetAllUsersParams
  | IGetOneUser
  | ISetLoading
  | ISetError
  | IToggleModals
  | IAllUserTableGlobalSearch
  | IAllUserTableSetSelectedUser
  | IAllUserTableCleanupSelectedUser
  | IAllUserTablePaginationPageSize
  | IAllUserTablePaginationCurrentPage
  | IAllUserTableSortDirection
  | IAllUserTableSortColumn
  | IAllUserTableSetCurrentClickedUser
  | IAllUserTableRemoveCurrentClickedUser
  | IAllUserTableCurrentClickedActionsRowId
  | ISetSubOrganisations
  | ISetCentres
  | ISetNetworks
  | ISetRoles
  | ISetBulkModalSelectedUsers
  | IRemoveBulModalUser;
