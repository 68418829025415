import './NameAndAddress.scss';

import { TextField } from '@mui/material';
import { useFormikContext } from 'formik';
import React from 'react';

import { texts } from '../../../../../../constants/texts';
import { IInitialValues } from '../../../../types';

const NameAndAddress: React.FC = () => {
  const { values, errors, isSubmitting, handleChange } =
    useFormikContext<IInitialValues>();

  return (
    <div className="site-name_site-address-content">
      <TextField
        name="name"
        label={texts.pages.manage.content.main.inputs.name}
        id="name"
        value={values.name}
        onChange={handleChange}
        error={Boolean(errors.name)}
        helperText={errors.name}
        disabled={isSubmitting}
        fullWidth
      />
      <TextField
        name="address"
        label={texts.pages.manage.content.main.inputs.address}
        id="address"
        value={values.address}
        onChange={handleChange}
        error={Boolean(errors.address)}
        helperText={errors.address}
        disabled={isSubmitting}
        fullWidth
      />
    </div>
  );
};

export default NameAndAddress;
