import { CheckCircleOutlineOutlined as CheckCircleOutlineOutlinedIcon } from '@mui/icons-material';
import BackdropWithLoader from 'components/common/Backdrop';
import Button from 'components/common/Button';
import Input from 'components/common/Input';
import { AlertStatusesEnum } from 'constants/constants';
import { ForgotPasswordPageText } from 'constants/text/text';
import { urls } from 'constants/urls/url';
import { FORGOT_PASSWORD_SCHEMA } from 'helpers/validationSchemas';
import { useActions } from 'hooks/useActions';
import { useTypedSelector } from 'hooks/useTypedSelector';
import LoginLayout from 'layouts/login';
import { useSnackbar } from 'notistack';
import React, { FormEvent, useEffect, useState } from 'react';
import { Link, Navigate } from 'react-router-dom';

const ForgotPasswordPage: React.FC = () => {
  const { sendForgotPasswordEmail, cleanUpAuthStore } = useActions();
  const { enqueueSnackbar } = useSnackbar();
  const [user, setUser] = useState({
    login: '',
  });
  const [errors, setErrors] = useState('');
  const { hasLoggedIn, isForgotLoading, isForgotPasswordStatus, forgotError } =
    useTypedSelector((state) => state && state.auth);

  useEffect(() => {
    if (forgotError) {
      enqueueSnackbar(String(forgotError), {
        variant: AlertStatusesEnum.ERROR,
      });
    }

    return () => {
      cleanUpAuthStore();
    };
  }, [forgotError]);

  const inputHandler = ({
    target: { value, name },
  }: React.ChangeEvent<HTMLInputElement>) => {
    setErrors('');
    setUser({ ...user, [name]: value });
  };

  const handleReset = async (event: FormEvent) => {
    event.preventDefault();
    try {
      await FORGOT_PASSWORD_SCHEMA.validate(user);
      setUser({ ...user });
      const callBackUrl = `${window.location.origin}${urls.reset}`;
      const newUser = {
        login: user.login,
        callbackUrl: callBackUrl,
      };
      sendForgotPasswordEmail(newUser);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      setErrors(err.errors);
    }
  };

  if (hasLoggedIn) {
    return <Navigate to={urls.campaign} />;
  }

  return (
    <LoginLayout header={ForgotPasswordPageText.header}>
      <>
        {!isForgotPasswordStatus ? (
          <div className="form-content">
            <form onSubmit={handleReset}>
              <div className="input-container">
                <span className="input-label">
                  {ForgotPasswordPageText.emailLabel}
                </span>
                <Input
                  name="login"
                  className="input-field"
                  onChange={inputHandler}
                  error={Boolean(errors)}
                  helperText={errors}
                />
              </div>
              <div className="button-group button-group_top-margin">
                <Button
                  onClick={handleReset}
                  label={ForgotPasswordPageText.recovert}
                  icon={<CheckCircleOutlineOutlinedIcon />}
                  className="without-shadow"
                />
              </div>
            </form>
          </div>
        ) : (
          <div className="input-container">
            <div className="reset-text">{ForgotPasswordPageText.sentLabel}</div>
          </div>
        )}
        <div className="remember-group">
          <div>
            <Link to={urls.login} className="link">
              {ForgotPasswordPageText.back}
            </Link>
          </div>
        </div>
      </>
      <BackdropWithLoader open={isForgotLoading} />
    </LoginLayout>
  );
};

export default ForgotPasswordPage;
