import { Box } from '@mui/material';
import { withStyles } from 'tss-react/mui';

const SBox = withStyles(Box, {
  root: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    padding: '2em',
    gap: 8,
  },
});

export { SBox };
