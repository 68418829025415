import { AxiosPromise } from 'axios';

import { AxiosMethodEnum } from '../../../constants/text/text';
import { urls } from '../../../constants/urls/url';
import { apiCall } from '../../../helpers/api/apiCall';

const getMethodStates = <T extends object>(url: string): AxiosPromise<T> => {
  return apiCall<T>(AxiosMethodEnum.get, url);
};

export const getStatesApi = <T extends object>(): AxiosPromise<T> =>
  getMethodStates<T>(urls.states);
