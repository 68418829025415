import { Checkbox, Chip } from '@mui/material';
import { ColumnDef } from '@tanstack/react-table';
import classnames from 'classnames';
import React from 'react';

import { IUserShort } from '../../../../types';
import { UserActionsButton } from './components';

interface IGetColumnsProps {
  getAllSelected: () => boolean;
  getSomeSelected: () => boolean;
  selectAll: () => void;
  loading?: boolean;
}

export const getColumns = ({
  getAllSelected,
  getSomeSelected,
  selectAll,
  loading,
}: IGetColumnsProps): ColumnDef<IUserShort>[] => {
  return [
    {
      id: 'Select',
      maxSize: 50,
      header: () => (
        <Checkbox
          {...{
            checked: getAllSelected(),
            indeterminate: getSomeSelected(),
            onChange: selectAll,
            disabled: loading,
          }}
        />
      ),
      cell: ({ row }) => (
        <div
          className={classnames(
            '__cell_block',
            '__cell_block_body',
            '__center',
          )}
        >
          <Checkbox
            {...{
              checked: row.getIsSelected(),
              indeterminate: row.getIsSomeSelected(),
              onChange: row.getToggleSelectedHandler(),
            }}
          />
        </div>
      ),
      enableSorting: false,
      enableHiding: false,
    },
    {
      id: 'First Name',
      accessorKey: 'firstName',
      minSize: 150,
      header: () => (
        <div className={classnames('__cell_block', '__cell_block_header')}>
          First name
        </div>
      ),
      cell: ({ getValue }) => {
        return (
          <div className={classnames('__cell_block', '__cell_block_body')}>
            {getValue<string>()}
          </div>
        );
      },

      enableHiding: false,
      enableSorting: true,
    },
    {
      id: 'Last Name',
      accessorKey: 'lastName',
      minSize: 150,
      header: () => (
        <div className={classnames('__cell_block', '__cell_block_header')}>
          Last name
        </div>
      ),
      cell: ({ getValue }) => {
        return (
          <div className={classnames('__cell_block', '__cell_block_body')}>
            {getValue<string>()}
          </div>
        );
      },
      enableSorting: true,
      enableHiding: false,
    },
    {
      id: 'User Name',
      accessorKey: 'userName',
      minSize: 150,
      header: () => (
        <div className={classnames('__cell_block', '__cell_block_header')}>
          User Name
        </div>
      ),
      cell: ({ getValue }) => {
        return (
          <div className={classnames('__cell_block', '__cell_block_body')}>
            {getValue<string>()}
          </div>
        );
      },
      enableSorting: true,
    },
    {
      id: 'Email',
      accessorKey: 'email',
      minSize: 150,
      header: () => (
        <div className={classnames('__cell_block', '__cell_block_header')}>
          Email
        </div>
      ),
      cell: ({ getValue }) => {
        return (
          <div className={classnames('__cell_block', '__cell_block_body')}>
            {getValue<string>()}
          </div>
        );
      },
      enableSorting: true,
    },
    {
      id: 'Role',
      accessorKey: 'role',
      minSize: 150,
      header: () => (
        <div
          className={classnames(
            '__cell_block',
            '__cell_block_header',
            '__center',
          )}
        >
          Role
        </div>
      ),
      cell: ({ getValue }) => {
        return (
          <div
            className={classnames(
              '__cell_block',
              '__cell_block_body',
              '__center_horizontal',
              '__table_cell_ps20',
            )}
          >
            <Chip
              label={getValue<string>()}
              sx={{ width: '100%' }}
              className="__max_width-160"
            />
          </div>
        );
      },
      enableSorting: true,
    },
    {
      id: 'Actions',
      maxSize: 80,
      cell: ({ row }) => {
        return (
          <div
            className={classnames(
              '__cell_block',
              '__cell_block_body',
              '__right',
              '__table_cell_ps20',
            )}
          >
            <UserActionsButton row={row} />
          </div>
        );
      },
      enableSorting: false,
      enableHiding: false,
    },
  ];
};
