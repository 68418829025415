import { AlertStatusesEnum } from 'constants/constants';
import { urls } from 'constants/urls/url';
import { NavigateFunction } from 'react-router-dom';
import { Dispatch } from 'redux';
import {
  ICampaign,
  IDeclineDialogValues,
  IDefaultDtoExtended,
  INewCampaign,
  TShowMessage,
} from 'types';

import { CampaignActionTypesEnum, TCampaignAction } from '../../../ActionTypes';
import {
  allCampaigns,
  allCampaignTypes,
  archivedCampaign,
  archivedCampaigns,
  campaignStopped,
  deletedCampaign,
  deletedCampaigns,
  editCampaign,
  editDraftCampaign,
  fetchCampaignFilters,
  newCampaign,
  newDraftCampaign,
  oneCampaign,
  putCampaignApproved,
  putCampaignDeclined,
  searchedCampaignsByName,
} from '../campaignsApi';

export const getCampaigns =
  (params: object) =>
  async (dispatch: Dispatch<TCampaignAction>): Promise<void> => {
    try {
      dispatch({ type: CampaignActionTypesEnum.GET_CAMPAIGNS_LOADING });
      const { data } = await allCampaigns<IDefaultDtoExtended<ICampaign[]>>(
        params,
      );

      const payload = {
        campaigns: data.data,
        pageNumber: data.pageNumber,
        pageSize: data.pageSize,
        totalItems: data.totalCount,
      };

      dispatch({
        type: CampaignActionTypesEnum.GET_CAMPAIGNS_SUCCESS,
        payload,
      });
    } catch (error: any) {
      dispatch({
        type: CampaignActionTypesEnum.GET_CAMPAIGNS_ERROR,
        payload: error as any,
      });
    }
  };

export const getCampaignById =
  (id: number) =>
  async (dispatch: Dispatch<TCampaignAction>): Promise<void> => {
    try {
      dispatch({ type: CampaignActionTypesEnum.GET_CAMPAIGN_BY_ID_LOADING });
      const { data } = await oneCampaign(id);
      dispatch({
        type: CampaignActionTypesEnum.GET_CAMPAIGN_BY_ID_SUCCESS,
        payload: data,
      });
    } catch (error: any) {
      dispatch({
        type: CampaignActionTypesEnum.GET_CAMPAIGN_BY_ID_ERROR,
        payload: error as any,
      });
    }
  };

export const getSearchedByNameCampaigns =
  (name?: string) =>
  async (dispatch: Dispatch<TCampaignAction>): Promise<void> => {
    try {
      dispatch({
        type: CampaignActionTypesEnum.GET_SEARCHED_CAMPAIGNS_LOADING,
      });
      const { data } = await searchedCampaignsByName(name);
      dispatch({
        type: CampaignActionTypesEnum.GET_SEARCHED_CAMPAIGNS_SUCCESS,
        payload: data,
      });
    } catch (error: any) {
      dispatch({
        type: CampaignActionTypesEnum.GET_SEARCHED_CAMPAIGNS_ERROR,
        payload: error as any,
      });
    }
  };

export const getCampaignTypes =
  () =>
  async (dispatch: Dispatch<TCampaignAction>): Promise<void> => {
    try {
      dispatch({ type: CampaignActionTypesEnum.GET_CAMPAIGN_TYPES_LOADING });
      const { data } = await allCampaignTypes();
      dispatch({
        type: CampaignActionTypesEnum.GET_CAMPAIGN_TYPES_SUCCESS,
        payload: data,
      });
    } catch (error: any) {
      dispatch({
        type: CampaignActionTypesEnum.GET_CAMPAIGN_TYPES_ERROR,
        payload: error as any,
      });
    }
  };

export const archiveCampaign =
  (id: number) =>
  async (dispatch: Dispatch<TCampaignAction>): Promise<void> => {
    try {
      await archivedCampaign(id);
      dispatch({ type: CampaignActionTypesEnum.DELETE_CAMPAIGN, payload: id });
      dispatch({ type: CampaignActionTypesEnum.CAMPAIGN_LENGTH, payload: 1 });
    } catch (error: any) {
      dispatch({
        type: CampaignActionTypesEnum.DELETE_CAMPAIGN_ERROR,
        payload: error as any,
      });
    }
  };

export const archiveCampaigns =
  (ids: number[]) =>
  async (dispatch: Dispatch<TCampaignAction>): Promise<void> => {
    try {
      const params = { ids };
      await archivedCampaigns(params);
      dispatch({
        type: CampaignActionTypesEnum.DELETE_CAMPAIGNS,
        payload: ids,
      });
      dispatch({
        type: CampaignActionTypesEnum.CAMPAIGN_LENGTH,
        payload: ids.length,
      });
    } catch (error: any) {
      const message = error?.response?.data?.UserMessage;
      console.error(message);
      throw new Error(message);
    }
  };

export const deleteCampaign =
  (id: number) =>
  async (dispatch: Dispatch<TCampaignAction>): Promise<void> => {
    try {
      await deletedCampaign(id);
      dispatch({ type: CampaignActionTypesEnum.DELETE_CAMPAIGN, payload: id });
      dispatch({ type: CampaignActionTypesEnum.CAMPAIGN_LENGTH, payload: 1 });
    } catch (error: any) {
      dispatch({
        type: CampaignActionTypesEnum.DELETE_CAMPAIGN_ERROR,
        payload: error as any,
      });
    }
  };

export const deleteCampaigns =
  (ids: number[]) =>
  async (dispatch: Dispatch<TCampaignAction>): Promise<void> => {
    try {
      const params = { ids };
      await deletedCampaigns(params);
      dispatch({
        type: CampaignActionTypesEnum.DELETE_CAMPAIGNS,
        payload: ids,
      });
      dispatch({
        type: CampaignActionTypesEnum.CAMPAIGN_LENGTH,
        payload: ids.length,
      });
    } catch (error: any) {
      dispatch({
        type: CampaignActionTypesEnum.DELETE_CAMPAIGNS_ERROR,
        payload: error as any,
      });
    }
  };

export const postCampaigns =
  (campaign: INewCampaign, navigate: NavigateFunction) =>
  async (dispatch: Dispatch<TCampaignAction>): Promise<void> => {
    try {
      dispatch({ type: CampaignActionTypesEnum.POST_CAMPAIGNS_LOADING });
      await newCampaign(campaign);
      dispatch({ type: CampaignActionTypesEnum.POST_CAMPAIGNS_SUCCESS });
      navigate(urls.campaign);
    } catch (error: any) {
      dispatch({
        type: CampaignActionTypesEnum.POST_CAMPAIGNS_ERROR,
        payload: error as any,
      });
    }
  };

export const postDraftCampaigns =
  (campaign: INewCampaign, navigate: NavigateFunction) =>
  async (dispatch: Dispatch<TCampaignAction>): Promise<void> => {
    try {
      dispatch({ type: CampaignActionTypesEnum.POST_DRAFT_CAMPAIGN_LOADING });
      await newDraftCampaign(campaign);
      dispatch({ type: CampaignActionTypesEnum.POST_DRAFT_CAMPAIGN_SUCCESS });
      navigate(urls.campaign);
    } catch (error: any) {
      dispatch({
        type: CampaignActionTypesEnum.POST_DRAFT_CAMPAIGN_ERROR,
        payload: error?.response?.data?.UserMessage || error,
      });
    }
  };

export const getCampaignsFilters =
  () =>
  async (dispatch: Dispatch<TCampaignAction>): Promise<void> => {
    try {
      dispatch({ type: CampaignActionTypesEnum.GET_CAMPAIGNS_FILTERS_LOADING });
      const { data } = await fetchCampaignFilters();
      dispatch({
        type: CampaignActionTypesEnum.GET_CAMPAIGNS_FILTERS_SUCCESS,
        payload: data,
      });
    } catch (error: any) {
      dispatch({
        type: CampaignActionTypesEnum.GET_CAMPAIGNS_FILTERS_ERROR,
        payload: error as any,
      });
    }
  };

export const postEditCampaign =
  (
    id: number,
    newCampaignData: object,
    showMessage: TShowMessage,
    navigate: NavigateFunction,
  ) =>
  async (dispatch: Dispatch<TCampaignAction>): Promise<void> => {
    try {
      dispatch({ type: CampaignActionTypesEnum.POST_EDIT_CAMPAIGN_LOADING });
      await editCampaign(id, newCampaignData);
      dispatch({ type: CampaignActionTypesEnum.POST_EDIT_CAMPAIGN_SUCCESS });
      navigate(urls.campaign);
      showMessage('Success Update', { variant: AlertStatusesEnum.SUCCESS });
    } catch (error: any) {
      dispatch({
        type: CampaignActionTypesEnum.POST_EDIT_CAMPAIGN_ERROR,
        payload: error?.response?.data?.UserMessage || error,
      });
    }
  };

export const postDraftEditCampaign =
  (
    id: number,
    campaignData: object,
    showMessage: TShowMessage,
    navigate: NavigateFunction,
  ) =>
  async (dispatch: Dispatch<TCampaignAction>): Promise<void> => {
    try {
      dispatch({
        type: CampaignActionTypesEnum.POST_DRAFT_EDIT_CAMPAIGN_LOADING,
      });
      await editDraftCampaign(id, campaignData);
      dispatch({
        type: CampaignActionTypesEnum.POST_DRAFT_EDIT_CAMPAIGN_SUCCESS,
      });
      showMessage('Success Save to Draft', {
        variant: AlertStatusesEnum.SUCCESS,
      });
      navigate(urls.campaign);
    } catch (error: any) {
      dispatch({
        type: CampaignActionTypesEnum.POST_DRAFT_EDIT_CAMPAIGN_ERROR,
        payload: error?.response?.data?.UserMessage || error,
      });
    }
  };

export const putApproveCampaign =
  (id: number, newCampaignData: object, navigate: NavigateFunction) =>
  async (dispatch: Dispatch<TCampaignAction>): Promise<void> => {
    try {
      dispatch({ type: CampaignActionTypesEnum.PUT_APPROVE_CAMPAIGN_LOADING });
      await putCampaignApproved(id, newCampaignData);
      dispatch({ type: CampaignActionTypesEnum.PUT_APPROVE_CAMPAIGN_SUCCESS });
      navigate(urls.campaign);
    } catch (error: any) {
      dispatch({
        type: CampaignActionTypesEnum.PUT_APPROVE_CAMPAIGN_ERROR,
        payload: error as any,
      });
    }
  };

export const putDeclineCampaign =
  (id: number, reason: IDeclineDialogValues, navigate: NavigateFunction) =>
  async (dispatch: Dispatch<TCampaignAction>): Promise<void> => {
    try {
      dispatch({ type: CampaignActionTypesEnum.PUT_DECLINE_CAMPAIGN_LOADING });
      await putCampaignDeclined(id, reason);
      dispatch({ type: CampaignActionTypesEnum.PUT_DECLINE_CAMPAIGN_SUCCESS });
      navigate(urls.campaign);
    } catch (error: any) {
      dispatch({
        type: CampaignActionTypesEnum.PUT_DECLINE_CAMPAIGN_ERROR,
        payload: error as any,
      });
    }
  };

export const cleanUpCampaignState =
  () =>
  async (dispatch: Dispatch<TCampaignAction>): Promise<void> => {
    dispatch({ type: CampaignActionTypesEnum.CLEANUP_CAMPAIGN_STATE });
  };

export const stopCampaign =
  (id: number, navigate: NavigateFunction) =>
  async (dispatch: Dispatch<TCampaignAction>): Promise<void> => {
    try {
      dispatch({ type: CampaignActionTypesEnum.CAMPAIGN_STOP_LOADING });
      await campaignStopped(id);
      dispatch({ type: CampaignActionTypesEnum.CAMPAIGN_STOP_SUCCESS });
      dispatch({
        type: CampaignActionTypesEnum.CAMPAIGN_STOP_MODAL_TOGGLE,
        payload: false,
      });

      navigate(urls.campaign);
    } catch (error: any) {
      dispatch({
        type: CampaignActionTypesEnum.CAMPAIGN_STOP_ERROR,
        payload: error?.response?.data?.UserMessage,
      });
    }
  };
