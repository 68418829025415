import {
  Checkbox,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Popover,
} from '@mui/material';
import { Table } from '@tanstack/react-table';
import React, { ReactElement } from 'react';

import { TActionsButtons } from '../../../../types';

interface IOrderingPopover<TData> {
  readonly isOpen: boolean;
  readonly table: Table<TData>;
  readonly anchorEl: TActionsButtons;
  readonly handleClose: () => void;
}

const OrderingPopover = <TData,>({
  isOpen,
  anchorEl,
  table,
  handleClose,
}: IOrderingPopover<TData>): ReactElement => {
  return (
    <Popover
      open={isOpen}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      anchorEl={anchorEl}
    >
      <List>
        <ListItem
          secondaryAction={
            <Checkbox
              edge="end"
              checked={table.getIsAllColumnsVisible()}
              onChange={table.getToggleAllColumnsVisibilityHandler()}
            />
          }
          disablePadding
        >
          <ListItemButton
            onClick={table.getToggleAllColumnsVisibilityHandler()}
          >
            <ListItemText id="ToggleAll" primary="Toggle all" />
          </ListItemButton>
        </ListItem>
        {table.getAllLeafColumns().map((column) => {
          if (!column.getCanHide()) {
            return null;
          }

          return (
            <ListItem
              secondaryAction={
                <Checkbox
                  edge="end"
                  checked={column.getIsVisible()}
                  onChange={column.getToggleVisibilityHandler()}
                />
              }
              key={column.id}
              disablePadding
            >
              <ListItemButton onClick={column.getToggleVisibilityHandler()}>
                <ListItemText id={column.id} primary={column.id} />
              </ListItemButton>
            </ListItem>
          );
        })}
      </List>
    </Popover>
  );
};

export default OrderingPopover;
