import './SitesPage.scss';

import { Paper } from '@mui/material';
import { AdjustmentsIcon, SiteIcon } from 'assets/icons';
import classnames from 'classnames';
import Button from 'components/common/Button';
import IconButton from 'components/common/Button/components/IconButton';
import SvgIcon from 'components/common/SvgIcon/SvgIcon';
import Table from 'components/common/Table';
import TableFilter from 'components/common/Table/TableFilter';
import {
  INITIAL_SITES_FILTER_VALUE,
  INITIAL_SITES_TABLE_COLUMNS_EXCEPTIONS,
  INITIAL_SITES_TABLE_COLUMNS_VALUE,
} from 'constants/constants';
import { SitesFilterText, SitesTableText } from 'constants/text/text';
import { isAdmin } from 'helpers';
import { useTypedSelector } from 'hooks';
import MainLayout from 'layouts/Main';
import React, { useState } from 'react';
import { ISelectedTableItem } from 'types';

import { Modals } from './components';
import { texts } from './constants/texts';
import { SitesHeader } from './shared';
import useSitePage from './SitesPage.hook';

const SitesPage: React.FC = () => {
  const [isFilterOpen, setIsFilterOpen] = useState<boolean>(true);
  const [selected, setSelected] = useState<ISelectedTableItem[]>([]);

  const [tableColumnsFilter, setTableColumnsFilter] = useState<string[]>(
    INITIAL_SITES_TABLE_COLUMNS_VALUE,
  );
  const { totalItems, loadings, sitesFilters } = useTypedSelector(
    (state) => state && state.sites,
  );
  const { role } = useTypedSelector((state) => state && state.auth);

  const {
    filtersValue,
    sortingValue,
    searchQuery,
    durationSeconds,
    durationMinute,
    pageNumber,
    pageSize,
    tableItems,
    tableMenuItem,
    resetPagination,
    handleClickAddSite,
    setSearchQuery,
    setDurationFilterSeconds,
    setDurationFilterMinute,
    setPageSize,
    setPageNumber,
    setFiltersValue,
    setSortingValue,
  } = useSitePage();

  return (
    <MainLayout>
      <div className="sites-container">
        <SitesHeader title={texts.pages.main.title}>
          <>
            {isAdmin(role) && (
              <Button
                label={texts.pages.main.buttons.add}
                icon={
                  <SvgIcon color="primary">
                    <SiteIcon style={{ fill: 'black', color: 'black' }} />
                  </SvgIcon>
                }
                onClick={handleClickAddSite}
              />
            )}
          </>
        </SitesHeader>
        <div className="campaign-body">
          <Paper sx={{ height: 960 }}>
            <div className="table-container">
              <div
                className={classnames('filter-campaign-container', {
                  'opened-campaign-filter-container': isFilterOpen,
                })}
              >
                {isFilterOpen ? (
                  <TableFilter
                    setIsFilterOpen={setIsFilterOpen}
                    setSearchQuery={setSearchQuery}
                    searchQuery={searchQuery}
                    filtersList={sitesFilters}
                    filtersValue={filtersValue}
                    setFiltersValue={setFiltersValue}
                    tableColumnsFilter={tableColumnsFilter}
                    setTableColumnsFilter={setTableColumnsFilter}
                    resetPagination={resetPagination}
                    initialFilterValue={INITIAL_SITES_FILTER_VALUE}
                    initialTableColumnsValue={INITIAL_SITES_TABLE_COLUMNS_VALUE}
                    exceptions={INITIAL_SITES_TABLE_COLUMNS_EXCEPTIONS}
                    filtersText={SitesFilterText}
                    durationFilter={{ name: 'Duration' }}
                    setDurationFilterSeconds={setDurationFilterSeconds}
                    setDurationFilterMinute={setDurationFilterMinute}
                    durationSeconds={durationSeconds}
                    durationMinute={durationMinute}
                    setPageNumber={setPageNumber}
                  />
                ) : (
                  <div style={{ paddingTop: '10px' }}>
                    <IconButton
                      onClick={() => setIsFilterOpen(true)}
                      icon={<AdjustmentsIcon />}
                      className="icon-l"
                    />
                  </div>
                )}
              </div>
              <Table
                items={tableItems}
                total={totalItems}
                pageNumber={pageNumber}
                pageSize={pageSize}
                setPageSize={setPageSize}
                setPageNumber={setPageNumber}
                tableColumnsFilter={tableColumnsFilter}
                sortingValue={sortingValue}
                setSortingValue={setSortingValue}
                isLoadingItems={loadings.getAll}
                tableText={SitesTableText}
                selected={selected}
                setSelected={setSelected}
                tableMenuItem={tableMenuItem}
              />
            </div>
          </Paper>
        </div>
      </div>
      <Modals />
    </MainLayout>
  );
};

export default SitesPage;
