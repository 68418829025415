import { TextField } from '@mui/material';
import { useFormikContext } from 'formik';
import React, { ChangeEvent, memo } from 'react';

import { regulars } from '../../../../../../../../../../../constants/regular/regulars';
import { maxLengthUserInputs } from '../../../../constants/validationSchema';
import { TInitialValues } from '../../../../utils';
import { SBox } from './UserInfo.styled';

const UserInfo = () => {
  const { values, touched, errors, handleChange, isSubmitting } =
    useFormikContext<TInitialValues>();

  const handleChangeInput = (event: ChangeEvent<HTMLInputElement>) => {
    const isMaxLength = event.target.value.length < maxLengthUserInputs + 1;
    const isNotContainForbiddenSymbol = regulars.forbiddenSymbol.test(
      event.target.value,
    );
    if (isMaxLength && !isNotContainForbiddenSymbol) {
      handleChange(event);
    }
  };

  return (
    <SBox>
      <TextField
        id="firstName"
        name="firstName"
        label="First name"
        value={values.firstName}
        onChange={handleChangeInput}
        error={touched.firstName && Boolean(errors.firstName)}
        helperText={touched.firstName && errors.firstName}
        disabled={isSubmitting}
      />
      <TextField
        id="lastName"
        name="lastName"
        label="Last name"
        value={values.lastName}
        onChange={handleChangeInput}
        error={touched.lastName && Boolean(errors.lastName)}
        helperText={touched.lastName && errors.lastName}
        disabled={isSubmitting}
      />
      <TextField
        id="userName"
        name="userName"
        label="User name"
        value={values.userName}
        onChange={handleChangeInput}
        error={touched.userName && Boolean(errors.userName)}
        helperText={touched.userName && errors.userName}
        disabled={isSubmitting}
      />
      <TextField
        id="email"
        name="email"
        label="Email"
        value={values.email}
        onChange={handleChange}
        error={touched.email && Boolean(errors.email)}
        helperText={touched.email && errors.email}
        disabled={isSubmitting}
      />
    </SBox>
  );
};

export default memo(UserInfo);
