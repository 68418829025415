import { FormatsEnum, formatsScheme } from '../../constants/file/formats';
import { IObjectIdWithName, ISelectOption, TNullable } from '../../types';
import { ICampaign } from '../../types/campaign';

function fromObjectIdWithName<T extends string | number = string | number>(
  data: TNullable<IObjectIdWithName<string, T>>,
): ISelectOption<T> | undefined;
function fromObjectIdWithName<T extends string | number = string | number>(
  data: TNullable<IObjectIdWithName<string, T>[]>,
): ISelectOption<T>[] | undefined;
function fromObjectIdWithName<T extends string | number = string | number>(
  data:
    | TNullable<IObjectIdWithName<string, T>>
    | TNullable<IObjectIdWithName<string, T>[]>,
): ISelectOption<T> | ISelectOption<T>[] | undefined {
  if (!data) {
    return;
  }

  if (Array.isArray(data)) {
    return data.map((item) => ({ value: item.id, label: item.name }));
  }

  return { value: data.id, label: data.name };
}

function fromFormats(
  formats: Array<keyof typeof FormatsEnum>,
): ISelectOption<number>[] {
  return formats.map((format) => {
    if (formatsScheme[format]) {
      return {
        value: Number(formatsScheme[format].id),
        label: String(format),
      };
    }

    throw new Error('Wrong Format');
  });
}

function fromCampaign(campaigns: ICampaign[]): ISelectOption<number>[] {
  return campaigns.map((campaign) => ({
    value: campaign.id,
    label: campaign.name,
  }));
}

export const convertToSelectData = {
  fromObjectIdWithName,
  fromFormats,
  fromCampaign,
};
