import produce from 'immer';

import {
  CampaignActionTypesEnum,
  ICampaignState,
  TCampaignAction,
} from '../../../ActionTypes/campaignsTypes/main/campaignsTypes';

const initialState: ICampaignState = {
  campaigns: [],
  campaignTypes: [],
  searchedCampaigns: [],
  currentCampaign: null,
  isLoadingCurrentCampaign: false,
  hasErrorCurrentCampaign: false,
  isLoadingSearchedCampaigns: false,
  hasErrorSearchedCampaigns: false,
  isLoadingCampaignTypes: false,
  hasErrorCampaignTypesLoading: false,
  isLoadingCampaigns: false,
  hasErrorCampaignsLoading: false,
  isSearchCampaignLoading: false,
  hasErrorCampaignLoading: false,
  hasDeleteProjectError: false,
  isApproveCampaignLoading: false,
  hasApproveCampaignError: false,
  isDeclineCampaignLoading: false,
  hasDeclineCampaignError: false,
  isPostCampaignLoading: false,
  hasPostCampaignError: false,
  isPostEditCampaignLoading: false,
  hasPostEditCampaignError: false,
  isStopLoading: false,
  stopError: '',
  isStopModalOpen: false,
  isViewAllMediaModalOpen: false,
  totalItems: 0,
  pageNumber: 0,
  pageSize: 10,
  isLoadingCampaignFilters: false,
  hasErrorCampaignsFiltersLoading: false,
  filters: [],
  totalFilters: 0,
};

const campaignReducer = produce(
  (draft = initialState, action: TCampaignAction): ICampaignState => {
    switch (action.type) {
      case CampaignActionTypesEnum.GET_CAMPAIGNS_LOADING:
        draft.isLoadingCampaigns = true;

        return draft;

      case CampaignActionTypesEnum.GET_CAMPAIGNS_SUCCESS:
        draft.campaigns = action.payload.campaigns;
        draft.totalItems = action.payload.totalItems;
        draft.pageNumber = action.payload.pageNumber;
        draft.pageSize = action.payload.pageSize;
        draft.isLoadingCampaigns = false;

        return draft;
      case CampaignActionTypesEnum.GET_CAMPAIGNS_ERROR:
        draft.isLoadingCampaigns = false;
        draft.hasErrorCampaignsLoading = action.payload;

        return draft;
      case CampaignActionTypesEnum.GET_SEARCHED_CAMPAIGNS_LOADING:
        draft.isLoadingSearchedCampaigns = true;

        return draft;
      case CampaignActionTypesEnum.GET_SEARCHED_CAMPAIGNS_SUCCESS:
        draft.searchedCampaigns = action.payload.data;
        draft.isLoadingSearchedCampaigns = false;

        return draft;
      case CampaignActionTypesEnum.GET_SEARCHED_CAMPAIGNS_ERROR:
        draft.isLoadingSearchedCampaigns = false;
        draft.hasErrorSearchedCampaigns = action.payload;

        return draft;
      case CampaignActionTypesEnum.GET_CAMPAIGN_BY_ID_LOADING:
        draft.isLoadingCurrentCampaign = true;

        return draft;
      case CampaignActionTypesEnum.GET_CAMPAIGN_BY_ID_SUCCESS:
        draft.currentCampaign = action.payload.data;
        draft.isLoadingCurrentCampaign = false;

        return draft;
      case CampaignActionTypesEnum.GET_CAMPAIGN_BY_ID_ERROR:
        draft.isLoadingCurrentCampaign = false;
        draft.hasErrorCurrentCampaign = action.payload;

        return draft;
      case CampaignActionTypesEnum.GET_CAMPAIGN_TYPES_LOADING:
        draft.isLoadingCampaignTypes = true;

        return draft;
      case CampaignActionTypesEnum.GET_CAMPAIGN_TYPES_SUCCESS:
        draft.campaignTypes = action.payload.data;
        draft.isLoadingCampaignTypes = false;

        return draft;
      case CampaignActionTypesEnum.GET_CAMPAIGN_TYPES_ERROR:
        draft.isLoadingCampaignTypes = false;
        draft.hasErrorCampaignTypesLoading = action.payload;

        return draft;
      case CampaignActionTypesEnum.POST_CAMPAIGNS_LOADING:
        draft.hasPostCampaignError = false;
        draft.isPostCampaignLoading = true;

        return draft;
      case CampaignActionTypesEnum.POST_CAMPAIGNS_SUCCESS:
        draft.isPostCampaignLoading = false;

        return draft;
      case CampaignActionTypesEnum.POST_CAMPAIGNS_ERROR:
        draft.isPostCampaignLoading = false;
        draft.hasPostCampaignError = action.payload;

        return draft;
      case CampaignActionTypesEnum.POST_DRAFT_CAMPAIGN_LOADING:
        draft.isPostCampaignLoading = true;
        draft.hasPostCampaignError = false;

        return draft;
      case CampaignActionTypesEnum.POST_DRAFT_CAMPAIGN_SUCCESS:
        draft.isPostCampaignLoading = false;

        return draft;
      case CampaignActionTypesEnum.POST_DRAFT_CAMPAIGN_ERROR:
        draft.isPostCampaignLoading = false;
        draft.hasPostCampaignError = action.payload;

        return draft;
      case CampaignActionTypesEnum.DELETE_CAMPAIGN:
        draft.campaigns = draft.campaigns.filter(
          (c) => c.id !== action.payload,
        );

        return draft;
      case CampaignActionTypesEnum.DELETE_CAMPAIGN_ERROR:
        draft.hasDeleteProjectError = action.payload;

        return draft;
      case CampaignActionTypesEnum.PUT_APPROVE_CAMPAIGN_SUCCESS:
        draft.isApproveCampaignLoading = false;
        draft.hasApproveCampaignError = false;

        return draft;
      case CampaignActionTypesEnum.PUT_APPROVE_CAMPAIGN_LOADING:
        draft.isApproveCampaignLoading = true;

        return draft;
      case CampaignActionTypesEnum.PUT_APPROVE_CAMPAIGN_ERROR:
        draft.isApproveCampaignLoading = false;
        draft.hasApproveCampaignError = action.payload;

        return draft;
      case CampaignActionTypesEnum.PUT_DECLINE_CAMPAIGN_SUCCESS:
        draft.isDeclineCampaignLoading = false;
        draft.hasDeclineCampaignError = false;

        return draft;
      case CampaignActionTypesEnum.PUT_DECLINE_CAMPAIGN_LOADING:
        draft.isDeclineCampaignLoading = true;

        return draft;
      case CampaignActionTypesEnum.PUT_DECLINE_CAMPAIGN_ERROR:
        draft.isDeclineCampaignLoading = false;
        draft.hasDeclineCampaignError = action.payload;

        return draft;
      case CampaignActionTypesEnum.DELETE_CAMPAIGNS:
        draft.campaigns = draft.campaigns.filter(
          (item) => !action.payload.includes(item.id),
        );

        return draft;
      case CampaignActionTypesEnum.DELETE_CAMPAIGNS_ERROR:
        draft.hasDeleteProjectError = action.payload;

        return draft;
      case CampaignActionTypesEnum.GET_CAMPAIGNS_FILTERS_LOADING:
        draft.isLoadingCampaignFilters = true;

        return draft;
      case CampaignActionTypesEnum.GET_CAMPAIGNS_FILTERS_SUCCESS:
        draft.filters = action.payload.data;
        draft.isLoadingCampaignFilters = false;

        return draft;
      case CampaignActionTypesEnum.GET_CAMPAIGNS_FILTERS_ERROR:
        draft.isLoadingCampaignFilters = false;
        draft.hasErrorCampaignsFiltersLoading = action.payload;

        return draft;
      case CampaignActionTypesEnum.POST_EDIT_CAMPAIGN_LOADING:
        draft.isPostEditCampaignLoading = true;
        draft.hasPostEditCampaignError = false;

        return draft;
      case CampaignActionTypesEnum.POST_EDIT_CAMPAIGN_SUCCESS:
        draft.isPostEditCampaignLoading = false;

        return draft;
      case CampaignActionTypesEnum.POST_EDIT_CAMPAIGN_ERROR:
        draft.isPostEditCampaignLoading = false;
        draft.hasPostEditCampaignError = action.payload;

        return draft;
      case CampaignActionTypesEnum.POST_DRAFT_EDIT_CAMPAIGN_LOADING:
        draft.isPostEditCampaignLoading = true;
        draft.hasPostEditCampaignError = false;

        return draft;
      case CampaignActionTypesEnum.POST_DRAFT_EDIT_CAMPAIGN_SUCCESS:
        draft.isPostEditCampaignLoading = false;

        return draft;
      case CampaignActionTypesEnum.POST_DRAFT_EDIT_CAMPAIGN_ERROR:
        draft.isPostEditCampaignLoading = false;
        draft.hasPostEditCampaignError = action.payload;

        return draft;
      case CampaignActionTypesEnum.CLEANUP_CAMPAIGN_STATE:
        draft.campaigns = [];
        draft.campaignTypes = [];
        draft.searchedCampaigns = [];
        draft.hasPostCampaignError = false;
        draft.hasPostEditCampaignError = false;
        draft.currentCampaign = null;
        draft.isLoadingCampaignFilters = false;
        draft.hasErrorCampaignsFiltersLoading = false;
        draft.filters = [];

        return draft;
      case CampaignActionTypesEnum.CAMPAIGN_LENGTH:
        return {
          ...draft,
          totalItems: draft.totalItems - action.payload,
        };

      case CampaignActionTypesEnum.CAMPAIGN_STOP_MODAL_TOGGLE:
        draft.isStopModalOpen = action.payload;

        return draft;

      case CampaignActionTypesEnum.CAMPAIGN_VIEW_ALL_MEDIA_MODAL:
        draft.isViewAllMediaModalOpen = action.payload;

        return draft;

      case CampaignActionTypesEnum.CAMPAIGN_STOP_LOADING:
        draft.isStopLoading = true;

        return draft;

      case CampaignActionTypesEnum.CAMPAIGN_STOP_SUCCESS:
        draft.isStopLoading = false;

        return draft;

      case CampaignActionTypesEnum.CAMPAIGN_STOP_ERROR:
        return {
          ...draft,
          stopError: action.payload,
        };
      default:
        return draft;
    }
  },
  initialState,
);

export default campaignReducer;
