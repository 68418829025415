import produce from 'immer';

import {
  CentresActionsEnum,
  TCentresAction,
} from '../../ActionTypes/CentreTypes/centreTypes';
import { ICentreState } from './type';

const initialValues: ICentreState = {
  centre: [],
  loading: {
    getAll: false,
    addNew: false,
  },
};

const centreReducer = produce(
  (draft = initialValues, action: TCentresAction) => {
    switch (action.type) {
      case CentresActionsEnum.SET_CENTRES: {
        draft.centre = action.payload;

        return draft;
      }

      case CentresActionsEnum.SET_LOADING: {
        draft.loading[action.payload.type] = action.payload.value;

        return draft;
      }

      default:
        return draft;
    }
  },
  initialValues,
);

export default centreReducer;
