import 'antd/dist/antd.min.css';
import 'react-day-picker/dist/style.css';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import './index.css';

import { CssBaseline, ThemeProvider } from '@mui/material';
import { SnackbarProvider } from 'notistack';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import App from './App';
import store from './redux/Store';
import theme from './theme/theme';

ReactDOM.render(
  <BrowserRouter>
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <SnackbarProvider
          autoHideDuration={5000}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
          <App />
        </SnackbarProvider>
      </ThemeProvider>
    </Provider>
  </BrowserRouter>,
  document.getElementById('root'),
);
