import { ICentreNetworkFull } from 'redux/Reducers/users/types';
import { IUser, TNullable, UserRolesEnum } from 'types';

import {
  createCentreNetworksScheme,
  getNetworkScheme,
  ICentreNetworks,
} from './centreNetworks';

export type TInitialValues = {
  firstName: string;
  lastName: string;
  userName: string;
  email: string;
  role: UserRolesEnum | undefined;
  password: string;
  confirmPassword: string;
  subOrganisation: number;
  adminNetworks: TNullable<number[]>;
  centreNetworks: ICentreNetworks<TNullable<number>>[] | null;
  isUpdate: boolean;
};

export interface IAdditionalUserSchemeProps {
  originalCentreNetworks?: ICentreNetworkFull[];
}

const initialValuesSchema: Readonly<TInitialValues> = {
  firstName: '',
  lastName: '',
  userName: '',
  email: '',
  role: undefined,
  password: '',
  confirmPassword: '',
  subOrganisation: -1,
  centreNetworks: null,
  adminNetworks: null,
  isUpdate: false,
};

export const getInitialValues = (user?: IUser | undefined): TInitialValues => {
  if (!user) {
    return initialValuesSchema;
  }

  const initialValuesFromUser: TInitialValues = {
    firstName: user.firstName,
    lastName: user.lastName,
    userName: user.userName,
    role: user.role,
    email: user.email,
    password: '',
    confirmPassword: '',
    subOrganisation: user.subOrganisationId ?? -1,
    adminNetworks: getNetworkScheme(user.centreNetworks),
    centreNetworks: createCentreNetworksScheme(user.centreNetworks),
    isUpdate: true,
  };

  return initialValuesFromUser;
};
