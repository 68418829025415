import {
  Box,
  CircularProgress,
  CircularProgressProps,
  Typography,
  TypographyPropsVariantOverrides,
} from '@mui/material';
import { colors } from 'constants/colors/colors';
import React from 'react';

interface ICircularProgressWithLabel extends CircularProgressProps {
  value: number;
  typographyProps?: TypographyPropsVariantOverrides;
}

const CircularProgressWithLabel: React.FC<ICircularProgressWithLabel> = (
  props,
  typographyProps,
) => {
  return (
    <Box
      sx={{ position: 'relative', display: 'inline-flex' }}
      className="circular-progress-with-label-wrapper"
    >
      <CircularProgress
        variant="determinate"
        className="circular-progress-with-label"
        {...props}
      />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography
          variant="caption"
          component="div"
          sx={{ color: colors.White }}
          {...typographyProps}
        >{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  );
};

export default CircularProgressWithLabel;
