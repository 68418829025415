import { ManageCampaignModeEnum } from 'constants/enums/campaign';
import MainLayout from 'layouts/Main';
import React, { useEffect } from 'react';

import { useActions } from '../../../../hooks/useActions';
import { texts } from '../../constants';
import { CampaignForm } from '../../shared';

const AddCampaign: React.FC = () => {
  const { setCampaignMode } = useActions();

  useEffect(() => {
    setCampaignMode(ManageCampaignModeEnum.ADD);
  }, []);

  return (
    <MainLayout>
      <CampaignForm title={texts.add} />
    </MainLayout>
  );
};

export default AddCampaign;
