import produce from 'immer';

import {
  IProductState,
  ProductActionTypesEnum,
  TProductAction,
} from '../../ActionTypes/productTypes/productTypes';

const initialState: IProductState = {
  products: [],
  hasErrorProductsLoading: false,
  isLoadingProducts: false,
  isLoadingPostProduct: false,
  hasErrorPostProduct: false,
};

const productReducer = produce(
  (draft = initialState, action: TProductAction) => {
    switch (action.type) {
      case ProductActionTypesEnum.GET_PRODUCTS_LOADING:
        draft.isLoadingProducts = true;

        return draft;
      case ProductActionTypesEnum.GET_PRODUCTS_SUCCESS:
        draft.products = action.payload;
        draft.isLoadingProducts = false;

        return draft;
      case ProductActionTypesEnum.GET_PRODUCTS_ERROR:
        draft.hasErrorProductsLoading = false;
        draft.hasErrorProductsLoading = action.payload;

        return draft;
      case ProductActionTypesEnum.POST_PRODUCTS_LOADING:
        draft.isLoadingPostProduct = true;

        return draft;
      case ProductActionTypesEnum.POST_PRODUCTS_SUCCESS:
        draft.isLoadingPostProduct = false;

        return draft;
      case ProductActionTypesEnum.POST_PRODUCTS_ERROR:
        draft.hasErrorPostProduct = action.payload;

        return draft;
      case ProductActionTypesEnum.POST_PRODUCTS_CACHE_LOADING:
        draft.isLoadingPostProduct = true;

        return draft;
      case ProductActionTypesEnum.POST_PRODUCTS_CACHE_SUCCESS:
        draft.isLoadingPostProduct = false;

        return draft;
      case ProductActionTypesEnum.POST_PRODUCTS_CACHE_ERROR:
        draft.hasErrorPostProduct = action.payload;

        return draft;
      default:
        return draft;
    }
  },
  initialState,
);

export default productReducer;
