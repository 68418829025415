import { Dispatch } from 'redux';

import { ICategoryAllDto, ICategoryMapDto } from '../../../types';
import {
  CategoryActionTypesEnum,
  TCategoryAction,
} from '../../ActionTypes/categoriesTypes/categoryTypes';
import { allCategories, chooseCategory } from './categoryApi';

export const getCategories =
  () =>
  async (dispatch: Dispatch<TCategoryAction>): Promise<void> => {
    try {
      dispatch({ type: CategoryActionTypesEnum.GET_CATEGORIES_LOADING });
      const { data } = await allCategories<ICategoryAllDto>();
      dispatch({
        type: CategoryActionTypesEnum.GET_CATEGORIES_SUCCESS,
        payload: data.data,
      });
    } catch (error: any) {
      dispatch({
        type: CategoryActionTypesEnum.GET_CATEGORIES_ERROR,
        payload: error as any,
      });
    }
  };

export const getCategoryCache =
  (productId: number) =>
  async (dispatch: Dispatch<TCategoryAction>): Promise<void> => {
    try {
      dispatch({ type: CategoryActionTypesEnum.GET_CATEGORY_CACHE_LOADING });
      const { data } = await chooseCategory<ICategoryMapDto>(productId);
      dispatch({
        type: CategoryActionTypesEnum.GET_CATEGORY_CACHE_SUCCESS,
        payload: [data.data],
      });
    } catch (error: any) {
      dispatch({
        type: CategoryActionTypesEnum.GET_CATEGORY_CACHE_ERROR,
        payload: error as any,
      });
    }
  };
