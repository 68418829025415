/* eslint-disable max-lines */
import './CampaignPage.scss';

import { Delete as DeleteIcon } from '@mui/icons-material';
import {
  AdjustmentsIcon,
  ArchiveIcon,
  CampaignIcon,
  CampaignIconMenu,
} from 'assets/icons';
import BulkActionsButton from 'components/BulkActionsButton';
import Button from 'components/common/Button';
import IconButton from 'components/common/Button/components/IconButton';
import { TRangeDate } from 'components/common/DatePicker/types';
import AlertDialog from 'components/common/Modals/DialogModal';
import SvgIcon from 'components/common/SvgIcon/SvgIcon';
import Table from 'components/common/Table';
import TableFilter from 'components/common/Table/TableFilter';
import {
  INITIAL_CAMPAIGN_FILTER_VALUE,
  INITIAL_CAMPAIGN_TABLE_COLUMNS_EXCEPTIONS,
  INITIAL_CAMPAIGN_TABLE_COLUMNS_VALUE,
  INITIAL_SORTING_VALUE_CAMPAIGN_PAGE,
} from 'constants/constants';
import { CampaignStatusEnum } from 'constants/enums/campaign';
import {
  BulkActionsMassages,
  campaignArchiveDialog,
  campaignDeleteDialog,
  CampaignListMenuText,
  CampaignPageText,
  CampaignsFilterText,
  CampaignTableText,
  DatePickerText,
} from 'constants/text/text';
import { urlConstructors } from 'constants/urls/url';
import { removeTimezone } from 'helpers';
import { createCampaignsData } from 'helpers/constructors';
import { isAdmin } from 'helpers/role';
import { useError } from 'hooks';
import { useActions } from 'hooks/useActions';
import { useTypedSelector } from 'hooks/useTypedSelector';
import MainLayout from 'layouts/Main';
import React, { useEffect, useMemo, useState } from 'react';
import { DateRange } from 'react-day-picker';
import { useNavigate } from 'react-router-dom';
import {
  ICampaignFilter,
  IDateRangeFilter,
  ISelectedTableItem,
  ISortingValue,
  ITableMenuProps,
} from 'types';
import { useDebounce } from 'usehooks-ts';

import { SPaper } from './CampaignPage.styled';

const CampaignPage: React.FC = () => {
  const {
    campaigns,
    totalItems,
    isLoadingCampaigns,
    filters: campaignFilters,
  } = useTypedSelector((state) => state && state.campaigns);
  const { role } = useTypedSelector((state) => state && state.auth);
  const {
    getCampaigns,
    getCampaignsFilters,
    deleteCampaign,
    deleteCampaigns,
    archiveCampaign,
    archiveCampaigns,
  } = useActions();

  const [isFilterOpen, setIsFilterOpen] = useState<boolean>(true);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [pageNumber, setPageNumber] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(10);
  const [tableMenuAnchorEl, setTableMenuAnchorEl] = useState<
    null | HTMLElement | Element
  >(null);
  const [bulkActionsAnchorEl, setBulkActionsAnchorEl] = useState<
    null | HTMLElement | Element
  >(null);
  const [isArchiveCampaignDialogOpen, setIsArchiveCampaignDialogOpen] =
    useState<boolean>(false);
  const [isDeleteCampaignDialogOpen, setIsDeleteCampaignDialogOpen] =
    useState<boolean>(false);
  const [isArchiveCampaignsDialogOpen, setIsArchiveCampaignsDialogOpen] =
    useState<boolean>(false);
  const [isDeleteCampaignsDialogOpen, setIsDeleteCampaignsDialogOpen] =
    useState<boolean>(false);
  const [dateRange, setDateRange] = useState<TRangeDate>();

  const [filtersValue, setFiltersValue] = useState<ICampaignFilter>(
    INITIAL_CAMPAIGN_FILTER_VALUE,
  );
  const [tableColumnsFilter, setTableColumnsFilter] = useState<string[]>(
    INITIAL_CAMPAIGN_TABLE_COLUMNS_VALUE,
  );
  const [sortingValue, setSortingValue] = useState<ISortingValue>(
    INITIAL_SORTING_VALUE_CAMPAIGN_PAGE,
  );

  const [dateRangePickerOpenAnchor, setDateRangePickerOpenAnchor] =
    useState<Element | null>(null);

  const [selectedCampaign, setSelectedCampaign] = useState<number | null>(null);
  const [selected, setSelected] = useState<ISelectedTableItem[]>([]);

  const navigate = useNavigate();

  const campaignFiltersLength = campaignFilters.length;

  const debouncedSearchValue = useDebounce(searchQuery, 300);
  const debouncedDateRange = useDebounce(dateRange, 500);
  const tableItems = createCampaignsData(campaigns);
  const { outputError } = useError();

  const campaign = campaigns.find((item) => item.id === selectedCampaign);
  const isAdminAndPendingStatus =
    isAdmin(role) && campaign?.status === CampaignStatusEnum.PENDING_APPROVAL;

  const datas = useMemo(() => {
    const filters = {
      'Filters.Statuses': filtersValue.Status,
      'Filters.TypesIds': filtersValue['Campaign type'],
      'Filters.AdvertisersIds': filtersValue.Advertiser,
      'Filters.ProductsIds': filtersValue.Product,
      'Filters.CategoriesIds': filtersValue.Category,
      'Filters.SitesIds': filtersValue.Site,
      'Filters.AuthorsIds': filtersValue['Created By'],
      'Filters.StartDate':
        dateRange?.from && removeTimezone(dateRange.from).toISOString(),
      'Filters.EndDate':
        dateRange?.to && removeTimezone(dateRange.to).toISOString(),
    };

    const sorting = {
      SortDirection: sortingValue?.SortDirection?.toUpperCase(),
      SortColumn: sortingValue?.SortColumn,
    };

    return {
      pageNumber,
      pageSize,
      SearchQuery: debouncedSearchValue,
      ...sorting,
      ...filters,
    };
  }, [
    pageNumber,
    pageSize,
    debouncedSearchValue,
    filtersValue,
    sortingValue,
    dateRange,
  ]);

  useEffect(() => {
    getCampaigns(datas);
  }, [
    pageNumber,
    pageSize,
    debouncedSearchValue,
    filtersValue,
    sortingValue,
    debouncedDateRange,
  ]);

  useEffect(() => {
    if (!campaignFiltersLength) {
      getCampaignsFilters();
    }
  }, [campaignFiltersLength]);

  const newCampaignHandler = () => {
    navigate(urlConstructors.newCampaign());
  };

  const resetPagination = () => {
    setPageNumber(0);
  };

  const handleArchiveCampaign = () => {
    setIsArchiveCampaignDialogOpen(true);
    setTableMenuAnchorEl(null);
  };

  const handleDeleteCampaign = () => {
    setIsDeleteCampaignDialogOpen(true);
    setTableMenuAnchorEl(null);
  };

  const handleArchiveCampaigns = () => {
    setIsArchiveCampaignsDialogOpen(true);
    setBulkActionsAnchorEl(null);
  };

  const handleDeleteCampaigns = () => {
    setIsDeleteCampaignsDialogOpen(true);
    setBulkActionsAnchorEl(null);
  };

  const confirmArchiveCampaign = () => {
    setTableMenuAnchorEl(null);
    if (tableItems.length === 1 && pageNumber !== 0) {
      setPageNumber(pageNumber - 1);
    }
    if (selectedCampaign) {
      archiveCampaign(selectedCampaign);
    }
    setSelectedCampaign(null);
  };

  const confirmDeleteCampaign = () => {
    setTableMenuAnchorEl(null);
    if (tableItems.length === 1 && pageNumber !== 0) {
      setPageNumber(pageNumber - 1);
    }
    if (selectedCampaign) {
      deleteCampaign(selectedCampaign);
    }
    setSelectedCampaign(null);
  };

  const handleCloseArchiveCampaignDialog = () => {
    setIsArchiveCampaignDialogOpen(false);
    setSelectedCampaign(null);
  };

  const handleCloseDeleteCampaignDialog = () => {
    setIsDeleteCampaignDialogOpen(false);
    setSelectedCampaign(null);
  };

  const handleCloseArchiveCampaignsDialog = () => {
    setIsArchiveCampaignsDialogOpen(false);
  };

  const handleCloseDeleteCampaignsDialog = () => {
    setIsDeleteCampaignsDialogOpen(false);
  };

  const handleArchiveSeveralCampaigns = async (
    ids?: number[],
  ): Promise<void> => {
    try {
      if (tableItems.length === ids?.length && pageNumber !== 0) {
        setPageNumber(pageNumber - 1);
      }
      if (ids?.length) {
        await archiveCampaigns(ids);
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      outputError(error?.message, { isSnackbar: true });
    }
  };

  const handleDeleteSeveralCampaigns = (ids?: number[]) => {
    if (tableItems.length === ids?.length && pageNumber !== 0) {
      setPageNumber(pageNumber - 1);
    }
    if (ids?.length) {
      deleteCampaigns(ids);
    }
  };

  const handleOpenTableMenu = (
    anchorEl: HTMLElement | Element,
    id?: number,
  ) => {
    setTableMenuAnchorEl(anchorEl);
    if (id) {
      setSelectedCampaign(id);
    }
  };

  const handleCloseTableMenu = () => {
    setTableMenuAnchorEl(null);
  };

  const handleOpenBulkActionsMenu = (
    anchorEl: HTMLElement | Element,
    id?: number,
  ) => {
    setBulkActionsAnchorEl(anchorEl);
    if (id) {
      setSelectedCampaign(id);
    }
  };

  const handleCloseBulkActionsMenu = () => {
    setBulkActionsAnchorEl(null);
  };

  const handleOpenDatePickerRange = (e: { currentTarget: HTMLElement }) => {
    setDateRangePickerOpenAnchor(e.currentTarget);
  };

  const handleCloseDatePickerRange = () => {
    setDateRangePickerOpenAnchor(null);
  };

  const handleChangeDateRange = (dateRangeValue: DateRange | undefined) => {
    setDateRange(dateRangeValue);
    handleCloseDatePickerRange();
  };

  const handleClearDateRange = (
    event?: React.MouseEvent<HTMLButtonElement>,
  ) => {
    if (event) {
      event.stopPropagation();
    }

    setDateRange(undefined);
  };

  const dateRangeFilter: IDateRangeFilter = useMemo(
    () => ({
      dateRangePickerOpenAnchor,
      handleOpenDatePickerRange,
      handleCloseDatePickerRange,
      handleClearDateRange,
      dateRange,
      datePickerProps: {
        mode: 'range',
        relatedComponent: 'input',
        defaultDate: dateRange,
        onSelect: handleChangeDateRange,
      },
      DatePickerText,
    }),
    [dateRange],
  );

  const handleMenuItemClick = () => {
    if (selectedCampaign) {
      if (isAdminAndPendingStatus) {
        navigate(urlConstructors.reviewCampaign(selectedCampaign));
      } else {
        navigate(urlConstructors.editCampaign(selectedCampaign));
      }
    }
  };

  const campaignItem = () => {
    switch (campaign?.status) {
      case CampaignStatusEnum.ARCHIVED:
        return {
          id: campaign.id,
          label: CampaignListMenuText.delete,
          icon: <DeleteIcon color="error" />,
          onClick: handleDeleteCampaign,
        };
      case CampaignStatusEnum.DRAFT:
      case CampaignStatusEnum.COMPLETED:
        return {
          id: campaign.id,
          label: CampaignListMenuText.archive,
          icon: <ArchiveIcon />,
          onClick: handleArchiveCampaign,
        };
      default:
        return null;
    }
  };

  const menuListItems = [
    {
      id: 1,
      label: isAdminAndPendingStatus
        ? CampaignListMenuText.review
        : CampaignListMenuText.edit,
      icon: <CampaignIconMenu />,
      onClick: handleMenuItemClick,
    },
    campaignItem(),
  ];

  const bulkActionsListItems = [
    {
      id: 1,
      label: CampaignListMenuText.archive,
      icon: <ArchiveIcon />,
      onClick: handleArchiveCampaigns,
      errorToasterLabel: BulkActionsMassages.campaignsError,
    },
    {
      id: 2,
      label: CampaignListMenuText.delete,
      icon: <DeleteIcon color="error" />,
      onClick: handleDeleteCampaigns,
      errorToasterLabel: BulkActionsMassages.campaignsError,
    },
  ];

  const tableMenuItem: ITableMenuProps = {
    menuListItems,
    tableMenuAnchorEl,
    handleOpenTableMenu,
    handleCloseTableMenu,
  };

  const bulkActionsMenuItems: ITableMenuProps = {
    menuListItems: bulkActionsListItems,
    tableMenuAnchorEl: bulkActionsAnchorEl,
    handleOpenTableMenu: handleOpenBulkActionsMenu,
    handleCloseTableMenu: handleCloseBulkActionsMenu,
  };

  const selectedCampaignItems = tableItems.filter((item) =>
    selected.map((el) => el.id).includes(item.id.value),
  );

  return (
    <MainLayout>
      <div className="container">
        <div className="campaigns-header">
          <div className="first-header">{CampaignPageText.header}</div>
          <div className="controllers">
            <BulkActionsButton
              selectedItems={selectedCampaignItems}
              bulkActionsMenuItems={bulkActionsMenuItems}
            />
            <Button
              onClick={newCampaignHandler}
              label={CampaignPageText.newCampaign}
              icon={
                <SvgIcon className="icon-xxxl-new-campaign">
                  <CampaignIcon />
                </SvgIcon>
              }
            />
          </div>
        </div>
        <div className="campaign-body">
          <SPaper>
            <div className="table-container">
              <div
                className={`filter-campaign-container ${
                  isFilterOpen ? 'opened-campaign-filter-container' : ''
                }`}
              >
                {isFilterOpen ? (
                  <TableFilter
                    setIsFilterOpen={setIsFilterOpen}
                    setSearchQuery={setSearchQuery}
                    searchQuery={searchQuery}
                    filtersList={campaignFilters}
                    filtersValue={filtersValue}
                    setFiltersValue={setFiltersValue}
                    tableColumnsFilter={tableColumnsFilter}
                    setTableColumnsFilter={setTableColumnsFilter}
                    resetPagination={resetPagination}
                    initialFilterValue={INITIAL_CAMPAIGN_FILTER_VALUE}
                    initialTableColumnsValue={
                      INITIAL_CAMPAIGN_TABLE_COLUMNS_VALUE
                    }
                    exceptions={INITIAL_CAMPAIGN_TABLE_COLUMNS_EXCEPTIONS}
                    filtersText={CampaignsFilterText}
                    dateRangeFilter={dateRangeFilter}
                    setPageNumber={setPageNumber}
                  />
                ) : (
                  <div style={{ paddingTop: '10px' }}>
                    <IconButton
                      onClick={() => setIsFilterOpen(true)}
                      icon={<AdjustmentsIcon />}
                      className="icon-l"
                    />
                  </div>
                )}
              </div>
              <Table
                items={tableItems}
                total={totalItems}
                pageNumber={pageNumber}
                pageSize={pageSize}
                setPageSize={setPageSize}
                setPageNumber={setPageNumber}
                tableColumnsFilter={tableColumnsFilter}
                sortingValue={sortingValue}
                setSortingValue={setSortingValue}
                isLoadingItems={isLoadingCampaigns}
                tableText={CampaignTableText}
                selected={selected}
                setSelected={setSelected}
                tableMenuItem={tableMenuItem}
              />
            </div>
          </SPaper>
        </div>
        <AlertDialog
          message={campaignArchiveDialog.header}
          isOpen={isArchiveCampaignDialogOpen}
          handleClose={handleCloseArchiveCampaignDialog}
          handleConfirmAction={confirmArchiveCampaign}
        />
        <AlertDialog
          message={campaignDeleteDialog.header}
          isOpen={isDeleteCampaignDialogOpen}
          handleClose={handleCloseDeleteCampaignDialog}
          handleConfirmAction={confirmDeleteCampaign}
        />
        <AlertDialog
          message={campaignDeleteDialog.header}
          isOpen={isDeleteCampaignsDialogOpen}
          handleClose={handleCloseDeleteCampaignsDialog}
          handleConfirmAction={handleDeleteSeveralCampaigns}
          selectedItems={selectedCampaignItems}
        />
        <AlertDialog
          message={campaignArchiveDialog.header}
          isOpen={isArchiveCampaignsDialogOpen}
          handleClose={handleCloseArchiveCampaignsDialog}
          handleConfirmAction={handleArchiveSeveralCampaigns}
          selectedItems={selectedCampaignItems}
        />
      </div>
    </MainLayout>
  );
};

export default CampaignPage;
