// import { AxiosError } from 'axios';
import { Dispatch } from 'redux';

import { AlertStatusesEnum } from '../../../constants/constants';
import {
  IGetOneUserData,
  IObjectIdWithName,
  IUserShort,
  SortDirectionEnum,
  TAllUsersColumns,
  TPagination,
  TShowMessage,
  TUserDto,
} from '../../../types';
import {
  IGetAllUsersPayload,
  ISetLoadingPayload,
  IToggleModalsPayload,
} from '../../ActionTypes/UsersTypes/types';
import {
  TUsersAction,
  UserActionsEnum,
} from '../../ActionTypes/UsersTypes/usersTypes';
import { IGetAllUsersParams, TPromiseSuccess } from './types';
import {
  createUserApi,
  deleteUserApi,
  deleteUserBulkApi,
  fetchAllUsers,
  getNewUserDataApi,
  getOneApi,
  updateUserApi,
} from './UsersApi';

export const getAllUsers =
  (params?: IGetAllUsersParams) =>
  async (dispatch: Dispatch<TUsersAction>): Promise<void> => {
    try {
      dispatch({
        type: UserActionsEnum.SET_LOADING,
        payload: { type: 'getAll', value: true },
      });
      const { data } = await fetchAllUsers<IGetAllUsersPayload>(params);
      dispatch({ type: UserActionsEnum.GET_ALL_USERS, payload: data });
    } catch (error) {
      // console.error((error as AxiosError).toJSON());
    }
  };

export const setAllUsersParams =
  (params: IGetAllUsersParams) =>
  (dispatch: Dispatch<TUsersAction>): void => {
    dispatch({ type: UserActionsEnum.SET_ALL_USERS_PARAMS, payload: params });
  };

export const getOneUser =
  (id: number) =>
  async (dispatch: Dispatch<TUsersAction>): Promise<void> => {
    try {
      dispatch({
        type: UserActionsEnum.SET_LOADING,
        payload: { type: 'getOne', value: true },
      });
      const {
        data: { data },
      } = await getOneApi<IGetOneUserData>(id);
      dispatch({
        type: UserActionsEnum.GET_ONE_USER,
        payload: data,
      });
    } catch (error) {
      console.error(error);
      dispatch({
        type: UserActionsEnum.TOGGLE_MODAL,
        payload: { type: 'manageUser', value: false },
      });
    } finally {
      dispatch({
        type: UserActionsEnum.SET_LOADING,
        payload: { type: 'getOne', value: false },
      });
    }
  };

export const createUser =
  (data: TUserDto) =>
  async (dispatch: Dispatch<TUsersAction>): Promise<void> => {
    try {
      dispatch({
        type: UserActionsEnum.SET_LOADING,
        payload: { type: 'createUser', value: true },
      });
      await createUserApi(data);
      dispatch({
        type: UserActionsEnum.TOGGLE_MODAL,
        payload: { type: 'manageUser', value: false },
      });
    } catch (error) {
      console.error(error);
      throw error;
    } finally {
      dispatch({
        type: UserActionsEnum.SET_LOADING,
        payload: { type: 'createUser', value: false },
      });
    }
  };

export const updateUser =
  (id: number, data: TUserDto) =>
  async (dispatch: Dispatch<TUsersAction>): Promise<void> => {
    try {
      dispatch({
        type: UserActionsEnum.SET_LOADING,
        payload: { type: 'updateUser', value: true },
      });
      await updateUserApi({ id, data });
      dispatch({
        type: UserActionsEnum.TOGGLE_MODAL,
        payload: { type: 'manageUser', value: false },
      });
      dispatch({
        type: UserActionsEnum.REMOVE_CURRENT_CLICKED_USER,
      });
    } catch (error) {
      console.error(error);
      throw error;
    } finally {
      dispatch({
        type: UserActionsEnum.SET_LOADING,
        payload: { type: 'updateUser', value: false },
      });
    }
  };

export const deleteUser =
  (id: number) =>
  async (dispatch: Dispatch<TUsersAction>): Promise<void> => {
    try {
      dispatch({
        type: UserActionsEnum.SET_LOADING,
        payload: { type: 'deleteOne', value: true },
      });
      await deleteUserApi(id);
      dispatch({
        type: UserActionsEnum.SET_LOADING,
        payload: { type: 'deleteOne', value: false },
      });
      dispatch({
        type: UserActionsEnum.TOGGLE_MODAL,
        payload: { type: 'deleteOne', value: false },
      });
    } catch (error) {
      console.error(error);
    }
  };

export const deleteUsersBulk =
  (ids: number[]) =>
  async (dispatch: Dispatch<TUsersAction>): Promise<void> => {
    try {
      dispatch({
        type: UserActionsEnum.SET_LOADING,
        payload: { type: 'deleteBulk', value: true },
      });
      await deleteUserBulkApi(ids);
      dispatch({
        type: UserActionsEnum.SET_LOADING,
        payload: { type: 'deleteBulk', value: false },
      });
      dispatch({
        type: UserActionsEnum.TOGGLE_MODAL,
        payload: { type: 'deleteBulk', value: false },
      });
    } catch (error) {
      console.error(error);
    }
  };

export const usersModalToggler =
  ({ type, value }: IToggleModalsPayload) =>
  (dispatch: Dispatch<TUsersAction>): void => {
    dispatch({
      type: UserActionsEnum.TOGGLE_MODAL,
      payload: { type, value },
    });
  };

export const usersLoadersToggler =
  ({ type, value }: ISetLoadingPayload) =>
  (dispatch: Dispatch<TUsersAction>): void => {
    dispatch({
      type: UserActionsEnum.SET_LOADING,
      payload: { type, value },
    });
  };

export const allUserTableGlobalSearch =
  (value?: string) =>
  (dispatch: Dispatch<TUsersAction>): void => {
    dispatch({
      type: UserActionsEnum.ALL_USER_TABLE_GLOBAL_SEARCH,
      payload: value || '',
    });
  };

export const allUserTableSetSelectedUser =
  (data: IUserShort[]) =>
  (dispatch: Dispatch<TUsersAction>): void => {
    dispatch({
      type: UserActionsEnum.ALL_USER_TABLE_SET_SELECTED_USERS,
      payload: data,
    });
  };

export const allUserTableCleanupSelectedUser =
  () =>
  (dispatch: Dispatch<TUsersAction>): void => {
    dispatch({
      type: UserActionsEnum.ALL_USER_TABLE_CLEANUP_SELECTED_USERS,
    });
  };

export const allUserTablePaginationPageSize =
  (data: TPagination['pageSize']) =>
  (dispatch: Dispatch<TUsersAction>): void => {
    dispatch({
      type: UserActionsEnum.ALL_USER_TABLE_PAGINATION_PAGE_SIZE,
      payload: data,
    });
  };

export const allUserTablePaginationCurrentPage =
  (data: TPagination['currentPage']) =>
  (dispatch: Dispatch<TUsersAction>): void => {
    dispatch({
      type: UserActionsEnum.ALL_USER_TABLE_PAGINATION_CURRENT_PAGE,
      payload: data,
    });
  };

export const allUserTableSortDirection =
  (data?: SortDirectionEnum | undefined) =>
  (dispatch: Dispatch<TUsersAction>): void => {
    dispatch({
      type: UserActionsEnum.ALL_USER_TABLE_SORT_DIRECTION,
      payload: data,
    });
  };

export const allUserTableSortColumn =
  (data?: TAllUsersColumns | undefined) =>
  (dispatch: Dispatch<TUsersAction>): void => {
    dispatch({
      type: UserActionsEnum.ALL_USER_TABLE_SORT_COLUMN,
      payload: data,
    });
  };

export const allUserTableSetCurrentClickedUser =
  (data: IUserShort) =>
  (dispatch: Dispatch<TUsersAction>): void => {
    dispatch({
      type: UserActionsEnum.SET_CURRENT_CLICKED_USER,
      payload: data,
    });
  };

export const allUserTableRemoveCurrentClickedUser =
  () =>
  (dispatch: Dispatch<TUsersAction>): void => {
    dispatch({
      type: UserActionsEnum.REMOVE_CURRENT_CLICKED_USER,
    });
  };

export const setCurrentClickedActionsRowId =
  (id: number | null) =>
  (dispatch: Dispatch<TUsersAction>): void => {
    dispatch({
      type: UserActionsEnum.ALL_USER_TABLE_CURRENT_CLICKED_ACTIONS_ROW_ID,
      payload: id,
    });
  };

export const setSubOrganisations =
  (payload: IObjectIdWithName[]) =>
  (dispatch: Dispatch<TUsersAction>): void => {
    dispatch({
      type: UserActionsEnum.SET_SUBORGANISATIONS,
      payload,
    });
  };

export const setNetworks =
  (payload: IObjectIdWithName[]) =>
  (dispatch: Dispatch<TUsersAction>): void => {
    dispatch({
      type: UserActionsEnum.SET_NETWORKS,
      payload,
    });
  };

export const setCentres =
  (payload: IObjectIdWithName[]) =>
  (dispatch: Dispatch<TUsersAction>): void => {
    dispatch({
      type: UserActionsEnum.SET_CENTRES,
      payload,
    });
  };

export const getManageUserData =
  (showMessage: TShowMessage) =>
  async (dispatch: Dispatch<TUsersAction>): Promise<void> => {
    try {
      dispatch({
        type: UserActionsEnum.SET_LOADING,
        payload: { type: 'manageUser', value: true },
      });
      const [subOrganisations, networks, centres, roles] =
        await getNewUserDataApi();

      if ((subOrganisations as TPromiseSuccess).value) {
        dispatch({
          type: UserActionsEnum.SET_SUBORGANISATIONS,
          payload: (subOrganisations as TPromiseSuccess).value.data.data,
        });
      }

      if ((networks as TPromiseSuccess).value) {
        dispatch({
          type: UserActionsEnum.SET_NETWORKS,
          payload: (networks as TPromiseSuccess).value.data.data,
        });
      }

      if ((centres as TPromiseSuccess).value) {
        dispatch({
          type: UserActionsEnum.SET_CENTRES,
          payload: (centres as TPromiseSuccess).value.data.data,
        });
      }

      if ((roles as TPromiseSuccess).value) {
        dispatch({
          type: UserActionsEnum.SET_ROLES,
          payload: (roles as TPromiseSuccess).value.data.data,
        });
      }

      dispatch({
        type: UserActionsEnum.SET_LOADING,
        payload: { type: 'manageUser', value: false },
      });
    } catch (error: any) {
      showMessage(error?.response?.data?.UserMessage, {
        variant: AlertStatusesEnum.ERROR,
      });
      dispatch({
        type: UserActionsEnum.SET_LOADING,
        payload: { type: 'manageUser', value: false },
      });
    }
  };

export const bulkDeleteModalSetUsers =
  (data: IUserShort[]) =>
  (dispatch: Dispatch<TUsersAction>): void => {
    dispatch({
      type: UserActionsEnum.SET_BULK_MODAL_SELECTED_USERS,
      payload: data,
    });
  };

export const bulkDeleteModalRemoveUser =
  (id: IUserShort['id']) =>
  (dispatch: Dispatch<TUsersAction>): void => {
    dispatch({
      type: UserActionsEnum.REMOVE_BULK_MODAL_USER,
      payload: id,
    });
  };
