import './Content.scss';

import { RowSelectionState } from '@tanstack/react-table';
import { Table } from 'components/common';
import { useActions, useTypedSelector } from 'hooks';
import React, { useEffect, useMemo, useState } from 'react';

import { createTableData } from '../../helpers/table';
import { getColumns } from './columns';

const ManageTabContent: React.FC = () => {
  const [selected, setSelected] = useState<RowSelectionState>({});
  const { setSelectedSiteSchedules } = useActions();

  useEffect(() => {
    setSelectedSiteSchedules(selected);
  }, [selected]);

  const { campaignSites } = useTypedSelector(
    (state) => state.manageCampaign.manage,
  );
  const tableData = createTableData(campaignSites);
  const columns = useMemo(() => getColumns(), []);

  return (
    <div className="manage-tab-content-wrapper">
      <div className="manage-tab-content-wrapper-canvas">
        <div className="manage-tab-content-table-wrapper">
          <Table
            data={tableData}
            columns={columns}
            state={{
              rowSelection: selected,
              pagination: { pageIndex: 0, pageSize: 10000 },
            }}
            actions={[{ id: 1, type: 'ordering' }]}
            showDefaultActions={false}
            onRowSelectionChange={setSelected}
            rowSelecting
          />
        </div>
      </div>
    </div>
  );
};

export default ManageTabContent;
