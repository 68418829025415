import classnames from 'classnames';
import React from 'react';

import SvgIcon from '../../../../../common/SvgIcon/SvgIcon';
import { TSideBarListItem } from '../../ListItem';
import {
  SListItem,
  SListItemButton,
  SListItemIcon,
  SListItemText,
} from '../../ListItem.styled';

const DefaultListItem: React.FC<TSideBarListItem> = ({
  isActive,
  label,
  icon,
  onClick,
}) => {
  return (
    <SListItem>
      <SListItemButton
        onClick={onClick}
        className={classnames({
          active: isActive,
        })}
      >
        <SListItemIcon>
          <SvgIcon>{icon}</SvgIcon>
        </SListItemIcon>
        <SListItemText primary={label} />
      </SListItemButton>
    </SListItem>
  );
};
export default DefaultListItem;
