import './ViewAllSelectedMedia.scss';

import { Box, CircularProgress, Dialog, DialogContent } from '@mui/material';
import { getAllSchedulesMedia } from 'helpers/Campaign';
import { useActions, useTypedSelector } from 'hooks';
import React, { useEffect } from 'react';

import { Carousel } from './components';
import useViewAllSelectedMedia from './ViewAllSelectedMedia.hook';

const ViewAllSelectedMedia: React.FC = () => {
  const { modals, campaignSites, loaders } = useTypedSelector(
    (state) => state && state.manageCampaign.manage,
  );

  const { getSelectedMediasForViewAllMediaModal, cleanupViewAllMediaModal } =
    useActions();

  const { handleCloseViewMediaModal } = useViewAllSelectedMedia();

  const scheduleMedias = getAllSchedulesMedia(campaignSites, true);
  const scheduleMediasIds = scheduleMedias.map(
    (scheduleMedia) => scheduleMedia.mediaId,
  );

  useEffect(() => {
    getSelectedMediasForViewAllMediaModal(scheduleMediasIds);

    return () => {
      cleanupViewAllMediaModal();
    };
  }, []);

  return (
    <Dialog
      open={modals.viewAllMedia}
      onClose={handleCloseViewMediaModal}
      maxWidth="md"
      className="view-all-media-dialog"
    >
      <DialogContent sx={{ padding: '0' }}>
        {loaders.getSelectedMediaForViewAll ? (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: 75,
              height: 75,
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <Carousel />
        )}
      </DialogContent>
    </Dialog>
  );
};

export default ViewAllSelectedMedia;
