import produce from 'immer';

import {
  IMediaState,
  MediaActionTypesEnum,
  TMediaAction,
} from '../../ActionTypes/mediaTypes';

const initialState: IMediaState = {
  media: [],
  isLoadingMedia: false,
  hasErrorMediaLoading: false,
  totalItems: 0,
  pageNumber: 0,
  pageSize: 10,
  mediaFilters: [],
  isLoadingMediaFilters: false,
  isErrorMediaFiltersLoading: false,
  isLoadingRemoveMedias: false,
  hasErrorRemoveMedias: false,
  isLoadingRemoveMedia: false,
  hasErrorRemoveMedia: false,
  recentlyMedia: [],
  selectedMedia: [],
  isLoadingRecentlyMedia: false,
  isLoadingSelectedMedia: false,
  hasErrorRecentlyMedia: false,
  hasErrorSelectedMedia: false,
};

const mediaReducer = produce(
  (state = initialState, action: TMediaAction): IMediaState => {
    switch (action.type) {
      case MediaActionTypesEnum.GET_MEDIA_LOADING:
        return {
          ...state,
          isLoadingMedia: true,
        };
      case MediaActionTypesEnum.GET_MEDIA_SUCCESS:
        return {
          ...state,
          media: action.payload.medias,
          totalItems: action.payload.totalItem,
          pageNumber: action.payload.pageNumber,
          pageSize: action.payload.pageSize,
          isLoadingMedia: false,
        };
      case MediaActionTypesEnum.GET_MEDIA_ERROR:
        return {
          ...state,
          isLoadingMedia: false,
          hasErrorMediaLoading: action.payload,
        };
      case MediaActionTypesEnum.GET_MEDIA_FILTERS_LOADING:
        return {
          ...state,
          isLoadingMediaFilters: true,
        };
      case MediaActionTypesEnum.GET_MEDIA_FILTERS_SUCCESS:
        return {
          ...state,
          isLoadingMediaFilters: false,
          mediaFilters: action.payload.data,
        };
      case MediaActionTypesEnum.GET_MEDIA_FILTERS_ERROR:
        return {
          ...state,
          isLoadingMediaFilters: false,
          isErrorMediaFiltersLoading: action.payload,
        };
      case MediaActionTypesEnum.REMOVE_MEDIAS_LOADING:
        return {
          ...state,
          isLoadingRemoveMedias: false,
        };
      case MediaActionTypesEnum.REMOVE_MEDIAS_SUCCESS:
        return {
          ...state,
          isLoadingRemoveMedias: false,
          media: state.media.filter(
            (item) => !action.payload.includes(item.id),
          ),
        };
      case MediaActionTypesEnum.REMOVE_MEDIAS_ERROR:
        return {
          ...state,
          isLoadingRemoveMedias: false,
          hasErrorRemoveMedias: action.payload,
        };
      case MediaActionTypesEnum.REMOVE_MEDIA_LOADING:
        return {
          ...state,
          isLoadingRemoveMedia: true,
        };
      case MediaActionTypesEnum.REMOVE_MEDIA_SUCCESS:
        return {
          ...state,
          isLoadingRemoveMedia: false,
          media: state.media.filter((item) => action.payload !== item.id),
        };
      case MediaActionTypesEnum.MEDIA_LENGTH:
        return {
          ...state,
          totalItems: state.totalItems - action.payload,
        };
      case MediaActionTypesEnum.REMOVE_MEDIA_ERROR:
        return {
          ...state,
          isLoadingRemoveMedia: false,
          hasErrorRemoveMedias: action.payload,
        };

      case MediaActionTypesEnum.GET_RECENTLY_MEDIA_LOADING:
        return {
          ...state,
          isLoadingRecentlyMedia: true,
        };
      case MediaActionTypesEnum.GET_RECENTLY_MEDIA_SUCCESS:
        return {
          ...state,
          isLoadingRecentlyMedia: false,
          recentlyMedia: action.payload,
        };
      case MediaActionTypesEnum.GET_RECENTLY_MEDIA_ERROR:
        return {
          ...state,
          isLoadingRecentlyMedia: false,
          hasErrorRecentlyMedia: true,
        };
      case MediaActionTypesEnum.GET_SELECTED_MEDIA_LOADING:
        return {
          ...state,
          isLoadingSelectedMedia: true,
        };
      case MediaActionTypesEnum.GET_SELECTED_MEDIA_SUCCESS:
        return {
          ...state,
          isLoadingSelectedMedia: false,
          selectedMedia: action.payload,
        };
      case MediaActionTypesEnum.GET_SELECTED_MEDIA_ERROR:
        return {
          ...state,
          isLoadingSelectedMedia: false,
          hasErrorSelectedMedia: true,
        };

      default:
        return state;
    }
  },
  initialState,
);

export default mediaReducer;
