import { TabList as MuiTabsList } from '@mui/lab';
import { Tab as MuiTab } from '@mui/material';
import { ManageCampaignAddMediaTabsEnum } from 'constants/enums';
import { useActions, useTypedSelector } from 'hooks';
import React, { SyntheticEvent } from 'react';

const TabsList: React.FC = () => {
  const { campaignSchedule } = useTypedSelector(
    (state) => state && state.manageCampaign.manage.addMediaModal,
  );
  const { setAddMediaModalTab } = useActions();

  const handleChange = (
    __: SyntheticEvent,
    newValue: ManageCampaignAddMediaTabsEnum,
  ) => {
    setAddMediaModalTab(newValue);
  };

  const isViewSelectedTab = Boolean(campaignSchedule?.scheduleMedias.length);

  return (
    <MuiTabsList
      onChange={handleChange}
      sx={{ '& .MuiTabs-flexContainer': { justifyContent: 'center' } }}
    >
      {isViewSelectedTab && (
        <MuiTab
          label={ManageCampaignAddMediaTabsEnum.SELECTED}
          value={ManageCampaignAddMediaTabsEnum.SELECTED}
        />
      )}
      <MuiTab
        label={ManageCampaignAddMediaTabsEnum.RECENTLY}
        value={ManageCampaignAddMediaTabsEnum.RECENTLY}
      />
      <MuiTab
        label={ManageCampaignAddMediaTabsEnum.ALL}
        value={ManageCampaignAddMediaTabsEnum.ALL}
      />
    </MuiTabsList>
  );
};

export default TabsList;
