// TODO Refactor component
import './TableBody.scss';

import { MoreVert as MoreVertIcon } from '@mui/icons-material';
import { IconButton, TableBody } from '@mui/material';
import React, { useState } from 'react';

import {
  TABLE_DATES_VALUE,
  TABLE_MEDIA_VALUE,
} from '../../../../constants/constants';
import { convertStatusName } from '../../../../helpers/common';
import {
  ISelectedTableItem,
  ITableItems,
  ITableMenuProps,
} from '../../../../types';
import Checkbox from '../../Checkbox';
import { STableCell, STableCellButton, STableRow } from './TableBody.styled';

interface ICampaignTableBodyProps<Item> {
  setSelected: React.Dispatch<React.SetStateAction<ISelectedTableItem[]>>;
  selected: ISelectedTableItem[];
  items: Item[];
  tableColumnsFilter: string[];
  tableMenuItem?: ITableMenuProps;
  menuHandler: (e: React.SyntheticEvent, id: number) => void;
}

const CampaignTableBody: React.FC<ICampaignTableBodyProps<ITableItems>> = ({
  setSelected,
  selected,
  items,
  tableColumnsFilter,
  menuHandler,
  tableMenuItem,
}) => {
  const [showMultiple, setShowMultiple] = useState<number[]>([]);

  const handleShowMultiple = (e: React.SyntheticEvent, id: number) => {
    e.stopPropagation();
    if (showMultiple.includes(id)) {
      setShowMultiple(showMultiple.filter((item) => item !== id));

      return;
    }
    setShowMultiple([...showMultiple, id]);
  };

  const handleSelect = (item: ITableItems) => {
    // TODO Refactoring
    const selectedItem = selected.find((el) => el.id === item.id.value);

    if (selectedItem && Array.isArray(item.dates?.value)) {
      if (item.dates?.value.length === selectedItem?.index?.length) {
        const newSelected = selected.filter((el) => el.id !== item.id.value);

        return setSelected(newSelected);
      }

      const newSelected = selected.map((el) => {
        if (el.id === item.id.value && Array.isArray(item.dates?.value)) {
          const indexes = item.dates?.value.map((itm, index) => index);
          const itemObj = { id: item.id.value, index: indexes };

          return itemObj;
        }

        return el;
      });

      return setSelected(newSelected);
    }

    if (selectedItem) {
      const newSelected = selected.filter((el) => el.id !== item.id.value);

      return setSelected(newSelected);
    }

    if (Array.isArray(item.dates?.value)) {
      const indexes = item.dates?.value.map((itm, index) => index);
      const itemObj = { id: item.id.value, index: indexes };

      return setSelected([...selected, itemObj]);
    }

    const itemObj = { id: item.id.value };
    setSelected([...selected, itemObj]);
  };

  const handleSelectSubRow = (item: ITableItems, index: number) => {
    // TODO Refactoring
    const selectedItem = selected.find((el) => el.id === item.id.value);
    if (selectedItem?.index?.includes(index)) {
      const newSelectedItems = selected.reduce(
        (acc: ISelectedTableItem[], itm) => {
          if (itm.id === selectedItem.id && selectedItem?.index) {
            const newIndexes = selectedItem?.index.filter((id) => id !== index);
            if (newIndexes.length) {
              return [...acc, { id: item.id.value, index: newIndexes }];
            }

            return acc;
          }

          return [...acc, itm];
        },
        [],
      );
      setSelected(newSelectedItems);

      return;
    }
    if (selectedItem) {
      if (selectedItem.index) {
        const newSelectedItems = selected.map((itm) => {
          if (itm.id === selectedItem.id && selectedItem?.index) {
            return {
              id: item.id.value,
              // eslint-disable-next-line no-unsafe-optional-chaining
              index: [...selectedItem?.index, index],
            };
          }

          return itm;
        });
        setSelected(newSelectedItems);

        return;
      }
    }
    if (selectedItem) {
      if (!selectedItem?.index) {
        const newSelectedItems = selected.map((itm) => {
          if (itm.id === selectedItem.id) {
            return { id: item.id.value, index: [index] };
          }

          return itm;
        });
        setSelected(newSelectedItems);

        return;
      }
    }
    const itemObj = { id: item.id.value, index: [index] };
    setSelected([...selected, itemObj]);
  };

  const isSelectedRow = (item: ITableItems) => {
    const selectedItem = selected.find((el) => el.id === item.id.value);
    if (Array.isArray(item.dates?.value)) {
      const isMultiple =
        Boolean(selectedItem) &&
        item.dates?.value.length === selectedItem?.index?.length;

      return isMultiple;
    }

    return Boolean(selectedItem);
  };

  const isIndeterminateRow = (item: ITableItems): boolean => {
    const selectedItem = selected.find((el) => el.id === item.id.value);
    if (Array.isArray(item.dates?.value)) {
      const isMultiple =
        Boolean(selectedItem) &&
        item.dates?.value.length !== selectedItem?.index?.length;

      return isMultiple;
    }

    return false;
  };

  const isSelectedSubRow = (item: ITableItems, index: number) => {
    const selectedItem = selected.find((el) => el.id === item.id.value);

    return Boolean(selectedItem?.index?.includes(index));
  };

  const selectedItems = items.filter((item) =>
    selected.map((el) => el.id).includes(item.id.value),
  );

  return (
    <TableBody>
      {items.length
        ? items.map((row: ITableItems) => {
            const id = row.id.value;
            const isItemSelected = isSelectedRow(row);
            const isItemIndeterminated = isIndeterminateRow(row);
            const isActiveMenu =
              Number(tableMenuItem?.tableMenuAnchorEl?.id) === row.id.value;

            return (
              <React.Fragment key={id}>
                <STableRow
                  hover
                  role="checkbox"
                  aria-checked={isItemSelected}
                  tabIndex={-1}
                  selected={isItemSelected}
                  onClick={() => handleSelect(row)}
                >
                  <STableCell padding="checkbox">
                    <Checkbox
                      isChecked={isItemSelected}
                      indeterminate={isItemIndeterminated}
                    />
                  </STableCell>
                  {Object.entries(row).map(([key, cellItem]) => {
                    if (cellItem) {
                      const { onClick, icon } = cellItem;
                      const showCellData =
                        cellItem?.value || typeof cellItem.value === 'number';
                      const textButtonValue = cellItem.value
                        ? cellItem.value
                        : '-';
                      const textData = convertStatusName(showCellData);
                      const isDisabled =
                        cellItem.name === TABLE_MEDIA_VALUE.label &&
                        row.dates?.value === TABLE_DATES_VALUE.nonExistMedia;
                      if (tableColumnsFilter.includes(cellItem.name)) {
                        return (
                          <STableCell
                            className={`table-row-cell ${
                              key === 'name' ? 'bold-cell' : null
                            }`}
                            key={cellItem.name}
                            align="left"
                          >
                            {Array.isArray(cellItem.value) ? (
                              <STableCellButton
                                label="Multiple"
                                variant="text"
                                onClick={(e) => handleShowMultiple(e, id)}
                              />
                            ) : (
                              <>
                                {onClick ? (
                                  <STableCellButton
                                    onClick={(e) => {
                                      onClick({
                                        e,
                                        id,
                                        item: row,
                                        ids: selected,
                                        items: selectedItems,
                                      });
                                      e.stopPropagation();
                                    }}
                                    label={textButtonValue}
                                    variant="text"
                                    icon={icon}
                                    disabled={isDisabled}
                                  />
                                ) : (
                                  <div>
                                    <div
                                      dangerouslySetInnerHTML={{
                                        __html: `${
                                          showCellData ? textData : '-'
                                        }`,
                                      }}
                                    />
                                  </div>
                                )}
                              </>
                            )}
                          </STableCell>
                        );
                      }
                    }

                    return null;
                  })}
                  {tableMenuItem && (
                    <STableCell align="left">
                      <div className="more-icon-container">
                        <IconButton
                          className={isActiveMenu ? 'active-icon-button' : ''}
                          aria-label="more"
                          aria-controls="long-menu"
                          aria-haspopup="true"
                          id={String(id)}
                          onClick={(e) => menuHandler(e, id)}
                        >
                          <MoreVertIcon name="menu" />
                        </IconButton>
                      </div>
                    </STableCell>
                  )}
                </STableRow>
                {showMultiple.includes(id) &&
                  Array.isArray(row?.dates?.value) &&
                  row?.dates?.value.map((rw, index) => {
                    const isSubRowSelected = isSelectedSubRow(row, index);

                    return (
                      <STableRow
                        hover
                        key={rw}
                        aria-checked={isSubRowSelected}
                        onClick={() => handleSelectSubRow(row, index)}
                        selected={isSubRowSelected}
                        role="checkbox"
                      >
                        <STableCell padding="checkbox">
                          <Checkbox isChecked={isSubRowSelected} />
                        </STableCell>
                        {Object.entries(row).map(([key, cellItem]) => {
                          const { onClick, icon } = cellItem;
                          if (cellItem) {
                            const textButtonValue = cellItem.value
                              ? String(cellItem.value)
                              : '-';
                            if (tableColumnsFilter.includes(cellItem.name)) {
                              return (
                                <STableCell
                                  className={`table-row-cell ${
                                    key === 'name' ? 'bold-cell' : null
                                  }`}
                                  key={cellItem.name}
                                  align="left"
                                >
                                  {Array.isArray(cellItem.value) ? (
                                    <STableCellButton
                                      onClick={(e) =>
                                        onClick({
                                          e,
                                          id,
                                          ids: selected,
                                          items: selectedItems,
                                          item: row,
                                          index,
                                        })
                                      }
                                      label={cellItem.value[index]}
                                      icon={icon}
                                      variant="text"
                                    />
                                  ) : (
                                    <>
                                      {cellItem.onClick ? (
                                        <STableCellButton
                                          onClick={(e) =>
                                            onClick({
                                              e,
                                              id,
                                              ids: selected,
                                              items: selectedItems,
                                              item: row,
                                              index,
                                            })
                                          }
                                          label={textButtonValue}
                                          icon={icon}
                                          variant="text"
                                        />
                                      ) : null}
                                    </>
                                  )}
                                </STableCell>
                              );
                            }
                          }

                          return null;
                        })}
                      </STableRow>
                    );
                  })}
              </React.Fragment>
            );
          })
        : null}
    </TableBody>
  );
};

export default CampaignTableBody;
