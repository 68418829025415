import React from 'react';

import DetailTab from './Detail/DetailTab';
import ManageTab from './Manage/ManageTab';

const ManageCampaignTabs: React.FC = () => {
  return (
    <div className="manage-campaign-tabs">
      <DetailTab />
      <ManageTab />
    </div>
  );
};

export default ManageCampaignTabs;
