import { AxiosPromise } from 'axios';

import { AxiosMethodEnum } from '../../../constants/text/text';
import { urls } from '../../../constants/urls/url';
import { apiCall } from '../../../helpers/api/apiCall';
import { IAddNewCentrePayload } from './type';

const getMethodCentres = <T extends object>(url: string): AxiosPromise<T> => {
  return apiCall<T>(AxiosMethodEnum.get, url);
};

const postMethodCentres = <T extends object>(
  url: string,
  data?: object,
): AxiosPromise<T> => {
  return apiCall<T>(AxiosMethodEnum.post, url, data);
};

export const getCentresApi = <
  T extends object | Array<object>,
>(): AxiosPromise<T> => getMethodCentres<T>(urls.centres);

export const addCentreApi = <T extends object | Array<object>>(
  data: IAddNewCentrePayload,
): AxiosPromise<T> => postMethodCentres<T>(urls.centres, data);
