import { IMainManageCampaignReducer } from 'redux/Reducers/campaignsReducer/manage/type';

import { TManageCampaignDetailAction } from './detail/detailTypes';
import { TManageCampaignManageTabActions } from './manage/manageTypes';
import {
  IContainRootDataPayload,
  ISetLoadingsPayloadManageRoot,
  ISetMainModalsPayload,
} from './type';

export enum ManageMainActionEnum {
  CONTAIN_MANAGE_CAMPAIGN_ROOT_DATA = 'CONTAIN_MANAGE_CAMPAIGN_ROOT_DATA',
  SET_CAMPAIGN_MODE = 'SET_CAMPAIGN_MODE',
  CHANGE_CAMPAIGN_TAB = 'CHANGE_CAMPAIGN_TAB',

  SET_LOADINGS = 'SET_LOADINGS_MANAGE_CAMPAIGN_ROOT',
  SET_MODALS = 'SET_MODALS_MANAGE_CAMPAIGN_ROOT',

  SET_COPY_CAMPAIGN_ID = 'SET_COPY_CAMPAIGN_ID',

  SET_DRAFT_MANAGE_CAMPAIGN = 'SET_DRAFT_MANAGE_CAMPAIGN',
  APPLY_DRAFT_MANAGE_CAMPAIGN = 'APPLY_DRAFT_MANAGE_CAMPAIGN',

  CLEANUP_ROOT_MANAGE_STATE = 'CLEANUP_ROOT_MANAGE_STATE',
}

interface IContainManageCampaignRootData {
  type: ManageMainActionEnum.CONTAIN_MANAGE_CAMPAIGN_ROOT_DATA;
  payload: IContainRootDataPayload;
}

interface ISetCampaignMode {
  type: ManageMainActionEnum.SET_CAMPAIGN_MODE;
  payload: IMainManageCampaignReducer['mode'];
}

interface IChangeCampaignTab {
  type: ManageMainActionEnum.CHANGE_CAMPAIGN_TAB;
  payload: IMainManageCampaignReducer['activeTab'];
}

interface ISetLoadings {
  type: ManageMainActionEnum.SET_LOADINGS;
  payload: ISetLoadingsPayloadManageRoot;
}

interface ISetModals {
  type: ManageMainActionEnum.SET_MODALS;
  payload: ISetMainModalsPayload;
}

interface ISetCopyCampaignId {
  type: ManageMainActionEnum.SET_COPY_CAMPAIGN_ID;
  payload: IMainManageCampaignReducer['copyCampaignId'];
}

interface ICleanupManageRootState {
  type: ManageMainActionEnum.CLEANUP_ROOT_MANAGE_STATE;
}

interface ISetDraftManageCampaign {
  type: ManageMainActionEnum.SET_DRAFT_MANAGE_CAMPAIGN;
}

interface IApplyDraftManageCampaign {
  type: ManageMainActionEnum.APPLY_DRAFT_MANAGE_CAMPAIGN;
}

export type TManageCampaignRootAction =
  | IContainManageCampaignRootData
  | ISetCampaignMode
  | IChangeCampaignTab
  | ISetLoadings
  | ISetModals
  | ISetCopyCampaignId
  | ICleanupManageRootState
  | ISetDraftManageCampaign
  | IApplyDraftManageCampaign;

export type TManageCampaignAction =
  | TManageCampaignRootAction
  | TManageCampaignDetailAction
  | TManageCampaignManageTabActions;
