export const texts = {
  pages: {
    main: {
      title: 'Sites',
      buttons: {
        add: 'Add Site',
      },
    },

    // Сombining "add" and "edit" pages
    manage: {
      content: {
        main: {
          title: 'Main information',
          inputs: {
            name: 'Site name',
            address: 'Site address',
            broadsignId: 'Broadsign ID',
          },
        },
        centreNetworks: {
          title: 'Centre and network',
          labels: {
            addNewCentre: 'New centre',
          },
          inputs: {
            centre: 'New centre',
          },
          selects: {
            states: 'States',
            centre: 'Centre',
            networks: 'Networks',
          },
        },
        mediaConfig: {
          title: 'Media Configuration',
          selects: {
            formats: 'Formats',
            resolution: 'Resolution',
            orientation: 'Orientation',
          },
          labels: {
            addNewResolution: 'New resolution',
          },
          inputs: {
            width: 'Width',
            height: 'Height',
          },
        },
        broadsign: {
          title: 'Broadsign',
        },
        siteDayParts: {
          title: 'Site day parts',
          accordions: {
            dayPart: (number: number): string => `Day part #${number}`,
            siteFrame: (number: number): string => `Site frame #${number}`,
          },
          inputs: {
            duration: 'Duration (s)',
            siteFrame: {
              name: 'Name',
              id: 'Frame ID',
            },
          },
          buttons: {
            addDayPart: 'Add day part',
            addSiteFrame: 'Add site frame',
          },
        },
      },
    },

    edit: {
      title: 'Edit site',
    },

    add: {
      title: 'Add new site',
    },
  },
} as const;

export const messages = {
  incorrectDayPartsSector: {
    title: 'You have incorrectly filled in parts of the day',
    content:
      'If you confirm sending the data, then the wrong parts of the day will simply be deleted. You can add them later',
  },
  leave: 'Are you sure you want to leave?',
  required: 'This field is required',
};
