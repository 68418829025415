import { FormatsEnum, formatsScheme } from 'constants/file/formats';

export const convertToNumberFromFormats = (formats: string): number => {
  const formatsArray = formats.split(',');

  return formatsArray.reduce<number>((acc, format) => {
    let count = acc;

    const formatTrim = format.trim() as FormatsEnum;

    if (!formatsScheme[formatTrim]) {
      throw new Error('Unknown file format detected');
    }
    count += formatsScheme[formatTrim].id;

    return count;
  }, 0);
};
