import {
  DATE_WITH_DAY_OF_WEEK_AND_TIME,
  DAYS_OF_WEEK,
  DEFAULT_DATE_FORMAT_REVERSE,
  DEFAULT_DATE_FORMAT_REVERSE_SHORT,
  EVERY_DAY_VALUE,
  TIME_FORMAT_LT,
} from 'constants/constants';
import { CampaignStatusEnum } from 'constants/enums/campaign';
import { regulars } from 'constants/regular/regulars';
import { ActionDescriptionTextEnum, StatusBoxText } from 'constants/text/text';
import _ from 'lodash';
import moment from 'moment';
import { INewCampaign, TNullable } from 'types';

export const createTableDates = (
  startDate: TNullable<Date>,
  endDate: TNullable<Date>,
): string =>
  `${moment(startDate).format(DEFAULT_DATE_FORMAT_REVERSE_SHORT)} - ${moment(
    endDate,
  ).format(DEFAULT_DATE_FORMAT_REVERSE)}`;

export const createTableTimes = (
  startTime: Date | null,
  endTime: Date | null,
): string => {
  const times = `${moment(startTime).format(TIME_FORMAT_LT)} - ${moment(
    endTime,
  ).format(TIME_FORMAT_LT)}`;

  return times;
};

export const createTableDays = (
  selected: string[],
  allDays: string[],
): string => {
  if (_.isEqual(selected, allDays)) {
    return EVERY_DAY_VALUE;
  }
  if (selected.length === 1) {
    const [day] = selected;
    const currentDay = DAYS_OF_WEEK?.find((el) => el.value === day);
    const currentDayLabel = currentDay?.label;

    return currentDayLabel || day;
  }
  const daysLabel = selected.map((el) => {
    const currentDay = DAYS_OF_WEEK?.find((dayItem) => dayItem.value === el);
    const currentDayLabel = currentDay?.label;

    return currentDayLabel || el;
  });

  return daysLabel.join(', ');
};

export const getFilesType = (type: string): string => {
  const [, typeLabel] = type.split('/');

  return typeLabel;
};

export const fileSizeToMb = (size: number): string =>
  (size / (1024 * 1024)).toFixed(2);

export const getHumanDate = (date: string | undefined): string =>
  moment(date).format(DATE_WITH_DAY_OF_WEEK_AND_TIME);

export const lineConstructor = (
  type: ActionDescriptionTextEnum,
  date?: string,
  author?: string,
): string => `${type} ${getHumanDate(date)} ${StatusBoxText.by} ${author}`;

export const checkIsCampaignNameValid = (str: string): boolean =>
  !regulars.campaignNameReg.test(str);

export const checkIsDraftDisabled = (Campaign: INewCampaign): boolean =>
  !(
    Boolean(Campaign.name) &&
    checkIsCampaignNameValid(Campaign.name) &&
    Campaign.typeId &&
    Campaign.advertiserId &&
    Campaign.categoryId &&
    ((Campaign.typeId === 1 && Campaign.productId) || Campaign.typeId !== 1)
  );

const startFirstWord = 0;
const startSecondWord = 7;

export const convertStatusName = (statusName: string): string => {
  return statusName === CampaignStatusEnum.PENDING_APPROVAL
    ? `${statusName.slice(startFirstWord, startSecondWord)} ${statusName.slice(
        startSecondWord,
        statusName.length,
      )}`
    : statusName;
};
