import { ClassNameMap, makeStyles } from '@mui/styles';

import { colors } from '../../constants/colors/colors';

interface ILoadingHook {
  classes: ClassNameMap<
    | 'root'
    | 'circle'
    | 'localRoot'
    | 'localCircle'
    | 'iconCircleXs'
    | 'iconCircleXm'
    | 'iconCircleXl'
  >;
}

const useLoadingHook = (): ILoadingHook => {
  const useStyles = makeStyles(() => ({
    root: {
      position: 'absolute',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      width: '100%',
      height: '100vh',
      alignItems: 'center',
    },
    circle: {
      color: colors.Cerulean,
    },
    localRoot: {
      height: 'fit-content',
      margin: 'auto',
    },
    localCircle: {
      color: colors.Cerulean,
    },
    iconCircleXs: {
      color: colors.Cerulean,
      width: '14px !important',
      height: '14px !important',
    },
    iconCircleXm: {
      color: colors.Cerulean,
      width: '18px !important',
      height: '18px !important',
    },
    iconCircleXl: {
      color: colors.Cerulean,
      width: '22px !important',
      height: '22px !important',
    },
  }));

  const classes = useStyles();

  return { classes };
};

export default useLoadingHook;
