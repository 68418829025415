import { Button } from 'components/common';
import { colors } from 'constants/colors/colors';
import { withStyles } from 'tss-react/mui';

const SAddButton = withStyles(Button, {
  root: {
    textTransform: 'unset',
    padding: 8,
    fontSize: 14,
    color: colors.Cerulean,
  },
});

export { SAddButton };
