import { FileTypesEnum } from 'constants/enums';
import { millisecondsToSeconds } from 'date-fns';
import {
  checkResolution,
  getDimensionFromImage,
  getIsValidFileFormat,
  getMetaDataFromVideo,
  getSrcFromImage,
  getVideoImage,
} from 'helpers';
import { createObjectLoadFile } from 'helpers/convert/file';
import { useActions, useTypedSelector } from 'hooks';
import { useSnackbar } from 'notistack';
import { useCallback } from 'react';

import { AlertStatusesEnum } from '../../../../../../../../../../../../../constants/constants';

interface IAddMediaModalDropzoneHookReturn {
  onDrop: (acceptedFiles: File[]) => void;
}

const useAddMediaModalDropzone = (): IAddMediaModalDropzoneHookReturn => {
  const { campaignSchedule, campaignSite } = useTypedSelector(
    (state) => state && state.manageCampaign.manage.addMediaModal,
  );
  const { addFileToLoad, setLoaderFileSrc } = useActions();
  const { enqueueSnackbar } = useSnackbar();
  const showSnackbar = (message: string) =>
    enqueueSnackbar(message, {
      variant: AlertStatusesEnum.ERROR,
    });
  const isCampaignSite = !!campaignSite;
  const isCampaignSchedule = !!campaignSchedule;

  const onDrop: IAddMediaModalDropzoneHookReturn['onDrop'] = useCallback(
    (files) => {
      if (isCampaignSite && isCampaignSchedule) {
        files.forEach((file) => {
          const fileScheme = createObjectLoadFile(file);
          const isValidFileFormat = getIsValidFileFormat(
            file,
            campaignSite.formats,
          );
          if (!isValidFileFormat) {
            showSnackbar(
              `Incorrect file format ${fileScheme.name}: ${fileScheme.fileFormat}`,
            );

            return;
          }
          const [siteWidth, siteHeight] = campaignSite.screenSize
            .split('x')
            .map(Number);

          if (fileScheme.fileType === FileTypesEnum.IMAGE) {
            getDimensionFromImage(file, (dimension) => {
              const isResolutionOk = checkResolution(
                { width: siteWidth, height: siteHeight },
                { width: dimension.width, height: dimension.height },
                5,
              );

              if (isResolutionOk) {
                addFileToLoad(fileScheme);
                getSrcFromImage(file, (src) => {
                  setLoaderFileSrc({ id: fileScheme.id, src });
                });

                return;
              }
              showSnackbar(`Incorrect image resolution ${fileScheme.name}`);
            });

            return;
          }

          if (fileScheme.fileType === FileTypesEnum.VIDEO) {
            getMetaDataFromVideo(file, (metadata) => {
              const isResolutionOk = checkResolution(
                { width: siteWidth, height: siteHeight },
                { width: metadata.width, height: metadata.height },
                5,
              );

              if (!isResolutionOk) {
                showSnackbar(`Incorrect video resolution ${fileScheme.name}`);

                return;
              }

              const { duration } = campaignSchedule;
              const durationsOnSeconds = millisecondsToSeconds(duration);

              const isDurationOk =
                metadata.duration < durationsOnSeconds + 1 &&
                metadata.duration > durationsOnSeconds - 1;

              if (!isDurationOk) {
                showSnackbar(
                  `Wrong duration of video ${fileScheme.name}: [${Math.round(
                    metadata.duration,
                  )}s]`,
                );

                return;
              }

              addFileToLoad(fileScheme);
              getVideoImage(file, (src) => {
                setLoaderFileSrc({ id: fileScheme.id, src });
              });
            });
          }
        });
      }
    },
    [],
  );

  return { onDrop };
};

export default useAddMediaModalDropzone;
