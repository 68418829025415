import { Dispatch } from 'redux';

import {
  IAdvertisersAllDto,
  IAdvertisersNewDto,
  TAdvertiserOmit,
} from '../../../types';
import {
  AdvertiserActionTypesEnum,
  TAdvertiserAction,
} from '../../ActionTypes/advertiserTypes/advertisersTypes';
import { allAdvertisers, newAdvertisers } from './advertiserApi';
import { INewAdvertiser } from './type';

export const getAdvertisers =
  () =>
  async (dispatch: Dispatch<TAdvertiserAction>): Promise<void> => {
    try {
      dispatch({ type: AdvertiserActionTypesEnum.GET_ADVERTISERS_LOADING });
      const { data } = await allAdvertisers<IAdvertisersAllDto>();
      dispatch({
        type: AdvertiserActionTypesEnum.GET_ADVERTISERS_SUCCESS,
        payload: data.data,
      });
    } catch (error: any) {
      dispatch({
        type: AdvertiserActionTypesEnum.GET_ADVERTISERS_ERROR,
        payload: error as any,
      });
    }
  };

export const postAdvertiser =
  (
    advertiser: INewAdvertiser,
    callBack: (value: TAdvertiserOmit | null) => void,
  ) =>
  async (dispatch: Dispatch<TAdvertiserAction>): Promise<void> => {
    try {
      dispatch({ type: AdvertiserActionTypesEnum.POST_ADVERTISERS_LOADING });
      const {
        data: { data },
      } = await newAdvertisers<IAdvertisersNewDto>(advertiser);
      callBack(data);
      dispatch({
        type: AdvertiserActionTypesEnum.POST_ADVERTISERS_SUCCESS,
        payload: data,
      });
      dispatch({ type: AdvertiserActionTypesEnum.GET_ADVERTISERS_LOADING });
      const { data: advertiserList } =
        await allAdvertisers<IAdvertisersAllDto>();
      dispatch({
        type: AdvertiserActionTypesEnum.GET_ADVERTISERS_SUCCESS,
        payload: advertiserList.data,
      });
    } catch (error: any) {
      dispatch({
        type: AdvertiserActionTypesEnum.POST_ADVERTISERS_ERROR,
        payload: error as any,
      });
    }
  };
