import { TCurrentWindowParams } from '../../Reducers/appReducer/types';

export enum AppActionTypesEnum {
  SWITCH_WINDOW_PARAM = 'SWITCH_WINDOW_PARAM',
}

interface ISwitchWindowParam {
  type: AppActionTypesEnum.SWITCH_WINDOW_PARAM;
  payload: Readonly<TCurrentWindowParams>;
}

export type TAppAction = ISwitchWindowParam;
