export enum DatePickerModeEnum {
  DEFAULT = 'default',
  SINGLE = 'single',
  RANGE = 'range',
  MULTIPLE = 'multiple',
}

export enum MonthTogglerEnum {
  ONE = 1,
  TWO = 2,
  THREE = 3,
}

export enum CurrentFocusEnum {
  START = 'start',
  END = 'end',
}
