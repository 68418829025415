export enum SizesEnum {
  'xxs' = 10,
  'xs' = 12,
  's' = 14,
  'm' = 16,
  'l' = 18,
  'xl' = 20,
  'xxl' = 22,
  '2xs' = 24,
  '2s' = 28,
  '2m' = 32,
  '2l' = 36,
  '2xl' = 40,
  '2xxl' = 44,
}

type TArea = {
  width: number;
  height: number;
};

type TIconSizes = Record<string, TArea>;

export const getObjectSizes = (): TIconSizes => {
  return Object.entries(SizesEnum)
    .filter((elem) => Number.isNaN(Number(elem[0])))
    .reduce<TIconSizes>((acc, [key, value]) => {
      acc[`&.icon-${key}`] = { width: Number(value), height: Number(value) };

      return acc;
    }, {});
};
