import { UploadIcon } from 'assets/icons';
import SvgIcon from 'components/common/SvgIcon/SvgIcon';
import { FileLoadStatusesEnum } from 'constants/enums';
import { convertFromMillisecondsToHumanFormat } from 'helpers';
import { useActions, useTypedSelector } from 'hooks';
import { useSnackbar } from 'notistack';
import React, { useEffect } from 'react';
import { useDropzone } from 'react-dropzone';

import { useAddMediaModalDropzone } from '../../hooks';

const Dropzone: React.FC = () => {
  const { campaignSchedule, campaignSite, mediaStorage, isBulk } =
    useTypedSelector(
      (state) => state && state.manageCampaign.manage.addMediaModal,
    );
  const {
    changeFileLoadStatus,
    uploadMediaManageCampaign,
    uploadMediaManageCampaignBulk,
    changeLoadFileProgress,
  } = useActions();

  const { onDrop } = useAddMediaModalDropzone();

  const { getRootProps, getInputProps } = useDropzone({ onDrop, noDrag: true });

  const { loaderStore } = mediaStorage;
  const { enqueueSnackbar } = useSnackbar();
  const isFileFormats = Boolean(campaignSite?.formats);
  const isDimension = Boolean(campaignSite?.screenSize);
  const isDuration = Boolean(campaignSchedule?.duration);

  useEffect(() => {
    if (loaderStore.length) {
      loaderStore.forEach((file) => {
        if (file.status === FileLoadStatusesEnum.ADDED) {
          if (isBulk) {
            uploadMediaManageCampaignBulk(
              file,
              changeLoadFileProgress,
              enqueueSnackbar,
            );
          } else {
            uploadMediaManageCampaign(
              file,
              changeLoadFileProgress,
              enqueueSnackbar,
            );
          }
          changeFileLoadStatus({
            id: file.id,
            status: FileLoadStatusesEnum.LOADED,
          });
        }
      });
    }
  }, [loaderStore.length]);

  return (
    <div {...getRootProps({ className: 'inner-add-media-modal-dropzone' })}>
      <input {...getInputProps()} />
      <div className="dropzone-wrapper">
        <div className="dropzone-icon-wrapper">
          <SvgIcon sx={{ width: 60, height: 60 }}>
            <UploadIcon />
          </SvgIcon>
        </div>

        <div className="dropzone-info-wrapper">
          {isFileFormats && (
            <div>Accepted file formats: {campaignSite?.formats}</div>
          )}
          {isDimension && <div>Dimension: {campaignSite?.screenSize}</div>}
          {isDuration && (
            <div>
              Duration (For video):
              {convertFromMillisecondsToHumanFormat(campaignSchedule?.duration)}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Dropzone;
