import './TableBodyLoading.scss';

import { Skeleton } from '@mui/material';
import React from 'react';

interface ITableBodyLoading {
  readonly rowsNumber: number;
  readonly columnsNumber: number;
}

const TableBodyLoading: React.FC<ITableBodyLoading> = ({
  rowsNumber,
  columnsNumber,
}) => {
  return (
    <tbody className="__ table-body __table_body-loading">
      {Array.from({ length: rowsNumber }).map((_rowValue, rowIndex) => (
        <tr
          key={`row-${rowIndex}`}
          className={`__table_row __table_row-body __table_row-loading __row-${rowIndex} __row-body-${rowIndex} __row-body-loading-${rowIndex}`}
        >
          {Array.from({ length: columnsNumber }).map(
            (_columnValue, columnIndex) => (
              <td
                key={`column-${columnIndex}`}
                style={{ padding: '0 10px' }}
                className={`__table_cell __table_cell-body __table_cell-loading __cell-${rowIndex}-${columnIndex} __cell-body-${rowIndex}-${columnIndex} __cell-body-loading-${rowIndex}-${columnIndex}`}
              >
                <Skeleton variant="rectangular" width="100%" height={30} />
              </td>
            ),
          )}
        </tr>
      ))}
    </tbody>
  );
};

export default TableBodyLoading;
