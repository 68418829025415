import moment from 'moment';
import { ISiteDayPartsNew } from 'types';

import { momentFormats } from '../../../constants/date/date';
import { TAntRangeValue } from '../../../types/date';

export const getDayPartFromDuration = (
  dayParts: ISiteDayPartsNew[],
  duration: number | undefined,
): ISiteDayPartsNew => {
  if (!duration) {
    return dayParts[0];
  }

  const lookingDayPart = dayParts.find(
    (dayPart) => dayPart.adLength === duration,
  );

  if (!lookingDayPart) {
    return dayParts[0];
  }

  return lookingDayPart;
};

export const getDefaultScheduleTime = (
  startTime?: string,
  endTime?: string,
): TAntRangeValue => {
  const startTimeValue = startTime || '00:00:00';
  const endTimeValue = endTime || '23:59:00';

  return [
    moment(startTimeValue, momentFormats.time().fullTime.default),
    moment(endTimeValue, momentFormats.time().fullTime.default),
  ];
};
