import { regulars } from 'constants/regular/regulars';
import * as yup from 'yup';

import { messages, texts } from '../../../constants';

const minInputLength = {
  4: 4,
} as const;

const maxInputLength = {
  7: 7,
  24: 24,
} as const;

const stringSchema = yup
  .string()
  .required(messages.required)
  .max(maxInputLength[24], messages.max(maxInputLength[24]))
  .test(
    'Not contain forbidden Symbol',
    messages.forbiddenSymbols,
    (value) => !regulars.forbiddenSymbol.test(value || ''),
  );

export const validationSchemaCentre = yup.object().shape({
  [texts.centre.formName]: stringSchema.min(
    minInputLength[4],
    messages.min(minInputLength[4]),
  ),
  state: yup.object().required().nullable(true),
});

export const validationSchemaNetwork = yup.object().shape({
  [texts.network.name]: stringSchema,
});

export const validationSchemaOrientation = yup.object().shape({
  [texts.orientation.name]: stringSchema,
});

export const validationSchemaResolution = yup.object().shape({
  width: yup
    .string()
    .required(messages.required)
    .max(maxInputLength[7], messages.max(maxInputLength[7]))
    .matches(regulars.onlyNumber, messages.onlyNumber),
  height: yup
    .string()
    .required(messages.required)
    .max(maxInputLength[7], messages.max(maxInputLength[7]))
    .matches(regulars.onlyNumber, messages.onlyNumber),
});

export const validationSchemaSuborganisation = yup.object().shape({
  [texts.suborganisation.name]: stringSchema,
});
