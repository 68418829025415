import './NoSite.scss';

import { AddCircleOutline as AddCircleOutlineIcon } from '@mui/icons-material';
import { Button } from 'components/common';
import { useActions } from 'hooks';
import React from 'react';

import { helperMessages } from '../../../../../../../../../../../constants/messages';
import { STypography } from './NoSite.styled';

const NoSites: React.FC = () => {
  const { setModalsManageTab } = useActions();

  const handleOpenAddSiteModal = () => {
    setModalsManageTab({ type: 'addSites', value: true });
  };

  return (
    <div className="no-site-container">
      <STypography variant="h5" component="p">
        {helperMessages.noSiteTitle}
      </STypography>
      <Button
        label="Add Site"
        icon={<AddCircleOutlineIcon />}
        onClick={handleOpenAddSiteModal}
      />
    </div>
  );
};

export default NoSites;
