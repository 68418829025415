import { Button } from '@mui/material';
import { withStyles } from 'tss-react/mui';

import { colors } from '../../../constants/colors/colors';

const SButton = withStyles(Button, () => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    height: 42,
    padding: '0 20px',
    fontWeight: 900,
    lineHeight: 0,
    letterSpacing: '1.6px',
    borderRadius: 8,
    color: 'white',
    alignContent: 'flex-end',
    fontSize: 16,

    '&.without-shadow': {
      boxShadow: 'none ',
    },

    '&.dangerous-button': {
      backgroundColor: 'rgba(255, 0, 0, 0.84)',

      '&:hover': {
        backgroundColor: 'rgba(231, 15, 15, 0.84)',
      },
    },

    '&.successfully': {
      backgroundColor: colors.Emerald,

      '&:hover': {
        backgroundColor: colors.DarkEmerald,
      },
    },
  },

  containedPrimary: {
    opacity: 1,
    background: colors.Cerulean,
    boxShadow: `0 3px 10px ${colors.Shadow}`,

    '&:hover': {
      background: '#0091f1 0% 0% no-repeat padding-box',
      boxShadow: `0 3px 10px ${colors.Shadow}`,

      transition: 'all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1) 0s',
    },

    '&:active': {
      background: '#0277c5 0% 0% no-repeat padding-box',
      boxShadow: `0 3px 10px ${colors.Shadow}`,
    },
  },

  textPrimary: {
    color: colors.Cerulean,

    '&:hover': {
      background: 'none',

      transition: 'all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1) 0s',
    },
  },

  containedSecondary: {
    opacity: 1,
    background: colors.Abbey,
    border: '2px solid #fff',
    boxShadow: `0 3px 10px ${colors.Shadow}`,

    '&:hover': {
      background: colors.Abbey,
      border: `${colors.Cerulean} solid 2px`,
      boxShadow: `0 3px 10px ${colors.Shadow}`,

      transition: 'all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1) 0s',
    },

    '&:active': {
      background: '#0285dc 0% 0% no-repeat padding-box',
      boxShadow: `0 3px 10px ${colors.Shadow}`,
    },
  },

  contained: {
    ':disabled': {
      color: colors.White,

      backgroundColor: '#c7c7c7',
      borderColor: 'rgba(0, 0, 0, 0.6)',
    },
  },

  text: {
    color: colors.White,
  },
}));

export { SButton };
