import {
  Add as AddIcon,
  ArrowForwardIosSharp as ArrowForwardIosSharpIcon,
  Close as CloseIcon,
} from '@mui/icons-material';
import { IconButton, TextField, Typography } from '@mui/material';
import { TimePicker } from 'antd';
import { FieldArray, FieldArrayRenderProps, useFormikContext } from 'formik';
import React, { Fragment } from 'react';

import { momentFormats } from '../../../../../../../../../../constants/date/date';
import { texts } from '../../../../../../../../constants/texts';
import { IInitialValues } from '../../../../../../types';
import {
  SAccordion,
  SAccordionDetails,
  SAccordionSummary,
  SButton,
} from '../../SiteDayParts.styled';
import { FrameManager } from '..';
import useTimeManager from './TimeManager.hook';

interface ITimeManagerProps {
  expand: boolean;
  index: number;
  number: number;
  dayPartArrayHelper: FieldArrayRenderProps;
  handleChangeAccordion: (accordionNumber: number) => void;
}

const { RangePicker } = TimePicker;

const minuteStep = 5;

const TimeManager: React.FC<ITimeManagerProps> = ({
  expand,
  index,
  number,
  dayPartArrayHelper,
  handleChangeAccordion,
}) => {
  const { values, isSubmitting } = useFormikContext<IInitialValues>();

  const {
    isRemoveButtonDisabled,
    secondaryAccordionElementData,
    getIsExpand,
    handleRemoveDayPart,
    handleClickAddFrame,
    handleChangePicker,
    handleChangeFrameAccordion,
    handleDurationChange,
  } = useTimeManager({ index });

  return (
    <SAccordion
      expanded={expand}
      onChange={() => handleChangeAccordion(index)}
      disabled={isSubmitting}
      disableGutters
      elevation={0}
      square
    >
      <SAccordionSummary
        expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
      >
        <Typography sx={{ width: '33%', flexShrink: 0 }}>
          {texts.pages.manage.content.siteDayParts.accordions.dayPart(number)}
        </Typography>
        {secondaryAccordionElementData.visible && (
          <>{secondaryAccordionElementData.element}</>
        )}
        <IconButton
          sx={{
            position: 'absolute',
            right: 0,
            top: 4,
          }}
          onClick={(event) =>
            handleRemoveDayPart(event, dayPartArrayHelper, index)
          }
          disabled={isRemoveButtonDisabled}
        >
          <CloseIcon color={!isRemoveButtonDisabled ? 'error' : 'inherit'} />
        </IconButton>
      </SAccordionSummary>
      <SAccordionDetails>
        <div className="time-duration-wrapper">
          <RangePicker
            use12Hours
            format={momentFormats.time().hoursWithMinutes.use12HoursUpper}
            value={values.siteDayParts[index].rangeTime}
            onChange={(ranges) => handleChangePicker(ranges, index)}
            minuteStep={minuteStep}
            disabled={isSubmitting}
            size="large"
          />
          <TextField
            label={texts.pages.manage.content.siteDayParts.inputs.duration}
            name={`siteDayParts[${index}].duration`}
            onChange={handleDurationChange}
            value={values.siteDayParts[index].duration}
            sx={{ width: 125 }}
          />
        </div>
        <div className="site-frame-wrapper">
          <FieldArray
            name={`siteDayParts[${index}].siteFrames`}
            render={(siteFrameArrayHelper) => (
              <Fragment>
                {values.siteDayParts[index].siteFrames.map(
                  (__, siteFrameIndex) => (
                    <FrameManager
                      expand={getIsExpand(siteFrameIndex)}
                      handleChangeAccordion={handleChangeFrameAccordion}
                      key={siteFrameIndex}
                      number={siteFrameIndex + 1}
                      index={siteFrameIndex}
                      dayPartIndex={index}
                      siteFrameArrayHelper={siteFrameArrayHelper}
                    />
                  ),
                )}
                <SButton
                  startIcon={<AddIcon sx={{ color: 'rgba(0, 0, 0, 0.54)' }} />}
                  color="inherit"
                  fullWidth
                  onClick={() => handleClickAddFrame(siteFrameArrayHelper)}
                >
                  {texts.pages.manage.content.siteDayParts.buttons.addSiteFrame}
                </SButton>
              </Fragment>
            )}
          />
        </div>
      </SAccordionDetails>
    </SAccordion>
  );
};

export default TimeManager;
