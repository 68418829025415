import { TabList } from '@mui/lab';
import { colors } from 'constants/colors/colors';
import { withStyles } from 'tss-react/mui';

const STabsList = withStyles(TabList, () => ({
  root: {
    backgroundColor: colors.White,
    padding: '16px 0',
  },

  scroller: {
    display: 'flex',
    justifyContent: 'center',
  },

  flexContainer: {
    '.MuiButtonBase-root': {
      minHeight: 'unset',
      padding: '8px 16px',
    },
  },

  indicator: {
    height: 3,
  },
}));

export { STabsList };
