import {
  IDayPartsForm,
  IInitialValues,
  ISiteFrameForm,
} from '../shared/SiteForm/types';

export const initialSiteFrame: Readonly<ISiteFrameForm> = {
  id: undefined,
  name: undefined,
  frameId: undefined,
};

export const initialDayPart: Readonly<IDayPartsForm> = {
  rangeTime: undefined,
  duration: undefined,
  siteFrames: [initialSiteFrame],
};

export const initialValues: Readonly<IInitialValues> = {
  name: '',
  address: '',
  broadsignId: undefined,
  orientation: undefined,
  centre: undefined,
  network: undefined,
  state: undefined,
  formats: undefined,
  resolution: undefined,
  siteDayParts: [initialDayPart],
  isUpdate: false,
};
