import { IObjectWithId } from '../../types';

const excludeArraysForId = <T extends IObjectWithId, U extends IObjectWithId>(
  array1: T[],
  array2: U[],
): T[] => {
  return array1.filter((item) => {
    return !array2.some((subItem) => {
      return item.id === subItem.id;
    });
  });
};

const arrayHaveSameId = <T extends IObjectWithId, U extends IObjectWithId>(
  array1: T[],
  array2: U[],
): boolean => {
  return array1.every((item) => {
    return array2.some((subItem) => item.id === subItem.id);
  });
};

export { arrayHaveSameId, excludeArraysForId };
