import { Box, Button, MenuItem } from '@mui/material';
import { colors } from 'constants/colors/colors';
import { withStyles } from 'tss-react/mui';

const SFileListWrapper = withStyles(Button, {
  root: {
    width: 60,
    height: 60,
    position: 'relative',
    borderRadius: 4,
    boxShadow: '0 0 10px 0 #00000030',
    padding: 0,

    '&.success': {
      border: `2px solid ${colors.Emerald}`,
      cursor: 'pointer',
    },

    '&.error': {
      border: `2px solid ${colors.Red}`,
      cursor: 'pointer',
    },
  },
});

const SFileIconNotUrl = withStyles(Box, {
  root: {
    border: '1px solid black',
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

const SMenuItem = withStyles(MenuItem, {
  root: {
    color: colors.Cerulean,

    '&.red-item': {
      color: colors.Red,
    },
  },
});

export { SFileIconNotUrl, SFileListWrapper, SMenuItem };
