import { Chip, Typography } from '@mui/material';
import { useFormikContext } from 'formik';
import moment from 'moment';
import React from 'react';

import { momentFormats } from '../../../../../../../../../../../../constants/date/date';
import { IInitialValues } from '../../../../../../../../types';

interface ISecondaryTextProps {
  readonly isStartTime: boolean;
  readonly isEndTime: boolean;
  readonly isRangeTime: boolean;
  readonly isDuration: boolean;
  readonly isSiteFrames: boolean;
  readonly index: number;
}

const { use12HoursUpper } = momentFormats.time().hoursWithMinutes;

const SecondaryText: React.FC<ISecondaryTextProps> = ({
  isStartTime,
  isEndTime,
  isRangeTime,
  isDuration,
  isSiteFrames,
  index,
}) => {
  const {
    values: { siteDayParts },
  } = useFormikContext<IInitialValues>();

  const isComma = (isStartTime || isEndTime) && isDuration;

  return (
    <div className="secondary-accordion-text">
      <Typography sx={{ color: 'text.secondary' }}>
        {isStartTime &&
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          moment(siteDayParts[index].rangeTime![0]).format(use12HoursUpper)}
        {isRangeTime && ' - '}
        {isEndTime &&
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          moment(siteDayParts[index].rangeTime![1]).format(use12HoursUpper)}
        {isComma && ' , '}
      </Typography>
      <Typography sx={{ color: 'text.secondary' }}>
        {isDuration && `${siteDayParts[index].duration}s`}
      </Typography>

      {isSiteFrames && (
        <Chip
          size="small"
          label={`Frames: ${siteDayParts[index].siteFrames.length}`}
        />
      )}
    </div>
  );
};

export default SecondaryText;
