import './PreviewModal.scss';

import { Clear as ClearIcon } from '@mui/icons-material';
import { Fade, Modal } from '@mui/material';
import React, { useState } from 'react';
import ReactPlayer from 'react-player';
import { makeStyles } from 'tss-react/mui';

import {
  MEDIA_TYPES,
  VIDEO_PLAYER_STYLES,
} from '../../../../constants/constants';
import { IPreviewMediaData } from '../../../../types';
import IconButton from '../../Button/components/IconButton';

const useStyles = makeStyles()(() => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

interface IPreviewMediaModalProps {
  open: boolean;
  previewMediaUrl: IPreviewMediaData;
  handleClose: () => void;
}

const PreviewMediaModal: React.FC<IPreviewMediaModalProps> = ({
  open,
  handleClose,
  previewMediaUrl,
}) => {
  const { classes } = useStyles();

  const [isPlaying, setIsPlaying] = useState<boolean>(false);

  const { url, type } = previewMediaUrl;

  const handlePlay = () => {
    setIsPlaying(!isPlaying);
  };

  const isVideo = type === MEDIA_TYPES.video;

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={open}
      onClose={handleClose}
      closeAfterTransition
    >
      <div className="preview-modal-container">
        <Fade style={{ maxHeight: 'inherit' }} in={open}>
          <div className="preview-inner-modal-container">
            <IconButton
              onClick={handleClose}
              className="close-modal-button active-icon"
              icon={<ClearIcon />}
            />
            <div className="preview-content-wrapper">
              {isVideo && url && (
                <button className="play-button" onClick={handlePlay}>
                  <div>
                    <ReactPlayer
                      className="video-player"
                      width={VIDEO_PLAYER_STYLES.width}
                      height={VIDEO_PLAYER_STYLES.height}
                      controls
                      url={url}
                      playing={isPlaying}
                    />
                  </div>
                </button>
              )}
              {!isVideo && url && (
                <img className="preview-img" alt="media-preview" src={url} />
              )}
            </div>
          </div>
        </Fade>
      </div>
    </Modal>
  );
};

export default PreviewMediaModal;
