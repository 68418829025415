import './CampaignContent.scss';

import BackdropWithLoader from 'components/common/Backdrop';
import { useActions, useTypedSelector } from 'hooks';
import React, { useEffect } from 'react';

import { CampaignHeader } from '..';
import { Content, HeaderButtons } from './components';

interface ICampaignForm {
  title: string;
}

const CampaignForm: React.FC<ICampaignForm> = ({ title }) => {
  const { loadings } = useTypedSelector(
    (state) => state && state.manageCampaign.main,
  );
  const { modals } = useTypedSelector(
    (state) => state && state.manageCampaign.detail,
  );

  const { cleanupAllManageCampaignData } = useActions();

  useEffect(() => {
    return () => {
      cleanupAllManageCampaignData();
    };
  }, []);

  const isAllModalClosed = Object.values(modals).every(
    (modalState) => !modalState,
  );

  const isShowGlobalLoading = Boolean(
    isAllModalClosed &&
      (loadings.getOne ||
        loadings.saveAsDraft ||
        loadings.publish ||
        loadings.update ||
        loadings.decline ||
        loadings.approve),
  );

  return (
    <div className="campaign-wrapper">
      <CampaignHeader title={title}>
        <HeaderButtons />
      </CampaignHeader>
      <Content />
      <BackdropWithLoader open={isShowGlobalLoading} />
    </div>
  );
};

export default CampaignForm;
