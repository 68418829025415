import { Dispatch } from 'redux';
import { IDefaultDto, IDimensionObject, IObjectIdWithName } from 'types';

import { ResolutionActionEnum, TResolutionActions } from '../../../ActionTypes';
import {
  createOrientationApi,
  createResolutionApi,
  getResolutionsApi,
} from './resolutionApi';

export const getResolutions =
  () =>
  async (dispatch: Dispatch<TResolutionActions>): Promise<void> => {
    try {
      dispatch({
        type: ResolutionActionEnum.SET_LOADING,
        payload: { type: 'getAll', value: true },
      });
      const {
        data: { data },
      } = await getResolutionsApi<IDefaultDto<IObjectIdWithName[]>>();

      dispatch({ type: ResolutionActionEnum.SET_RESOLUTIONS, payload: data });
    } catch (error) {
      console.error(error);
    } finally {
      dispatch({
        type: ResolutionActionEnum.SET_LOADING,
        payload: { type: 'getAll', value: false },
      });
    }
  };

export const createResolution =
  (resolution: IDimensionObject) =>
  async (dispatch: Dispatch<TResolutionActions>): Promise<void> => {
    try {
      dispatch({
        type: ResolutionActionEnum.SET_LOADING,
        payload: { type: 'createOne', value: true },
      });
      await createResolutionApi<IDefaultDto<number>>(resolution);
    } catch (error: any) {
      const message = error?.response?.data?.UserMessage;
      console.error(message);
      throw new Error(error);
    } finally {
      dispatch({
        type: ResolutionActionEnum.SET_LOADING,
        payload: { type: 'createOne', value: false },
      });
    }
  };

export const createNewOrientation =
  (name: string) =>
  async (dispatch: Dispatch<TResolutionActions>): Promise<void> => {
    try {
      dispatch({
        type: ResolutionActionEnum.SET_LOADING,
        payload: { type: 'createOrientation', value: true },
      });
      await createOrientationApi({ name });
    } catch (error: any) {
      const message = error?.response?.data?.UserMessage || error?.message;

      console.error(message);
      throw new Error(error);
    } finally {
      dispatch({
        type: ResolutionActionEnum.SET_LOADING,
        payload: { type: 'createOrientation', value: false },
      });
    }
  };
