import {
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { withStyles } from 'tss-react/mui';

import { colors } from '../../../../constants/colors/colors';

const SListItem = withStyles(ListItem, () => ({
  root: {
    padding: 0,
  },
}));

const SListItemButton = withStyles(ListItemButton, () => ({
  root: {
    height: 72,
    transition: '.5s ease',

    '&:hover': {
      backgroundColor: colors.Cerulean,
    },

    '&.active': {
      backgroundColor: colors.Cerulean,
    },
  },
}));

const SListItemIcon = withStyles(ListItemIcon, () => ({
  root: {
    minWidth: 36,

    svg: {
      color: colors.White,
    },
  },
}));

const SListItemText = withStyles(ListItemText, () => ({
  root: {
    textTransform: 'uppercase',
    color: colors.White,

    '.MuiTypography-root': {
      fontWeight: 600,
      letterSpacing: '1px',
      fontSize: 16,
    },
  },
}));

export { SListItem, SListItemButton, SListItemIcon, SListItemText };
