import { useState } from 'react';

export interface IOrderingHookReturn {
  readonly isOrderingPopoverOpen: boolean;
  readonly orderingPopoverOpen: () => void;
  readonly orderingPopoverClose: () => void;
}

const useOrdering = (): IOrderingHookReturn => {
  const [isOrderingPopoverOpen, setIsOrderingPopoverOpen] =
    useState<boolean>(false);

  const orderingPopoverOpen = () => setIsOrderingPopoverOpen(true);

  const orderingPopoverClose = () => setIsOrderingPopoverOpen(false);

  return { isOrderingPopoverOpen, orderingPopoverOpen, orderingPopoverClose };
};

export default useOrdering;
