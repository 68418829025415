import { AlertStatusesEnum } from 'constants/constants';
import { urls } from 'constants/urls/url';
import { radix } from 'constants/variables';
import { FormikHelpers } from 'formik';
import { useActions } from 'hooks';
import { useSnackbar } from 'notistack';
import { useNavigate, useParams } from 'react-router-dom';

import { messages } from '../../constants/texts';
import {
  dayPartsInspector,
  incorrectDayPartsInspector,
} from '../../helpers/inspectors';
import { createSchemeToQuery } from '../../helpers/site';
import { IInitialValues } from './types';

interface ISiteFromHookReturn {
  onSubmit: (
    values: IInitialValues,
    formHelper: FormikHelpers<IInitialValues>,
  ) => Promise<void>;
  handleSubmit: (values: IInitialValues) => Promise<void>;
  handleClickGoBack: () => void;
}

const useSiteForm = (): ISiteFromHookReturn => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { setManageSiteModal, createSite, updateSite, cleanupCurrentSite } =
    useActions();

  const { id } = useParams<{ id: string }>();

  const handleClickGoBack = (): void => {
    navigate(urls.sites);
  };

  const handleSubmit = async (values: IInitialValues): Promise<void> => {
    try {
      const siteScheme = createSchemeToQuery(values);

      if (values.isUpdate) {
        if (parseInt(id || '0', radix[10])) {
          await updateSite(parseInt(id || '0', radix[10]), siteScheme);
        }
      } else {
        await createSite(siteScheme);
      }

      cleanupCurrentSite();

      navigate(urls.sites);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      enqueueSnackbar(error?.response?.data?.UserMessage, {
        variant: AlertStatusesEnum.ERROR,
      });
    }
  };

  const onSubmit = async (
    values: IInitialValues,
    formHelper: FormikHelpers<IInitialValues>,
  ): Promise<void> => {
    const { setSubmitting } = formHelper;
    const hasCorrectDayPart = dayPartsInspector(values.siteDayParts);

    const hasIncorrectDayParts = incorrectDayPartsInspector(
      values.siteDayParts,
    );

    if (hasCorrectDayPart) {
      if (hasIncorrectDayParts) {
        setManageSiteModal({ type: 'hasIncorrectDayParts', value: true });

        return;
      }

      await handleSubmit(values);

      setSubmitting(false);

      return;
    }

    enqueueSnackbar(messages.incorrectDayPartsSector.title, {
      variant: AlertStatusesEnum.ERROR,
    });
    setSubmitting(false);
  };

  return { onSubmit, handleSubmit, handleClickGoBack };
};

export default useSiteForm;
