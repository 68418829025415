import { ViewColumn as ViewColumnIcon } from '@mui/icons-material';
import React from 'react';

import {
  IDefaultActionsInfo,
  TActions,
  TDefaultActionsTypes,
} from '../../../types';
import { ITableActions } from '../TableActions';
import { IOrderingHookReturn } from './ordering.hook';

interface IActionsInfoProps {
  readonly handleOrderingClick: <T>(args: T) => void;
}

interface ITableActionsHookProps<TData> {
  readonly actions: ITableActions<TData>['actions'];
  readonly orderingPopoverOpen: IOrderingHookReturn['orderingPopoverOpen'];
}
interface ITableActionsHookReturn {
  readonly allActions: TActions[];
}

const defaultActionsTypes: Array<TDefaultActionsTypes> = ['ordering'];

const defaultActionsInfoFn = ({
  handleOrderingClick,
}: IActionsInfoProps): Partial<
  Record<TDefaultActionsTypes, IDefaultActionsInfo>
> => ({
  ordering: {
    icon: <ViewColumnIcon />,
    handleClick: handleOrderingClick,
    label: 'Ordering',
  },
});

const useTableActions = <TData,>({
  actions,
  orderingPopoverOpen,
}: ITableActionsHookProps<TData>): ITableActionsHookReturn => {
  const defaultActionsInfo = defaultActionsInfoFn({
    handleOrderingClick: orderingPopoverOpen,
  });

  const allActions = actions?.length
    ? actions.map((item) => {
        if (item.type && defaultActionsTypes.includes(item.type)) {
          return { ...item, ...defaultActionsInfo[item.type] };
        }

        return item;
      })
    : [];

  return { allActions };
};

export default useTableActions;
