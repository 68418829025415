import { IObjectIdWithName } from 'types';

export interface IProductState {
  products: IObjectIdWithName[];
  hasErrorProductsLoading: boolean;
  isLoadingProducts: boolean;
  isLoadingPostProduct: boolean;
  hasErrorPostProduct: boolean;
}

export enum ProductActionTypesEnum {
  GET_PRODUCTS_LOADING = 'GET_PRODUCTS_LOADING',
  GET_PRODUCTS_SUCCESS = 'GET_PRODUCTS_SUCCESS',
  GET_PRODUCTS_ERROR = 'GET_PRODUCTS_ERROR',
  POST_PRODUCTS_LOADING = 'POST_PRODUCTS_LOADING',
  POST_PRODUCTS_SUCCESS = 'POST_PRODUCTS_SUCCESS',
  POST_PRODUCTS_ERROR = 'POST_PRODUCTS_ERROR',
  POST_PRODUCTS_CACHE_LOADING = 'POST_PRODUCTS_CACHE_LOADING',
  POST_PRODUCTS_CACHE_SUCCESS = 'POST_PRODUCTS_CACHE_SUCCESS',
  POST_PRODUCTS_CACHE_ERROR = 'POST_PRODUCTS_CACHE_ERROR',
}

interface IGetProductLoadingAction {
  type: ProductActionTypesEnum.GET_PRODUCTS_LOADING;
}
interface IGetProductSuccessAction {
  type: ProductActionTypesEnum.GET_PRODUCTS_SUCCESS;
  payload: IObjectIdWithName[];
}
interface IGetProductErrorAction {
  type: ProductActionTypesEnum.GET_PRODUCTS_ERROR;
  payload: boolean;
}
interface IPostProductLoadingAction {
  type: ProductActionTypesEnum.POST_PRODUCTS_LOADING;
}
interface IPostProductSuccessAction {
  type: ProductActionTypesEnum.POST_PRODUCTS_SUCCESS;
}
interface IPostProductErrorAction {
  type: ProductActionTypesEnum.POST_PRODUCTS_ERROR;
  payload: boolean;
}

interface IPostProductCacheLoadingAction {
  type: ProductActionTypesEnum.POST_PRODUCTS_CACHE_LOADING;
}
interface IPostProductCacheSuccessAction {
  type: ProductActionTypesEnum.POST_PRODUCTS_CACHE_SUCCESS;
}
interface IPostProductCacheErrorAction {
  type: ProductActionTypesEnum.POST_PRODUCTS_CACHE_ERROR;
  payload: boolean;
}

export type TProductAction =
  | IGetProductLoadingAction
  | IGetProductSuccessAction
  | IGetProductErrorAction
  | IPostProductLoadingAction
  | IPostProductSuccessAction
  | IPostProductErrorAction
  | IPostProductCacheLoadingAction
  | IPostProductCacheSuccessAction
  | IPostProductCacheErrorAction;
