import './NewCheckbox.scss';

import {
  Checkbox as MuiCheckbox,
  CheckboxProps,
  FormControl,
  FormControlLabel,
  FormControlLabelProps,
  FormControlTypeMap,
  FormLabel,
  FormLabelTypeMap,
} from '@mui/material';
import React, { InputHTMLAttributes } from 'react';

interface ICheckbox extends CheckboxProps {
  label?: string;
  isFormLabel?: boolean;
  formControlProps?: FormControlTypeMap;
  labelProps?: FormLabelTypeMap;
  controlLabelProps?: FormControlLabelProps;
  inputProps?: InputHTMLAttributes<HTMLInputElement>;
  CheckboxComponent?: (props: CheckboxProps) => JSX.Element;
}
const Checkbox: React.FC<ICheckbox> = ({
  label,
  isFormLabel = false,
  formControlProps,
  labelProps,
  controlLabelProps,
  inputProps,
  CheckboxComponent,
  ...checkboxProps
}) => {
  return (
    <FormControl {...formControlProps}>
      {isFormLabel && <FormLabel {...labelProps}>{label}</FormLabel>}
      <FormControlLabel
        control={
          CheckboxComponent ? (
            <CheckboxComponent {...checkboxProps} />
          ) : (
            <MuiCheckbox
              sx={{
                '&:hover': { bgcolor: 'transparent' },
              }}
              disableRipple
              color="default"
              checkedIcon={<span className="checkbox-icon checkbox-done" />}
              icon={<span className="checkbox-icon" />}
              indeterminateIcon={
                <span className="checkbox-icon checkbox-indeterminate" />
              }
              inputProps={{ 'aria-label': 'Checkbox demo', ...inputProps }}
              {...checkboxProps}
            />
          )
        }
        label={label}
        {...controlLabelProps}
      />
    </FormControl>
  );
};

export default Checkbox;
