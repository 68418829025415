import './BulkDelete.scss';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import { ColumnDef } from '@tanstack/react-table';
import { Table } from 'components/common';
import { useActions, useTypedSelector } from 'hooks';
import React, { useMemo } from 'react';
import { IUserShort } from 'types';

import useBulkDelete from './BulkDelete.hook';
import { getColumns } from './columns';

const BulkDelete: React.FC = () => {
  const { modals, loaders, bulkDeleteModalData } = useTypedSelector(
    (state) => state && state.users,
  );

  const { bulkDeleteModalRemoveUser } = useActions();

  const { handleClose, handleDeleteBulk } = useBulkDelete();

  const columns = useMemo<ColumnDef<IUserShort>[]>(
    () =>
      getColumns({
        data: bulkDeleteModalData.selectedUsers,
        handleRemove: bulkDeleteModalRemoveUser,
        removeDisabled: Boolean(loaders.deleteBulk),
      }),
    [bulkDeleteModalData.selectedUsers],
  );

  return (
    <Dialog
      open={Boolean(modals.deleteBulk)}
      onClose={handleClose}
      maxWidth="md"
    >
      <DialogTitle>Are you sure you want to delete these users?</DialogTitle>
      <DialogContent className="bulk-delete-dialog-content">
        <Table
          data={bulkDeleteModalData.selectedUsers}
          columns={columns}
          hasPagination
          isFullWidth
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} disabled={loaders.deleteBulk}>
          Disagree
        </Button>
        <Button
          onClick={() => handleDeleteBulk()}
          disabled={loaders.deleteBulk}
        >
          Agree
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default BulkDelete;
