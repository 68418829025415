import { SelectChangeEvent } from '@mui/material';
import { Dispatch, SetStateAction } from 'react';

import { TSizesPage } from '../../../../../../../types';
import { ITablePagination } from '../TablePagination';

interface ITablePaginationPagesHookProps<TData> {
  readonly table: ITablePagination<TData>['table'];
  readonly totalPages: number;
  readonly isSSR?: ITablePagination<TData>['isSSR'];
  readonly pagination?: ITablePagination<TData>['pagination'];
  readonly handleChangePageSize?: ITablePagination<TData>['handleChangePageSize'];
  readonly handleChangePageNumber?: ITablePagination<TData>['handleChangePageNumber'];
  readonly setPageSize: Dispatch<SetStateAction<TSizesPage>>;
}

interface ITablePaginationPagesHookReturn {
  readonly handlePageSize: (event: SelectChangeEvent<TSizesPage>) => void;
  readonly handleClickFirstPage: () => void;
  readonly handleClickPreviousPage: () => void;
  readonly handleClickNextPage: () => void;
  readonly handleClickLastPage: () => void;
  readonly outputCurrentPage: () => string;
}

const useTablePaginationPages = <TData>({
  table,
  totalPages,
  isSSR,
  pagination,
  handleChangePageSize,
  handleChangePageNumber,
  setPageSize,
}: ITablePaginationPagesHookProps<TData>): ITablePaginationPagesHookReturn => {
  const handlePageSize = ({
    target: { value },
  }: SelectChangeEvent<TSizesPage>): void => {
    if (typeof value === 'string') {
      return;
    }

    if (isSSR && handleChangePageSize) {
      handleChangePageSize(value);
    } else {
      setPageSize(value);
    }
    table.setPageSize(value);
  };

  const handleClickFirstPage = (): void => {
    if (isSSR && handleChangePageNumber) {
      handleChangePageNumber(0);
    } else {
      table.setPageIndex(0);
    }
  };

  const handleClickPreviousPage = (): void => {
    if (
      isSSR &&
      handleChangePageNumber &&
      pagination?.currentPage !== undefined
    ) {
      handleChangePageNumber(pagination.currentPage - 1);
    } else {
      table.previousPage();
    }
  };

  const handleClickNextPage = (): void => {
    if (
      isSSR &&
      handleChangePageNumber &&
      pagination?.currentPage !== undefined
    ) {
      handleChangePageNumber(pagination.currentPage + 1);
    } else {
      table.nextPage();
    }
  };

  const handleClickLastPage = (): void => {
    if (
      isSSR &&
      handleChangePageNumber &&
      pagination?.currentPage !== undefined
    ) {
      handleChangePageNumber(totalPages - 1);
    } else {
      table.setPageIndex(table.getPageCount() - 1);
    }
  };

  const outputCurrentPage = (): string => {
    return isSSR && pagination?.currentPage !== undefined
      ? `${pagination.currentPage + 1} of ${totalPages}`
      : `${
          table.getState().pagination.pageIndex + 1
        } of ${table.getPageCount()}`;
  };

  return {
    handlePageSize,
    handleClickFirstPage,
    handleClickPreviousPage,
    handleClickNextPage,
    handleClickLastPage,
    outputCurrentPage,
  };
};

export default useTablePaginationPages;
