import { Close as CloseIcon } from '@mui/icons-material';
import {
  Box,
  Button,
  Chip,
  IconButton,
  MenuItem,
  Tooltip,
  Typography,
} from '@mui/material';
import { FieldArray, useFormikContext } from 'formik';
import { useTypedSelector } from 'hooks';
import React, { memo } from 'react';

import { TInitialValues } from '../../../../utils';
import {
  SFormControl,
  SInputLabel,
  SSelect,
} from '../../ManageUserContent.styled';
import { SBoxContent, SBoxWrapper } from './CentreNetworksSelect.styled';
import { texts } from './constants/texts';

const CentreNetworksSelect: React.FC = () => {
  const { manageUserData } = useTypedSelector((state) => state && state.users);
  const { values, handleChange, isSubmitting } =
    useFormikContext<TInitialValues>();

  const checkEmptyCentres = (): boolean => {
    if (values.centreNetworks) {
      const emptyItem = values.centreNetworks.find(
        (item) => item.centreId === -1 || !item.networkIds.length,
      );

      if (emptyItem) {
        return true;
      }
    }

    return false;
  };

  const getCentreItemDisabled = (currentItemId: number): boolean => {
    if (values.centreNetworks) {
      const hasCentre = values.centreNetworks.find(
        (centreNetwork) => centreNetwork.centreId === currentItemId,
      );

      if (hasCentre) {
        return true;
      }
    }

    return false;
  };

  const isRemoveButtonDisables =
    !values.centreNetworks || values.centreNetworks.length <= 1;

  return (
    <FieldArray
      name="centreNetworks"
      validateOnChange={false}
      render={(arrayHelper) => (
        <SBoxWrapper className="centre-networks-wrapper">
          <Typography>{texts.title}</Typography>
          {values.centreNetworks &&
            values.centreNetworks.map(
              (__centreNetwork, indexCentreNetworks) => (
                <SBoxContent
                  key={`centreNetworks-${indexCentreNetworks}`}
                  className="centre-networks-content"
                >
                  <SFormControl fullWidth>
                    <SInputLabel
                      id={`user-centre-label-${indexCentreNetworks}`}
                    >
                      {texts.selects.centre}
                    </SInputLabel>
                    <SSelect
                      labelId={`user-centre-label-${indexCentreNetworks}`}
                      label="Centre"
                      id="select-user-centre"
                      name={`centreNetworks[${indexCentreNetworks}].centreId`}
                      value={
                        values.centreNetworks?.[indexCentreNetworks].centreId
                      }
                      disabled={!manageUserData.centres.length || isSubmitting}
                      onChange={handleChange}
                    >
                      {manageUserData.centres.length &&
                        manageUserData.centres.map((centre) => {
                          return (
                            <MenuItem
                              value={centre.id}
                              key={centre.id}
                              disabled={getCentreItemDisabled(centre.id)}
                            >
                              {centre.name}
                            </MenuItem>
                          );
                        })}
                    </SSelect>
                  </SFormControl>
                  <SFormControl fullWidth>
                    <SInputLabel
                      id={`user-network-label-${indexCentreNetworks}`}
                    >
                      {texts.selects.networks}
                    </SInputLabel>
                    <SSelect
                      labelId={`user-network-label-${indexCentreNetworks}`}
                      id="select-user-networks"
                      label="Network"
                      name={`centreNetworks[${indexCentreNetworks}].networkIds`}
                      value={
                        values.centreNetworks?.[indexCentreNetworks].networkIds
                      }
                      onChange={handleChange}
                      multiple
                      disabled={!manageUserData.networks.length || isSubmitting}
                      renderValue={(selected) => (
                        <Box
                          sx={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            gap: 0.5,
                          }}
                        >
                          {selected.map((selectedItem) => {
                            const chipValue = manageUserData.networks.find(
                              (network) => network.id === selectedItem,
                            );

                            return (
                              chipValue && (
                                <Chip
                                  key={chipValue.name}
                                  label={chipValue.name}
                                />
                              )
                            );
                          })}
                        </Box>
                      )}
                    >
                      {manageUserData.networks.map((network) => {
                        return (
                          <MenuItem value={network.id} key={network.id}>
                            {network.name}
                          </MenuItem>
                        );
                      })}
                    </SSelect>
                  </SFormControl>
                  <Tooltip title="Delete Centre">
                    <IconButton
                      color="error"
                      onClick={() => arrayHelper.remove(indexCentreNetworks)}
                      disabled={isRemoveButtonDisables}
                    >
                      <CloseIcon />
                    </IconButton>
                  </Tooltip>
                </SBoxContent>
              ),
            )}
          <Button
            variant="text"
            onClick={() =>
              arrayHelper.push({
                centreId: -1,
                networkIds: [],
              })
            }
            disabled={checkEmptyCentres()}
            sx={{ marginLeft: '40px' }}
          >
            {texts.buttons.oneMore}
          </Button>
        </SBoxWrapper>
      )}
    />
  );
};

export default memo(CentreNetworksSelect);
