import { useActions } from 'hooks';

interface IUsersContentTableSearchHookReturn {
  readonly handleGlobalSearch: (value: string) => void;
}

const useUsersContentTableSearch = (): IUsersContentTableSearchHookReturn => {
  const { allUserTableGlobalSearch } = useActions();

  const handleGlobalSearch = (value: string) => {
    allUserTableGlobalSearch(value);
  };

  return { handleGlobalSearch };
};

export default useUsersContentTableSearch;
