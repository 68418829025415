import {
  ArrowCircleLeft as ArrowCircleLeftIcon,
  ArrowCircleRight as ArrowCircleRightIcon,
} from '@mui/icons-material';
import IconButton from 'components/common/Button/components/IconButton';
import React from 'react';

const NavigationButtons: React.FC = () => {
  return (
    <>
      <IconButton
        icon={<ArrowCircleLeftIcon />}
        wrapperSize="2xl"
        color="primary"
        className="swiper-button swiper-prev"
      />
      <IconButton
        icon={<ArrowCircleRightIcon />}
        color="primary"
        wrapperSize="2xl"
        className="swiper-button swiper-next"
      />
    </>
  );
};

export default NavigationButtons;
