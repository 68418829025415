import { ManageCampaignModeEnum } from 'constants/enums/campaign';
import { urls } from 'constants/urls/url';
import { radix } from 'constants/variables';
import { useActions, useError } from 'hooks';
import MainLayout from 'layouts/Main';
import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { texts } from '../../constants/texts';
import { CampaignForm } from '../../shared';

const EditCampaign: React.FC = () => {
  const { setCampaignMode, getCurrentCampaign } = useActions();

  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const { outputError } = useError();
  const campaignIdNumber = id ? parseInt(id, radix[10]) : null;

  const getCampaignData = async (campaignId: number) => {
    try {
      await getCurrentCampaign(campaignId);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      outputError(error?.message, { isSnackbar: true });
      navigate(urls.campaign);
    }
  };

  useEffect(() => {
    setCampaignMode(ManageCampaignModeEnum.EDIT);

    if (campaignIdNumber) {
      getCampaignData(campaignIdNumber);
    }
  }, [campaignIdNumber]);

  return (
    <MainLayout>
      <CampaignForm title={texts.edit} />
    </MainLayout>
  );
};

export default EditCampaign;
