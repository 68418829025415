export enum FileLoadStatusesEnum {
  ADDED = 'ADDED',
  LOADED = 'LOADED',
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
}

export enum FileTypesEnum {
  IMAGE = 'Image',
  VIDEO = 'Video',
  UNKNOWN = 'Unknown',
}
