import './SitesHeader.scss';

import React from 'react';

interface ISitesHeaderProps {
  title: string;
  children?: JSX.Element; // should be only single element
}

const SitesHeader: React.FC<ISitesHeaderProps> = ({ title, children }) => {
  return (
    <div className="sites-header">
      <div className="first-header">{title}</div>
      {children}
    </div>
  );
};

export default SitesHeader;
