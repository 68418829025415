import { urls } from 'constants/urls/url';
import { getToken } from 'helpers/jwt';
import { useActions } from 'hooks';
import { useTypedSelector } from 'hooks/useTypedSelector';
import React, { ReactElement, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { MainLoading } from '../Loading';

interface IPrivateRouteProps {
  children: ReactElement;
}
const PrivateRoute = ({ children }: IPrivateRouteProps): ReactElement => {
  const { hasLoggedIn } = useTypedSelector((state) => state && state.auth);
  const navigate = useNavigate();
  const { cleanupCurrent } = useActions();

  const token = getToken();

  useEffect(() => {
    if (!token) {
      navigate(urls.login);
      cleanupCurrent();
    }
  }, [token]);

  if (!hasLoggedIn) {
    return <MainLoading />;
  }

  return children;
};

export default PrivateRoute;
