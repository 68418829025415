import { Header, SortDirection } from '@tanstack/react-table';
import { MouseEvent } from 'react';

import { ITableHeader } from './TableHeader';

interface ITableHeaderHookProps<TData> {
  readonly isSSR: ITableHeader<TData>['isSSR'];
  readonly loading: ITableHeader<TData>['loading'];
  readonly sort: ITableHeader<TData>['sort'];
  readonly handleChangeSort: ITableHeader<TData>['handleChangeSort'];
}

interface ITableHeaderHookReturn<TData> {
  readonly handleToggleSortingHandler: (
    header: Header<TData, unknown>,
    event: MouseEvent<HTMLTableHeaderCellElement>,
  ) => unknown | void;
  readonly getIsSorted: (
    header: Header<TData, unknown>,
  ) => boolean | SortDirection;
  readonly getChevronPosition: (
    header: Header<TData, unknown>,
  ) => string | undefined;
}

const useTableHeader = <TData>({
  isSSR,
  loading,
  sort,
  handleChangeSort,
}: ITableHeaderHookProps<TData>): ITableHeaderHookReturn<TData> => {
  const handleToggleSortingHandler = (
    header: Header<TData, unknown>,
    event?: MouseEvent<HTMLTableHeaderCellElement>,
  ) => {
    if (!header.column.getCanSort() || loading) {
      return;
    }

    const toggleSortingHandler = header.column.getToggleSortingHandler();

    if (isSSR && handleChangeSort) {
      // this property is there, but for some reason it is omitted in the types in the library
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      return handleChangeSort((header.column.columnDef as any).accessorKey);
    }

    if (toggleSortingHandler) {
      return toggleSortingHandler(event);
    }
  };

  const getIsSorted = (
    header: Header<TData, unknown>,
  ): boolean | SortDirection => {
    if (!header.column.getCanSort()) {
      return false;
    }

    return isSSR && sort
      ? // eslint-disable-next-line @typescript-eslint/no-explicit-any
        sort.column === (header.column.columnDef as any).accessorKey
      : header.column.getIsSorted();
  };

  const getChevronPosition = (
    header: Header<TData, unknown>,
  ): string | undefined => {
    if (isSSR && sort?.direction) {
      return { 0: 'asc', 1: 'desc' }[sort?.direction];
    }

    const headerSorted = header.column.getIsSorted();

    if (!headerSorted) {
      return;
    }

    return { asc: 'asc', desc: 'desc' }[headerSorted];
  };

  return { handleToggleSortingHandler, getIsSorted, getChevronPosition };
};

export default useTableHeader;
