import React, { useEffect } from 'react';

import { useActions } from '../../../../../../hooks/useActions';
import { useTypedSelector } from '../../../../../../hooks/useTypedSelector';
import { ITabsSwitcherState } from '../type';

interface IAdditionalStaffQueryHookProps {
  tabsSwitcherState: ITabsSwitcherState;
}

const useAdditionalStaffQuery = ({
  tabsSwitcherState,
}: IAdditionalStaffQueryHookProps): void => {
  const { getStates } = useActions();
  const { loading: statesLoading } = useTypedSelector(
    (state) => state && state.states,
  );

  // Checks whether this request is being executed, if so, it makes no sense to run it again,
  // and also checks whether it needs to be called at all, since this tab can be disabled
  const isStateQuery = tabsSwitcherState.isCentre && !statesLoading.getAll;

  useEffect(() => {
    if (isStateQuery) {
      getStates();
    }
  }, []);
};

export default useAdditionalStaffQuery;
