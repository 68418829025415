import { AxiosPromise } from 'axios';

import { AxiosMethodEnum } from '../../../constants/text/text';
import { urls } from '../../../constants/urls/url';
import { apiCall } from '../../../helpers/api/apiCall';

function getMethodCategory<T extends object>(url: string): AxiosPromise<T> {
  return apiCall<T>(AxiosMethodEnum.get, url, '', null, '', {});
}

export const allCategories = <T extends object>(): AxiosPromise<T> =>
  getMethodCategory<T>(urls.categories);

export const chooseCategory = <T extends object>(
  productId: number,
): AxiosPromise<T> =>
  getMethodCategory<T>(`${urls.categoryProductCache}/${productId}`);
