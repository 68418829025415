import './UsersContent.scss';

import Table from 'components/common/Table/React-Table/React-Table';
import { ITableRefObject } from 'components/common/Table/React-Table/types';
import { useTypedSelector } from 'hooks';
import React, { useRef } from 'react';
import { IUserShort } from 'types';

import { BulkDelete, DeleteOne, ManageUserModal } from './components';
import {
  useUserContentQuery,
  useUsersContentColumns,
  useUsersContentModals,
  useUsersContentRowSelection,
  useUsersContentSort,
  useUsersContentTableActions,
  useUsersContentTablePagination,
} from './hooks';
import useUsersContentTableSearch from './hooks/search.hook';

const Content: React.FC = () => {
  const { loaders, users, allUsersTable } = useTypedSelector(
    (state) => state && state.users,
  );

  useUserContentQuery();
  const { columns } = useUsersContentColumns();
  const { actions } = useUsersContentTableActions();
  const { handleChangeSort } = useUsersContentSort();
  const { handleGlobalSearch } = useUsersContentTableSearch();
  const { handleChangePageNumber, handleChangePageSize } =
    useUsersContentTablePagination();
  const { onRowClick, rowSelection } = useUsersContentRowSelection();
  const { isViewManageUser, isViewDeleteOne, isViewBulkDelete } =
    useUsersContentModals();

  const tableRef = useRef<ITableRefObject<IUserShort>>(null);

  return (
    <div className="users-content-wrapper">
      <Table
        data={users}
        columns={columns}
        loading={loaders.getAll}
        ref={tableRef}
        globalSearch={allUsersTable.globalSearch}
        handleGlobalSearch={handleGlobalSearch}
        onRowClick={onRowClick}
        handleChangePageSize={handleChangePageSize}
        handleChangePageNumber={handleChangePageNumber}
        handleChangeSort={handleChangeSort}
        sort={allUsersTable.sort}
        state={{
          rowSelection,
        }}
        actions={actions}
        totalDataCount={allUsersTable.totalUsersCount}
        pagination={allUsersTable.pagination}
        itemsCount={allUsersTable.pagination.pageSize}
        hasPagination
        rowSelecting
        isFullWidth
        isSearchable
        isSSR
      />
      {isViewManageUser && <ManageUserModal />}
      {isViewDeleteOne && <DeleteOne />}
      {isViewBulkDelete && <BulkDelete />}
    </div>
  );
};

export default Content;
