import { AdjustmentIcon } from 'assets/icons';
import { Button } from 'components/common';
import SvgIcon from 'components/common/SvgIcon/SvgIcon';
import { ManageCampaignTabsEnum } from 'constants/enums/campaign';
import { useActions } from 'hooks';
import { texts } from 'pages/Campaign/constants';
import React from 'react';

import useInspector from '../../../../../hooks/useInspector';
import { STabPanel } from '../Tabs.styled';
import { TabContent } from './components';

const DetailTab: React.FC = () => {
  const { getIsCheckDetailFullFill } = useInspector();
  const { changeCampaignTab } = useActions();

  const handleChangeTabToManage = () => {
    changeCampaignTab(ManageCampaignTabsEnum.MANAGE);
  };

  return (
    <STabPanel value={ManageCampaignTabsEnum.DETAIL}>
      <TabContent />
      <div className="manage-campaign-footer">
        <Button
          label={texts.buttons.next}
          disabled={!getIsCheckDetailFullFill()}
          onClick={handleChangeTabToManage}
          icon={
            <SvgIcon className="icon-s">
              <AdjustmentIcon className="whiteSVG" />
            </SvgIcon>
          }
        />
      </div>
    </STabPanel>
  );
};

export default DetailTab;
