import { ListItemIcon } from '@mui/material';
import { withStyles } from 'tss-react/mui';

import { colors } from '../../../../../../constants/colors/colors';

const SListItemIcon = withStyles(ListItemIcon, () => ({
  root: {
    minWidth: 36,
    margin: 0,
    justifyContent: 'center',

    svg: {
      color: colors.White,
    },
  },
}));

export { SListItemIcon };
