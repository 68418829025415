import './Centre.scss';

import { Select } from 'components/common';
import { useFormikContext } from 'formik';
import { convertToSelectData } from 'helpers';
import { useActions, useTypedSelector } from 'hooks';
import React, { useEffect } from 'react';
import { SingleValue } from 'react-select';

import { texts } from '../../../../../../constants/texts';
import { IInitialValues } from '../../../../types';

const { fromObjectIdWithName } = convertToSelectData;

const CentreNetwork: React.FC = () => {
  const { getCentres, getStates } = useActions();

  const { centre, loading } = useTypedSelector(
    (state) => state && state.centre,
  );

  const { values, errors, isSubmitting, setFieldValue } =
    useFormikContext<IInitialValues>();

  useEffect(() => {
    getCentres();
    getStates();
  }, []);

  const handleChangeCentre = (
    newValue: SingleValue<IInitialValues['centre']>,
  ) => {
    setFieldValue('centre', newValue);
  };

  return (
    <div className="centre-wrapper">
      <Select
        label={texts.pages.manage.content.centreNetworks.selects.centre}
        placeholder={texts.pages.manage.content.centreNetworks.selects.centre}
        helperText={errors.centre}
        error={Boolean(errors.centre)}
        options={fromObjectIdWithName(centre)}
        name="centre"
        value={values.centre}
        isClearable
        isSearchable
        isLoading={loading.getAll}
        onChange={handleChangeCentre}
        isDisabled={isSubmitting}
      />
    </div>
  );
};

export default CentreNetwork;
