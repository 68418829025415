import './HelperMessage.scss';

import React from 'react';

interface IHelperMessage {
  readonly message: string;
}

const HelperMessage: React.FC<IHelperMessage> = ({ message }) => {
  return (
    <div className="message-wrapper">
      <div className="message-container">{message}</div>
    </div>
  );
};

export default HelperMessage;
