import { useMemo } from 'react';

import { IAdditionallyStaff } from '../AdditionallyStaff';
import { TabIdsEnum } from '../constants';
import { IFabPosition, ITabsSwitcherState, TSides } from '../type';

interface IFabConfigurationHookProps extends ITabsSwitcherState {
  customFabPadding: IAdditionallyStaff['customFabPadding'];
  verticalPosition: IAdditionallyStaff['verticalPosition'];
  horizontalPosition: IAdditionallyStaff['horizontalPosition'];
}

interface IFabConfigurationHookReturn {
  tabsSwitcherState: ITabsSwitcherState;
  verticalSx: TSides;
  horizontalSx: TSides;
  isAllTurnOff: boolean;
  initialedCurrentTab: TabIdsEnum;
}

const defaultPadding = 16;

const positions = (padding = defaultPadding): IFabPosition => ({
  vertical: {
    top: { top: padding },
    bottom: { bottom: padding },
  },
  horizontal: {
    right: { right: padding },
    left: { left: padding },
  },
});

const initialCurrentTab = (
  switchers: Record<keyof ITabsSwitcherState, boolean>,
): TabIdsEnum => {
  if (switchers.isCentre) {
    return TabIdsEnum.CENTRE;
  }

  if (switchers.isNetwork) {
    return TabIdsEnum.NETWORK;
  }

  if (switchers.isResolution) {
    return TabIdsEnum.RESOLUTION;
  }

  if (switchers.isSuborganisation) {
    return TabIdsEnum.SUBORGANISATION;
  }

  return TabIdsEnum.CENTRE;
};

const useFabConfiguration = ({
  isCentre,
  isNetwork,
  isResolution,
  isSuborganisation,
  customFabPadding,
  verticalPosition,
  horizontalPosition,
}: IFabConfigurationHookProps): IFabConfigurationHookReturn => {
  const tabsSwitcherState = useMemo<ITabsSwitcherState>(
    () => ({
      isCentre,
      isNetwork,
      isResolution,
      isSuborganisation,
    }),
    [isCentre, isNetwork, isResolution, isSuborganisation],
  );

  const verticalSx = useMemo<TSides>(() => {
    if (verticalPosition) {
      return positions(customFabPadding).vertical[verticalPosition];
    }

    return positions(customFabPadding).vertical.bottom;
  }, [verticalPosition, customFabPadding]);

  const horizontalSx = useMemo<TSides>(() => {
    if (horizontalPosition) {
      return positions(customFabPadding).horizontal[horizontalPosition];
    }

    return positions(customFabPadding).horizontal.right;
  }, [horizontalPosition, customFabPadding]);

  // If it turned out that the user has access to this component, but all tabs were turned off, then the button will be blocked
  const isAllTurnOff = Object.values(tabsSwitcherState).every((item) => !item);

  const initialedCurrentTab = initialCurrentTab(tabsSwitcherState);

  return {
    tabsSwitcherState,
    verticalSx,
    horizontalSx,
    isAllTurnOff,
    initialedCurrentTab,
  };
};

export default useFabConfiguration;
