import { AdditionallyStaff } from 'components/common';
import MainLayout from 'layouts/Main';
import React from 'react';

import { initialValues } from '../../constants/initialValues';
import { texts } from '../../constants/texts';
import { SiteForm } from '../../shared';

const AddSite: React.FC = () => {
  return (
    <MainLayout>
      <SiteForm initialValues={initialValues} title={texts.pages.add.title} />
      <AdditionallyStaff />
    </MainLayout>
  );
};

export default AddSite;
