import { IAdvertiser } from '../../../types';

export interface IAdvertiserState {
  advertisers: IAdvertiser[];
  hasErrorAdvertisersLoading: boolean;
  isLoadingAdvertiser: boolean;
  isLoadingPostAdvertiser: boolean;
  hasErrorPostAdvertiser: boolean;
}

export enum AdvertiserActionTypesEnum {
  GET_ADVERTISERS_LOADING = 'GET_ADVERTISERS_LOADING',
  GET_ADVERTISERS_SUCCESS = 'GET_ADVERTISERS_SUCCESS',
  GET_ADVERTISERS_ERROR = 'GET_ADVERTISERS_ERROR',
  POST_ADVERTISERS_LOADING = 'POST_ADVERTISERS_LOADING',
  POST_ADVERTISERS_SUCCESS = 'POST_ADVERTISERS_SUCCESS',
  POST_ADVERTISERS_ERROR = 'POST_ADVERTISERS_ERROR',
}

interface IGetAdvertiserLoadingAction {
  type: AdvertiserActionTypesEnum.GET_ADVERTISERS_LOADING;
}
interface IGetAdvertiserSuccessAction {
  type: AdvertiserActionTypesEnum.GET_ADVERTISERS_SUCCESS;
  payload: IAdvertiser[];
}
interface IGetAdvertiserErrorAction {
  type: AdvertiserActionTypesEnum.GET_ADVERTISERS_ERROR;
  payload: object;
}
interface IPostAdvertiserLoadingAction {
  type: AdvertiserActionTypesEnum.POST_ADVERTISERS_LOADING;
}
interface IPostAdvertiserSuccessAction {
  type: AdvertiserActionTypesEnum.POST_ADVERTISERS_SUCCESS;
}
interface IPostAdvertiserErrorAction {
  type: AdvertiserActionTypesEnum.POST_ADVERTISERS_ERROR;
  payload: object;
}

export type TAdvertiserAction =
  | IGetAdvertiserLoadingAction
  | IGetAdvertiserSuccessAction
  | IGetAdvertiserErrorAction
  | IPostAdvertiserLoadingAction
  | IPostAdvertiserSuccessAction
  | IPostAdvertiserErrorAction;
