import { AxiosPromise } from 'axios';
import { ICampaignQueryDto } from 'types';

import { AxiosMethodEnum } from '../../../constants/text/text';
import { urlConstructors, urls } from '../../../constants/urls/url';
import { apiCall } from '../../../helpers/api/apiCall';
import { IDeclineCampaignPayload } from './manage/type';

const getMethodCampaign = <T>(
  url: string,
  params?: object,
): AxiosPromise<T> => {
  return apiCall<T>(AxiosMethodEnum.get, url, '', params);
};

const postMethodCampaign = <T>(url: string, data?: object) => {
  return apiCall<T>(AxiosMethodEnum.post, url, data);
};

const deleteMethodCampaign = <T>(
  url: string,
  params?: object,
): AxiosPromise<T> => {
  return apiCall<T>(AxiosMethodEnum.delete, url, '', params);
};

const putMethodCampaign = <T>(
  url: string,
  data?: object,
  params?: object,
): AxiosPromise<T> => {
  return apiCall<T>(AxiosMethodEnum.put, url, data, params);
};

export const allCampaigns = <T = unknown>(params?: object): AxiosPromise<T> =>
  getMethodCampaign<T>(urls.campaigns, params);

export const oneCampaign = <T = unknown>(id: number): AxiosPromise<T> =>
  getMethodCampaign<T>(`${urls.campaigns}/${id}`);

export const searchedCampaignsByName = <T = unknown>(
  name?: string,
): AxiosPromise<T> => getMethodCampaign<T>(`${urls.campaigns}/${name}`);

export const allCampaignTypes = <T = unknown>(): AxiosPromise<T> =>
  getMethodCampaign<T>(urlConstructors.campaignTypes());

export const fetchCampaignFilters = <T = unknown>(): AxiosPromise<T> =>
  getMethodCampaign<T>(urlConstructors.campaignFilters());

export const deletedCampaign = <T = unknown>(id: number): AxiosPromise<T> =>
  deleteMethodCampaign<T>(`${urls.campaigns}/${id}`);

export const deletedCampaigns = <T = unknown>(
  params: object,
): AxiosPromise<T> => deleteMethodCampaign<T>(`${urls.campaigns}`, params);

export const archivedCampaign = <T = unknown>(id: number): AxiosPromise<T> =>
  deleteMethodCampaign<T>(`${urls.campaigns}/${id}/archive`);

export const archivedCampaigns = <T = unknown>(
  params: object,
): AxiosPromise<T> =>
  deleteMethodCampaign<T>(`${urls.campaigns}/archive`, params);

export const newCampaign = <T = unknown>(data: object): AxiosPromise<T> =>
  postMethodCampaign<T>(urlConstructors.campaignPublish(), data);

export const newDraftCampaign = <T = unknown>(data: object): AxiosPromise<T> =>
  postMethodCampaign<T>(urlConstructors.campaignDraft(), data);

export const editCampaign = <T = unknown>(
  id: number,
  data: object,
): AxiosPromise<T> =>
  putMethodCampaign<T>(`${urls.campaigns}/${id}/published`, data);

export const editDraftCampaign = <T = unknown>(
  id: number,
  data: object,
): AxiosPromise<T> =>
  putMethodCampaign<T>(`${urls.campaigns}/${id}/draft`, data);

export const putCampaignApproved = <T = unknown>(
  id: number,
  data: object,
): AxiosPromise<T> =>
  putMethodCampaign<T>(`${urls.campaigns}/${id}/approve`, data);

export const putCampaignDeclined = <T = unknown>(
  id: number,
  rejectReason: object,
): AxiosPromise<T> =>
  putMethodCampaign<T>(`${urls.campaigns}/${id}/reject`, rejectReason);

export const campaignStopped = <T = unknown>(id: number): AxiosPromise<T> =>
  putMethodCampaign<T>(`${urls.campaigns}/${id}/stop`);

export const saveCampaignAsDraftApi = <T extends object>(
  data: ICampaignQueryDto,
): AxiosPromise<T> =>
  postMethodCampaign<T>(urlConstructors.campaignDraft(), data);

export const editDraftCampaignAsDraftApi = <T extends object>(
  data: ICampaignQueryDto,
  id: number,
): AxiosPromise<T> =>
  putMethodCampaign<T>(urlConstructors.campaignDraft(id), data);

export const publishCampaignApi = <T extends object>(
  data: ICampaignQueryDto,
): AxiosPromise<T> =>
  postMethodCampaign<T>(urlConstructors.campaignPublish(), data);

export const updateCampaignApi = <T extends object>(
  data: ICampaignQueryDto,
  id: number,
): AxiosPromise<T> =>
  putMethodCampaign<T>(urlConstructors.campaignPublish(id), data);

export const declineCampaignApi = <T extends object>(
  data: IDeclineCampaignPayload,
): AxiosPromise<T> =>
  putMethodCampaign<T>(urlConstructors.campaignReject(data.id), {
    reason: data.reason,
  });

export const approveCampaignApi = <T extends object>(
  data: number, // campaign ID
): AxiosPromise<T> =>
  putMethodCampaign<T>(urlConstructors.campaignApprove(data));

export const stopCampaignApi = <T extends object>(
  data: number, // campaign ID
): AxiosPromise<T> => putMethodCampaign<T>(urlConstructors.campaignStop(data));
