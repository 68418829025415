import './RangePicker.scss';

import classnames from 'classnames';
import React from 'react';
import { DayPicker, DayPickerRangeProps } from 'react-day-picker';

import { TSeparator } from '../../../../../types/date';
import Coordinate from '../../../Coordinate/Coordinate';
import Portal from '../../../Portal/Portal';
import { TRangeDate } from '../../types';
import { DateInput, MonthToggler } from '../common';
import useRangePicker from './RangePicker.hook';

export interface IRangePickerProps extends Omit<DayPickerRangeProps, 'mode'> {
  separator: TSeparator;
  defaultDate?: TRangeDate;
  onSelect?: (date: TRangeDate) => void;
  relatedComponent?: 'input' | undefined;
  relatedComponentDisabled?: boolean;
  anchorClassName?: classnames.ArgumentArray;
}

const RangePicker: React.FC<IRangePickerProps> = ({
  separator,
  defaultDate,
  onSelect,
  relatedComponent,
  relatedComponentDisabled,
  anchorClassName,
  ...props
}) => {
  const {
    selected,
    isOpen,
    anchorRef,
    calendarRef,
    numberOfMonth,
    currentViewMonth,
    handleSelect,
    handleDateStart,
    handleDateEnd,
    handleMonthChange,
    handleNumberOfMonth,
    toggleOpenWithStart,
    toggleOpenWithEnd,
  } = useRangePicker({ defaultDate, onSelect });

  if (relatedComponent === 'input') {
    return (
      <>
        <div
          ref={anchorRef}
          className={classnames(
            'date-picker-anchor',
            'range-picker',
            anchorClassName,
          )}
        >
          <DateInput
            label="Start Date"
            separator={separator}
            className="date-range start-date"
            handleDateSelect={handleDateStart}
            toggleOpen={toggleOpenWithStart}
            dateValue={selected?.from}
            disabled={relatedComponentDisabled}
          />
          <DateInput
            label="End Date"
            className="date-range end-date"
            separator={separator}
            handleDateSelect={handleDateEnd}
            toggleOpen={toggleOpenWithEnd}
            dateValue={selected?.to}
            disabled={relatedComponentDisabled}
          />
        </div>
        {isOpen && (
          <Portal>
            <Coordinate ref={calendarRef} anchorEl={anchorRef}>
              <DayPicker
                mode="range"
                selected={selected}
                onSelect={handleSelect}
                month={currentViewMonth}
                onMonthChange={handleMonthChange}
                numberOfMonths={numberOfMonth}
                {...props}
              />
              <MonthToggler
                alignment={numberOfMonth}
                handleChange={handleNumberOfMonth}
              />
            </Coordinate>
          </Portal>
        )}
      </>
    );
  }

  return (
    <DayPicker
      mode="range"
      selected={selected}
      onSelect={handleSelect}
      {...props}
    />
  );
};

export default RangePicker;
