import { Menu, MenuItem } from '@mui/material';
import { withStyles } from 'tss-react/mui';

import { colors } from '../../../constants/colors/colors';

const StyledMenuItem = withStyles(MenuItem, (theme) => ({
  root: {
    '& div': {
      '& span': {
        fontSize: '14px !important',
        fontWeight: 600,
      },
    },
    '&:focus': {
      backgroundColor: colors.White,
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme.palette.common.white,
      },
    },

    '.menu-list-icon': {
      display: 'flex',
      alignItems: 'center',
    },
  },
}));

const StyledMenu = withStyles(Menu, () => ({
  paper: {
    border: `1px solid ${colors.Iron}`,
    color: colors.Cerulean,
    width: '250px',
  },
}));

export { StyledMenu, StyledMenuItem };
