import './ListMenu.scss';

import { useSnackbar } from 'notistack';
import React from 'react';

import { AlertStatusesEnum } from '../../../constants/constants';
import {
  CampaignListMenuText,
  ManageCampaignText,
} from '../../../constants/text/text';
import {
  ISelectedTableItem,
  ITableItems,
  ITableMenuProps,
} from '../../../types';
import SvgIcon from '../SvgIcon/SvgIcon';
import { StyledMenu, StyledMenuItem } from './ListMenu.styled';

interface IOnClickProps {
  ids?: number[];
  id?: number;
}

interface ICampaignListMenuProps<Item> {
  menuItem: ITableMenuProps;
  popupAlign?: number | 'left' | 'right' | 'center';
  selectedItemsIds?: number[];
  selectedItems?: Item[];
  selectedItemsIdsAndIndexes?: ISelectedTableItem[];
}

const ListMenu: React.FC<ICampaignListMenuProps<ITableItems>> = ({
  menuItem,
  popupAlign = 'center',
  selectedItemsIds,
  selectedItems,
  selectedItemsIdsAndIndexes,
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const {
    handleCloseTableMenu,
    menuListItems: items,
    tableMenuAnchorEl: anchorEl,
  } = menuItem;

  const isOpen = Boolean(anchorEl);

  return (
    <StyledMenu
      id="long-menu"
      anchorEl={anchorEl}
      keepMounted
      open={isOpen}
      onClose={handleCloseTableMenu}
      elevation={0}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: popupAlign,
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      <div>
        {items.map((item) => {
          if (!item) return;

          const { id, label, icon, onClick, errorToasterLabel } = item;

          const isErrorColor =
            label === CampaignListMenuText.archive ||
            label === CampaignListMenuText.delete ||
            label === ManageCampaignText.removeSite;

          // TODO: Need fix it later
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const handleClick = (options: IOnClickProps, selected?: any[]) => {
            const { id: optionId, ids } = options;
            if (!ids?.length && errorToasterLabel) {
              enqueueSnackbar(errorToasterLabel, {
                variant: AlertStatusesEnum.ERROR,
              });
              handleCloseTableMenu();

              return;
            }
            if (onClick) {
              if (optionId) {
                onClick({ id: optionId, ids, items: selected });

                return;
              }
              const currentId = ids?.[0];
              onClick({
                id: currentId,
                ids,
                items: selected,
                selectedIdsAndIndexes: selectedItemsIdsAndIndexes,
              });
            }
          };

          const handleMenuItemClick = () => {
            handleClick(
              {
                ids: selectedItemsIds,
              },
              selectedItems,
            );
            handleCloseTableMenu();
          };

          return (
            <StyledMenuItem onClick={handleMenuItemClick} key={id}>
              <div className="menu-list-icon">
                <SvgIcon className="activeSVG icon-l">{icon}</SvgIcon>
                <span
                  className={
                    isErrorColor ? 'icon-text-error-type' : 'icon-text'
                  }
                >
                  {label}
                </span>
              </div>
            </StyledMenuItem>
          );
        })}
      </div>
    </StyledMenu>
  );
};

export default ListMenu;
