import './Tabs.scss';

import React from 'react';

import { isRole } from '../../../../../../../../../helpers';
import { useTypedSelector } from '../../../../../../../../../hooks/useTypedSelector';
import { UserRolesEnum } from '../../../../../../../../../types/general';
import { ITabsSwitcherState } from '../../../../type';
import CentreTab from './CentreTab/CentreTab';
import NetworkTab from './NetworkTab/NetworkTab';
import ResolutionTab from './ResolutionTab/ResolutionTab';
import Suborganisation from './SuborganisationTab/SuborganisationTab';

interface ITabs {
  tabsSwitcherState: ITabsSwitcherState;
  handleModalClose: () => void;
}

export interface ITabPropsDefault {
  handleModalClose: ITabs['handleModalClose'];
}

const Tabs: React.FC<ITabs> = ({ tabsSwitcherState, handleModalClose }) => {
  const { role } = useTypedSelector((state) => state && state.auth);

  const isNetworkView =
    tabsSwitcherState.isNetwork && isRole(UserRolesEnum.SuperAdmin, role);

  return (
    <div className="additionally-staff-tab-panel">
      {tabsSwitcherState.isCentre && (
        <CentreTab handleModalClose={handleModalClose} />
      )}
      {isNetworkView && <NetworkTab handleModalClose={handleModalClose} />}
      {tabsSwitcherState.isResolution && (
        <ResolutionTab handleModalClose={handleModalClose} />
      )}
      {tabsSwitcherState.isSuborganisation && (
        <Suborganisation handleModalClose={handleModalClose} />
      )}
    </div>
  );
};

export default Tabs;
