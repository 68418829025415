import { Input } from 'components/common';
import { ManageCampaignModeEnum } from 'constants/enums';
import { useActions, useTypedSelector } from 'hooks';
import React, { ChangeEvent } from 'react';

const CampaignName: React.FC = () => {
  const { mode } = useTypedSelector(
    (state) => state && state.manageCampaign.main,
  );
  const { campaignName } = useTypedSelector(
    (state) => state && state.manageCampaign.detail,
  );

  const { changeCampaignName } = useActions();

  const handleNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    const {
      target: { value },
    } = event;

    changeCampaignName(value);
  };

  const isDisabled = mode === ManageCampaignModeEnum.REVIEW;

  return (
    <div className="campaign-name">
      <Input
        name="campaign-name"
        value={campaignName}
        label="CAMPAIGN NAME"
        onChange={handleNameChange}
        disabled={isDisabled}
      />
    </div>
  );
};

export default CampaignName;
