import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()({
  chevronIcon: {
    transform: 'rotate(90deg)',

    '&.desc': {
      transform: 'rotate(270deg)',
      transition: '0.3s',
    },
  },
});
