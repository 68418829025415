import { CreatableSelect } from 'components/common';
import { convertToSelectData } from 'helpers';
import { useActions, useTypedSelector } from 'hooks';
import React, { memo } from 'react';
import { SingleValue } from 'react-select';
import { ISelectOption } from 'types';

import { ManageCampaignModeEnum } from '../../../../../../../../../../../../../../constants/enums/campaign';
import useSelects from '../Selects.hook';

const { fromObjectIdWithName } = convertToSelectData;

const Advertising: React.FC = () => {
  const { advertisers, isLoadingAdvertiser } = useTypedSelector(
    (state) => state && state.advertiser,
  );

  const { mode } = useTypedSelector(
    (state) => state && state.manageCampaign.main,
  );

  const { loaders, advertiser } = useTypedSelector(
    (state) => state && state.manageCampaign.detail,
  );

  const {
    setAdvertiserManageCampaign,
    setProductManageCampaign,
    createNewAdvertisingDetailTab,
  } = useActions();

  const { formatCreateLabel } = useSelects();

  const handleChange = (value: SingleValue<ISelectOption<number>>): void => {
    setAdvertiserManageCampaign(value);
    setProductManageCampaign(undefined);
  };

  const handleCreateAdvertiser = (inputValue: string) => {
    createNewAdvertisingDetailTab({ name: inputValue });
  };

  const isLoading = isLoadingAdvertiser || loaders.createAdvertising;
  const isDisabled = isLoading || mode === ManageCampaignModeEnum.REVIEW;

  return (
    <CreatableSelect
      label="ADVERTISER"
      value={advertiser}
      options={fromObjectIdWithName<number>(advertisers)}
      onChange={handleChange}
      isLoading={isLoading}
      isDisabled={isDisabled}
      formatCreateLabel={formatCreateLabel}
      onCreateOption={handleCreateAdvertiser}
    />
  );
};

export default memo(Advertising);
