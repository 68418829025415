import { validationSchema } from '../constants/validateSchema';
import { IDayPartsForm, IInitialValues } from '../shared/SiteForm/types';

const isValidDayPart = ({ duration, rangeTime, siteFrames }: IDayPartsForm) => {
  const hasSiteFrames = siteFrames.find((frame) => frame.name && frame.frameId);
  if (duration && rangeTime?.[0] && rangeTime?.[1] && hasSiteFrames) {
    return true;
  }

  return false;
};

export const dayPartsInspector = (
  dayParts: IDayPartsForm | IDayPartsForm[],
): boolean => {
  if (Array.isArray(dayParts)) {
    return Boolean(dayParts.find((dayPart) => isValidDayPart(dayPart)));
  }

  return isValidDayPart(dayParts);
};

export const incorrectDayPartsInspector = (
  dayParts: IDayPartsForm | IDayPartsForm[],
): boolean => {
  if (Array.isArray(dayParts)) {
    return Boolean(dayParts.find((dayPart) => !isValidDayPart(dayPart)));
  }

  return !isValidDayPart(dayParts);
};

export const disabledSaveButtonInspector = (
  values: IInitialValues,
): boolean => {
  // eslint-disable-next-line no-sync
  const isValidFromYupScheme = validationSchema.isValidSync(values);
  const hasCorrectDayPart = dayPartsInspector(values.siteDayParts);

  if (isValidFromYupScheme && hasCorrectDayPart) {
    return false;
  }

  return true;
};
