import classnames from 'classnames';
import React from 'react';
import { DayPicker, DayPickerSingleProps } from 'react-day-picker';

import { TSeparator } from '../../../../../types';
import Coordinate from '../../../Coordinate/Coordinate';
import Portal from '../../../Portal/Portal';
import { TSingleDate } from '../../types';
import { DateInput } from '../common';
import useSinglePicker from './SinglePicker.hook';

export interface ISingleProps extends Omit<DayPickerSingleProps, 'mode'> {
  relatedComponent?: 'input' | undefined;
  defaultDate?: TSingleDate;
  separator: TSeparator;
  relatedComponentDisabled?: boolean;
  onSelect?: (date: TSingleDate) => void;
  anchorClassName?: classnames.ArgumentArray;
}

const SinglePicker: React.FC<ISingleProps> = ({
  relatedComponent,
  defaultDate,
  onSelect,
  separator,
  relatedComponentDisabled,
  anchorClassName,
  ...props
}) => {
  const {
    selected,
    isOpen,
    monthView,
    changeMonthView,
    anchorRef,
    calendarRef,
    handleChangeDate,
    toggleOpen,
    handleSelect,
  } = useSinglePicker({ defaultDate, onSelect });

  if (relatedComponent === 'input') {
    return (
      <>
        <div
          ref={anchorRef}
          className={classnames(
            'date-picker-anchor',
            'single-picker',
            anchorClassName,
          )}
        >
          <DateInput
            separator={separator}
            dateValue={selected}
            handleDateSelect={handleChangeDate}
            label="Date"
            toggleOpen={toggleOpen}
            disabled={relatedComponentDisabled}
          />
        </div>
        {isOpen && (
          <Portal>
            <Coordinate ref={calendarRef} anchorEl={anchorRef}>
              <DayPicker
                mode="single"
                selected={selected}
                onSelect={handleSelect}
                month={monthView}
                onMonthChange={changeMonthView}
                {...props}
              />
            </Coordinate>
          </Portal>
        )}
      </>
    );
  }

  return (
    <DayPicker
      mode="single"
      selected={selected}
      onSelect={handleSelect}
      {...props}
    />
  );
};

export default SinglePicker;
