import { Theme } from '@mui/material';
import { CSSObjectWithLabel, GroupBase, StylesConfig } from 'react-select';

export const defaultStyledConfig = <
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>(
  muiTheme: Theme,
  error?: boolean,
): StylesConfig<Option, IsMulti, Group> => ({
  control: (baseStyles: CSSObjectWithLabel) => ({
    ...baseStyles,
    border: error ? '2px solid red' : baseStyles.border,
  }),
  menu: (baseStyles: CSSObjectWithLabel) => ({
    ...baseStyles,
    zIndex: muiTheme.zIndex.drawer,
  }),
});
