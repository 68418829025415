import { Box } from '@mui/material';
import { colors } from 'constants/colors/colors';
import { withStyles } from 'tss-react/mui';

const SBox = withStyles(Box, {
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: colors.Catskill,
    height: 90,
  },
});

export { SBox };
