import { useActions } from 'hooks';

interface IViewAllSelectedMediaHookReturn {
  handleOpenViewMediaModal: () => void;
  handleCloseViewMediaModal: () => void;
}

const useViewAllSelectedMedia = (): IViewAllSelectedMediaHookReturn => {
  const { setModalsManageTab } = useActions();
  const handleOpenViewMediaModal: IViewAllSelectedMediaHookReturn['handleOpenViewMediaModal'] =
    () => {
      setModalsManageTab({ type: 'viewAllMedia', value: true });
    };

  const handleCloseViewMediaModal: IViewAllSelectedMediaHookReturn['handleCloseViewMediaModal'] =
    () => {
      setModalsManageTab({ type: 'viewAllMedia', value: false });
    };

  return { handleOpenViewMediaModal, handleCloseViewMediaModal };
};

export default useViewAllSelectedMedia;
