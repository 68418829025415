import { Tooltip } from '@mui/material';
import classnames from 'classnames';
import React from 'react';

import SvgIcon from '../../../../../common/SvgIcon/SvgIcon';
import { TSideBarListItem } from '../../ListItem';
import { SListItem, SListItemButton } from '../../ListItem.styled';
import { SListItemIcon } from './SmallListItem.styled';

const SmallListItem: React.FC<TSideBarListItem> = ({
  isActive,
  label,
  icon,
  onClick,
}) => {
  return (
    <Tooltip title={label.toUpperCase()} placement="right" arrow>
      <SListItem>
        <SListItemButton
          onClick={onClick}
          className={classnames({
            active: isActive,
          })}
        >
          <SListItemIcon>
            <SvgIcon>{icon}</SvgIcon>
          </SListItemIcon>
        </SListItemButton>
      </SListItem>
    </Tooltip>
  );
};

export default SmallListItem;
