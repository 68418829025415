/* eslint-disable no-bitwise */

import { IDimensionObject } from '../../types';

// /** @link [source](https://gist.github.com/jonleighton/958841) */
export const base64ArrayBuffer = (arrayBuffer: ArrayBuffer): string => {
  let base64 = '';
  const encodings =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/';

  const bytes = new Uint8Array(arrayBuffer);
  const { byteLength } = bytes;
  const byteRemainder = byteLength % 3;
  const mainLength = byteLength - byteRemainder;

  let a: number;
  let b: number;
  let c: number;
  let d: number;
  let chunk: number;

  // eslint-disable-next-line no-loops/no-loops
  for (let i = 0; i < mainLength; i += 3) {
    chunk = (bytes[i] << 16) | (bytes[i + 1] << 8) | bytes[i + 2];

    a = (chunk & 16515072) >> 18;
    b = (chunk & 258048) >> 12;
    c = (chunk & 4032) >> 6;
    d = chunk & 63;

    base64 += encodings[a] + encodings[b] + encodings[c] + encodings[d];
  }

  if (byteRemainder === 1) {
    chunk = bytes[mainLength];

    a = (chunk & 252) >> 2;

    b = (chunk & 3) << 4;

    base64 += `${encodings[a] + encodings[b]}==`;
  } else if (byteRemainder === 2) {
    chunk = (bytes[mainLength] << 8) | bytes[mainLength + 1];

    a = (chunk & 64512) >> 10;
    b = (chunk & 1008) >> 4;

    c = (chunk & 15) << 2;

    base64 += `${encodings[a] + encodings[b] + encodings[c]}=`;
  }

  return base64;
};

const getDimensionFromImage = (
  image: File,
  cb: (dimension: IDimensionObject) => void,
): void => {
  const FR = new FileReader();
  FR.onload = ({ target }) => {
    if (target?.result) {
      const img = new Image();

      if (Array.isArray(target.result)) {
        img.src = base64ArrayBuffer(target.result as ArrayBuffer);
      } else {
        img.src = target.result as string;
      }

      img.onload = () => {
        cb({ width: img.width, height: img.height });
      };

      return;
    }

    return 'image is empty';
  };
  FR.readAsDataURL(image);
};

const getSrcFromImage = (image: File, cb: (src: string) => void): void => {
  const FR = new FileReader();

  FR.onloadend = () => {
    if (FR.result instanceof ArrayBuffer) {
      const encoder = new TextDecoder('utf-8');
      cb(encoder.decode(FR.result));
    } else {
      cb(FR.result || '');
    }
  };

  FR.readAsDataURL(image);
};

/** it can be extensible */
interface IVideoMetadata {
  width: number;
  height: number;
  duration: number;
}

const getMetaDataFromVideo = (
  file: File,
  cb: (data: IVideoMetadata) => void,
): void => {
  const video = document.createElement('video');
  video.preload = 'metadata';
  video.src = URL.createObjectURL(file);
  video.onloadedmetadata = () => {
    window.URL.revokeObjectURL(video.src);

    cb({
      width: video.videoWidth,
      height: video.videoHeight,
      duration: video.duration,
    } as IVideoMetadata);
  };
};

const checkResolution = (
  /** Resolution that we expect */
  expectedResolution: IDimensionObject,

  /** Current media resolution */
  currentResolution: IDimensionObject,

  /** Allows you to slightly go out of the frame by a certain number of pixels */
  different = 0,
): boolean => {
  return (
    currentResolution.width >= expectedResolution.width - different &&
    currentResolution.width <= expectedResolution.width + different &&
    currentResolution.height >= expectedResolution.height - different &&
    currentResolution.height <= expectedResolution.height + different
  );
};

const getVideoImage = (file: File, cb: (src: string) => void): void => {
  const video = document.createElement('video');
  const canvas = document.createElement('canvas');

  const url = URL.createObjectURL(file);

  video.preload = 'metadata';
  video.src = url;

  video.onloadedmetadata = () => {
    window.URL.revokeObjectURL(video.src);

    video.currentTime = 0.5;

    video.onseeked = () => {
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      canvas
        .getContext('2d')
        ?.drawImage(video, 0, 0, canvas.width, canvas.height);

      cb(canvas.toDataURL('image/jpeg'));
    };
  };
};

export const getFileName = (
  file: File | string,
): { name: string; format: string } => {
  const isFile = file instanceof File;
  const fileSplit = isFile ? file.name.split('.') : file.split('.');

  const fileFormat = fileSplit.pop() || '';
  const fileName = fileSplit.join('');

  return { name: fileName, format: fileFormat };
};

export const getIsValidFileFormat = (file: File, formats: string): boolean => {
  const formatsArray = formats.split(', ');
  const { format } = getFileName(file);

  if (formatsArray.includes(format.toUpperCase())) {
    return true;
  }

  return false;
};

export {
  checkResolution,
  getDimensionFromImage,
  getMetaDataFromVideo,
  getSrcFromImage,
  getVideoImage,
};
