import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import { FileTypesEnum } from 'constants/enums/fileLoadStatuses';
import { useTypedSelector } from 'hooks';
import React from 'react';
import ReactPlayer from 'react-player';

import useLookingMediaModal from './LookingMediaModal.hook';

const LookingMediaModal: React.FC = () => {
  const { addMediaModal } = useTypedSelector(
    (state) => state && state.manageCampaign.manage,
  );

  const { handleLookingModalClose } = useLookingMediaModal();

  const hasMedia = Boolean(addMediaModal.currentLookingMedia);

  if (!addMediaModal.currentLookingMedia) {
    return null;
  }
  const { file, name, fileType, url } = addMediaModal.currentLookingMedia;

  const fileNameSplit = name.split('.');
  fileNameSplit.pop();
  const fileName = fileNameSplit.join('');

  const isImage = fileType === FileTypesEnum.IMAGE;
  const isVideo = fileType === FileTypesEnum.VIDEO;

  const currentUrl = isVideo && file ? URL.createObjectURL(file) : url;

  return (
    <Dialog open={hasMedia} onClose={handleLookingModalClose} maxWidth="xl">
      <DialogTitle>{fileName}</DialogTitle>
      <DialogContent>
        {isImage && (
          <img
            src={currentUrl}
            alt={name}
            style={{ maxWidth: '80vw', maxHeight: '80vh' }}
          />
        )}
        {isVideo && <ReactPlayer url={currentUrl} controls />}
      </DialogContent>
    </Dialog>
  );
};

export default LookingMediaModal;
