import { Dispatch } from 'redux';

import { IDefaultDto, IObjectIdWithName } from '../../../types/general';
import {
  CentresActionsEnum,
  TCentresAction,
} from '../../ActionTypes/CentreTypes/centreTypes';
import { addCentreApi, getCentresApi } from './centreApi';
import { IAddNewCentrePayload } from './type';

export const getCentres =
  () =>
  async (dispatch: Dispatch<TCentresAction>): Promise<void> => {
    try {
      dispatch({
        type: CentresActionsEnum.SET_LOADING,
        payload: { type: 'getAll', value: true },
      });
      const {
        data: { data },
      } = await getCentresApi<{ data: IObjectIdWithName[] }>();
      dispatch({ type: CentresActionsEnum.SET_CENTRES, payload: data });
    } catch (error) {
      console.error(error);
    } finally {
      dispatch({
        type: CentresActionsEnum.SET_LOADING,
        payload: { type: 'getAll', value: false },
      });
    }
  };

export const addNewCentre =
  (centre: IAddNewCentrePayload) =>
  async (dispatch: Dispatch<TCentresAction>): Promise<void> => {
    try {
      dispatch({
        type: CentresActionsEnum.SET_LOADING,
        payload: { type: 'addNew', value: true },
      });
      await addCentreApi<IDefaultDto<number>>(centre);
    } catch (error: any) {
      const message = error?.response?.data?.UserMessage || error?.message;

      console.error(message);
      throw new Error(error);
    } finally {
      dispatch({
        type: CentresActionsEnum.SET_LOADING,
        payload: { type: 'addNew', value: false },
      });
    }
  };
