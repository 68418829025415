import './MainLayout.scss';

import classnames from 'classnames';
import { MainLoading } from 'components/Loading';
import { LocalStorageKeysEnum } from 'constants/constants';
import { urls } from 'constants/urls/url';
import { getFromStorage } from 'helpers/storage';
import { useActions, useTypedSelector } from 'hooks';
import React, { ReactNode, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { StorageTypeEnum } from 'types/general';

interface IMainLayout {
  children: ReactNode | ReactNode[];
}

const urlsForGridTwoElements: string[] = [urls.users];

const MainLayout: React.FC<IMainLayout> = ({ children }) => {
  const { currentWindowParam } = useTypedSelector(
    (state) => state && state.app,
  );
  const { organisation } = useTypedSelector(
    (state) => state && state.organisation,
  );

  const navigate = useNavigate();
  const location = useLocation();

  const currentOrganisation = localStorage.getItem(
    LocalStorageKeysEnum.currentOrganisation,
  );

  const { getOrganisation } = useActions();

  const isOrganisation: boolean | null = getFromStorage<boolean>(
    LocalStorageKeysEnum.isOrganisation,
    StorageTypeEnum.localStorage,
  );

  useEffect(() => {
    if (!currentOrganisation && !isOrganisation) {
      navigate(urls.changePortal);
    } else {
      getOrganisation();
    }
  }, []);

  if (!organisation.id) {
    return <MainLoading />;
  }

  const isGridForTwoElement = urlsForGridTwoElements.includes(
    location.pathname,
  );

  return (
    <div
      className={classnames('main-layout', {
        isSmall: currentWindowParam.isSmall,
        isMedium: currentWindowParam.isMedium,
        gridForTwoElements: isGridForTwoElement,
      })}
    >
      {children}
    </div>
  );
};

export default MainLayout;
