import './Resolution.scss';

import { Select } from 'components/common';
import { useFormikContext } from 'formik';
import { convertToSelectData } from 'helpers';
import { useActions, useTypedSelector } from 'hooks';
import React, { useEffect } from 'react';
import { SingleValue } from 'react-select';

import { texts } from '../../../../../../constants/texts';
import { IInitialValues } from '../../../../types';

const { fromObjectIdWithName } = convertToSelectData;

const Resolution: React.FC = () => {
  const { resolutions, loadings } = useTypedSelector(
    (state) => state && state.resolution,
  );

  const { getResolutions } = useActions();
  const { values, isSubmitting, errors, setFieldValue } =
    useFormikContext<IInitialValues>();

  useEffect(() => {
    getResolutions();
  }, []);

  const handleResolutionChange = (
    newValue: SingleValue<IInitialValues['resolution']>,
  ) => {
    setFieldValue('resolution', newValue);
  };

  return (
    <div className="wrapper-resolution">
      <Select
        label={texts.pages.manage.content.mediaConfig.selects.resolution}
        placeholder={texts.pages.manage.content.mediaConfig.selects.resolution}
        options={fromObjectIdWithName(resolutions)}
        value={values.resolution}
        onChange={handleResolutionChange}
        isLoading={loadings.getAll}
        error={Boolean(errors.resolution)}
        helperText={errors.resolution}
        isDisabled={isSubmitting}
        isClearable
      />
    </div>
  );
};

export default Resolution;
