import './CampaignDeclineDialog.scss';

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { CheckCircle } from 'assets/icons';
import { colors } from 'constants/colors/colors';
import { DeclineDialogText } from 'constants/text/text';
import { useFormik } from 'formik';
import { DECLINING_SCHEMA } from 'helpers/validationSchemas';
import React, { memo } from 'react';
import { makeStyles } from 'tss-react/mui';
import { IDeclineDialogValues } from 'types';

import Button from '../../Button';
import Input from '../../Input';

interface ICampaignDeclineDialogProps {
  isDeclineDialogOpened: boolean;
  handleToggleDeclineDialog: () => void;
  handleDeclineCampaign: (values: IDeclineDialogValues) => void;
}

const useStyles = makeStyles()(() => ({
  dialogTitle: {
    '& h2': {
      textAlign: 'center',
      fontFamily: 'Poppins-Bold',
      fontSize: 30,
      fontWeight: 'bold',
      color: colors.Tundora,
    },
  },
  dialogContentText: {
    marginTop: '20px',
    color: colors.Tundora,
    opacity: 0.5,
  },
  dialogTextarea: {
    height: 'auto !important',
    '& div': {
      minWidth: 550,
      minHeight: 170,
      display: 'block',
      marginTop: '20px',
    },
  },
  dialogActions: {
    padding: '20px 8px',
    justifyContent: 'center',
    background: colors.Catskill,
  },
}));

const { header, inputLabel, placeholder, description, submit } =
  DeclineDialogText;

const CampaignDeclineDialog: React.FC<ICampaignDeclineDialogProps> = ({
  isDeclineDialogOpened,
  handleToggleDeclineDialog,
  handleDeclineCampaign,
}) => {
  const { classes } = useStyles();

  const formik = useFormik({
    initialValues: { reason: '' },
    validationSchema: DECLINING_SCHEMA,
    onSubmit: (values) => handleDeclineCampaign(values),
  });

  const { handleChange, handleSubmit, errors } = formik;

  return (
    <Dialog
      open={isDeclineDialogOpened}
      onClose={handleToggleDeclineDialog}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className="decline-dialog-container"
    >
      <DialogTitle id="alert-dialog-title" className={classes.dialogTitle}>
        {header}
      </DialogTitle>
      <form onSubmit={handleSubmit}>
        <DialogContent>
          <span className="sub-text">{inputLabel}</span>
          <Input
            name="reason"
            onChange={handleChange}
            placeholder={placeholder}
            multiline
            className={classes.dialogTextarea}
            error={Boolean(errors.reason)}
            helperText={errors.reason}
          />
          <DialogContentText
            id="alert-dialog-description"
            className={classes.dialogContentText}
          >
            {description}
          </DialogContentText>
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          {/* <Button
              variant="text"
              label={dismiss}
              className="dismiss-button"
            /> */}
          <Button
            type="submit"
            color="primary"
            label={submit}
            icon={<CheckCircle />}
          />
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default memo(CampaignDeclineDialog);
