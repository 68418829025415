import { ISiteResolutionsState } from '../../../Reducers/sites/resolutions/type';
import { IResolutionLoadingPayload } from './type';

export enum ResolutionActionEnum {
  /* <--------- Resolutions ---------> */

  SET_RESOLUTIONS = 'SET_RESOLUTIONS',

  /* <--------- Resolutions ---------> */

  SET_ORIENTATIONS = 'SET_ORIENTATIONS',

  /* <--------- Loadings ---------> */

  SET_LOADING = 'SET_LOADING_ORIENTATION',
}

interface ISetResolution {
  type: ResolutionActionEnum.SET_RESOLUTIONS;
  payload: ISiteResolutionsState['resolutions'];
}

interface ISetOrientation {
  type: ResolutionActionEnum.SET_ORIENTATIONS;
  payload: ISiteResolutionsState['orientations'];
}

interface ISetLoading {
  type: ResolutionActionEnum.SET_LOADING;
  payload: IResolutionLoadingPayload;
}

export type TResolutionActions = ISetResolution | ISetOrientation | ISetLoading;
