import { ISingleSite } from '../../../../types';
import { ISetModalsPayload } from '../../../Actions/sitesActions/main/type';
import { IGetAllSitesPayload, ISetLoadingsPayload } from './type';

export enum SitesActionTypesEnum {
  /* <--------- LEGACY ---------> */

  GET_SITES_LOADING = 'GET_SITES_LOADING',
  GET_SITES_SUCCESS = 'GET_SITES_SUCCESS',
  GET_SITES_ERROR = 'GET_SITES_ERROR',
  GET_SELECTED_SITES_LOADING = 'GET_SELECTED_SITES_LOADING',
  GET_SELECTED_SITES_SUCCESS = 'GET_SELECTED_SITES_SUCCESS',
  GET_SELECTED_SITES_ERROR = 'GET_SELECTED_SITES_ERROR',
  GET_SITES_FILTERS_LOADING = 'GET_SITES_FILTERS_LOADING',
  GET_SITES_FILTERS_SUCCESS = 'GET_SITES_FILTERS_SUCCESS',
  GET_SITES_FILTERS_ERROR = 'GET_SITES_FILTERS_ERROR',
  GET_EDIT_CAMPAIGNS_SITES_LOADING = 'GET_EDIT_CAMPAIGNS_SITES_LOADING',
  GET_EDIT_CAMPAIGNS_SITES_SUCCESS = 'GET_EDIT_CAMPAIGNS_SITES_SUCCESS',
  GET_EDIT_CAMPAIGNS_SITES_ERROR = 'GET_EDIT_CAMPAIGNS_SITES_ERROR',
  CLEAN_UP_SITES_STORE = 'CLEAN_UP_SITES_STORE',
  SITES_LENGTH = 'SITES_LENGTH',

  /* <--------- SITE ---------> */

  SET_SINGLE_SITE = 'SET_SINGLE_SITE',
  SET_CURRENT_SITE_ID = 'SET_CURRENT_SITE_ID',
  CLEANUP_CURRENT_SITE = 'CLEANUP_CURRENT_SITE',

  /* <--------- LOADING ---------> */

  SET_LOADING = 'SET_LOADING',

  /* <--------- MODALS ---------> */

  SET_MODAL = 'SET_MODAL_SITES',
}

interface IGetSitesLoadingAction {
  type: SitesActionTypesEnum.GET_SITES_LOADING;
}

interface IGetSitesSuccessAction {
  type: SitesActionTypesEnum.GET_SITES_SUCCESS;
  payload: IGetAllSitesPayload;
}

interface IGetSitesErrorAction {
  type: SitesActionTypesEnum.GET_SITES_ERROR;
  payload: boolean;
}

interface IGetSitesFiltersLoadingAction {
  type: SitesActionTypesEnum.GET_SITES_FILTERS_LOADING;
}

interface IGetSitesFiltersSuccessAction {
  type: SitesActionTypesEnum.GET_SITES_FILTERS_SUCCESS;
  payload: any;
}

interface IGetSitesFiltersErrorAction {
  type: SitesActionTypesEnum.GET_SITES_FILTERS_ERROR;
  payload: boolean;
}

interface IGetEditCampaignSitesLoadingAction {
  type: SitesActionTypesEnum.GET_EDIT_CAMPAIGNS_SITES_LOADING;
}

interface IGetEditCampaignSitesSuccessAction {
  type: SitesActionTypesEnum.GET_EDIT_CAMPAIGNS_SITES_SUCCESS;
  payload: any;
}

interface IGetEditCampaignSitesErrorAction {
  type: SitesActionTypesEnum.GET_EDIT_CAMPAIGNS_SITES_ERROR;
  payload: boolean;
}

interface IGetSelectedSitesLoadingAction {
  type: SitesActionTypesEnum.GET_SELECTED_SITES_LOADING;
}

interface IGetSelectedSitesSuccessAction {
  type: SitesActionTypesEnum.GET_SELECTED_SITES_SUCCESS;
  payload: any;
}

interface IGetSelectedSitesErrorAction {
  type: SitesActionTypesEnum.GET_SELECTED_SITES_ERROR;
  payload: boolean;
}

interface ICleanUpSitesStore {
  type: SitesActionTypesEnum.CLEAN_UP_SITES_STORE;
}

interface ISitesLengthStore {
  type: SitesActionTypesEnum.SITES_LENGTH;
  payload: number;
}

interface ISetSingleSite {
  type: SitesActionTypesEnum.SET_SINGLE_SITE;
  payload: ISingleSite;
}

interface ISiteLoading {
  type: SitesActionTypesEnum.SET_LOADING;
  payload: ISetLoadingsPayload;
}

interface ISiteModals {
  type: SitesActionTypesEnum.SET_MODAL;
  payload: ISetModalsPayload;
}

interface ISetCurrentSiteId {
  type: SitesActionTypesEnum.SET_CURRENT_SITE_ID;
  payload: number;
}

interface ICleanupCurrentSite {
  type: SitesActionTypesEnum.CLEANUP_CURRENT_SITE;
}

export type TSitesAction =
  | IGetSitesLoadingAction
  | IGetSitesSuccessAction
  | IGetSitesErrorAction
  | IGetSitesFiltersLoadingAction
  | IGetSitesFiltersSuccessAction
  | IGetSitesFiltersErrorAction
  | IGetEditCampaignSitesLoadingAction
  | IGetEditCampaignSitesSuccessAction
  | IGetEditCampaignSitesErrorAction
  | IGetSelectedSitesLoadingAction
  | IGetSelectedSitesSuccessAction
  | IGetSelectedSitesErrorAction
  | ICleanUpSitesStore
  | ISitesLengthStore
  | ISetSingleSite
  | ISiteLoading
  | ISiteModals
  | ISetCurrentSiteId
  | ICleanupCurrentSite;
