import { useTypedSelector } from 'hooks';
import React from 'react';

import SchedulesModal from './Schedules/SchedulesModal';

const Modals: React.FC = () => {
  const { modals } = useTypedSelector((state) => state && state.sites);

  return <>{modals.schedules && <SchedulesModal />}</>;
};

export default Modals;
