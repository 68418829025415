import { FieldArrayRenderProps, useFormikContext } from 'formik';
import React, { ChangeEvent, MouseEvent, ReactNode, useState } from 'react';

import { regulars } from '../../../../../../../../../../constants/regular/regulars';
import { TAntRangeValue } from '../../../../../../../../../../types';
import { initialSiteFrame } from '../../../../../../../../constants/initialValues';
import { IInitialValues, ISecondaryElement } from '../../../../../../types';
import { SecondaryText } from './components';

interface ITimeManagerHookProps {
  index: number;
}

interface ITimeManagerHookReturn {
  isRemoveButtonDisabled: boolean;
  secondaryAccordionElementData: ISecondaryElement;
  getIsExpand: (accordionNumber: number) => boolean;
  handleChangePicker: (ranges: TAntRangeValue, accordionIndex: number) => void;
  handleRemoveDayPart: (
    event: MouseEvent<HTMLButtonElement>,
    helper: FieldArrayRenderProps,
    dayPartIndex: number,
  ) => void;
  handleClickAddFrame: (helper: FieldArrayRenderProps) => void;
  handleChangeFrameAccordion: (accordionNumber: number) => void;
  handleDurationChange: (event: ChangeEvent<HTMLInputElement>) => void;
}

const useTimeManager = ({
  index,
}: ITimeManagerHookProps): ITimeManagerHookReturn => {
  const [expandedAccordions, setExpandedAccordions] = useState<number[]>([]);

  const { values, setFieldValue, handleChange } =
    useFormikContext<IInitialValues>();

  const handleChangePicker = (
    ranges: TAntRangeValue,
    accordionIndex: number,
  ): void => {
    setFieldValue(`siteDayParts[${accordionIndex}].rangeTime`, ranges);
  };

  const handleChangeFrameAccordion = (accordionNumber: number): void => {
    if (expandedAccordions.includes(accordionNumber)) {
      setExpandedAccordions((prev) =>
        prev.filter((item) => item !== accordionNumber),
      );

      return;
    }

    setExpandedAccordions((prev) => [...prev, accordionNumber]);
  };

  const getIsExpand = (accordionNumber: number): boolean => {
    if (expandedAccordions.includes(accordionNumber)) {
      return true;
    }

    return false;
  };

  const handleClickAddFrame = (helper: FieldArrayRenderProps): void => {
    helper.push(initialSiteFrame);
  };

  const secondaryAccordionElement = (
    accordionIndex: number,
  ): ISecondaryElement => {
    const isStartTime =
      Boolean(values.siteDayParts[accordionIndex].rangeTime) &&
      Boolean(values.siteDayParts[accordionIndex].rangeTime?.[0]);
    const isEndTime =
      Boolean(values.siteDayParts[accordionIndex].rangeTime) &&
      Boolean(values.siteDayParts[accordionIndex].rangeTime?.[1]);
    const isRangeTime = isStartTime || isEndTime;

    const isDuration = Boolean(values.siteDayParts[accordionIndex].duration);

    const isSiteFrames = Boolean(
      values.siteDayParts[accordionIndex].siteFrames.length,
    );

    const isCondition = isStartTime || isEndTime || isDuration || isSiteFrames;

    if (isCondition) {
      return {
        visible: true,
        element: (
          <SecondaryText
            isStartTime={isStartTime}
            isEndTime={isEndTime}
            isRangeTime={isRangeTime}
            isDuration={isDuration}
            isSiteFrames={isSiteFrames}
            index={accordionIndex}
          />
        ),
      };
    }

    return { visible: false, element: null };
  };

  const handleRemoveDayPart = (
    event: MouseEvent<HTMLButtonElement>,
    helper: FieldArrayRenderProps,
    dayPartIndex: number,
  ): void => {
    event.stopPropagation();
    helper.remove(dayPartIndex);
  };

  const handleDurationChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (!event.target.value || regulars.onlyNumber.test(event.target.value)) {
      handleChange(event);
    }
  };

  const secondaryAccordionElementData = secondaryAccordionElement(index);
  const isRemoveButtonDisabled = values.siteDayParts.length <= 1;

  return {
    isRemoveButtonDisabled,
    secondaryAccordionElementData,
    getIsExpand,
    handleChangePicker,
    handleRemoveDayPart,
    handleClickAddFrame,
    handleChangeFrameAccordion,
    handleDurationChange,
  };
};

export default useTimeManager;
