import { Tab as MuiTab } from '@mui/material';
import { AdjustmentIcon, CampaignIcon } from 'assets/icons';
import classNames from 'classnames';
import SvgIcon from 'components/common/SvgIcon/SvgIcon';
import { ManageCampaignTabsEnum } from 'constants/enums/campaign';
import { useActions, useTypedSelector } from 'hooks';
import React, { SyntheticEvent } from 'react';

import { texts } from '../../../../../../constants/texts';
import useInspector from '../../../../hooks/useInspector';
import { STabsList } from './TabsList.styled';

const TabsList: React.FC = () => {
  const { activeTab } = useTypedSelector(
    (state) => state && state.manageCampaign.main,
  );
  const { changeCampaignTab } = useActions();
  const { getIsCheckDetailFullFill } = useInspector();

  const handleTabChange = (
    __: SyntheticEvent,
    newValue: ManageCampaignTabsEnum,
  ) => {
    changeCampaignTab(newValue);
  };

  const isDetailTab = activeTab === ManageCampaignTabsEnum.DETAIL;
  const isManageTab = activeTab === ManageCampaignTabsEnum.MANAGE;

  return (
    <STabsList value={activeTab} onChange={handleTabChange}>
      <MuiTab
        label={texts.tabs.details}
        value={ManageCampaignTabsEnum.DETAIL}
        id={ManageCampaignTabsEnum.DETAIL}
        icon={
          <SvgIcon className="tab-icon_margin add-campaign-details-icon">
            <CampaignIcon
              className={classNames('dark-icon', {
                'active-icon': isDetailTab,
              })}
            />
          </SvgIcon>
        }
        sx={{ flexDirection: 'row', gap: 1 }}
      />
      <MuiTab
        label={texts.tabs.manage}
        value={ManageCampaignTabsEnum.MANAGE}
        id={ManageCampaignTabsEnum.MANAGE}
        icon={
          <SvgIcon className="tab-icon_margin icon-s">
            <AdjustmentIcon
              className={classNames('dark-icon', {
                'active-icon': isManageTab,
              })}
            />
          </SvgIcon>
        }
        sx={{ flexDirection: 'row', gap: 1 }}
        disabled={!getIsCheckDetailFullFill()}
      />
    </STabsList>
  );
};

export default TabsList;
