import { useTypedSelector } from 'hooks';
import React from 'react';

import { IListItem } from '../../types';
import { DefaultListItem, SmallListItem } from './components';

interface ISideBarListItem extends IListItem {
  isActive?: boolean;
}

export type TSideBarListItem = Omit<ISideBarListItem, 'id'>;

const SideBarListItem: React.FC<TSideBarListItem> = (props) => {
  const { isExpand } = useTypedSelector((state) => state && state.sideBar);
  const { currentWindowParam } = useTypedSelector(
    (state) => state && state.app,
  );

  if (currentWindowParam.isSmall && !isExpand) {
    return <SmallListItem {...props} />;
  }

  return <DefaultListItem {...props} />;
};

export default SideBarListItem;
