import { ICategory } from '../../../types';

export interface ICategoryState {
  categories: ICategory[];
  hasErrorCategoriesLoading: boolean;
  isLoadingCategories: boolean;
}
export interface IChooseCategoryState {
  chooseCategory: ICategory[];
  errorChooseCategoriesLoading: boolean;
  loadingChooseCategories: boolean;
}

export enum CategoryActionTypesEnum {
  GET_CATEGORIES_LOADING = 'GET_CATEGORIES_LOADING',
  GET_CATEGORIES_SUCCESS = 'GET_CATEGORIES_SUCCESS',
  GET_CATEGORIES_ERROR = 'GET_CATEGORIES_ERROR',
  GET_CATEGORY_CACHE_LOADING = 'GET_CATEGORY_CACHE_LOADING',
  GET_CATEGORY_CACHE_SUCCESS = 'GET_CATEGORY_CACHE_SUCCESS',
  GET_CATEGORY_CACHE_ERROR = 'GET_CATEGORY_CACHE_ERROR',
}

interface IGetCategoryLoadingAction {
  type: CategoryActionTypesEnum.GET_CATEGORIES_LOADING;
}
interface IGetCategorySuccessAction {
  type: CategoryActionTypesEnum.GET_CATEGORIES_SUCCESS;
  payload: ICategory[];
}
interface IGetCategoryErrorAction {
  type: CategoryActionTypesEnum.GET_CATEGORIES_ERROR;
  payload: object;
}

interface IGetCategoryCachrLoadingAction {
  type: CategoryActionTypesEnum.GET_CATEGORY_CACHE_LOADING;
}
interface IGetCategoryCacheSuccessAction {
  type: CategoryActionTypesEnum.GET_CATEGORY_CACHE_SUCCESS;
  payload: ICategory[];
}
interface IGetCategoryCacheErrorAction {
  type: CategoryActionTypesEnum.GET_CATEGORY_CACHE_ERROR;
  payload: object;
}

export type TCategoryAction =
  | IGetCategoryLoadingAction
  | IGetCategorySuccessAction
  | IGetCategoryErrorAction
  | IGetCategoryCachrLoadingAction
  | IGetCategoryCacheSuccessAction
  | IGetCategoryCacheErrorAction;
