import { useTheme } from '@mui/material';
import classnames from 'classnames';
import React, { ReactElement } from 'react';
import { GroupBase, StylesConfig } from 'react-select';
import ReactSelect, { CreatableProps } from 'react-select/creatable';

import { defaultStyledConfig } from '../../Select.styled';

type TSelectProps<
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
> = {
  label?: string;
  error?: boolean;
  helperText?: string;
  styles?: StylesConfig<Option>;
} & CreatableProps<Option, IsMulti, Group>;

const CreatableSelect = <
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>({
  label,
  error,
  helperText,
  styles,
  ...selectProps
}: TSelectProps<Option, IsMulti, Group>): ReactElement => {
  const theme = useTheme();
  const stylesConfig: StylesConfig<Option, IsMulti, Group> = {
    ...defaultStyledConfig(theme, error),
    ...styles,
  };

  return (
    <div className="select-wrapper">
      {label && <div className="select-label">{label}</div>}
      <ReactSelect<Option, IsMulti, Group>
        {...selectProps}
        styles={stylesConfig}
      />
      {helperText && (
        <div className={classnames('select-helper-text', { error })}>
          {helperText}
        </div>
      )}
    </div>
  );
};

export default CreatableSelect;
