import { Dispatch } from 'redux';

import { LocalStorageKeysEnum } from '../../../constants/constants';
import { getToken } from '../../../helpers/jwt';
import { setToStorage } from '../../../helpers/storage';
import {
  IMagicLinkSendData,
  IResetPasswordDto,
  ISignInDto,
  UserRolesEnum,
} from '../../../types';
import {
  AuthActionTypesEnum,
  TAuthAction,
} from '../../ActionTypes/authTypes/authTypes';
import {
  forgotPasswordMessage,
  loginMagicLink,
  loginUser,
  userWithNewPassword,
} from './authApi';

export const signIn =
  (user: object) =>
  async (dispatch: Dispatch<TAuthAction>): Promise<void> => {
    try {
      dispatch({ type: AuthActionTypesEnum.LOGIN_LOADING });
      const {
        data: { data },
      } = await loginUser<ISignInDto>(user);

      setToStorage({
        [LocalStorageKeysEnum.jwtToken]: data.jwtToken,
        [LocalStorageKeysEnum.isOrganisation]: data.hasOrganisation,
        [LocalStorageKeysEnum.userRole]: data.role,
      });

      dispatch({
        type: AuthActionTypesEnum.LOGIN_SUCCESS,
        payload: data,
      });
    } catch (error: any) {
      dispatch({
        type: AuthActionTypesEnum.LOGIN_ERROR,
        payload: error?.response?.data?.UserMessage || error,
      });
    }
  };

export const signOut =
  () =>
  (dispatch: Dispatch<TAuthAction>): void => {
    localStorage.clear();
    dispatch({ type: AuthActionTypesEnum.SIGN_OUT });
  };

export const setNewPassword =
  (user: object) =>
  async (dispatch: Dispatch<TAuthAction>): Promise<void> => {
    try {
      dispatch({ type: AuthActionTypesEnum.RESET_PASSWORD_LOADING });
      const { data } = await userWithNewPassword<IResetPasswordDto>(user);
      dispatch({
        type: AuthActionTypesEnum.RESET_PASSWORD_SUCCESS,
        payload: data.data,
      });
    } catch (error: any) {
      dispatch({
        type: AuthActionTypesEnum.RESET_PASSWORD_ERROR,
        payload: error?.response?.data?.UserMessage || error,
      });
    }
  };

export const sendForgotPasswordEmail =
  (user: object) =>
  async (dispatch: Dispatch<TAuthAction>): Promise<void> => {
    try {
      dispatch({ type: AuthActionTypesEnum.FORGOT_PASSWORD_LOADING });
      await forgotPasswordMessage(user);
      dispatch({ type: AuthActionTypesEnum.FORGOT_PASSWORD_SUCCESS });
    } catch (error: any) {
      dispatch({
        type: AuthActionTypesEnum.FORGOT_PASSWORD_ERROR,
        payload: error?.response?.data?.UserMessage || error,
      });
    }
  };

export const checkIsLogged =
  () =>
  async (dispatch: Dispatch<TAuthAction>): Promise<void> => {
    try {
      const authenticated = () => {
        const token = getToken();
        const role = localStorage.getItem(
          LocalStorageKeysEnum.userRole,
        ) as UserRolesEnum;
        if (token && role) {
          return dispatch({
            type: AuthActionTypesEnum.IS_LOGGED,
            payload: { jwtToken: token, role },
          });
        }

        return null;
      };
      authenticated();
    } catch (error: any) {
      dispatch({
        type: AuthActionTypesEnum.IS_UNAUTHORIZE_USER,
        payload: { errorMessage: 'Unauthorized user' },
      });
    }
  };

export const sendMagicLink =
  (userData: IMagicLinkSendData) =>
  async (dispatch: Dispatch<TAuthAction>): Promise<void> => {
    try {
      dispatch({ type: AuthActionTypesEnum.SEND_MAGIC_LINK_LOADING });
      const { data } = await loginMagicLink(userData);
      if (!data) {
        throw new Error();
      }
      dispatch({ type: AuthActionTypesEnum.SEND_MAGIC_LINK_SUCCESS });
    } catch (error: any) {
      dispatch({
        type: AuthActionTypesEnum.SEND_MAGIC_LINK_ERROR,
        payload: error?.response?.data?.UserMessage || error,
      });
    }
  };

export const signInWithMagicLink =
  (jwtToken: string) =>
  (dispatch: Dispatch<TAuthAction>): void => {
    try {
      dispatch({ type: AuthActionTypesEnum.LOGIN_LOADING });
      localStorage.setItem(LocalStorageKeysEnum.jwtToken, jwtToken);
      dispatch({
        type: AuthActionTypesEnum.LOGIN_SUCCESS,
        payload: { jwtToken },
      });
    } catch (error: any) {
      dispatch({
        type: AuthActionTypesEnum.LOGIN_ERROR,
        payload: error?.response?.data?.UserMessage || error,
      });
    }
  };

export const cleanUpAuthStore =
  () =>
  (dispatch: Dispatch<TAuthAction>): void => {
    dispatch({ type: AuthActionTypesEnum.CLEAN_UP_AUTH_STORE });
  };
