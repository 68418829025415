import { isAfter } from 'date-fns';
import { useOnClickOutsideExpand } from 'hooks';
import { RefObject, useEffect, useRef, useState } from 'react';
import { MonthChangeEventHandler } from 'react-day-picker';

import { CurrentFocusEnum, MonthTogglerEnum } from '../../enum';
import { TRangeDate, TSingleDate } from '../../types';
import { IRangePickerProps } from './RangePicker';

interface IRangePickerHookProps {
  defaultDate: IRangePickerProps['defaultDate'];
  onSelect: IRangePickerProps['onSelect'];
}

interface IRangePickerHookReturn {
  isOpen: boolean;
  currentViewMonth: Date;
  selected: TRangeDate;
  numberOfMonth: MonthTogglerEnum;
  anchorRef: RefObject<HTMLDivElement>;
  calendarRef: RefObject<HTMLDivElement>;
  handleDateStart: (date: TSingleDate) => void;
  handleDateEnd: (date: TSingleDate) => void;
  toggleOpenWithStart: () => void;
  toggleOpenWithEnd: () => void;
  handleSelect: (date: TRangeDate) => void;
  handleMonthChange: MonthChangeEventHandler;
  handleNumberOfMonth: (
    _event: React.MouseEvent<HTMLElement>,
    newAlignment: MonthTogglerEnum | null,
  ) => void;
}

const useRangePicker = ({
  defaultDate,
  onSelect,
}: IRangePickerHookProps): IRangePickerHookReturn => {
  const [selected, setSelected] = useState<TRangeDate>(defaultDate);
  const [currentViewMonth, setCurrentViewMonth] = useState<Date>(new Date());
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [currentFocus, setCurrentFocus] = useState<
    CurrentFocusEnum | undefined
  >();
  const [numberOfMonth, setNumberOfMonths] = useState<MonthTogglerEnum>(1);

  useEffect(() => {
    setSelected(defaultDate);
  }, [defaultDate]);

  useEffect(() => {
    if (selected?.to && selected?.from) {
      if (isAfter(selected.from, selected.to)) {
        setSelected({ from: selected.to, to: selected.from });

        return;
      }
    }

    onSelect?.(selected);
  }, [selected]);

  const anchorRef = useRef<HTMLDivElement>(null);
  const calendarRef = useRef<HTMLDivElement>(null);

  useOnClickOutsideExpand([anchorRef, calendarRef], () => setIsOpen(false));

  /** Needs for Input Start Date */
  const handleDateStart = (date: TSingleDate): void => {
    setSelected((prev) => ({ ...prev, from: date }));

    if (date) {
      setCurrentViewMonth(date);
    }
  };

  /** Needs for Input End Date */
  const handleDateEnd = (date: TSingleDate): void => {
    if (selected?.from) {
      setSelected((prev) => ({ from: prev?.from, to: date }));
    } else {
      setSelected({ from: undefined, to: date });
    }

    if (date) {
      setCurrentViewMonth(date);
    }
  };

  const toggleOpenWithStart = (): void => {
    if (isOpen) {
      setCurrentFocus(undefined);
    } else {
      setCurrentFocus(CurrentFocusEnum.START);
    }
    setIsOpen((prev) => !prev);
  };

  const toggleOpenWithEnd = (): void => {
    if (isOpen) {
      setCurrentFocus(undefined);
    } else {
      setCurrentFocus(CurrentFocusEnum.END);
    }
    setIsOpen((prev) => !prev);
  };

  const handleSelect = (date: TRangeDate): void => {
    if (!date) {
      setSelected(date);

      return;
    }

    if (!selected?.from && date && currentFocus === CurrentFocusEnum.END) {
      setSelected({ from: undefined, to: date.from });
      setCurrentFocus(CurrentFocusEnum.START);

      return;
    }

    if (currentFocus === CurrentFocusEnum.START && selected?.to && date.from) {
      setSelected((prev) => ({ from: date.from, to: prev?.to }));
      setCurrentFocus(undefined);

      return;
    }

    setSelected(date);
  };

  const handleMonthChange: MonthChangeEventHandler = (date: Date): void => {
    setCurrentViewMonth(date);
  };

  const handleNumberOfMonth = (
    _event: React.MouseEvent<HTMLElement>,
    newAlignment: MonthTogglerEnum | null,
  ): void => {
    if (newAlignment) {
      setNumberOfMonths(newAlignment);
    }
  };

  return {
    isOpen,
    currentViewMonth,
    selected,
    numberOfMonth,
    anchorRef,
    calendarRef,
    handleDateStart,
    handleDateEnd,
    toggleOpenWithStart,
    toggleOpenWithEnd,
    handleSelect,
    handleMonthChange,
    handleNumberOfMonth,
  };
};

export default useRangePicker;
