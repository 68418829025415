import { AxiosPromise } from 'axios';

import { AxiosMethodEnum } from '../../../constants/text/text';
import { urls } from '../../../constants/urls/url';
import { apiCall } from '../../../helpers/api/apiCall';
import { INewCategoryCache } from './type';

function getProducts<T extends object>(
  url: string,
  advertiserId?: number,
): AxiosPromise<T> {
  return apiCall<T>(AxiosMethodEnum.get, url, '', { advertiserId }, '', {});
}

function postProducts<T extends object>(
  url: string,
  data: object,
): AxiosPromise<T> {
  return apiCall<T>(AxiosMethodEnum.post, url, data, null, '', {});
}

function postProductsCache<T extends object>(
  url: string,
  data: object,
): AxiosPromise<T> {
  return apiCall<T>(AxiosMethodEnum.post, url, data, null, '', {});
}

export const allProducts = <T extends object>(
  advertiserId?: number,
): AxiosPromise<T> => getProducts<T>(urls.products, advertiserId);

export const newProduct = <T extends object>(data: object): AxiosPromise<T> =>
  postProducts<T>(urls.products, data);

export const newProductCache = <T extends object>(
  data: INewCategoryCache,
): AxiosPromise<T> => postProductsCache<T>(urls.categoryProductCache, data);
