import {
  AuthActionTypesEnum,
  IAuthState,
  TAuthAction,
} from '../../ActionTypes/authTypes/authTypes';

const initialState: IAuthState = {
  user: [],
  isLoading: false,
  error: false,
  hasLoggedIn: false,
  jwtToken: '',
  role: null,
  errorMessage: false,
  forgotError: false,
  isForgotLoading: false,
  isForgotPasswordStatus: false,
  isResetPasswordStatus: false,
  isResetPasswordLoading: false,
  resetPasswordError: false,
  isSendMagicLinkLoading: false,
  isSendMagicLinkStatus: false,
  sendMagicLinkError: false,
};

const authReducer = (state = initialState, action: TAuthAction): IAuthState => {
  switch (action.type) {
    case AuthActionTypesEnum.LOGIN_LOADING:
      return {
        ...state,
        isLoading: true,
        error: null,
        jwtToken: null,
        errorMessage: false,
      };
    case AuthActionTypesEnum.LOGIN_SUCCESS:
      return {
        ...state,
        user: action.payload.user,
        jwtToken: action.payload.jwtToken,
        role: action.payload.role,
        isLoading: false,
        hasLoggedIn: true,
        error: false,
        errorMessage: false,
      };
    case AuthActionTypesEnum.LOGIN_WITH_MAGIC_LINK_SUCCESS:
      return {
        ...state,
        jwtToken: action.payload.jwtToken,
        isLoading: false,
        hasLoggedIn: true,
        error: false,
        errorMessage: false,
      };
    case AuthActionTypesEnum.LOGIN_ERROR:
      return {
        ...state,
        error: true,
        isLoading: false,
        hasLoggedIn: false,
        jwtToken: null,
        errorMessage: action.payload,
      };
    case AuthActionTypesEnum.FORGOT_PASSWORD_LOADING:
      return {
        ...state,
        forgotError: false,
        isForgotLoading: true,
        isForgotPasswordStatus: false,
      };
    case AuthActionTypesEnum.FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        isForgotLoading: false,
        isForgotPasswordStatus: true,
      };
    case AuthActionTypesEnum.FORGOT_PASSWORD_ERROR:
      return {
        ...state,
        forgotError: action.payload,
        isForgotLoading: false,
        isForgotPasswordStatus: false,
      };
    case AuthActionTypesEnum.RESET_PASSWORD_LOADING:
      return {
        ...state,
        resetPasswordError: false,
        isResetPasswordLoading: true,
        isResetPasswordStatus: false,
      };
    case AuthActionTypesEnum.RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        isResetPasswordLoading: false,
        isResetPasswordStatus: true,
      };
    case AuthActionTypesEnum.RESET_PASSWORD_ERROR:
      return {
        ...state,
        isResetPasswordLoading: false,
        isResetPasswordStatus: false,
        resetPasswordError: action.payload,
      };
    case AuthActionTypesEnum.SEND_MAGIC_LINK_LOADING:
      return {
        ...state,
        resetPasswordError: false,
        isSendMagicLinkLoading: true,
      };
    case AuthActionTypesEnum.SEND_MAGIC_LINK_SUCCESS:
      return {
        ...state,
        isSendMagicLinkLoading: false,
        isSendMagicLinkStatus: true,
      };
    case AuthActionTypesEnum.SEND_MAGIC_LINK_ERROR:
      return {
        ...state,
        isSendMagicLinkStatus: false,
        isSendMagicLinkLoading: false,
        sendMagicLinkError: action.payload,
      };
    case AuthActionTypesEnum.IS_LOGGED:
      return {
        ...state,
        hasLoggedIn: true,
        jwtToken: action.payload.jwtToken,
        role: action.payload.role,
      };
    case AuthActionTypesEnum.SIGN_OUT:
      return {
        ...state,
        user: [],
        hasLoggedIn: false,
        jwtToken: '',
        role: null,
      };
    case AuthActionTypesEnum.CLEAN_UP_AUTH_STORE:
      return {
        ...state,
        error: false,
        errorMessage: false,
        forgotError: false,
        isForgotLoading: false,
        isForgotPasswordStatus: false,
        isResetPasswordStatus: false,
        isResetPasswordLoading: false,
        resetPasswordError: false,
        isSendMagicLinkLoading: false,
        isSendMagicLinkStatus: false,
        sendMagicLinkError: false,
        hasLoggedIn: false,
      };
    default:
      return state;
  }
};

export default authReducer;
