import { urls } from 'constants/urls/url';

export const getValidRedirectTo = (redirectTo: string | null): string => {
  if (!redirectTo) {
    return urls.campaign;
  }

  if (redirectTo.startsWith('/')) {
    return redirectTo;
  }

  return `/${redirectTo}`;
};
