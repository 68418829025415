import { AlertStatusesEnum } from 'constants/constants';
import { useActions, useTypedSelector } from 'hooks';
import { useSnackbar } from 'notistack';

interface IDeleteOneHookReturn {
  handleOpen: () => void;
  handleClose: () => void;
  handleDeleteUser: (id: number | undefined) => void;
}

const useDeleteOne = (): IDeleteOneHookReturn => {
  const { loaders, queryParams } = useTypedSelector(
    (state) => state && state.users,
  );

  const { enqueueSnackbar } = useSnackbar();

  const {
    deleteUser,
    getAllUsers,
    usersModalToggler,
    allUserTableRemoveCurrentClickedUser,
  } = useActions();

  const handleOpen = () => {
    usersModalToggler({ type: 'deleteOne', value: true });
  };

  const handleClose = () => {
    if (!loaders.deleteOne) {
      usersModalToggler({ type: 'deleteOne', value: false });
      allUserTableRemoveCurrentClickedUser();
    }
  };

  const handleDeleteUser = async (id: number | undefined) => {
    if (id) {
      try {
        await deleteUser(id);
        enqueueSnackbar('User successfully delete', {
          variant: AlertStatusesEnum.SUCCESS,
        });
        await getAllUsers(queryParams.getAll);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        console.error(error);
        enqueueSnackbar(error?.response?.data?.UserMessage, {
          variant: AlertStatusesEnum.ERROR,
        });
      }
    }
  };

  return { handleOpen, handleClose, handleDeleteUser } as const;
};

export default useDeleteOne;
