import { useActions, useTypedSelector } from 'hooks';
import { useEffect, useMemo } from 'react';
import { IGetAllUsersParams } from 'redux/Actions/users/types';
import { useDebounce } from 'usehooks-ts';

const useUserContentQuery = (): void => {
  const { allUsersTable } = useTypedSelector((state) => state && state.users);

  const { getAllUsers, setAllUsersParams } = useActions();
  const debounceGlobalSearch = useDebounce(allUsersTable.globalSearch, 500);

  const allUsersParams = useMemo<IGetAllUsersParams>(
    () => ({
      SearchQuery: debounceGlobalSearch,
      PageSize: allUsersTable.pagination.pageSize,
      PageNumber: allUsersTable.pagination.currentPage,
      SortDirection: allUsersTable.sort.direction,
      SortColumn: allUsersTable.sort.column,
    }),
    [debounceGlobalSearch, allUsersTable.pagination, allUsersTable.sort],
  );

  useEffect(() => {
    getAllUsers(allUsersParams);
    setAllUsersParams(allUsersParams);
  }, [allUsersParams]);
};

export default useUserContentQuery;
