import { INetworkState } from '../../Reducers/network/type';
import { INetworksLoadingPayload } from './type';

export enum NetworksActionsEnum {
  /* <--------- Networks ---------> */
  SET_NETWORKS = 'SET_NETWORKS',

  /* <--------- Loadings ---------> */
  SET_LOADING = 'SET_LOADING_NETWORK',
}

interface ISetNetworks {
  type: NetworksActionsEnum.SET_NETWORKS;
  payload: INetworkState['networks'];
}

interface ISetLoading {
  type: NetworksActionsEnum.SET_LOADING;
  payload: INetworksLoadingPayload;
}

export type TNetworksAction = ISetNetworks | ISetLoading;
