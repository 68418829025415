import { TablePagination } from '@mui/material';
import { withStyles } from 'tss-react/mui';

const STablePagination = withStyles(TablePagination, {
  toolbar: {
    boxSizing: 'border-box',
    minHeight: 52,
    paddingRight: 50,
    fontSize: 14,

    '& > p': {
      fontSize: 14,
      marginBottom: 0,
    },

    '.MuiInputBase-root': {
      border: '1px solid #ccc',
      borderRadius: 5,

      '.MuiSelect-nativeInput': {
        fontSize: 14,
      },
    },
  },
});

export { STablePagination };
