export const texts = {
  add: 'New campaign',
  edit: 'Edit campaign',
  review: 'Review campaign',
  buttons: {
    saveAsDraft: 'Save as draft',
    publish: 'Publish',
    update: 'Update',
    decline: 'Decline',
    approve: 'Approve',
    next: 'Next: Manage Campaign',
    cancel: 'Cancel',
    agree: 'Agree',
  },
  tabs: {
    details: 'Campaign Details',
    manage: 'Manage Campaign',
  },
} as const;
