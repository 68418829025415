import './ChangePortal.scss';

import { QuestionMark } from 'assets/icons';
import { LocalLoading } from 'components/Loading';
import { AlertStatusesEnum, LocalStorageKeysEnum } from 'constants/constants';
import { AxiosMethodEnum } from 'constants/text/text';
import { urls } from 'constants/urls/url';
import { apiCall } from 'helpers/api/apiCall';
import { useActions } from 'hooks/useActions';
import { useTypedSelector } from 'hooks/useTypedSelector';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { IOrganisation, IOrganisationsDto } from 'types';

import { ILoadingLogoIds } from './interfaces';

const ChangePortalPage: React.FC = () => {
  const [organisations, setOrganisations] = useState<IOrganisation[]>([]);
  const [isLoadingLogo, setIsLoadingLogo] = useState<ILoadingLogoIds>({});

  const [errors, setErrors] = useState('');
  const { enqueueSnackbar } = useSnackbar();

  const { organisation } = useTypedSelector(
    (state) => state && state.organisation,
  );

  const { getOrganisation, cleanUpAuthStore } = useActions();
  const navigate = useNavigate();

  const keyNameForGetOrganisation = 'Enter';

  const organisationId = localStorage.getItem(
    LocalStorageKeysEnum.currentOrganisation,
  );

  const getOrganisations = async () => {
    try {
      const { data } = await apiCall<IOrganisationsDto>(
        AxiosMethodEnum.get,
        'organisations',
      );
      setOrganisations(data.data);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      if (error.response.status === 401) {
        cleanUpAuthStore();
        setErrors('You need to log in again');

        return;
      }
      setErrors('There is no data about the organizations');
    }
  };

  const handleKey = ({ key }: { key: string }, id: number) => {
    if (key === keyNameForGetOrganisation) {
      getOrganisation(id);
    }
  };

  const handleClickOrganisation = (id: number) => {
    getOrganisation(id);
  };

  const handleError = (id: number) => {
    setIsLoadingLogo({ [id]: true });
  };

  useEffect(() => {
    if (organisation.id) {
      navigate(urls.campaign);
    }
  }, [organisation.id]);

  useEffect(() => {
    if (organisationId) {
      getOrganisation();
    } else {
      getOrganisations();
    }
  }, []);

  useEffect(() => {
    if (errors) {
      enqueueSnackbar(String(errors), { variant: AlertStatusesEnum.ERROR });
    }
  }, [errors]);

  return (
    <div className="change-organisations-page">
      <div className="container">
        <span className="title">Select portal</span>
        {![organisations].length && (
          <div className="loading-container">
            <LocalLoading />
          </div>
        )}
        <div className="container-blocks">
          {organisations.map(({ id, name, logoUrl }) => (
            <div
              key={id}
              className="organisation-block"
              onKeyDown={(e) => handleKey(e, id)}
              onClick={() => handleClickOrganisation(id)}
              role="button"
              tabIndex={0}
            >
              <div className="organisation-logo-block">
                <img
                  src={logoUrl}
                  onError={() => handleError(id)}
                  alt={name}
                  className={
                    isLoadingLogo[`${id}`]
                      ? 'organisations_logo-hidden'
                      : 'organisations_logo'
                  }
                />
                {isLoadingLogo[`${id}`] && (
                  <QuestionMark className="error-logo" />
                )}
              </div>
              <span className="organisation-name">{name}</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ChangePortalPage;
