import { TCoordinate } from '../../../types';

interface ICoordinateHookReturn {
  calculatePosition: <El extends HTMLElement>(
    anchorEl: El,
    containerEl: El,
  ) => TCoordinate;
}

const TOP_SPACE = 15;
const RIGHT_SPACE = 15;

const useCoordinate = (): ICoordinateHookReturn => {
  const calculatePosition = <El extends HTMLElement>(
    anchorEl: El,
    containerEl: El,
  ): TCoordinate => {
    const anchorGraph = anchorEl.getBoundingClientRect();
    const containerGraph = containerEl.getBoundingClientRect();

    const windowSizes = {
      width: window.innerWidth,
      height: window.innerHeight,
    };

    const isReachesRightEdge =
      anchorGraph.left + containerGraph.width > windowSizes.width;
    const isReachesBottomEdge =
      anchorGraph.bottom + containerGraph.height > windowSizes.height;
    const isReachesTopEdge =
      anchorGraph.top - TOP_SPACE - containerGraph.height < 0;

    if (isReachesRightEdge) {
      if (isReachesBottomEdge) {
        return {
          top: anchorGraph.top - containerGraph.height - TOP_SPACE,
          right: RIGHT_SPACE,
        };
      }

      return { top: anchorGraph.bottom + TOP_SPACE, right: RIGHT_SPACE };
    }

    if (isReachesBottomEdge) {
      if (isReachesTopEdge) {
        return {
          bottom: TOP_SPACE,
          left: anchorGraph.left,
        };
      }

      return {
        top: anchorGraph.top - containerGraph.height - TOP_SPACE,
        left: anchorGraph.left,
      };
    }

    return {
      top: anchorGraph.bottom + TOP_SPACE,
      left: anchorGraph.left,
    };
  };

  return { calculatePosition };
};

export default useCoordinate;
