import { dayOsWeekFull, getInitialDaysOfWeek } from 'constants/date/date';
import { format, parseISO } from 'date-fns';
import { ISiteDayPartsNew, TDaysOfWeek } from 'types';

interface IOptionsFormat {
  locale?: Locale;
  weekStartOn: 0 | 2 | 1 | 3 | 4 | 5 | 6;
  firstWeekContainsDate?: number;
  useAdditionalWeekYearTokens?: boolean;
  useAdditionalDayOfYearTokens?: boolean;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
interface IParseISOOptions {
  additionalDigits?: 0 | 1 | 2;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export const formatFromISO = (
  date: string | undefined,
  dateFormat: string,
  options?: IOptionsFormat,
  parseOptions?: IParseISOOptions,
): string => {
  if (!date) {
    return '';
  }

  return format(parseISO(date, parseOptions), dateFormat, options);
};

export const getDaysOfWeek = (days?: string): Record<TDaysOfWeek, boolean> => {
  if (!days || days === 'All') {
    return getInitialDaysOfWeek();
  }

  return {
    Mon: days.indexOf('Monday') !== -1,
    Tue: days.indexOf('Tuesday') !== -1,
    Wed: days.indexOf('Wednesday') !== -1,
    Thu: days.indexOf('Thursday') !== -1,
    Fri: days.indexOf('Friday') !== -1,
    Sat: days.indexOf('Saturday') !== -1,
    Sun: days.indexOf('Sunday') !== -1,
  };
};

export const getDayPartFromDuration = (
  dayParts: ISiteDayPartsNew[],
  duration: number | undefined,
): ISiteDayPartsNew => {
  if (!duration) {
    return dayParts[0];
  }

  const lookingDayPart = dayParts.find(
    (dayPart) => dayPart.adLength === duration,
  );

  if (!lookingDayPart) {
    return dayParts[0];
  }

  return lookingDayPart;
};

export const getDayOfWeekString = (
  days: Record<TDaysOfWeek, boolean>,
): string => {
  const isAll = Object.values(days).every((item) => !!item);

  if (isAll) {
    return 'All';
  }

  return Object.entries(days).reduce((acc, [key, value]) => {
    let daysOfWeekString = acc;

    if (value) {
      if (!daysOfWeekString) {
        daysOfWeekString = dayOsWeekFull[key as TDaysOfWeek];
      } else {
        daysOfWeekString = `${daysOfWeekString},${
          dayOsWeekFull[key as TDaysOfWeek]
        }`;
      }
    }

    return daysOfWeekString;
  }, '');
};

export const changeValueToDayPartsOfWeek = (
  days: Record<TDaysOfWeek, boolean>,
  day: TDaysOfWeek,
  value: boolean,
): Record<TDaysOfWeek, boolean> => {
  days[day] = value;

  return days;
};

export const removeTimezone = (date: Date): Date => {
  const timezone = date.getTimezoneOffset() * 60000;

  return new Date(date.getTime() - timezone);
};

export const getStringFromDate = (date: Date, separator?: string): string => {
  const day = date.toLocaleDateString('en-US', { day: '2-digit' });
  const month = date.toLocaleDateString('en-US', { month: '2-digit' });
  const year = date.toLocaleDateString('en-US', { year: 'numeric' });

  return `${day}${separator || ''}${month}${separator || ''}${year}`;
};
