import { Select } from 'components/common';
import {
  CampaignTypesEnum,
  ManageCampaignModeEnum,
} from 'constants/enums/campaign';
import { convertToSelectData } from 'helpers';
import { useActions, useTypedSelector } from 'hooks';
import { constants } from 'pages/Campaign/constants';
import React from 'react';
import { SingleValue } from 'react-select';
import { ISelectOption } from 'types';

const { fromObjectIdWithName } = convertToSelectData;

const Category: React.FC = () => {
  const { mode } = useTypedSelector(
    (state) => state && state.manageCampaign.main,
  );
  const { category, product, loaders, campaignType } = useTypedSelector(
    (state) => state && state.manageCampaign.detail,
  );
  const { categories, isLoadingCategories } = useTypedSelector(
    (state) => state && state.category,
  );

  const {
    setCategoryManageCampaign,
    setAutoSubstitutionCategory,
    setCategoryToCache,
  } = useActions();

  const handleChange = (value: SingleValue<ISelectOption<number>>): void => {
    setCategoryManageCampaign(value);

    if (value) {
      setAutoSubstitutionCategory(false);
    } else {
      setAutoSubstitutionCategory(true);
    }

    if (product?.value && value) {
      setCategoryToCache({ productId: product.value, categoryId: value.value });
    }
  };

  const isLoading = loaders.getCategoryFromCache || isLoadingCategories;
  const isAdvertising = campaignType?.name === CampaignTypesEnum.ADVERTISING;
  const isDisabled =
    isLoading || !isAdvertising || mode === ManageCampaignModeEnum.REVIEW;

  const getValue = (): SingleValue<ISelectOption<number>> | undefined => {
    if (isAdvertising) {
      return category;
    }

    return {
      value: -1,
      label: constants.defaultCategory,
    };
  };

  return (
    <Select
      options={fromObjectIdWithName<number>(categories)}
      label="CATEGORY"
      value={getValue()}
      onChange={handleChange}
      isLoading={isLoading}
      isDisabled={isDisabled}
      isClearable
    />
  );
};

export default Category;
