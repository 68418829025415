/* eslint-disable @typescript-eslint/no-explicit-any */
import { ChevronRight as ChevronRightIcon } from '@mui/icons-material';
import { flexRender, Table } from '@tanstack/react-table';
import classnames from 'classnames';
import React, { ReactElement } from 'react';

import { useStyles } from '../../React-Table.styled';
import { TTable } from '../../types';
import useTableHeader from './TableHeader.hook';

export interface ITableHeader<TData> {
  readonly table: Table<TData>;
  readonly isSSR?: TTable<TData>['isSSR'];
  readonly sort?: TTable<TData>['sort'];
  readonly loading?: TTable<TData>['loading'];
  readonly handleChangeSort?: TTable<TData>['handleChangeSort'];
}

const TableHeader = <TData,>({
  table,
  isSSR,
  sort,
  loading,
  handleChangeSort,
}: ITableHeader<TData>): ReactElement => {
  const { classes, cx } = useStyles();
  const { handleToggleSortingHandler, getIsSorted, getChevronPosition } =
    useTableHeader<TData>({ isSSR, loading, sort, handleChangeSort });

  return (
    <thead className="__table-head">
      {table.getHeaderGroups().map((headerGroup) => (
        <tr key={headerGroup.id} className="__table_row __table_row-header">
          {headerGroup.headers.map((header) => (
            <th
              key={header.id}
              colSpan={header.colSpan}
              style={{ width: header.getSize() }}
              className={classnames(
                {
                  canSorted: header.column.getCanSort(),
                  [`__${(header.column.columnDef as any).accessorKey}`]:
                    Boolean((header.column.columnDef as any).accessorKey),
                },
                '__table_cell',
                '__table_cell-header',
              )}
              onClick={(event) => handleToggleSortingHandler(header, event)}
            >
              {header.isPlaceholder
                ? null
                : flexRender(
                    header.column.columnDef.header,
                    header.getContext(),
                  )}
              {getIsSorted(header) ? (
                <div className={classnames('__cell_sort_chevron')}>
                  <ChevronRightIcon
                    className={cx(
                      classes.chevronIcon,
                      getChevronPosition(header),
                    )}
                  />
                </div>
              ) : null}
            </th>
          ))}
        </tr>
      ))}
    </thead>
  );
};

export default TableHeader;
