import { Close as CloseIcon } from '@mui/icons-material';
import {
  AppBar,
  DialogTitle,
  IconButton,
  Toolbar,
  Typography,
} from '@mui/material';
import { colors } from 'constants/colors/colors';
import { useTypedSelector } from 'hooks';
import React, { memo } from 'react';

import useManageUserModal from '../../ManageUser.hook';

const ManageUserTitle: React.FC = () => {
  const {
    currentWindowParam: { isSmall },
  } = useTypedSelector((state) => state && state.app);

  const { viewTitle, handleModalClose } = useManageUserModal();

  if (isSmall) {
    return (
      <AppBar sx={{ position: 'relative', bgcolor: colors.White }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleModalClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            {viewTitle()}
          </Typography>
        </Toolbar>
      </AppBar>
    );
  }

  return <DialogTitle>{viewTitle()}</DialogTitle>;
};

export default memo(ManageUserTitle);
