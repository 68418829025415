export const colors = {
  Cerulean: '#00B6F1',
  Iron: '#D3D4D5',
  White: '#FFFFFF',
  Black: '#000000',
  LilyWhite: '#E5F8FE',
  Silver: '#C7C7C7',
  Red: '#FF0000',
  DarkerRed: '#d60000',
  Tundora: '#444444',
  Catskill: '#F1F5F8',
  Abbey: '#48494a',
  Shadow: '#aaaaaa58',
  Gray: '#e5e5e5',
  Emerald: '#4ccf68',
  DarkEmerald: '#2faa4a',
} as const;
