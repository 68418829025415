import { AxiosError } from 'axios';
import { OptionsObject, useSnackbar } from 'notistack';

import { AlertStatusesEnum } from '../constants/constants';

export interface IErrorOptions {
  readonly isSnackbar?: boolean;
  readonly snackBarProps?: OptionsObject;
}

export interface IDefaultError {
  readonly UserMessage?: string;
}

export interface ICatchError<T> {
  readonly errorMessage: string;
  readonly response?: AxiosError<T>['response'];
  readonly error?: AxiosError<T>;
}

export interface IErrorHookReturn<T> {
  readonly outputError: (
    error: AxiosError<T> | string | undefined,
    options?: Partial<IErrorOptions>,
  ) => ICatchError<T>;
}

export const defaultErrorMessage = 'Something went wrong';

const useError = (): IErrorHookReturn<IDefaultError> => {
  const { enqueueSnackbar } = useSnackbar();

  const showSnackbar = (
    message: string = defaultErrorMessage,
    isSnackbar = false,
    props: IErrorOptions['snackBarProps'] = {},
  ) => {
    if (isSnackbar) {
      enqueueSnackbar(message, {
        variant: AlertStatusesEnum.ERROR,
        preventDuplicate: true,
        ...props,
      });
    }
  };

  const outputError = (
    error: AxiosError<IDefaultError> | string | undefined,
    options?: Partial<IErrorOptions>,
  ) => {
    if (typeof error === 'string') {
      const message = error || defaultErrorMessage;

      showSnackbar(message, options?.isSnackbar, options?.snackBarProps);

      return { errorMessage: message };
    }

    const message = error?.response?.data?.UserMessage || defaultErrorMessage;

    showSnackbar(message, options?.isSnackbar, options?.snackBarProps);

    return {
      errorMessage: message,
      response: error?.response,
      error,
    };
  };

  return { outputError };
};

export default useError;
