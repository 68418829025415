import { ITablePagination } from '../TablePagination';

interface ITablePaginationDisabledHookProps<TData> {
  readonly table: ITablePagination<TData>['table'];
  readonly data: ITablePagination<TData>['data'];
  readonly totalPages: number;
  readonly isSSR?: ITablePagination<TData>['isSSR'];
  readonly loading?: ITablePagination<TData>['loading'];
  readonly pagination?: ITablePagination<TData>['pagination'];
  readonly totalDataCount: ITablePagination<TData>['totalDataCount'];
}

interface ITablePaginationDisabledHookReturn {
  readonly isPreviousPageButtonDisabled: () => boolean;
  readonly isNextPageButtonDisabled: () => boolean;
  readonly isMenuItemDisabled: (value: number) => boolean;
}

const defaultPageDivider = 10;

const useTablePaginationDisabled = <TData>({
  table,
  data,
  isSSR,
  loading,
  pagination,
  totalPages,
  totalDataCount,
}: ITablePaginationDisabledHookProps<TData>): ITablePaginationDisabledHookReturn => {
  const isPreviousPageButtonDisabled = (): boolean => {
    if (loading) {
      return true;
    }

    return isSSR ? pagination?.currentPage === 0 : !table.getCanPreviousPage();
  };

  const isNextPageButtonDisabled = (): boolean => {
    if (loading) {
      return true;
    }

    return isSSR
      ? pagination?.currentPage === totalPages - 1
      : !table.getCanNextPage();
  };

  const isMenuItemDisabled = (value: number): boolean => {
    if (!isSSR) {
      return value - defaultPageDivider >= data.length;
    }

    if (!totalDataCount) {
      return true;
    }

    return value - defaultPageDivider >= totalDataCount;
  };

  return {
    isPreviousPageButtonDisabled,
    isNextPageButtonDisabled,
    isMenuItemDisabled,
  };
};

export default useTablePaginationDisabled;
