// eslint-disable-next-line import/no-extraneous-dependencies
import { Getter, Row } from '@tanstack/table-core';
import { ManageCampaignModeEnum } from 'constants/enums';
import { parseSubRowsIds } from 'helpers/Campaign';
import { useActions, useTypedSelector } from 'hooks';
import React, { memo, MouseEvent } from 'react';

import useScheduleModal from '../../../../../../../Modals/ScheduleModal/ScheduleModal.hook';
import {
  ICampaignSitesDataTable,
  TCampaignDates,
} from '../../../../helpers/table';
import { SButton } from '../../Content.styled';

interface ISetDatesButton {
  row: Row<ICampaignSitesDataTable>;
  getValue: Getter<unknown>;
}

const SetDatesButton: React.FC<ISetDatesButton> = ({ row, getValue }) => {
  const { mode } = useTypedSelector(
    (state) => state && state.manageCampaign.main,
  );
  const value = getValue<TCampaignDates>();

  const { handleOpen } = useScheduleModal();

  const { containScheduleModal } = useActions();

  const handleClickScheduleButton = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();

    if (Array.isArray(row.original.campaignScheduleIds)) {
      return;
    }
    const campaignSiteId = parseSubRowsIds(row.original.id);

    containScheduleModal(campaignSiteId);
    handleOpen();
  };

  const isReview = mode === ManageCampaignModeEnum.REVIEW;

  if (!value) {
    return (
      <SButton
        variant="text"
        disableRipple
        onClick={handleClickScheduleButton}
        disabled={isReview}
      >
        Set dates
      </SButton>
    );
  }

  if (Array.isArray(value)) {
    return (
      <SButton
        onClick={(event) => {
          event.stopPropagation();
          event.preventDefault();
          row.getToggleExpandedHandler()();
        }}
        variant="text"
        disableRipple
      >
        Multiple
      </SButton>
    );
  }

  return (
    <SButton onClick={handleClickScheduleButton} disabled={isReview}>
      {value}
    </SButton>
  );
};

export default memo(SetDatesButton);
