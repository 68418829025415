import { AxiosPromise, ResponseType } from 'axios';

import { AxiosMethodEnum } from '../../../constants/text/text';
import { urlConstructors, urls } from '../../../constants/urls/url';
import { apiCall } from '../../../helpers/api/apiCall';
import { ISitesSchedulesPayload } from './type';

function getMethodSites<T>(
  url: string,
  params?: object,
  responseType?: ResponseType,
): AxiosPromise<T> {
  return apiCall<T>(
    AxiosMethodEnum.get,
    url,
    '',
    params,
    undefined,
    {},
    '',
    undefined,
    { responseType },
  );
}

function postMethodSites<T extends object>(
  url: string,
  data?: object | object[],
  params?: object,
): AxiosPromise<T> {
  return apiCall<T>(AxiosMethodEnum.post, url, data, params);
}

function putMethodSites<T extends object>(
  url: string,
  data?: object | object[],
  params?: object,
): AxiosPromise<T> {
  return apiCall<T>(AxiosMethodEnum.put, url, data, params);
}

function getSitesFilters<T extends object>(
  url: string,
  selectedIds: number[] = [],
): AxiosPromise<T> {
  return apiCall<T>(AxiosMethodEnum.get, url, '', { selectedIds });
}

function getSelectedSitesApi<T extends object>(
  url: string,
  params: object,
): AxiosPromise<T> {
  return apiCall<T>(AxiosMethodEnum.get, url, '', params, '', {});
}

export const allSites = <T extends object>(params: object): AxiosPromise<T> =>
  getMethodSites<T>(urls.sites, params);

export const getSiteByIdApi = <T extends object>(
  id: number | string,
): AxiosPromise<T> => getMethodSites<T>(urlConstructors.siteWithId(id));

export const sitesFilters = <T extends object>(
  selectedIds: number[] = [],
): AxiosPromise<T> =>
  getSitesFilters<T>(urlConstructors.sitesFilters(), selectedIds);

export const editCampaignSites = <T extends object>(
  params: object,
): AxiosPromise<T> =>
  getMethodSites<T>(urlConstructors.sitesSelected(), params);

export const selectedSitesRequest = <T extends object>(
  params: object,
): AxiosPromise<T> =>
  getSelectedSitesApi<T>(urlConstructors.sitesSelected(), params);

export const createNewSiteApi = <T extends object>(
  data: object,
): AxiosPromise<T> => postMethodSites<T>(urls.sites, data);

export const updateSiteApi = <T extends object>(
  id: number | string,
  data: object,
): AxiosPromise<T> => putMethodSites<T>(urlConstructors.siteWithId(id), data);

export const sitesSchedulesApi = <T = unknown>(
  data: ISitesSchedulesPayload<string>,
): AxiosPromise<T> =>
  getMethodSites(urlConstructors.siteSchedules(data.id), data, 'blob');
