import { AdjustmentsIcon } from 'assets/icons';
import classnames from 'classnames';
import IconButton from 'components/common/Button/components/IconButton';
import Table from 'components/common/Table';
import TableFilter from 'components/common/Table/TableFilter';
import {
  INITIAL_MEDIA_FILTER_VALUE_ALL_TAB,
  INITIAL_MEDIA_TABLE_COLUMNS_EXCEPTIONS,
  INITIAL_MEDIA_TABLE_COLUMNS_VALUE,
  INITIAL_SORTING_VALUE,
} from 'constants/constants';
import {
  MediaFilterText,
  MediaTableText,
  StartDatePickerText,
} from 'constants/text/text';
import { convertToNumberFromFormats, removeTimezone } from 'helpers';
import { getSelectedMedias } from 'helpers/Campaign';
import { createMediaDataManageCampaign } from 'helpers/constructors';
import { useActions, useTypedSelector } from 'hooks';
import React, { useEffect, useMemo, useState } from 'react';
import { IAddMediaModal } from 'redux/Reducers/campaignsReducer/manage/manage/type';
import {
  ISelectedTableItem,
  ISortingValue,
  IStartDateFilter,
  TMediaFilterAllTabs,
} from 'types';
import { useDebounce } from 'usehooks-ts';

const AllTabTable: React.FC = () => {
  const { addMediaModal, campaignSites } = useTypedSelector(
    (state) => state && state.manageCampaign.manage,
  );
  const { totalItems, isLoadingMedia, media } = useTypedSelector(
    (state) => state && state.medias,
  );

  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [pageNumber, setPageNumber] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(10);
  const [selected, setSelected] = useState<ISelectedTableItem[]>([]);
  const [filtersValue, setFiltersValue] = useState<TMediaFilterAllTabs>(
    INITIAL_MEDIA_FILTER_VALUE_ALL_TAB,
  );
  const [tableColumnsFilter, setTableColumnsFilter] = useState<string[]>(
    INITIAL_MEDIA_TABLE_COLUMNS_VALUE,
  );
  const [sortingValue, setSortingValue] = useState<ISortingValue>(
    INITIAL_SORTING_VALUE,
  );
  const [dateAdded, setDateAdded] = useState<Date | undefined>(undefined);

  const debouncedSearchValue = useDebounce(searchQuery, 300);

  const { getMedia, addToAllTabStorage, setCurrentLookingMedia } = useActions();

  const handleOpenPreviewMediaModal = (
    currentLookingMedia: IAddMediaModal['currentLookingMedia'],
  ) => {
    if (currentLookingMedia) {
      setCurrentLookingMedia({ ...currentLookingMedia });
    }
  };

  const siteResolution = addMediaModal.campaignSite?.screenSize;
  const siteFormats = addMediaModal.campaignSite?.formats
    ? convertToNumberFromFormats(addMediaModal.campaignSite?.formats)
    : 0;

  const tableItems = createMediaDataManageCampaign(
    media,
    handleOpenPreviewMediaModal,
  );

  const handleChangeDateAdded = (date: Date | undefined): void => {
    setDateAdded(date);
  };

  const handleClearStartDate = (): void => {
    setDateAdded(undefined);
  };

  const startDateFilter: IStartDateFilter = {
    StartDatePickerText,
    datePickerProps: {
      mode: 'single',
      relatedComponent: 'input',
      onSelect: handleChangeDateAdded,
      disabled: { after: new Date() },
      toDate: new Date(),
    },
    dateAdded,
    handleChangeDateAdded,
    handleClearStartDate,
  };

  const resetPagination = (): void => {
    setPageNumber(0);
  };

  const datas = useMemo(() => {
    const filters = {
      'Filters.Types': filtersValue.Type,
      'Filters.AuthorsIds': filtersValue['Uploaded by'],
      'Filters.CreatedDate':
        dateAdded && removeTimezone(dateAdded).toISOString(),
      'Filters.AdvertisersIds': filtersValue?.Advertiser,
      'Filters.ProductsIds': filtersValue?.Product,
      'Filters.CategoriesIds': filtersValue?.Category,
    };

    const sorting = {
      SortDirection: sortingValue?.SortDirection?.toUpperCase(),
      SortColumn: sortingValue?.SortColumn,
    };

    return {
      pageNumber,
      pageSize,
      SearchQuery: debouncedSearchValue,
      screenSize: siteResolution,
      formats: siteFormats,
      ...sorting,
      ...filters,
    };
  }, [
    pageNumber,
    pageSize,
    debouncedSearchValue,
    filtersValue,
    dateAdded,
    sortingValue,
    siteResolution,
    siteFormats,
  ]);

  useEffect(() => {
    getMedia(datas);
  }, [datas]);

  useEffect(() => {
    addToAllTabStorage(selected);
  }, [selected]);

  const isNoItems = isLoadingMedia || !media.length;

  const selectedMediaIds = getSelectedMedias(campaignSites);
  const filteredMediaItems = tableItems.filter(
    (item) => !selectedMediaIds?.includes(item.id.value),
  );

  return (
    <div style={{ display: 'flex' }}>
      <div
        className={classnames(`filter-campaign-container`, {
          'opened-campaign-filter-container': isFilterOpen,
        })}
      >
        {isFilterOpen ? (
          <TableFilter
            setIsFilterOpen={setIsFilterOpen}
            setSearchQuery={setSearchQuery}
            searchQuery={searchQuery}
            filtersList={addMediaModal.mediaFilters}
            filtersValue={filtersValue}
            setFiltersValue={setFiltersValue}
            tableColumnsFilter={tableColumnsFilter}
            setTableColumnsFilter={setTableColumnsFilter}
            exceptions={INITIAL_MEDIA_TABLE_COLUMNS_EXCEPTIONS}
            resetPagination={resetPagination}
            initialFilterValue={INITIAL_MEDIA_FILTER_VALUE_ALL_TAB}
            initialTableColumnsValue={INITIAL_MEDIA_TABLE_COLUMNS_VALUE}
            filtersText={MediaFilterText}
            startDateFilter={startDateFilter}
            setPageNumber={setPageNumber}
          />
        ) : (
          <div style={{ paddingTop: '10px' }}>
            <IconButton
              onClick={() => setIsFilterOpen(true)}
              icon={<AdjustmentsIcon />}
              className="icon-l"
            />
          </div>
        )}
      </div>
      <div className="content-wrapper">
        <div
          className={classnames(`media-table-content-container`, {
            'no-scroll': isNoItems,
          })}
        >
          <div className="table-wrapper">
            <Table
              items={filteredMediaItems}
              total={totalItems}
              pageNumber={pageNumber}
              pageSize={pageSize}
              setPageSize={setPageSize}
              setPageNumber={setPageNumber}
              tableColumnsFilter={tableColumnsFilter}
              sortingValue={sortingValue}
              setSortingValue={setSortingValue}
              isLoadingItems={isLoadingMedia}
              tableText={MediaTableText}
              selected={selected}
              setSelected={setSelected}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllTabTable;
