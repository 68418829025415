import {
  VideoCameraBack as VideoCameraBackIcon,
  ZoomIn as ZoomInIcon,
} from '@mui/icons-material';
import { ImageListItemBar } from '@mui/material';
import { colors } from 'constants/colors/colors';
import { momentFormats } from 'constants/date/date';
import { ManageCampaignAddMediaTabsEnum } from 'constants/enums';
import { FileTypesEnum } from 'constants/enums/fileLoadStatuses';
import { useActions } from 'hooks';
import moment from 'moment';
import React from 'react';
import { IMedia } from 'types';

import useLookingMediaModal from '../LookingMediaModal/LookingMediaModal.hook';
import { ActionIcon } from './components';
import {
  SBoxSubTitle,
  SIconButton,
  SImageList,
  SImageListItem,
} from './ImageList.styled';

interface IImageList {
  medias: IMedia[];
  tab: ManageCampaignAddMediaTabsEnum;
}

const ImageList: React.FC<IImageList> = ({ medias, tab }) => {
  const { clickedMedia } = useActions();

  const { handleLookingModalOpen } = useLookingMediaModal();

  return (
    <>
      <SImageList gap={8} cols={3}>
        {medias.map((media) => {
          const mediaNameSplit = media.name.split('.');
          const mediaFileFormat = mediaNameSplit.pop()?.toUpperCase();
          const mediaName = mediaNameSplit.join('');
          const createdInfo = moment(media.createdDate).format(
            momentFormats.date(' ').textMonth.full,
          );

          const firstSubTitle = `${mediaFileFormat} • ${media.dimensions} •`;
          const secondSubTitle = `Added ${createdInfo} by ${media.author}`;

          return (
            <SImageListItem
              key={media.id}
              onClick={() => clickedMedia({ media, tab })}
            >
              {media.type === FileTypesEnum.IMAGE ? (
                <img
                  src={media.url}
                  alt={media.name}
                  loading="lazy"
                  style={{ maxHeight: 200 }}
                />
              ) : (
                <VideoCameraBackIcon sx={{ width: 60, height: 60 }} />
              )}
              <SIconButton
                onClick={(event) => handleLookingModalOpen(media, event)}
              >
                <ZoomInIcon sx={{ color: colors.White }} />
              </SIconButton>
              <ImageListItemBar
                title={mediaName}
                subtitle={
                  <SBoxSubTitle>
                    <span>{firstSubTitle}</span>
                    <span>{secondSubTitle}</span>
                  </SBoxSubTitle>
                }
                actionIcon={<ActionIcon currentMedia={media} />}
              />
            </SImageListItem>
          );
        })}
      </SImageList>
    </>
  );
};

export default ImageList;
