import { TabPanel } from '@mui/lab';
import { Button, TextField } from '@mui/material';
import { AlertStatusesEnum } from 'constants/constants';
import { regulars } from 'constants/regular/regulars';
import { radix } from 'constants/variables';
import { FormikHelpers, useFormik } from 'formik';
import { useActions, useError } from 'hooks';
import { useSnackbar } from 'notistack';
import React, { ChangeEvent, memo } from 'react';

import {
  messages,
  StaffEnum,
  TabIdsEnum,
  texts,
} from '../../../../../constants';
import { validationSchemaResolution } from '../../../constants/validationSchemas';
import { ITabPropsDefault } from '../Tabs';

interface IForm {
  width: string;
  height: string;
}

const inputWidth = 200;

const ResolutionTab: React.FC<ITabPropsDefault> = ({ handleModalClose }) => {
  const { createResolution, getResolutions } = useActions();
  const { enqueueSnackbar } = useSnackbar();
  const { outputError } = useError();

  const onSubmit = async (
    data: IForm,
    { setSubmitting, resetForm }: FormikHelpers<IForm>,
  ) => {
    try {
      await createResolution({
        width: parseInt(data.width, radix[10]),
        height: parseInt(data.height, radix[10]),
      });
      enqueueSnackbar(messages.success(StaffEnum.RESOLUTION), {
        variant: AlertStatusesEnum.SUCCESS,
        anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
      });
      resetForm();
      await getResolutions();
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      outputError(error, { isSnackbar: true });
    } finally {
      setSubmitting(false);
    }
  };

  const { values, isSubmitting, errors, handleChange, handleSubmit } =
    useFormik<IForm>({
      initialValues: { width: '', height: '' },
      validationSchema: validationSchemaResolution,
      onSubmit,
      validateOnMount: false,
      validateOnChange: false,
    });

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (!event.target.value || regulars.onlyNumber.test(event.target.value)) {
      handleChange(event);
    }
  };

  return (
    <TabPanel value={TabIdsEnum.RESOLUTION}>
      <form onSubmit={handleSubmit}>
        <div className="content resolution-content">
          <div className="resolution-values">
            <TextField
              value={values.width}
              label={texts.resolution.width.label}
              name={texts.resolution.width.name}
              onChange={handleInputChange}
              sx={{ width: inputWidth }}
              disabled={isSubmitting}
              error={Boolean(errors.width)}
              helperText={errors.width}
            />
            <TextField
              value={values.height}
              label={texts.resolution.height.label}
              name={texts.resolution.height.name}
              onChange={handleInputChange}
              sx={{ width: inputWidth }}
              disabled={isSubmitting}
              error={Boolean(errors.height)}
              helperText={errors.height}
            />
          </div>
        </div>
        <div className="actions resolution-actions">
          <Button variant="contained" type="submit" disabled={isSubmitting}>
            {texts.actions.submit}
          </Button>
          <Button
            variant="contained"
            color="error"
            disabled={isSubmitting}
            onClick={handleModalClose}
          >
            {texts.actions.close}
          </Button>
        </div>
      </form>
    </TabPanel>
  );
};

export default memo(ResolutionTab);
