import { Dispatch } from 'redux';

import { IDefaultDto, IObjectIdWithName } from '../../../types/general';
import {
  ProductActionTypesEnum,
  TProductAction,
} from '../../ActionTypes/productTypes/productTypes';
import { allProducts, newProduct, newProductCache } from './productApi';

export const getProducts =
  (advertiserId?: number) =>
  async (dispatch: Dispatch<TProductAction>): Promise<void> => {
    try {
      dispatch({ type: ProductActionTypesEnum.GET_PRODUCTS_LOADING });
      const { data } = await allProducts<IDefaultDto<IObjectIdWithName[]>>(
        advertiserId,
      );
      dispatch({
        type: ProductActionTypesEnum.GET_PRODUCTS_SUCCESS,
        payload: data.data,
      });
    } catch (error: any) {
      dispatch({
        type: ProductActionTypesEnum.GET_PRODUCTS_ERROR,
        payload: !!error,
      });
    }
  };

export const postProducts =
  (
    product: { name: string; advertiserId: number },
    callBack: (value: IObjectIdWithName | null) => void,
  ) =>
  async (dispatch: Dispatch<TProductAction>): Promise<void> => {
    try {
      dispatch({ type: ProductActionTypesEnum.POST_PRODUCTS_LOADING });
      const {
        data: { data },
      } = await newProduct<IDefaultDto<IObjectIdWithName>>(product);
      callBack(data);
      dispatch({
        type: ProductActionTypesEnum.POST_PRODUCTS_SUCCESS,
        payload: data,
      });
      dispatch({ type: ProductActionTypesEnum.GET_PRODUCTS_LOADING });
      const { data: productsList } = await allProducts<
        IDefaultDto<IObjectIdWithName[]>
      >(product.advertiserId);
      dispatch({
        type: ProductActionTypesEnum.GET_PRODUCTS_SUCCESS,
        payload: productsList.data,
      });
    } catch (error: any) {
      dispatch({
        type: ProductActionTypesEnum.POST_PRODUCTS_ERROR,
        payload: !!error,
      });
    }
  };

export const postProductsCache =
  (product: { productId: number; categoryId: number }) =>
  async (dispatch: Dispatch<TProductAction>): Promise<void> => {
    try {
      dispatch({ type: ProductActionTypesEnum.POST_PRODUCTS_CACHE_LOADING });
      await newProductCache(product);
      dispatch({
        type: ProductActionTypesEnum.POST_PRODUCTS_CACHE_SUCCESS,
      });
    } catch (error: any) {
      dispatch({
        type: ProductActionTypesEnum.POST_PRODUCTS_CACHE_ERROR,
        payload: !!error,
      });
    }
  };
