import { LoginIcon } from 'assets/icons';
import BackdropWithLoader from 'components/common/Backdrop';
import Button from 'components/common/Button';
import Input from 'components/common/Input';
import SvgIcon from 'components/common/SvgIcon/SvgIcon';
import {
  AlertStatusesEnum,
  LocalStorageKeysEnum,
  SessionStorageKeysEnum,
} from 'constants/constants';
import { LoginPageText } from 'constants/text/text';
import { urls } from 'constants/urls/url';
import { useFormik } from 'formik';
import { getFromStorage } from 'helpers/storage';
import { LOGIN_VALIDATION_SCHEMA } from 'helpers/validationSchemas';
import { useActions } from 'hooks/useActions';
import { useTypedSelector } from 'hooks/useTypedSelector';
import LoginLayout from 'layouts/login';
import { useSnackbar } from 'notistack';
import React, { useEffect } from 'react';
import { Link, Navigate } from 'react-router-dom';
import { StorageTypeEnum } from 'types';

interface IUserData {
  login: string;
  password: string;
}

const LoginPage: React.FC = () => {
  // const [isChecked, setIsChecked] = useState(false);
  const { hasLoggedIn, isLoading, errorMessage } = useTypedSelector(
    (state) => state && state.auth,
  );

  const { organisation } = useTypedSelector(
    (state) => state && state.organisation,
  );
  const { signIn } = useActions();
  const { enqueueSnackbar } = useSnackbar();

  // const handleCheck = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   setIsChecked(e.target.checked);
  // };

  const handleLogIn = async (userData: IUserData) => {
    await signIn(userData);
  };

  const isOrganisation: boolean | null = getFromStorage<boolean>(
    LocalStorageKeysEnum.isOrganisation,
    StorageTypeEnum.localStorage,
  );

  const redirectTo: string | null = getFromStorage<string>(
    SessionStorageKeysEnum.redirectTo,
    StorageTypeEnum.sessionStorage,
  );

  useEffect(() => {
    if (errorMessage) {
      enqueueSnackbar(String(errorMessage), {
        variant: AlertStatusesEnum.ERROR,
      });
    }
  }, [errorMessage, enqueueSnackbar]);

  // const handleMagicLink = () => {
  //   navigate(urls.magicLink);
  // };

  const formik = useFormik({
    initialValues: { login: '', password: '' },
    validationSchema: LOGIN_VALIDATION_SCHEMA,
    onSubmit: (values) => handleLogIn(values),
  });

  const { handleChange, handleSubmit, errors } = formik;

  if (hasLoggedIn && isOrganisation) {
    if (redirectTo) {
      sessionStorage.removeItem(SessionStorageKeysEnum.redirectTo);

      return <Navigate to={redirectTo} replace />;
    }

    return <Navigate to={urls.campaign} replace />;
  }

  if (hasLoggedIn && !organisation.id) {
    return <Navigate to={urls.changePortal} replace />;
  }

  if (hasLoggedIn && organisation.id) {
    return <Navigate to={urls.campaign} replace />;
  }

  return (
    <LoginLayout header={LoginPageText.header}>
      <form onSubmit={handleSubmit}>
        <div className="input-container">
          <span className="input-label">{LoginPageText.emailLabel}</span>
          <Input
            disabled={isLoading}
            name="login"
            className="input-field"
            onChange={handleChange}
            error={Boolean(errors.login)}
            helperText={errors.login}
          />
        </div>
        <div className="input-container">
          <span className="input-label">{LoginPageText.passwordLabel}</span>
          <Input
            disabled={isLoading}
            name="password"
            className="input-field"
            type="password"
            onChange={handleChange}
            error={Boolean(errors.password)}
            helperText={errors.password}
          />
        </div>
        <div className="button-group button-group_top-margin">
          <Button
            label={LoginPageText.loginButton}
            type="submit"
            disabled={isLoading}
            className="btn_right-margin without-shadow"
            icon={
              <SvgIcon className="icon-xxxl">
                <LoginIcon />
              </SvgIcon>
            }
          />
          {/* <Button
            onClick={handleMagicLink}
            label={LoginPageText.magicLink}
            color="secondary"
            disabled={isLoading}
            icon={
              <SvgIcon className="icon-xxxl">
                <MagicLink />
              </SvgIcon>
            }
            className="without-shadow"
          /> */}
        </div>
        <div className="remember-group">
          <div>
            <Link to={urls.forgot} className="link">
              {LoginPageText.forgot}
            </Link>
          </div>
          {/* <div className="remember-container">
            <Checkbox
              id="remember-checkbox"
              name="remember"
              isChecked={isChecked}
              onChange={handleCheck}
            />
            <span className="remember-label">{LoginPageText.remember}</span>
          </div> */}
        </div>
      </form>
      <BackdropWithLoader open={isLoading} />
    </LoginLayout>
  );
};

export default LoginPage;
