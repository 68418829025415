import { SyntheticEvent, useCallback, useState } from 'react';

import { TabIdsEnum } from '../constants';

interface ITabsConfigurationHookProps {
  initialedCurrentTab: TabIdsEnum;
}

interface ITabsConfigurationHookReturn {
  currentTab: TabIdsEnum;
  handleTabChange: (event: SyntheticEvent, newIndex: TabIdsEnum) => void;
}

const useTabsConfiguration = ({
  initialedCurrentTab,
}: ITabsConfigurationHookProps): ITabsConfigurationHookReturn => {
  const [currentTab, setCurrentTab] = useState<TabIdsEnum>(initialedCurrentTab);

  const handleTabChange = useCallback(
    (__: SyntheticEvent, newIndex: TabIdsEnum) => {
      setCurrentTab(newIndex);
    },
    [],
  );

  return { currentTab, handleTabChange };
};

export default useTabsConfiguration;
