import { OptionsObject, SnackbarKey, SnackbarMessage } from 'notistack';
import { ReactNode } from 'react';

export enum ValueForDayPartTimeEnum {
  dayTime = 'Day time',
  nightTime = 'Night time',
  empty = '',
}

export enum UserRolesEnum {
  SuperAdmin = 'Super Admin',
  SuperAdminWithoutSpace = 'SuperAdmin',
  Admin = 'Admin',
  Editor = 'Editor',
  Marketing = 'Marketing',
  Advertising = 'Advertising',
}

export type TNullable<T> = T | null | undefined;

export interface IDeclineDialogValues {
  readonly reason: string;
}

export interface IDefaultDto<T = unknown> {
  readonly status: string;
  readonly data: T;
  readonly userMessage: string | null;
  readonly systemMessage: string | null;
  readonly stackTrace: string | null;
  readonly debugInfo: string | null;
  readonly generatedAtUtc: string;
}

export interface IDefaultDtoExtended<T> extends IDefaultDto<T> {
  readonly pageSize: number;
  readonly pageNumber: number;
  readonly totalCount: number;
  readonly totalReturned: number;
}

export interface ISortingValue {
  readonly SortDirection: 'asc' | 'desc' | undefined;
  readonly SortColumn: string | undefined;
}

export interface IMagicLinkSendData {
  readonly email: string;
  readonly callbackUrl: string;
}

export interface ITableText {
  readonly noResults: string;
}

export interface ITableItems {
  readonly id: Readonly<{
    name: string;
    value: number;
    onClick?: () => void;
  }>;
  readonly dates?: Readonly<{
    name: string;
    value: number | string[] | string;
    onClick?: () => void;
    icon?: ReactNode;
  }>;
}

export interface IRejectReason {
  readonly authorName: string;
  readonly reason: string;
  readonly createdDate: string;
}

export interface IUrlState {
  readonly location: string;
}

export type TShowMessage = (
  message: SnackbarMessage,
  options?: OptionsObject,
) => SnackbarKey;

export type TItem = {
  readonly id: Readonly<{
    name: string;
    value: number;
    onClick?: () => void;
  }>;
};

export enum StorageTypeEnum {
  localStorage = 'localStorage',
  sessionStorage = 'sessionStorage',
}

export type TCoordinate = {
  readonly top?: number;
  readonly bottom?: number;
  readonly left?: number;
  readonly right?: number;
};

export type TSizesPage = Readonly<10 | 20 | 30 | 40 | 50>;

export type TPagination = {
  pageSize: TSizesPage;
  currentPage: number;
};

export type TSort<TColumns> = {
  direction: SortDirectionEnum | undefined;
  column: TColumns | undefined;
};

export interface IObjectWithId<T extends string | number = string | number> {
  id: T;
}

export interface IObjectIdWithName<
  TName = string,
  TId extends string | number = number,
> extends IObjectWithId<TId> {
  name: TName;
}

export interface ISelectOption<GValue = string | number> {
  readonly value: GValue;
  readonly label: string;
  readonly isFixed?: boolean;
  readonly isDisabled?: boolean;
  readonly __isNew__?: boolean;
}

export enum SortDirectionEnum {
  ASC = 0,
  DESC = 1,
}
