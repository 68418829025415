import {
  IUrlState,
  TUrlStateAction,
  UrlActionTypesEnum,
} from '../../ActionTypes/UrlTypes/UrlTypes';

const initialState: IUrlState = {
  location: '',
};

const urlReducer = (
  state = initialState,
  action: TUrlStateAction,
): IUrlState => {
  if (action.type === UrlActionTypesEnum.POST_URL_LOCATION) {
    return { ...state, location: action.payload.location };
  }

  return state;
};

export default urlReducer;
