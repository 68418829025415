import { AxiosPromise } from 'axios';
import { AxiosMethodEnum } from 'constants/text/text';
import { urls } from 'constants/urls/url';
import { apiCall } from 'helpers/api/apiCall';
import { IDimensionObject } from 'types';

import { ICreateOrientationPayload } from './type';

const getMethodResolutions = <T extends object>(
  url: string,
): AxiosPromise<T> => {
  return apiCall<T>(AxiosMethodEnum.get, url);
};

const postMethodResolution = <T extends object>(
  url: string,
  data: object,
): AxiosPromise<T> => {
  return apiCall<T>(AxiosMethodEnum.post, url, data);
};

export const getResolutionsApi = <T extends object>(): AxiosPromise<T> =>
  getMethodResolutions<T>(urls.resolutions);

export const createResolutionApi = <T extends object>(
  data: IDimensionObject,
): AxiosPromise<T> => postMethodResolution<T>(urls.resolutions, data);

export const createOrientationApi = <T extends object>(
  data: ICreateOrientationPayload,
): AxiosPromise<T> => postMethodResolution<T>(urls.orientations, data);
