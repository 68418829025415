import { useActions, useTypedSelector } from 'hooks';
import { SortDirectionEnum, TAllUsersColumns } from 'types';

interface IUsersContentSortHookReturn {
  readonly handleChangeSort: (value: TAllUsersColumns | undefined) => void;
}

const useUsersContentSort = (): IUsersContentSortHookReturn => {
  const { allUsersTable } = useTypedSelector((state) => state && state.users);

  const { allUserTableSortColumn, allUserTableSortDirection } = useActions();

  const handleChangeSort = (value?: TAllUsersColumns | undefined): void => {
    if (allUsersTable.sort.column === undefined) {
      allUserTableSortColumn(value);
      allUserTableSortDirection(SortDirectionEnum.ASC);

      return;
    }

    if (allUsersTable.sort.column === value) {
      if (allUsersTable.sort.direction === SortDirectionEnum.ASC) {
        allUserTableSortDirection(SortDirectionEnum.DESC);

        return;
      }

      if (allUsersTable.sort.direction === SortDirectionEnum.DESC) {
        allUserTableSortColumn(undefined);
        allUserTableSortDirection(undefined);

        return;
      }
    }

    allUserTableSortColumn(value);
    allUserTableSortDirection(SortDirectionEnum.ASC);
  };

  return { handleChangeSort };
};

export default useUsersContentSort;
