import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  LinearProgress,
} from '@mui/material';
import { Button } from 'components/common';
import { AlertStatusesEnum } from 'constants/constants';
import { urls } from 'constants/urls/url';
import { useActions, useError, useTypedSelector } from 'hooks';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useNavigate } from 'react-router-dom';

const StopCampaignAlert: React.FC = () => {
  const { modals } = useTypedSelector(
    (state) => state && state.manageCampaign.manage,
  );

  const { loadings, campaignId } = useTypedSelector(
    (state) => state && state.manageCampaign.main,
  );
  const { setModalsManageTab, stopCampaignManageCampaign } = useActions();
  const { outputError } = useError();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const handleCloseStopCampaignModal = async () => {
    if (!loadings.stopped) {
      setModalsManageTab({ type: 'stopCampaign', value: false });
    }
  };

  const handleAgree = async () => {
    try {
      if (campaignId) {
        await stopCampaignManageCampaign(campaignId);
        enqueueSnackbar('Campaign stopped', {
          variant: AlertStatusesEnum.INFO,
        });
        navigate(urls.campaign);
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      outputError(error?.message, { isSnackbar: true });
    }
  };

  return (
    <Dialog open={modals.stopCampaign} onClose={handleCloseStopCampaignModal}>
      {loadings.stopped && <LinearProgress />}
      <DialogTitle>Confirm the action</DialogTitle>
      <DialogContent>
        <DialogContentText>
          This action is irreversible, you will need to re-create your campaign
          if you need it to play again
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          label="Cancel"
          className="dangerous-button"
          onClick={handleCloseStopCampaignModal}
          disabled={loadings.stopped}
        />
        <Button
          label="Agree"
          disabled={loadings.stopped}
          onClick={handleAgree}
        />
      </DialogActions>
    </Dialog>
  );
};

export default StopCampaignAlert;
