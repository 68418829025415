import { Dispatch } from 'redux';
import { INewAdvertiser } from 'redux/Actions/advertiserActions/type';
import {
  allProducts,
  newProduct,
  newProductCache,
} from 'redux/Actions/productActions/productApi';
import {
  INewCategoryCache,
  INewProduct,
} from 'redux/Actions/productActions/type';
import {
  AdvertiserActionTypesEnum,
  IManageCampaignDetailModals,
  ManageCampaignDetailActionEnum,
  ProductActionTypesEnum,
  TAdvertiserAction,
  TManageCampaignDetailAction,
  TProductAction,
} from 'redux/ActionTypes';
import { IInitialCampaignDetail } from 'redux/Reducers/campaignsReducer/manage/detail/type';
import {
  IAdvertisersAllDto,
  ICampaign,
  IDefaultDto,
  IObjectIdWithName,
} from 'types';

import {
  allAdvertisers,
  newAdvertisers,
} from '../../../advertiserActions/advertiserApi';
import { chooseCategory } from '../../../categoryActions/categoryApi';
import { allCampaigns, searchedCampaignsByName } from '../../campaignsApi';

export const getCampaignsForManage =
  (name?: string) =>
  async (dispatch: Dispatch<TManageCampaignDetailAction>): Promise<void> => {
    try {
      dispatch({
        type: ManageCampaignDetailActionEnum.SET_LOADING_MANAGE_CAMPAIGN_DETAIL,
        payload: { type: 'getByName', value: true },
      });

      if (name) {
        const {
          data: { data },
        } = await searchedCampaignsByName<IDefaultDto<ICampaign[]>>(name);

        dispatch({
          type: ManageCampaignDetailActionEnum.SET_CAMPAIGN_FOR_MANAGE,
          payload: data,
        });
      } else {
        const {
          data: { data },
        } = await allCampaigns<IDefaultDto<ICampaign[]>>();

        dispatch({
          type: ManageCampaignDetailActionEnum.SET_CAMPAIGN_FOR_MANAGE,
          payload: data,
        });
      }
    } catch (error) {
      console.error(error);
    } finally {
      dispatch({
        type: ManageCampaignDetailActionEnum.SET_LOADING_MANAGE_CAMPAIGN_DETAIL,
        payload: { type: 'getByName', value: false },
      });
    }
  };

export const changeCampaignName =
  (name: IInitialCampaignDetail['campaignName']) =>
  (dispatch: Dispatch<TManageCampaignDetailAction>): void => {
    dispatch({
      type: ManageCampaignDetailActionEnum.SET_CAMPAIGN_NAME_MANAGE,
      payload: name,
    });
  };

export const setSearchCampaign =
  (name: IInitialCampaignDetail['searchCampaign']) =>
  (dispatch: Dispatch<TManageCampaignDetailAction>): void => {
    dispatch({
      type: ManageCampaignDetailActionEnum.SET_SEARCH_CAMPAIGN_MANAGE,
      payload: name,
    });
  };

export const changeCampaignType =
  (type: IInitialCampaignDetail['campaignType']) =>
  (dispatch: Dispatch<TManageCampaignDetailAction>): void => {
    dispatch({
      type: ManageCampaignDetailActionEnum.SET_CAMPAIGN_TYPE,
      payload: type,
    });
  };

export const setAdvertiserManageCampaign =
  (payload: IInitialCampaignDetail['advertiser']) =>
  (dispatch: Dispatch<TManageCampaignDetailAction>): void => {
    dispatch({ type: ManageCampaignDetailActionEnum.SET_ADVERTISER, payload });
  };

export const setCategoryManageCampaign =
  (payload: IInitialCampaignDetail['category']) =>
  (dispatch: Dispatch<TManageCampaignDetailAction>): void => {
    dispatch({ type: ManageCampaignDetailActionEnum.SET_CATEGORY, payload });
  };

export const setProductManageCampaign =
  (payload: IInitialCampaignDetail['product']) =>
  (dispatch: Dispatch<TManageCampaignDetailAction>): void => {
    dispatch({ type: ManageCampaignDetailActionEnum.SET_PRODUCT, payload });
  };

export const setAutoSubstitutionCategory =
  (payload: IInitialCampaignDetail['isAutoSubstitutionCategory']) =>
  (dispatch: Dispatch<TManageCampaignDetailAction>): void => {
    dispatch({
      type: ManageCampaignDetailActionEnum.SET_AUTO_SUBSTITUTION_CATEGORY,
      payload,
    });
  };

export const setManageCampaignDetailModals =
  (payload: IManageCampaignDetailModals) =>
  (dispatch: Dispatch<TManageCampaignDetailAction>): void => {
    dispatch({
      type: ManageCampaignDetailActionEnum.SET_MODAL_MANAGE_CAMPAIGN_DETAIL,
      payload,
    });
  };

export const createNewAdvertisingDetailTab =
  (payload: INewAdvertiser) =>
  async (
    dispatch: Dispatch<TManageCampaignDetailAction | TAdvertiserAction>,
  ): Promise<void> => {
    try {
      dispatch({
        type: ManageCampaignDetailActionEnum.SET_LOADING_MANAGE_CAMPAIGN_DETAIL,
        payload: { type: 'createAdvertising', value: true },
      });
      dispatch({
        type: ManageCampaignDetailActionEnum.SET_ADVERTISER,
        payload: { value: 0, label: payload.name },
      });

      const {
        data: { data },
      } = await newAdvertisers<IDefaultDto<IObjectIdWithName>>(payload);

      dispatch({
        type: ManageCampaignDetailActionEnum.SET_ADVERTISER,
        payload: { value: data.id, label: data.name },
      });

      dispatch({ type: AdvertiserActionTypesEnum.GET_ADVERTISERS_LOADING });
      const {
        data: { data: advertisers },
      } = await allAdvertisers<IAdvertisersAllDto>();
      dispatch({
        type: AdvertiserActionTypesEnum.GET_ADVERTISERS_SUCCESS,
        payload: advertisers,
      });
    } catch (error) {
      console.error(error);
    } finally {
      dispatch({
        type: ManageCampaignDetailActionEnum.SET_LOADING_MANAGE_CAMPAIGN_DETAIL,
        payload: { type: 'createAdvertising', value: false },
      });
    }
  };

export const createNewProductDetailTab =
  (payload: INewProduct) =>
  async (
    dispatch: Dispatch<TManageCampaignDetailAction | TProductAction>,
  ): Promise<void> => {
    try {
      dispatch({
        type: ManageCampaignDetailActionEnum.SET_LOADING_MANAGE_CAMPAIGN_DETAIL,
        payload: { type: 'createProduct', value: true },
      });
      dispatch({
        type: ManageCampaignDetailActionEnum.SET_PRODUCT,
        payload: { value: 0, label: payload.name },
      });

      const {
        data: { data },
      } = await newProduct<IDefaultDto<IObjectIdWithName>>(payload);

      dispatch({
        type: ManageCampaignDetailActionEnum.SET_PRODUCT,
        payload: { value: data.id, label: data.name },
      });

      dispatch({ type: ProductActionTypesEnum.GET_PRODUCTS_LOADING });

      const {
        data: { data: products },
      } = await allProducts<IDefaultDto<IObjectIdWithName[]>>(
        payload.advertiserId,
      );

      dispatch({
        type: ProductActionTypesEnum.GET_PRODUCTS_SUCCESS,
        payload: products,
      });
    } catch (error) {
      console.error(error);
    } finally {
      dispatch({
        type: ManageCampaignDetailActionEnum.SET_LOADING_MANAGE_CAMPAIGN_DETAIL,
        payload: { type: 'createProduct', value: false },
      });
    }
  };

export const getCategoryFromCache =
  (productId: number) =>
  async (dispatch: Dispatch<TManageCampaignDetailAction>): Promise<void> => {
    try {
      dispatch({
        type: ManageCampaignDetailActionEnum.SET_LOADING_MANAGE_CAMPAIGN_DETAIL,
        payload: { type: 'getCategoryFromCache', value: true },
      });
      const {
        data: { data },
      } = await chooseCategory<IDefaultDto<IObjectIdWithName>>(productId);
      dispatch({
        type: ManageCampaignDetailActionEnum.SET_CATEGORY,
        payload: { value: data.id, label: data.name },
      });
    } catch (error) {
      console.error(error);
    } finally {
      dispatch({
        type: ManageCampaignDetailActionEnum.SET_LOADING_MANAGE_CAMPAIGN_DETAIL,
        payload: { type: 'getCategoryFromCache', value: false },
      });
    }
  };

export const setCategoryToCache =
  (payload: INewCategoryCache) =>
  async (dispatch: Dispatch<TManageCampaignDetailAction>): Promise<void> => {
    try {
      dispatch({
        type: ManageCampaignDetailActionEnum.SET_LOADING_MANAGE_CAMPAIGN_DETAIL,
        payload: { type: 'setCategoryToCache', value: true },
      });

      await newProductCache(payload);
    } catch (error) {
      console.error(error);
    } finally {
      dispatch({
        type: ManageCampaignDetailActionEnum.SET_LOADING_MANAGE_CAMPAIGN_DETAIL,
        payload: { type: 'setCategoryToCache', value: false },
      });
    }
  };

export const cleanupManageCampaignDetail =
  () =>
  (dispatch: Dispatch<TManageCampaignDetailAction>): void => {
    dispatch({
      type: ManageCampaignDetailActionEnum.CLEANUP_MANAGE_CAMPAIGN_DETAIL_DATA,
    });
  };
