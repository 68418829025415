export const parseSubRowsIds = (
  id: number | string,
  returned: 'siteId' | 'scheduleId' = 'siteId',
): number | string | undefined => {
  if (typeof id === 'number') {
    if (returned === 'siteId') {
      return id;
    }

    return;
  }

  const splitId = id.split('.');

  if (returned === 'siteId') {
    if (Number(splitId[0])) {
      return Number(splitId[0]);
    }

    return splitId[0];
  }

  if (returned === 'scheduleId') {
    if (Number(splitId[1])) {
      return Number(splitId[1]);
    }

    return splitId[1];
  }

  if (returned === 'siteId') {
    return id;
  }
};
