import { isRole } from 'helpers';
import { ICentreNetworkFull } from 'redux/Reducers/users/types';
import {
  ICentreNetworksDto,
  IUserBaseDto,
  IUserCentreNetworkDto,
  IUserSubOrganisationDto,
  TUserDto,
} from 'types';
import { TNullable, UserRolesEnum } from 'types/general';

import { IAdditionalUserSchemeProps, TInitialValues } from '../initialValues';

const createBaseInfoScheme = ({
  userName,
  firstName,
  lastName,
  email,
  role,
  password,
}: TInitialValues): IUserBaseDto => {
  return {
    userName,
    firstName,
    lastName,
    email,
    role,
    password,
  };
};

const createSubOrganisationInfoScheme = ({
  subOrganisation,
}: TInitialValues): IUserSubOrganisationDto => {
  if (subOrganisation !== -1) {
    return { subOrganisationId: subOrganisation };
  }

  return {
    subOrganisationId: null,
  };
};

const centreNetworkAfterCompared = (
  centreNetworksForm: TNullable<ICentreNetworksDto[]>,
  centreNetworksFromUser: ICentreNetworkFull[] | undefined,
) => {
  if (!centreNetworksForm) {
    return [];
  }

  if (!centreNetworksFromUser) {
    return centreNetworksForm;
  }

  const centreNetworksCompared = centreNetworksForm.map((centreNetwork) => {
    const similarCentreNetwork = centreNetworksFromUser.find(
      (centreNetworkUser) => {
        const isCentre = centreNetwork.centreId === centreNetworkUser.centreId;
        const isNetwork =
          centreNetwork.networkId === centreNetworkUser.networkId;

        if (isCentre && isNetwork) {
          return true;
        }

        return false;
      },
    );

    if (!similarCentreNetwork) {
      return centreNetwork;
    }

    return similarCentreNetwork;
  });

  return centreNetworksCompared;
};

const createCentreNetworkInfoScheme = (
  { centreNetworks }: TInitialValues,
  originalCentreNetworks: IAdditionalUserSchemeProps['originalCentreNetworks'],
): IUserCentreNetworkDto => {
  const centreNetworksSchemeDraft: ICentreNetworksDto[] = [];

  if (centreNetworks) {
    centreNetworks.forEach((item) => {
      item.networkIds.forEach((networkId) => {
        if (item.centreId && networkId) {
          centreNetworksSchemeDraft.push({
            centreId: item.centreId,
            networkId,
            id: 0,
          });
        }
      });
    });
  }

  const centreNetworksScheme: ICentreNetworksDto[] = centreNetworkAfterCompared(
    centreNetworksSchemeDraft,
    originalCentreNetworks,
  );

  return {
    centreNetworks: centreNetworksScheme,
  };
};

const creteNetworkInfoScheme = (
  { adminNetworks }: TInitialValues,
  originalCentreNetworks: IAdditionalUserSchemeProps['originalCentreNetworks'],
): IUserCentreNetworkDto => {
  if (adminNetworks) {
    const centreNetworksSchemeDraft = adminNetworks.map((networkId) => ({
      centreId: null,
      networkId,
      id: 0,
    }));

    const centreNetworksScheme: ICentreNetworksDto[] =
      centreNetworkAfterCompared(
        centreNetworksSchemeDraft,
        originalCentreNetworks,
      );

    return {
      centreNetworks: centreNetworksScheme,
    };
  }

  return {
    centreNetworks: [{ centreId: null, networkId: null, id: 0 }],
  };
};

export const createUserScheme = (
  values: TInitialValues,
  additionalData?: IAdditionalUserSchemeProps,
): TUserDto => {
  if (isRole(UserRolesEnum.Advertising, values.role)) {
    return {
      ...createBaseInfoScheme(values),
      ...createCentreNetworkInfoScheme(
        values,
        additionalData?.originalCentreNetworks,
      ),
      ...createSubOrganisationInfoScheme(values),
    };
  }

  if (isRole([UserRolesEnum.Marketing, UserRolesEnum.Editor], values.role)) {
    return {
      ...createBaseInfoScheme(values),
      ...createCentreNetworkInfoScheme(
        values,
        additionalData?.originalCentreNetworks,
      ),
    };
  }

  if (isRole(UserRolesEnum.Admin, values.role)) {
    return {
      ...createBaseInfoScheme(values),
      ...creteNetworkInfoScheme(values, additionalData?.originalCentreNetworks),
    };
  }

  return {
    ...createBaseInfoScheme(values),
  };
};
