import { FileLoadStatusesEnum } from 'constants/enums';
import { ManageCampaignAddMediaTabsEnum } from 'constants/enums/campaign';
import { getRecentlyPayload, getSelectedMedias } from 'helpers/Campaign';
import { useActions, useError, useTypedSelector } from 'hooks';
import { useEffect } from 'react';

interface IAddMediaHookReturn {
  hasLoadedMedia: boolean;
  handleAddMediaOpenWithContain: (campaignScheduleId?: number | string) => void;
  handleAddMediaOpen: () => void;
  handleAddMediaClose: () => void;
  getDrawerGridTemplate: () => string;
  getDrawerWidth: () => string;
}

const defaultDrawerWidth = '65%';
const defaultDrawerWidthSmallWindow = '85%';
const drawerWidthAllTab = '80%';
const drawerWidthAllTabWhenSmallWindow = '90%';

const useAddMedia = (): IAddMediaHookReturn => {
  const { currentWindowParam } = useTypedSelector(
    (state) => state && state.app,
  );
  const { addMediaModal, campaignSites, loaders } = useTypedSelector(
    (state) => state && state.manageCampaign.manage,
  );
  const {
    getRecentlyAddedMedia,
    getSelectedMediaV2,
    setModalsManageTab,
    containAddMediaModal,
    cleanupAddMediaModal,
    getMediaFiltersManageCampaign,
  } = useActions();
  const { outputError } = useError();

  const hasLoadedMedia = Boolean(
    addMediaModal.mediaStorage.loaderStore.find(
      (file) => file.status === FileLoadStatusesEnum.LOADED,
    ),
  );

  const handleAddMediaOpen: IAddMediaHookReturn['handleAddMediaOpen'] = () => {
    setModalsManageTab({ type: 'addMedia', value: true });
  };

  const handleAddMediaOpenWithContain: IAddMediaHookReturn['handleAddMediaOpenWithContain'] =
    (campaignScheduleId) => {
      if (campaignScheduleId) {
        containAddMediaModal(campaignScheduleId);
        handleAddMediaOpen();
      }
    };

  const handleAddMediaClose = (): void => {
    if (!hasLoadedMedia) {
      setModalsManageTab({ type: 'addMedia', value: false });
    }
  };

  const handleGetRecently = async () => {
    try {
      if (addMediaModal.campaignSchedule) {
        await getRecentlyAddedMedia(
          getRecentlyPayload(campaignSites, addMediaModal.campaignSchedule.id),
        );
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      handleAddMediaClose();
      outputError(error.message, {
        isSnackbar: true,
      });
    }
  };

  const handleGetSelected = async () => {
    if (addMediaModal.campaignSchedule) {
      try {
        await getSelectedMediaV2(
          getSelectedMedias(campaignSites, addMediaModal.campaignSchedule.id),
        );
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        handleAddMediaClose();
        outputError(error.message, { isSnackbar: true });
      }
    }
  };

  useEffect(() => {
    if (addMediaModal.campaignSchedule) {
      if (!loaders.getMediaFilters) {
        getMediaFiltersManageCampaign();
      }
      if (!loaders.getRecentlyMedia) {
        handleGetRecently();
      }
      if (!loaders.getSelectedMedia) {
        handleGetSelected();
      }
    }

    return () => {
      cleanupAddMediaModal();
    };
  }, []);

  const getDrawerGridTemplate = (): string => {
    const isAllTab =
      addMediaModal.currentTab === ManageCampaignAddMediaTabsEnum.ALL;

    if (isAllTab) {
      return 'auto 1fr auto';
    }

    return 'auto 125px 1fr auto';
  };

  const getDrawerWidth = (): string => {
    const isAllTab =
      addMediaModal.currentTab === ManageCampaignAddMediaTabsEnum.ALL;

    if (currentWindowParam.isSmall) {
      if (isAllTab) {
        return drawerWidthAllTabWhenSmallWindow;
      }

      return defaultDrawerWidthSmallWindow;
    }

    if (isAllTab) {
      return drawerWidthAllTab;
    }

    return defaultDrawerWidth;
  };

  return {
    hasLoadedMedia,
    getDrawerGridTemplate,
    getDrawerWidth,
    handleAddMediaOpen,
    handleAddMediaOpenWithContain,
    handleAddMediaClose,
  };
};

export default useAddMedia;
