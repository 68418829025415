import produce from 'immer';

import { ResolutionActionEnum, TResolutionActions } from '../../../ActionTypes';
import { ISiteResolutionsState } from './type';

const initialValues: ISiteResolutionsState = {
  resolutions: [],
  orientations: [],
  loadings: {
    getAll: false,
    createOne: false,
    createOrientation: false,
  },
};

const resolutionReducer = produce(
  (draft = initialValues, action: TResolutionActions) => {
    switch (action.type) {
      case ResolutionActionEnum.SET_RESOLUTIONS: {
        draft.resolutions = action.payload;

        return draft;
      }

      case ResolutionActionEnum.SET_ORIENTATIONS: {
        draft.orientations = action.payload;

        return draft;
      }

      case ResolutionActionEnum.SET_LOADING: {
        draft.loadings[action.payload.type] = action.payload.value;

        return draft;
      }

      default: {
        return draft;
      }
    }
  },
  initialValues,
);

export default resolutionReducer;
