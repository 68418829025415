import { Group as GroupIcon } from '@mui/icons-material';
import {
  CampaignIcon,
  ChangePortalIcon,
  MediaIcon,
  SaveIcon,
  SignOutIcon,
  SiteIcon,
} from 'assets/icons';
import { USER_GUIDE_PDF_HREF } from 'constants/constants';
import { SideNavBarText } from 'constants/text/text';
import { urls } from 'constants/urls/url';
import { isRole } from 'helpers/role';
import { useActions, useTypedSelector } from 'hooks';
import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';
import { UserRolesEnum } from 'types/general';

import { IAdditionallyItems, TSideBarList } from './types';

interface ISideNavBarHook {
  classes: Record<
    | 'root'
    | 'content'
    | 'drawer'
    | 'paper'
    | 'drawerContainer'
    | 'noBorderRight',
    string
  >;
  isSmall: boolean;
  handleLogOut: () => void;
  handleChangeButton: () => void;
  hasRendered: boolean;
  sideBarLayout: TSideBarList;
}

const drawerWidth = {
  small: 60,
  default: 250,
};

const useStyles = makeStyles<{
  isSmall: boolean;
  isMedium: boolean;
  isExpand: boolean;
}>()((_theme, { isSmall, isExpand }) => ({
  root: {
    display: 'flex',
    height: '100%',
    paddingBottom: 20,
    backgroundColor: 'rgba(0,0,0,0.7)',
  },
  drawer: {
    flexShrink: 0,
    backgroundColor: '#000',
  },
  paper: {
    width: drawerWidth[isSmall && !isExpand ? 'small' : 'default'],
    transition: 'width 0.5s ease',
    overflow: isSmall ? 'hidden' : 'auto',
  },
  drawerContainer: {
    overflow: 'auto',
  },
  content: {
    flexGrow: 1,
    padding: '0',
  },
  noBorderRight: {
    borderRight: 0,
  },
}));

/** Specifies which urls the sideBar can be displayed on */
const allowedUrls = [urls.campaign, urls.sites, urls.media, urls.users];

const useSideNavBar = (): ISideNavBarHook => {
  const { currentWindowParam } = useTypedSelector(
    (state) => state && state.app,
  );
  const { hasLoggedIn, role } = useTypedSelector(
    (state) => state && state.auth,
  );
  const { organisation } = useTypedSelector(
    (state) => state && state.organisation,
  );
  const { isExpand } = useTypedSelector((state) => state && state.sideBar);

  const { classes } = useStyles({ ...currentWindowParam, isExpand });

  const { pathname } = useLocation();
  const navigate = useNavigate();

  const { signOut, sideBarCompress, cleanUpCampaignState, cleanupCurrent } =
    useActions();

  useEffect(() => {
    sideBarCompress();
  }, [currentWindowParam.isSmall]);

  const hasRendered: boolean =
    hasLoggedIn &&
    allowedUrls.some((elem) => pathname.startsWith(elem)) &&
    !!organisation.id;

  const handleLogOut = () => {
    cleanupCurrent();
    cleanUpCampaignState();
    signOut();
    navigate(urls.login);
  };

  const handleChangeButton = () => {
    sideBarCompress();
    cleanupCurrent();
    cleanUpCampaignState();
    navigate(urls.changePortal);
  };

  const openUserManual = () => {
    window.open(USER_GUIDE_PDF_HREF, '_blank');
  };

  // rendering always
  const listLayoutDefault: TSideBarList = {
    top: [
      {
        id: urls.campaign,
        label: SideNavBarText.campaign,
        icon: <CampaignIcon />,
        onClick: () => navigate(urls.campaign),
      },
      {
        id: urls.sites,
        label: SideNavBarText.sites,
        icon: <SiteIcon />,
        onClick: () => navigate(urls.sites),
      },
      {
        id: urls.media,
        label: SideNavBarText.mediaLibrary,
        icon: <MediaIcon />,
        onClick: () => navigate(urls.media),
      },
    ],
    bottom: [],
  };

  const additionallyListItems: IAdditionallyItems[] = [
    {
      id: urls.users,
      label: SideNavBarText.users,
      icon: <GroupIcon />,
      onClick: () => navigate(urls.users),
      roles: [UserRolesEnum.Admin, UserRolesEnum.SuperAdmin],
      place: 'top',
    },
    {
      id: 'change portal',
      label: SideNavBarText.changePortalButton,
      icon: <ChangePortalIcon />,
      onClick: handleChangeButton,
      roles: [UserRolesEnum.SuperAdmin],
      place: 'bottom',
    },
    {
      id: 'user guide',
      label: SideNavBarText.userGuide,
      icon: <SaveIcon />,
      onClick: openUserManual,
      roles: [UserRolesEnum.Marketing],
      place: 'bottom',
    },
    {
      id: 'sign out',
      label: SideNavBarText.signOutButton,
      icon: <SignOutIcon />,
      onClick: handleLogOut,
      roles: null,
      place: 'bottom',
    },
  ];

  const generateLayout = (
    defaultLayout: TSideBarList,
    additionally: IAdditionallyItems[],
  ): TSideBarList => {
    const layout = defaultLayout;

    additionally.forEach((elem) => {
      if (elem.roles) {
        if (isRole(elem.roles, role)) {
          layout[elem.place].push(elem);
        }
      } else {
        layout[elem.place].push(elem);
      }
    });

    return layout;
  };

  const sideBarLayout = generateLayout(
    listLayoutDefault,
    additionallyListItems,
  );

  return {
    handleLogOut,
    handleChangeButton,
    isSmall: currentWindowParam.isSmall,
    classes,
    hasRendered,
    sideBarLayout,
  };
};
export default useSideNavBar;
