import { TabPanel } from '@mui/lab';
import { colors } from 'constants/colors/colors';
import { withStyles } from 'tss-react/mui';

const STabPanel = withStyles(TabPanel, () => ({
  root: {
    display: 'grid',
    height: '100%',

    gridTemplateRows: '1fr auto',
    padding: 0,

    marginTop: 6,

    '.manage-campaign-content': {
      backgroundColor: colors.White,
      paddingTop: 24,

      '.tab-content-wrapper': {
        display: 'flex',
        justifyContent: 'center',

        position: 'relative',
        height: '100%',
        boxSizing: 'border-box',

        '.tab-content-wrapper-content': {
          position: 'relative',

          transition: '0.3s',
          width: '50%',

          '@media (max-width: 1200px)': {
            width: '75%',
          },
        },
      },
    },

    '.manage-campaign-footer': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      paddingTop: 20,

      '#adjustments': {
        stroke: colors.White,
      },
    },
  },
}));

export { STabPanel };
