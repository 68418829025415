import { TabPanel } from '@mui/lab';
import { Box, Button, Typography } from '@mui/material';
import { withStyles } from 'tss-react/mui';

const STabPanel = withStyles(TabPanel, {
  root: {
    position: 'relative',
    height: '100%',
  },
});

const SCanvas = withStyles(Box, {
  root: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,

    overflowY: 'auto',
  },
});

const STabContent = withStyles(Box, {
  root: {
    minHeight: '100%',
    paddingTop: 10,
    boxShadow:
      'inset 0 5px 10px -5px rgba(0,0,0,0.3), inset 0 -5px 10px -5px rgba(0,0,0,0.3)',
  },
});

const SMediaCardButton = withStyles(Button, {
  root: {},
});

const STypographyWrapper = withStyles(Box, {
  root: {
    padding: '0 2rem',
  },
});

const STypography = withStyles(Typography, {
  root: {
    font: 'normal normal bold 30px/60px Poppins-Bold',
  },
});

export {
  SCanvas,
  SMediaCardButton,
  STabContent,
  STabPanel,
  STypography,
  STypographyWrapper,
};
