import './Content.scss';

import { Typography } from '@mui/material';
import { useFormikContext } from 'formik';
import { usePrompt } from 'hooks/usePrompt';
import React from 'react';

import { messages, texts } from '../../../../constants/texts';
import {
  Broadsign,
  Centre,
  Formats,
  NameAndAddress,
  Networks,
  Resolution,
  SiteDayParts,
} from './components';

const AddSiteContent: React.FC = () => {
  const { dirty: isDirty, isSubmitting } = useFormikContext();

  const isPrompt = isDirty && !isSubmitting;

  usePrompt(messages.leave, isPrompt);

  return (
    <div className="sites-content">
      <Typography variant="h5">
        {texts.pages.manage.content.main.title}
      </Typography>
      <NameAndAddress />
      <Typography variant="h5">
        {texts.pages.manage.content.centreNetworks.title}
      </Typography>
      <Centre />
      <Networks />
      <Typography variant="h5">
        {texts.pages.manage.content.mediaConfig.title}
      </Typography>
      <Formats />
      <Resolution />
      <Typography variant="h5">
        {texts.pages.manage.content.broadsign.title}
      </Typography>
      <Broadsign />
      <Typography variant="h5">
        {texts.pages.manage.content.siteDayParts.title}
      </Typography>
      <SiteDayParts />
    </div>
  );
};

export default AddSiteContent;
