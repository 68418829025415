import { DraftIcon, PublishIcon } from 'assets/icons';
import { Button } from 'components/common';
import SvgIcon from 'components/common/SvgIcon/SvgIcon';
import { ManageCampaignModeEnum } from 'constants/enums';
import { urls } from 'constants/urls/url';
import { useActions, useError, useTypedSelector } from 'hooks';
import { useSnackbar } from 'notistack';
import { texts } from 'pages/Campaign/constants';
import React, { memo } from 'react';
import { useNavigate } from 'react-router-dom';

import { AlertStatusesEnum } from '../../../../../../constants/constants';
import useCampaign from '../../../../hooks/campaign.hook';
import useInspector from '../../../../shared/CampaignContent/hooks/useInspector';

const HeaderButtons: React.FC = () => {
  const { mode, campaignId } = useTypedSelector(
    (state) => state.manageCampaign.main,
  );
  const {
    saveCampaignAsDraft,
    editDraftCampaignAsDraft,
    publishCampaign,
    updateCampaign,
  } = useActions();

  const { getCampaignQueryScheme } = useCampaign();
  const { getIsCheckDetailFullFill, getIsCheckManageTabFullFill } =
    useInspector();
  const { enqueueSnackbar } = useSnackbar();
  const { outputError } = useError();
  const navigate = useNavigate();

  const handleClickSaveAsDraft = async () => {
    try {
      const campaignScheme = getCampaignQueryScheme();

      if (!campaignScheme) {
        outputError('', { isSnackbar: true });

        return;
      }

      if (mode === ManageCampaignModeEnum.ADD) {
        await saveCampaignAsDraft(campaignScheme);
        enqueueSnackbar('Saved as draft', { variant: AlertStatusesEnum.INFO });
        navigate(urls.campaign);

        return;
      }
      if (mode === ManageCampaignModeEnum.EDIT && campaignId) {
        await editDraftCampaignAsDraft(campaignScheme, campaignId);
        enqueueSnackbar('Saved as draft', { variant: AlertStatusesEnum.INFO });
        navigate(urls.campaign);
      }

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      outputError(error?.message, { isSnackbar: true });
    }
  };

  const handleClickUpload = async () => {
    try {
      const campaignScheme = getCampaignQueryScheme();

      if (!campaignScheme) {
        outputError('', { isSnackbar: true });

        return;
      }

      if (mode === ManageCampaignModeEnum.ADD) {
        await publishCampaign(campaignScheme);
        enqueueSnackbar('Campaign successfully added', {
          variant: AlertStatusesEnum.SUCCESS,
        });
        navigate(urls.campaign);

        return;
      }
      if (mode === ManageCampaignModeEnum.EDIT && campaignId) {
        await updateCampaign(campaignScheme, campaignId);
        enqueueSnackbar('Campaign successfully added', {
          variant: AlertStatusesEnum.SUCCESS,
        });
        navigate(urls.campaign);
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      outputError(error?.message, { isSnackbar: true });
    }
  };

  const isUploadDisabled = !(
    getIsCheckDetailFullFill() && getIsCheckManageTabFullFill()
  );

  return (
    <div className="__children-many-element-default-container">
      <Button
        label={texts.buttons.saveAsDraft}
        icon={
          <SvgIcon className="icon-xxxl">
            <DraftIcon className="whiteSVG" />
          </SvgIcon>
        }
        disabled={!getIsCheckDetailFullFill()}
        onClick={handleClickSaveAsDraft}
      />

      <Button
        label={texts.buttons.publish}
        className="successfully"
        icon={
          <SvgIcon className="icon-xxxl">
            <PublishIcon className="whiteSVG" />
          </SvgIcon>
        }
        disabled={isUploadDisabled}
        onClick={handleClickUpload}
      />
    </div>
  );
};

export default memo(HeaderButtons);
