import { SvgIconProps } from '@mui/material';
import React from 'react';

import { SSvgIcon } from './SvgIcon.styled';

const SvgIcon: React.FC<SvgIconProps> = ({ children, ...props }) => {
  return <SSvgIcon {...props}>{children}</SSvgIcon>;
};

export default SvgIcon;
