import React, { ReactElement } from 'react';
import { Navigate, Route } from 'react-router-dom';

import PrivateRoute from '../components/PrivateRoute/PrivateRoute';
import { urlParams, urls } from '../constants/urls/url';
import {
  AddCampaign,
  CampaignPage,
  ChangePortal,
  EditCampaign,
  ForgotPassword,
  LoginPage,
  MediaPage,
  Redirect,
  ResetPassword,
  ReviewCampaign,
  SitesPage,
  UsersPage,
} from '../pages';
import AddSite from '../pages/Sites/Pages/AddSite/AddSite';
import EditSite from '../pages/Sites/Pages/EditSite/EditSite';
import { UserRolesEnum } from '../types';
import { IRoute } from './types';

export const routesLayout: IRoute[] = [
  {
    path: urls.login,
    element: <LoginPage />,
  },
  {
    path: urls.forgot,
    element: <ForgotPassword />,
  },
  {
    path: urls.reset,
    element: <ResetPassword />,
  },

  {
    path: urls.changePortal,
    roles: [UserRolesEnum.SuperAdmin],
    element: (
      <PrivateRoute>
        <ChangePortal />
      </PrivateRoute>
    ),
  },
  {
    path: urls.campaign,
    element: (
      <PrivateRoute>
        <CampaignPage />
      </PrivateRoute>
    ),
    children: [
      {
        path: urlParams.new,
        element: (
          <PrivateRoute>
            <AddCampaign />
          </PrivateRoute>
        ),
      },
      {
        path: urlParams.id,
        element: <></>,
        children: [
          {
            path: urlParams.edit,
            element: (
              <PrivateRoute>
                <EditCampaign />
              </PrivateRoute>
            ),
          },
          {
            path: urlParams.review,
            element: (
              <PrivateRoute>
                <ReviewCampaign />
              </PrivateRoute>
            ),
            roles: [UserRolesEnum.Admin, UserRolesEnum.Editor],
          },
        ],
      },
    ],
  },
  {
    path: urls.sites,
    element: (
      <PrivateRoute>
        <SitesPage />
      </PrivateRoute>
    ),
    children: [
      {
        path: urlParams.new,
        element: (
          <PrivateRoute>
            <AddSite />
          </PrivateRoute>
        ),
        roles: [UserRolesEnum.SuperAdmin, UserRolesEnum.Admin],
      },
      {
        path: urlParams.id,
        element: <Navigate to={urls.sites} />,
        children: [
          {
            path: urlParams.edit,
            element: (
              <PrivateRoute>
                <EditSite />
              </PrivateRoute>
            ),
            roles: [UserRolesEnum.SuperAdmin, UserRolesEnum.Admin],
          },
        ],
      },
    ],
  },
  {
    path: urls.media,
    element: (
      <PrivateRoute>
        <MediaPage />
      </PrivateRoute>
    ),
  },
  {
    path: urls.users,
    element: (
      <PrivateRoute>
        <UsersPage />
      </PrivateRoute>
    ),
    roles: [UserRolesEnum.Admin, UserRolesEnum.SuperAdmin],
  },
  {
    path: urls.redirect,
    element: <Redirect />,
  },
];

export function generateRoutesMap(
  layout: IRoute[],
  role: UserRolesEnum | null,
): ReactElement[] {
  return layout.reduce<ReactElement[]>((acc, item) => {
    const isCondition =
      // NOTE: Checking, if the roles are specified, then we check the current user role, or if the roles are not specified, we skip in any case. Either you are a superadmin
      ((role && item.roles?.includes(role)) ||
        !item.roles?.length ||
        role === UserRolesEnum.SuperAdmin) &&
      // NOTE: Checking, if the operating modes of the program are specified, then we check with the current one, if not, we draw in any case
      ((item.mode && item.mode === process.env.NODE_ENV) || !item.mode);

    if (isCondition) {
      return [
        ...acc,
        <Route path={item.path} key={item.path}>
          <Route index element={item.element} />

          {item.children && generateRoutesMap(item.children, role)}
        </Route>,
      ];
    }

    return acc;
  }, []);
}
