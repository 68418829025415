import { PublishIcon } from 'assets/icons';
import { Button } from 'components/common';
import SvgIcon from 'components/common/SvgIcon/SvgIcon';
import { urls } from 'constants/urls/url';
import { useActions, useError, useTypedSelector } from 'hooks';
import { useSnackbar } from 'notistack';
import { texts } from 'pages/Campaign/constants';
import React, { memo } from 'react';
import { useNavigate } from 'react-router-dom';

import { AlertStatusesEnum } from '../../../../../../constants/constants';
import useCampaign from '../../../../hooks/campaign.hook';
import useInspector from '../../../../shared/CampaignContent/hooks/useInspector';

const HeaderButtons: React.FC = () => {
  const { campaignId } = useTypedSelector((state) => state.manageCampaign.main);
  const { getIsCheckDetailFullFill } = useInspector();
  const { updateCampaign } = useActions();
  const { enqueueSnackbar } = useSnackbar();
  const { outputError } = useError();
  const navigate = useNavigate();

  const { getCampaignQueryScheme } = useCampaign();

  const handleClick = async () => {
    try {
      const campaignScheme = getCampaignQueryScheme();
      if (campaignScheme && campaignId) {
        await updateCampaign(campaignScheme, campaignId);

        enqueueSnackbar('Campaign successfully updated', {
          variant: AlertStatusesEnum.SUCCESS,
        });
        navigate(urls.campaign);
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      outputError(error?.message, { isSnackbar: true });
    }
  };

  return (
    <Button
      disabled={!getIsCheckDetailFullFill()}
      label={texts.buttons.update}
      icon={
        <SvgIcon className="icon-l">
          <PublishIcon className="whiteSVG" />
        </SvgIcon>
      }
      className="successfully"
      onClick={handleClick}
    />
  );
};

export default memo(HeaderButtons);
