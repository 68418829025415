import { Dispatch } from 'redux';

import { ISiteDto } from '../../../../types';
import {
  ISetModalsPayload,
  ManageSiteActionEnum,
  TManageSiteActions,
} from '../../../ActionTypes';
import { createNewSiteApi, updateSiteApi } from '../sitesApi';

export const setNewCentre =
  (payload: boolean) =>
  (dispatch: Dispatch<TManageSiteActions>): void => {
    dispatch({ type: ManageSiteActionEnum.SET_IS_NEW_CENTRE, payload });
  };

export const setManageSiteModal =
  (payload: ISetModalsPayload) =>
  (dispatch: Dispatch<TManageSiteActions>): void => {
    dispatch({ type: ManageSiteActionEnum.SET_MODALS, payload });
  };

export const createSite = (payload: ISiteDto) => async (): Promise<void> => {
  await createNewSiteApi(payload);
};

export const updateSite =
  (id: number | string, payload: ISiteDto) => async (): Promise<void> => {
    await updateSiteApi(id, payload);
  };
