import {
  Button,
  ButtonProps,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import React, { MouseEvent, ReactNode, SyntheticEvent } from 'react';

interface ITitledButtonProps extends ButtonProps {
  title?: string;
}

interface IConfirmDialog {
  isOpen: boolean;
  onConfirm: (event: MouseEvent<HTMLButtonElement>) => void;
  onCancel: (event: MouseEvent<HTMLButtonElement>) => void;
  onClose?: (event: SyntheticEvent) => void;
  title?: string;
  CancelButtonProps?: ITitledButtonProps;
  ConfirmButtonProps?: ITitledButtonProps;
  children?: ReactNode;
}

const ConfirmDialog: React.FC<IConfirmDialog> = ({
  isOpen,
  title,
  onConfirm,
  onCancel,
  onClose = onCancel,
  CancelButtonProps,
  ConfirmButtonProps,
  children,
}) => {
  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>{title || 'Confirm the action'}</DialogTitle>
      {children && <DialogContent>{children}</DialogContent>}
      <DialogActions>
        <Button
          onClick={onCancel}
          color="error"
          variant="contained"
          {...CancelButtonProps}
        >
          {CancelButtonProps?.title || 'Cancel'}
        </Button>
        <Button
          onClick={onConfirm}
          color="primary"
          variant="contained"
          {...ConfirmButtonProps}
        >
          {ConfirmButtonProps?.title || 'Confirm'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialog;
