import './Carousel.scss';

import { FileTypesEnum } from 'constants/enums';
import { SwiperNavigationTypesEnum } from 'constants/swiper/enum';
import { useTypedSelector } from 'hooks';
import React from 'react';
import { Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import {
  Footer,
  ImageSlide,
  NavigationButtons,
  VideoSlide,
} from './components';

const bulletsNavigationLimit = 15;

const Carousel: React.FC = () => {
  const { viewAllMediaModal } = useTypedSelector(
    (state) => state && state.manageCampaign.manage,
  );

  const { medias } = viewAllMediaModal;

  const hasMediasMoreThanOne = medias.length > 1;

  const hasMediaMoreThanLimit = medias.length > bulletsNavigationLimit;

  const navigationType = hasMediaMoreThanLimit
    ? SwiperNavigationTypesEnum.FRACTION
    : SwiperNavigationTypesEnum.BULLETS;

  return (
    <>
      {hasMediasMoreThanOne && <NavigationButtons />}
      <Swiper
        modules={[Navigation, Pagination]}
        slidesPerView={1}
        spaceBetween={30}
        navigation={{
          enabled: hasMediasMoreThanOne,
          nextEl: '.swiper-next',
          prevEl: '.swiper-prev',
        }}
        loop={hasMediasMoreThanOne}
        pagination={{
          type: navigationType,
          clickable: true,
        }}
        autoHeight
        grabCursor
        lazy
      >
        {medias.map((media) => (
          <SwiperSlide key={media.id}>
            <div className="all-media-slide-wrapper">
              {media.type === FileTypesEnum.IMAGE && (
                <ImageSlide media={media} />
              )}
              {media.type === FileTypesEnum.VIDEO && (
                <VideoSlide media={media} />
              )}
              <Footer media={media} />
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  );
};

export default Carousel;
