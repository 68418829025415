export const messages = {
  form: {
    email: 'Enter a valid email',
    required: 'This field is required',
    length: {
      min: (length: number) => `This Field should be more than ${length}`,
      max: (length: number) => `This Field should be less than ${length}`,
    },
    confirmPassword: "Passwords don't match",
    oneUpperCase: 'Here must be at least one capital letter',
    oneDigit: 'Here must be at least one digit',
    forbiddenSymbol: 'You cannot use characters that are not letters',
  },
} as const;
