import { TabPanel } from '@mui/lab';
import { Button, TextField } from '@mui/material';
import { FormikHelpers, useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import React, { memo } from 'react';
import { SingleValue } from 'react-select';

import { AlertStatusesEnum } from '../../../../../../../../../../constants/constants';
import { convertToSelectData } from '../../../../../../../../../../helpers';
import {
  useActions,
  useError,
  useTypedSelector,
} from '../../../../../../../../../../hooks';
import { ISelectOption } from '../../../../../../../../../../types/general';
import { Select } from '../../../../../../../..';
import { messages, texts } from '../../../../../constants';
import { StaffEnum, TabIdsEnum } from '../../../../../constants/enum';
import { validationSchemaCentre } from '../../../constants/validationSchemas';
import { ITabPropsDefault } from '../Tabs';

const { fromObjectIdWithName } = convertToSelectData;

interface IForm {
  [texts.centre.formName]: string;
  state: ISelectOption | null;
}

const CentreTab: React.FC<ITabPropsDefault> = ({ handleModalClose }) => {
  const { addNewCentre, getCentres } = useActions();
  const { enqueueSnackbar } = useSnackbar();

  const { outputError } = useError();

  const onSubmit = async (
    values: IForm,
    { setSubmitting, resetForm }: FormikHelpers<IForm>,
  ) => {
    try {
      await addNewCentre({
        name: values[texts.centre.formName],
        stateId: Number(values.state?.value),
      });
      enqueueSnackbar(messages.success(StaffEnum.CENTRE), {
        variant: AlertStatusesEnum.SUCCESS,
        anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
      });
      resetForm();
      await getCentres();
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      outputError(error?.message, { isSnackbar: true });
    } finally {
      setSubmitting(false);
    }
  };
  const {
    values,
    errors,
    isSubmitting,
    handleChange,
    setFieldValue,
    handleSubmit,
  } = useFormik<IForm>({
    initialValues: {
      [texts.centre.formName]: '',
      state: null,
    },
    validationSchema: validationSchemaCentre,
    onSubmit,
    validateOnMount: false,
    validateOnChange: false,
  });
  const { states, loading } = useTypedSelector(
    (state) => state && state.states,
  );

  const handleStateChange = (newValue: SingleValue<ISelectOption>) => {
    setFieldValue('state', newValue);
  };

  return (
    <TabPanel value={TabIdsEnum.CENTRE} sx={{ height: '100%' }}>
      <form onSubmit={handleSubmit}>
        <div className="content centre-content">
          <TextField
            label={texts.centre.input}
            value={values.centreName}
            name={texts.centre.formName}
            onChange={handleChange}
            error={Boolean(errors[texts.centre.formName])}
            helperText={errors[texts.centre.formName]}
            disabled={isSubmitting}
            fullWidth
          />
          <Select
            options={fromObjectIdWithName(states)}
            value={values.state}
            onChange={handleStateChange}
            error={Boolean(errors.state)}
            helperText={errors.state}
            label={texts.centre.selectState}
            placeholder={texts.centre.selectState}
            isDisabled={loading.getAll || isSubmitting}
            isLoading={loading.getAll}
          />
        </div>
        <div className="actions centre-actions">
          <Button variant="contained" type="submit" disabled={isSubmitting}>
            {texts.actions.submit}
          </Button>
          <Button
            variant="contained"
            color="error"
            disabled={isSubmitting}
            onClick={handleModalClose}
          >
            {texts.actions.close}
          </Button>
        </div>
      </form>
    </TabPanel>
  );
};

export default memo(CentreTab);
