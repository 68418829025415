import { ImageOutlined as ImageOutlinedIcon } from '@mui/icons-material';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Getter, Row } from '@tanstack/table-core';
import { ManageCampaignModeEnum } from 'constants/enums';
import { useTypedSelector } from 'hooks';
import React from 'react';
import { IMediaForCreateCampaign } from 'types';

import useAddMedia from '../../../../../../../Modals/AddMedia/hooks/AddMedia.hook';
import { ICampaignSitesDataTable } from '../../../../helpers/table';
import { SButton } from '../../Content.styled';

interface IMediaButton {
  row: Row<ICampaignSitesDataTable>;
  getValue: Getter<unknown>;
}

const MediaButton: React.FC<IMediaButton> = ({ row, getValue }) => {
  const { mode } = useTypedSelector(
    (state) => state && state.manageCampaign.main,
  );
  const { handleAddMediaOpenWithContain } = useAddMedia();

  if (row.getCanExpand() || Array.isArray(row.original.campaignScheduleIds)) {
    return null;
  }

  const value = getValue<IMediaForCreateCampaign[]>();
  const isReview = mode === ManageCampaignModeEnum.REVIEW;

  const isDisabled = !row.original.campaignScheduleIds || isReview;

  if (!value.length) {
    return (
      <SButton
        startIcon={<ImageOutlinedIcon />}
        onClick={() =>
          handleAddMediaOpenWithContain(
            row.original.campaignScheduleIds as string | number | undefined,
          )
        }
        disabled={isDisabled}
      >
        Add Media
      </SButton>
    );
  }

  return (
    <SButton
      startIcon={<ImageOutlinedIcon />}
      onClick={() =>
        handleAddMediaOpenWithContain(
          row.original.campaignScheduleIds as string | number | undefined,
        )
      }
      disabled={isReview}
    >
      View media
    </SButton>
  );
};

export default MediaButton;
