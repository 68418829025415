import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import { Button, Input } from 'components/common';
import { useActions, useTypedSelector } from 'hooks';
import React, { ChangeEvent, useEffect, useState } from 'react';

const RenameFileModal: React.FC = () => {
  const { currentRenamedFile } = useTypedSelector(
    (state) => state && state.manageCampaign.manage.addMediaModal,
  );
  const { setCurrentRenamedFile, renameLoadedFile, renameMediaNew } =
    useActions();

  const [name, setName] = useState<string>('');

  useEffect(() => {
    if (currentRenamedFile?.name) {
      setName(currentRenamedFile.name);
    }
  }, [currentRenamedFile?.name]);

  const hasFile = Boolean(currentRenamedFile);

  const handleClose = () => {
    setCurrentRenamedFile(null);
  };

  if (!currentRenamedFile) {
    return null;
  }

  const onNameChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
    setName(target.value);
  };

  const handleRename = () => {
    if (name) {
      renameLoadedFile({ id: currentRenamedFile.id, name });
      handleClose();

      if (typeof currentRenamedFile.id === 'number') {
        renameMediaNew(currentRenamedFile.id, name);
      }
    }
  };

  return (
    <Dialog open={hasFile} onClose={handleClose} fullWidth maxWidth="xs">
      <DialogTitle>{currentRenamedFile.name}</DialogTitle>
      <DialogContent>
        <Input label="New name" value={name} onChange={onNameChange} />
      </DialogContent>
      <DialogActions>
        <Button label="Cancel" onClick={handleClose} color="error" />
        <Button
          label="Rename"
          onClick={handleRename}
          disabled={!name}
          className="successfully"
        />
      </DialogActions>
    </Dialog>
  );
};

export default RenameFileModal;
