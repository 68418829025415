import { SvgIcon } from '@mui/material';
import { withStyles } from 'tss-react/mui';

import { getObjectSizes } from './sizes';

const SSvgIcon = withStyles(SvgIcon, {
  root: {
    ...getObjectSizes(),
  },
});

export { SSvgIcon };
