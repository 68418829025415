import { Paper } from '@mui/material';
import { withStyles } from 'tss-react/mui';

const SPaper = withStyles(Paper, {
  root: {
    height: 960,
  },
});

export { SPaper };
