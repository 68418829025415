import { Dispatch } from 'redux';

import { IObjectIdWithName } from '../../../types/general';
import { NetworksActionsEnum, TNetworksAction } from '../../ActionTypes';
import { createNewNetworkApi, getNetworksApi } from './networksApi';

export const getNetworks =
  () =>
  async (dispatch: Dispatch<TNetworksAction>): Promise<void> => {
    try {
      dispatch({
        type: NetworksActionsEnum.SET_LOADING,
        payload: { type: 'getAll', value: true },
      });
      const {
        data: { data },
      } = await getNetworksApi<{ data: IObjectIdWithName[] }>();
      dispatch({ type: NetworksActionsEnum.SET_NETWORKS, payload: data });
    } catch (error) {
      console.error(error);
    } finally {
      dispatch({
        type: NetworksActionsEnum.SET_LOADING,
        payload: { type: 'getAll', value: false },
      });
    }
  };

export const createNewNetwork =
  (name: string) =>
  async (dispatch: Dispatch<TNetworksAction>): Promise<void> => {
    try {
      dispatch({
        type: NetworksActionsEnum.SET_LOADING,
        payload: { type: 'createOne', value: true },
      });
      await createNewNetworkApi({ name });
    } catch (error: any) {
      const message = error?.response?.data?.UserMessage || error?.message;

      console.error(message);
      throw new Error(message);
    } finally {
      dispatch({
        type: NetworksActionsEnum.SET_LOADING,
        payload: { type: 'createOne', value: false },
      });
    }
  };
