import { Row } from '@tanstack/react-table';
import { ChangeEvent, MouseEvent, useEffect } from 'react';

import { ITableHookProps, ITableHookReturn } from './types';

const useTableHook = <TData>({
  isSSR,
  selected,
  table,
  rowSelecting,
  onRowClick,
  onSelectedChange,
  handleGlobalSearch,
  setGlobalTableSearch,
}: ITableHookProps<TData>): ITableHookReturn<TData> => {
  useEffect(() => {
    onSelectedChange?.(table.getSelectedRowModel().flatRows);
  }, [selected]);

  const handleRowClick = (row: Row<TData>, event?: MouseEvent): void => {
    if (rowSelecting) {
      row.toggleSelected();

      onRowClick?.(row, !row.getIsSelected(), event);
    }
  };

  const handleChangeGlobalSearch = ({
    target: { value },
  }: ChangeEvent<HTMLInputElement>) => {
    if (isSSR) {
      handleGlobalSearch?.(value);
    } else {
      setGlobalTableSearch(value);
    }
  };

  return { handleRowClick, handleChangeGlobalSearch };
};

export default useTableHook;
