import { useTypedSelector } from 'hooks';

interface IUsersContentModalsHookReturn {
  isViewManageUser: boolean;
  isViewDeleteOne: boolean;
  isViewBulkDelete: boolean;
}

const useUsersContentModals = (): IUsersContentModalsHookReturn => {
  const { loaders, modals, allUsersTable } = useTypedSelector(
    (state) => state && state.users,
  );

  const isViewManageUser =
    loaders.getAll === false && Boolean(modals.manageUser);

  const isViewDeleteOne = loaders.getAll === false && Boolean(modals.deleteOne);

  const isViewBulkDelete =
    loaders.getAll === false &&
    Boolean(modals.deleteBulk) &&
    Boolean(allUsersTable.selectedUsers.length);

  return { isViewManageUser, isViewDeleteOne, isViewBulkDelete };
};

export default useUsersContentModals;
