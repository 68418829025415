import * as yup from 'yup';

import { regulars } from '../../../../../../../../constants/regular/regulars';
import { UserRolesEnum } from '../../../../../../../../types';
import { messages } from './texts';

const passwordMinLength = 8;
export const maxLengthUserInputs = 16;
const minLengthUserInput = 3;

const { required, email, length, oneUpperCase, oneDigit, confirmPassword } =
  messages.form;

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const validationSchema = (roleItems: UserRolesEnum[]) =>
  yup.object().shape({
    firstName: yup
      .string()
      .required(required)
      .min(minLengthUserInput, length.min(minLengthUserInput))
      .max(maxLengthUserInputs, length.max(maxLengthUserInputs))
      .test(
        'Not contain forbidden Symbol',
        messages.form.forbiddenSymbol,
        (value) => !regulars.forbiddenSymbol.test(value || ''),
      )
      .trim(),
    lastName: yup
      .string()
      .required(required)
      .min(minLengthUserInput, length.min(minLengthUserInput))
      .max(maxLengthUserInputs, length.max(maxLengthUserInputs))
      .test(
        'Not contain forbidden Symbol',
        messages.form.forbiddenSymbol,
        (value) => !regulars.forbiddenSymbol.test(value || ''),
      )
      .trim(),
    userName: yup
      .string()
      .required(required)
      .min(minLengthUserInput, length.min(minLengthUserInput))
      .max(maxLengthUserInputs, length.max(maxLengthUserInputs))
      .test(
        'Not contain forbidden Symbol',
        messages.form.forbiddenSymbol,
        (value) => !regulars.forbiddenSymbol.test(value || ''),
      )
      .trim(),
    email: yup.string().email(email).required(required).trim(),
    role: yup.mixed().oneOf(Object.values(roleItems)).required(required),
    password: yup.string().when('isUpdate', {
      is: false,
      then: yup
        .string()
        .min(passwordMinLength, length.min(passwordMinLength))
        .matches(regulars.oneOfUpperCaseWord, oneUpperCase)
        .matches(regulars.oneOfDigit, oneDigit)
        .required(required)
        .trim(),
    }),
    confirmPassword: yup
      .string()
      .when('isUpdate', {
        is: false,
        then: yup
          .string()
          .min(passwordMinLength, length.min(passwordMinLength))
          .required(required)
          .oneOf([yup.ref('password')], confirmPassword),
        otherwise: yup.string().when('password', (password, schema) => {
          return password
            ? schema
                .required(required)
                .oneOf([yup.ref('password')], confirmPassword)
            : schema;
        }),
      })
      .trim(),

    isUpdate: yup.boolean(),
  });
