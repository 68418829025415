import { CheckedIcon, IndeterminateIcon, UncheckedIcon } from 'assets/icons';
import classNames from 'classnames';
import SvgIcon from 'components/common/SvgIcon/SvgIcon';
import React from 'react';

import { SCheckbox, SFormControlLabel } from './Checkbox.styled';

interface ICheckbox {
  id?: string;
  name?: string;
  isChecked: boolean;
  indeterminate?: boolean;
  disableRipple?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  withoutPaddings?: boolean;
  label?: string;
  classNames?: string;
}

const Checkbox: React.FC<ICheckbox> = ({
  isChecked = false,
  indeterminate = false,
  onChange,
  id,
  name,
  disableRipple,
  withoutPaddings,
  label,
}) => {
  return (
    <SFormControlLabel
      control={
        <SCheckbox
          id={id}
          name={name}
          checked={isChecked}
          indeterminate={indeterminate}
          onChange={onChange}
          className={classNames({ withoutPaddings, withLabel: Boolean(label) })}
          disableRipple={disableRipple}
          icon={
            <SvgIcon className="icon-l checkbox-default">
              <UncheckedIcon />
            </SvgIcon>
          }
          checkedIcon={
            <SvgIcon className="icon-l checkbox-default">
              <CheckedIcon />
            </SvgIcon>
          }
          indeterminateIcon={
            <SvgIcon className="icon-l checkbox-default">
              <IndeterminateIcon />
            </SvgIcon>
          }
        />
      }
      label={label}
    />
  );
};

export default Checkbox;
