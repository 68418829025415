import BackdropWithLoader from 'components/common/Backdrop';
import { useFormikContext } from 'formik';
import { useTypedSelector } from 'hooks/useTypedSelector';
import React from 'react';

import IncorrectDayParts from './IncorrectDayParts/IncorrectDayParts';

const Modals: React.FC = () => {
  const { modals } = useTypedSelector((state) => state && state.manageSite);
  const { isSubmitting } = useFormikContext();

  return (
    <>
      {modals.hasIncorrectDayParts && <IncorrectDayParts />}
      {<BackdropWithLoader open={isSubmitting} />}
    </>
  );
};

export default Modals;
