export const texts = {
  title: 'You can choose the centers and the networks to them',
  selects: {
    centre: 'Centre',
    networks: ' Networks',
  },
  buttons: {
    oneMore: 'One more',
  },
};
