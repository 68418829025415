import { useTypedSelector } from 'hooks';
import React from 'react';

import AllTab from './AllTab/AllTab';
import RecentlyTab from './RecentlyTab/RecentlyTab';
import SelectedTab from './SelectedTab/SelectedTab';

const Tabs: React.FC = () => {
  const { campaignSchedule } = useTypedSelector(
    (state) => state && state.manageCampaign.manage.addMediaModal,
  );

  const isViewSelectedTab = Boolean(campaignSchedule?.scheduleMedias.length);

  return (
    <div className="tab-wrapper">
      {isViewSelectedTab && <SelectedTab />}
      <RecentlyTab />
      <AllTab />
    </div>
  );
};

export default Tabs;
