/* eslint-disable max-lines */
/* eslint-disable max-params-no-constructor/max-params-no-constructor */
import 'moment-duration-format';

import DefVideoImg from 'assets/images/play-video.jpg';
import {
  DEFAULT_DATE_FORMAT_REVERSE,
  DEFAULT_DATE_FORMAT_REVERSE_SHORT,
  DEFAULT_SCHEDULE_VALUE,
  DEFAULT_SLASH_DATE_FORMAT,
  DEFAULT_TIME_FORMAT,
  DEFAULT_TIME_FORMAT_AM_PM,
  FORMAT_FOR_DURATION,
  TABLE_MEDIA_VALUE,
  TIME_MILLISECOND,
} from 'constants/constants';
import { dateFnsFormats } from 'constants/date/date';
import moment from 'moment';
import { ReactNode } from 'react';
import { IAddMediaModal } from 'redux/Reducers/campaignsReducer/manage/manage/type';
import {
  ICampaign,
  ICampaignById,
  ICampaignScheduleById,
  IMedia,
  IMediaForCreateCampaign,
  IMenuListItemsFuncProps,
  INewCampaign,
  ISchedule,
  ISites,
  TNullable,
} from 'types';

import { FileTypesEnum } from '../constants/enums/fileLoadStatuses';
import { formatFromISO } from '.';

export type TCampaignTableDataItem = {
  id: {
    name: string;
    value: number;
  };
  name: {
    name: string;
    value: string | null;
  };
  authorName: {
    name: string;
    value: string | null;
  };
  type: {
    name: string;
    value: string | null;
  };
  advertiserName: {
    name: string;
    value: string | null;
  };
  productName: {
    name: string;
    value: string | null;
  };
  categoryName: {
    name: string;
    value: string | null;
  };
  status: {
    name: string;
    value: string | null;
  };
  startDate: {
    name: string;
    value: string | null;
  };
  endDate: {
    name: string;
    value: string | null;
  };
  siteCount: {
    name: string;
    value: string | null;
  };
};

export type TSitesTableDataItem = {
  id: {
    name: string;
    value: number;
  };
  name: {
    name: string;
    value: string | null;
  };
  centre: {
    name: string;
    value: string | null;
  };
  state: {
    name: string;
    value: string | null;
  };
  screenSize: {
    name: string;
    value: string | null;
  };
  orientation: {
    name: string;
    value: string | null;
  };
  formats: {
    name: string;
    value: string | null;
    isUnsorting?: boolean;
  };
  network: {
    name: string;
    value: string | null;
  };
  startTime: {
    name: string;
    value: string | null;
  };
  endTime: {
    name: string;
    value: string | null;
  };
};

export type TMediaTableDataItem = {
  id: {
    name: string;
    value: number;
  };
  name: {
    name: string;
    value: string;
  };
  createdDate: {
    name: string;
    value: string;
  };
  type: {
    name: string;
    value: string;
  };
  author: {
    name: string;
    value: string;
  };
  url: {
    name: string;
    value: string;
  };
  advertiser: {
    name: string;
    value: string | null;
  };
  product: {
    name: string;
    value: string | null;
  };
  category: {
    name: string;
    value: string | null;
  };
};
export type TSelectedSitesSchedule = {
  id: {
    name: string;
    value: number;
  };
  sitesId: {
    name: string;
    value: number;
  };
  name: {
    name: string;
    value?: string;
  };
  centre: {
    name: string;
    value?: string;
  };
  state: {
    name: string;
    value?: string;
  };
  duration: {
    name: string;
    value: string | string[];
    onClick: (options: IMenuListItemsFuncProps) => void;
  };
  screenSize: {
    name: string;
    value?: string;
  };
  dates: {
    name: string;
    value: string | string[];
    onClick: (options: IMenuListItemsFuncProps) => void;
  };
  times: {
    name: string;
    value: string | string[];
    onClick: (options: IMenuListItemsFuncProps) => void;
  };
  days: {
    name: string;
    value: string | string[];
    onClick: (options: IMenuListItemsFuncProps) => void;
  };
  formats: {
    name: string;
    value?: string;
    isUnsorted?: boolean;
  };
  media: {
    name: string;
    value: string | string[];
    onClick: (options: IMenuListItemsFuncProps) => void;
    icon: ReactNode;
    isUnsorted?: boolean;
  };
};

export const newCampaignCloneConstructor = (
  currentCampaign: ICampaignById,
): INewCampaign => {
  const cloneCampaign: INewCampaign = {
    name: '',
    isPreemptive: currentCampaign.isPreemptive
      ? currentCampaign.isPreemptive
      : false,
    advertiserId: currentCampaign.advertiser.id
      ? currentCampaign.advertiser.id
      : null,
    categoryId: currentCampaign.category?.id
      ? currentCampaign.category.id
      : null,
    productId: currentCampaign.product?.id ? currentCampaign.product.id : null,
    typeId: currentCampaign.type.id ? Number(currentCampaign.type.id) : null,
    campaignSites: [],
  };

  return cloneCampaign;
};

export const newCampaignConstructor = (
  currentCampaign: ICampaignById,
): INewCampaign => {
  const cloneCampaign: INewCampaign = {
    name: currentCampaign.name,
    isPreemptive: currentCampaign.isPreemptive
      ? currentCampaign.isPreemptive
      : false,
    advertiserId: currentCampaign.advertiser.id
      ? currentCampaign.advertiser.id
      : null,
    categoryId: currentCampaign.category?.id
      ? currentCampaign.category?.id
      : null,
    productId: currentCampaign.product?.id ? currentCampaign.product?.id : null,
    typeId: currentCampaign.type.id ? Number(currentCampaign.type.id) : null,
    campaignSites: currentCampaign.campaignSites.map((item) => ({
      siteId: item.siteId,
      id: item?.id,
      endTime: item.endTime,
      startTime: item.startTime,
      campaignSchedules: item.campaignSchedules,
      centre: item.centre,
      duration: item.duration,
      formats: item.formats,
      siteName: item.siteName,
      state: item.state,
    })),
  };

  return cloneCampaign;
};

export const createCampaignsData = (
  campaigns: ICampaign[],
): TCampaignTableDataItem[] => {
  const createData = (
    id: number,
    name: string | null,
    author: string | null,
    type: string | null,
    advertiser: string | null,
    product: string | null,
    category: string | null,
    status: string | null,
    startDate: string | null,
    endDate: string | null,
    sitesCount: string | null,
  ) => ({
    id: {
      name: 'ID',
      value: id,
    },
    name: {
      name: 'Campaign name',
      value: name,
    },
    authorName: {
      name: 'Created by',
      value: author,
    },
    type: {
      name: 'Type',
      value: type,
    },
    advertiserName: {
      name: 'Advertiser',
      value: advertiser,
    },
    productName: {
      name: 'Product',
      value: product,
    },
    categoryName: {
      name: 'Category',
      value: category,
    },
    status: {
      name: 'Status',
      value: status,
    },
    startDate: {
      name: 'Starts',
      value: startDate,
    },
    endDate: {
      name: 'Ends',
      value: endDate,
    },
    siteCount: {
      name: '# of sites',
      value: sitesCount,
    },
  });

  const rows: TCampaignTableDataItem[] =
    campaigns &&
    campaigns.map((campaign) => {
      const newStartDate = campaign.startDate
        ? formatFromISO(campaign.startDate, dateFnsFormats.default('/'))
        : '';

      const newEndDate = campaign.endDate
        ? formatFromISO(campaign.endDate, dateFnsFormats.default('/'))
        : '';

      return createData(
        campaign.id,
        campaign.name,
        campaign.authorName,
        campaign.type,
        campaign.advertiserName,
        campaign.productName,
        campaign.categoryName,
        campaign.status,
        newStartDate,
        newEndDate,
        String(campaign.siteCount),
      );
    });

  return rows;
};

export const createSitesData = (sites: ISites[]): TSitesTableDataItem[] => {
  const createData = (
    id: number,
    state: string | null,
    centre: string | null,
    orientation: string | null,
    screenSize: string | null,
    formats: string | null,
    name: string | null,
    network: string | null,
    startTime: string | null,
    endTime: string | null,
    duration: string | undefined,
  ) => ({
    id: {
      name: 'Site ID',
      value: id,
    },
    name: {
      name: 'Site name',
      value: name,
    },
    centre: {
      name: 'Centre',
      value: centre,
    },
    state: {
      name: 'State',
      value: state,
    },
    screenSize: {
      name: 'Dimensions',
      value: screenSize,
    },
    orientation: {
      name: 'Type',
      value: orientation,
    },
    formats: {
      name: 'Formats',
      value: formats,
      isUnsorting: true,
    },
    network: {
      name: 'Network',
      value: network,
    },
    startTime: {
      name: 'Start time',
      value: startTime,
    },
    endTime: {
      name: 'End time',
      value: endTime,
    },
    duration: {
      name: 'Duration',
      value: duration,
    },
  });

  const rows: TSitesTableDataItem[] =
    sites &&
    sites.map((site) => {
      const durationPart = site.siteDayParts.map((el) =>
        moment
          .duration(el.adLength, TIME_MILLISECOND)
          .format(FORMAT_FOR_DURATION),
      );

      return createData(
        site.id,
        site.state,
        site.centre,
        site.orientation,
        site.screenSize,
        site.formats,
        site.name,
        site.network,
        site.startTime,
        site.endTime,
        site.siteDayParts.length > 1
          ? durationPart.join('/')
          : durationPart.join(''),
      );
    });

  return rows;
};

export const createTableDates = (
  startDate: Date | null,
  endDate: Date | null,
): string =>
  `${moment(startDate).format(DEFAULT_DATE_FORMAT_REVERSE_SHORT)} - ${moment(
    endDate,
  ).format(DEFAULT_DATE_FORMAT_REVERSE)}`;

export const createTableTimes = (
  startTime: moment.Moment | null,
  endTime: moment.Moment | null,
): string =>
  `${moment(startTime).format(DEFAULT_TIME_FORMAT_AM_PM)} - ${moment(
    endTime,
  ).format(DEFAULT_TIME_FORMAT_AM_PM)}`;

export const createSitesScheduleData = (
  campaign: ICampaignScheduleById,
  handleOpenScheduleModal: (options: IMenuListItemsFuncProps) => void,
  handleOpenMediaModal: (options: IMenuListItemsFuncProps) => void,
  icon: ReactNode,
): TSelectedSitesSchedule[] => {
  const schedules: TSelectedSitesSchedule[] = [];

  campaign.campaignSites.forEach((item) => {
    const destruct = (el: string[]) => (el.length > 1 ? el : el[0]);
    const dates =
      destruct(
        item.campaignSchedules.map((el) =>
          createTableDates(new Date(el.startDate), new Date(el.endDate)),
        ),
      ) || DEFAULT_SCHEDULE_VALUE.datesValue;
    const times =
      destruct(
        item.campaignSchedules.map((el) =>
          createTableTimes(
            moment(el.startTime, DEFAULT_TIME_FORMAT),
            moment(el.endTime, DEFAULT_TIME_FORMAT),
          ),
        ),
      ) || DEFAULT_SCHEDULE_VALUE.datesValue;

    const maxLengthForSingleSchedule = 1;

    const getDurationFromBackEnd = () =>
      destruct(
        item.siteDayParts.map((el) =>
          String(
            moment
              .duration(Number(el.adLength), TIME_MILLISECOND)
              .format(FORMAT_FOR_DURATION),
          ),
        ),
      );

    const getDurationFromSchedule = () =>
      destruct(
        item.campaignSchedules.map((el) =>
          String(
            moment
              .duration(Number(el.duration), TIME_MILLISECOND)
              .format(FORMAT_FOR_DURATION),
          ),
        ),
      );

    const days =
      destruct(item.campaignSchedules.map((el) => el.dayOfWeek)) ||
      DEFAULT_SCHEDULE_VALUE.days;
    const duration =
      item.siteDayParts.length > maxLengthForSingleSchedule
        ? getDurationFromSchedule() || getDurationFromBackEnd()
        : getDurationFromBackEnd();

    const medias = item.campaignSchedules.map((el) => {
      if (el.scheduleMedias?.length) {
        return TABLE_MEDIA_VALUE.existMedia;
      }

      return TABLE_MEDIA_VALUE.nonExistMedia;
    });
    const media = item.campaignSchedules.length > 1 ? medias : medias[0];
    schedules.push({
      id: {
        name: 'id',
        value: item.id,
      },
      sitesId: {
        name: 'Site ID',
        value: item.siteId,
      },
      name: {
        name: 'Site name',
        value: item.siteName || '',
      },
      centre: {
        name: 'Centre',
        value: item.centre || '',
      },
      state: {
        name: 'State',
        value: item.state || '',
      },
      screenSize: {
        name: 'Dimensions',
        value: item.screenSize,
      },
      duration: {
        name: 'Duration',
        value: duration,
        onClick: handleOpenScheduleModal,
      },
      dates: {
        name: 'Campaign dates',
        value: dates,
        onClick: handleOpenScheduleModal,
      },
      times: {
        name: 'Times',
        value: times,
        onClick: handleOpenScheduleModal,
      },
      days: {
        name: 'Day parting',
        value: days,
        onClick: handleOpenScheduleModal,
      },
      formats: {
        name: 'Formats',
        value: item.formats || '',
      },
      media: {
        name: 'Media',
        value: media || TABLE_MEDIA_VALUE.nonExistMedia,
        onClick: handleOpenMediaModal,
        icon,
      },
    });
  });

  return schedules;
};

export const createMediaData = (
  media: IMedia[],
  handleOpenPreview: (url: string, type: string) => void,
): TMediaTableDataItem[] => {
  const createData = (
    id: number,
    name: string,
    type: string,
    author: string,
    url: string,
    advertiser: string[] | null,
    product: string[] | null,
    category: string[] | null,
    createdDate: string,
    dimensions: string | null,
    siteCount: number | null,
  ) => ({
    id: {
      name: 'ID',
      value: id,
    },
    url: {
      name: 'Content',
      value:
        type === 'Image'
          ? `
          <img 
          loading="lazy"
          width="40"
          height="60"
          src="${url}"
          class="table-image"
          />`
          : `<img 
          loading="lazy"
          width="40"
          height="60"
          src="${DefVideoImg}"
          class="table-video-image"
          " />`,
      onClick: () => handleOpenPreview(url, type),
    },
    name: {
      name: 'Name',
      value: name,
    },
    author: {
      name: 'Uploaded by',
      value: author,
    },
    type: {
      name: 'Type',
      value: type,
    },
    dimensions: {
      name: 'Dimensions',
      value: dimensions,
    },
    advertiser: {
      name: 'Advertiser',
      value: advertiser?.length ? advertiser.filter(Boolean).join(', ') : null,
    },
    product: {
      name: 'Product',
      value: product?.length ? product.filter(Boolean).join(', ') : null,
    },
    category: {
      name: 'Category',
      value: category?.length ? category.filter(Boolean).join(', ') : null,
    },
    createdDate: {
      name: 'Date added',
      value: createdDate,
    },
    siteCount: {
      name: 'Number of sites',
      value: String(siteCount),
    },
  });

  const rows: TMediaTableDataItem[] =
    media &&
    media.map((mediaItem) => {
      const formattedCreatedDate = moment(mediaItem.createdDate).format(
        DEFAULT_SLASH_DATE_FORMAT,
      );
      const data = createData(
        mediaItem.id,
        mediaItem.name,
        mediaItem.type,
        mediaItem.author,
        mediaItem.url,
        mediaItem.advertisers,
        mediaItem.products,
        mediaItem.categories,
        formattedCreatedDate,
        mediaItem.dimensions,
        mediaItem.siteCount,
      );

      return data;
    });

  return rows;
};

/**
 * This is a copied version of the method above,
 * the only change here is the **handleOpenPreview** method
 */
export const createMediaDataManageCampaign = (
  media: IMedia[],
  handleOpenPreview: (data: IAddMediaModal['currentLookingMedia']) => void,
): TMediaTableDataItem[] => {
  const createData = (
    id: number,
    name: string,
    type: FileTypesEnum,
    author: string,
    url: string,
    advertiser: string[] | null,
    product: string[] | null,
    category: string[] | null,
    createdDate: string,
    dimensions: string | null,
    siteCount: number | null,
  ) => ({
    id: {
      name: 'ID',
      value: id,
    },
    url: {
      name: 'Content',
      value:
        type === FileTypesEnum.IMAGE
          ? `
          <img 
          loading="lazy"
          width="40"
          height="60"
          src="${url}"
          class="table-image"
          />`
          : `<img 
          loading="lazy"
          width="40"
          height="60"
          src="${DefVideoImg}"
          class="table-video-image"
          " />`,
      onClick: () => handleOpenPreview({ name, fileType: type, url }),
    },
    name: {
      name: 'Name',
      value: name,
    },
    author: {
      name: 'Uploaded by',
      value: author,
    },
    type: {
      name: 'Type',
      value: type,
    },
    dimensions: {
      name: 'Dimensions',
      value: dimensions,
    },
    advertiser: {
      name: 'Advertiser',
      value: advertiser?.length ? advertiser.filter(Boolean).join(', ') : null,
    },
    product: {
      name: 'Product',
      value: product?.length ? product.filter(Boolean).join(', ') : null,
    },
    category: {
      name: 'Category',
      value: category?.length ? category.filter(Boolean).join(', ') : null,
    },
    createdDate: {
      name: 'Date added',
      value: createdDate,
    },
    siteCount: {
      name: 'Number of sites',
      value: String(siteCount),
    },
  });

  const rows: TMediaTableDataItem[] =
    media &&
    media.map((mediaItem) => {
      const formattedCreatedDate = moment(mediaItem.createdDate).format(
        DEFAULT_SLASH_DATE_FORMAT,
      );
      const data = createData(
        mediaItem.id,
        mediaItem.name,
        mediaItem.type,
        mediaItem.author,
        mediaItem.url,
        mediaItem.advertisers,
        mediaItem.products,
        mediaItem.categories,
        formattedCreatedDate,
        mediaItem.dimensions,
        mediaItem.siteCount,
      );

      return data;
    });

  return rows;
};

export const createSiteScheduleData = (
  startDateValue: TNullable<Date>,
  endDateValue: TNullable<Date>,
  startTime: Date | null,
  endTime: Date | null,
  days: string[],
  medias?: IMediaForCreateCampaign[],
  duration?: number | undefined,
): ISchedule => {
  const fixTimezoneOffset = (date: TNullable<Date>): string => {
    if (!date) return '';

    return new Date(date.getTime() - date.getTimezoneOffset() * 60000).toJSON();
  };
  const startDate = fixTimezoneOffset(startDateValue);
  const endDate = fixTimezoneOffset(endDateValue);
  const schedule: ISchedule = {
    startDate,
    endDate,
    startTime: moment(startTime).format(DEFAULT_TIME_FORMAT) || '',
    endTime: moment(endTime).format(DEFAULT_TIME_FORMAT) || '',
    dayOfWeek: days.join(', '),
    scheduleMedias: medias || [],
    rangeTimes: [],
    duration: duration || 0,
  };

  return schedule;
};
