import {
  ICampaign,
  ICampaignById,
  IFetchedCampaignType,
  IFiltersList,
} from '../../../../types';
import { IObjectIdWithName } from '../../../../types/general';
import { IAllCampaignPayload } from './type';

export interface ICampaignState {
  campaigns: ICampaign[];
  campaignTypes: IFetchedCampaignType[];
  searchedCampaigns: IObjectIdWithName[];
  currentCampaign: ICampaignById | null;
  isLoadingCurrentCampaign: boolean;
  hasErrorCurrentCampaign: boolean;
  isLoadingSearchedCampaigns: boolean;
  hasErrorSearchedCampaigns: boolean;
  isLoadingCampaigns: boolean;
  isLoadingCampaignTypes: boolean;
  hasErrorCampaignTypesLoading: boolean;
  isSearchCampaignLoading: boolean;
  hasErrorCampaignLoading: boolean;
  hasErrorCampaignsLoading: boolean;
  hasDeleteProjectError: boolean;
  isApproveCampaignLoading: boolean;
  hasApproveCampaignError: boolean;
  isDeclineCampaignLoading: boolean;
  hasDeclineCampaignError: boolean;
  isPostCampaignLoading: boolean;
  hasPostCampaignError: boolean;
  isPostEditCampaignLoading: boolean;
  hasPostEditCampaignError: boolean;
  isStopLoading: boolean;
  stopError: string;
  isStopModalOpen: boolean;
  isViewAllMediaModalOpen: boolean;
  totalItems: number;
  pageNumber: number;
  pageSize: number;
  filters: IFiltersList[];
  hasErrorCampaignsFiltersLoading: boolean;
  isLoadingCampaignFilters: boolean;
  totalFilters: number;
}

export enum CampaignActionTypesEnum {
  GET_CAMPAIGNS_LOADING = 'GET_CAMPAIGNS_LOADING',
  GET_CAMPAIGNS_SUCCESS = 'GET_CAMPAIGNS_SUCCESS',
  GET_CAMPAIGNS_ERROR = 'GET_CAMPAIGNS_ERROR',
  GET_CAMPAIGN_BY_ID_LOADING = 'GET_CAMPAIGN_BY_ID_LOADING',
  GET_CAMPAIGN_BY_ID_SUCCESS = 'GET_CAMPAIGN_BY_ID_SUCCESS',
  GET_CAMPAIGN_BY_ID_ERROR = 'GET_CAMPAIGN_BY_ID_ERROR',
  GET_SEARCHED_CAMPAIGNS_LOADING = 'GET_SEARCHED_CAMPAIGNS_LOADING',
  GET_SEARCHED_CAMPAIGNS_SUCCESS = 'GET_SEARCHED_CAMPAIGNS_SUCCESS',
  GET_SEARCHED_CAMPAIGNS_ERROR = 'GET_SEARCHED_CAMPAIGNS_ERROR',
  GET_CAMPAIGN_TYPES_LOADING = 'GET_CAMPAIGN_TYPES_LOADING',
  GET_CAMPAIGN_TYPES_SUCCESS = 'GET_CAMPAIGN_TYPES_SUCCESS',
  GET_CAMPAIGN_TYPES_ERROR = 'GET_CAMPAIGN_TYPES_ERROR',
  DELETE_CAMPAIGN = 'DELETE_CAMPAIGN',
  DELETE_CAMPAIGNS = 'DELETE_CAMPAIGNS',
  DELETE_CAMPAIGN_ERROR = 'DELETE_CAMPAIGN_ERROR',
  DELETE_CAMPAIGNS_ERROR = 'DELETE_CAMPAIGNS_ERROR',
  PUT_APPROVE_CAMPAIGN_SUCCESS = 'PUT_APPROVE_CAMPAIGN_SUCCESS',
  PUT_APPROVE_CAMPAIGN_LOADING = 'PUT_APPROVE_CAMPAIGN_LOADING',
  PUT_APPROVE_CAMPAIGN_ERROR = 'PUT_APPROVE_CAMPAIGN_ERROR',
  PUT_DECLINE_CAMPAIGN_SUCCESS = 'PUT_DECLINE_CAMPAIGN_SUCCESS',
  PUT_DECLINE_CAMPAIGN_LOADING = 'PUT_DECLINE_CAMPAIGN_LOADING',
  PUT_DECLINE_CAMPAIGN_ERROR = 'PUT_DECLINE_CAMPAIGN_ERROR',
  POST_CAMPAIGNS_LOADING = 'POST_CAMPAIGNS_LOADING',
  POST_CAMPAIGNS_SUCCESS = 'POST_CAMPAIGNS_SUCCESS',
  POST_CAMPAIGNS_ERROR = 'POST_CAMPAIGNS_ERROR',
  POST_DRAFT_CAMPAIGN_LOADING = 'POST_DRAFT_CAMPAIGN_LOADING',
  POST_DRAFT_CAMPAIGN_SUCCESS = 'POST_DRAFT_CAMPAIGN_SUCCESS',
  POST_DRAFT_CAMPAIGN_ERROR = 'POST_DRAFT_CAMPAIGN_ERROR',
  GET_CAMPAIGNS_FILTERS_LOADING = 'GET_CAMPAIGNS_FILTERS_LOADING',
  GET_CAMPAIGNS_FILTERS_SUCCESS = 'GET_CAMPAIGNS_FILTERS_SUCCESS',
  GET_CAMPAIGNS_FILTERS_ERROR = 'GET_CAMPAIGNS_FILTERS_ERROR',
  POST_EDIT_CAMPAIGN_LOADING = 'POST_EDIT_CAMPAIGN_LOADING',
  POST_EDIT_CAMPAIGN_SUCCESS = 'POST_EDIT_CAMPAIGN_SUCCESS',
  POST_EDIT_CAMPAIGN_ERROR = 'POST_EDIT_CAMPAIGN_ERROR',
  POST_DRAFT_EDIT_CAMPAIGN_LOADING = 'POST_DRAFT_EDIT_CAMPAIGN_LOADING',
  POST_DRAFT_EDIT_CAMPAIGN_SUCCESS = 'POST_DRAFT_EDIT_CAMPAIGN_SUCCESS',
  POST_DRAFT_EDIT_CAMPAIGN_ERROR = 'POST_DRAFT_CAMPAIGN_ERROR',
  CLEANUP_CAMPAIGN_STATE = 'CLEANUP_CAMPAIGN_STATE',
  CAMPAIGN_LENGTH = 'CAMPAIGN_LENGTH',
  CAMPAIGN_STOP_SUCCESS = 'CAMPAIGN_STOP_SUCCESS',
  CAMPAIGN_STOP_MODAL_TOGGLE = 'CAMPAIGN_STOP_MODAL_TOGGLE',
  CAMPAIGN_STOP_LOADING = 'CAMPAIGN_STOP_LOADING',
  CAMPAIGN_STOP_ERROR = 'CAMPAIGN_STOP_ERROR',
  CAMPAIGN_VIEW_ALL_MEDIA_MODAL = 'CAMPAIGN_VIEW_ALL_MEDIA_MODAL',
}

interface IGetCampaignLoadingAction {
  type: CampaignActionTypesEnum.GET_CAMPAIGNS_LOADING;
}
interface IGetCampaignSuccessAction {
  type: CampaignActionTypesEnum.GET_CAMPAIGNS_SUCCESS;
  payload: IAllCampaignPayload;
}
interface IGetCampaignErrorAction {
  type: CampaignActionTypesEnum.GET_CAMPAIGNS_ERROR;
  payload: boolean;
}

interface IGetCampaignByIdLoadingAction {
  type: CampaignActionTypesEnum.GET_CAMPAIGN_BY_ID_LOADING;
}
interface IGetCampaignByIdSuccessAction {
  type: CampaignActionTypesEnum.GET_CAMPAIGN_BY_ID_SUCCESS;
  payload: any;
}
interface IGetCampaignByIdErrorAction {
  type: CampaignActionTypesEnum.GET_CAMPAIGN_BY_ID_ERROR;
  payload: boolean;
}

interface IGetSearchedCampaignsLoadingAction {
  type: CampaignActionTypesEnum.GET_SEARCHED_CAMPAIGNS_LOADING;
}
interface IGetSearchedCampaignsSuccessAction {
  type: CampaignActionTypesEnum.GET_SEARCHED_CAMPAIGNS_SUCCESS;
  payload: any;
}
interface IGetSearchedCampaignsErrorAction {
  type: CampaignActionTypesEnum.GET_SEARCHED_CAMPAIGNS_ERROR;
  payload: boolean;
}

interface IGetCampaignTypesLoadingAction {
  type: CampaignActionTypesEnum.GET_CAMPAIGN_TYPES_LOADING;
}
interface IGetCampaignTypesSuccessAction {
  type: CampaignActionTypesEnum.GET_CAMPAIGN_TYPES_SUCCESS;
  payload: any;
}
interface IGetCampaignTypesErrorAction {
  type: CampaignActionTypesEnum.GET_CAMPAIGN_TYPES_ERROR;
  payload: boolean;
}

interface ICampaignDeleteCampaign {
  type: CampaignActionTypesEnum.DELETE_CAMPAIGN;
  payload: number;
}
interface ICampaignDeleteErrorCampaign {
  type: CampaignActionTypesEnum.DELETE_CAMPAIGN_ERROR;
  payload: boolean;
}

interface ICampaignDeleteCampaigns {
  type: CampaignActionTypesEnum.DELETE_CAMPAIGNS;
  payload: number[];
}
interface ICampaignDeleteErrorCampaigns {
  type: CampaignActionTypesEnum.DELETE_CAMPAIGNS_ERROR;
  payload: boolean;
}

interface ICampaignApproveSuccessCampaign {
  type: CampaignActionTypesEnum.PUT_APPROVE_CAMPAIGN_SUCCESS;
}

interface ICampaignApproveLoadingCampaign {
  type: CampaignActionTypesEnum.PUT_APPROVE_CAMPAIGN_LOADING;
}

interface ICampaignApproveErrorCampaign {
  type: CampaignActionTypesEnum.PUT_APPROVE_CAMPAIGN_ERROR;
  payload: boolean;
}

interface ICampaignDeclineSuccessCampaign {
  type: CampaignActionTypesEnum.PUT_DECLINE_CAMPAIGN_SUCCESS;
}

interface ICampaignDeclineLoadingCampaign {
  type: CampaignActionTypesEnum.PUT_DECLINE_CAMPAIGN_LOADING;
}

interface ICampaignDeclineErrorCampaign {
  type: CampaignActionTypesEnum.PUT_DECLINE_CAMPAIGN_ERROR;
  payload: boolean;
}

interface IPostCampaignLoadingCampaigns {
  type: CampaignActionTypesEnum.POST_CAMPAIGNS_LOADING;
}
interface IPostCampaignSuccessCampaigns {
  type: CampaignActionTypesEnum.POST_CAMPAIGNS_SUCCESS;
}
interface IPostCampaignSuccessDeleteErrorCampaigns {
  type: CampaignActionTypesEnum.POST_CAMPAIGNS_ERROR;
  payload: boolean;
}

interface IPostDraftCampaignLoadingCampaigns {
  type: CampaignActionTypesEnum.POST_DRAFT_CAMPAIGN_LOADING;
}
interface IPostDraftCampaignSuccessCampaigns {
  type: CampaignActionTypesEnum.POST_DRAFT_CAMPAIGN_SUCCESS;
}
interface IPostDraftCampaignSuccessDeleteErrorCampaigns {
  type: CampaignActionTypesEnum.POST_DRAFT_CAMPAIGN_ERROR;
  payload: boolean;
}

interface IGetCampaignFiltersLoadingAction {
  type: CampaignActionTypesEnum.GET_CAMPAIGNS_FILTERS_LOADING;
}
interface IGetCampaignFiltersSuccessAction {
  type: CampaignActionTypesEnum.GET_CAMPAIGNS_FILTERS_SUCCESS;
  payload: any;
}
interface IGetCampaignFiltersErrorAction {
  type: CampaignActionTypesEnum.GET_CAMPAIGNS_FILTERS_ERROR;
  payload: boolean;
}

interface IPostEditCampaignLoadingAction {
  type: CampaignActionTypesEnum.POST_EDIT_CAMPAIGN_LOADING;
}
interface IPostEditCampaignSuccessAction {
  type: CampaignActionTypesEnum.POST_EDIT_CAMPAIGN_SUCCESS;
}

interface IPostEditCampaignErrorAction {
  type: CampaignActionTypesEnum.POST_EDIT_CAMPAIGN_ERROR;
  payload: boolean;
}

interface IPostDraftEditCampaignLoadingAction {
  type: CampaignActionTypesEnum.POST_DRAFT_EDIT_CAMPAIGN_LOADING;
}
interface IPostDraftEditCampaignSuccessAction {
  type: CampaignActionTypesEnum.POST_DRAFT_EDIT_CAMPAIGN_SUCCESS;
}

interface IPostDraftEditCampaignErrorAction {
  type: CampaignActionTypesEnum.POST_DRAFT_EDIT_CAMPAIGN_ERROR;
  payload: boolean;
}

interface ICleanUpCampaignState {
  type: CampaignActionTypesEnum.CLEANUP_CAMPAIGN_STATE;
}

interface ICampaignLengthState {
  type: CampaignActionTypesEnum.CAMPAIGN_LENGTH;
  payload: number;
}

interface ICampaignStopLoading {
  type: CampaignActionTypesEnum.CAMPAIGN_STOP_LOADING;
}

interface ICampaignStopSuccess {
  type: CampaignActionTypesEnum.CAMPAIGN_STOP_SUCCESS;
}

interface ICampaignStopError {
  type: CampaignActionTypesEnum.CAMPAIGN_STOP_ERROR;
  payload: string;
}

interface ICampaignStopModalToggle {
  type: CampaignActionTypesEnum.CAMPAIGN_STOP_MODAL_TOGGLE;
  payload: boolean;
}

interface ICampaignViewAllMediaModalToggle {
  type: CampaignActionTypesEnum.CAMPAIGN_VIEW_ALL_MEDIA_MODAL;
  payload: boolean;
}

export type TCampaignAction =
  | IGetCampaignSuccessAction
  | IGetCampaignLoadingAction
  | IGetCampaignErrorAction
  | ICampaignDeleteCampaign
  | ICampaignDeleteErrorCampaign
  | ICampaignDeleteCampaigns
  | ICampaignDeleteErrorCampaigns
  | IPostCampaignLoadingCampaigns
  | IPostCampaignSuccessCampaigns
  | IPostCampaignSuccessDeleteErrorCampaigns
  | IPostDraftCampaignLoadingCampaigns
  | IPostDraftCampaignSuccessCampaigns
  | IPostDraftCampaignSuccessDeleteErrorCampaigns
  | IGetCampaignFiltersLoadingAction
  | IGetCampaignFiltersSuccessAction
  | IGetCampaignFiltersErrorAction
  | IGetCampaignTypesLoadingAction
  | IGetCampaignTypesSuccessAction
  | IGetCampaignTypesErrorAction
  | IGetSearchedCampaignsLoadingAction
  | IGetSearchedCampaignsSuccessAction
  | IGetSearchedCampaignsErrorAction
  | IGetCampaignByIdLoadingAction
  | IGetCampaignByIdSuccessAction
  | IGetCampaignByIdErrorAction
  | ICleanUpCampaignState
  | IPostEditCampaignLoadingAction
  | IPostEditCampaignSuccessAction
  | IPostEditCampaignErrorAction
  | IPostDraftEditCampaignLoadingAction
  | IPostDraftEditCampaignSuccessAction
  | IPostDraftEditCampaignErrorAction
  | ICampaignLengthState
  | ICampaignApproveSuccessCampaign
  | ICampaignApproveLoadingCampaign
  | ICampaignApproveErrorCampaign
  | ICampaignDeclineSuccessCampaign
  | ICampaignDeclineLoadingCampaign
  | ICampaignDeclineErrorCampaign
  | ICampaignStopLoading
  | ICampaignStopSuccess
  | ICampaignStopError
  | ICampaignStopModalToggle
  | ICampaignViewAllMediaModalToggle;
