import * as AdvertiserActionCreators from './advertiserActions/advertiserActions';
import * as AuthActionCreators from './authActions/authActions';
import * as CampaignsActionCreators from './campaignsActions/main/campaignsActions';
import * as ManageCampaignV2 from './campaignsActions/manage';
import * as CategoryActionCreators from './categoryActions/categoryActions';
import * as CentreActions from './centre/centreActions';
import * as MediaActionsCreators from './mediaActions/mediaActions';
import * as NetworkActions from './networks/networkActions';
import * as OrganisationActionCreators from './organisationActions/organisationActions';
import * as ProductsActionCreators from './productActions/productActions';
import * as SideBarActions from './sideBarActions/sideBarActions';
import * as SitesActionCreators from './sitesActions/main/sitesAction';
import * as ManageSiteActions from './sitesActions/manageSite/ManageSiteActions';
import * as ResolutionActions from './sitesActions/resolution/resolutionActions';
import * as StatesActions from './states/stateActions';
import * as UrlAction from './UrlAction/UrlAction';
import * as UsersActions from './users/UsersActions';

export default {
  ...AuthActionCreators,
  ...CampaignsActionCreators,
  ...ProductsActionCreators,
  ...AdvertiserActionCreators,
  ...CategoryActionCreators,
  ...SitesActionCreators,
  ...ManageSiteActions,
  ...ResolutionActions,
  ...OrganisationActionCreators,
  ...MediaActionsCreators,
  ...SideBarActions,
  ...UrlAction,
  ...UsersActions,
  ...NetworkActions,
  ...CentreActions,
  ...StatesActions,
  ...ManageCampaignV2,
};
