import { FieldArrayRenderProps, useFormikContext } from 'formik';
import { ChangeEvent, MouseEvent } from 'react';

import { regulars } from '../../../../../../../../../../constants/regular/regulars';
import { IInitialValues } from '../../../../../../types';

interface IFrameManagerHookProps {
  dayPartIndex: number;
}

interface IFrameManagerHookReturn {
  handleRemoveFrame: (
    event: MouseEvent<HTMLButtonElement>,
    helper: FieldArrayRenderProps,
    frameIndex: number,
  ) => void;
  handleFrameIdChange: (event: ChangeEvent<HTMLInputElement>) => void;
  isRemoveButtonDisabled: boolean;
}

const useFrameManager = ({
  dayPartIndex,
}: IFrameManagerHookProps): IFrameManagerHookReturn => {
  const { values, handleChange } = useFormikContext<IInitialValues>();

  const handleRemoveFrame = (
    event: MouseEvent<HTMLButtonElement>,
    helper: FieldArrayRenderProps,
    frameIndex: number,
  ): void => {
    event.stopPropagation();
    helper.remove(frameIndex);
  };

  const handleFrameIdChange = (event: ChangeEvent<HTMLInputElement>): void => {
    if (!event.target.value || regulars.onlyNumber.test(event.target.value)) {
      handleChange(event);
    }
  };

  const isRemoveButtonDisabled =
    values.siteDayParts[dayPartIndex].siteFrames.length <= 1;

  return { handleRemoveFrame, handleFrameIdChange, isRemoveButtonDisabled };
};

export default useFrameManager;
