import { ManageCampaignModeEnum } from 'constants/enums/campaign';
import { useTypedSelector } from 'hooks';
import React from 'react';

import { CampaignStatusEnum } from '../../../../../../constants/enums/campaign';
import { HeaderButtons as AddPageHeaderButtons } from '../../../../Pages/AddCampaign/components';
import { HeaderButtons as EditPageHeaderButtons } from '../../../../Pages/EditCampaign/components';
import { HeaderButtons as ReviewPageHeaderButtons } from '../../../../Pages/ReviewCampaign/components';

const HeaderButtons: React.FC = () => {
  const { mode, status } = useTypedSelector(
    (state) => state && state.manageCampaign.main,
  );

  const isAddMode = mode === ManageCampaignModeEnum.ADD;
  const isEditMode = mode === ManageCampaignModeEnum.EDIT;
  const isReviewMode = mode === ManageCampaignModeEnum.REVIEW;

  const isStatusDraft = status === CampaignStatusEnum.DRAFT;
  const isStatusArchive = status === CampaignStatusEnum.ARCHIVED;
  const isStatusStopped = status === CampaignStatusEnum.STOPPED;
  const isStatusPendingApproval =
    status === CampaignStatusEnum.PENDING_APPROVAL;

  const isNoButton = isStatusArchive || isStatusStopped;
  const isAddButtons = isAddMode || isStatusDraft;
  const isReviewButtons = isReviewMode && isStatusPendingApproval;

  if (isNoButton) {
    return null;
  }

  if (isAddButtons) {
    return <AddPageHeaderButtons />;
  }

  if (isEditMode) {
    return <EditPageHeaderButtons />;
  }

  if (isReviewButtons) {
    return <ReviewPageHeaderButtons />;
  }

  return null;
};

export default HeaderButtons;
