import './TabContent.scss';

import { ManageCampaignModeEnum } from 'constants/enums/campaign';
import { useActions, useTypedSelector } from 'hooks';
import { helperMessages } from 'pages/Campaign/constants';
import React, { useEffect } from 'react';

import { CampaignTypesEnum } from '../../../../../../../../../../../constants/enums/campaign';
import useInspector from '../../../../../../../hooks/useInspector';
import {
  CampaignName,
  CampaignType,
  HelperMessage,
  OrDivider,
  SelectCampaign,
  Selects,
} from './components';

const TabContent: React.FC = () => {
  const { mode } = useTypedSelector(
    (state) => state && state.manageCampaign.main,
  );
  const { campaignType } = useTypedSelector(
    (state) => state && state.manageCampaign.detail,
  );
  const { isLoadingProducts } = useTypedSelector(
    (state) => state && state.products,
  );
  const { isLoadingCategories } = useTypedSelector(
    (state) => state && state.category,
  );
  const { isLoadingAdvertiser } = useTypedSelector(
    (state) => state && state.advertiser,
  );
  const { isLoadingCampaignTypes, campaignTypes } = useTypedSelector(
    (state) => state && state.campaigns,
  );

  const { isMarketing, isAdvertiser } = useInspector();

  const {
    getProducts,
    getCategories,
    getAdvertisers,
    getCampaignTypes,
    changeCampaignType,
  } = useActions();

  useEffect(() => {
    if (!isLoadingAdvertiser) {
      getAdvertisers();
    }
    if (!isLoadingProducts) {
      getProducts();
    }
    if (!isLoadingCategories) {
      getCategories();
    }
    if (!isLoadingCampaignTypes) {
      getCampaignTypes();
    }
  }, []);

  /**
   * This is necessary because users with Marketing roles can only create marketing-type companies,
   * and users with advertising roles can only create advertising-type campaigns
   */
  useEffect(() => {
    if (campaignTypes.length) {
      if (isAdvertiser) {
        const advertiserCampaignType = campaignTypes.find(
          (type) => type.name === CampaignTypesEnum.ADVERTISING,
        );
        changeCampaignType(advertiserCampaignType);

        return;
      }

      if (isMarketing) {
        const marketingCampaignType = campaignTypes.find(
          (type) => type.name === CampaignTypesEnum.MARKETING,
        );
        changeCampaignType(marketingCampaignType);
      }
    }
  }, [campaignTypes.length]);

  const isNewCampaignPage = mode === ManageCampaignModeEnum.ADD;

  const isCampaignTypeView = !(isAdvertiser || isMarketing);

  return (
    <div className="manage-campaign-content detail-content">
      <div className="tab-content-wrapper detail-tab-content-wrapper">
        <div className="tab-content-wrapper-content">
          {isNewCampaignPage && (
            <HelperMessage message={helperMessages.campaignName} />
          )}
          <div className="campaign-form">
            <div className="campaign-form-wrapper">
              <CampaignName />
              {isNewCampaignPage && <OrDivider />}
              {isNewCampaignPage && <SelectCampaign />}
            </div>
            {isCampaignTypeView && <CampaignType />}
            {campaignType && <Selects />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TabContent;
