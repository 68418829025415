export const regulars = {
  passwordReg:
    /^(?=.*[A-Za-z])(?=.*\d)(?=.*[\x21-\x2F\x3A-\x40\x5B-\x60\x7B-\x7E])[A-Za-z\d\x21-\x2F\x3A-\x40\x5B-\x60\x7B-\x7E]{8,}$/,
  campaignNameReg: /[^A-Za-z0-9\-/& ]/,
  onlyNumber: /^\d+$/,
  oneOfUpperCaseWord: /(?=.*[A-Z])/,
  oneOfLowerCaseWord: /(?=.*[a-z])/,
  oneOfDigit: /(?=.*\d)/,
  oneOfNonWordCharacter: /(?=.*\W)/,
  forbiddenSymbol: /^(?=.*[!@#$%^&(),.+=/\][{}?><":;|])/,
  date: /^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[1,3-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/,
  email: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
};
