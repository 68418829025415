import { Menu, MenuItem } from '@mui/material';
import { withStyles } from 'tss-react/mui';

import { colors } from '../../../constants/colors/colors';

const StyledMenu = withStyles(Menu, () => ({
  paper: {
    border: `1px solid ${colors.Iron}`,
    color: colors.Tundora,
    width: '250px',
  },
}));

const StyledMenuItem = withStyles(MenuItem, (theme) => ({
  root: {
    '&:focus': {
      backgroundColor: colors.White,
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme.palette.common.white,
      },
    },
  },
}));

export { StyledMenu, StyledMenuItem };
