import { Dialog, DialogActions, DialogTitle } from '@mui/material';
import React from 'react';
import { makeStyles } from 'tss-react/mui';

import {
  campaignArchiveDialog,
  campaignDeleteDialog,
} from '../../../../constants/text/text';
import { TItem } from '../../../../types';
import Button from '../../Button';

interface IDialogModal<Item> {
  message: string;
  isOpen: boolean;
  handleClose: () => void;
  selectedItems?: Item[];
  handleConfirmAction?: (ids?: number[]) => void;
}

const AlertDialog: React.FC<IDialogModal<TItem>> = ({
  message,
  isOpen,
  handleClose,
  handleConfirmAction,
  selectedItems,
}) => {
  const useStyles = makeStyles()(() => ({
    root: {
      padding: '20px',
    },
    header: {
      marginBottom: '20px',
    },
  }));

  const isArchiving = message.includes(campaignArchiveDialog.confirmButton);
  const { classes } = useStyles();

  const handleConfirm = () => {
    if (handleConfirmAction) {
      if (selectedItems?.length) {
        const ids = selectedItems.map((item) => item.id.value);
        handleConfirmAction(ids);
        handleClose();

        return;
      }
      handleConfirmAction();
    }
    handleClose();
  };

  return (
    <div>
      <Dialog
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div className={classes.root}>
          <DialogTitle className={classes.header} id="alert-dialog-title">
            {message}
          </DialogTitle>
          <DialogActions>
            <Button
              onClick={handleClose}
              label={campaignArchiveDialog.cancelButton}
              color="secondary"
            />
            <Button
              onClick={handleConfirm}
              label={
                isArchiving
                  ? campaignArchiveDialog.confirmButton
                  : campaignDeleteDialog.confirmButton
              }
              className="dangerous-button"
            />
          </DialogActions>
        </div>
      </Dialog>
    </div>
  );
};

export default AlertDialog;
