import './App.scss';
import 'moment/locale/en-gb';

import React, {
  ReactElement,
  useEffect,
  useLayoutEffect,
  useMemo,
} from 'react';
import { useDispatch } from 'react-redux';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { useEffectOnce, useMediaQuery } from 'usehooks-ts';

import SideNavBar from './components/SideNavBar/SideNavBar';
import { urls } from './constants/urls/url';
import { useActions } from './hooks';
import { useTypedSelector } from './hooks/useTypedSelector';
import { AppActionTypesEnum } from './redux/ActionTypes';
import { generateRoutesMap, routesLayout } from './routes/layout';

function App(): ReactElement {
  const { checkIsLogged } = useActions();

  const { role } = useTypedSelector((state) => state && state.auth);
  const location = useLocation();
  const dispatch = useDispatch();

  const isSmall = useMediaQuery('(max-width: 1100px)');

  useLayoutEffect(() => {
    if (isSmall) {
      dispatch({
        type: AppActionTypesEnum.SWITCH_WINDOW_PARAM,
        payload: 'isSmall',
      });

      return;
    }

    dispatch({
      type: AppActionTypesEnum.SWITCH_WINDOW_PARAM,
      payload: 'isMedium',
    });
  }, [isSmall]);

  useEffect(() => {
    if (location.key !== 'default') {
      checkIsLogged();
    }
  }, [location.pathname]);

  useEffectOnce(() => {
    checkIsLogged();
  });

  const routeMap = useMemo(() => generateRoutesMap(routesLayout, role), [role]);

  return (
    <div className="App">
      <SideNavBar />
      <Routes>
        <Route path={urls.root}>
          <Route index element={<Navigate to={urls.login} replace />} />
          {routeMap}
        </Route>
        <Route path="*" element={<div>not found</div>} />
      </Routes>
    </div>
  );
}

export default App;
