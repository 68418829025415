import {
  IOrganisationState,
  OrganisationActionTypesEnum,
  TOrganisationAction,
} from '../../ActionTypes/organisationTypes/organisationTypes';

const initialState: IOrganisationState = {
  organisation: {
    id: 0,
    name: '',
    logo: '',
    logoUrl: '',
  },
  loading: {},
  subOrganisations: [],
  isLoadingOrganisation: false,
  errorOrganisationLoading: false,
};

const organisationReducer = (
  state = initialState,
  action: TOrganisationAction,
): IOrganisationState => {
  switch (action.type) {
    case OrganisationActionTypesEnum.GET_ORGANISATION_LOADING:
      return {
        ...state,
        isLoadingOrganisation: true,
      };
    case OrganisationActionTypesEnum.GET_ORGANISATION_SUCCESS:
      return {
        ...state,
        organisation: action.payload,
      };
    case OrganisationActionTypesEnum.GET_ORGANISATION_ERROR:
      return {
        ...state,
        isLoadingOrganisation: false,
        errorOrganisationLoading: true,
      };
    case OrganisationActionTypesEnum.SET_SUBORGANISATIONS:
      return {
        ...state,
        subOrganisations: action.payload,
      };

    case OrganisationActionTypesEnum.CLEANUP_CURRENT:
      return {
        ...state,
        organisation: initialState.organisation,
      };
    default:
      return state;
  }
};

export default organisationReducer;
