import { Dispatch } from 'redux';

import { IObjectIdWithName } from '../../../types/general';
import { StatesActionEnum, TStatesActions } from '../../ActionTypes';
import { getStatesApi } from './statesApi';

export const getStates =
  () =>
  async (dispatch: Dispatch<TStatesActions>): Promise<void> => {
    try {
      dispatch({
        type: StatesActionEnum.SET_LOADING,
        payload: { type: 'getAll', value: true },
      });
      const {
        data: { data },
      } = await getStatesApi<{ data: IObjectIdWithName[] }>();
      dispatch({ type: StatesActionEnum.SET_STATES, payload: data });
    } catch (error) {
      console.error(error);
    } finally {
      dispatch({
        type: StatesActionEnum.SET_LOADING,
        payload: { type: 'getAll', value: false },
      });
    }
  };
