import { ManageCampaignAddMediaTabsEnum } from 'constants/enums';
import { useTypedSelector } from 'hooks';
import React from 'react';

import { ImageList, LoadersFileList, MediaLoading } from '../..';
import {
  SCanvas,
  STabContent,
  STabPanel,
  STypography,
  STypographyWrapper,
} from '../Tabs.styled';

const RecentlyTab: React.FC = () => {
  const { addMediaModal, loaders } = useTypedSelector(
    (state) => state && state.manageCampaign.manage,
  );

  const { mediaStorage } = addMediaModal;

  return (
    <STabPanel value={ManageCampaignAddMediaTabsEnum.RECENTLY}>
      <SCanvas>
        <STabContent>
          {Boolean(mediaStorage.loaderStore.length) && <LoadersFileList />}

          <STypographyWrapper>
            <STypography variant="h4">Recently added media</STypography>
          </STypographyWrapper>
          <div>
            {loaders.getRecentlyMedia ? (
              <MediaLoading />
            ) : (
              <ImageList
                medias={addMediaModal.mediaStorage.recentlyTab}
                tab={ManageCampaignAddMediaTabsEnum.RECENTLY}
              />
            )}
          </div>
        </STabContent>
      </SCanvas>
    </STabPanel>
  );
};

export default RecentlyTab;
