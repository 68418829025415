import './DayParts.scss';

import { HighlightOff as HighlightOffIcon } from '@mui/icons-material';
import { FormControlLabel, Radio } from '@mui/material';
import { TimePicker } from 'antd';
import { Checkbox } from 'components/common';
import { momentFormats } from 'constants/date/date';
import { radix } from 'constants/variables';
import { convertFromMillisecondsToHumanFormat } from 'helpers';
import { useActions, useTypedSelector } from 'hooks';
import React, { ChangeEvent } from 'react';
import { TAntRangeValue, TDaysOfWeekWithAll } from 'types';

import { SRadioGroup } from './DayParts.styled';

const { RangePicker } = TimePicker;

const DayParts: React.FC = () => {
  const { scheduleCampaignModalData } = useTypedSelector(
    (state) => state && state.manageCampaign.manage,
  );
  const {
    setTimesOnScheduleModal,
    setDaysOfWeekScheduleModal,
    setDayPartScheduleModal,
    removeDayPart,
  } = useActions();

  const currentCampaignSite = scheduleCampaignModalData.campaignSite;
  const isMoreThanOneDayPart =
    currentCampaignSite && currentCampaignSite.siteDayParts.length > 1;
  const isRemoveDisabled = scheduleCampaignModalData.dayParts.length < 2;

  const handleChangeDaysOfWeek = (
    event: ChangeEvent<HTMLInputElement>,
    index: number,
  ) => {
    setDaysOfWeekScheduleModal({
      index,
      day: event.target.name as TDaysOfWeekWithAll,
      checked: event.target.checked,
    });
  };
  const handleCalendarChange = (times: TAntRangeValue, index: number) => {
    setTimesOnScheduleModal({ times, index });
  };

  const handleDayPartChange = (value: string, index: number) => {
    setDayPartScheduleModal({ index, duration: parseInt(value, radix[10]) });
  };

  const getDayFrameLabel = (ms: number) =>
    `Day Frame (${convertFromMillisecondsToHumanFormat(ms)})`;

  return (
    <div className="day-parts-wrapper">
      {scheduleCampaignModalData.dayParts.map((dayPartSchedule, index) => (
        <div key={index} className="day-part-item">
          <div className="day-part-item-content">
            {isMoreThanOneDayPart && (
              <SRadioGroup
                defaultValue={currentCampaignSite.siteDayParts[0].adLength}
                onChange={(_, value) => handleDayPartChange(value, index)}
              >
                {currentCampaignSite?.siteDayParts.map((dayPart) => {
                  return (
                    <FormControlLabel
                      key={dayPart.adLength}
                      value={dayPart.adLength}
                      control={<Radio />}
                      label={getDayFrameLabel(dayPart.adLength)}
                    />
                  );
                })}
              </SRadioGroup>
            )}
            <RangePicker
              value={dayPartSchedule.times}
              onChange={(values) => handleCalendarChange(values, index)}
              format={momentFormats.time().hoursWithMinutes.use12HoursUpper}
              minuteStep={5}
              use12Hours
            />
            <div className="day-parts-days-of-week">
              <div className="day-parts-days-of-week-every-day">
                <Checkbox
                  label="Every day"
                  name="All"
                  checked={Object.values(dayPartSchedule.days).every(
                    (value) => !!value,
                  )}
                  onChange={(event) => handleChangeDaysOfWeek(event, index)}
                />
              </div>
              <div className="day-parts-days-of-week-separated-days">
                {Object.entries(dayPartSchedule.days).map(([key, value]) => (
                  <Checkbox
                    key={key}
                    name={key}
                    label={key}
                    checked={value}
                    onChange={(event) => handleChangeDaysOfWeek(event, index)}
                  />
                ))}
              </div>
            </div>
          </div>
          <button
            className="remove-day-part"
            disabled={isRemoveDisabled}
            onClick={() => removeDayPart(index)}
          >
            <HighlightOffIcon />
          </button>
        </div>
      ))}
    </div>
  );
};

export default DayParts;
