import { CAMPAIGN_MARKETING_TYPE } from 'constants/constants';
import { NothingEnum } from 'constants/enums/nothing';
import { parseISO } from 'date-fns';
import {
  ICampaignSitesDto,
  ICampaignSitesNew,
  IFetchedCampaignType,
  ISites,
  ITypeCampaign,
} from 'types';
import { v4 as uuidv4 } from 'uuid';

export const checkCampaignType = (
  types: IFetchedCampaignType[],
  type: ITypeCampaign,
): boolean => {
  const currentCampTypeName = types.find((item) => item.id === type?.campType);
  const isAdvertisingType = Boolean(
    currentCampTypeName?.name &&
      !CAMPAIGN_MARKETING_TYPE.includes(currentCampTypeName?.name),
  );

  return isAdvertisingType;
};

export const createCampaignSiteScheme = (
  sites: ISites[],
): ICampaignSitesNew[] => {
  return sites.map((site) => {
    const uuid = uuidv4();

    return {
      centre: site.centre || NothingEnum.NO_CENTRE,
      state: site.state || NothingEnum.NO_STATE,
      formats: site.formats || NothingEnum.NO_FORMATS,
      id: uuid,
      siteId: site.id,
      screenSize: site.screenSize || NothingEnum.NO_SCREEN_SIZE,
      siteDayParts: site.siteDayParts,
      siteName: site.name || NothingEnum.NO_SITE_NAME,
      campaignSchedules: [],
    };
  });
};

export const convertIsoToDate = (
  campaignSites: ICampaignSitesDto[],
): ICampaignSitesNew[] => {
  return campaignSites.map((campaignSite) => {
    return {
      ...campaignSite,
      campaignSchedules: campaignSite.campaignSchedules.map(
        (campaignSchedule) => {
          return {
            ...campaignSchedule,
            startDate: parseISO(campaignSchedule.startDate),
            endDate: parseISO(campaignSchedule.endDate),
          };
        },
      ),
    };
  });
};
