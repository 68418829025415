import '../Table.scss';

import {
  TableHead as MaterialTableHead,
  TableRow,
  TableSortLabel,
} from '@mui/material';
import React, { ReactElement, useMemo } from 'react';

import { SortingTypeEnum } from '../../../../constants/constants';
import {
  ISelectedTableItem,
  ISortingValue,
  ITableMenuProps,
} from '../../../../types';
import Checkbox from '../../Checkbox';
import { STableCell } from './TableHead.styled';

interface ITableHeadProps<Item> {
  onSelectAllClick: (vent: React.ChangeEvent<HTMLInputElement>) => void;
  rowCount: number;
  items: Item[];
  tableColumnsFilter: string[];
  sortingValue: ISortingValue;
  setSortingValue: React.Dispatch<React.SetStateAction<ISortingValue>>;
  tableMenuItem?: ITableMenuProps;
  isDisabledSorting?: boolean;
  selected: ISelectedTableItem[];
}

interface IHeaderItem {
  id: {
    name: string;
    value: number;
    isUnsorting?: boolean;
  };
}

const TableHead = <Item extends IHeaderItem>({
  onSelectAllClick,
  rowCount,
  items,
  tableColumnsFilter,
  sortingValue,
  setSortingValue,
  tableMenuItem,
  isDisabledSorting,
  selected,
}: ITableHeadProps<Item>): ReactElement => {
  const [firstItem] = items;
  const headers = firstItem && Object.entries(firstItem);

  const itemIds = useMemo<number[]>(
    () => items.map((item) => item.id.value),
    [items.length],
  );
  const selectedItemsIds = useMemo<number[]>(
    () => selected.map((item) => item.id),
    [selected.length],
  );

  const isSelectAllCheckboxChecked = useMemo<boolean>(
    () => itemIds.every((item) => selectedItemsIds.includes(item)),
    [itemIds.length, selectedItemsIds.length],
  );

  const isIndeterminateCheckBox = Boolean(
    rowCount && selected.length && !isSelectAllCheckboxChecked,
  );

  const handleClick = (key: string) => {
    const { asc, desc } = SortingTypeEnum;
    if (sortingValue.SortColumn === key) {
      switch (sortingValue.SortDirection) {
        case asc:
          setSortingValue({
            ...sortingValue,
            SortDirection: desc,
            SortColumn: key,
          });

          return;
        case desc:
          setSortingValue({
            ...sortingValue,
            SortDirection: undefined,
            SortColumn: undefined,
          });

          return;
        default:
          setSortingValue({
            ...sortingValue,
            SortDirection: asc,
            SortColumn: key,
          });

          return;
      }
    }
    setSortingValue({ ...sortingValue, SortColumn: key, SortDirection: asc });
  };

  return (
    <MaterialTableHead>
      <TableRow>
        <STableCell
          padding="checkbox"
          className="without-border header-table-cell"
        >
          <Checkbox
            isChecked={isSelectAllCheckboxChecked}
            indeterminate={isIndeterminateCheckBox}
            onChange={onSelectAllClick}
          />
        </STableCell>
        {headers?.length &&
          headers.map((headCell) => {
            const [key, headerItem] = headCell;
            const isExistColumn = tableColumnsFilter.includes(headerItem.name);
            const isSortActive = Boolean(
              key === sortingValue.SortColumn && sortingValue.SortDirection,
            );
            const isDisabledSortingValue =
              isDisabledSorting || headerItem?.isUnsorting;
            if (isExistColumn) {
              return (
                <STableCell className="table-cell header-table-cell" key={key}>
                  <TableSortLabel
                    active={isSortActive}
                    direction={sortingValue.SortDirection}
                    onClick={() => handleClick(key)}
                    disabled={isDisabledSortingValue}
                  >
                    {headerItem.name}
                  </TableSortLabel>
                </STableCell>
              );
            }

            return null;
          })}
        {tableMenuItem && (
          <STableCell className="without-border header-table-cell">
            <div className="void-table-header-table-cell" />
          </STableCell>
        )}
      </TableRow>
    </MaterialTableHead>
  );
};

export default TableHead;
