import { CancelOutlined as CancelOutlinedIcon } from '@mui/icons-material';
import { ApproveIcon } from 'assets/icons';
import { Button } from 'components/common';
import SvgIcon from 'components/common/SvgIcon/SvgIcon';
import { urls } from 'constants/urls/url';
import { useActions, useError, useTypedSelector } from 'hooks';
import { useSnackbar } from 'notistack';
import { texts } from 'pages/Campaign/constants';
import React, { memo } from 'react';
import { useNavigate } from 'react-router-dom';

import { AlertStatusesEnum } from '../../../../../../constants/constants';

const HeaderButtons: React.FC = () => {
  const { campaignId } = useTypedSelector(
    (state) => state && state.manageCampaign.main,
  );
  const { setModalManageCampaignRoot, approveCampaign } = useActions();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { outputError } = useError();

  const handleOpenDeclineModal = () => {
    setModalManageCampaignRoot({ type: 'reject', value: true });
  };

  const handleApprove = async () => {
    try {
      if (campaignId) {
        await approveCampaign(campaignId);
        enqueueSnackbar('Campaign approved', {
          variant: AlertStatusesEnum.SUCCESS,
        });
        navigate(urls.campaign);
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      outputError(error?.message);
    }
  };

  return (
    <div className="__children-many-element-default-container">
      <Button
        label={texts.buttons.decline}
        icon={<CancelOutlinedIcon />}
        className="dangerous-button"
        onClick={handleOpenDeclineModal}
      />
      <Button
        label={texts.buttons.approve}
        icon={
          <SvgIcon className="icon-l">
            <ApproveIcon className="whiteSVG" />
          </SvgIcon>
        }
        className="successfully"
        onClick={handleApprove}
      />
    </div>
  );
};

export default memo(HeaderButtons);
