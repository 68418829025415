import './DatePicker.scss';

import React, { useEffect, useState } from 'react';
import { DayPicker } from 'react-day-picker';

import { MultiplePicker, RangePicker, SinglePicker } from './components';
import { DatePickerModeEnum } from './enum';
import { TDatePicker, TMultipleDate, TRangeDate, TSingleDate } from './types';

const DatePicker: React.FC<TDatePicker> = ({
  mode,
  defaultDate,
  onSelect,
  relatedComponent,
  separator = '/',
  relatedComponentDisabled = false,
  anchorClassName,
  ...props
}) => {
  const [selected, setSelected] =
    useState<TDatePicker['defaultDate']>(defaultDate);

  useEffect(() => {
    setSelected(defaultDate);
  }, [defaultDate]);

  if (mode === DatePickerModeEnum.SINGLE) {
    return (
      <SinglePicker
        relatedComponent={relatedComponent}
        defaultDate={selected as TSingleDate}
        onSelect={onSelect}
        separator={separator}
        relatedComponentDisabled={relatedComponentDisabled}
        anchorClassName={anchorClassName}
        {...props}
      />
    );
  }

  if (mode === DatePickerModeEnum.RANGE) {
    return (
      <RangePicker
        defaultDate={selected as TRangeDate}
        onSelect={onSelect}
        separator={separator}
        relatedComponent={relatedComponent}
        relatedComponentDisabled={relatedComponentDisabled}
        anchorClassName={anchorClassName}
        {...props}
      />
    );
  }

  if (mode === DatePickerModeEnum.MULTIPLE) {
    return (
      <MultiplePicker
        defaultDate={selected as TMultipleDate}
        onSelect={onSelect}
      />
    );
  }

  return <DayPicker selected={defaultDate} />;
};

export default DatePicker;
