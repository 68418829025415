import './CampaignHeader.scss';

import React from 'react';

interface ICampaignHeaderProps {
  title: string;
  children?: JSX.Element; // should be only single element
}

const CampaignHeader: React.FC<ICampaignHeaderProps> = ({
  title,
  children,
}) => {
  return (
    <div className="campaign-header">
      <div className="first-header">{title}</div>
      {children}
    </div>
  );
};

export default CampaignHeader;
