import {
  AddOutlined as AddOutlinedIcon,
  Delete as DeleteIcon,
} from '@mui/icons-material';
import { TActions } from 'components/common/Table/React-Table/types';
import { useActions } from 'hooks';
import { useTypedSelector } from 'hooks/useTypedSelector';
import React from 'react';

import { IGetActionsProps } from '../types';

interface IUsersContentTableActionsHookReturn {
  readonly actions: TActions[];
}

const getActions = ({ addUser, bulkDelete }: IGetActionsProps): TActions[] => {
  return [
    {
      id: 1,
      icon: <DeleteIcon />,
      label: 'Bulk Delete',
      handleClick: bulkDelete.handleClick,
      disabled: bulkDelete.disabled,
    },
    {
      id: 2,
      icon: <AddOutlinedIcon />,
      label: 'Add new user',
      handleClick: addUser.handleClick,
    },
    {
      id: 3,
      type: 'ordering',
    },
  ];
};

const useUsersContentTableActions = (): IUsersContentTableActionsHookReturn => {
  const { allUsersTable } = useTypedSelector((state) => state && state.users);
  const { usersModalToggler, bulkDeleteModalSetUsers } = useActions();

  const actions = getActions({
    addUser: {
      handleClick: () => usersModalToggler({ type: 'manageUser', value: true }),
    },
    bulkDelete: {
      handleClick: () => {
        usersModalToggler({ type: 'deleteBulk', value: true });
        bulkDeleteModalSetUsers(allUsersTable.selectedUsers);
      },
      disabled: !allUsersTable.selectedUsers.length,
    },
  });

  return { actions };
};

export default useUsersContentTableActions;
