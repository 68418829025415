export const size = {
  mediaSelectionWidth: '60%',
};

export const radix = {
  2: 2,
  8: 8,
  10: 10,
  16: 16,
  32: 32,
};
