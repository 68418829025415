import { Typography } from '@mui/material';
import { momentFormats } from 'constants/date/date';
import { getFileName } from 'helpers';
import { getSitesByMediaId } from 'helpers/Campaign';
import { useTypedSelector } from 'hooks';
import moment from 'moment';
import React from 'react';
import { IMedia } from 'types';

interface IFooter {
  media: IMedia;
}

const Footer: React.FC<IFooter> = ({ media }) => {
  const { campaignSites } = useTypedSelector(
    (state) => state && state.manageCampaign.manage,
  );
  const { format } = getFileName(media.name);
  const mediaAdditionallyInfo = `${format.toUpperCase()} • ${media.dimensions}`;

  const createdInfo = moment(media.createdDate).format(
    momentFormats.date(' ').textMonth.full,
  );

  const createData = `Added ${createdInfo} by ${media.author}`;

  const schedulesSites = getSitesByMediaId(campaignSites, media.id);

  return (
    <div className="all-media-footer">
      <div className="footer-info-block base-media-info">
        <div>{media.name}</div>
        <div>{mediaAdditionallyInfo}</div>
        <div>{createData}</div>
      </div>
      <div className="footer-info-block related-sites-list-wrapper">
        <Typography>Sites using</Typography>
        <div className="related-sites-list">
          {schedulesSites.map((scheduleSite) => (
            <div key={scheduleSite.id}>{scheduleSite.siteName}</div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Footer;
