import { TableCell } from '@mui/material';
import { withStyles } from 'tss-react/mui';

const STableCell = withStyles(TableCell, {
  root: {
    fontWeight: 600,
    fontSize: 10,
  },
});

export { STableCell };
