import { useOnClickOutsideExpand } from 'hooks';
import { RefObject, useEffect, useRef, useState } from 'react';
import {
  MonthChangeEventHandler,
  SelectSingleEventHandler,
} from 'react-day-picker';

import { TSingleDate } from '../../types';
import { ISingleProps } from './SinglePicker';

interface ISinglePickerHookProps {
  defaultDate: ISingleProps['defaultDate'];
  onSelect: ISingleProps['onSelect'];
}

interface ISinglePickerHookReturn {
  isOpen: boolean;
  monthView: TSingleDate;
  selected: TSingleDate;
  anchorRef: RefObject<HTMLDivElement>;
  calendarRef: RefObject<HTMLDivElement>;
  handleSelect: SelectSingleEventHandler;
  toggleOpen: () => void;
  handleChangeDate: (date: TSingleDate) => void;
  changeMonthView: MonthChangeEventHandler;
}

const useSinglePicker = ({
  defaultDate,
  onSelect,
}: ISinglePickerHookProps): ISinglePickerHookReturn => {
  const [selected, setSelected] = useState<TSingleDate>(defaultDate);
  const [monthView, setMonthView] = useState<TSingleDate>(defaultDate);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  useEffect(() => {
    setSelected(defaultDate);
  }, [defaultDate]);

  useEffect(() => {
    if (onSelect) {
      onSelect(selected);
    }
  }, [selected]);

  const handleSelect: SelectSingleEventHandler = (date: TSingleDate): void => {
    setSelected(date);
  };

  const anchorRef = useRef<HTMLDivElement>(null);
  const calendarRef = useRef<HTMLDivElement>(null);

  useOnClickOutsideExpand([anchorRef, calendarRef], () => setIsOpen(false));

  const toggleOpen = (): void => {
    setIsOpen((prev) => !prev);
  };

  const handleChangeDate = (date: TSingleDate): void => {
    setSelected(date);
    setMonthView(date);
  };

  const changeMonthView: MonthChangeEventHandler = (date: Date): void => {
    setMonthView(date);
  };

  return {
    isOpen,
    monthView,
    selected,
    anchorRef,
    calendarRef,
    handleSelect,
    toggleOpen,
    handleChangeDate,
    changeMonthView,
  };
};

export default useSinglePicker;
