import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';

import ActionCreators from '../redux/Actions';

type TUseActions = typeof ActionCreators;

export const useActions = (): TUseActions => {
  const dispatch = useDispatch();

  return bindActionCreators(ActionCreators, dispatch);
};
