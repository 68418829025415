import './AddSiteTableManageCampaign.scss';

import { AdjustmentsIcon } from 'assets/icons';
import classnames from 'classnames';
import IconButton from 'components/common/Button/components/IconButton';
import Table from 'components/common/Table';
import TableFilter from 'components/common/Table/TableFilter';
import {
  INITIAL_SITES_FILTER_VALUE,
  INITIAL_SITES_TABLE_COLUMNS_EXCEPTIONS,
  INITIAL_SITES_TABLE_COLUMNS_VALUE,
  INITIAL_SORTING_VALUE,
} from 'constants/constants';
import {
  AddSitesModalText,
  SitesFilterText,
  SitesTableText,
} from 'constants/text/text';
import { createSitesData } from 'helpers/constructors';
import { useActions, useTypedSelector } from 'hooks';
import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { ISelectedTableItem, ISitesFilter, ISortingValue } from 'types';
import { useDebounce } from 'usehooks-ts';

interface IAddSiteTableManageCampaign {
  selected: ISelectedTableItem[];
  setSelected: Dispatch<SetStateAction<ISelectedTableItem[]>>;
}

const AddSiteTableManageCampaign: React.FC<IAddSiteTableManageCampaign> = ({
  selected,
  setSelected,
}) => {
  const { siteFilters } = useTypedSelector(
    (state) => state && state.manageCampaign.manage,
  );

  const { loadings, totalItems, sites } = useTypedSelector(
    (state) => state && state.sites,
  );

  const [isFilterOpen, setIsFilterOpen] = useState<boolean>(true);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [pageNumber, setPageNumber] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(25);

  const [filtersValue, setFiltersValue] = useState<ISitesFilter>(
    INITIAL_SITES_FILTER_VALUE,
  );
  const [tableColumnsFilter, setTableColumnsFilter] = useState<string[]>(
    INITIAL_SITES_TABLE_COLUMNS_VALUE,
  );
  const [sortingValue, setSortingValue] = useState<ISortingValue>(
    INITIAL_SORTING_VALUE,
  );

  const tableItems = createSitesData(sites);
  const debouncedSearchValue = useDebounce(searchQuery, 300);
  const { getSites } = useActions();

  const params = useMemo(() => {
    const filtersParams = {
      'Filters.StatesIds': filtersValue.State,
      'Filters.CentresIds': filtersValue.Centre,
      'Filters.ResolutionsIds': filtersValue.Dimensions,
      'Filters.OrientationsIds': filtersValue['Site type'],
      'Filters.Formats': filtersValue.Formats,
      'Filters.NetworksIds': filtersValue.Network,
    };

    const sortingParams = {
      SortDirection: sortingValue?.SortDirection?.toUpperCase(),
      SortColumn: sortingValue?.SortColumn,
    };

    return {
      pageNumber,
      pageSize,
      searchQuery: debouncedSearchValue,
      ...sortingParams,
      ...filtersParams,
    };
  }, [pageNumber, pageSize, debouncedSearchValue, filtersValue, sortingValue]);

  useEffect(() => {
    getSites(params);
  }, [params]);

  const resetPagination = () => {
    setPageNumber(0);
  };

  const isNoItems = !sites.length || loadings.getAll;

  return (
    <div className="add-site-table-wrapper">
      <div
        className={classnames(`filter-campaign-container`, {
          'opened-campaign-filter-container': isFilterOpen,
        })}
      >
        {isFilterOpen ? (
          <TableFilter
            setIsFilterOpen={setIsFilterOpen}
            setSearchQuery={setSearchQuery}
            searchQuery={searchQuery}
            filtersList={siteFilters}
            filtersValue={filtersValue}
            setFiltersValue={setFiltersValue}
            tableColumnsFilter={tableColumnsFilter}
            setTableColumnsFilter={setTableColumnsFilter}
            resetPagination={resetPagination}
            initialFilterValue={INITIAL_SITES_FILTER_VALUE}
            initialTableColumnsValue={INITIAL_SITES_TABLE_COLUMNS_VALUE}
            exceptions={INITIAL_SITES_TABLE_COLUMNS_EXCEPTIONS}
            filtersText={SitesFilterText}
            setPageNumber={setPageNumber}
          />
        ) : (
          <div style={{ paddingTop: '10px' }}>
            <IconButton
              onClick={() => setIsFilterOpen(true)}
              icon={<AdjustmentsIcon />}
              className="icon-l"
            />
          </div>
        )}
      </div>
      <div className="content-wrapper">
        <div className={classnames('second-header', 'add-sites-header')}>
          {AddSitesModalText.header}
        </div>
        <div
          className={classnames(`content-container`, {
            'no-scroll': isNoItems,
          })}
        >
          <div className="table-wrapper">
            <Table
              items={tableItems}
              total={totalItems}
              pageNumber={pageNumber}
              pageSize={pageSize}
              setPageSize={setPageSize}
              setPageNumber={setPageNumber}
              tableColumnsFilter={tableColumnsFilter}
              sortingValue={sortingValue}
              setSortingValue={setSortingValue}
              isLoadingItems={loadings.getAll}
              tableText={SitesTableText}
              selected={selected}
              setSelected={setSelected}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddSiteTableManageCampaign;
