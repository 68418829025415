import { TableCell, TableRow } from '@mui/material';
import { withStyles } from 'tss-react/mui';

import { colors } from '../../../../constants/colors/colors';
import Button from '../../Button';

const STableRow = withStyles(TableRow, {
  root: {
    '&.Mui-selected, &.Mui-selected:hover': {
      color: colors.Cerulean,

      backgroundColor: colors.LilyWhite,
    },
    cursor: 'pointer',
  },
});

const STableCell = withStyles(TableCell, {
  root: {
    '&.bold-cell': {
      fontWeight: 600,
    },

    '.active-icon-button': {
      color: colors.White,

      backgroundColor: colors.Cerulean,
    },
  },
  sizeSmall: {
    padding: '3px 24px 3px 16px',
  },
});

const STableCellButton = withStyles(Button, {
  root: {
    padding: 0,
    fontSize: 12,
    textOverflow: 'clip',
    textTransform: 'unset',
    letterSpacing: 'unset',
    whiteSpace: 'nowrap',
    color: colors.Cerulean,

    '&.Mui-disabled': {
      color: colors.Silver,
    },
  },
});

export { STableCell, STableCellButton, STableRow };
