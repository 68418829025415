import produce from 'immer';

import {
  CategoryActionTypesEnum,
  IChooseCategoryState,
  TCategoryAction,
} from '../../ActionTypes/categoriesTypes/categoryTypes';

const initialState: IChooseCategoryState = {
  chooseCategory: [],
  errorChooseCategoriesLoading: false,
  loadingChooseCategories: false,
};

const chooseCategoryReducer = produce(
  (draft = initialState, action: TCategoryAction) => {
    switch (action.type) {
      case CategoryActionTypesEnum.GET_CATEGORY_CACHE_LOADING:
        draft.loadingChooseCategories = true;
        draft.errorChooseCategoriesLoading = false;

        return draft;
      case CategoryActionTypesEnum.GET_CATEGORY_CACHE_SUCCESS:
        draft.chooseCategory = action.payload;
        draft.loadingChooseCategories = false;

        return draft;
      case CategoryActionTypesEnum.GET_CATEGORY_CACHE_ERROR:
        draft.loadingChooseCategories = false;
        draft.errorChooseCategoriesLoading = true;

        return draft;
      default:
        return draft;
    }
  },
  initialState,
);

export default chooseCategoryReducer;
