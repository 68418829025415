import {
  millisecondsToMinutes,
  millisecondsToSeconds,
  minutesToMilliseconds,
} from 'date-fns';

const oneSecond = 1000;
const oneMinute = oneSecond * 60;

export const convertFromMillisecondsToHumanFormat = (
  milliseconds: number | undefined,
): string => {
  if (!milliseconds) {
    return '0s';
  }

  if (milliseconds > oneMinute) {
    const minutes = millisecondsToMinutes(milliseconds);

    const remains = milliseconds - minutesToMilliseconds(minutes);

    if (remains >= oneSecond) {
      return `${minutes}m:${millisecondsToSeconds(remains)}s`;
    }

    return `${minutes}m:00s`;
  }

  return `${millisecondsToSeconds(milliseconds)}s`;
};
