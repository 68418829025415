import { ICentreState } from '../../Reducers/centre/type';
import { ICentreLoadingPayload } from './type';

export enum CentresActionsEnum {
  /* <--------- Centres ---------> */
  SET_CENTRES = 'SET_CENTRES',

  /* <--------- Loadings ---------> */
  SET_LOADING = 'SET_LOADING_CENTRE',
}

interface ISetCentres {
  type: CentresActionsEnum.SET_CENTRES;
  payload: ICentreState['centre'];
}

interface ISetLoading {
  type: CentresActionsEnum.SET_LOADING;
  payload: ICentreLoadingPayload;
}

export type TCentresAction = ISetCentres | ISetLoading;
