import { CreatableSelect } from 'components/common';
import { AlertStatusesEnum } from 'constants/constants';
import { ManageCampaignModeEnum } from 'constants/enums';
import { convertToSelectData } from 'helpers';
import { useActions, useTypedSelector } from 'hooks';
import { useSnackbar } from 'notistack';
import { helperMessages } from 'pages/Campaign/constants';
import React, { useEffect } from 'react';
import { SingleValue } from 'react-select';
import { ISelectOption } from 'types';

import useSelects from '../Selects.hook';

const { fromObjectIdWithName } = convertToSelectData;

const Product: React.FC = () => {
  const { mode } = useTypedSelector(
    (state) => state && state.manageCampaign.main,
  );
  const { product, loaders, advertiser, isAutoSubstitutionCategory } =
    useTypedSelector((state) => state && state.manageCampaign.detail);
  const { products, isLoadingProducts } = useTypedSelector(
    (state) => state.products,
  );
  const {
    setProductManageCampaign,
    getProducts,
    getCategoryFromCache,
    createNewProductDetailTab,
  } = useActions();
  const { formatCreateLabel } = useSelects();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    getProducts(advertiser?.value);
  }, [advertiser?.value]);

  const handleChange = (value: SingleValue<ISelectOption<number>>): void => {
    setProductManageCampaign(value);

    if (value && isAutoSubstitutionCategory) {
      getCategoryFromCache(value.value);
    }
  };

  const handleCreateProduct = (inputValue: string): void => {
    if (!advertiser) {
      enqueueSnackbar('Advertiser not selected', {
        variant: AlertStatusesEnum.ERROR,
        preventDuplicate: true,
      });

      return;
    }

    createNewProductDetailTab({
      name: inputValue,
      advertiserId: advertiser.value,
    });
  };

  const isLoading = isLoadingProducts || loaders.createProduct;
  const isDisabled = isLoading || mode === ManageCampaignModeEnum.REVIEW;

  return (
    <CreatableSelect
      label="PRODUCT"
      options={fromObjectIdWithName<number>(products)}
      value={product || null}
      onChange={handleChange}
      noOptionsMessage={() => helperMessages.emptyProduct}
      isLoading={isLoading}
      isDisabled={isDisabled}
      formatCreateLabel={formatCreateLabel}
      onCreateOption={handleCreateProduct}
      isClearable
    />
  );
};

export default Product;
