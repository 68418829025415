import { Add as AddIcon } from '@mui/icons-material';
import { FieldArray, FieldArrayRenderProps, useFormikContext } from 'formik';
import React, { Fragment, useState } from 'react';

import { initialDayPart } from '../../../../../../constants/initialValues';
import { texts } from '../../../../../../constants/texts';
import { IInitialValues } from '../../../../types';
import { TimeManager } from './components';
import { SButton } from './SiteDayParts.styled';

const SiteDayParts: React.FC = () => {
  const [expandedAccordions, setExpandedAccordions] = useState<number[]>([]);

  const { values } = useFormikContext<IInitialValues>();

  const handleChange = (accordionNumber: number): void => {
    if (expandedAccordions.includes(accordionNumber)) {
      setExpandedAccordions((prev) =>
        prev.filter((item) => item !== accordionNumber),
      );

      return;
    }

    setExpandedAccordions((prev) => [...prev, accordionNumber]);
  };

  const isExpand = (accordionNumber: number): boolean => {
    if (expandedAccordions.includes(accordionNumber)) {
      return true;
    }

    return false;
  };

  const handleClickAddDayPart = (helper: FieldArrayRenderProps) => {
    helper.push(initialDayPart);
  };

  return (
    <div className="site-day-parts-wrapper">
      <FieldArray
        name="siteDayParts"
        render={(dayPartArrayHelper) => (
          <Fragment>
            {values.siteDayParts.map((__, dayPartIndex) => (
              <TimeManager
                key={dayPartIndex}
                index={dayPartIndex}
                number={dayPartIndex + 1}
                expand={isExpand(dayPartIndex)}
                handleChangeAccordion={handleChange}
                dayPartArrayHelper={dayPartArrayHelper}
              />
            ))}
            <SButton
              startIcon={<AddIcon sx={{ color: 'rgba(0, 0, 0, 0.54)' }} />}
              color="inherit"
              fullWidth
              onClick={() => handleClickAddDayPart(dayPartArrayHelper)}
            >
              {texts.pages.manage.content.siteDayParts.buttons.addDayPart}
            </SButton>
          </Fragment>
        )}
      />
    </div>
  );
};

export default SiteDayParts;
