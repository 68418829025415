import {
  FileLoadStatusesEnum,
  FileTypesEnum,
} from 'constants/enums/fileLoadStatuses';
import {
  FormatsEnum,
  formatsImageScheme,
  formatsVideoScheme,
  TImageFormats,
  TVideoFormats,
} from 'constants/file/formats';
import { ILoadersStorageMedia } from 'redux/Reducers/campaignsReducer/manage/manage/type';
import { v4 as uuidv4 } from 'uuid';

export const getFileTypeFromFormat = (
  fileFormat: FormatsEnum,
): FileTypesEnum => {
  const upperCase = fileFormat.toUpperCase();

  if (formatsImageScheme[upperCase as TImageFormats]) {
    return FileTypesEnum.IMAGE;
  }
  if (formatsVideoScheme[upperCase as TVideoFormats]) {
    return FileTypesEnum.VIDEO;
  }

  return FileTypesEnum.UNKNOWN;
};

export const createObjectLoadFile = (file: File): ILoadersStorageMedia => {
  const nameSplit = file.name.split('.');

  const fileFormat = nameSplit.pop() as FormatsEnum;
  const fileName = nameSplit.join('');

  const fileType = getFileTypeFromFormat(fileFormat);

  return {
    id: uuidv4(),
    name: fileName,
    loadProgress: 0,
    fileType,
    fileFormat,
    file,
    status: FileLoadStatusesEnum.ADDED,
  };
};
